import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col, Select, Button, Modal, Popconfirm } from 'antd';
import * as AntDIcons from '@ant-design/icons';
import Utils from '../../../misc/Utils';
import Enums from '../../../misc/Enums';
import { useNavigate } from 'react-router-dom';
import DocumentViewer from '../../../components/document-viewer/DocumentViewer';

const { Option } = Select;

export function View(props) {
   const navigate = useNavigate();

   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);
   // const currentDocument = props.document;
   const schedType = props.type;
   const uploader = props.uploader;
   const showSearchTools = props.showsearchtools;
   const emptydesc = props.emptydescription;

   const [currentDocument, setCurrentDocument] = useState(null);
   const [sectionLoading, setSectionLoading] = useState(false);
   const [levelList, setLevelList] = useState([]);
   const [sectionList, setSectionList] = useState([]);
   const [level, setLevel] = useState(null);
   const [section, setSection] = useState(null);
   const [scheduleID, setScheduleID] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();

      if (showSearchTools)
         getLevels();

      if (user_role === 'Student' || user_role === 'Parent')
         getScheduleStudent();

      setCurrentDocument(props.document);
   }, [props.document]);

   const getLevels = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/levellist`).then((response) => {
         if (response.data) {
            const data = response.data;
            let levels = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               levels.push(obj);
            });

            setLevelList(levels);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleLevelChange = (val) => {
      setCurrentDocument(null);
      setSection(null)
      setSectionList([]);
      setLevel(val === undefined ? null : val);
      setSectionLoading(true);

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${val}`).then((response) => {
         if (response.data) {
            const data = response.data;

            var sectionlist = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               sectionlist.push(obj);
            });

            props.onChangeSearchCriteria(val === undefined ? null : val, section);

            setSectionList(sectionlist);
            setSectionLoading(false);
         }
      }, (error) => {
         setSectionLoading(false);
         console.log(error.message);
      });
   }

   const handleSectionChange = (val) => {
      setSection(val === undefined ? null : val);
      setCurrentDocument(null);
      props.onChangeSearchCriteria(level, val === undefined ? null : val);
   }

   const handleSearchSchedule = () => {
      setCurrentDocument(null);

      const url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/getschedule?type=${schedType}&level=${level}&section=${section}&uploader=${uploader}`;
      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;
            setCurrentDocument(data.file_url);
            setScheduleID(data.schedule_id);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getScheduleStudent = () => {
      const url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/getschedulestudent?type=${schedType}&id=${uploader}`;
      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;
            setCurrentDocument(data.file_url);
            setScheduleID(data.schedule_id);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleClearSchedule = () => {
      var url = "";

      if (schedType === "school")
         url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/removeschedule?st=${schedType}&level=${-1}&section=${-1}`;
      else
         url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/removeschedule?st=${schedType}&level=${level}&section=${section}`;

      axios.post(url).then((response) => {
         if (response.data) {
            const data = response.data;

            setCurrentDocument(null);

            if (data.Code === 1)
               Modal.error({
                  title: 'Schedule',
                  content: 'Schedule was not removed.',
                  centered: true,
               });
            else
               Modal.success({
                  title: 'Schedule',
                  content: 'Schedule removed.',
                  centered: true,
               });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   return (
      <>
         {
            (showSearchTools && (user_role === 'Admin' || user_role === 'Teacher')) &&
            <Row gutter={10} style={{ paddingBottom: "20px" }} >
               <Col span={24}>
                  <div className='search-columns-3'>
                     <Select
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Level"
                        onChange={(val) => handleLevelChange(val)}
                        onClear={(val) => handleLevelChange(val)}
                        value={level}
                     >
                        {levelList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>

                     <Select
                        loading={sectionLoading}
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Section"
                        value={section}
                        onChange={(val) => handleSectionChange(val)}
                        onClear={(val) => handleSectionChange(val)}
                     >
                        {sectionList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>

                     <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleSearchSchedule()} icon={<AntDIcons.SearchOutlined />}>Search</Button>
                  </div>
               </Col>
            </Row>
         }

         {
            currentDocument !== null &&
            <Popconfirm
               title="Are you sure you want to remove this schedule?"
               onConfirm={() => handleClearSchedule()}
               okText="Yes"
               cancelText="No"
            >
               <Button className='button-shadow' type='primary' shape='round' icon={<AntDIcons.DeleteOutlined />} style={{ marginBottom: '10px' }}>Clear Schedule</Button>
            </Popconfirm>
         }

         {
            user_role === 'Student' || user_role === 'Parent'
               ?
               <DocumentViewer
                  document={currentDocument}
                  documentlist={null}
                  dvheight={"calc(100vh - 54px)"}
                  emptydescription={emptydesc}
               />
               :
               <DocumentViewer
                  document={currentDocument}
                  documentlist={null}
                  dvheight={"calc(100vh - 200px)"}
                  emptydescription={emptydesc}
               />
         }
      </>
   );
}