import React,{ useEffect, useState,useRef } from 'react'
import CanvasComponent from './CanvasComponent';
import Utils from '../../../misc/Utils';
import "./LessonViewer.scss"
import {Pagination
 } from 'antd';
 import { isMobile } from 'react-device-detect';

export const CanvasContext = React.createContext({});  

export default function LessonViewer(props) {

    const [zoomValue, setZoomValue] = useState(1);
    const [lessonId, setLessonId] = useState("")
    const [canvasData, setCanvasData] = useState([])
    const [canvasListData, setCanvasListData] = useState([])
    const [canvasListDataIdx, setCanvasListDataIdx] = useState(0)
    // const [bodyBackgroundColor, setBodyBackgroundColor] = useState("white")
    const [bodyBackgroundImage, setBodyBackgroundImage] = useState("")
    const [scaleWidth, setScaleWidth] = useState(1)
    const [scaleHeight, setScaleHeight] = useState(1)
    const [scale, setScale] = useState(1)
    const [isLoaded, setIsLoaded] = useState(false)
    const containerRef = useRef(null)
    const divWrapper = useRef(null)
    // const [showPointer, setShowPointer] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [current, setCurrent] = useState(0);
    const [scaleX, setScaleX] = useState(1)
    const [scaleY, setScaleY] = useState(1)
    const [translationX, setTranslationX] = useState(0)
    const [translationY, setTranslationY] = useState(0)
    const [proportional, setProportional] = useState(true)

  //   useEffect(() => {
  //     const handleResize = () => {
  //         setWindowWidth(window.innerWidth);

  //         var elHeight = containerRef.current?.clientHeight;
  //         var elWidth = containerRef.current?.clientWidth;  
          
  //         var scale;
  //         var width = divWrapper.current?.clientWidth/ elWidth
  //         var height =  divWrapper.current?.clientHeight / elHeight
  //         scale = Math.min(
  //           divWrapper.current.offsetWidth/ elWidth,    
  //           divWrapper.current.offsetHeight / elHeight
  //         );
    

  //         scale = scale > 1 ? 1 : scale
  //         var minHeight = scale === 0 ? scaleHeight : height-0.2
  //         var minWidth = scale === 0 ? scaleWidth : scale-0.03

  //         setScale(minWidth)
  //         setScaleWidth(minWidth)
  //         setScaleHeight(minHeight)     
  //         // divWrapper.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});   
  //     };

  //     window.addEventListener("resize", handleResize);
  //     return () => {
  //         window.removeEventListener("resize", handleResize);
  //     };
  // }, []);    

  useEffect(() => {
    if (!isMobile)
    {
        if (!divWrapper.current) return;
        if (!containerRef.current) return;

        const handleResize = () => {
              var currentWidth = containerRef.current?.clientWidth; 
              var currentHeight = containerRef.current?.clientHeight;
            
              var availableHeight = divWrapper.current?.clientHeight; 
              var availableWidth = divWrapper.current?.clientWidth; 
            
              var scaleX = availableWidth / currentWidth;
              var scaleY = (availableHeight / currentHeight);
            
              if (proportional) {
                scaleX = Math.min(scaleX, scaleY)
                scaleY = scaleX 
              }
            
              var translationX = Math.round((availableWidth - (currentWidth * scaleX)) / 2);
              var translationY = Math.round((availableHeight - (currentHeight * scaleY)) / 2);

              setTranslationX(translationX)
              setTranslationY(translationY)
              setScaleX(scaleX)
              setScaleY(scaleY)      
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };    
    }
  }, []);    

  useEffect(() => {
        // Set Initial value
        var currentWidth = containerRef.current?.clientWidth; 
        var currentHeight = containerRef.current?.clientHeight;
      
        var availableHeight = divWrapper.current?.clientHeight; 
        var availableWidth = divWrapper.current?.clientWidth; 
      
        var scaleX = availableWidth / currentWidth;
        var scaleY = (availableHeight / currentHeight);
      
        if (proportional) {
          scaleX = Math.min(scaleX, scaleY)
          scaleY = scaleX 
        }
      
        var translationX = Math.round((availableWidth - (currentWidth * scaleX)) / 2);
        var translationY = Math.round((availableHeight - (currentHeight * scaleY)) / 2);

        setTranslationX(translationX)
        setTranslationY(translationY)
        setScaleX(scaleX)
        setScaleY(scaleY)   
   }, []);    


const setSelectedIdxCanvas = (idx) => {
    // set default in List Canvas
    var canvas = canvasListData[idx-1].data
    setCanvasListDataIdx(idx-1)
    setCanvasData(canvas)
    setCurrent(idx)
}


    useEffect(() => {
        // let data = props.sections
        const search = window.location.search;
        const query = new URLSearchParams(search);
        const version = query.get('version')       

        let data = props.data
        setCanvasListDataIdx(0)
        if (data.sections === null)
           data.sections = []
        data = Utils.convertKeysToLowerCase(data)
        
        if (props.version === 0 && version !== '2')
        {
          var listData = {backgroundcolor : "white", data : canvasData}
          setCanvasListData([...canvasData, listData])
        }
        else
        {
          var canvasListDataT = data //data.sections[props.lessonIdx].contents[props.topicIdx]?.data
          var canvasDataT = []
          if (canvasListDataT)
          {
             if (canvasListDataT.length > 0)
              {
                canvasDataT = [...{...canvasListDataT[0]}?.data]
                setCanvasData(canvasDataT)
                setCanvasListData([...canvasListDataT])
              }
             else
             {
              var listData = {backgroundcolor : "white", data : canvasDataT}
              setCanvasListData([...canvasDataT, listData])
             } 
          } 
          else
          {
            var listData = {backgroundcolor : "white", data : canvasDataT}
            setCanvasListData([...canvasDataT, listData])
          }
  
          setCanvasData(canvasDataT)  
          setIsLoaded(true)
        }      
  
     }, [props.data]);

    //  const clickNextPrev = (type) =>
    //  {
    //     var dataIdx = canvasListDataIdx
    //      if (type === "prev")
    //     {
    //         dataIdx = (dataIdx-1) < 0 ? 0 : dataIdx-1
    //     }
    //     if (type === "next")
    //     {
    //         dataIdx = (dataIdx+1) > canvasListData.length-1 ? canvasListData.length-1 : dataIdx+1
    //     }     

    //     setSelectedIdxCanvas(dataIdx)
    //  }

 
    return( 
        <>
           {/* {<CanvasContext.Provider value={context}>      */}
           {/* <div style={{position : "relative", height : "100%" , width : "100%" , scale : "100%"}}> */}
             <div class="scaleable-wrapper" id="scaleable-wrapper" ref={divWrapper}>
             <div class="very-specific-design" id="very-specific-design" ref={containerRef}
                  style={{
                    position: "absolute",
                    border : "1px solid black",
                    left: "0px",
                    top: "0px",
                    "-webkit-transform": "translate(" + translationX + "px, "
                                                      + translationY + "px) scale3d("
                                                      + scaleX + ", " + scaleY + ", 1)",
                    "-webkit-transform-origin": "0 0",                    
                    backgroundColor : canvasListData.length > 0 ?  canvasListData[canvasListDataIdx].backgroundcolor : "white" ,
                    backgroundImage : canvasListData.length > 0 ?  (`url(${canvasListData[canvasListDataIdx].backgroundimage})`) : "" ,                  
                  }}
                >              
                      {canvasData.map((canvas) => {
                          return <CanvasComponent {...canvas} />;
                      })} 
                </div>  
              </div>    
              {/* </div>            */}
                <div
                  style={{
                    display : "flex",
                    width : "100%",
                    justifyContent : "right",
                    alignContent : "center",
                    // backgroundColor  : "red"
                  }}
                >
                    <div>
                        <Pagination
                            total={canvasListData.length}
                            onChange={setSelectedIdxCanvas}
                            current={current}
                            defaultPageSize={1}
                            responsive
                            showQuickJumper
                            showTotal={(total) => `Total ${total} items`}
                        />   
                    </div>
                </div>                         
        {/* </CanvasContext.Provider>}        */}
        </>
    )
}
