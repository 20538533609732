import { useContext} from 'react'
import SearchObjectsAPI from '../../LessonComponents/SearchObjectsAPI'
import { CanvasContext } from '../../hooks/LessonPage'

function VectorPanel() {

  const { state, actions } = useContext(CanvasContext);

  const setItem = (param) =>
  {
    var data = {type : "IMAGE", url : param.image, defaultcontent : ""}
    actions?.addElement(data);
  }
  return (
    <>
     <SearchObjectsAPI add={setItem} type={"vector"}/>
    </>
  )
}
export default VectorPanel
