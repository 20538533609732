import React, { useEffect, useState, useReducer, useRef } from 'react';
import { Input, Upload, Row, Col, Radio, Space, Button, Modal } from 'antd';
import * as AntDIcons from '@ant-design/icons';
import SimpleBarReact from "simplebar-react";

// import { Editor } from '@tinymce/tinymce-react';
import uuid from 'react-uuid';
// import { at } from 'lodash';
import Utils from '../../../misc/Utils';
import Enums from '../../../misc/Enums';

const { TextArea } = Input;

export default function Submit(props) {
   const editorRef = useRef(null);

   const [submitType, setSubmitType] = useState("1");
   const [uploadFileList, setUploadFileList] = useState([]);
   const [message, setMessage] = useState("");
   const [urlLink, setUrlLink] = useState("");

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const upload_props = {
      onRemove: file => {
         setUploadFileList(() => {
            const index = uploadFileList.indexOf(file.originFileObj);
            const newFileList = uploadFileList.slice();
            newFileList.splice(index, 1);
            return newFileList;
         })
      },

      beforeUpload: file => {
         // var list = [];
         // list.push(file);
         // setUploadFileList(list);
         // // console.log(uploadFileList);
         // return false;

         const isPNG = file.type === 'image/png';
         const isJPG = file.type === 'image/jpg' || 'image/jpeg';
         const isBMP = file.type === 'image/bmp';
         const isDOC = file.type === 'application/msword' || file.type === 'application/vnd.ms-word';
         const isDOCX = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
         const isXLS = file.type === 'application/msexcel' || file.type === 'application/vnd.ms-excel';
         const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
         const isPPT = file.type === 'application/vnd.ms-powerpoint';
         const isPPTX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
         const isPPSX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow';
         const isPDF = file.type === 'application/pdf';
         const isMP4 = file.type === 'video/mp4';
         const isAVI = file.type === 'video/avi';
         const isMP3 = file.type === 'audio/mpeg';
         const isWAV = file.type === 'audio/wav';

         const fileValid = isPNG || isJPG || isBMP || isDOC || isDOCX || isXLS || isXLSX || isPPT || isPPTX || isPDF || isPPSX || isMP4 || isAVI || isMP3 || isWAV;

         if (fileValid) {
            const allowedFileSize = 100;
            const isLtMB = file.size / 1024 / 1024 <= allowedFileSize;

            if (isLtMB) {
               var list = uploadFileList;
               list.push(file);
               setUploadFileList(list);
            } else {
               Modal.error({
                  title: 'Homework',
                  content: `Maximum allowed file size is ${allowedFileSize} MB`,
                  centered: true
               });

               return fileValid || Upload.LIST_IGNORE;
            }

            // console.log(uploadFileList);
         } else {
            Modal.error({
               title: 'Homework',
               content: 'You are not allowed to upload that file type',
               centered: true
            });
         }

         return false;
      },

      uploadFileList,
   };

   const handleMessageChange = () => {

   }

   const handleSubmitTypeChange = (val) => {
      setSubmitType(val);
   }

   const handleCancelSubmit = () => {
      props.onCancelCallback();
   }

   const handleSubmitHomework = () => {
      if (message && (uploadFileList.length > 0 || urlLink !== "")) {
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         var submit_id = "submit_homework_" + o2o_mode + "_" + uuid();
         let submittedData = {
            id: submit_id,
            homework_id: props.homeworkID,
            student_id: user_id,
            message: message,
            document: "",
            file_name: "",
            url_link: urlLink,
         }

         props.onSubmitCallback(submittedData, uploadFileList)
      } else {
         Modal.info({
            title: 'Homework',
            content: (
               <>
                  Please complete the following before submitting:
                  <ul>
                     <li>
                        Message
                     </li>
                     <li>
                        Upload File or External link
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   return (
      <>
         <Row gutter={12} style={{ paddingBottom: "10px" }}>
            <Col span={24}>
               {/* <Form.Item name="description" label="Description" 
               rules={[{ 
                  required: true,
                  message: 'Please enter description' 
               }]}
            > */}
               <label>Message</label>
               {/* <Editor
                  apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                  onInit={(evt, editor) => editorRef.current = editor}
                  onFocusOut={(e) =>  setMessage(e.target.innerHTML)}
                  initialValue={""}
                  init={{
                     menubar: true,  
                     height :220, 
                     statusbar: true,
                     plugins: [
                     'advlist autolink lists link image charmap print preview anchor',
                     'searchreplace visualblocks code fullscreen',
                     'insertdatetime media table paste code help wordcount'
                     ],
                     toolbar: 'undo redo | formatselect | ' +
                     'bold italic backcolor | alignleft aligncenter ' +
                     'alignright alignjustify | bullist numlist outdent indent | ' +
                     'removeformat | emoticons | help',
                     content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
               />  */}
               <TextArea
                  allowClear
                  rows={5}
                  onBlur={(event) => setMessage(event.target.value)}
               />
               {/* </Form.Item> */}
            </Col>
         </Row>
         <Row gutter={12} style={{ paddingBottom: "10px" }}>
            <Col xs={24} xl={8}>
               <Space direction='vertical'>
                  <label>Submit Method</label>
                  <Radio.Group
                     name='submittype'
                     defaultValue={"1"}
                     // value={ass_options.enable_timer} 
                     buttonStyle='solid'
                     onChange={(e) => handleSubmitTypeChange(e.target.value)}
                  >
                     <Radio.Button value="0">External Link</Radio.Button>
                     <Radio.Button value="1">Upload File</Radio.Button>
                  </Radio.Group>
               </Space>
            </Col>
            <Col xs={24} xl={16}>
               <Space direction='vertical' style={{ width: "100%" }}>
                  {submitType === "1"
                     ?
                     <>
                        <label>Upload Homework</label>
                        <SimpleBarReact style={{ maxHeight: "250px", overflow: "auto" }}>
                           <Upload
                              style={{ display: "block" }}
                              maxCount={5}
                              multiple
                              {...upload_props}
                              // listType="picture"
                              defaultFileList={[...uploadFileList]}
                              className="upload-list-inline"
                              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                              .xls,.xlsx,.png,.jpg,.pdf,.mp4,.avi,.ppt,.pptx,.ppsx,"
                           >
                              <Button shape='round' icon={<AntDIcons.CloudUploadOutlined />} block>Select Files (Max: 5)</Button>
                           </Upload>
                        </SimpleBarReact>
                     </>
                     :
                     <>
                        <label>External Link</label>
                        <Input
                           allowClear
                           style={{ borderRadius: 7, width: "100%" }}
                           onBlur={(e) => setUrlLink(e.target.value)}
                        />
                     </>
                  }
               </Space>
            </Col>
         </Row>
         <Row gutter={12}>
            <Col span={24} style={{ textAlign: "right" }}>
               <Space direction='horizontal'>
                  <Button shape='round' onClick={() => { handleCancelSubmit(); }}>Cancel</Button>
                  <Button type='primary' shape='round' onClick={() => { handleSubmitHomework(); }}>Submit</Button>
               </Space>
            </Col>
         </Row>
      </>
   )
}