import * as React from 'react';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import * as AntDIcons from '@ant-design/icons';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import "../Viewer/Components/FileElement.scss"


const PDFLessonViewer = ({ fileUrl }) => {
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;
    const pageLayout = {
        transformSize: ({ size }) => ({
            height: size.height + 30,
            width: size.width + 30,
        }),
    };
 
    return (
        <div
            className="rpv-core__viewer"
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                height: '100%',
                position: 'relative',
                // backgroundColor : "green"x
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    border: '1px solid rgba(0, 0, 0, 0.2)',
                    borderRadius: '2px',
                    bottom: '16px',
                    display: 'flex',
                    left: '50%',
                    padding: '4px',
                    position: 'absolute',
                    transform: 'translate(-50%, 10px) scale(1)',
                    zIndex: 1,
                    // transform : "scale(4)",
                }}
            >
                <Toolbar>
                    {(props) => {
                        const {
                            CurrentPageInput,
                            Download,
                            EnterFullScreen,
                            GoToNextPage,
                            GoToPreviousPage,
                            NumberOfPages,
                            Print,
                            ZoomIn,
                            ZoomOut,
                        } = props;
                        return (
                            <>
                                <div style={{ padding: '0px 2px'}}>
                                    <ZoomOut />
                                </div>
                                <div style={{ padding: '0px 2px' }}>
                                    <ZoomIn />
                                </div>
                                <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                    <GoToPreviousPage />
                                </div>
                                <div style={{ padding: '0px 2px', width: '3rem' }}>
                                    <CurrentPageInput />
                                </div>
                                {/* <div style={{ padding: '0px 2px' }}>
                                    <NumberOfPages />
                                </div> */}
                                <div style={{ padding: '0px 2px' }}>
                                    <GoToNextPage />
                                </div>
                                <div style={{ padding: '10px' }}>
                                    <AntDIcons.FullscreenOutlined style={{height : "40px", width : "40px", cursor : "pointer" }} 
                                          onClick={()=> window.open(`${fileUrl}`, "_blank")}
                                    />
                                </div>
                                <div style={{ padding: '0px 2px' }}>
                                    <Download />
                                </div>
                                <div style={{ padding: '0px 2px' }}>
                                    <Print />
                                </div>
                            </>
                        );
                    }}
                </Toolbar>
            </div>
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            >
                <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                   <Viewer pageLayout={pageLayout}  fileUrl={fileUrl} plugins={[toolbarPluginInstance]} defaultScale={SpecialZoomLevel.PageWidth} />
                </Worker>
            </div>
        </div>
    );
};

export default PDFLessonViewer;