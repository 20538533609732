import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import LessonService from '../../services/LessonService';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { Divider, message, Space } from 'antd';
import { Tooltip, Popconfirm, Modal, Input, Button, Collapse, Select, DatePicker } from 'antd';
import moment from 'moment';
import uuid from 'react-uuid'
import { update_topic_order, delete_item, edit_title, add_item } from '../../actions';
const { Option } = Select;
const { RangePicker } = DatePicker;

export function EditQuiz(props) {
   const [lessonTitle, setLessonTitle] = React.useState('');
   const [effectiveDate, seteffectiveDate] = React.useState('');
   const [effectiveDate1, seteffectiveDate1] = React.useState('');
   const [effectiveDate2, seteffectiveDate2] = React.useState('');
   const [items, setItems] = React.useState([]);
   const lessonState = useSelector(state => state.lesson)
   const [dateEffective, setDateEffective] = React.useState([]);
   const [formativeQuiz, setFormativeQuiz] = React.useState([]);
   const [quizSelected, setQuizSelected] = React.useState({});
   const [Id, setId] = React.useState(0);
   const [levelId, setLevelId] = React.useState(0);
   const [formativeID, setFormativeID] = useState('');
   const [formativeAssignID, setFormativeAssignID] = useState('');

   const dispatch = useDispatch();
   const dateFormat = 'YYYY-MM-DD h:mm a'
   // const dateFormat = 'MMM D, YYYY h:mm a';;

   useEffect(() => {
      var itemsContents = [...lessonState.sections[props.topicIdx].contents];
      var items = itemsContents.filter(item => item.id === props.lessonIdx)[0];
      setLessonTitle(items.title)
      setFormativeID(items.otherid);
      setFormativeAssignID(items.otherid2);
      setItems(items.links)

      var effDate = ""
      var tmpeffectivedate = items.effectivedate;
      if (tmpeffectivedate.includes("@")) {
         var tmpdatesplit = tmpeffectivedate.split("@");
         seteffectiveDate1(tmpdatesplit[0])
         seteffectiveDate2(tmpdatesplit[1])
         setDateEffective(tmpdatesplit)
      }
      else {
         seteffectiveDate1(tmpeffectivedate)
         seteffectiveDate2(tmpeffectivedate)
         var daterange = []
         daterange.push(tmpeffectivedate)
         daterange.push(tmpeffectivedate)
         setDateEffective(daterange)
      }
      //   seteffectiveDate(effDate) 

      const search = window.location.search;
      const query = new URLSearchParams(search);
      let user = Utils.getCurrentUserInfo(Enums.UserInfo.id)
      const LId = query.get('level')
      setLevelId(LId)
      setId(user)
      LessonService.getListFormative(user, LId).then((res) => {
         var data = res.data;
         let allquiz = []
         data.map((item) => {
            const dta = { id: item.id, subject_desc: item.subject_desc, title: item.title, question_type: item.file_url === '' ? 2 : 1 }
            allquiz.push(dta)
         })
         setFormativeQuiz(allquiz)
      }).catch((ex) => {
         message.error(ex.message)
      })
   }, []);


   const dateEffectiveChange = (date, datestring) => {
      setDateEffective(datestring)
   }

   const handleChangeQuiz = (value) => {
      console.log(`selected ${value}`);
      const selectedQuiz = formativeQuiz.filter(p => p.id === value)
      setQuizSelected(selectedQuiz[0])
   }

   const editFormativeQuiz = () => {
      if (dateEffective !== "") {
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         const assignment_id = "ass_assign_" + o2o_mode + "_" + uuid();
         const effectiveDaterange = dateEffective[0] + "@" + dateEffective[1];

         if (formativeAssignID !== "") {
            if (Object.keys(quizSelected).length > 0) {
               LessonService.assignFormative(assignment_id, quizSelected.id, Id, lessonState.lessonid, dateEffective[0], dateEffective[1], levelId).then(() => {
                  var params = { Type: "QUIZ", TopicIndex: props.topicIdx, LessonIndex: props.lessonIdx, title: quizSelected.title, otherdesc: quizSelected.subject_desc, otherid: quizSelected.id, otherid2: assignment_id, effectivedate: effectiveDaterange, q_type: quizSelected.question_type }
                  dispatch(edit_title(params));
                  //  setVisibleSmall(false)   
                  props.saveLesson()
               }).catch((ex) => {
                  message.error(ex.message)
                  //  setVisibleSmall(false)   
               })
            }
         }
         else {
            LessonService.updateAssignFormative(formativeAssignID, formativeID, Id, lessonState.lessonid, dateEffective[0], dateEffective[1], levelId).then(() => {
               var params = { Type: "QUIZ-DATE", TopicIndex: props.topicIdx, LessonIndex: props.lessonIdx, effectivedate: effectiveDaterange }
               dispatch(edit_title(params));
               //  setVisibleSmall(false)   
               props.saveLesson()
            }).catch((ex) => {
               message.error(ex.message)
            });
         }
      }
      else {
         message.error("Effective date is blank!")
      }
   }

   return (
      <>
         <div style={{ marginBottom: "10px" }}>
            <span>Current Lesson Name : <span style={{ fontWeight: "bold" }}>{lessonTitle}</span></span>
         </div>
         {effectiveDate1 !== "" && <>  <span>Effective Date : </span>
            <RangePicker
               style={{ width: '100%' }}
               showTime={{ format: 'hh:mm A', use12Hours: true }}
               defaultValue={[moment(effectiveDate1, dateFormat), moment(effectiveDate2, dateFormat)]}
               format={dateFormat}
               onChange={dateEffectiveChange}
            />
            <div style={{ marginTop: "8px" }}>
               <span>Change Formative : </span>
               <div style={{ margin: "auto" }} >
                  <Select style={{ width: '100%' }} onChange={handleChangeQuiz}>
                     {
                        formativeQuiz.map((item) => {
                           return (
                              <Option value={item.id}>{item.title}</Option>
                           )
                        })
                     }
                  </Select>
                  {/*                     <Button type="primary" onClick={() => addFormativeQuiz(props.topicIdx)} style={{borderRadius: "7px", marginLeft : "5px" , width : "150px"}}>Change Formative</Button>  */}
               </div>
            </div>
            <div style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
               <div>
                  <Button className='button-shadow' type="primary" style={{ borderRadius: "7px", marginLeft: "5px", width: "150px" }} onClick={() => editFormativeQuiz()}>Update Lesson</Button>
               </div>
            </div>
         </>}
      </>
   );
}