import {useSelector, useDispatch} from 'react-redux'
import React, {useEffect} from 'react'
import { Button } from 'antd';
import './WebsiteContent.scss'


export function WebsiteContent (props)
{
    const lessonState = useSelector(state => state.lesson)  
  var firstContent = lessonState.sections[props.currentSectionIdx].contents[props.currentSectionContentIdx].links[props.linkIndex];
  var linkTitle = firstContent.title;
  var linkDescription = firstContent.description;
  var linkImage = firstContent.image;
  var linkUrl = firstContent.url

  return <>
          <div style={{display : "flex", justifyContent : "center", height : "100%"}}>
          <div class="card_autoplay">
            <div class="card">
            <div class="card__image " style={{backgroundImage : `url(${linkImage})`}}>                              
            </div>
            <div class="card__content">
                <div class="card__title"> {linkTitle}</div>
                <p class="card__text">{linkDescription} </p>
                <Button type='primary' size='middle' shape='round' 
                onClick={() => window.open(linkUrl,'_blank') }>
                   Open Link</Button>                                    
            </div>
            </div>
          </div>  
          </div>
{/*       <div style={{display : "flex", position: "relative", height : "100%"}}>       
          <div style = {{height : "30%" , width :"30%", margin : "30px", backgroundColor : "#ECECEC", borderRadius : "7px", borderColor: "#ECECEC"}}>
             <img height="100%" src={linkImage} style={{borderRadius : "7px"}}/>  
              <div style={{display : "flex" , position: "absolute", width : "30%", justifyContent: "center", paddingTop : "10px" }}>   
                <div >      
                   <a className="button" href={linkUrl} target="_blank">Open Link</a>
                </div> 
               </div>                     
          </div>                   
          <div style={{height : "100%", width : "60%" } }>
            <div style={{display: "flex", marginTop : "15px"}}>
                <div style={{ paddingLeft : "20px", textAlign: "justify" }}>
                <h5>Title</h5>{linkTitle}
                </div>
            </div>
            <div style={{display: "flex", marginTop : "15px"}}>
              <div style={{ height : "100px", paddingLeft : "20px", textAlign: "justify", textJustify: "inter-word"}}>
                <span><h5>Description</h5> {linkDescription}</span>
              </div>
            </div>
          </div> 
        </div>  */}   
  </>
}