//this function will be used by our /reducers
export const update_links_order = (payload) => {
    return {
        //we could name type key anything we want but we shouldnt
        type: 'UPDATE-LINK-ORDER',
        payload: payload
    }
}

export const update_lesson_order = (payload) => {
    return {
        //we could name type key anything we want but we shouldnt
        type: 'UPDATE-LESSON-ORDER',
        payload: payload
    }
}

export const update_topic_order = (payload) => {
    return {
        //we could name type key anything we want but we shouldnt
        type: 'UPDATE-TOPIC-ORDER',
        payload: payload
    }
}

export const delete_item = (payload) => {
    return {
        //we could name type key anything we want but we shouldnt
        type: 'DELETE-ITEM',
        payload: payload
    }
}

export const add_item = (payload) => {
    return {
        //we could name type key anything we want but we shouldnt
        type: 'ADD-ITEM',
        payload: payload
    }
}



export const edit_title = (payload) => {
    return {
        //we could name type key anything we want but we shouldnt
        type: 'EDIT-TITLE',
        payload: payload
    }
}

// CJC Lesson Creation
export const update_lesson_data = (data) => {
    return {
        type: 'UPDATE-LESSON-DATA',
        payload: data
    }
}

export const update_lesson_section = (data) => {
    return {
        type: 'UPDATE-LESSON-SECTION',
        payload: data
    }
}

export const add_lesson_item = (data) => {
    return {
        type: 'POST-LESSON-SECTION',
        payload: data
    }
}

export const edit_lesson_title = (data) => {
    return {
        type: 'EDIT-LESSON-TITLE',
        payload: data
    }
} 

export const post_lookup = (data) => {
    return {
        type: 'POST-TABLE',
        payload: data        
    }
}

export const initialize_lesson = (data) => {
    return {
        type: 'INITIALIZE-LESSON',
        payload: data        
    }
}

export const reset_lesson = (data) => {
    return {
        type: 'RESET-LESSON',
        payload: data        
    }
}

export const set_lessonrec = (data) => {
    return {
        type: 'SET-LESSONREC',
        payload: data        
    }
}

export const set_lesson = (data) => {
    return {
        type: 'SET-LESSON',
        payload: data        
    }
}

export const update_notice_data = (data) => {
    return {
        type: 'UPDATE-NOTICE-DATA',
        payload: data        
    }
} 

export const post_notice = (data) => {
    return {
        type: 'POST-NOTICE',
        payload: data        
    }
}

//-- EMN Assessment
export const update_ass_option_data = (data) => {
   return {
       //we could name type key anything we want but we shouldnt
       type: 'UPDATE-ASS-OPTION-DATA',
       payload: data
   }
}

export const set_mastery = (data) => {
   return {
       type: 'SET-MASTERY',
       payload: data        
   }
}

export const update_mastery_data = (data) => {
   return {
       type: 'UPDATE-MASTERY-DATA',
       payload: data        
   }
}

export const reset_mastery_data = (data) => {
   return {
       type: 'RESET-MASTERY-DATA',
       payload: data        
   }
}

export const set_mastery_search_keys = (data) => {
   return {
       type: 'SET-MASTERY-SEARCH-KEYS',
       payload: data        
   }
}

export const reset_mastery_search_keys = (data) => {
   return {
       type: 'RESET-MASTERY-SEARCH-KEYS',
       payload: data        
   }
}

export const set_mastery_assignment = (data) => {
   return {
       type: 'SET-MASTERY-ASSIGNMENT',
       payload: data        
   }
}

export const update_mastery_assignment_data = (data) => {
   return {
       type: 'UPDATE-MASTERY-ASSIGNMENT-DATA',
       payload: data        
   }
}

export const reset_mastery_assignment_data = (data) => {
   return {
       type: 'RESET-MASTERY-ASSIGNMENT-DATA',
       payload: data        
   }
}