import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './SideCalendar.css'

function SideCalendar() {
   const [value, onChange] = useState(new Date());    

   return ( 
      <div className="notice-item-inner">
         <div className="notice-item-inner2">
         <Calendar
            onChange={onChange}
            value={value}
            calendarType='US'
         />
         </div>
      </div>        
   )
}

export default SideCalendar;    