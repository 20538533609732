import { Layout } from 'antd';
import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, InputNumber } from 'antd';
import "./Registration.scss"
const { Header, Footer, Sider, Content } = Layout;



export default function Registration() {
   
    const onFinish = (values) => {
        console.log('Success:', values);
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };      

    return (
        <>
         <Layout className="layout" style={{ backgroundColor: "white", height: "100vh" }}>
            <Header style={{ height: "70px", backgroundColor: "white", borderBottom: "5px", borderBottomColor: "black", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
               <div className="header-logo" >
                  <img src="/images/brainee_logo_new.png" height="52px"></img>
               </div>
            </Header>
            <Content style={{ minHeight: "480px", overflow: "auto", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <div style={{width :"30%"}}>
            <Form
                name="basic"
                labelCol={{
                span: 8,
                }}
                wrapperCol={{
                span: 16,
                }}
                style={{
                maxWidth: 600,
                }}
                initialValues={{
                remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                label="Username"
                name="username"
                rules={[
                    {
                    required: true,
                    message: 'Please input your username!',
                    },
                ]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                    required: true,
                    message: 'Please input your password!',
                    },
                ]}
                >
                <Input.Password />
                </Form.Item>

                <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
                >
                <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
                >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Form.Item>
            </Form>                
            </div>    
            <div style={{width :"70%"}}>
            <Form
                {...layout}
                name="nest-messages"
                onFinish={onFinish}
                style={{
                maxWidth: 600,
                }}
            >
                <Form.Item
                name={['user', 'name']}
                label="Name"
                rules={[
                    {
                    required: true,
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                name={['user', 'email']}
                label="Email"
                rules={[
                    {
                    type: 'email',
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                name={['user', 'age']}
                label="Age"
                rules={[
                    {
                    type: 'number',
                    min: 0,
                    max: 99,
                    },
                ]}
                >
                <InputNumber />
                </Form.Item>
                <Form.Item name={['user', 'website']} label="Website">
                <Input />
                </Form.Item>
                <Form.Item name={['user', 'introduction']} label="Introduction">
                <Input.TextArea />
                </Form.Item>
                <Form.Item
                wrapperCol={{
                    ...layout.wrapperCol,
                    offset: 8,
                }}
                >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Form.Item>
            </Form>                
            </div> 
            </Content>
            <Footer style={{ textAlign: 'center', backgroundColor: "white", height: "70px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>Brainee ©2022 Powered by CloudPH - Ver.{1.0}</Footer>
         </Layout>

      </>        
    )
}