import React, { useState } from 'react'
import { Input, Tooltip } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { CloseCircleTwoTone } from '@ant-design/icons';
// import Utils from '../../../misc/Utils';

const { TextArea } = Input;

export default function MultipleAnswerItem(props) {
   const [value, setValue] = useState(props.value);

   const handleRemoveOption = (idx) => {
      props.parentCallback("remove", "", idx);
   }

   const handleChange = (value) => {
      props.parentCallback("update", value, props.id);
   }

   const handleCheckChange = (event) => {
      props.parentCallback("update-check", event.target.checked, props.id);
   }

   return (
      <>
         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '7px' }}>
            <Checkbox
               name={props.id}
               checked={props.correct === "1" ? true : false}
               onChange={handleCheckChange}
               disabled={props.mode.includes("assign") ? true : false}
            />
            <TextArea
               autoSize={{
                  minRows:1,
                  maxRows: 2,
               }}
               id={props.id}
               name={props.id}
               defaultValue={props.value}
               onBlur={(e) => handleChange(e.target.value)}
               allowClear
               disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
               placeholder={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? 'Borderless' : ''}
               style={{ borderRadius: 7, width: "100%", marginRight: '7px', marginLeft: '7px' }} 
            />
            {
               props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view")
                  ?
                  <></>
                  :
                  <Tooltip placement="right" title='Remove answer key'>
                     <CloseCircleTwoTone twoToneColor="red" style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => handleRemoveOption(props.id)} />
                  </Tooltip>
            }
         </div>
         {/* <Input
            // value={props.value}
            id={props.id}
            name={props.id}
            defaultValue={props.value}
            disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
            placeholder={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? 'Borderless' : ''}
            onBlur={(e) => handleChange(e.target.value)}
            allowClear
            addonBefore={
               <Checkbox
                  name={props.id}
                  checked={props.correct === "1" ? true : false}
                  onChange={handleCheckChange}
                  disabled={props.mode.includes("assign") ? true : false}
               />
            }
            addonAfter={
               props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view")
                  ?
                  <></>
                  :
                  <Tooltip placement="left" title='Remove Option'>
                     <CloseCircleTwoTone twoToneColor="red" style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => handleRemoveOption(props.id)} />
                  </Tooltip>
            }
            style={{ width: "100%", marginBottom: '7px', borderRadius: '7px' }} 
         /> */}
      </>
   )
}