import React, { useContext, useRef } from "react"
import { CanvasContext } from "../hooks/LessonPage"
import "./VideoImageElement.scss"

const VideoImageElement = props => {
  const { metadata, id, position, dimension, type } = props
  const { actions } = useContext(CanvasContext)
  const elementRef = React.useRef(null)
  const [showGrids, setShowGrids] = React.useState(false)

  const onClick = (e) => {
    e.stopPropagation();
    actions?.setTargetRef(elementRef)
    actions?.setCurrentElementId(id)
    var activeContent ={id : id, type : type , metadata : metadata, dimension : dimension, position : position }
    actions?.setActiveEditable(activeContent)    
  }

  const renderImage = () => {
    return (  
        <div  ref={elementRef} 
             onClick={(e) => onClick(e)}
              style={{position : "relative", width : "100%", height :"100%"}}>
             <img style={{position: "relative" , width: "100%", height : "100%"}} src={props.metadata.url}></img>
       </div>   
    )
  }

  const renderVideo = () =>
  {
    return(

            <div 
                ref={elementRef} 
                onClick={(e) => onClick(e)}            
                 style={{ position : "absolute", width : "100%", height :"100%"}}>
                   <img style={{position: "relative" , width: "100%", height : "100%"}} src={props.metadata.videoimage}></img>
                {/* <ReactPlayer url={props.url} width="100%"  height="100%" />  */}
            </div>   
    )     
  }

  const onEnter = (e,value) =>
  {
    e.stopPropagation()
    e.preventDefault()
    setShowGrids(value)
  }

  const renderElement = (type) =>
  {
    return(
          <>
            <div 
                ref={elementRef} 
                onClick={(e) => onClick(e)}       
                onMouseEnter={(e) =>  onEnter(e,true)}
                onMouseLeave={(e) =>  onEnter(e,false)}            
                // onDoubleClick={(e) => textDoubleClick(e)}                      
                 style={{ position : "absolute", 
                          left: position?.left || 0, 
                          top: position?.top || 0,
                          width: dimension?.width || 0, 
                          height: dimension?.height || 0,     
                          transform : dimension?.transform || "translate(1px, 1px)",                 
                          fontFamily: "Arial",
                          fontSize: "13px",
                          padding: 0,
                          float : "left",
                          backgroundColor : type === "WEB" ? "white" : "transparent",
                          borderRadius : type === "WEB" ? "10px" : "",
                          border : type === "WEB" ? "1px solid black" : "",
                          border: `2px solid ${
                            showGrids 
                              ? "#21DEE5"
                              : "transparent"
                          }`,                            
                        }}>

                   <img style={{position: "relative" , width: "100%", height : "100%"}} src={type === "VIDEO" ? props.metadata.videoimage : props.metadata.url}></img>
           
                   {type === "VIDEO"  && <span class="play-btn"></span>}
                   {type === "WEB"  && <div className="web-icon"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1024px-Google_%22G%22_logo.svg.png"/></div>}
                    
            </div>   
            </>
    )     
  } 

  return (
    renderElement(type)
  )
}

export default VideoImageElement
