import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Select, Input, Row, Col, Tag,
   Modal, Typography, Spin, Breadcrumb, Popconfirm, InputNumber, Form, Empty
} from 'antd';
// import * as AntDIcons from '@ant-design/icons';
import { SearchOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import SimpleBarReact from "simplebar-react";

const { Option } = Select;
const { Text } = Typography;

export function Levels() {
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [acadLevelList, setAcadLevelList] = useState([]);
   const [levelList, setLevelList] = useState([]);
   const [sectionList, setSectionList] = useState([]);
   const [levelListPromote, setLevelListPromote] = useState([]);
   const [searchLoading, setSearchLoading] = useState(false);

   const [updating, setUpdating] = useState(false);
   const [editModalVisible, setEditModalVisible] = useState(false);
   const [addModalVisible, setAddModalVisible] = useState(false);

   const [acadLevel, setAcadLevel] = useState(null);
   const [level, setLevel] = useState(null);
   const [levelDesc, setLevelDesc] = useState(null);
   const [levelSequence, setLevelSequence] = useState(null);
   const [levelPromote, setLevelPromote] = useState(null);
   const [levelActive, setLevelActive] = useState(null);
   const [selectedSections, setSelectedSections] = useState([]);

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      GetLevelList();
   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'promotion_level',
         key: 'promotion_level',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'assigned_sections',
         key: 'assigned_sections',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'academic_program',
         key: 'academic_program',
         hidden: true,
      },
      {
         title: 'Level',
         key: 'level_desc',
         ...getColumnSearchProps('level_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.level_desc}>
               <Text>
                  {
                     payload.level_desc
                  }
               </Text>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Education Level',
         key: 'academic_program_desc',
         ...getColumnSearchProps('academic_program_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.academic_program_desc}>
               <Text>
                  {
                     payload.academic_program_desc
                  }
               </Text>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Sequence',
         dataIndex: 'sort_order',
         key: 'sort_order',
         width: '8%'
      },
      {
         title: 'Promotion Level',
         key: 'promote_to_level_desc',
         ...getColumnSearchProps('promote_to_level_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.promote_to_level_desc}>
               <Text>
                  {
                     payload.promote_to_level_desc
                  }
               </Text>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Sections Assigned',
         key: 'assigned_sections_desc',
         ...getColumnSearchProps('assigned_sections_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.assigned_sections_desc}>
               <Text>
                  {/* {
                     payload.assigned_sections_desc
                  } */}
                  {
                     (payload.assigned_sections_desc !== undefined && payload.assigned_sections_desc !== "" && payload.assigned_sections_desc !== null) &&
                     <div dangerouslySetInnerHTML={{ __html: payload.assigned_sections_desc.replace(new RegExp(',', 'g'), '<br />') }} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} />
                  }
               </Text>
            </Tooltip>
         ),
         width: '28%'
      },
      {
         title: 'Active',
         key: 'is_active',
         dataIndex: 'is_active',
         sorter: (a, b) => { return a.is_active.localeCompare(b.is_active) },
         render: is_active => (
            <Tag style={{ fontSize: 12 }} color={is_active === 'no' ? 'red' : 'green'}>
               {is_active.toUpperCase()}
            </Tag>
         ),
         width: '6%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Edit Level'>
                     <EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEdit(payload.id, payload.level_desc, payload.is_active, payload.sort_order, payload.promote_to_level, payload.assigned_sections, payload.academic_program)} />
                  </Tooltip>
                  {/* <Tooltip placement="top" title='Remove Level'>
                     <Popconfirm
                        title="Are you sure you want to remove this level?"
                        onConfirm={() => handleRemove(payload.id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip> */}
               </Space>
            </>
         },
         width: '6%'
      },
   ].filter(item => !item.hidden);

   const section_columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: 'Section',
         key: 'section_desc',
         ...getColumnSearchProps('section_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.section_desc}>
               <Text>
                  {
                     payload.section_desc
                  }
               </Text>
            </Tooltip>
         ),
         width: '100%'
      },
   ].filter(item => !item.hidden);

   const GetAcadProgramList = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/acadproglist`).then((response) => {
         if (response.data) {
            const data = response.data;

            setAcadLevelList(data);
         }
      }, (error) => {
         setSearchLoading(false);
         // console.log(error.message);
      });
   }
   const GetLevelList = () => {
      setSearchLoading(true);

      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getlevellist`).then((response) => {
         if (response.data) {
            const data = response.data;

            var sectionlist = [];
            data.map((item) => {
               let obj = { 'value': item.id, 'name': item.level_desc }
               sectionlist.push(obj);
            });

            setLevelListPromote(sectionlist);

            setLevelList(data);
            setSearchLoading(false);
         }
      }, (error) => {
         setSearchLoading(false);
         console.log(error.message);
      });
   }

   const GetSectionList = () => {
      setSearchLoading(true);

      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getsectionlist`).then((response) => {
         if (response.data) {
            const data = response.data;

            for (var i = 0; i < data.length; i++) {
               data[i].key = data[i].id;
            }

            setSectionList(data);

            setSearchLoading(false);
         }
      }, (error) => {
         setSearchLoading(false);
         console.log(error.message);
      });
   }

   const handleAddNewLevel = () => {
      GetAcadProgramList();
      GetSectionList();
      setLevel(null);
      setLevelDesc(null);
      setLevelActive(null);
      setLevelSequence(null);
      setLevelPromote(null);
      setSelectedSections(null);

      setAddModalVisible(true);
   }

   const handleEdit = (id, desc, active, sequence, promote_level, sections, acad_program) => {
      GetAcadProgramList();
      GetSectionList();
      setLevel(id);
      setLevelDesc(desc);
      setLevelActive(active);
      setLevelSequence(sequence);
      setLevelPromote(promote_level);
      setAcadLevel(acad_program);
      setSelectedSections(sections !== null ? sections.split(',').map(Number) : []);
      setEditModalVisible(true);
   }

   const handleSave = () => {
      if (levelDesc !== null && acadLevel !== null && levelSequence !== null && selectedSections !== null &&
         levelDesc !== '' && acadLevel !== '' && levelSequence !== '' && selectedSections !== '') {
         setUpdating(true);

         var levelData = {
            level_desc: levelDesc,
            sort_order: levelSequence,
            promote_to_level: levelPromote === null ? 100 : levelPromote,
            assigned_sections: selectedSections !== null ? selectedSections.join(',') : '',
            academic_program: acadLevel,
         }

         var url = `${process.env.REACT_APP_API_MANAGEMENT}/createlevel`;

         axios.post(url, levelData).then((response) => {
            if (response.data) {
               const data = response.data;

               GetLevelList();
               setUpdating(false);
               // setEditModalVisible(false);
               // setAddModalVisible(false);

               if (data.Code === 1) {
                  Modal.error({
                     title: 'Level Management',
                     content: 'Ooops! It seems that there was a problem encountered while processing your request.',
                     centered: true,
                  });
               }
               else {
                  Modal.success({
                     title: 'Level Management',
                     content: 'Add successful.',
                     centered: true,
                     onOk: () => { handleCancel() },
                  });
               }

               setUpdating(false);
            }
         }, (error) => {
            setUpdating(false);
            Modal.error({
               title: 'Level Management',
               content: 'Ooops! It seems that there was a problem encountered while processing your request.',
               centered: true,
            });
         });
      } else {
         Modal.info({
            title: 'Student Management',
            content: (
               <>
                  Please complete the folowing before saving:
                  <ul>
                     <li>
                        New Grade Level
                     </li>
                     <li>
                        Education Level
                     </li>
                     <li>
                        Sequence Order
                     </li>
                     <li>
                        Assign Sections
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   const handleCancel = () => {
      setEditModalVisible(false);
      setAddModalVisible(false);
   }

   const handleUpdate = () => {
      if (levelDesc !== null && acadLevel !== null && levelSequence !== null && selectedSections !== null &&
         levelDesc !== '' && acadLevel !== '' && levelSequence !== '' && selectedSections !== '') {
         setUpdating(true);

         var levelData = {
            id: level,
            level_desc: levelDesc,
            is_active: levelActive,
            sort_order: levelSequence,
            promote_to_level: levelPromote === null ? 100 : levelPromote,
            assigned_sections: selectedSections.join(','),
            academic_program: acadLevel,
         }

         var url = `${process.env.REACT_APP_API_MANAGEMENT}/updatelevel`;

         axios.post(url, levelData).then((response) => {
            if (response.data) {
               const data = response.data;

               GetLevelList();
               setUpdating(false);
               // setEditModalVisible(false);
               // setAddModalVisible(false);

               if (data.Code === 1) {
                  Modal.error({
                     title: 'Level Management',
                     content: 'Ooops! It seems that there was a problem encountered while processing your request.',
                     centered: true,
                  });
               }
               else {
                  Modal.success({
                     title: 'Level Management',
                     content: 'Update successful.',
                     centered: true,
                     onOk: () => { handleCancel() },
                  });
               }
            }
         }, (error) => {
            setUpdating(false);
            Modal.error({
               title: 'Level Management',
               content: 'Ooops! It seems that there was a problem encountered while processing your request.',
               centered: true,
            });
         });

         // GetLevelList();
         // setUpdating(false);
         // setEditModalVisible(false);
         // setAddModalVisible(false);
      } else {
         Modal.info({
            title: 'Student Management',
            content: (
               <>
                  Please complete the folowing before saving:
                  <ul>
                     <li>
                        Grade Level
                     </li>
                     <li>
                        Education Level
                     </li>
                     <li>
                        Sequence Order
                     </li>
                     <li>
                        Assign Sections
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   const handleRemove = (id) => {
      setUpdating(true);

      axios.post(`${process.env.REACT_APP_API_MANAGEMENT}/deletelevel?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;

            GetLevelList();
            setUpdating(false);
            // setEditModalVisible(false);
            // setAddModalVisible(false);

            if (data.Code === 1) {
               Modal.error({
                  title: 'Level Management',
                  content: 'Ooops! It seems that there was a problem encountered while processing your request.',
                  centered: true,
               });
            }
            else {
               Modal.success({
                  title: 'Level Management',
                  content: 'Remove successful.',
                  centered: true,
                  onOk: () => { handleCancel() },
               });
            }
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Level Management',
            content: 'Ooops! It seems that there was a problem encountered while processing your request.',
            centered: true,
         });
      });
   }

   const rowSelection = {
      selectedRowKeys: selectedSections,
      onChange: (selectedRowKeys, selectedRows) => {
         setSelectedSections(selectedRowKeys);
      },
   };

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Management</li>
                     <li><span>/</span></li>
                     <li className="active">Levels</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Management</Breadcrumb.Item>
                     <Breadcrumb.Item>Levels</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
            </div>

            <Row gutter={12}>
               <Col className="gutter-row" span={24} style={{ textAlign: "left" }}>
                  {/* <Tooltip placement="top" title='Create Mastery Assessment'> */}
                  <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => handleAddNewLevel()}>
                     <Text ellipsis style={{ color: "#fff" }}><PlusOutlined />&nbsp;Add New Level</Text>
                  </Button>
                  {/* </Tooltip> */}
               </Col>
            </Row>

            <Row gutter={12} style={{ marginTop: '15px' }} >
               <Col span={24}>
                  <div className="dashboard-item-inner3">
                     {
                        searchLoading
                           ?
                           <div className='center-empty-space'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                           :
                           (levelList.length > 0 && !searchLoading)
                              ?
                              <Table
                                 rowKey={'uid'}
                                 // loading={searchLoading}
                                 size='small'
                                 columns={columns}
                                 dataSource={levelList}
                                 pagination={false}
                                 scroll={{
                                    y: 'calc(100svh - 12rem)',
                                    x: '1000px'
                                 }}
                                 footer={() => 'Total Records: ' + levelList.length}
                              />
                              :
                              <div className='center-empty-space'>
                                 <Empty image="../images/brainee_mascot.png" description="No records found" />
                              </div>
                     }

                  </div>
               </Col>
            </Row>
         </div>

         {/* Modals */}
         <Modal
            title={"Add New Level"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            onOk={() => { handleSave() }}
            open={addModalVisible}
            width='40%'
            style={{ minWidth: '300px' }}
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Save'}
         >
            <Spin spinning={updating} tip={"Updating, please wait..."}>
               {/* <SimpleBarReact style={{ height: 'calc(100vh - 180px)' }}> */}
               <Form
                  layout={"vertical"}
                  colon={false}
                  style={{ width: '100%' }}
               >
                  <Row gutter={12}>
                     <Col sm={24} md={12} lg={12}>
                        <Form.Item name="level" label="New Grade Level"
                           rules={[{
                              required: true,
                              message: 'Required'
                           }]}
                        >
                           <Input
                              allowClear
                              size='middle'
                              style={{ borderRadius: 7 }}
                              placeholder="Enter New Level"
                              onBlur={(event) => { setLevelDesc(event.target.value) }}
                           />
                        </Form.Item>
                     </Col>
                     <Col sm={24} md={12} lg={12}>
                        <Form.Item name="acadlevel" label="Education Level"
                           rules={[{
                              required: true,
                              message: 'Required'
                           }]}
                        >
                           <Select
                              showArrow
                              size='middle'
                              allowClear
                              placeholder="Select Education Level"
                              onChange={(val) => setAcadLevel(val)}
                              style={{ width: '100%' }}
                           >
                              {acadLevelList.map(({ value, name }, idx) => {
                                 return <Option value={value}>{name}</Option>
                              })}
                           </Select>
                        </Form.Item>
                     </Col>
                  </Row>

                  <Row gutter={12}>
                     <Col sm={24} md={12} lg={12}>
                        <Form.Item name="promotion" label="Promotion Level">
                           <Select
                              showArrow
                              size='middle'
                              allowClear
                              placeholder="Select Level"
                              onChange={(val) => setLevelPromote(val)}
                              style={{ width: '100%' }}
                           >
                              {levelListPromote.map(({ value, name }, idx) => {
                                 return <Option value={value}>{name}</Option>
                              })}
                           </Select>
                        </Form.Item>
                     </Col>
                     <Col sm={24} md={12} lg={12}>
                        <Form.Item name="sequence" label="Sequence Order">
                           <InputNumber
                              key={'uid'}
                              size='middle'
                              style={{ borderRadius: 7, width: "100%" }}
                              min="1"
                              onChange={(val) => setLevelSequence(val)}
                           />
                        </Form.Item>
                     </Col>
                  </Row>

                  <Text>Assign Sections</Text>
                  <Table
                     rowSelection={rowSelection}
                     size='small'
                     columns={section_columns}
                     dataSource={sectionList}
                     pagination={false}
                     // style={{ minWidth: '300px' }}
                     scroll={{
                        // x: '1000px',
                        y: 'calc(100vh - 27rem)',
                     }}
                  />
               </Form>
               {/* </SimpleBarReact> */}
            </Spin>
         </Modal>

         <Modal
            title={"Edit Level"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            onOk={() => { handleUpdate() }}
            open={editModalVisible}
            width='35%'
            style={{ minWidth: '300px' }}
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Update'}
         >
            <Spin spinning={updating} tip={"Updating, please wait..."}>
               <Form
                  layout={"vertical"}
                  colon={false}
                  style={{ width: '100%' }}
               >
                  <Row gutter={12}>
                     <Col sm={24} md={12} lg={12}>
                        <Form.Item name="level" label="New Grade Level"
                           rules={[{
                              required: true,
                              message: 'Required'
                           }]}
                        >
                           <Input
                              allowClear
                              size='middle'
                              style={{ borderRadius: 7 }}
                              placeholder="Enter New Level"
                              onBlur={(event) => { setLevelDesc(event.target.value) }}
                              defaultValue={levelDesc}
                           />
                        </Form.Item>
                     </Col>
                     <Col sm={24} md={12} lg={12}>
                        <Form.Item name="acadlevel" label="Education Level"
                           rules={[{
                              required: true,
                              message: 'Required'
                           }]}
                        >
                           <Select
                              showArrow
                              size='middle'
                              allowClear
                              placeholder="Select Education Level"
                              onChange={(val) => setAcadLevel(val)}
                              style={{ width: '100%' }}
                              defaultValue={acadLevel}
                           >
                              {acadLevelList.map(({ value, name }, idx) => {
                                 return <Option value={value}>{name}</Option>
                              })}
                           </Select>
                        </Form.Item>
                     </Col>
                  </Row>

                  <Row gutter={12}>
                     <Col sm={24} md={12} lg={12}>
                        <Form.Item name="promotion" label="Promotion Level">
                           <Select
                              showArrow
                              size='middle'
                              allowClear
                              placeholder="Select Level"
                              onChange={(val) => setLevelPromote(val)}
                              style={{ width: '100%' }}
                              defaultValue={levelPromote}
                           >
                              {levelListPromote.map(({ value, name }, idx) => {
                                 return <Option value={value}>{name}</Option>
                              })}
                           </Select>
                        </Form.Item>
                     </Col>
                     <Col sm={24} md={12} lg={12}>
                        <Form.Item name="sequence" label="Sequence Order">
                           <InputNumber
                              key={'uid'}
                              size='middle'
                              style={{ borderRadius: 7, width: "100%" }}
                              min="1"
                              onChange={(val) => setLevelSequence(val)}
                              defaultValue={levelSequence}
                           />
                        </Form.Item>
                     </Col>
                  </Row>

                  <Text>Assign Sections</Text>
                  <Table
                     rowSelection={rowSelection}
                     size='small'
                     columns={section_columns}
                     dataSource={sectionList}
                     pagination={false}
                     // style={{ minWidth: '300px' }}
                     scroll={{
                        // x: '1000px',
                        y: 'calc(100vh - 27rem)',
                     }}
                  />
               </Form>
            </Spin>
         </Modal>
      </>
   );
}