import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Select, Input, Row, Col, Tag,
   Modal, Typography, Spin, Breadcrumb, Popconfirm, InputNumber, Form
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../../misc/Utils';
// import Enums from '../../../misc/Enums';

const { Text } = Typography;

export function AttendanceList(props) {
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [listLoading, setListLoading] = useState(false);
   const [attendanceList, setAttendanceList] = useState([]);
   const [attendedCount, setAttendedCount] = useState(0);

   useEffect(() => {
      Utils.verifyJwt();

      getAssignedtStudentList(props.id);
   }, [props.id]);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'section',
         key: 'section',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'level',
         key: 'level',
         hidden: true,
      },
      {
         title: 'Student Name',
         key: 'student_name',
         ...getColumnSearchProps('student_name'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.student_name}>
               <Text>
                  {
                     payload.student_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                  }
               </Text>
            </Tooltip>
         ),
         width: '50%'
      },
      {
         title: 'Level',
         key: 'level_desc',
         ...getColumnSearchProps('level_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.level_desc}>
               <Text>
                  {
                     payload.level_desc
                  }
               </Text>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Section',
         key: 'section_desc',
         ...getColumnSearchProps('section_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.section_desc}>
               <Text>
                  {
                     payload.section_desc
                  }
               </Text>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Attended',
         key: 'attended',
         dataIndex: 'attended',
         sorter: (a, b) => { return a.attended.localeCompare(b.attended) },
         render: attended => (
            <Tag style={{ fontSize: 12 }} color={attended === 'no' ? 'red' : 'green'}>
               {attended.toUpperCase()}
            </Tag>
         ),
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const getAssignedtStudentList = (id) => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_CPH}/getdiagnosticassignedstudents?id=${id}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;

            let attendCount = data.filter(p => p.attended === 'yes');

            setAttendedCount(attendCount);
            setListLoading(false);
            setAttendanceList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   return (
      <>
         <div className="dashboard-item-inner3">
            <Table
               rowKey={'uid'}
               loading={listLoading}
               size='small'
               columns={columns}
               dataSource={attendanceList}
               pagination={false}
               scroll={{
                  // y: 'calc(100svh - 20rem)',
                  x: '500px',
                  y: 'calc(100vh - 18.5rem)',
               }}
               // footer={() => 'Total Records: ' + attendanceList.length}
               footer={() =>
                  <Row>
                     <Col span={12}>{'Total Records: ' + attendanceList.length}</Col>
                     <Col span={12} style={{ textAlign: 'right' }}>{'Attended: ' + attendedCount.length}</Col>
                  </Row>
               }
            />
         </div>
      </>
   );
}