import React, { useState, useEffect } from 'react'
import { Button, Col, InputNumber, Radio, Row, Space, Typography, DatePicker, Divider, Descriptions, Tree, Select, message, Empty } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import StickyBox from "react-sticky-box";
import { Levels } from '../../constants'
// import Utils from '../../misc/Utils';
// import Enums from '../../misc/Enums';

const { Text, Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select

export default function BraineeAssignTool(props) {
   // const assOptionState = useSelector(state => state.assOptionState)
   // const dispatch = useDispatch();   
   const [expandedKeys, setExpandedKeys] = useState([]);
   const [checkedKeys, setCheckedKeys] = useState([]);
   const [selectedKeys, setSelectedKeys] = useState([]);
   // const [autoExpandParent, setAutoExpandParent] = useState(true);
   const [treeData, setTreeData] = useState([]);

   const dateFormat = 'MMM D, YYYY h:mm a';
   const customFormat = value => `${value.format(dateFormat)}`;

   var ass_options = props.options;

   useEffect(() => {
      // Utils.verifyJwt();

      if (props.options.level !== undefined && props.options.level !== null && props.options.level !== 0) {
         ass_options.level = props.options.level;
         setExpandedKeys([]);

         axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/studentlistperleveljson?level=${props.options.level}`).then((response) => {
            if (response.data) {
               const data = response.data;

               setTreeData(data);

               // var students = props.options.assigned_students.split(',');
               // const finallist = students.map(e => 's_' + e);
               // setCheckedKeys(finallist);

               var students = props.options.assigned_students_with_section.split(',');
               setCheckedKeys(students);
            }
         }, (error) => {
            console.log(error.message);
         });

         props.parentCallback(ass_options);
      }

   }, [props.options.id]);

   const onExpand = (expandedKeysValue) => {
      //console.log('onExpand', expandedKeysValue); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.

      setExpandedKeys(expandedKeysValue);
   };

   const onCheck = (checkedKeysValue) => {
      //console.log('onCheck', checkedKeysValue);
      setCheckedKeys(checkedKeysValue);

      //-- (start) with section ------------------------------
      const selectedStudsWithSection = checkedKeysValue.filter((e) => {
         return e.includes('_');
         //return e.includes('|');
      });

      ass_options.assigned_students_with_section = selectedStudsWithSection.join(',');

      //-- Remove the section part
      for (var i = 0; i < selectedStudsWithSection.length; i++) {
         selectedStudsWithSection[i] = selectedStudsWithSection[i].substring(selectedStudsWithSection[i].indexOf('_') + 1, selectedStudsWithSection[i].length);
         //selectedStudsWithSection[i] = selectedStudsWithSection[i].substring(selectedStudsWithSection[i].indexOf('|') + 1, selectedStudsWithSection[i].length);
      }

      var selected = [...new Set(selectedStudsWithSection)]; //-- Remove duplicate students
      ass_options.assigned_students = selected.join(',');
      //-- (end) with section ------------------------------

      // //-- (start) without section ------------------------------
      // const selectedStuds = checkedKeysValue.filter((e) => {
      //    return e.includes('s_');
      // });

      // ass_options.assigned_students = selectedStuds.join(',');
      // ass_options.assigned_students = ass_options.assigned_students.replace(/s_/g, '');
      // //-- (end) without section ------------------------------

      props.parentCallback(ass_options);
   };

   const onSelect = (selectedKeysValue, info) => {
      //console.log('onSelect', info);
      setSelectedKeys(selectedKeysValue);
   };

   const handleChange = (event) => {
      props.parentCallback("update", event.target.value, props.id);
   }

   const handleClose = (event) => {
      // props.parentCallback(ass_options);
      props.backCallback(ass_options);
   }

   const onDateTimeChange = (value, dateString) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      ass_options.start_date = dateString[0];
      ass_options.end_date = dateString[1];
      props.parentCallback(ass_options);
   }

   const onDurationChange = (val) => {
      ass_options.duration = val.toString();
      // ass_options = dispatch(update_ass_option_data(assOptions));
      // ass_options = assOptionState;
      props.parentCallback(ass_options);
   }

   const onPercentChange = (val) => {
      ass_options.passing_percentage = val.toString();
      props.parentCallback(ass_options);
   }

   // const onTermChange = (val) => {
   //    ass_options.term = val.toString();
   //    props.parentCallback(ass_options);
   // }

   const onRandomizeQuestionsChange = (val) => {
      ass_options.randomize_questions = parseInt(val.target.value);
      props.parentCallback(ass_options);
   }

   const onAttemptsChange = (val) => {
      ass_options.allowed_attempts = val.toString();
      props.parentCallback(ass_options);
   }

   const onShowResultChange = (e) => {
      ass_options.allow_result_viewing = e.target.value;
      props.parentCallback(ass_options);
   }

   const onTimerChange = (e) => {
      ass_options.enable_timer = e.target.value;
      props.parentCallback(ass_options);
   }

   const handleSave = () => {
      localStorage.setItem("_assoptions", JSON.stringify(ass_options));
      console.log(localStorage.getItem("_assoptions"));
      props.saveCallback(ass_options);
   }

   const handleLevelChange = (val) => {
      // setAssLevel(val);
      ass_options.level = val;
      setTreeData([]);
      setCheckedKeys([]);
      setExpandedKeys([]);

      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/studentlistperleveljson?level=${val}`).then((response) => {
         if (response.data) {
            const data = response.data;
            setTreeData(data);
         }
      }, (error) => {
         console.log(error.message);
      });

      props.parentCallback(ass_options);
   }

   return (
      <>
         <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>{props.title}</Title>

         <Divider orientation="left">
            <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Set Options</Title>
         </Divider>

         <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", padding: "0px" }} column={4}>
            <Descriptions.Item label={"Duration in Minutes"} span={2} style={{ textAlign: "center" }} >
               <InputNumber
                  style={{ borderRadius: 7, width: "100%" }}
                  // defaultValue={props.options.duration}
                  value={ass_options.duration}
                  min="1"
                  onChange={(val) => onDurationChange(val)} />
            </Descriptions.Item>
            <Descriptions.Item label={"Passing Percentage %"} span={2} style={{ textAlign: "center" }} >
               <InputNumber
                  style={{ borderRadius: 7, width: "100%" }}
                  // defaultValue={props.options.passing_percentage}
                  value={ass_options.passing_percentage}
                  min="30"
                  onChange={(val) => onPercentChange(val)} />
            </Descriptions.Item>
            <Descriptions.Item label={"Allowed Attempts"} span={2} style={{ textAlign: "center" }} >
               <InputNumber
                  style={{ borderRadius: 7, width: "100%" }}
                  // defaultValue={props.options.allowed_attempts}
                  value={ass_options.allowed_attempts}
                  min="1"
                  onChange={(val) => onAttemptsChange(val)} />
            </Descriptions.Item>
            <Descriptions.Item label={ass_options.mode === 2 || ass_options.mode === '2' ? 'Randomize Questions' : ''} span={2} style={{ textAlign: "center" }}>
               {
                  (ass_options.mode === 2 || ass_options.mode === '2') &&
                  <Radio.Group
                     name='randomize_question'
                     defaultValue={'0'}
                     value={ass_options.randomize_questions.toString()}
                     buttonStyle='solid'
                     onChange={(e) => onRandomizeQuestionsChange(e)}
                  >
                     <Radio.Button value="1">Yes</Radio.Button>
                     <Radio.Button value="0">No</Radio.Button>
                  </Radio.Group>
               }
            </Descriptions.Item>
            <Descriptions.Item label={"Show Result on Submit"} style={{ textAlign: "center" }} span={2}>
               <span style={{ textAlign: "center" }}>
                  <Radio.Group
                     name='show_result'
                     // defaultValue={props.options.allow_result_viewing} 
                     value={ass_options.allow_result_viewing}
                     buttonStyle='solid'
                     onChange={(e) => onShowResultChange(e)}
                  >
                     <Radio.Button value="1">Yes</Radio.Button>
                     <Radio.Button value="0">No</Radio.Button>
                  </Radio.Group>
               </span>
            </Descriptions.Item>
            <Descriptions.Item label={"Timer"} style={{ textAlign: "center" }} span={2}>
               <Radio.Group
                  name='timer'
                  // defaultValue={props.options.enable_timer} 
                  value={ass_options.enable_timer}
                  buttonStyle='solid'
                  onChange={(e) => onTimerChange(e)}
               >
                  <Radio.Button value="1">On</Radio.Button>
                  <Radio.Button value="0">Off</Radio.Button>
               </Radio.Group>
            </Descriptions.Item>
            <Descriptions.Item label={"Schedule"} style={{ textAlign: "center" }} span={4}>
               <RangePicker
                  style={{ width: "100%" }}
                  showTime={{ format: 'hh:mm A', use12Hours: true }}
                  format={customFormat}
                  onChange={onDateTimeChange}
                  // defaultValue={
                  //    [props.options.start_date !== "" ? moment(props.options.start_date) : "", 
                  //    props.options.end_date !== "" ? moment(props.options.end_date) : ""]
                  // }
                  value={
                     [ass_options.start_date !== "" ? moment(ass_options.start_date) : "",
                     ass_options.end_date !== "" ? moment(ass_options.end_date) : ""]
                  }
               />
            </Descriptions.Item>
         </Descriptions>

         <Divider orientation="left">
            <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Assign Students</Title>
         </Divider>

         <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", padding: "0px" }} column={4}>
            <Descriptions.Item label={"Select Students"} span={4} style={{ textAlign: "center" }} >
               <Select
                  showArrow
                  size='default'
                  style={{ width: '100%', textAlign: "left" }}
                  allowClear
                  placeholder="Select Level"
                  onChange={(val) => handleLevelChange(val)}
                  value={props.options.level}
               >
                  {Levels.map(({ value, name }, idx) => {
                     return <Option value={value}>{name}</Option>
                  })}
               </Select>
               {props.options.level !== undefined && props.options.level !== null && props.options.level !== 0 && treeData.length > 0
                  ?
                  <Tree
                     checkable
                     // showLine={true}
                     // showIcon={false}
                     onExpand={onExpand}
                     expandedKeys={expandedKeys}
                     autoExpandParent={true}
                     onCheck={onCheck}
                     checkedKeys={checkedKeys}
                     onSelect={onSelect}
                     selectedKeys={selectedKeys}
                     treeData={treeData}
                     style={{ paddingBottom: "15px", paddingTop: "15px" }}
                     defaultExpandParent={true}
                  />
                  :
                  // <Empty
                  //    image="../images/brainee_mascot.png"
                  //    description={'No records found'}
                  //    style={{ paddingTop: '5px' }}
                  // />
                  <></>
               }
            </Descriptions.Item>
            {/* <Descriptions.Item label={"Select Students"} span={4} style={{textAlign:"center"}} >
               
            </Descriptions.Item> */}
         </Descriptions>

         <StickyBox bottom={true} style={{ zIndex: 1000 }}>
            <Button className='button-shadow' type='primary' block shape='round' onClick={props.mode === "drawer" ? handleSave : handleClose}>
               <Text ellipsis style={{ color: "#fff" }}>{props.mode === "drawer" ? <SaveOutlined /> : <ArrowLeftOutlined />}&nbsp;{props.mode === "drawer" ? "Save" : "Back"}</Text>
            </Button>
         </StickyBox>
      </>
   )
}