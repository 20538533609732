/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Empty, Breadcrumb, Descriptions } from 'antd';
import './MasteryAssessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
// import { CardRequestHelper } from './Helpers';
import { CardRequestHelper } from '../../helper/AQHelper';
import { useSelector } from 'react-redux';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import Split from 'react-split';
import Wysiwyg from '../../components/wysiwyg/wysiwyg';

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];

export function MasteryPreviewManual(props) {
   const location = useLocation();
   const navigate = useNavigate();

   const masteryrec = useSelector(state => state.masteryrec);
   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [answerSheetData, setAnswerSheetData] = useState(() => {
      var answer_sheet = JSON.parse(localStorage.getItem(user_id + "mastery_asd"));
      if (answer_sheet !== null) return answer_sheet;
      else return [];
   });

   const [instruction, setInstruction] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();
      qcardIdx = 1;
      idxCol = [];

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'co-teacher')
         navigate('/home');


      qcardIdx = 1;
      idxCol = [];

      switch (location.state.type) {
         case "formative_1":
            setAnswerSheetData(JSON.parse(masteryrec.formative_1));
            setInstruction(masteryrec.formative_1_instruction)
            break;
         case "formative_2":
            setAnswerSheetData(JSON.parse(masteryrec.formative_2));
            setInstruction(masteryrec.formative_2_instruction)
            break;
         case "formative_3":
            setAnswerSheetData(JSON.parse(masteryrec.formative_3));
            setInstruction(masteryrec.formative_3_instruction)
            break;
         case "summative":
            setAnswerSheetData(JSON.parse(masteryrec.summative));
            break;
         default:
            setAnswerSheetData("");
      }
   }, [masteryrec.id]);

   const handleCardRequest = (method, type, data, idx) => {
      var asd = CardRequestHelper(method, type, data, idx, answerSheetData);

      // setQuestionTemplate(template);
      setAnswerSheetData(asd);

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div className="dashboard-item">
                  <div className="dashboard-item-inner">
                     <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                        <Breadcrumb.Item>
                           <Link to="/home">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                        <Breadcrumb.Item>
                           <a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subject</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                           <a href="#" onClick={() => navigate(`/masteryassessmentsubject?id=${location.state.subjectid}&name=${location.state.subject}`)}>Mastery Assessment ({location.state.subject})</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                           <a href="#" onClick={() => navigate(`/masteryassessment/preview?id=${masteryrec.id}&subject=${location.state.subjectid}&subjectname=${location.state.subject}&mode=${location.state.mode}`)}>Preview</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{location.state.title}</Breadcrumb.Item>
                     </Breadcrumb>
                  </div>

                  {answerSheetData.length > 0 ?
                     <div className='answer-sheet-content2'>
                        <SimpleBarReact>
                           <ul className="questions">
                              {answerSheetData.map(({ id, type, points, correct, option_labels, question, image_url, template, question_image, question_audio, question_video }, idx) => {

                                 if (type === "section") {
                                    qcardIdx = 1;
                                    idxCol.push(sectionIdx);
                                    sectionIdx++;
                                 } else {
                                    idxCol.push(qcardIdx);
                                    qcardIdx++;
                                 }

                                 return (
                                    <li>
                                       <BraineeQuestionCard
                                          key={id}
                                          index={idxCol[idx]}
                                          id={idx}
                                          type={type}
                                          points={points}
                                          correct={correct}
                                          option_labels={option_labels}
                                          question={question}
                                          image_url={image_url}
                                          question_type={'2'}
                                          mode={'preview'}
                                          parentCallback={handleCardRequest}
                                          card_bg={location.state.card_bg}
                                          template={template}
                                          question_image={question_image}
                                          question_audio={question_audio}
                                          question_video={question_video}
                                          subject_name={location.state.subject}
                                          assessment_type={'mastery'}
                                       />
                                    </li>
                                 );
                              })}
                           </ul>
                        </SimpleBarReact>
                     </div>
                     :
                     <div className='temp-answer-sheet-content2'>
                        <Empty description='No items added.' />
                     </div>
                  }
               </div>

               <div>
                  <div className="notice-item">
                     <div className='answer-sheet-content'>
                        {
                           instruction !== null &&
                           <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", width: "100%" }} column={4}>
                              <Descriptions.Item label={"Instruction"} span={4} style={{ width: "50%" }} >
                                 <Wysiwyg
                                    content={instruction}
                                    height={300}
                                    contentHeight={235}
                                    disabled={true}
                                 />
                              </Descriptions.Item>
                           </Descriptions>
                        }
                     </div>
                  </div>
               </div>
            </Split>
         </div>
      </>
   )
}
