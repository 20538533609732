export const LessonModel = {
    lessonid: "",
    title: "",
    techearName: "",
    subject: "", 
    overview :"",   
    term: "",
    availabilitydate: [],
    display_availabilitydate: "",
    educationallevel: "",
    studentviewing: "",
    lessonsharing: "",
    gradelevel: "",
    sectionlist: [],
    assigned_students: "",
    filteredsection: [
        { 
          code: '000',
          label: 'NO RECORD',
          value: 'NORECORD' 
        }
      ],
    learningtype: "",
    learningmode: "",
    conference: "",
    conferencelink: "",
    classroomid: "",
    learningplan: "",
    type: "",
    sections: [
        // {
        // id : '3',
        // title : 'Topic',
        // contents: [
        //     {
        //         id : 31,
        //         type : 'video',
        //         title : 'Associative Law of addition',
        //         links : [
        //             {
        //                 id: 32, 
        //                 type : "videolink",
        //                 url : 'https://www.youtube.com/watch?v=oTKVXJ7TcbA',
        //                 image : 'https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2019/11/23171201/Subject-combination.jpg'
        //             },
        //             {
        //                 id: 33, 
        //                 type : "videolink",
        //                 url : 'https://www.youtube.com/watch?v=oTKVXJ7TcbA',
        //                 image : 'https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2019/11/23171201/Subject-combination.jpg'
        //             }
        //         ]
        //     }
        // ]
        // },
        // {
        // id : '4',
        // title : 'Topic2',
        // contents: [
        //     {
        //         id : 41,
        //         type : 'video',
        //         title : 'Associative Law of addition',
        //         links : [
        //             {
        //                 id: 42, 
        //                 type : "videolink",
        //                 url : 'https://www.youtube.com/watch?v=oTKVXJ7TcbA',
        //                 image : 'https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2019/11/23171201/Subject-combination.jpg'
        //             }
        //         ]
        //     }
        // ]
        // }, 
        // {
        //     id : '5',
        //     title : 'Topic3',
        //     contents: [
        //     ]
        //     },     
        
        
        ///
     {
        id : '',
        title : '',
        contents: [
        ]
        }         
        ///
    ],
};