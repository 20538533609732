/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
   Empty, Input, Tabs, Table, Typography, Space, Tooltip, Button, Select, Row, Col,
   Popconfirm, Drawer, Modal, Spin, Breadcrumb, Upload, Image, Descriptions
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import SimpleBarReact from "simplebar-react";
import axios from 'axios';
import uuid from 'react-uuid';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import Iframe from 'react-iframe';
import StickyBox from "react-sticky-box";
import DiagnosticAssignTool from '../../components/assessment/DiagnosticAssignTool';
import { AttendanceList, EditAttendance } from './components'
import ProcessingModal from '../../components/processing/ProcessingModal';
import ViewInstructions from '../MasteryAssessmentPage/Components/ViewInstructions'; //'..//Components/ViewInstructions';

const { TabPane } = Tabs;
const { Text, Paragraph } = Typography;
const { Option } = Select;

const { TextArea } = Input;

let g_instruction_data = {
   instruction_id: null,
   instruction_desc: null,
   instruction_docs: null,
   instruction_doc_names: null,
};

export function DiagnosticAssessment() {
   // const location = useLocation();
   const navigate = useNavigate();

   const search = window.location.search;
   const query = new URLSearchParams(search);
   const diagnosticType = query.get('type');

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [assignVisible, setAssignVisible] = useState(false);
   const [selectedLevel, setSelectedLevel] = useState(-1);
   const [selectedSubject, setSelectedSubject] = useState(null);
   const [activeTab, setActiveTab] = useState('1');
   const [masterList, setMasterList] = useState([]);
   const [assignedList, setAssignedList] = useState([]);
   const [deleteList, setDeletedList] = useState([]);
   const [listLoading, setListLoading] = useState(false);
   const [fileURL, setFileURL] = useState(null);
   const [password, setPassword] = useState(null);
   const [diagnosticID, setDiagnosticID] = useState(null);
   const [message, setMessage] = useState(null);
   const [instructionDocs, setInstructionDocs] = useState(null);
   const [instructionDocNames, setInstructionDocNames] = useState(null);

   const [showCreateDiagnostic, setShowCreateDiagnostic] = useState(false);
   const [showEditAssessment, setShowEditAssessment] = useState(false);
   const [showPreview, setShowPreview] = useState(false);

   const [updating, setUpdating] = useState(false);

   const [assOptions, setAssOptions] = useState(null);
   const [assTitle, setAssTitle] = useState(null);

   const [lookUps, setLookUps] = useState({
      GradeLevels: null,
      SubjectList: null,
   });

   const [uploadFileList, setUploadFileList] = useState([]);
   const [instructionsURL, setInstructionsURL] = useState(null);
   const [showInstructions, setShowInstructions] = useState(false);
   const [showEditLink, setShowEditLink] = useState(false);
   const [assignedBy, setAssignedBy] = useState(null);
   const [showAttendance, setShowAttendance] = useState(false);
   const [assignId, setAssignId] = useState(null);
   const [showCheckAttendance, setShowCheckAttendance] = useState(false);
   const [attendedStudents, setAttendedStudents] = useState([]);
   const [savingAttendance, setSavingAttendance] = useState(false);
   const [isProcessing, setIsProcessing] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      window.addEventListener('error', e => {
         if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
            const resizeObserverErrDiv = document.getElementById(
               'webpack-dev-server-client-overlay-div'
            );
            const resizeObserverErr = document.getElementById(
               'webpack-dev-server-client-overlay'
            );
            if (resizeObserverErr) {
               resizeObserverErr.setAttribute('style', 'display: none');
            }
            if (resizeObserverErrDiv) {
               resizeObserverErrDiv.setAttribute('style', 'display: none');
            }
         }
      });

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      getLookUps();
   }, [diagnosticType]);

   const getLookUps = () => {
      // axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterygradelevels`).then((response) => {
      //    if (response.data) {
      //       const data = response.data;
      //       var levels = [];
      //       data.map((item) => {
      //          let obj = { 'value': item.value, 'name': item.name }
      //          levels.push(obj);
      //       });

      //       setLookUps({
      //          MasteryGradeLevels: levels,
      //       });
      //    }
      // }, (error) => {
      //    console.log(error.message);
      // });
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/standardlevellist`).then((response) => {
         if (response.data) {
            const data = response.data;
            var tmp_levels = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               tmp_levels.push(obj);
            });

            axios.get(`${process.env.REACT_APP_API_LOOKUP}/getdiagnosticsubjects`).then((response) => {
               if (response.data) {
                  const data = response.data;
                  var tmp_subjects = [];
                  data.map((item) => {
                     let obj = { 'value': item.value, 'name': item.name }
                     tmp_subjects.push(obj);
                  });

                  setLookUps({
                     GradeLevels: tmp_levels,
                     SubjectList: tmp_subjects,
                  });

                  getMasterList();
               }
            }, (error) => {
               console.log(error.message);
            });


         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const masterListColumns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'file_url',
         key: 'file_url',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'instruction_file_url',
         key: 'instruction_file_url',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'type',
         key: 'type',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'level',
         key: 'level',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'password',
         key: 'password',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: title => (
            <Tooltip placement="top" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '30%'
      },
      // {
      //    title: 'File URL',
      //    dataIndex: 'file_url',
      //    key: 'file_url',
      //    ellipsis: {
      //       showTitle: true,
      //    },
      //    render: file_url => (
      //       <Tooltip placement="top" title={file_url}>
      //          <span style={{ cursor: "default" }}>{file_url}</span>
      //       </Tooltip>
      //    ),
      //    width: '30%'
      // },
      {
         title: 'Level',
         dataIndex: 'level_desc',
         key: 'level_desc',
         sorter: (a, b) => { return a.level_desc.localeCompare(b.level_desc) },
         ...getColumnSearchProps('level_desc'),
         width: '10%'
      },
      {
         title: 'Subject',
         dataIndex: 'subject',
         key: 'subject',
         sorter: (a, b) => { return a.subject.localeCompare(b.subject) },
         ...getColumnSearchProps('subject'),
         render: subject => (
            <span style={{ textTransform: 'capitalize' }}>{subject}</span>
         ),
         width: '25%'
      },
      {
         title: 'Date Created',
         key: 'date_created',
         dataIndex: 'date_created',
         sorter: (a, b) => { return a.date_created.localeCompare(b.date_created) },
         width: '15%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  {user_role === 'Admin' &&
                     <>
                        <Tooltip placement="top" title='Edit'><AntDIcons.EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditDiagnosticAssessment(payload.id, payload.level, payload.title, payload.subject)} /></Tooltip>
                        <Tooltip placement="top" title='Assign'><AntDIcons.UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleAssignDiagnosticLink(payload.id, payload.title)} /></Tooltip>
                        <Tooltip placement="top" title='Remove'>
                           <Popconfirm
                              title="Are you sure you want to remove this Assessment?"
                              onConfirm={() => handleDeleteDiagnosticLink(payload.id, 1, 'main')}
                              okText="Yes"
                              cancelText="No"
                           >
                              <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                           </Popconfirm>
                        </Tooltip>
                     </>
                  }
                  {/* {
                     payload.instruction_file_url !== null && payload.instruction_file_url !== '' &&
                     <Tooltip placement="top" title='View Instructions'><AntDIcons.FileOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleViewInstructions(payload.id, payload.instruction_file_url)} /></Tooltip>
                  }
                  {
                     payload.file_url !== null && payload.file_url !== '' &&
                     <Tooltip placement="top" title='Preview'><AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handlePreviewDiagnosticLink(payload.id, payload.file_url)} /></Tooltip>
                  } */}

               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const assigned_ass_columns = [
      {
         title: 'Title',
         key: 'title',
         //dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: payload => (
            <Tooltip placement="top" title={payload.title}>
               {/* <span style={{ cursor: "default" }}>{payload.title}</span> */}
               <div style={{ width: "100%", cursor: "default" }}>
                  <b><Text>{payload.title}</Text></b>
                  <p style={{ marginBottom: '0px' }}>{payload.start_date} - {payload.end_date}</p>
               </div>
            </Tooltip>
         ),
         width: '30%'
      },
      {
         title: 'Level',
         dataIndex: 'level_desc',
         key: 'level_desc',
         sorter: (a, b) => { return a.level_desc.localeCompare(b.level_desc) },
         ...getColumnSearchProps('level_desc'),
         width: '8%'
      },
      {
         title: 'Subject',
         dataIndex: 'subject',
         key: 'subject',
         sorter: (a, b) => { return a.subject.localeCompare(b.subject) },
         ...getColumnSearchProps('subject'),
         render: subject => (
            <span style={{ textTransform: 'capitalize' }}>{subject}</span>
         ),
         width: '25%'
      },
      {
         title: 'Assigned By',
         dataIndex: 'assigned_by_name',
         key: 'assigned_by_name',
         sorter: (a, b) => { return a.assigned_by_name.localeCompare(b.assigned_by_name) },
         ...getColumnSearchProps('assigned_by_name'),
         width: '15%'
      },
      {
         title: 'Assigned To',
         key: 'section_desc',
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            // <Tooltip placement="topLeft" title={payload.section_desc}>
            <>
               {
                  (payload.section_desc !== null && payload.section_desc !== '') &&
                  <Text>
                     <div dangerouslySetInnerHTML={{ __html: payload.section_desc.replace(new RegExp(',', 'g'), '<br />') }} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} />
                  </Text>
               }
            </>
            // </Tooltip>
         ),
         width: '10%'
      },
      // {
      //    title: 'Start Date',
      //    key: 'start_date',
      //    dataIndex: 'start_date',
      //    sorter: (a, b) => { return a.start_date.localeCompare(b.start_date) },
      //    width: '10%'
      // },
      // {
      //    title: 'End Date',
      //    key: 'end_date',
      //    dataIndex: 'end_date',
      //    sorter: (a, b) => { return a.end_date.localeCompare(b.end_date) },
      //    width: '10%'
      // },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  {
                     ((user_id === '10000' || user_id === '1') && (activeTab === '3' || activeTab === '2')) &&
                     // <Tooltip placement="top" title='Edit'><AntDIcons.EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditDiagnosticAssignmentLink(payload.id, payload.level, payload.title, payload.file_url, payload.password, payload.instruction_file_url, payload.assigned_by)} /></Tooltip>
                     <Tooltip placement="top" title='Edit'><AntDIcons.EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditDiagnosticAssignmentLink(payload.id, payload.level, payload.subject, payload.title, payload.file_url, payload.password, payload.assigned_by, payload.message, payload.instruction_docs, payload.instruction_doc_names)} /></Tooltip>
                  }
                  {
                     ((parseInt(user_id) === payload.assigned_by || parseInt(user_id) === 1) && parseInt(user_id) !== 10000) &&
                     <>
                        <Tooltip placement="top" title='Assign'><AntDIcons.UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssignDiagnosticAssessment(payload.id, payload.title)} /></Tooltip>
                        <Tooltip placement="top" title='Remove'>
                           <Popconfirm
                              placement='left'
                              title="Are you sure you want to remove this Assessment?"
                              onConfirm={() => handleDeleteDiagnosticLink(payload.id, 1, 'assignment')}
                              okText="Yes"
                              cancelText="No"
                           >
                              <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                           </Popconfirm>
                        </Tooltip>
                     </>
                  }
                  {/* <Tooltip placement="top" title='Preview'><AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handlePreviewDiagnosticLink(payload.id, payload.file_url)} /></Tooltip> */}
                  {
                     // payload.instruction_file_url !== null && payload.instruction_file_url !== '' &&
                     payload.instruction_docs !== null &&
                     <Tooltip placement="top" title='View Instructions'><AntDIcons.FileOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleViewInstructions(payload.id, payload.message, payload.instruction_docs, payload.instruction_doc_names)} /></Tooltip>
                  }
                  {
                     payload.file_url !== null && payload.file_url !== '' &&
                     <Tooltip placement="top" title='Preview'><AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handlePreviewDiagnosticLink(payload.id, payload.title, payload.password, payload.file_url, payload.message)} /></Tooltip>
                  }
                  {
                     parseInt(user_id) !== payload.assigned_by &&
                     <Tooltip placement="top" title='Show Attendance'><AntDIcons.CarryOutOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleShowAttendance(payload.id)} /></Tooltip>
                  }
                  {
                     (parseInt(user_id) === payload.assigned_by && (activeTab === '2' || activeTab === '4')) &&
                     <Tooltip placement="left" title='Check Attendance'><AntDIcons.CarryOutOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleShowCheckAttendance(payload.id)} /></Tooltip>
                  }
               </Space>
            </>
         },
         width: '12%'
      },
   ].filter(item => !item.hidden);

   const recycle_bin_columns = [
      {
         title: 'Title',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: title => (
            <Tooltip placement="top" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '40%'
      },
      {
         title: 'Level',
         dataIndex: 'level_desc',
         key: 'level_desc',
         sorter: (a, b) => { return a.level_desc.localeCompare(b.level_desc) },
         ...getColumnSearchProps('level_desc'),
         width: '10%'
      },
      {
         title: 'Subject',
         dataIndex: 'subject',
         key: 'subject',
         sorter: (a, b) => { return a.subject.localeCompare(b.subject) },
         ...getColumnSearchProps('subject'),
         render: subject => (
            <span style={{ textTransform: 'capitalize' }}>{subject}</span>
         ),
         width: '10%'
      },
      {
         title: 'Location',
         dataIndex: 'location',
         key: 'location',
         sorter: (a, b) => { return a.location.localeCompare(b.location) },
         ...getColumnSearchProps('location'),
         width: '15%'
      },
      {
         title: 'Date Removed',
         key: 'date_deleted',
         dataIndex: 'date_deleted',
         sorter: (a, b) => { return a.date_deleted.localeCompare(b.date_deleted) },
         width: '15%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Restore'>
                     <Popconfirm
                        title="Do you want to restore this assessment?"
                        onConfirm={() => handleDeleteDiagnosticLink(payload.id, 0, payload.location === 'Assessment List' ? 'main' : 'assignment')}
                        okText="Yes"
                        cancelText="No"
                     >
                        <AntDIcons.UndoOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
                  <Tooltip placement="top" title='Preview'><AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handlePreviewDiagnosticLink(payload.id, payload.title, payload.password, payload.file_url, payload.message)} /></Tooltip>
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const onDrawerClose = () => {
      setAssignVisible(false);
   }

   const renderDrawer = () => {
      return <>
         <Drawer
            // title={<Text style={{textAlign: "justify", textJustify: "inter-word"}}>{assTitle}</Text>}
            title="Diagnostic Assignment"
            placement="right"
            size={"default"}
            closable={false}
            onClose={onDrawerClose}
            open={assignVisible}
            zIndex={1001}
            extra={
               <Space>
                  <Button type='primary' shape='round' onClick={onDrawerClose}>Close</Button>
               </Space>
            }
         >
            <SimpleBarReact style={{ height: "calc(100vh - 110px)" }}>
               <DiagnosticAssignTool
                  mode={"drawer"}
                  options={assOptions}
                  parentCallback={handleSetOptions}
                  saveCallback={handleSaveAssignment}
                  diagnostic_type={diagnosticType}
               />
            </SimpleBarReact>
         </Drawer>
      </>
   }

   const handleSetOptions = (data) => {
      let tempAssOptions = null;
      tempAssOptions = JSON.parse(JSON.stringify(data));
      setAssOptions(tempAssOptions);
   }

   const getMasterList = () => {
      var url = `${process.env.REACT_APP_API_CPH}/getdiagnostics?type=${diagnosticType}`;

      setListLoading(true);
      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setListLoading(false);
            setMasterList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getAssignedtList = (time_tense) => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_CPH}/getdiagnosticassignments?assigned_by=${user_id}&type=${diagnosticType}&time_tense=${time_tense}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setListLoading(false);
            setAssignedList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const getRecycleBin = () => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_CPH}/getdeleteddiagnostics?assigned_by=${user_id}&type=${diagnosticType}`;
      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setListLoading(false);
            setDeletedList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleTabChange = (key) => {
      switch (key) {
         case "1":
            getMasterList();
            setActiveTab("1");
            break;
         case "2":
            getAssignedtList(1);
            setActiveTab("2");
            break;
         case "3":
            getAssignedtList(2);
            setActiveTab("3");
            break;
         case "4":
            getAssignedtList(3);
            setActiveTab("4");
            break;
         case "5":
            getRecycleBin();
            setActiveTab("5");
            break;

      }
   }

   const refreshList = (tab) => {
      switch (tab) {
         case "1":
            getMasterList();
            break;
         case "2":
            getAssignedtList(1);
            break;
         case "3":
            getAssignedtList(2);
            break;
         case "4":
            getAssignedtList(3);
            break;
         case "5":
            getRecycleBin();
            break;
      }
   }

   function GetCurrentBreadcrumbLabel(type) {
      var retVal = "";

      switch (type) {
         case "pre":
            retVal = "Pre Diagnostic Test";
            break;
         case "post":
            retVal = "Post Diagnostic Test";
            break;
         default:
            break;
      }

      return retVal;
   }

   const handleAssignDiagnosticLink = (id, title) => {
      let ass_options_default = {
         id: '',
         assessment_id: id,
         assigned_by: user_id,
         assigned_students: '',
         assigned_students_with_section: '',
         start_date: '',
         end_date: '',
         level: null,
         section: null,
         title: title,
         pre_assignment_id: null
      }

      setAssOptions(ass_options_default);
      setAssTitle(title);
      setAssignVisible(true);
   }

   const handleEditDiagnosticAssessment = (id, level, title, subject) => {
      setDiagnosticID(id);
      setSelectedLevel(level);
      setAssTitle(title);
      setSelectedSubject(subject);
      setShowEditAssessment(true);
   }

   const handleEditDiagnosticAssignmentLink = (id, level, subject, title, file_url, password, assigned_by, message, instruction_docs, instruction_doc_names) => {

      var fileList = [];

      uploadFileList.forEach(file => {
         fileList.push(file.name);
      });

      setDiagnosticID(id);
      setSelectedLevel(level);
      setSelectedSubject(subject);
      setAssTitle(title);
      setFileURL(file_url);
      setPassword(password);
      // setInstructionsURL(instruction_url);
      setMessage(message);
      setInstructionDocs(instruction_docs);
      setInstructionDocNames(instruction_doc_names);
      setAssignedBy(assigned_by);
      setShowEditLink(true);
   }

   const handleDeleteDiagnosticLink = (id, status, location) => {
      const url = `${process.env.REACT_APP_API_CPH}/setdiagnosticassessmentdeletestatus?id=${id}&status=${status}&location=${location}`;
      axios.post(url).then(response => {
         console.log(response);

         if (response.data) {
            getMasterList();

            if (status === 1)
               Modal.error({
                  title: 'Diagnostic Assessment',
                  content: 'Removing assessment link failed.',
                  centered: true,
               });
            else
               Modal.success({
                  title: 'Diagnostic Assessment',
                  content: 'Removing assessment link successful.',
                  centered: true,
                  onOk: refreshList(activeTab)
               });
         }

      }, (error) => {
         console.log(error.message);
         if (status === 1)
            Modal.error({
               title: 'Diagnostic Assessment',
               content: 'Removing assessment link failed.',
               centered: true
            });
         else
            Modal.error({
               title: 'Diagnostic Assessment',
               content: 'Removing assessment link failed.',
               centered: true
            });
      });
   }

   const handlePreviewDiagnosticLink = (id, title, password, file_url, message) => {
      setMessage(message);
      setFileURL(file_url);
      setAssTitle(title);
      setPassword(password);
      setShowPreview(true);
   }

   const handleViewInstructions = (id, msg, instruction_docs, instruction_doc_names) => {
      // setInstructionsURL(instruction_url);
      g_instruction_data = {
         instruction_id: '',
         instruction_desc: msg,
         instruction_docs: instruction_docs !== null ? instruction_docs.split(',') : null,
         instruction_doc_names: instruction_doc_names !== null ? instruction_doc_names.split(',') : null,
      }

      setShowInstructions(true);
   }

   const handleCreateDiagnosticLink = () => {
      setShowCreateDiagnostic(true);
   }

   const handleEditAssignDiagnosticAssessment = (id, title) => {
      axios.get(`${process.env.REACT_APP_API_CPH}/getdiagnosticassignmentinfo?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            let ass_options = {
               id: data.id,
               assessment_id: data.assessment_id,
               assigned_by: data.assigned_by,
               start_date: data.start_date,
               end_date: data.end_date,
               assigned_students: data.assigned_students,
               assigned_students_with_section: data.assigned_students_with_section,
               level: data.level,
               title: title,
               section: data.section,
               pre_assignment_id: data.pre_assignment_id,
            };

            setAssOptions(ass_options);
            setAssTitle(title);
            setAssignVisible(true);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const setModalVisible = (visible) => {
      setShowCreateDiagnostic(visible);
      setShowEditAssessment(visible);
   }

   const handleSaveAssessment = (mode) => {
      if (assTitle !== null && assTitle !== '' && selectedLevel !== null && selectedLevel !== '') {
         setUpdating(true);
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         let id = 'diagnostic_assessment_' + o2o_mode + "_" + uuid();

         var data = {
            id: mode === 'edit' ? diagnosticID : id,
            title: assTitle,
            // file_url: fileURL,
            level: selectedLevel,
            type: diagnosticType,
            subject: selectedSubject,
            // password: password,
            // instruction_file_url: instructionsURL,
         }

         const url = `${process.env.REACT_APP_API_CPH}/upsertdiagnostic`;
         axios.post(url, data).then(response => {
            console.log(response);
            if (response.data) {
               // const data = response.data;

               setActiveTab('1');
               getMasterList();
               setUpdating(false);
               setSelectedSubject(null);

               Modal.success({
                  title: 'Diagnostic Assessment',
                  content: mode === 'edit' ? 'Update successful.' : 'Add successful.',
                  centered: true,
                  onOk: () => { setModalVisible(false) },
               });
            }

         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Diagnostic Assessment',
               content: 'Ooops! It seems that there was a problem encountered while processing your request.',
               centered: true
            });
         });
      } else {
         Modal.info({
            title: 'Diagnostic Assessment',
            content: (
               <>
                  Please complete the folowing before saving:
                  <ul>
                     <li>
                        Level
                     </li>
                     <li>
                        Title
                     </li>
                  </ul>
               </>),
            centered: true
         });
      }
   }

   const handleSaveDiagnosticAssignmentLink = () => {
      // if (fileURL !== null && fileURL !== '' && password !== null && password !== '' && instructionsURL !== null && instructionsURL !== '') {
      if (fileURL !== null && fileURL !== '' && password !== null && password !== '' && uploadFileList.length > 0) {
         setUpdating(true);

         var formData = new FormData();

         uploadFileList.forEach(file => {
            // the name has to be 'files' so that .NET could properly bind it (Phuck this sheet! Took me the whole day to find this sheet!)
            formData.append('files', file);
         });

         var uploadedfiles = "";
         var url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMultiple?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}`;

         axios.post(url, formData).then(response => {

            if (response.data) {
               uploadedfiles = response.data;

               var fileList = [];

               uploadFileList.forEach(file => {
                  fileList.push(file.name);
               });

               var data = {
                  id: diagnosticID,
                  assigned_by: assignedBy,
                  file_url: fileURL,
                  password: password,
                  instruction_file_url: '',
                  instruction_docs: uploadedfiles,
                  instruction_doc_names: fileList.join(','),
                  message: message,
               }

               url = `${process.env.REACT_APP_API_CPH}/updatediagnosticassignmentlinks`;
               axios.post(url, data).then(response => {
                  console.log(response);

                  if (response.data) {
                     // const data = response.data;

                     refreshList(activeTab);
                     setUpdating(false);

                     Modal.success({
                        title: 'Diagnostic Assessment',
                        content: 'Update successful.',
                        centered: true,
                        onOk: () => { setShowEditLink(false) },
                     });
                  }

               }, (error) => {
                  console.log(error.message);
                  Modal.error({
                     title: 'Diagnostic Assessment',
                     content: 'Ooops! It seems that there was a problem encountered while processing your request.',
                     centered: true
                  });
               });

            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Diagnostic Assessment',
               content: 'Ooops! It seems that there was a problem encountered while processing your request.',
               centered: true
            });
         });


      } else {
         Modal.info({
            title: 'Diagnostic Assessment',
            content: (
               <>
                  Please complete the folowing before saving:
                  <ul>
                     <li>
                        Test Link
                     </li>
                     <li>
                        Password
                     </li>
                     <li>
                        Instruction Link
                     </li>
                  </ul>
               </>),
            centered: true
         });
      }
   }

   const handleSaveAssignment = (data) => {
      setIsProcessing(true);
      setAssignVisible(false);

      if (data.id === '') { //--New assignment
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         var assignmentData = data;

         assignmentData.id = "diagnostic_assign_" + o2o_mode + "_" + uuid();

         const url = `${process.env.REACT_APP_API_CPH}/upsertdiagnosticassignment`
         axios.post(url, assignmentData).then(response => {
            if (response.data) {
               const data = response.data;

               Modal.success({
                  title: 'Diagnostic Assessment',
                  content: 'Diagnostic was assigned successfully.',
                  centered: true,
               });


               setIsProcessing(false);
            }
         }, (error) => {
            console.log(error.message);

            Modal.error({
               title: 'Diagnostic Assessment',
               content: 'Diagnostic assignment failed.',
               centered: true,
            });
            setIsProcessing(false);
         });
      } else {//-- Edit assignment
         const url = `${process.env.REACT_APP_API_CPH}/upsertdiagnosticassignment`
         axios.post(url, data).then(response => {

            if (response.data) {
               const data = response.data;

               Modal.success({
                  title: 'Diagnostic Assessment',
                  content: 'Diagnostic was updated successfully.',
                  centered: true,
               });

               setIsProcessing(false);
            }
         }, (error) => {
            console.log(error.message);

            Modal.error({
               title: 'Diagnostic Assessment',
               content: 'Diagnostic assignment update failed.',
               centered: true,
            });
            setIsProcessing(false);
         });
      }
   }

   const upload_props = {
      onRemove: file => {
         setUploadFileList(() => {
            const index = uploadFileList.indexOf(file.originFileObj);
            const newFileList = uploadFileList.slice();
            newFileList.splice(index, 1);
            return newFileList;
         })
      },

      beforeUpload: file => {
         const isPNG = file.type === 'image/png';
         const isJPG = file.type === 'image/jpeg';
         const isBMP = file.type === 'image/bmp';
         const isDOC = file.type === 'application/msword' || file.type === 'application/vnd.ms-word';
         const isDOCX = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
         const isXLS = file.type === 'application/msexcel' || file.type === 'application/vnd.ms-excel';
         const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
         const isPPT = file.type === 'application/vnd.ms-powerpoint';
         const isPPTX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
         const isPPSX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow';
         const isPDF = file.type === 'application/pdf';

         const isMP4 = file.type === 'video/mp4';
         const isAVI = file.type === 'video/avi';

         const fileValid = isPNG || isJPG || isBMP || isDOC || isDOCX || isXLS || isXLSX || isPPT || isPPTX || isPDF || isPPSX || isMP4 || isAVI;

         if (fileValid) {
            const isLt100M = file.size / 1024 / 1024 <= 100;

            if (isLt100M) {
               var list = uploadFileList;
               list.push(file);
               setUploadFileList(list);
            } else {
               Modal.error({
                  title: 'Diagnostic Assessment',
                  content: 'Maximum allowed file size is 100 MB',
                  centered: true
               });
            }

            // console.log(uploadFileList);
         } else {
            Modal.error({
               title: 'Diagnostic Assessment',
               content: 'You are not allowed to upload that file type',
               centered: true
            });
         }

         return false;
      },

      uploadFileList,
   };

   const handleShowAttendance = (id) => {
      setAssignId(id);
      setShowAttendance(true);
   }

   const handleShowCheckAttendance = (id) => {
      setAssignId(id);
      setShowCheckAttendance(true);
   }

   const handleAttendanceSave = () => {
      // console.log(attendedStudents);
      if (attendedStudents.length > 0) {
         setSavingAttendance(true);
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         let id = 'diagnostic_attendance_' + o2o_mode + "_" + uuid();

         var data = {
            id,
            assignment_id: assignId,
            students: attendedStudents.join(','),
         }

         const url = `${process.env.REACT_APP_API_CPH}/upsertdiagnosticattendance`;

         axios.post(url, data).then(response => {
            console.log(response);
            if (response.data) {
               // const data = response.data;

               Modal.success({
                  title: 'Diagnostic Assessment',
                  content: 'Save successful.',
                  centered: true,
                  onOk: () => { setShowCheckAttendance(false) },
               });

               setSavingAttendance(false);
            }

         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Diagnostic Assessment',
               content: 'Ooops! It seems that there was a problem encountered while processing your request.',
               centered: true
            });
            setSavingAttendance(false);
         });
      } else {
         Modal.info({
            title: 'Diagnostic Assessment',
            content: 'You have not selected any students.',
            centered: true
         });
      }

   }

   const handleSetAttendedStudents = (selected) => {
      setAttendedStudents(selected);
   }

   const displayPaneTable = (columns, data) => {
      return (
         <>
            {listLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading, please wait..." />
               </div>
               :
               (data.length > 0 && !listLoading)
                  ?
                  <Table
                     // loading={listLoading}
                     size='small'
                     rowKey={'uid'}
                     columns={columns}
                     dataSource={data}
                     pagination={{ position: ['topCenter'] }}
                     scroll={{
                        x: '1000px',
                        y: 'calc(100vh - 18.5rem)',
                     }}
                     footer={() => 'Total Records: ' + data.length}
                  />
                  :
                  <div className='center-empty-space'>
                     <Empty image="../images/brainee_mascot.png" description="No records found" />
                  </div>
            }
         </>
      )
   }

   const handleCancelEditLink = () => {
      setShowEditLink(false);
      setUploadFileList([]);
   }

   const handleShowFullscreen = () => {
      let element = document.getElementById('msdoc-iframe');
      element.requestFullscreen();
   }

   const handleCancelPreview = () => {
      setShowPreview(false);
   }

   const copyText = (text) => {
      navigator.clipboard.writeText(text);
      const key = 'updatable';
      message.info({ content: 'Copied to clipboard!', key });
   };

   return (
      <>
         {renderDrawer()}
         <NoticeBoardSlide />
         <ProcessingModal
            processing={isProcessing}
            title={'Diagnostic Assessment'}
         />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li className="active">
                        {GetCurrentBreadcrumbLabel(diagnosticType)}
                     </li>
                  </ul>
                  {/* <Breadcrumb style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <AntDIcons.HomeOutlined />
                        <a href="#" onClick={() => navigate('/home')}>Home</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        { GetCurrentBreadcrumbLabel(diagnosticType) }
                     </Breadcrumb.Item>
                  </Breadcrumb> */}
               </div>

               {user_id === '1' &&
                  <Row gutter={12}>
                     <Col className="gutter-row" span={24} style={{ textAlign: "left" }}>
                        <Tooltip placement="top" title='Create Empty Diagnostic Assessment'>
                           <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => handleCreateDiagnosticLink()}>
                              <Text ellipsis style={{ color: "#fff" }}><AntDIcons.PlusOutlined />&nbsp;Create Diagnostic</Text>
                           </Button>
                        </Tooltip>
                     </Col>
                  </Row>
               }

               {masterList !== null
                  ?
                  <>
                     <div className="dashboard-item-inner3">
                        <Tabs defaultActiveKey={'1'} activeKey={activeTab} centered onChange={(key) => handleTabChange(key)}>
                           <TabPane
                              tab={(<Tooltip title="List of all Diagnostic Assessments">
                                 <span>Assessment List</span>
                              </Tooltip>
                              )}
                              key="1"
                           >
                              {displayPaneTable(masterListColumns, masterList)}
                           </TabPane>

                           <TabPane
                              tab={(
                                 <Tooltip title="List of Assigned Current Diagnostic Assessments">
                                    <span><AntDIcons.UsergroupAddOutlined /> Current</span>
                                 </Tooltip>
                              )}
                              key="2"
                           >
                              {displayPaneTable(assigned_ass_columns, assignedList)}
                           </TabPane>
                           <TabPane
                              tab={(
                                 <Tooltip title="List of Assigned Upcoming Diagnostic Assessments">
                                    <span><AntDIcons.UsergroupAddOutlined /> Upcoming</span>
                                 </Tooltip>
                              )}
                              key="3"
                           >
                              {displayPaneTable(assigned_ass_columns, assignedList)}
                           </TabPane>
                           <TabPane
                              tab={(
                                 <Tooltip title="List of Assigned Past Diagnostic Assessments">
                                    <span><AntDIcons.UsergroupAddOutlined /> Past</span>
                                 </Tooltip>
                              )}
                              key="4"
                           >
                              {displayPaneTable(assigned_ass_columns, assignedList)}
                           </TabPane>
                           {user_role === 'Admin' &&
                              <TabPane
                                 tab={(
                                    <Tooltip title="List of all deleted Diagnostic Assessments">
                                       <span><AntDIcons.RestOutlined /> Recycle Bin</span>
                                    </Tooltip>
                                 )}
                                 key="5"
                              >
                                 {displayPaneTable(recycle_bin_columns, deleteList)}
                              </TabPane>
                           }
                        </Tabs>
                     </div>
                  </>
                  :
                  <>
                     <div className='mastery_assessment_list'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  </>
               }
            </div>
         </div>

         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar/>
               <img src="../images/brainee_ma.png" width="100%" style={{ padding: "5px", maxWidth: '600px' }}></img>
               <NoticeBoard />
            </div>
         </div> */}

         <Modal
            // title="Create Diagnostic Assessment"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Create Diagnostic Assessment</div>}
            destroyOnClose={true}
            centered
            open={showCreateDiagnostic}
            onOk={() => handleSaveAssessment('new')}
            onCancel={() => setShowCreateDiagnostic(false)}
            okText={"Save"}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round" }}
         >
            <Spin spinning={updating} tip={"Processing, please wait..."}>
               <div className='survey_modal_container'>
                  {lookUps.GradeLevels !== null &&
                     <Row style={{ paddingBottom: "10px" }}>
                        <Col span={6}>
                           <Text>Grade Level</Text>
                        </Col>
                        <Col span={18}>
                           <Select
                              showArrow
                              size='default'
                              style={{ width: '100%', textAlign: "left" }}
                              allowClear
                              placeholder="Select Level"
                              onChange={(val) => setSelectedLevel(val)}
                           // value={selectedLevel}
                           >
                              {lookUps.GradeLevels.map(({ value, name }, idx) => {
                                 return <Option value={value}>{name}</Option>
                              })}
                           </Select>
                        </Col>
                     </Row>
                  }

                  {
                     lookUps.SubjectList !== null &&
                     <Row style={{ paddingBottom: "10px" }}>
                        <Col span={6}>
                           <Text>Subject</Text>
                        </Col>
                        <Col span={18}>
                           <Select
                              showArrow
                              size='default'
                              style={{ width: '100%', textAlign: "left" }}
                              allowClear
                              placeholder="Select Subject"
                              onChange={(val) => setSelectedSubject(val)}
                           >
                              {lookUps.SubjectList.map(({ value, name }, idx) => {
                                 return <Option value={value}>{name}</Option>
                              })}
                           </Select>
                        </Col>
                     </Row>
                  }


                  <Row style={{ paddingBottom: "10px" }}>
                     <Col span={6}>
                        <Text>Title</Text>
                     </Col>
                     <Col span={18}>
                        <TextArea
                           rows={3}
                           onBlur={(e) => setAssTitle(e.target.value)}
                           allowClear
                        >
                        </TextArea>
                     </Col>
                  </Row>
                  {/* <Row style={{ paddingBottom: "10px" }}>
                     <Col span={6}>
                        <Text>Link</Text>
                     </Col>
                     <Col span={18}>
                        <Input onBlur={(e) => setFileURL(e.target.value)} allowClear></Input>
                     </Col>
                  </Row>
                  <Row style={{ paddingBottom: "10px" }}>
                     <Col span={6}>
                        <Text>Password</Text>
                     </Col>
                     <Col span={18}>
                        <Input onBlur={(e) => setPassword(e.target.value)} allowClear></Input>
                     </Col>
                  </Row> */}

               </div>
            </Spin>
         </Modal>

         <Modal
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Edit Diagnostic Assessment</div>}
            destroyOnClose={true}
            centered
            open={showEditAssessment}
            onOk={() => handleSaveAssessment('edit')}
            onCancel={() => setShowEditAssessment(false)}
            okText={"Save"}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round" }}
         >
            <Spin spinning={updating} tip={"Processing, please wait..."}>
               <div className='survey_modal_container'>
                  {lookUps.GradeLevels !== null &&
                     <Row style={{ paddingBottom: "10px" }}>
                        <Col span={6}>
                           <Text>Grade Level</Text>
                        </Col>
                        <Col span={18}>
                           <Select
                              showArrow
                              size='default'
                              style={{ width: '100%', textAlign: "left" }}
                              allowClear
                              placeholder="Select Level"
                              onChange={(val) => setSelectedLevel(val)}
                              defaultValue={selectedLevel}
                           >
                              {lookUps.GradeLevels.map(({ value, name }, idx) => {
                                 return <Option value={value}>{name}</Option>
                              })}
                           </Select>
                        </Col>
                     </Row>
                  }
                  {
                     lookUps.SubjectList !== null &&
                     <Row style={{ paddingBottom: "10px" }}>
                        <Col span={6}>
                           <Text>Subject</Text>
                        </Col>
                        <Col span={18}>
                           <Select
                              showArrow
                              size='default'
                              style={{ width: '100%', textAlign: "left" }}
                              allowClear
                              placeholder="Select Subject"
                              onChange={(val) => setSelectedSubject(val)}
                              defaultValue={selectedSubject}
                           >
                              {lookUps.SubjectList.map(({ value, name }, idx) => {
                                 return <Option value={value}>{name}</Option>
                              })}
                           </Select>
                        </Col>
                     </Row>
                  }

                  <Row style={{ paddingBottom: "10px" }}>
                     <Col span={6}>
                        <Text>Title</Text>
                     </Col>
                     <Col span={18}>
                        <TextArea
                           rows={3}
                           onBlur={(e) => setAssTitle(e.target.value)}
                           defaultValue={assTitle}
                           allowClear
                        >
                        </TextArea>
                     </Col>
                  </Row>
               </div>
            </Spin>
         </Modal>

         <Modal
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Edit Assessment Link</div>}
            destroyOnClose={true}
            centered
            open={showEditLink}
            onOk={() => handleSaveDiagnosticAssignmentLink()}
            onCancel={() => handleCancelEditLink()}
            okText={"Save"}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round" }}
         >
            <Spin spinning={updating} tip={"Processing, please wait..."}>
               <div className='survey_modal_container'>
                  {lookUps.GradeLevels !== null &&
                     <Row style={{ paddingBottom: "10px" }}>
                        <Col span={6}>
                           <Text>Grade Level</Text>
                        </Col>
                        <Col span={18}>
                           <Select
                              disabled
                              showArrow
                              size='default'
                              style={{ width: '100%', textAlign: "left" }}
                              allowClear
                              placeholder="Select Level"
                              onChange={(val) => setSelectedLevel(val)}
                              defaultValue={selectedLevel}
                           >
                              {lookUps.GradeLevels.map(({ value, name }, idx) => {
                                 return <Option value={value}>{name}</Option>
                              })}
                           </Select>
                        </Col>
                     </Row>
                  }

                  {
                     lookUps.SubjectList !== null &&
                     <Row style={{ paddingBottom: "10px" }}>
                        <Col span={6}>
                           <Text>Subject</Text>
                        </Col>
                        <Col span={18}>
                           <Select
                              disabled
                              showArrow
                              size='default'
                              style={{ width: '100%', textAlign: "left" }}
                              allowClear
                              placeholder="Select Subject"
                              onChange={(val) => setSelectedSubject(val)}
                              defaultValue={selectedSubject}
                           >
                              {lookUps.SubjectList.map(({ value, name }, idx) => {
                                 return <Option value={value}>{name}</Option>
                              })}
                           </Select>
                        </Col>
                     </Row>
                  }

                  <Row style={{ paddingBottom: "10px" }}>
                     <Col span={6}>
                        <Text>Title</Text>
                     </Col>
                     <Col span={18}>
                        <TextArea
                           disabled
                           rows={3}
                           onBlur={(e) => setAssTitle(e.target.value)}
                           defaultValue={assTitle}
                           allowClear
                        >
                        </TextArea>
                     </Col>
                  </Row>

                  {
                     (user_id === '10000' || user_id === '1') &&
                     // (user_id === '10000') &&
                     <>
                        <Row style={{ paddingBottom: "10px" }}>
                           <Col span={6}>
                              <Text>Message</Text>
                           </Col>
                           <Col span={18}>
                              <TextArea
                                 rows={4}
                                 onBlur={(e) => setMessage(e.target.value)}
                                 defaultValue={message}
                                 allowClear
                              >
                              </TextArea>
                           </Col>
                        </Row>
                        <Row style={{ paddingBottom: "10px" }}>
                           <Col span={6}>
                              <Text>Test Link</Text>
                           </Col>
                           <Col span={18}>
                              <Input
                                 onBlur={(e) => setFileURL(e.target.value)}
                                 defaultValue={fileURL}
                                 allowClear
                              >

                              </Input>
                           </Col>
                        </Row>
                        <Row style={{ paddingBottom: "10px" }}>
                           <Col span={6}>
                              <Text>Password</Text>
                           </Col>
                           <Col span={18}>
                              <Input
                                 onBlur={(e) => setPassword(e.target.value)}
                                 defaultValue={password}
                                 allowClear
                              ></Input>
                           </Col>
                        </Row>
                        <Row style={{ paddingBottom: "10px" }}>
                           <Col span={6}>
                              <Text>Instructions</Text>
                           </Col>
                           <Col span={18}>
                              {/* <Input
                                 onBlur={(e) => setInstructionsURL(e.target.value)}
                                 defaultValue={instructionsURL}
                                 allowClear
                              ></Input> */}
                              <SimpleBarReact style={{ maxHeight: "150px", overflow: "auto" }}>
                                 <StickyBox style={{ zIndex: 1000 }}>
                                    <Upload
                                       style={{ display: "block" }}
                                       maxCount={5}
                                       multiple
                                       {...upload_props}
                                       listType="picture"
                                       defaultFileList={[...uploadFileList]}
                                       className="upload-list-inline"
                                       accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,.png,.jpg,.pdf,.ppsx,.avi,.mp4"
                                    >
                                       <Button shape='round' icon={<AntDIcons.CloudUploadOutlined />} block>Select Files</Button>
                                    </Upload>
                                 </StickyBox>
                              </SimpleBarReact>
                           </Col>
                        </Row>
                     </>
                  }
               </div>
            </Spin>
         </Modal>

         <Modal
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Preview Assessment Link</div>}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round", style: { display: 'none' } }}
            centered
            destroyOnClose={true}
            open={showPreview}
            width='100%'
            onCancel={() => { setShowPreview(false); }}
            // onOk={() => { setShowPreview(false); }}
            // onOk={() => { handleShowFullscreen(); }}
            footer={[
               <Button key="fullscreen" type="primary" shape='round' onClick={handleShowFullscreen}>
                  Full Screen
               </Button>,
               <Button key="close" type="primary" shape='round' onClick={handleCancelPreview}>
                  Close
               </Button>
            ]}
         >
            <div style={{ background: '#fff' }}>
               <Row>
                  <Col xs={24} md={6} lg={6}>
                     <div className='student_list_container' style={{ paddingRight: "10px", paddingLeft: "5px" }}>
                        <div style={{ display: 'block', textAlign: 'center' }}>
                           <Space direction='vertical' style={{ width: '100%' }}>
                              <img src="../images/brainee_mascot.png" width="100%" style={{ padding: "5px", maxWidth: '120px' }}></img>
                              <h5>Brainee Diagnostic Assessment</h5>
                           </Space>
                        </div>

                        <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", marginTop: '15px', width: "100%" }} column={4}>
                           <Descriptions.Item label={"Assessment Title"} span={4} style={{ textAlign: "center" }}>
                              <Paragraph style={{ textAlign: "left", width: "100%" }}>{assTitle}</Paragraph>
                           </Descriptions.Item>
                           <Descriptions.Item
                              label={<>Message</>}
                              span={4}
                              style={{ textAlign: "center" }}>
                              <Paragraph style={{ textAlign: "left", width: "100%" }}>
                                 {message}
                              </Paragraph>
                           </Descriptions.Item>
                           {/* <Descriptions.Item
                              label={<>Test Link <Tooltip placement="top" title='Copy test link'>
                                 <AntDIcons.CopyOutlined onClick={() => copyText(fileURL)} />
                              </Tooltip></>}
                              span={4}
                              style={{ textAlign: "center" }}>
                              <Paragraph style={{ textAlign: "left", width: "100%" }}>
                                 {fileURL}
                              </Paragraph>
                           </Descriptions.Item> */}
                           <Descriptions.Item
                              label={
                                 <>
                                    Test Password
                                    <Tooltip placement="top" title='Copy password'>
                                       <AntDIcons.CopyOutlined onClick={() => copyText(password)} />
                                    </Tooltip>
                                 </>}
                              span={4}
                              style={{ textAlign: "center" }}>
                              <Paragraph style={{ textAlign: "center", width: "100%" }}>
                                 {password}
                              </Paragraph>
                           </Descriptions.Item>
                        </Descriptions>
                     </div>
                  </Col>
                  <Col xs={24} md={18} lg={18}>
                     <div className='essay_list_container'>
                        <Iframe
                           id="msdoc-iframe"
                           title="msdoc-iframe"
                           src={fileURL}
                           frameBorder="0"
                           height='100%'
                           width='100%'
                           styles={{ height: "calc(100vh - 191px)" }}
                        />
                     </div>
                  </Col>
               </Row>
            </div>
         </Modal>

         <Modal
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> View Instructions</div>}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={showInstructions}
            width='75%'

            onCancel={() => { setShowInstructions(false); }}
            onOk={() => { setShowInstructions(false); }}
         >
            {/* <div className="modal-document-viewer">
               <Iframe
                  id="msdoc-iframe"
                  title="msdoc-iframe"
                  src={instructionsURL}
                  frameBorder="0"
                  height='100%'
                  width='100%'
               />
            </div> */}
            <ViewInstructions
               data={g_instruction_data}
            />
         </Modal>

         <Modal
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Show Attendance</div>}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={showAttendance}
            width='55%'
            onCancel={() => { setShowAttendance(false); }}
            onOk={() => { setShowAttendance(false); }}
         >
            <AttendanceList
               id={assignId}
            />
         </Modal>

         <Modal
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Check Attendance</div>}
            okText={"Save"}
            cancelText={'Cancel'}
            cancelButtonProps={{ shape: 'round' }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={showCheckAttendance}
            width='55%'
            onCancel={() => { setShowCheckAttendance(false); }}
            onOk={() => { handleAttendanceSave(); }}
         >
            <Spin spinning={savingAttendance} tip={"Saving, please wait..."}>
               <EditAttendance
                  id={assignId}
                  setSelectedCallback={handleSetAttendedStudents}
               />
            </Spin>
         </Modal>


      </>
   );
}