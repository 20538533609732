import React, { useEffect, useState, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
   Divider, Input, Tabs, Table, Typography, Space, Tooltip, Button, Select, Row, Col, message, Popconfirm,
   Radio, Tag, Drawer, notification, Modal, DatePicker, Upload, Form, Spin, Image
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import Enums from '../../../misc/Enums';
import Papa from 'papaparse';

import './../Management.css';

const { Text } = Typography;
const { Option } = Select;

export function StudentImport(props) {
   const navigate = useNavigate();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [levelList, setLevelList] = useState([]);
   const [sectionList, setSectionList] = useState([]);
   const [level, setLevel] = useState(null);
   const [section, setSection] = useState([]);
   const [sectionLoading, setSectionLoading] = useState(false);
   const [importData, setImportData] = useState([]);
   const [uploadFileList, setUploadFileList] = useState([]);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      getLevels();
   }, []);

   const getLevels = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/levellist`).then((response) => {
         if (response.data) {
            const data = response.data;
            var tmp_levels = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               tmp_levels.push(obj);
            });

            setLevelList(tmp_levels);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleLevelChange = (val) => {
      setSection(null)
      setSectionList([]);
      setLevel(val);
      setSectionLoading(true);

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${val}`).then((response) => {
         if (response.data) {
            const data = response.data;

            var sectionlist = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               sectionlist.push(obj);
            });

            setSectionList(sectionlist);
            setSectionLoading(false);
         }
      }, (error) => {
         setSectionLoading(false);
         console.log(error.message);
      });
   }

   const UploadCsv = () => {
      const props = {
         onRemove: file => {
            setImportData([]);

            // setUploadFileList(() => {
            //    const index = uploadFileList.indexOf(file.originFileObj);
            //    const newFileList = uploadFileList.slice();
            //    newFileList.splice(index, 1);
            //    return newFileList;
            // });
         },

         beforeUpload: file => {
            const isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel';

            const fileValid = isCSV;

            //-- ToDo: Check if the csv file contains the correct fields

            // console.log('File Type: ' + file.type);

            if (!fileValid) {
               setImportData([]);

               Modal.error({
                  title: 'Student Management',
                  content: 'You are not allowed to upload that file type.',
                  centered: true
               });

               return isCSV || Upload.LIST_IGNORE;

            } else {
               Papa.parse(file, {
                  header: true,
                  skipEmptyLines: true,
                  complete: function (results) {
                     var tmpData = results.data;

                     //-- Update values of level and section columns
                     for (var i = 0; i < tmpData.length; i++) {
                        tmpData[i].grade_level = level;
                        tmpData[i].section = section;
                        tmpData[i].is_active = 'yes';
                     }

                     // console.log(results.data);
                     // console.log(tmpData);

                     setImportData(tmpData);
                  }
               });
            }

            return false;
         },
      };

      return (
         <Upload
            {...props}
            // showUploadList={true}
            accept=".csv"
            style={{ width: "100%" }}
         >
            <Button className='button-shadow' type="primary" size="middle" shape='round' block>
               <Text ellipsis style={{ color: "#fff" }}><AntDIcons.CloudUploadOutlined />&nbsp;Load CSV File</Text>
            </Button>
         </Upload>
      );
   };

   const handleImport = () => {

      if (level && section && importData.length > 0) {
         props.onUpdatingCallBack();

         var url = `${process.env.REACT_APP_API_MANAGEMENT}/importstudentinfo`;
         axios.post(url, importData).then(response => {
            console.log(response);
            if (response.data) {
               const data = response.data;
               Modal.success({
                  title: 'Student Management',
                  content: 'Student import successful!',
                  centered: true,
                  onOk: () => { props.onSaveCompleteCallBack() },
               });
            }
         }, (error) => {
            Modal.error({
               title: 'Student Management',
               content: 'Student import failed!',
               centered: true,
            });
         });
      }
      else {
         Modal.info({
            title: 'Student Management',
            content: 'Please complete the required fields.',
            centered: true,
         })
      }
   }

   const handleCancel = () => {
      setSection(null);
      setLevel(null);
      props.onCancelCallback();
   }

   return (
      <>
         <Space direction='vertical' style={{ width: "100%" }} >
            <Text>Select Grade Level</Text>
            <Select
               showArrow
               size='middle'
               allowClear
               placeholder="Select Level"
               onChange={(val) => handleLevelChange(val)}
               // value={level}
               style={{ width: '100%' }}
            >
               {levelList.map(({ value, name }, idx) => {
                  return <Option value={value}>{name}</Option>
               })}
            </Select>
            <Text>Select Section</Text>
            <Select
               loading={sectionLoading}
               showArrow
               size='middle'
               style={{ width: '100%' }}
               allowClear
               placeholder="Select Section"
               value={section}
               onChange={(val) => setSection(val)}
            >
               {sectionList.map(({ value, name }, idx) => {
                  return <Option value={value}>{name}</Option>
               })}
            </Select>
            <Text>Load CSV File</Text>
            {UploadCsv()}
            <Row gutter={12} style={{ marginTop: '10px' }}>
               <Col xs={24} md={24} lg={24} style={{ textAlign: "right" }}>
                  <Space direction='horizontal' size={'small'}>
                     <Button shape='round' onClick={() => { handleCancel(); }}>Cancel</Button>
                     <Button type='primary' shape='round' onClick={() => { handleImport(); }}>Import</Button>
                  </Space>
               </Col>
            </Row>
         </Space >
      </>
   );
}