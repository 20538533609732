/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { Empty, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ProgressBar from "@ramonak/react-progress-bar";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import { InterpretationInd, IntroInd } from '../components';

export function MTG7MathReport(props) {
   const [reportData, setReportData] = useState(null);

   const [number, setNumber] = useState(null);
   const [measurement, setMeasurement] = useState(null);
   const [data, setData] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      ChartJS.register(
         CategoryScale,
         LinearScale,
         BarElement,
         Title,
         Tooltip,
         Legend
      );

      getReport(props.subject_id, props.assignment_id, props.student_id, props.template)
   }, []);

   const getReport = (subject, assignment_id, student_id, template) => {
      setDataLoading(true);
      var url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticreport?s=${subject}&ai=${assignment_id}&si=${student_id}&t=${template}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data[0];

            if (data !== undefined) {
               setMeasurement(data.s1 + data.s2 + data.s3 + data.s4 + data.s5 + data.s6 + data.s7 + data.s8 + data.s9 + data.s10 + data.s11 + data.s12 +
                  data.s13 + data.s14 + data.s15 + data.s16 + data.s17 + data.s18 + data.s19 + data.s20 + data.s21 + data.s22 + data.s23);
               setNumber(data.s24 + data.s25 + data.s26 + data.s27 + data.s28 + data.s29 + data.s30 + data.s31 + data.s32 + data.s33);
               setData(data.s34 + data.s35 + data.s36 + data.s37 + data.s38 + data.s39 + data.s40);

               setReportData(data);
            }

            setDataLoading(false);
         }
      }, (error) => {
         setDataLoading(false);
         console.log(error.message);
      });
      // }
   }

   const renderPartLabel = (title) => {
      return (
         <tr>
            <td style={{ width: '12.6pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
            </td>
            <td style={{ width: '382.3pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}><strong>{title}</strong></p>
            </td>
            <td style={{ width: '31.7pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
            </td>
            <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
            </td>
            <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
            </td>
         </tr>
      );
   }

   const renderCompetencyRow = (idx, label, answer, pre_percent, post_percent) => {
      return (
         <tr>
            <td style={{ width: '12.6pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{idx}</strong></p>
            </td>
            <td style={{ width: '382.3pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>{label}</p>
            </td>
            <td style={{ width: '31.7pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{answer === 1 || answer === '1' ? <CheckOutlined style={{ fontSize: '22px', color: '#52c41a' }} /> : <CloseOutlined style={{ fontSize: '22px', color: '#eb2f96' }} />}</p>
            </td>
            <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {
                     pre_percent !== 0 &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        // labelColor={pre_percent <= 40 ? "#000000" : "#FFFFFF"}
                        labelColor={"#FFFFFF"}
                        // labelAlignment={pre_percent <= 40 ? "outside" : "center"}
                        labelAlignment={"center"}
                        // customLabel={pre_percent.toFixed(1) + "%"}
                        completed={pre_percent}
                        labelSize="12px"
                        animateOnRender
                     />
                  }
               </p>
            </td>
            <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {/* {
                     pre_percent !== 0 &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        labelAlignment="center"
                        completed={pre_percent}
                        labelSize="12px"
                        animateOnRender
                     />
                  } */}
               </p>
            </td>
         </tr>
      );
   }

   const renderSummaryRow = (label, raw_score, item_count, s1, s2) => {
      return (
         <tr>
            <td style={{ width: '197.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
            </td>
            <td style={{ width: '79.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {raw_score}
               </p>
            </td>
            <td style={{ width: '61.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {(((raw_score) / item_count) * 100).toFixed(2)}%
               </p>
            </td>
            <td style={{ width: '59.9pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {((raw_score - s1) / s2).toFixed(2)}
               </p>
            </td>
            <td style={{ width: '99.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {((raw_score - s1) / s2) > 1 ? 'Competent' : (((raw_score - s1) / s2) >= -0.51 ? 'Basic' : (((raw_score - s1) / s2) > -10 ? 'Beginner' : ''))}
               </p>
            </td>
         </tr>
      );
   }

   const graph_options = {
      responsive: true,
      plugins: {
         legend: {
            position: 'top',
         },
         title: {
            display: true,
            text: 'Percent Correct for the Content Standards',
         },
      },
      scales: {
         y:
         {
            min: 0,
            max: 100,
            stepSize: 1,
         },
         x:
            {},
      }
   };
   const labels = [
      "Number and Algebra",
      "Measurement and Geometry",
      "Data and Probability"
   ];
   const graph_data = {
      labels: labels,
      datasets: [
         {
            label: "Percentage",
            backgroundColor: "#4F81BD",
            borderColor: "#4F81BD",
            data: [
               !isNaN((number / 23) * 100) ? ((number / 23) * 100).toFixed(2) : 0,
               !isNaN((measurement / 10) * 100) ? ((measurement / 10) * 100).toFixed(2) : 0,
               !isNaN((data / 7) * 100) ? ((data / 7) * 100).toFixed(2) : 0,
            ],
         },
      ],
   };

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               reportData !== null
                  ?
                  <div className="dashboard-item-inner3" style={{ padding: '7px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='individual-report'>

                        <IntroInd
                           subject='Math'
                           grade_level={reportData.grade_level}
                           student_name={reportData.student_name}
                           school_name={reportData.school_name}
                           section={reportData.section}
                           diagnostic_date={reportData.diagnostic_date}
                        />

                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '12pt' }}><strong>Competency Score</strong></p>
                        <div style={{ textAlign: 'center' }}>
                           <table cellSpacing={0} cellPadding={0} style={{ marginRight: 'auto', marginLeft: 'auto', border: '0.75pt solid #cccccc', borderCollapse: 'collapse', width: '100%' }}>
                              <tbody>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Learning Competency</span></strong></p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Score</span></strong></p>
                                    </td>
                                    <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Pretest Percent Correct for the Level</span></strong></p>
                                    </td>
                                    <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Posttest Percent Correct for the Level</span></strong></p>
                                    </td>
                                 </tr>

                                 {renderPartLabel("Part 1: Number and Algebra")}
                                 {renderCompetencyRow("1", "Solve problems involving:  a. percentage increase, and b. percentage decrease,", reportData.s1, reportData.percent_correct_s1, reportData.percent_correct_s1)}
                                 {renderCompetencyRow("2", "Solve money problems involving percentages (e. g., discount, commission, sales tax, simple interest).", reportData.s2, reportData.percent_correct_s2, reportData.percent_correct_s2)}
                                 {renderCompetencyRow("3", "Solve problems involving rates (e.g., speed).", reportData.s3, reportData.percent_correct_s3, reportData.percent_correct_s3)}
                                 {renderCompetencyRow("4", "Solve simple equations represented by bar models to find unknowns.", reportData.s4, reportData.percent_correct_s4, reportData.percent_correct_s4)}
                                 {renderCompetencyRow("5", "Use positive and negative numbers to describe directions or opposites in real-life situations.", reportData.s5, reportData.percent_correct_s5, reportData.percent_correct_s5)}
                                 {renderCompetencyRow("6", "Identify irrational numbers involving square roots and cube roots, and their locations on the number line.", reportData.s6, reportData.percent_correct_s6, reportData.percent_correct_s6)}
                                 {renderCompetencyRow("7", "Determine the square roots of perfect squares and the cube roots of perfect cubes.", reportData.s7, reportData.percent_correct_s7, reportData.percent_correct_s7)}
                                 {renderCompetencyRow("8", "Describe given rational numbers as fractions, decimals, or percentages.", reportData.s8, reportData.percent_correct_s8, reportData.percent_correct_s8)}
                                 {renderCompetencyRow("9", "Describe given rational numbers as fractions, decimals, or percentages.", reportData.s9, reportData.percent_correct_s9, reportData.percent_correct_s9)}
                                 {renderCompetencyRow("10", "Illustrate sets and their subsets, the union of sets, and the intersection of sets, through the use of Venn diagrams.", reportData.s10, reportData.percent_correct_s10, reportData.percent_correct_s10)}
                                 {renderCompetencyRow("11", "Add and subtract integers; using concrete models (e.g., counters, integer chips), pictorial models (e.g., bar models, number lines), and with integers written as numerals.", reportData.s11, reportData.percent_correct_s11, reportData.percent_correct_s11)}
                                 {renderCompetencyRow("12", "Multiply and divide integers.", reportData.s12, reportData.percent_correct_s12, reportData.percent_correct_s12)}
                                 {renderCompetencyRow("13", "Multiply and divide integers.", reportData.s13, reportData.percent_correct_s13, reportData.percent_correct_s13)}
                                 {renderCompetencyRow("14", "Perform operations on rational numbers.", reportData.s14, reportData.percent_correct_s14, reportData.percent_correct_s14)}
                                 {renderCompetencyRow("15", "Perform operations on rational numbers.", reportData.s15, reportData.percent_correct_s15, reportData.percent_correct_s15)}
                                 {renderCompetencyRow("16", "Convert units of measure within the International System of Units (SI) and across different systems of measure.", reportData.s16, reportData.percent_correct_s16, reportData.percent_correct_s16)}
                                 {renderCompetencyRow("17", "Convert units of measure within the International System of Units (SI) and across different systems of measure.", reportData.s17, reportData.percent_correct_s17, reportData.percent_correct_s17)}
                                 {renderCompetencyRow("18", "Perform operations on numbers expressed in scientific notation.", reportData.s18, reportData.percent_correct_s18, reportData.percent_correct_s18)}
                                 {renderCompetencyRow("19", "Compare and order integers.", reportData.s19, reportData.percent_correct_s19, reportData.percent_correct_s19)}
                                 {renderCompetencyRow("20", "Compare and order integers.", reportData.s20, reportData.percent_correct_s20, reportData.percent_correct_s20)}
                                 {renderCompetencyRow("21", "Order rational numbers on a number line.", reportData.s21, reportData.percent_correct_s21, reportData.percent_correct_s21)}
                                 {renderCompetencyRow("22", "Simplify numerical expressions involving integers using number properties and the order of operations (GEMDAS).", reportData.s22, reportData.percent_correct_s22, reportData.percent_correct_s22)}
                                 {renderCompetencyRow("23", "Simplify numerical expressions involving integers using number properties and the order of operations (GEMDAS).", reportData.s23, reportData.percent_correct_s23, reportData.percent_correct_s23)}
                                 {renderPartLabel("Part 2: Measurement and Geometry")}
                                 {renderCompetencyRow("24", "Solve problems involving volumes of square or rectangular pyramids", reportData.s24, reportData.percent_correct_s24, reportData.percent_correct_s24)}
                                 {renderCompetencyRow("25", "Solve problems involving the volumes of cylinders.", reportData.s25, reportData.percent_correct_s25, reportData.percent_correct_s25)}
                                 {renderCompetencyRow("26", "Solve problems involving algebraic expressions and formulas", reportData.s26, reportData.percent_correct_s26, reportData.percent_correct_s26)}
                                 {renderCompetencyRow("27", "Find the volume of a cylinder.", reportData.s27, reportData.percent_correct_s27, reportData.percent_correct_s27)}
                                 {renderCompetencyRow("28", "Determine the measures of angles and the number of sides of polygons.", reportData.s28, reportData.percent_correct_s28, reportData.percent_correct_s28)}
                                 {renderCompetencyRow("29", "Deduce the relationship between the exterior angle and the adjacent interior angle of a polygon.", reportData.s29, reportData.percent_correct_s29, reportData.percent_correct_s29)}
                                 {renderCompetencyRow("30", "Estimate volumes of square and rectangular pyramids.", reportData.s30, reportData.percent_correct_s30, reportData.percent_correct_s30)}
                                 {renderCompetencyRow("31", "Draw and describe regular and irregular polygons with 5, 6, 8, or 10 sides, based on measurements of sides and angles, using a ruler and protractor.", reportData.s31, reportData.percent_correct_s31, reportData.percent_correct_s31)}
                                 {renderCompetencyRow("32", "Classify polygons according to the number of sides, whether they are regular or irregular, and whether they are convex or non-convex.", reportData.s32, reportData.percent_correct_s32, reportData.percent_correct_s32)}
                                 {renderCompetencyRow("33", "Describe and explain the relationships between angle pairs based on their measures.", reportData.s33, reportData.percent_correct_s33, reportData.percent_correct_s33)}
                                 {renderPartLabel("Part 3: Data and Probability")}
                                 {renderCompetencyRow("34", "Solve problems using the outcomes of experiments.", reportData.s34, reportData.percent_correct_s34, reportData.percent_correct_s34)}
                                 {renderCompetencyRow("35", "Express outcomes in words and/or symbols, and represents outcomes in tables and/or graphs.", reportData.s35, reportData.percent_correct_s35, reportData.percent_correct_s35)}
                                 {renderCompetencyRow("36", "Use appropriate graphs to represent organized data: pie graph, bar graph, line graph, and stem-and-leaf plot.", reportData.s36, reportData.percent_correct_s36, reportData.percent_correct_s36)}
                                 {renderCompetencyRow("37", "Investigate different data collection and sampling techniques.", reportData.s37, reportData.percent_correct_s37, reportData.percent_correct_s37)}
                                 {renderCompetencyRow("38", "Organize statistical data in a frequency distribution table.", reportData.s38, reportData.percent_correct_s38, reportData.percent_correct_s38)}
                                 {renderCompetencyRow("39", "Interpret statistical graphs.", reportData.s39, reportData.percent_correct_s39, reportData.percent_correct_s39)}
                                 {renderCompetencyRow("40", "Interpret statistical graphs.", reportData.s40, reportData.percent_correct_s40, reportData.percent_correct_s40)}

                              </tbody>
                           </table>
                        </div>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt' }}><a name="_Hlk103365502"><strong>Summary of Results.</strong>&nbsp; The result for the content standards is expressed in terms of the raw score, percent correct, standard score, and proficiency level.&nbsp;</a></p>
                        <ul type="disc" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt', textAlign: 'justify' }}><strong><span style={{ fontFamily: 'Calibri' }}>Raw Score.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span><span style={{ fontFamily: 'Calibri' }}>The raw score is the total number of correct answers for the content standard.&nbsp;</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt', textAlign: 'justify' }}><strong><span style={{ fontFamily: 'Calibri' }}>Percent Correct.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span><span style={{ fontFamily: 'Calibri' }}>The percent correct is the raw score converted into percentage. The percent correct provides you with an easier way to determine how many correct answers you are able to obtain for each content standard. If the percent correct is close to 100%, the more correct answers you are able to attain.</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt', textAlign: 'justify' }}><strong><span style={{ fontFamily: 'Calibri' }}>Standard score.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;The scores are converted into a standard score to allow comparison of scores across content standards and across levels. A standard score near 0.0 means that the learner’s standing in the content standard is within the majority of the test takers. A standard score of more than 1.00 mean that the attainment of the competencies is high because there are several correct answers.&nbsp;</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt', textAlign: 'justify' }}><strong><span style={{ fontFamily: 'Calibri' }}>Proficiency Level.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;The proficiency level describes your present level of attainment on content standard. The levels of proficiency start from beginner to basic to competent. The beginner level means few correct answers attained in the content standard. The basic level means that the score is within the majority of the test takers. Competent means that several correct answers are attained.</span><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span></li>
                        </ul>
                        {
                           graph_data !== null &&
                           <div style={{ marginTop: "20px", paddingLeft: '120px', paddingRight: "120px" }}>
                              <Bar options={graph_options} data={graph_data} />
                           </div>
                        }


                        <div style={{ pageBreakBefore: 'always' }}></div>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '12pt' }}><strong>Summary of Results</strong></p>
                        <table cellSpacing={0} cellPadding={0} style={{ border: '0.75pt solid #cccccc', borderCollapse: 'collapse', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td style={{ width: '197.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Areas</span></strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Raw Score</span></strong></p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent Correct</span></strong></p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Standard Score</span></strong></p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Proficiency Level</span></strong></p>
                                 </td>
                              </tr>
                              {renderSummaryRow("Number and Algebra", number, 23, 15.23, 2.65)}
                              {renderSummaryRow("Measurement and Geometry", measurement, 10, 4.67, 1.62)}
                              {renderSummaryRow("Data and Probability", data, 7, 4.26, 1.39)}
                              {renderSummaryRow("Total", measurement + number + data, 40, 18.56, 3.95)}
                           </tbody>
                        </table>

                        <InterpretationInd backgroundColor='#4F81BD' />

                        <p style={{ marginTop: '0pt', marginBottom: '10pt' }}>&nbsp;</p>
                        <p style={{ marginTop: '0pt', marginBottom: '10pt' }}><strong>Name:&nbsp;</strong>{reportData.student_name}</p>
                     </div>
                  </div>
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }
      </>
   );
}