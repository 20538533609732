/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable default-case */
import React from "react";
import Iframe from 'react-iframe';
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
import ReactPlayer from 'react-player';
import { Image, Empty } from 'antd';
import SimpleBarReact from "simplebar-react";
import { PDFReader } from 'reactjs-pdf-reader';
import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

// const DocumentViewer = ({ document, documentlist, document_link = null, pdfheight, pdfshowdownload = false, pdfshowprint = false, videoheight, viewerheight, ismodal = false }) => {
const DocumentViewer = ({ document, documentlist, document_link = null, pdfshowdownload = false, pdfshowprint = false, dvheight, emptydescription = 'No documents available.', ismodal = false }) => {

   const renderPreviewer = () => {
      if (document !== null && document !== '') {
         var re = /(?:\.([^.]+))?$/;
         var ext = re.exec(document.toLowerCase());

         var subdomain = localStorage.getItem("lms-subdomain");
         // var url = `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}/${document}`;
         var url = `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${document}`;

         switch (ext[1]) {
            case "pdf":
               return <>
                  <div style={{ width: "100%", height: dvheight }}>
                     <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                        <Viewer
                           pageLayout={{
                              transformSize: ({ size }) => ({
                                 height: size.height + 30,
                                 width: size.width + 30,
                              }),
                           }}
                           fileUrl={url}
                           defaultScale={SpecialZoomLevel.PageWidth}
                           initialPage={0}
                        />
                     </Worker>
                  </div>
                  {/* <RenderPDFReader url={url} height={dvheight} /> */}
                  {/* <RenderPDFViewer url={url} height={dvheight} showdownload={pdfshowdownload} showprint={pdfshowprint} showpagecontrols={true} showfullscreen={true} /> */}
               </>

            case "doc":
            case "docx":
            case "xls":
            case "xlsx":
            case "ppt":
            case "pptx":
            case "ppsx":
               return <>
                  <div
                     className={ismodal ? 'modal-document-viewer' : 'document-viewer'}
                     style={{ height: "calc(" + dvheight + " - 10px)" }}
                     /*style={ismodal ? { height: "calc(" + dvheight + " - 10px)" } : { height: "calc(" + dvheight + " - 10px)" }}*/>
                     <Iframe
                        id="msdoc-iframe"
                        title="msdoc-iframe"
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
                        frameBorder="0"
                        height='100%'
                        width='100%'
                        loading='eager'
                     />
                  </div>
               </>

            case "png": case "jpg": case "jpeg":
               return <>
                  <div
                     className={ismodal ? 'modal-document-viewer' : 'document-viewer'}
                     // style={ismodal ? { height: dvheight } : { height: "calc(" + dvheight + " - 8px)" }}
                     style={{ height: "calc(" + dvheight + " - 8px)" }}
                  >
                     <Image
                        src={url}
                        style={{ maxHeight: "calc(" + dvheight + " - 8px)" }}
                     />
                  </div>
               </>

            case "mp4": case "avi": case "mp3": case "wav":
               return <>
                  <ReactPlayer
                     controls={true}
                     width='100%'
                     height={"calc(" + dvheight + " - 6px)"}
                     url={url.replace(/ /g, '+').replace(/#/g, '%23')}
                  />
               </>
         }
      }
      else if (document_link !== null && document_link !== '') {
         return <>
            <div
               className={ismodal ? 'modal-document-viewer' : 'document-viewer'}
               // style={ismodal ? { height: dvheight } : { height: "calc(" + dvheight + " - 10px)" }}
               style={{ height: "calc(" + dvheight + " - 10px)" }}
            >
               <a href={`${document_link}`} title="Click here to view the document" target={"_blank"}>Click here to view the document</a>
            </div>
         </>
      }
      else if (documentlist !== null && documentlist !== '') {
         return <>
            <div
               className={ismodal ? 'modal-document-viewer' : 'document-viewer'}
               // style={ismodal ? { height: dvheight } : { height: "calc(" + dvheight + " - 10px)" }}
               style={{ height: "calc(" + dvheight + " - 10px)" }}
            >
               <Empty description='Click an item in the document list to view.'
                  image="../images/brainee_mascot.png"
               />
            </div>
         </>
      }
      else {
         return <>
            <div
               className={ismodal ? 'modal-document-viewer' : 'document-viewer'}
               // style={ismodal ? { height: dvheight } : { height: "calc(" + dvheight + " - 10px)" }}
               style={{ height: "calc(" + dvheight + " - 10px)" }}
            >
               <Empty
                  description={emptydescription}
                  image="../images/brainee_mascot.png"
               />
            </div>
         </>
      }
      // return <>
      //    {
      //       documentlist !== null && documentlist !== ''
      //          ?
      //          <div className={ismodal ? 'modal-document-viewer' : 'document-viewer'}>
      //             <Empty description='Click an item in the document list to view.' />
      //          </div>
      //          :
      //          <div className={ismodal ? 'modal-document-viewer' : 'document-viewer'}>
      //             <Empty description='No uploaded documents found.' />
      //          </div>
      //    }
      // </>
   }

   return (
      <>
         {renderPreviewer(document, documentlist)}
      </>
   );
}

export default DocumentViewer;