/* eslint-disable default-case */
import React, { useEffect, useState, useReducer } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Input, Tabs, Table, Typography, Space, Tooltip, Button, Popconfirm, Modal, Breadcrumb } from 'antd';
import * as AntDIcons from '@ant-design/icons';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
// import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
// import NoticeBoardList from '../../components/notice-board/NoticeBoardList';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import platform from 'platform';
import uuid from 'react-uuid';
import { at } from 'lodash';
import { format } from 'date-fns';

import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';

// import './Responsive.css';

// import {useSelector, useDispatch} from 'react-redux'
// import { update_option_data } from '../../actions';

const { TabPane } = Tabs;
const { Text } = Typography;

export function StudentAssessment() {
   const navigate = useNavigate();
   const location = useLocation();
   const aTab = location.state !== null ? location.state.activetab : "1";

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   const [currentList, setCurrentList] = useState([]);
   const [pastList, setPastList] = useState([]);
   const [activeTab, setActiveTab] = useState("1");
   const [listLoading, setListLoading] = useState(true);

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_other_data = JSON.parse(Utils.getCurrentUserInfo(Enums.UserInfo.otherdata));
   const status = user_other_data.Status;

   useEffect(() => {
      Utils.verifyJwt();
      // const unloadCallback = (event) => {
      // //   event.preventDefault();
      // //   event.returnValue = "";
      // // localStorage.clear();
      //   return "";
      // };

      // window.addEventListener("beforeunload", unloadCallback);
      // return () => window.removeEventListener("beforeunload", unloadCallback);

      setActiveTab(() => {
         refreshList(aTab);
         return aTab
      });
   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
               </Button>
               <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const current_assigned_ass_columns = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: 'Allow',
         key: 'allow_result_viewing',
         dataIndex: 'allow_result_viewing',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.title}>
               <b><Text>{object.title}</Text></b>
               <p style={{ marginBottom: '0px', cursor: "default" }}>{object.start_date + ' - ' + object.end_date}</p>
            </Tooltip>
         ),
         width: '40%'
      },
      {
         title: 'Assessment Id',
         key: 'assessment_id',
         dataIndex: 'assessment_id',
         hidden: true,
      },
      {
         title: 'Start Date',
         key: 'start_date',
         dataIndex: 'start_date',
         hidden: true,
      },
      {
         title: 'End Date',
         key: 'end_date',
         dataIndex: 'end_date',
         hidden: true,
      },
      {
         title: 'Question Type',
         key: 'question_type',
         dataIndex: 'question_type',
         hidden: true,
      },
      {
         title: 'Term',
         dataIndex: 'term',
         key: 'term',
         width: '10%'
      },
      {
         title: 'Attempts',
         key: 'attempts',
         render: object => (
            <>{object.attempts + ' of ' + object.allowed_attempts}</>
         ),
         width: '10%'
      },
      {
         title: 'Assigned By',
         key: 'assigned_by',
         ...getColumnSearchProps('assigned_by'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.assigned_by}>
               <Text>{object.assigned_by}</Text>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  {
                     payload.allowed_attempts > payload.attempts
                        ?
                        payload.submit_status
                           ?
                           <>
                              <Tooltip placement="top" title='View Exam Result'><AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleViewAnswer(payload.id, payload.assessment_id, payload.question_type, activeTab, payload.attempts, payload.allow_result_viewing)} /></Tooltip>
                              {/* <Tooltip placement="top" title='Answer Assessment'>
                        <Popconfirm
                           title="Do you want to retake this assessment now?"
                           onConfirm={() => handleAnswerAssessment(payload.id, payload.assessment_id, payload.question_type) }
                           okText="Yes"
                           cancelText="No"
                        >
                           <AntDIcons.FormOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                     </Tooltip> */}
                           </>
                           :
                           <Tooltip placement="top" title='Answer Assessment'>
                              <Popconfirm
                                 placement='left'
                                 title="Do you want to answer this assessment now?"
                                 onConfirm={() => handleAnswerAssessment(payload.id, payload.assessment_id, payload.question_type)}
                                 okText="Yes"
                                 cancelText="No"
                              >
                                 <AntDIcons.FormOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                              </Popconfirm>
                           </Tooltip>
                        :
                        <Tooltip placement="top" title='View Exam Result'>
                           <AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleViewAnswer(payload.id, payload.assessment_id, payload.question_type, activeTab, payload.attempts, payload.allow_result_viewing)} />
                        </Tooltip>
                  }
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const past_assigned_ass_columns = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: 'Allow',
         key: 'allow_result_viewing',
         dataIndex: 'allow_result_viewing',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.title}>
               <b><Text>{object.title}</Text></b>
               <p style={{ marginBottom: '0px' }}>{object.start_date + ' - ' + object.end_date}</p>
            </Tooltip>
         ),
         width: '40%'
      },
      {
         title: 'Assessment Id',
         key: 'assessment_id',
         dataIndex: 'assessment_id',
         hidden: true,
      },
      {
         title: 'Start Date',
         key: 'start_date',
         dataIndex: 'start_date',
         hidden: true,
      },
      {
         title: 'End Date',
         key: 'end_date',
         dataIndex: 'end_date',
         hidden: true,
      },
      {
         title: 'Question Type',
         key: 'question_type',
         dataIndex: 'question_type',
         hidden: true,
      },
      {
         title: 'Term',
         dataIndex: 'term',
         key: 'term',
         width: '10%'
      },
      {
         title: 'Attempts',
         key: 'attempts',
         render: object => (
            <p>{object.attempts + ' of ' + object.allowed_attempts}</p>
         ),
         width: '10%'
      },
      {
         title: 'Assigned By',
         key: 'assigned_by',
         ...getColumnSearchProps('assigned_by'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="top" title={object.assigned_by}>
               <Text>{object.assigned_by}</Text>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  {payload.attempts !== 0
                     ?
                     <Tooltip
                        placement="top"
                        title='View Assessment Answers'
                     >
                        <AntDIcons.EyeOutlined
                           color='#4abec7'
                           style={{ cursor: 'pointer' }}
                           onClick={() => handleViewAnswer(payload.id, payload.assessment_id, payload.question_type, activeTab, payload.attempts, payload.allow_result_viewing)}
                        />
                     </Tooltip>
                     :
                     <Tooltip
                        placement="top"
                        title='Preview Assessment'
                     >
                        <AntDIcons.EyeOutlined
                           color='#4abec7'
                           style={{ cursor: 'pointer' }}
                           onClick={() => handleViewAnswer(payload.id, payload.assessment_id, payload.question_type, activeTab, payload.attempts, payload.allow_result_viewing)}
                        />
                     </Tooltip>
                  }
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const getCurrentList = () => {
      setListLoading(true);
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/listforstudents?student_id=${user_id}&t=current&at=summative`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setCurrentList(data);
            setListLoading(false);
         }
      }, (error) => {
         setListLoading(false);
         console.log(error.message);
      });
   }

   const getPastList = () => {
      setListLoading(true);
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/listforstudents?student_id=${user_id}&t=past&at=summative`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setPastList(data);
            setListLoading(false);
         }
      }, (error) => {
         setListLoading(false);
         console.log(error.message);
      });
   }

   const handleTabChange = (key) => {
      switch (key) {
         case "1":
            getCurrentList();
            setActiveTab("1");
            break;
         case "2":
            getPastList();
            setActiveTab("2");
            break;
      }
   }

   const refreshList = (tab) => {
      switch (tab) {
         case "1":
            getCurrentList();
            break;
         case "2":
            getPastList();
            break;
      }
   }

   const handleAnswerAssessment = (id, assessment_id, q_type) => {

      // localStorage.clear();

      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";

      var assignInfo = currentList.filter(element => element.id === id)[0];

      let timestampz = new Date().getTime();

      var ansInfo = {
         // Id: "ans_" + o2o_mode + "_" + uuid(),
         Id: "ans_" + o2o_mode + "_" + uuid() + "_" + timestampz + "_" + user_id,
         Assessment_id: assessment_id,
         Assignment_id: id,
         Student_id: user_id,
         User_agent: platform.ua,
         Browser: platform.name,
         Browser_version: platform.version,
         OS_platform: platform.os.family,
         Device: platform.product,
      }

      const addAnsUrl = `${process.env.REACT_APP_API_ASSESSMENT}/addanswer?at=summative`;
      axios.post(addAnsUrl, ansInfo).then(response => {
         // console.log(response);
         if (response.data) {
            const data = response.data;

            //-- This routine is for answering assessment
            const url = `${process.env.REACT_APP_API_ASSESSMENT}/questionaire?id=${assessment_id}&at=summative`;
            axios.get(url).then((response) => {
               if (response.data) {
                  const data = response.data;

                  var qs = JSON.parse(data.question_sheet);

                  for (let i = 0; i < qs.length; i++) {
                     if (qs[i].type !== 'section') {
                        delete qs[i].correct;
                     }
                  }

                  var assInfo = {
                     Id: data.id,
                     Title: data.title,
                     Term: data.term,
                     File_url: data.file_url,
                     Question_type: data.question_type,
                     Created_by: data.created_by,
                     Date_created: data.date_created,
                     Question_sheet: qs,
                     Instruction: data.instruction,
                  };

                  if (q_type.toString() === '1') {
                     // navigate('/assessment/answer-pdf',{state: {id:ansInfo.Id, assinfo, assignment_id: id}});
                     navigate('/assessment/answer-pdf', { state: { id: ansInfo.Id, assInfo, assignInfo } });
                  }
                  else if (q_type.toString() === '2') {
                     // navigate('/assessment/answer-manual',{state: {id:ansInfo.Id, assinfo, assignment_id: id}});
                     navigate('/assessment/answer-manual', { state: { id: ansInfo.Id, assInfo, assignInfo } });
                  }
               }
            }, (error) => {
               console.log(error.message);
            });

         }
      }, (error) => {
         console.log(error.message);
         // message.error('Assessment creation failed.');
         Modal.error({
            title: 'Summative Assessment',
            content: 'Attempt to answer assessment failed.',
            centered: true
         });
      });

   }

   const handleViewAnswer = (id, assessment_id, q_type, origin, attempts, allow) => {
      var assignInfo = null;

      if (origin === '1')
         assignInfo = currentList.filter(element => element.id === id)[0];
      else
         assignInfo = pastList.filter(element => element.id === id)[0];

      if (attempts !== 0 && allow === 1) {
         if (q_type.toString() === '1') {
            navigate('/studentassessment/view-ans-pdf', { state: { assessment_id, assignInfo, student_id: user_id, origin } });
         }
         else if (q_type.toString() === '2') {
            navigate('/studentassessment/view-ans-manual', { state: { assessment_id, assignInfo, student_id: user_id, origin } });
         }
      } else {
         Modal.error({
            title: 'Summative Assessment',
            content: 'Viewing of exam result is not yet allowed.',
            centered: true
         });
      }
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     {
                        status !== '0'
                           ?
                           <>
                              <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                              <li><span>/</span></li>
                              <li className="active">Brainee LMS</li>
                              <li><span>/</span></li>
                              <li className="active">Summative Assessments</li>
                           </>
                           :
                           <>
                              <li className="active">Examination</li>
                           </>
                     }

                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }} >
                     {
                        status !== '0'
                           ?
                           <>
                              <Breadcrumb.Item>
                                 <Link to="/home">Home</Link>
                              </Breadcrumb.Item>
                              <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                              <Breadcrumb.Item>Summative Assessment</Breadcrumb.Item>
                           </>
                           :
                           <>
                              <Breadcrumb.Item>Examination</Breadcrumb.Item>
                           </>
                     }
                  </Breadcrumb>
               </div>

               <div className="dashboard-item-inner3">
                  <Tabs defaultActiveKey={aTab} centered onChange={(key) => handleTabChange(key)}>
                     <TabPane style={{ overflow: "auto" }}
                        tab={(
                           <Tooltip title="Current assigned assessments">
                              <span>Current</span>
                           </Tooltip>
                        )}
                        key="1"
                     >
                        <Table
                           rowKey={'uid'}
                           loading={listLoading}
                           size='small'
                           columns={current_assigned_ass_columns}
                           dataSource={currentList}
                           pagination={false}
                           scroll={{
                              x: '1000px',
                              y: 'calc(100svh - 11.5rem)',
                           }}
                           footer={() => 'Records: ' + currentList.length}
                        />
                     </TabPane>
                     <TabPane style={{ overflow: "auto" }}
                        tab={(
                           <Tooltip title="Past assigned assessments">
                              <span>Past</span>
                           </Tooltip>
                        )}
                        key="2"
                     >
                        <Table
                           rowKey={'uid'}
                           loading={listLoading}
                           size='small'
                           columns={past_assigned_ass_columns}
                           dataSource={pastList}
                           pagination={false}
                           scroll={{
                              x: '1000px',
                              y: 'calc(100svh - 11.5rem)',
                           }}
                           footer={() => 'Records: ' + pastList.length}
                        />
                     </TabPane>
                  </Tabs>
               </div>
            </div>
         </div>

         {/* <div className="notice-area" style={{ display: "block" }}>
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}
      </>
   );
}