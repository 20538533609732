/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import axios from 'axios';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import { SendOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Descriptions, Typography, Popconfirm, Modal, Spin, Breadcrumb } from 'antd';
import StickyBox from "react-sticky-box";
import uuid from 'react-uuid';
import platform from 'platform';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { useSelector, useDispatch } from 'react-redux';

import Split from 'react-split';
import './MasteryAssessment.css';
import './MasteryAnswer.css';

// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import { PDFReader } from 'reactjs-pdf-reader';
// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
// import * as AntDIcons from '@ant-design/icons';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Text, Paragraph } = Typography;

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let pdfFile = '';

export function MasteryAnswerPDF(props) {
   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [masteryResult, setMasteryResult] = useState([]);
   const [masteryProficiencies, setMasteryProficiencies] = useState([]);

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);
   const masteryrec = useSelector(state => state.masteryrec);

   const origin = location.state.origin;
   const assessmentInfo = location.state.assInfo;
   const assignInfo = location.state.assignInfo;
   const ansId = location.state.id;
   const assessmentType = location.state.type;
   // const subdomain = localStorage.getItem("lms-subdomain");
   const subdomain = "ccph";

   const [answerSheetData, setAnswerSheetData] = useState(assessmentInfo.Question_sheet);
   const [answerData, setAnswerData] = useState(() => {
      var ansLocal = JSON.parse(localStorage.getItem(user_id + "_" + assessmentInfo.Id + "_myanswers"));
      if (ansLocal !== null)
         return ansLocal;
      else {
         return initializeAnswerData(assessmentInfo.Question_sheet);
      }
   });

   const [startTime, setStartTime] = useState(() => {
      var timeStarted = JSON.parse(localStorage.getItem(user_id + "_" + assessmentInfo.Id + "_timestarted"));
      if (timeStarted !== null) {
         var val = timeStarted;
         return val;
      } else {
         var val = Date.now();
         localStorage.setItem(user_id + "_" + assessmentInfo.Id + "_timestarted", JSON.stringify(val));
         return Date.now();
      }
   });

   const [isSubmitted, setIsSubmitted] = useState(false);
   const [pdfLoaded, setPdfLoaded] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      // const unloadCallback = (event) => {
      //   event.preventDefault();
      //   event.returnValue = "";
      // return "";
      // };

      // window.addEventListener("beforeunload", unloadCallback);      
      // localStorage.clear();

      pdfFile = '';
   }, [assignInfo.id]);

   function initializeAnswerData(data) {
      let ansData = [];
      let tmpdata = {};

      data.map(({ id, type, option_labels }, idx) => {
         if (data[idx].type.includes("section")) {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": data[idx].correct,
            }
         } else if (data[idx].type.includes("multiple")) {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": setDefaultAnswers(type, option_labels.length),
               // "considered":"0",
            }
         } else if (data[idx].type === "short_answer") {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": [""],
               "considered": "0",
            }
         } else {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": [""],
            }
         }

         ansData.push(tmpdata);
      });

      return ansData;
   }

   function setDefaultAnswers(type, options_length) {
      let retVal = [];
      var multiple = type.includes("multiple");

      if (multiple) {
         for (var i = 0; i < options_length; i++) {
            retVal.push("0");
         }
      }

      return retVal;
   }

   function handleAnswerRequest(data, idx) {
      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];

      var asd = [...answerSheetData];
      var ad = [...answerData];

      // var section = asd[idx].type.includes("section");

      // if (section) {
      //    ad[idx].correct = [];
      //    ad[idx].correct.push.apply(ad[idx].correct, asd[idx].correct);
      // }

      var multiple = ad[idx].type.includes("multiple");
      var matching = ad[idx].type.includes("matching");

      asd[idx].answer = [];
      ad[idx].answer = [];

      if (multiple || matching) {
         asd[idx].answer.push.apply(asd[idx].answer, data);
         ad[idx].answer.push.apply(ad[idx].answer, data);
      } else {
         asd[idx].answer.push(data);
         ad[idx].answer.push(data);
      }

      // if (asd[idx].type.includes("short")) {
      //    asd[idx].answer.push(data);
      //    ad[idx].answer.push(data);
      // }
      // else {
      //    asd[idx].answer.push.apply(asd[idx].answer, data);
      //    ad[idx].answer.push.apply(ad[idx].answer, data);
      // }

      pdfFile = assessmentInfo.File_url;

      //-- Save answer to local storage after every answer's filled
      localStorage.removeItem(user_id + "_" + assessmentInfo.Id + "_myanswers");
      localStorage.setItem(user_id + "_" + assessmentInfo.Id + "_myanswers", JSON.stringify(ad));
      // var ansData = localStorage.getItem("myanswers");
      // console.log(JSON.parse(ansData));
      // localStorage.removeItem("myanswers");

      setAnswerSheetData(asd);
      setAnswerData(ad);
   }

   const handleSubmit = () => {
      setIsSubmitted(true);
      // message.info(JSON.stringify(answerData));
      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
      let timestampz = new Date().getTime();

      var ansInfo = {
         Id: "ans_" + o2o_mode + "_" + uuid() + "_" + timestampz + "_" + user_id,
         Mastery_assessment_id: assessmentInfo.Id,
         Mastery_assignment_id: assignInfo.id,
         Student_id: user_id,
         User_agent: platform.ua,
         Browser: platform.name,
         Browser_version: platform.version,
         OS_platform: platform.os.family,
         Device: platform.product,
         Assessment_type: assessmentType,
         // Answer: JSON.stringify(answerData), 
         Answer: JSON.stringify(answerData.length > 0 ? answerData : JSON.parse(localStorage.getItem(user_id + "_" + assessmentInfo.Id + "_myanswers"))),
         Submit_status: 1,
         Score: 0,
         Essay_score: 0,
         Expiration: null,
         Status: -1,
      }

      // var ansInfo = {
      //    Id: ansId,
      //    Mastery_assessment_id: assessment_info.Id,
      //    Answer: JSON.stringify(answerData), 
      //    Submit_status: 1, 
      //    Score: 0,
      //    Essay_score: 0,
      //    Expiration: null,
      //    Status: -1,
      //    Student_id: user_id, 
      //    Mastery_assignment_id: assign_info.id,
      // }

      const updateAnsUrl = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/updateanswer?assessment_type=${assessmentType}&cache=${uuid()}`;
      axios.post(updateAnsUrl, ansInfo).then(response => {
         if (response.data) {
            const data = response.data;

            localStorage.removeItem(user_id + "_" + assessmentInfo.Id + "_timestarted");
            localStorage.removeItem(user_id + "_" + assessmentInfo.Id + "_myanswers");

            setIsSubmitted(false);

            handleNavigate();
         }
      }, (error) => {
         setIsSubmitted(false);

         Modal.error({
            title: 'Mastery Assessment',
            content: 'Submission of assessment answers failed.',
            centered: true
         });
      });
   }


   // const getMasteryProficiencies = () => {
   //    axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/listproficiencies`).then((response) => {
   //       if (response.data) {
   //          const data = response.data;

   //          setMasteryProficiencies(data);
   //       }
   //    }, (error) => {
   //       console.log(error.message);
   //    });
   // }

   // const handleTimeOut = () => {
   //    Modal.error({
   //       content: 'Your time is up!',
   //       centered: true,
   //       onOk: () => handleSubmit(),
   //    });
   // }

   const handleNavigate = () => {
      // navigate('/studentmasteryassessment/preview', {state: {assign_id: assign_info.id, mastery_id: masteryrec.id, assignInfo: assign_info, auto_proceed: true}})
      navigate(`/studentmasteryassessment/preview?assign_id=${assignInfo.id}&mastery_id=${masteryrec.id}&origin=${origin}&auto_proceed=${true}&qt=1`);
   }

   function sPDFFile() {
      pdfFile = assessmentInfo.File_url;
      return true;
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
               style={{ height: `calc(100vh - 2rem)` }}
            >
               <div>
                  {assessmentInfo.File_url
                     ?
                     <div className="dashboard-item">
                        <div className="dashboard-item-inner">
                           {/* <ul>
                              <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                              <li><span>/</span></li>
                              <li className="active">Brainee LMS</li>
                              <li><span>/</span></li>
                              <li><a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: "1" } })}>Mastery Assessments</a></li>
                              <li><span>/</span></li>
                              <li><a href="#" onClick={() => navigate(`/studentmasteryassessment/preview?origin=${origin}&qt=${assignInfo.mode}`)}>Start</a></li>
                              <li><span>/</span></li>
                              <li className="active">{Utils.GetTypeLabel(assessmentType)}</li>
                           </ul> */}
                           <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                              <Breadcrumb.Item>
                                 <Link to="/home">Home</Link>
                              </Breadcrumb.Item>
                              <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                              <Breadcrumb.Item>
                                 <a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: "1" } })}>Mastery Assessment</a>
                              </Breadcrumb.Item>
                              <Breadcrumb.Item>
                                 <a href="#" onClick={() => navigate(`/studentmasteryassessment/preview?origin=${origin}&qt=${assignInfo.mode}`)}>Start</a>
                              </Breadcrumb.Item>
                              <Breadcrumb.Item>{Utils.GetTypeLabel(assessmentType)}</Breadcrumb.Item>
                           </Breadcrumb>
                        </div>

                        <div className="pdf-all-page-container">
                           <div style={{ width: "100%", height: "calc(100vh - 58px)" }}>
                              <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                 <Viewer
                                    pageLayout={{
                                       transformSize: ({ size }) => ({
                                          height: size.height + 30,
                                          width: size.width + 30,
                                       }),
                                    }}
                                    fileUrl={assessmentInfo.File_url.includes('base64') ? assessmentInfo.File_url : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${assessmentInfo.File_url}`}
                                    defaultScale={SpecialZoomLevel.PageWidth}
                                    initialPage={0}
                                 />
                              </Worker>
                           </div>
                        </div>
                        {/* <RenderPDFReader url={assessmentInfo.File_url.includes('base64') ? assessmentInfo.File_url : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${assessmentInfo.File_url}`} height={"calc(100vh - 58px)"} /> */}
                     </div>
                     :
                     <div className='temp-answer-sheet-content2'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  }
               </div>

               <div>
                  {answerSheetData
                     ?
                     <div className="notice-item">
                        <div className='answer-sheet-content'>
                           <SimpleBarReact>
                              <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "5px", width: "100%" }} column={4}>
                                 <Descriptions.Item label={"Name"} span={4} style={{ textAlign: "center" }}>
                                    <Paragraph style={{ textAlign: "left", width: "100%" }}>{name}</Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Competency Title"} span={4} style={{ textAlign: "center" }}>
                                    <Paragraph style={{ textAlign: "left" }}>{assessmentInfo.Title}</Paragraph>
                                 </Descriptions.Item>

                                 {/* <Descriptions.Item label={"Date"} span={2} style={{textAlign:"center", width:"50%"}}>
                                 <Paragraph style={{textAlign:"left"}}>{assInfo.Date_created}</Paragraph>
                              </Descriptions.Item>
                              <Descriptions.Item label={"Timer"} span={2} style={{textAlign:"center", width:"50%"}}>
                                 <Paragraph>
                                    {
                                       assignInfo.enable_timer === 1
                                       ?
                                       <b>
                                          <Countdown 
                                             controlled={false}
                                             date={startTime + (assignInfo.duration * 60 * 1000)} 
                                             onComplete={() => handleTimeOut()}
                                          >
                                             <b>Your time is up!</b>
                                          </Countdown>
                                       </b>
                                       :
                                       <b>No Time Limit</b>
                                    }
                                 </Paragraph>
                              </Descriptions.Item>*/}

                              </Descriptions>
                              <StickyBox style={{ zIndex: 1000 }}>
                                 <Popconfirm
                                    title="Have you completed your answers?"
                                    onConfirm={() => handleSubmit()}
                                    okText="Yes"
                                    cancelText="No"
                                    icon={<QuestionCircleOutlined />}
                                 >
                                    <Button className='button-shadow' type='primary' block shape='round' style={{ marginBottom: "15px" }} disabled={isSubmitted}>
                                       <Text ellipsis style={{ color: "#fff" }}><SendOutlined />&nbsp;Submit Answer</Text>
                                    </Button>
                                 </Popconfirm>
                              </StickyBox>

                              <ul className="questions" style={{ paddingLeft: "0px" }}>
                                 {answerSheetData.map(({ id, type, points, option_labels, image_url }, idx) => {

                                    if (type === "section") {
                                       qcardIdx = 1;
                                       idxCol.push(sectionIdx);
                                       sectionIdx++;
                                    } else {
                                       idxCol.push(qcardIdx);
                                       qcardIdx++;
                                    }

                                    return (
                                       <li>
                                          <BraineeQuestionCard
                                             key={id}
                                             index={idxCol[idx]}
                                             id={idx}
                                             type={type}
                                             points={points}
                                             correct={answerData[idx].answer}
                                             option_labels={option_labels}
                                             image_url={image_url}
                                             question_type={'1'}
                                             mode={'answer'}
                                             answerCallback={handleAnswerRequest}
                                             card_bg={location.state.card_bg}
                                             assessment_type={'mastery'}
                                          />
                                       </li>
                                    );
                                 })}
                              </ul>
                           </SimpleBarReact>
                        </div>
                     </div>
                     :
                     <div className='temp-answer-sheet-content2'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  }
               </div>
            </Split>
         </div>
      </>
   )
}
