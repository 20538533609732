import React, { useContext, useRef } from "react"
import { CanvasContext } from "./hooks/LessonPage"
import VideoImageElement from "./LessonComponents/VideoImageElement"
import TextElement from "./LessonComponents/TextElement"
import ObjectElement from "./LessonComponents/ObjectElement"
import FilesElement from "./LessonComponents/FilesElement"

const componentMap = {
  TEXT: TextElement,
  IMAGE: VideoImageElement,
  VIDEO: VideoImageElement,
  OBJECT: ObjectElement,
  WEB: VideoImageElement,
  FILE: FilesElement
}

const getEnableResize = type => {
  return {
    bottom: type === "IMAGE",
    bottomLeft: true,
    bottomRight: true,

    top: type === "IMAGE",
    topLeft: true,
    topRight: true,

    left: true,
    right: true
  }
}
const CanvasComponent = props => {
  const { state, actions } = useContext(CanvasContext)
  const { dimension, position, id, type, metadata, backgroundcolor } = props
  const [showGrids, setShowGrids] = React.useState(false)
  const [isReadOnly, setIsReadOnly] = React.useState(true)
  const elementRef = React.useRef(null)
  const isDragged = useRef(false)
  // const Component = type && componentMap[type]

  const activeSelection = state?.activeSelection

  const onBlur = event => {
    const toolbarElement = document.querySelector("#toolbar")
    if (
      event.currentTarget.contains(event?.relatedTarget) ||
      toolbarElement?.contains(event?.relatedTarget)
    ) {
      return
    }
    setIsReadOnly(true)
    actions?.setEnableQuillToolbar(false)
    if (id && activeSelection) {
      activeSelection.delete(id)
      actions?.setActiveSelection(new Set(activeSelection))
    }
  }

  const getComponent = () => {
    const Component = type && componentMap[type]
    if (!Component || !id) return null
    return (
      <Component
        key={id}
        id={id}
        type={type}
        position={position}
        dimension={dimension}
        metadata={metadata}
        isreadonly={isReadOnly}
        backgroundcolor={backgroundcolor}
      />
    )
  }

  const style = {
    outline: "none",
    border: `2px solid ${
      (id && state?.activeSelection.has(id)) || showGrids || isDragged.current
        ? "#21DEE5"
        : "transparent"
    }`
  }


  const handleClass =
    id && state?.activeSelection.has(id) && state?.activeSelection.size === 1
      ? "showHandles"
      : ""

  const onDoubleClick = () => {
    if (!isReadOnly) return
    setIsReadOnly(false)
    actions?.setEnableQuillToolbar(true)
  }

  return (
    getComponent()
  )
}

export default CanvasComponent
