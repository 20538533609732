export const AssessmentOptionsModelDefault = {
   id: '',
   assessment_id: '',
   assigned_by: '1',
   duration: '1',
   allowed_attempts: '1',
   passing_percentage: '50',
   allow_result_viewing: '0',
   enable_timer: '0',
   start_date: '',
   end_date: '',
   assigned_students: '',
   term: ''
}

export const AK_SectionModel = {
   id: "",
   type: "section",
   correct: [""],
   option_labels: ""
}

export const AK_ShortAnswerModel = {
   id: "",
   type: "section",
   correct: [""],
   point: "1",
   question: ""
}

export const AK_EssayModel = {
   id: "",
   type: "long_answer",
   correct: [""],
   point: "1",
   question: ""
}