import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Empty, Input, Tabs, Table, Typography, Space, Tooltip, Button, Select, Row, Col, message, Popconfirm, Radio, 
   Tag, Drawer, notification, Modal, Breadcrumb } from 'antd';
import { SearchOutlined, UsergroupAddOutlined, DeleteOutlined, ProjectOutlined, EditOutlined, EyeOutlined, UndoOutlined, RestOutlined, PlusOutlined
 } from '@ant-design/icons';
import SideCalendar from '../../components/side-calendar/SideCalendar';
import NoticeBoard from '../../components/notice-board/NoticeBoard';
import Highlighter from 'react-highlight-words';
// import './MasteryAssessment.css';
import AssessmentAssignTool from '../../components/assessment/AssessmentAssignTool';
import SimpleBarReact from "simplebar-react";
import axios from 'axios';
import uuid from 'react-uuid';
// import { Levels, MasteryLevels } from '../../constants'
// import { at } from 'lodash';
// import NoticeBoardList from '../../components/notice-board/NoticeBoardList';
import Utils from '../../misc/Utils';
// import {useSelector, useDispatch} from 'react-redux'
// import { update_option_data } from '../../actions';

const { TabPane } = Tabs;
const { Text } = Typography;
// const { Option } = Select;
const masterListData = [];

export function MasteryContents() {
   const navigate = useNavigate();
   const location = useLocation();
   const aTab = location.state !== null ? location.state.activetab : "1";

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   const [questionTypeModalVisible, setQuestionTypeModalVisible] = useState(false);
   const [questionType, setQuestionType] = useState(1);
   const [showMasterList, setShowMasterList] = useState(false);
   const [selectedAssessment, setSelectedAssessment] = useState("")

   const [assOptions, setAssOptions] = useState(null);

   const [assignVisible, setAssignVisible] = useState(false);
   const [assTitle, setAssTitle] = useState('');
   const [assLevel, setAssLevel] = useState('');
   const [amsteryLevel, setMasteryLevel] = useState('');
   const [masterList, setMasterList] = useState([]);
   const [deleteList, setDeletedList] = useState([]);
   const [activeTab, setActiveTab] = useState("1");

   useEffect(() => {
      Utils.verifyJwt();
      // const unloadCallback = (event) => {
      // //   event.preventDefault();
      // //   event.returnValue = "";
      // // localStorage.clear();
      //   return "";
      // };

      // window.addEventListener("beforeunload", unloadCallback);
      // return () => window.removeEventListener("beforeunload", unloadCallback);

      // getSchoolTermList();
      // getMasterList();
      setActiveTab(() => {
         refreshList(aTab);
         return aTab
      });
   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const assigned_ass_columns = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.title}>
               <b><Text>{object.title}</Text></b>
               <p style={{ marginBottom: '0px' }}>{object.start_date + ' - ' + object.end_date}</p>
            </Tooltip>
         ),
         width: '40%'
      },
      {
         title: 'Start Date',
         key: 'start_date',
         dataIndex: 'start_date',
         hidden: true,
      },
      {
         title: 'End Date',
         key: 'end_date',
         dataIndex: 'end_date',
         hidden: true,
      },
      {
         title: 'Term',
         dataIndex: 'term',
         key: 'term',
         width: '10%'
      },
      {
         title: 'Assigned By',
         key: 'assigned_by',
         ...getColumnSearchProps('assigned_by'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.assigned_by}>
               <Text>{object.assigned_by}</Text>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Date Assigned',
         key: 'date_created',
         dataIndex: 'date_created',
         sorter: (a, b) => { return a.date_created.localeCompare(b.date_created) },
         width: '20%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Edit Assigment'><UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssessmentAssign(payload.id, payload.title, payload.term)} /></Tooltip>
                  <Tooltip placement="top" title='Remove'>
                     <Popconfirm
                        title="Are you sure you want to delete this assigned assessment?"
                        onConfirm={() => handleDeleteAssignment(payload.id)}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No">

                        <DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
                  <Tooltip placement="top" title='Report'><ProjectOutlined style={{ cursor: 'pointer' }} /></Tooltip>
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const masterListColumns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: 'Topic',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: title => (
            <Tooltip placement="topLeft" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '29%'
      },
      {
         title: 'Level',
         dataIndex: 'level_desc',
         key: 'level_desc',
         sorter: (a, b) => { return a.level_desc.localeCompare(b.level_desc) },
         width: '12%'
      },
      {
         title: 'Subject',
         key: 'subject_desc',
         dataIndex: 'subject_desc',
         sorter: (a, b) => { return a.subject_desc.localeCompare(b.subject_desc) },
         ...getColumnSearchProps('subject_desc'),
         ellipsis: {
            showTitle: true,
         },
         render: subject => (
            <Tooltip placement="topLeft" title={subject}>
               <span style={{ cursor: "default" }}>{subject}</span>
            </Tooltip>
         ),
         width: '25%'
      },
      {
         title: 'Type',
         dataIndex: 'assessment_type',
         key: 'assessment_type',
         sorter: (a, b) => { return a.assessment_type.localeCompare(b.assessment_type) },
         width: '12%'
      },
      {
         title: 'Date Created',
         key: 'date_created',
         dataIndex: 'date_created',
         sorter: (a, b) => { return a.date_created.localeCompare(b.date_created) },
         width: '15%'
      },
      {
         title: 'Question Type',
         key: 'question_type',
         dataIndex: 'question_type',
         hidden: true,
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Edit'><EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssessment(payload.id, payload.question_type)} /></Tooltip>
                  <Tooltip placement="top" title='Remove'>
                     <Popconfirm
                        title="Are you sure you want to delete this Content?"
                        onConfirm={() => handleDeleteAssessment(payload.id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
               </Space>
            </>
         },
         width: '7%'
      },
   ].filter(item => !item.hidden);

   const recycle_bin_columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
         // ellipsis: {
         //    showTitle: true,
         // },
         // render: id => (
         //    <Radio value={id} defaultChecked={false} onClick={() => setSelectedAssessment(id)} />
         // ),
         // width:'7%'
      },
      {
         title: '',
         dataIndex: 'assessment_id',
         key: 'assessment_id',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: title => (
            <Tooltip placement="topLeft" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '57%'
      },
      {
         title: 'Origin',
         key: 'origin',
         dataIndex: 'origin',
         sorter: (a, b) => { return a.origin.localeCompare(b.origin) },
         width: '13%'
      },
      {
         title: 'Date Deleted',
         key: 'date_deleted',
         dataIndex: 'date_deleted',
         sorter: (a, b) => { return a.date_deleted.localeCompare(b.date_deleted) },
         width: '20%'
      },
      {
         title: 'Question Type',
         key: 'question_type',
         dataIndex: 'question_type',
         hidden: true,
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='View'>
                     <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleAssessmentView(payload.assessment_id, payload.question_type, payload.origin)} />
                  </Tooltip>
                  <Tooltip placement="top" title='Restore'>
                     <Popconfirm
                        title="Do you want to restore this assessment?"
                        onConfirm={() => payload.origin.toLowerCase() === 'master list' ? handleRestoreAssessment(payload.id) : handleRestoreAssignedAssessment(payload.id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <UndoOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const handleCancelModal = () => {
      setQuestionTypeModalVisible(false);
      setShowMasterList(false);
      setQuestionType(1);
   }

   // const handleCopyAssessment = (id) => {
   //    var assinfo = null;

   //    axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/info?id=${id}`).then((response) => {
   //       if (response.data) {
   //          const data = response.data;

   //          var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
   //          var utc = new Date().toJSON().slice(0,19);

   //          assinfo = {
   //             Id: "ass_" + o2o_mode + "_" + uuid(),
   //             Title: data.title + " - ("+utc+")", 
   //             Term: data.term,
   //             File_url: data.file_url, 
   //             Question_type: data.question_type, 
   //             Created_by: data.created_by, 
   //             Copied_from: data.id, 
   //             Question_sheet: data.question_sheet
   //          };

   //          //-- Insert duplicated assessment
   //          const url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/add`
   //          axios.post(url, assinfo).then(response => {
   //             if (response.data) {
   //                const data = response.data;
   //                Modal.success({
   //                   content: 'A copy of your assessment has been created.',
   //                   centered: true,
   //                   onOk: getMasterList(),
   //                });
   //             }
   //          }, (error) => {
   //             console.log(error.message);
   //             Modal.error({
   //                content: 'Assessment duplication failed.',
   //                centered: true
   //             });
   //          });  
   //       }
   //    }, (error) => {
   //       console.log(error.message);
   //    });
   // }      

   const handleDeleteAssessment = (id) => {
      axios.post(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/delete?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               content: 'Your mastery assessment has been deleted.',
               centered: true,
               onOk: refreshList(activeTab)
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleDeleteAssignment = (id) => {
      axios.post(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/deleteassigned?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               content: 'Your assigned assessment has been deleted.',
               centered: true,
               onOk: refreshList(activeTab)
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleRestoreAssessment = (id) => {
      axios.post(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/undelete?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               content: 'Your assessment has been restored.',
               centered: true,
               onOk: refreshList(activeTab)
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleRestoreAssignedAssessment = (id) => {
      axios.post(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/undeleteassigned?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               content: 'Your assigned assessment has been restored.',
               centered: true,
               onOk: refreshList(activeTab)
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleCreateAssessment = () => {
      setQuestionTypeModalVisible(false);

      if (questionType === 1) {
         navigate('/masteryassessment/create-pdf', { state: { title: assTitle } });
      } else if (questionType === 2) {
         navigate('/masteryassessment/create-manual', { state: { title: assTitle } });
      }
   };

   const handleEditAssessment = (id, q_type) => {
      if (q_type.toString() === '1') {
         navigate('/masteryassessment/edit-pdf', { state: { assessmentID: id } });
      }
      else if (q_type.toString() === '2') {
         navigate('/masteryassessment/edit-manual', { state: { assessmentID: id } });
      }
   };

   const handleAssessmentView = (id, q_type, origin) => {
      if (q_type.toString() === '1') {
         navigate('/masteryassessment/view-pdf', { state: { id, origin } });
      }
      else if (q_type.toString() === '2') {
         navigate('/masteryassessment/view-manual', { state: { id, origin } });
      }
   }

   const handleQuestionType = (e) => {
      setQuestionType(e.target.value);
   };

   const rowSelection = {
      selectedAssessment,
      onChange: (selectedRowKeys, selectedRows) => {
         if (selectedRows[0].key != undefined) {
            console.log(selectedRows[0].key);
         }

         setSelectedAssessment(selectedRows[0].id);
      },
      type: 'radio'
   };

   // const handleAssignAssessment = (ass_id, title, term) => {      
   //    let ass_options_default = {
   //       id: '',
   //       assessment_id: ass_id,
   //       assigned_by: '1',
   //       duration: '1',
   //       allowed_attempts: '1',
   //       passing_percentage: '50',
   //       allow_result_viewing: '0',
   //       enable_timer: '0',
   //       start_date: '',
   //       end_date: '',
   //       assigned_students: '',
   //       term: term, 
   //       level: null
   //    }

   //    setAssOptions(ass_options_default);
   //    setAssTitle(title);
   //    setAssignVisible(true);
   // };

   const handleEditAssessmentAssign = (ass_id, title, term) => {
      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/assigninfo?id=${ass_id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            let ass_options = {
               id: data.id,
               assessment_id: data.assessment_id,
               assigned_by: data.assigned_by,
               duration: data.duration.toString(),
               allowed_attempts: data.allowed_attempts.toString(),
               passing_percentage: data.passing_percentage.toString(),
               allow_result_viewing: data.allow_result_viewing.toString(),
               enable_timer: data.enable_timer.toString(),
               start_date: data.start_date,
               end_date: data.end_date,
               assigned_students: data.assigned_students,
               term: data.term,
               level: data.level
            };

            setAssOptions(ass_options);
         }
      }, (error) => {
         console.log(error.message);
      });

      setAssTitle(title);
      setAssignVisible(true);
   };

   const handleSetOptions = (data) => {
      // message.info(JSON.stringify(data));
      let tempAssOptions = null;
      tempAssOptions = JSON.parse(JSON.stringify(data));
      setAssOptions(tempAssOptions);

      // onDrawerClose()
   }

   const onDrawerClose = () => {
      setAssignVisible(false);
   }

   const handleSaveAssignment = (data) => {
      // message.info(JSON.stringify(data));
      if (data.id === '') { //--New assignment
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         var assignmentData = data;

         assignmentData.id = "ass_assign_" + o2o_mode + "_" + uuid();

         // message.info(JSON.stringify(assignmentData));

         const url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/assign`
         axios.post(url, assignmentData).then(response => {
            // console.log(response);
            if (response.data) {
               const data = response.data;
               // message.success('Assessment was created.');
               // Modal.success({
               //    content: 'Assessment was assigned.',
               //    centered: true,
               // });
               notification.success({
                  message: `Assessment Assignment`,
                  description:
                     'Assessment was assigned successfully',
               });
            }
         }, (error) => {
            console.log(error.message);
            // message.error('Assessment creation failed.');
            notification.error({
               message: `Assessment Assignment`,
               description:
                  'Assessment assignment failed',
            });
         });
      } else {//-- Edit assignment
         const url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/assignupdate`
         axios.post(url, data).then(response => {
            // console.log(response);
            if (response.data) {
               const data = response.data;
               // message.success('Assessment was created.');
               // Modal.success({
               //    content: 'Assessment was assigned.',
               //    centered: true,
               // });
               notification.success({
                  message: `Assessment Assignment`,
                  description:
                     'Assessment assignment was updated successfully',
               });
            }
         }, (error) => {
            console.log(error.message);
            // message.error('Assessment creation failed.');
            notification.error({
               message: `Assessment Assignment`,
               description:
                  'Assessment assignment update failed',
            });
         });
      }
   }

   const renderDrawer = () => {
      return <>
         <Drawer
            title={assTitle}
            placement="right"
            size={"default"}
            closable={false}
            onClose={onDrawerClose}
            visible={assignVisible}
            extra={
               <Space>
                  <Button type='primary' shape='round' onClick={onDrawerClose}>Close</Button>
               </Space>
            }
         >
            <SimpleBarReact style={{ height: "calc(100vh - 110px)" }}>
               <AssessmentAssignTool
                  mode={"drawer"}
                  options={assOptions}
                  parentCallback={handleSetOptions}
                  saveCallback={handleSaveAssignment}
               />
            </SimpleBarReact>
         </Drawer>
      </>
   }

   const getMasterList = () => {
      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/listallcontents`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setMasterList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }


   const getRecycleBin = () => {
      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/listrecyclebin`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setDeletedList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleTabChange = (key) => {
      switch (key) {
         case "1":
            getMasterList();
            setActiveTab("1");
            break;
         case "2":
            getRecycleBin();
            setActiveTab("2");
            break;
      }
   }

   const refreshList = (tab) => {
      switch (tab) {
         case "1":
            getMasterList();
            break;
         case "2":
            getRecycleBin();
            break;
      }
   }

   return (
      <>
         {assOptions && renderDrawer()}

         {/* 
            Question types 
            1 = Upload
            2 = Create Manually
         */}

         <div className="dashboard-area">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Mastery Assessment Contents</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>Mastery Assessment Content</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               <Row gutter={24}>
                  <Col className="gutter-row" span={18}>
                     {/* <Input
                        allowClear
                        size='large'
                        style={{borderRadius: 7}}
                        placeholder="Enter mastery assessment title"
                        onChange={(e) => setAssTitle(e.target.value)}
                        // suffix={<AntDIcons.SearchOutlined style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }} />}
                     /> */}
                  </Col>
                  <Col className="gutter-row" span={6}>
                     <Tooltip placement="bottom" title='Create Mastery Assessment'>
                        <Button type="primary" size="large" shape='round' block onClick={() => setQuestionTypeModalVisible(true)}>
                           <Text ellipsis style={{ color: "#fff" }}><PlusOutlined />&nbsp;Create Mastery</Text>
                        </Button>
                     </Tooltip>
                     <Modal
                        title="Select Questionaire Option"
                        centered
                        destroyOnClose={true}
                        visible={questionTypeModalVisible}
                        onOk={() => handleCreateAssessment()}
                        onCancel={() => handleCancelModal()}
                     >
                        <Radio.Group onChange={(e) => handleQuestionType(e)} value={questionType}>
                           <Space direction="vertical">
                              <Radio value={1} onClick={() => setShowMasterList(false)}>
                                 <Tooltip placement="right" title='Upload questionaire in pdf format'>
                                    Upload {localStorage.getItem('cp_asd') !== null ? ' (Restore unfinished content)' : ''}
                                 </Tooltip>
                              </Radio>
                              <Radio value={2} onClick={() => setShowMasterList(false)}>
                                 <Tooltip placement="right" title='Enter the questions manually'>
                                    Create {localStorage.getItem('cm_asd') !== null ? ' (Restore unfinished content)' : ''}
                                 </Tooltip>
                              </Radio>
                              {/* <Radio value={3} onClick={() => setShowMasterList(true)}>
                                 <Tooltip placement="right" title='Select from the master list'>
                                    Master List
                                 </Tooltip>
                              </Radio> */}
                           </Space>
                        </Radio.Group>
                        {
                           showMasterList
                           &&
                           <Table
                              rowSelection={rowSelection}
                              expandable={{
                                 expandedRowRender: record => <p style={{ margin: 0 }}><Tag color={"#4abec7"}>Created By</Tag> {record.created_by}</p>
                              }}
                              pagination={{ pageSize: 5 }}
                              size='small'
                              columns={masterListColumns}
                              dataSource={masterListData}
                              bordered={false}
                              style={{ marginTop: "10px" }} />
                        }
                     </Modal>
                  </Col>

               </Row>

               <div className="dashboard-item-inner3">
                  <Tabs defaultActiveKey={aTab} centered onChange={(key) => handleTabChange(key)}>
                     <TabPane
                        tab={(<Tooltip title="List of all created Mastery Assessment Contents">
                           <span>Content List</span>
                        </Tooltip>
                        )}
                        key="1"
                     >
                        <Table
                           size='small'
                           columns={masterListColumns}
                           dataSource={masterList}
                           pagination={{ pageSize: 5 }}
                        />
                     </TabPane>
                     <TabPane
                        tab={(
                           <Tooltip title="List of all deleted Mastery Assessment Contents">
                              <span><RestOutlined /> Recycle Bin</span>
                           </Tooltip>
                        )}
                        key="2"
                     >
                        <Table
                           size='small'
                           columns={recycle_bin_columns}
                           dataSource={deleteList}
                           pagination={{ pageSize: 5 }}
                        />
                     </TabPane>
                  </Tabs>
               </div>
            </div>
         </div>

         <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
               {/* <NoticeBoardList/> */}
            </div>
         </div>
      </>
   );
}