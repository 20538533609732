import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Empty, message, Modal, Spin, Breadcrumb } from 'antd';
import './Assessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import StickyBox from "react-sticky-box";
// import QuestionCard from '../../components/assessment/QuestionCard';
import AssessmentAssignTool from '../../components/assessment/AssessmentAssignTool';
import AssessmentTools from '../../components/assessment/AssessmentTools';
// import { CardRequestHelper } from './Helpers';
import { CardRequestHelper } from '../../helper/AQHelper';

import Split from 'react-split';
// import './SplitPanel.css';
// import './Responsive.css';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';

// const questionData = [];

var assOptionsTemplate = {
   id: '',
   assessment_id: '',
   assigned_by: '1',
   duration: '1',
   allowed_attempts: '1',
   passing_percentage: '50',
   allow_result_viewing: '0',
   enable_timer: '0',
   start_date: '',
   end_date: '',
   assigned_students: '',
   term: ''
};

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let tempAssOptions = null;

export function EditManual(props) {
   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [answerSheetData, setAnswerSheetData] = useState(() => {
      var answer_sheet = JSON.parse(localStorage.getItem(user_id + "sa_asd"));
      if (answer_sheet !== null) return answer_sheet;
      else return [];
   });

   const [showAssessmentTools, setShowAssessmentTools] = useState(true);
   const [showAssignment, setShowAssignment] = useState(false);
   const [assOptions, setAssOptions] = useState(assOptionsTemplate);
   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: "",
      Term: "",
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: "",
      Level: "",
      Subject: "",
      Instruction: ""
   });

   const [hasAnswers, setHasAnswers] = useState(false);

   const location = useLocation();
   const navigate = useNavigate();


   useEffect(() => {
      Utils.verifyJwt();

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'co-teacher' && user_role.toLowerCase() !== 'registrar')
         navigate('/home');
      else {
         const assessmentID = location.state.assessmentID;
         // getAssessmentInfo(assessmentID);
         checkIfHasAnswers(assessmentID);
      }
   }, [location.state.assessmentID]);

   const checkIfHasAnswers = (assessment_id) => {
      var url = `${process.env.REACT_APP_API_ASSESSMENT}/hasanswers?assessment_id=${assessment_id}&at=summative`;
      axios.get(url).then((response) => {
         // if (response.data) {
         var data = response.data;

         setHasAnswers(data);
         getAssessmentInfo(assessment_id);
         // }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getAssessmentInfo = (id) => {
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/info?id=${id}&at=summative`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;

            setAssInfo({
               Id: data.id,
               Title: data.title,
               Term: data.term,
               File_url: data.file_url,
               Question_type: data.question_type,
               Created_by: data.created_by,
               Date_created: data.date_created,
               Question_sheet: JSON.parse(data.question_sheet),
               Level: data.level,
               Subject: data.subject,
               Instruction: data.instruction,
            });

            setAnswerSheetData(JSON.parse(data.question_sheet));
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   // const showDrawer = () => {
   //    setDrawerVisible(true);
   // };

   // const onClose = () => {
   //    setDrawerVisible(false);
   // };

   const handleOnDragEnd = (result) => {
      if (!result.destination) return;

      const items = Array.from(assInfo.Question_sheet);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setAnswerSheetData(items);

      setAssInfo({
         Id: assInfo.Id,
         Title: assInfo.Title,
         Term: assInfo.Term,
         File_url: assInfo.File_url,
         Question_type: assInfo.Question_type,
         Created_by: assInfo.Created_by,
         Date_created: assInfo.Date_created,
         Question_sheet: items,
         Level: assInfo.Level,
         Subject: assInfo.Subject,
         Instruction: assInfo.Instruction,
      });

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const handleCardRequest = (method, type, data, idx) => {
      var asd = CardRequestHelper(method, type, data, idx, answerSheetData);

      setAnswerSheetData(asd);

      setAssInfo({
         Id: assInfo.Id,
         Title: assInfo.Title,
         Term: assInfo.Term,
         File_url: assInfo.File_url,
         Question_type: assInfo.Question_type,
         Created_by: assInfo.Created_by,
         Date_created: assInfo.Date_created,
         Question_sheet: asd,
         Level: assInfo.Level,
         Subject: assInfo.Subject,
         Instruction: assInfo.Instruction,
      });

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];

      //-- Hide drawer on button click
      // setDrawerVisible(false);
   }

   const handleCardRequest2 = (method, type, data, idx, template) => {
      var asd = CardRequestHelper(method, type, data, idx, answerSheetData, template);

      setAnswerSheetData(asd);

      //--Save to local storage in case something happens to the browser
      localStorage.removeItem(user_id + "sa_asd");
      localStorage.removeItem(user_id + "sa_ass_options");

      // if (asd.length > 0) {
      //    localStorage.setItem(user_id + 'sa_asd', JSON.stringify(asd));
      //    localStorage.setItem(user_id + 'sa_ass_options', JSON.stringify(assOptions));
      // }

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const handleSave = (assinfo) => {
      // message.success(JSON.stringify(assOptions));
      // message.success(JSON.stringify(answerSheetData));

      const key = "updatable";

      message.loading({ content: "Updating assessment...", key });

      var ass_info = {
         Id: assInfo.Id,
         Title: assinfo.title,
         Term: assinfo.term,
         File_url: assInfo.File_url,
         Question_type: assInfo.Question_type,
         Created_by: assInfo.Created_by,
         // Question_sheet: JSON.stringify(assInfo.Question_sheet),
         Question_sheet: JSON.stringify(answerSheetData),
         Level: assinfo.level,
         Subject: assinfo.subject,
         Instruction: assinfo.instruction,
      }

      const url = `${process.env.REACT_APP_API_ASSESSMENT}/update?at=summative`;
      axios.post(url, ass_info).then(response => {
         // console.log(response);
         if (response.data) {
            const data = response.data;
            // message.success({ content: 'Assessment updated.', key });
            Modal.success({
               title: 'Summative Assessment',
               content: 'Assessment was updated.',
               centered: true
            });
         }
      }, (error) => {
         console.log(error.message);
         // message.error({ content: 'Assessment update failed.', key });
         Modal.error({
            title: 'Summative Assessment',
            content: 'Assessment update failed.',
            centered: true
         });
      });
   }

   const showHideAssignment = () => {
      setShowAssignment(!showAssignment);
      setShowAssessmentTools(!showAssessmentTools);
   }

   const handleSetOptions = (data) => {
      // message.info(JSON.stringify(data));
      tempAssOptions = null;
      tempAssOptions = JSON.parse(JSON.stringify(data));
      setAssOptions(tempAssOptions);
      // setShowAssignment(!showAssignment);
      // setShowAssessmentSheet(!showAssessmentSheet);
   }

   const handleBack = (data) => {
      // message.info(JSON.stringify(data));
      setShowAssignment(!showAssignment);
      setShowAssessmentTools(!showAssessmentTools);
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            // style={{ height: `calc(100vh - 10px)` }}
            >
               <div>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* {
                           user_role.toLowerCase() !== 'registrar'
                              ?
                              <ul>
                                 <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                                 <li><span>/</span></li>
                                 <li className="active">Brainee LMS</li>
                                 <li><span>/</span></li>
                                 <li><a href="#" onClick={() => navigate('/assessment', { state: { activetab: "1" } })}>Summative Assessments</a></li>
                                 <li><span>/</span></li>
                                 <li className="active">Edit</li>
                              </ul>
                              :
                              <ul>
                                 <li><a href="#" onClick={() => navigate('/assessment', { state: { activetab: "1" } })}>Examinations For Online Applicants</a></li>
                                 <li><span>/</span></li>
                                 <li className="active">Edit</li>
                              </ul>
                        } */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }} >
                           {
                              user_role.toLowerCase() !== 'registrar'
                                 ?
                                 <>
                                    <Breadcrumb.Item>
                                       <Link to="/home">Home</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                       <Link to="/assessment">Summative Assessment</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>Edit</Breadcrumb.Item>
                                 </>
                                 :
                                 <>
                                    <Breadcrumb.Item>
                                       <Link to="/assessment">Examinations For Online Applicants</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>Edit</Breadcrumb.Item>
                                 </>
                           }
                        </Breadcrumb>
                     </div>

                     {assInfo.Question_sheet.length > 0 ?
                        <div className='answer-sheet-content2'>
                           <SimpleBarReact>
                              {hasAnswers
                                 ?
                                 <>
                                    <ul style={{ paddingRight: "15px" }}>
                                       {answerSheetData.map(({ id, type, points, correct, option_labels, question, image_url, template, question_image, question_audio, question_video }, idx) => {

                                          if (type == "section") {
                                             qcardIdx = 1;
                                             idxCol.push(sectionIdx);
                                             sectionIdx++;
                                          } else {
                                             idxCol.push(qcardIdx);
                                             qcardIdx++;
                                          }

                                          return (
                                             <li>
                                                <BraineeQuestionCard
                                                   key={id}
                                                   index={idxCol[idx]}
                                                   id={idx}
                                                   type={type}
                                                   points={points}
                                                   correct={correct}
                                                   option_labels={option_labels}
                                                   question={question}
                                                   image_url={image_url}
                                                   question_type={'2'}
                                                   mode={'edit'}
                                                   parentCallback={handleCardRequest}
                                                   template={template}
                                                   question_image={question_image}
                                                   question_audio={question_audio}
                                                   question_video={question_video}
                                                   // subject_name={location.state.subject}
                                                   templateReplaceCallback={handleCardRequest2}
                                                   assessment_type={'summative'}
                                                />
                                             </li>
                                          );
                                       })}
                                    </ul>
                                 </>
                                 :
                                 <>
                                    <DragDropContext onDragEnd={(e) => handleOnDragEnd(e)}>
                                       <Droppable droppableId="questions">
                                          {(provided) => (
                                             <ul className="questions" {...provided.droppableProps} ref={provided.innerRef} style={{ paddingRight: "15px" }}>
                                                {answerSheetData.map(({ id, type, points, correct, option_labels, question, image_url, template, question_image, question_audio, question_video }, idx) => {

                                                   if (type == "section") {
                                                      qcardIdx = 1;
                                                      idxCol.push(sectionIdx);
                                                      sectionIdx++;
                                                   } else {
                                                      idxCol.push(qcardIdx);
                                                      qcardIdx++;
                                                   }

                                                   return (
                                                      <Draggable key={id} draggableId={id} index={idx}>
                                                         {(provided) => (
                                                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                               <BraineeQuestionCard
                                                                  key={id}
                                                                  index={idxCol[idx]}
                                                                  id={idx}
                                                                  type={type}
                                                                  points={points}
                                                                  correct={correct}
                                                                  option_labels={option_labels}
                                                                  question={question}
                                                                  image_url={image_url}
                                                                  question_type={'2'}
                                                                  mode={'edit'}
                                                                  parentCallback={handleCardRequest}
                                                                  template={template}
                                                                  question_image={question_image}
                                                                  question_audio={question_audio}
                                                                  question_video={question_video}
                                                                  // subject_name={location.state.subject}
                                                                  templateReplaceCallback={handleCardRequest2}
                                                                  assessment_type={'summative'}
                                                               />
                                                            </li>
                                                         )}
                                                      </Draggable>
                                                   );
                                                })}
                                                {provided.placeholder}
                                             </ul>
                                          )}
                                       </Droppable>
                                    </DragDropContext>
                                 </>
                              }
                           </SimpleBarReact>
                        </div>
                        :
                        <div className='temp-answer-sheet-content2'>
                           {/* <Empty description='No items added.'/> */}
                           <Spin size='large' tip="Loading, please wait..." />
                        </div>
                     }
                  </div>
               </div>

               <div>
                  <div className="notice-item">
                     <div className='answer-sheet-content'>
                        {showAssignment &&
                           <AssessmentAssignTool
                              mode={"normal"}
                              options={assOptions}
                              parentCallback={handleSetOptions}
                              backCallback={handleBack} />
                        }

                        {showAssessmentTools && assInfo.Id
                           ?
                           // <StickyBox style={{ zIndex: 1000 }}>
                           <SimpleBarReact>
                              <AssessmentTools
                                 key={"editManual"}
                                 data={assInfo}
                                 cardRequestCallback={handleCardRequest2}
                                 showHideAssCallback={showHideAssignment}
                                 saveCallback={handleSave}
                                 showToggle={false}
                                 mode={"edit"}
                                 type={'manual'}
                                 assessment_type={'summative'}
                              />
                           </SimpleBarReact>
                           :
                           <div className='temp-answer-sheet-content2'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                        }
                     </div>
                  </div>
               </div>
            </Split>
         </div>

         {/* <div className="dashboard-area">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <ul>
                     <li><a href="#">Home</a></li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate('/assessment', {state: {activetab: "1"}})}>Summative Assessments</a></li>
                     <li><span>/</span></li>
                     <li><a className="active" href="#">Edit</a></li>
                  </ul>        
               </div>   
               
               {assInfo.Question_sheet.length > 0 ?
               <div className='answer-sheet-content2'>
                  <SimpleBarReact>
                     <DragDropContext onDragEnd={(e) => handleOnDragEnd(e)}>
                        <Droppable droppableId="questions">
                           {(provided) => (
                              <ul className="questions" {...provided.droppableProps} ref={provided.innerRef}>
                                 {assInfo.Question_sheet.map(({id, type, points, correct, option_labels, question, image_url}, idx) => { 

                                    if (type == "section") {
                                       qcardIdx = 1;
                                       idxCol.push(sectionIdx);
                                       sectionIdx++;
                                    } else {
                                       idxCol.push(qcardIdx);
                                       qcardIdx++;
                                    }                                    

                                    return (
                                       <Draggable key={id} draggableId={id} index={ idx }>
                                          {(provided) => (
                                             <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <QuestionCard 
                                                   key={id}
                                                   index={ idxCol[idx] } 
                                                   id={idx} 
                                                   type={type} 
                                                   points={points} 
                                                   correct={correct} 
                                                   option_labels={option_labels} 
                                                   question={question}
                                                   image_url={image_url}
                                                   question_type={'2'}
                                                   mode={'create'}
                                                   parentCallback={handleCardRequest} />                                          
                                             </li>
                                          )}
                                       </Draggable>
                                    );
                                 })}
                                 {provided.placeholder}
                              </ul>
                           )}
                        </Droppable>
                     </DragDropContext>
                  </SimpleBarReact>
               </div>
               :
               <div className='temp-answer-sheet-content2'>
                  <Empty description='No items added.'/>
               </div>
               }
            </div>
         </div>

         <div className="notice-area" style={{display:"block"}}> 
            <div className="notice-item">
               <div className='answer-sheet-content'>
                  {showAssignment && 
                     <AssessmentAssignTool 
                        mode={"normal"}
                        options={assOptions} 
                        parentCallback={handleSetOptions}
                        backCallback={handleBack} />
                  }

                  {showAssessmentTools && assInfo.Id && 
                     <StickyBox style={{zIndex: 1000}}>
                        <AssessmentTools 
                           key={"editManual"}
                           data={assInfo} 
                           cardRequestCallback={handleCardRequest} 
                           showHideAssCallback={showHideAssignment} 
                           saveCallback={handleSave} 
                           showToggle={false}
                           mode={"edit"} />
                     </StickyBox>
                  }
               </div>                  
            </div>
         </div> */}
      </>
   )
}
