/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Empty, message, Modal, Breadcrumb } from 'antd';
import './MasteryAssessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import StickyBox from "react-sticky-box";
// import axios from 'axios';
// import uuid from 'react-uuid';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
// import AssessmentAssignTool from '../../components/assessment/AssessmentAssignTool';
import MasteryAssessmentToolsM from '../../components/assessment/MasteryAssessmentToolsM';
// import { CardRequestHelper } from './Helpers';
import { CardRequestHelper } from '../../helper/AQHelper';
import { useSelector, useDispatch } from 'react-redux';
import { update_mastery_data } from '../../actions';

import Split from 'react-split';
// import './SplitPanel.css';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';

const questionData = []

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let tempAssOptions = null;
let defaultAssOptions = {
   id: '',
   assessment_id: '',
   assigned_by: '1',
   duration: '1',
   allowed_attempts: '1',
   passing_percentage: '50',
   allow_result_viewing: '0',
   enable_timer: '0',
   start_date: '',
   end_date: '',
   assigned_students: '',
   term: ''
}

export function MasteryCreateManual(props) {
   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const masteryrec = useSelector(state => state.masteryrec);
   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   // const[answerSheetData, setAnswerSheetData] = useState(questionData);
   const [answerSheetData, setAnswerSheetData] = useState(() => {
      var questionsheet = JSON.parse(localStorage.getItem("mastery_asd"));
      if (questionsheet !== null) return questionsheet;
      else return [];
   });
   const [showAssessmentTools, setShowAssessmentTools] = useState(true);
   const [assOptions, setAssOptions] = useState(() => {
      var assoptns = JSON.parse(localStorage.getItem("mastery_options"));
      if (assoptns !== null) return assoptns;
      else return defaultAssOptions;
   });

   // const [questionTemplate, setQuestionTemplate] = useState(null);

   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: location.state.title,
      Level: location.state.level,
      Mastery_level: "",
      Subject: location.state.subjectname,
      File_url: "",
      Question_type: "2",
      Created_by: "",
      Date_created: "",
      Question_sheet: ""
   });

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin')
         navigate('/home');
   }, []);

   const handleOnDragEnd = (result) => {
      if (!result.destination) return;

      const items = Array.from(answerSheetData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setAnswerSheetData(items);

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const handleCardRequest = (method, type, data, idx) => {
      var asd = CardRequestHelper(method, type, data, idx, answerSheetData);

      //--Save to local storage in case something happens to the browser
      localStorage.removeItem("mastery_asd");
      localStorage.removeItem("mastery_options");

      // if (asd.length > 0) {
      //    localStorage.setItem('mastery_asd', JSON.stringify(asd));
      //    localStorage.setItem('mastery_options', JSON.stringify(assOptions));
      // }

      // setQuestionTemplate(template);
      setAnswerSheetData(asd);

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const handleCardRequest2 = (method, type, data, idx, template) => {
      var asd = CardRequestHelper(method, type, data, idx, answerSheetData, template);

      setAnswerSheetData(asd);

      //--Save to local storage in case something happens to the browser
      localStorage.removeItem("mastery_asd");
      localStorage.removeItem("mastery_options");

      // if (asd.length > 0) {
      //    localStorage.setItem('mastery_asd', JSON.stringify(asd));
      //    localStorage.setItem('mastery_options', JSON.stringify(assOptions));
      // }

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const handleGenerateCardsFromAI = (data) => {
      //--Save to local storage in case something happens to the browser
      localStorage.removeItem("mastery_asd");
      localStorage.removeItem("mastery_options");

      // if (data.length > 0) {
      //    localStorage.setItem('mastery_asd', JSON.stringify(data));
      //    localStorage.setItem('mastery_options', JSON.stringify(assOptions));
      // }

      // setQuestionTemplate(template);
      setAnswerSheetData(data);

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const handleSave = (assinfo) => {
      if (answerSheetData.length > 0) {
         localStorage.removeItem('mastery_asd');
         localStorage.removeItem('mastery_options');

         var params = { key: location.state.type, value: JSON.stringify(answerSheetData) };
         dispatch(update_mastery_data(params));

         //--Update instruction
         params = { key: location.state.type + '_instruction', value: assinfo.instruction };
         dispatch(update_mastery_data(params));

         if (location.state.mode === 'create')
            navigate('/masteryassessment/create?qt=2');
         else
            navigate(`/masteryassessment/edit?id=${masteryrec.id}&subject=${location.state.subjectid}&subjectname=${location.state.subjectname}`);
      } else {
         Modal.info({
            title: 'Mastery Assessment',
            content: (
               <>
                  Please complete the folowing before saving.
                  <ul>
                     <li>
                        Answer keys
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   // const handleSetOptions = (data) => {
   //    // message.info(JSON.stringify(data));
   //    tempAssOptions = null;
   //    tempAssOptions = JSON.parse(JSON.stringify(data));
   //    setAssOptions(tempAssOptions);
   // }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div className="dashboard-item">
                  <div className="dashboard-item-inner">
                     {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/masteryassessment', { state: { activetab: "1" } })}>Mastery Assessments</a></li>
                           <li><span>/</span></li>
                           <li><a className="active" href="#">Create</a></li>
                        </ul> */}
                     {/* <ul>
                        <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                        <li><span>/</span></li>
                        <li className="active">Brainee LMS</li>
                        <li><span>/</span></li>
                        <li><a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subjects</a></li>
                        {location.state.mode === 'edit'
                           ?
                           <>
                              <li><span>/</span></li>
                              <li><a href="#" onClick={() => navigate(`/masteryassessmentsubject?id=${location.state.subjectid}&name=${location.state.subjectname}`)}>Mastery Assessment ({location.state.subjectname})</a></li>
                           </>
                           :
                           <></>
                        }
                        <li><span>/</span></li>
                        {
                           location.state.mode === 'create'
                           ?
                           <li><a href="#" onClick={() => navigate('/masteryassessment/create?qt=2')}>Create</a></li>
                           :
                           <li><a href="#" onClick={() => navigate(`/masteryassessment/edit?id=${masteryrec.id}&subject=${location.state.subjectid}&subjectname=${location.state.subjectname}`)}> Edit</a></li>
                        }
                        <li><span>/</span></li>
                        <li><a className="active">{location.state.title} (Create)</a></li>
                     </ul> */}
                     <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                        <Breadcrumb.Item>
                           <Link to="/home">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                        <Breadcrumb.Item>
                           <a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subject</a>
                        </Breadcrumb.Item>
                        {
                           location.state.mode === 'edit'
                              ?
                              <>
                                 <Breadcrumb.Item>
                                    <a href="#" onClick={() => navigate(`/masteryassessmentsubject?id=${location.state.subjectid}&name=${location.state.subjectname}`)}>Mastery Assessment ({location.state.subjectname})</a>
                                 </Breadcrumb.Item>
                              </>
                              :
                              <></>
                        }
                        {
                           location.state.mode === 'create'
                              ?
                              <Breadcrumb.Item>
                                 <a href="#" onClick={() => navigate('/masteryassessment/create?qt=2')}>Create</a>
                              </Breadcrumb.Item>
                              :
                              <Breadcrumb.Item>
                                 <a href="#" onClick={() => navigate(`/masteryassessment/edit?id=${masteryrec.id}&subject=${location.state.subjectid}&subjectname=${location.state.subjectname}`)}> Edit</a>
                              </Breadcrumb.Item>
                        }
                        <Breadcrumb.Item>{location.state.title} (Create)</Breadcrumb.Item>
                     </Breadcrumb>
                  </div>

                  {
                     answerSheetData.length > 0
                        ?
                        <div className='answer-sheet-content2'>
                           <SimpleBarReact>
                              <DragDropContext onDragEnd={(e) => handleOnDragEnd(e)}>
                                 <Droppable droppableId="questions">
                                    {(provided) => (
                                       <ul className="questions" {...provided.droppableProps} ref={provided.innerRef}>
                                          {answerSheetData.map(({ id, type, points, correct, option_labels, question, image_url, template, question_image, question_audio, question_video }, idx) => {

                                             if (type === "section") {
                                                qcardIdx = 1;
                                                idxCol.push(sectionIdx);
                                                sectionIdx++;
                                             } else {
                                                idxCol.push(qcardIdx);
                                                qcardIdx++;
                                             }

                                             return (
                                                <Draggable key={id} draggableId={id} index={idx}>
                                                   {(provided) => (
                                                      <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                         <BraineeQuestionCard
                                                            key={id}
                                                            index={idxCol[idx]}
                                                            id={idx}
                                                            type={type}
                                                            points={points}
                                                            correct={correct}
                                                            option_labels={option_labels}
                                                            question={question}
                                                            image_url={image_url}
                                                            question_type={'2'}
                                                            mode={'create'}
                                                            parentCallback={handleCardRequest}
                                                            card_bg={location.state.card_bg}
                                                            template={template}
                                                            question_image={question_image}
                                                            question_audio={question_audio}
                                                            question_video={question_video}
                                                            subject_name={location.state.subjectname}
                                                            templateReplaceCallback={handleCardRequest2}
                                                            assessment_type={'mastery'}
                                                         />
                                                      </li>
                                                   )}
                                                </Draggable>
                                             );
                                          })}
                                          {provided.placeholder}
                                       </ul>
                                    )}
                                 </Droppable>
                              </DragDropContext>
                           </SimpleBarReact>
                        </div>
                        :
                        <div className='temp-answer-sheet-content2'>
                           <Empty description='No items added.' />
                        </div>
                  }
               </div>

               <div className="notice-item">
                  <div className='answer-sheet-content'>
                     <SimpleBarReact>
                        {showAssessmentTools &&
                           // <StickyBox style={{ zIndex: 1000 }}>
                           <MasteryAssessmentToolsM
                              key={"masteryassessmenttool"}
                              data={assInfo}
                              cardRequestCallback={handleCardRequest2}
                              saveCallback={handleSave}
                              showToggle={true}
                              mode={"create"}
                              type={'manual'}
                              generateCardsFromAI={handleGenerateCardsFromAI}
                           />
                           // </StickyBox>
                        }
                     </SimpleBarReact>
                  </div>
               </div>
            </Split>
         </div>
      </>
   )
}
