import React, { useContext } from "react"
import { Input } from 'theme-ui'
import { Gallery } from "react-grid-gallery";
// import { CanvasContext } from '../../EditLessonPage'
import { CanvasContext } from "../../hooks/LessonPage";
import "./Background.scss"

function BackgroundPanel() {
  const { state,actions } = useContext(CanvasContext)

  const images = [
    {
      src: "/images/background/BackgroundBrainee_1.png",
      original: "/images/background/BackgroundBrainee_1.png",
      width: 350,
      height: 174,
    },    
    {
      src: "/images/background/BackgroundBrainee_2.png",
      original: "/images/background/BackgroundBrainee_2.png",
      width: 350,
      height: 174,
    },  
    {
      src: "/images/background/BackgroundBrainee_3.png",
      original: "/images/background/BackgroundBrainee_3.png",
      width: 350,
      height: 174,
    },  
    {
      src: "/images/background/BackgroundBrainee_4.png",
      original: "/images/background/BackgroundBrainee_4.png",
      width: 350,
      height: 174,
    },  
    {
      src: "/images/background/BackgroundBrainee_5.png",
      original: "/images/background/BackgroundBrainee_5.png",
      width: 350,
      height: 174,
    },                  
    {
      src: "https://img.freepik.com/free-vector/geometric-background_53876-115958.jpg?w=2000&t=st=1715175586~exp=1715176186~hmac=df71e4558fa62ef81119a420be684fc7533149af9c21f03e0ad058aa144e50a9",
      original: "https://img.freepik.com/free-vector/geometric-background_53876-115958.jpg?w=2000&t=st=1715175586~exp=1715176186~hmac=df71e4558fa62ef81119a420be684fc7533149af9c21f03e0ad058aa144e50a9",
      width: 350,
      height: 174,
    },
    {
      src: "https://img.freepik.com/free-photo/abstract-paper-background-concept_23-2148812690.jpg?w=2000&t=st=1715177778~exp=1715178378~hmac=66e1c054b113dd2e62523fcc3fc699fc7dde3949bd0e30f3176a72b128bae4e5",
      original: "https://img.freepik.com/free-photo/abstract-paper-background-concept_23-2148812690.jpg?w=2000&t=st=1715177778~exp=1715178378~hmac=66e1c054b113dd2e62523fcc3fc699fc7dde3949bd0e30f3176a72b128bae4e5",
      width: 520,
      height: 212,
      caption: "Boats (Jeshu John - designerspics.com)",
    },
    {
      src: "https://img.freepik.com/free-vector/paper-style-dynamic-lines-background_23-2149008629.jpg?w=2000&t=st=1715179241~exp=1715179841~hmac=d35e7821317c959c3712883f5650a34ebba253e541c8e643730fa0d0c300b656",
      original: "https://img.freepik.com/free-vector/paper-style-dynamic-lines-background_23-2149008629.jpg?w=2000&t=st=1715179241~exp=1715179841~hmac=d35e7821317c959c3712883f5650a34ebba253e541c8e643730fa0d0c300b656",
      width: 520,
      height: 212,
      caption: "Color Pencils (Jeshu John - designerspics.com)",
    },
    {
      src: "https://img.freepik.com/free-vector/wave-background_53876-115944.jpg?w=2000&t=st=1715179332~exp=1715179932~hmac=dcb6da283b47cde1f3f9b2ea3258ebbb46641db211e2d613c60c932b933bc80f",
      original: "https://img.freepik.com/free-vector/wave-background_53876-115944.jpg?w=2000&t=st=1715179332~exp=1715179932~hmac=dcb6da283b47cde1f3f9b2ea3258ebbb46641db211e2d613c60c932b933bc80f",
      width: 520,
      height: 213,
      caption: "Red Apples with other Red Fruit (foodiesfeed.com)",
    },
    {
      src: "https://img.freepik.com/free-vector/blue-curve-background_53876-113112.jpg?w=2000&t=st=1715179513~exp=1715180113~hmac=649e761a96a1ebb38ff9ff18cf3a01d9a67a55abf13a21bfebcb32ddbed9ff70",
      original: "https://img.freepik.com/free-vector/blue-curve-background_53876-113112.jpg?w=2000&t=st=1715179513~exp=1715180113~hmac=649e761a96a1ebb38ff9ff18cf3a01d9a67a55abf13a21bfebcb32ddbed9ff70",
      width: 325200,
      height: 183,
      caption: "37H (gratispgraphy.com)",
    },
    {
      src: "https://img.freepik.com/free-vector/blue-abstract-layered-stripes-vector-background_53876-110553.jpg?w=2000&t=st=1715179577~exp=1715180177~hmac=c5e2b09f40296dd0fb6cc395aa73fc8e1ab8b6b191dfcb39cbd4936e1a06d5d1",
      original: "https://img.freepik.com/free-vector/blue-abstract-layered-stripes-vector-background_53876-110553.jpg?w=2000&t=st=1715179577~exp=1715180177~hmac=c5e2b09f40296dd0fb6cc395aa73fc8e1ab8b6b191dfcb39cbd4936e1a06d5d1",
      width: 520,
      height: 213,
      tags: [{ value: "Nature", title: "Nature" }],
      caption: "8H (gratisography.com)",
    },
    {
      src: "https://img.freepik.com/free-vector/gradient-blue-background-modern-design-geometric_343694-3827.jpg?w=2000&t=st=1715179679~exp=1715180279~hmac=a891c0809d795ac8d9378a1dca849d69cf6cf3872ee5e701455174ded8dde4a2",
      original: "https://img.freepik.com/free-vector/gradient-blue-background-modern-design-geometric_343694-3827.jpg?w=2000&t=st=1715179679~exp=1715180279~hmac=a891c0809d795ac8d9378a1dca849d69cf6cf3872ee5e701455174ded8dde4a2",
      width: 520,
      height: 190,
      caption: "286H (gratisography.com)",
    },
    {
      src: "https://img.freepik.com/free-photo/set-paper-sheets-with-copy-space_23-2148320415.jpg?w=2000&t=st=1715179749~exp=1715180349~hmac=ecdf011cc4721d3fd254a11f21574b58c6f663b23c1f4230474963ea2de83867",
      original: "https://img.freepik.com/free-photo/set-paper-sheets-with-copy-space_23-2148320415.jpg?w=2000&t=st=1715179749~exp=1715180349~hmac=ecdf011cc4721d3fd254a11f21574b58c6f663b23c1f4230474963ea2de83867",
      width: 520,
      height: 148,
      tags: [{ value: "People", title: "People" }],
      caption: "315H (gratisography.com)",
    },
    {
      src: "https://img.freepik.com/free-photo/orange-yellow-cupboard_23-2148518404.jpg?w=2000&t=st=1715179890~exp=1715180490~hmac=9161654b9a3ec2b38cfe2bf9412ffae9fcbbfe5503774f892b5675d9a941ad1e",
      original: "https://img.freepik.com/free-photo/orange-yellow-cupboard_23-2148518404.jpg?w=2000&t=st=1715179890~exp=1715180490~hmac=9161654b9a3ec2b38cfe2bf9412ffae9fcbbfe5503774f892b5675d9a941ad1e",
      width: 520,
      height: 213,
      caption: "201H (gratisography.com)",
    },
    {
      src: "https://img.freepik.com/free-vector/blue-geometric-minimal-background_53876-99573.jpg?w=2000&t=st=1715179950~exp=1715180550~hmac=c7bb614647da0e6652d44d6d8d43f77b795987fa4574a4f189fc72034dc79504",
      original: "https://img.freepik.com/free-vector/blue-geometric-minimal-background_53876-99573.jpg?w=2000&t=st=1715179950~exp=1715180550~hmac=c7bb614647da0e6652d44d6d8d43f77b795987fa4574a4f189fc72034dc79504",
      alt: "Big Ben - London",
      width: 520,
      height: 213,
      caption: "Big Ben (Tom Eversley - isorepublic.com)",
    },
    {
      src: "https://img.freepik.com/free-vector/white-gradient-background-abstract-modern-design_343694-3085.jpg?w=2000&t=st=1715180032~exp=1715180632~hmac=4964736ca85e2dc1a034df5c0300a0892d718fd959768e597b8f3e1842c1389f",
      original: "https://img.freepik.com/free-vector/white-gradient-background-abstract-modern-design_343694-3085.jpg?w=2000&t=st=1715180032~exp=1715180632~hmac=4964736ca85e2dc1a034df5c0300a0892d718fd959768e597b8f3e1842c1389f",
      alt: "Red Zone - Paris",
      width: 520,
      height: 113,
      tags: [{ value: "People", title: "People" }],
      caption: "Red Zone - Paris (Tom Eversley - isorepublic.com)",
    },
    {
      src: "https://img.freepik.com/free-vector/pink-green-watercolor-patterned-background-template_53876-115317.jpg?w=2000&t=st=1715180104~exp=1715180704~hmac=3d2e0dba4c09a3d131ef1fe2d1c513fcd2a303e3da6eef2d392452642e98c690",
      original: "https://img.freepik.com/free-vector/pink-green-watercolor-patterned-background-template_53876-115317.jpg?w=2000&t=st=1715180104~exp=1715180704~hmac=3d2e0dba4c09a3d131ef1fe2d1c513fcd2a303e3da6eef2d392452642e98c690",
      alt: "Wood Glass",
      width: 520,
      height: 213,
      caption: "Wood Glass (Tom Eversley - isorepublic.com)",
    },
    {
      src: "https://img.freepik.com/free-vector/geometric-background_91008-301.jpg?w=2000&t=st=1715180414~exp=1715181014~hmac=9c30c0ce549094707eeb9849d615dcad0173ff02333c228fd53f311b687a6499",
      original: "https://img.freepik.com/free-vector/geometric-background_91008-301.jpg?w=2000&t=st=1715180414~exp=1715181014~hmac=9c30c0ce549094707eeb9849d615dcad0173ff02333c228fd53f311b687a6499",
      width: 520,
      height: 213,
      caption: "Flower Interior Macro (Tom Eversley - isorepublic.com)",
    },
    {
      src: "https://img.freepik.com/free-photo/abstract-bluish-paint-background-wallpaper_53876-97482.jpg?w=2000&t=st=1715180861~exp=1715181461~hmac=930926379ceac467c3db6a6755e56e9330eeaf3aaf40fc37901b84cf30337dab",
      original: "https://img.freepik.com/free-photo/abstract-bluish-paint-background-wallpaper_53876-97482.jpg?w=2000&t=st=1715180861~exp=1715181461~hmac=930926379ceac467c3db6a6755e56e9330eeaf3aaf40fc37901b84cf30337dab",
      width: 520,
      height: 194,
      caption: "Old Barn (Tom Eversley - isorepublic.com)",
    },
    {
      src: "https://img.freepik.com/free-vector/background-luxury-minimalist-gradient-style-design_698780-705.jpg?w=2000&t=st=1715180917~exp=1715181517~hmac=e84a9e0d01c37944388821567752c8d370e6dc61c7031f42b508b08324d3e5ee",
      original: "https://img.freepik.com/free-vector/background-luxury-minimalist-gradient-style-design_698780-705.jpg?w=2000&t=st=1715180917~exp=1715181517~hmac=e84a9e0d01c37944388821567752c8d370e6dc61c7031f42b508b08324d3e5ee",
      alt: "Cosmos Flower",
      width: 520,
      height: 213,
      caption: "Cosmos Flower Macro (Tom Eversley - isorepublic.com)",
    },
    {
      src: "https://img.freepik.com/free-vector/simple-green-gradient-background-vector-business_53876-169283.jpg?w=2000&t=st=1715180951~exp=1715181551~hmac=223645441a48cfd8225d8ebe2e859be3e28559a5401eccdf6f9113133f8531bd",
      original: "https://img.freepik.com/free-vector/simple-green-gradient-background-vector-business_53876-169283.jpg?w=2000&t=st=1715180951~exp=1715181551~hmac=223645441a48cfd8225d8ebe2e859be3e28559a5401eccdf6f9113133f8531bd",
      width: 520,
      height: 220,
      caption: "Orange Macro (Tom Eversley - isorepublic.com)",
    },
    {
      src: "https://img.freepik.com/free-photo/yellow-craftpapers-blue-backdrop_23-2147878388.jpg?w=2000&t=st=1715180991~exp=1715181591~hmac=8b8ce9f52505e98a9f72c6988aa09cec8b66089a2002f712de2e3a9298ea74e6",
      original: "https://img.freepik.com/free-photo/yellow-craftpapers-blue-backdrop_23-2147878388.jpg?w=2000&t=st=1715180991~exp=1715181591~hmac=8b8ce9f52505e98a9f72c6988aa09cec8b66089a2002f712de2e3a9298ea74e6",
      width: 520,
      height: 213,
      tags: [
        { value: "Nature", title: "Nature" },
        { value: "People", title: "People" },
      ],
      caption: "Surfer Sunset (Tom Eversley - isorepublic.com)",
    },
    {
      src: "https://img.freepik.com/free-vector/flat-geometric-background_23-2148957205.jpg?w=2000&t=st=1715181246~exp=1715181846~hmac=bbea0836cf576caf270b34fa14589ece8adac6adc038af32fbf95519b43c55a4",
      original: "https://img.freepik.com/free-vector/flat-geometric-background_23-2148957205.jpg?w=2000&t=st=1715181246~exp=1715181846~hmac=bbea0836cf576caf270b34fa14589ece8adac6adc038af32fbf95519b43c55a4",
      width: 520,
      height: 213,
      tags: [
        { value: "People", title: "People" },
        { value: "Sport", title: "Sport" },
      ],
      caption: "Man on BMX (Tom Eversley - isorepublic.com)",
    },
  ];

  const onClick = (index, item, event) => {
    var data ={color: "", type : "", imagesrc : item.src}
    actions?.updateBackgroundData(data)
  }

  return (
    <>
      <div style={{ padding: '2rem 2rem 1rem' }}>
        <Input style={{ background: '#fff' }} type="text" placeholder="Search background" />
      </div>
      <div>
      <Gallery
        images={images}
        onClick={onClick}
        enableImageSelection={false}
      />
    </div>      
    </>
  )
}
export default BackgroundPanel
