import LessonBoard from '../LessonBoard';
import React, { useEffect, useState } from 'react';
import './CreateLessons.css';
import './Details.css';
import './Common.css';
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { Button, Input, Form, Select, Radio, DatePicker, Row, Col, Tree, Modal, Typography } from 'antd';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { update_lesson_data } from '../../../actions';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { DownloadOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import {
   post_lookup,
   initialize_lesson
} from '../../../actions';

function LessonCreatiionForm(props) {
   const navigate = useNavigate();
   const [formData, setFormData] = useState({
      subject: "",
      subjectList: [],
      title: "",
      term: "",
      availabilitydate: [],
      display_availabilitydate: "",
      educationallevel: "",
      studentviewing: "",
      lessonsharing: "",
      gradelevel: "",
      sectionlist: [],
      learningtype: "",
      learningmode: "",
      learningplan: "",
      type: "",
      topiclist: [],
   });
   const [topiclist, setTopiclist] = useState({
      contents: []
   })

   const { RangePicker } = DatePicker;

   const [form] = Form.useForm();
   const { confirm } = Modal;

   const dateFormat = 'MMM D YYYY, h:mm a';
   const customFormat = value => `${value.format(dateFormat)}`;

   const [subjectlist, setSubjectlist] = useState([])

   const lessonSelector = useSelector(state => state.lessonrec);
   const lookupSelector = useSelector(state => state.lookup);


   const [checkedKeys, setCheckedKeys] = useState([]);
   const [selectedKeys, setSelectedKeys] = useState([]);
   const [treeData, setTreeData] = useState([]);

   const [expandedKeys, setExpandedKeys] = useState([]);
   const [enableField, setEnableField] = useState(true);
   const [endStatus, setEndStatus] = useState(false)
   // const [addTopicRedirect, setAddTopicRedirect] = useState(false)
   const [saveClicked, setSaveClicked] = useState(false)

   const [lessonId, setLessonId] = useState('')
   const [lessonLevel, setLessonLevel] = useState('')

   // const [checkedKeysWithSection, setCheckedKeysWithSection] = useState([]);

   const dispatch = useDispatch();
   const location = useLocation();


   useEffect(() => {

      Utils.verifyJwt();
      let urllink = `${process.env.REACT_APP_API_LESSON_LOOKUP}`
      // console.log(location.state.userId)
      axios.get(urllink).then((response) => {
         dispatch(post_lookup(response.data))
      })

      if (lessonSelector.gradelevel) {
         GetSubjectList(lessonSelector.gradelevel)
      }

      if (props.mode === "add") {
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         var lessonid = "lesson_" + o2o_mode + "_" + uuid();
         setLessonId(lessonid)
         var params = { key: `lessonid`, value: lessonid }
         dispatch(update_lesson_data(params))
      }
      else {
         setLessonId(lessonSelector.lessonid)
         handleChangeLevel(lessonSelector.gradelevel)
      }

   }, []);

   const handleFormValuesChange = (changedValues) => {
      setSaveClicked(false)
   };

   const onFinish = (values) => {
      let dvalue = [];
      if (props.mode !== "add") {
         let dateString = lessonSelector.availabilitydate;
         if (Array.isArray(dateString)) {
            const d = dateString[0];//'Jan 21, 2022, 7:07:00 am';
            const d2 = dateString[1];//'Jan 21, 2022, 7:07:00 am';
            dvalue = d + ' - ' + d2;
         } else {
            dvalue = dateString;
         }
      }

      let lesson = {
         LessonId: lessonSelector.lessonid,
         Title: lessonSelector.title,
         TeacherName: props.mode === "add" ? lessonSelector.teacherName : lessonSelector.staffId,
         Subject: lessonSelector.subject,
         Overview: lessonSelector.overview,
         Term: lessonSelector.term,
         AvailabilityDate: props.mode === "add" ? lessonSelector.availabilitydate : dvalue,
         EducationalLevel: lessonSelector.educationallevel,
         StudentViewing: lessonSelector.studentviewing,
         LessonSharing: lessonSelector.lessonsharing,
         GradeLevel: lessonSelector.gradelevel,
         SectionList: lessonSelector.sectionlist,
         AssignedStudents: lessonSelector.assigned_students,
         SelectedStudents: lessonSelector.selected_students,
         LearningType: lessonSelector.learningtype,
         LearningMode: lessonSelector.learningmode,
         Conference: lessonSelector.conference,
         ConferenceLink: '',
         ClassRoomId: '',
         LearningPlan: lessonSelector.learningplan,
         Type: "",
         Sections: lessonSelector.sections,
         SectionVersion : 2,
         StaffId: props.mode === "add" ? location.state.userId : lessonSelector.staffId,
         joinLink: lessonSelector.join_link,
         Assigned_students_with_section: lessonSelector.assigned_students_with_section
      }

      let url = `${process.env.REACT_APP_LMS_API}api/Lesson/addLesson`// 'https://localhost:5001/api/Lesson/addLesson'

      axios.post(url, lesson).then(response => {
         if (response.data) {
            //    const data = response.data;
            /*                Modal.success({
                              // icon: <AntDIcons.CheckCircleOutlined />,
                              title: 'Create Lesson',
                              content: 'Lesson was created.',
                              centered: true
                           }); */
            var params = { key: ``, value: 'myvalue' }
            dispatch(initialize_lesson(params))
            //setEndStatus(true)
            TopicRedirect()
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            // icon: <AntDIcons.CloseCircleOutlined />,
            title: 'Create Lesson',
            content: 'Lesson creation failed.',
            centered: true
         });
      });
   };

   const onFinishFailed = (errorInfo) => {
      setSaveClicked(true)
      console.log('Failed:', errorInfo);
   };

   const TopicRedirect = () => {
      var mode = props.mode === "add" ? "created " : "updated "
      var content = props.mode === "add" ? "create " : "add more "
      confirm({
         title: 'Lesson was ' + mode + 'successfully.',
         icon: <ExclamationCircleFilled />,
         content: 'Would you like to ' + content + ' Topics to this Lesson ?',
         okText: 'Yes',
         cancelText: 'No',
         onOk() {
            navigate(`/subject/edit?Id=${lessonId}&level=${lessonLevel}`);
         },
         onCancel() {
            navigate(`/subject`);
            setEndStatus(true)
         },
      });

   };

   const handleChangeSubject = (e) => {

      var params = { key: `subject`, value: e }
      dispatch(update_lesson_data(params))
      let contentTmp = formData.subjectList.filter(p => p.subject_Code === e)
      setFormData({ ...formData, subject: contentTmp[0].description })
   }

   const handleChangeTitle = (e) => {
      var params = { key: `title`, value: e }
      dispatch(update_lesson_data(params))
   }

   const handleChangeTerm = (e) => {
      const value = e.value;
      var params = { key: `term`, value: e }
      dispatch(update_lesson_data(params))
   }

   const handleLearningType = (myvalue) => {
      var params = { key: `learningtype`, value: myvalue }
      dispatch(update_lesson_data(params))
   }

   const handleChangeEducLevel = (e) => {
      const value = e.value;
      // console.log(e)
      // console.log(e.target)
      // console.log(value)
      setFormData({ ...formData, educationallevel: e })
      var params = { key: `educationallevel`, value: e }
      dispatch(update_lesson_data(params))
   }

   const handleChangeLevel = (e) => {
      let level = e;
      // var programCode = lookupSelector.acadProgramLevels.find(e => e.value === String(level)).programCode
      // var levelId = lookupSelector.acadPrograms.find(e => e.programCode === String(programCode)).id
      // var params = { key: `educationallevel`, value: levelId }
      // dispatch(update_lesson_data(params))

      setLessonLevel(level)
      var params = { key: `gradelevel`, value: e }
      dispatch(update_lesson_data(params))

      let tempsections = lookupSelector.classSections;
      let filtered = tempsections?.filter(item => item.code === `${e}`);

      var params = { key: `filteredsection`, value: filtered }
      dispatch(update_lesson_data(params))

      GetSubjectList(e)

      var params = { key: `sectionlist`, value: [] }
      dispatch(update_lesson_data(params))


      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/studentlistperleveljson?level=${level}`).then((response) => {
      // axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/lessonstudentlistperleveljson?level=${level}`).then((response) => {
         console.log(response)
         if (response.data) {
            const data = response.data;

            //-- Remove the section part of the student list
            for (let i = 0; i < data[0].children.length; i++) {
               for (let ii=0; ii < data[0].children[i].children.length; ii++) {
                  data[0].children[i].children[ii].key = data[0].children[i].children[ii].key.substring(data[0].children[i].children[ii].key.indexOf('_') + 1, data[0].children[i].children[ii].key.length);
                  data[0].children[i].children[ii].key = 's_' + data[0].children[i].children[ii].key;
               }
            }

            setTreeData(data);

            var students = lessonSelector.assigned_students.split(',');

            const finallist = students.map(e => 's_' + e);
            setCheckedKeys(finallist);
            setEnableField(false)
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const GetSubjectList = (gradelevel) => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/subjectListbyLevel?level=${gradelevel}`).then((response) => {
         if (response.data) {
            const data = response.data;
            setSubjectlist(data);
            setFormData({ ...formData, subjectList: data })
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleChangeStudentViewing = (e) => {
      var params = { key: `studentviewing`, value: e.target.value }
      dispatch(update_lesson_data(params))
   }

   const handleChangeLessonSharing = (e) => {
      var params = { key: `lessonsharing`, value: e.target.value }
      dispatch(update_lesson_data(params))
   }

   const handleChangeDate = (date, dateString) => {
      const d = dateString[0];//'Jan 21, 2022, 7:07:00 am';
      const d2 = dateString[1];//'Jan 21, 2022, 7:07:00 am';
      const dvalue = d + ' - ' + d2;
      setFormData({ ...formData, availabilitydate: dateString, display_availabilitydate: dvalue })
      var params = { key: `availabilitydate`, value: dvalue }
      dispatch(update_lesson_data(params))
   }



   const FormatDate = () => {
      if (lessonSelector.availabilitydate && lessonSelector.availabilitydate.length) {
         const d = lessonSelector.availabilitydate[0];//'Jan 21, 2022, 7:07:00 am';
         const d2 = lessonSelector.availabilitydate[1];//'Jan 21, 2022, 7:07:00 am';
         const arr = [moment(d, dateFormat), moment(d2, dateFormat)];
         return arr;
      } else {
         console.log('no value');
         return [];
      }
   }


   const onExpand = (expandedKeysValue) => {
      setExpandedKeys(expandedKeysValue);
   };

   const onCheck = (checkedKeysValue) => {
      setCheckedKeys(checkedKeysValue);

      const selectedStuds = checkedKeysValue.filter((e) => {
         // return e.includes('s_');
         return e.includes('_');
      });

      // for (var i = 0; i < selectedStuds.length; i++) {
      //    selectedStuds[i] = selectedStuds[i].substring(selectedStuds[i].indexOf('_') + 1, selectedStuds[i].length);
      // }

      let students = selectedStuds.join(',');
      let assigned_students = students.replace(/s_/g, '');
      var params = { key: `assigned_students`, value: assigned_students }
      dispatch(update_lesson_data(params))

      // const selectedStudsWithSection = checkedKeysValue.filter((e) => {
      //    // return e.includes('s_');
      //    return e.includes('_');
      // });

      // let studentsws = selectedStudsWithSection.join(',');
      // let assigned_students_ws = studentsws.replace(/s_/g, '');
      // params = { key: `assigned_students_with_section`, value: assigned_students_ws }
      // dispatch(update_lesson_data(params))
   };

   const onSelect = (selectedKeysValue, info) => {
      setSelectedKeys(selectedKeysValue);
   };

   const handleChangeConference = (e) => {
      let myvalue = e.target.value
      var params = { key: `conference`, value: myvalue }
      dispatch(update_lesson_data(params))
   }

   const handleLearningMode = (myvalue) => {
      if (myvalue.target.value !== 'online' && myvalue.target.value !== 'hyflex') {
         dispatch(update_lesson_data({ key: `conference`, value: '' }))
      }
      var params = { key: `learningmode`, value: myvalue.target.value }
      dispatch(update_lesson_data(params))
   }

   const moduleNotAvailable = () => {
      Modal.info({
         // icon: <AntDIcons.InfoCircleTwoTone twoToneColor='#369DA2' />,
         title: 'Brainee LMS',
         content: 'We will let you know once this module is available.',
         centered: true,
      });
   }


   return <>
      {/*       {endStatus && <Navigate to={'/subject'} replace state={{}} />} */}
      {/*           {addTopicRedirect && <Navigate to={  navigate(`/subject/edit?Id=${lessonId}&level=${lessonLevel}`)} replace state={{}}  />} */}
      <div className="dashboard-area">
         <div className="dashboard-item">
            <div className="dashboard-item-inner">
               <ul>
                  <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                  <li><span>/</span></li>
                  <li className="active">Brainee LMS</li>
                  <li><span>/</span></li>
                  <li><a href="#" onClick={() => navigate('/subject')}>Lesson</a></li>
                  <li><span>/</span></li>
                  <li><a className="active">{props.mode === "add" ? "Create" : "Edit"}</a></li>
               </ul>
               <div className='DetailsDiv'>
                  <Form
                     form={form}
                     layout="vertical"
                     className='DetailsForm'
                     colon={false}
                     onFinish={onFinish}
                     onFinishFailed={onFinishFailed}
                     onValuesChange={handleFormValuesChange}
                     autoComplete="off"
                  >
                     <Row gutter={12}>
                        <Col xs={24} xl={8}>
                           <Form.Item label="Grade Level" name="level"
                              rules={[
                                 {
                                    required: true,
                                    message: 'Please choose a Level'
                                 },
                              ]} initialValue={lessonSelector.gradelevel}>
                              <Select
                                 //   style={{  width: '40%' }} 
                                 value={lessonSelector.gradelevel}
                                 onChange={(e) => {
                                    handleChangeLevel(e);
                                    // ClearLevel(e);
                                 }}>
                                 {
                                    lookupSelector.acadProgramLevels.map((filtered) => (
                                       <Select.Option value={filtered.value}>{filtered.label}</Select.Option>
                                    ))}
                              </Select>
                           </Form.Item>
                        </Col>
                        <Col xs={24} xl={16}>
                           <Form.Item label="Lesson Title" name="lesson.title"
                              rules={[
                                 {
                                    required: true,
                                    message: 'Please input your Lesson Title'
                                 },
                                 { whitespace: true },
                              ]}
                              initialValue={lessonSelector.title}
                           >
                              <Input
                                 size="default"
                                 placeholder=""
                                 defaultValue={lessonSelector.title}
                                 style={{ color: '#000000' }}
                                 value={lessonSelector.title}
                                 // value={ formData.title } 
                                 onChange={(event) => handleChangeTitle(event.target.value)}
                              />
                           </Form.Item>
                        </Col>
                     </Row>


                     <Row gutter={12}>
                        <Col xs={24} xl={8}>
                           <Form.Item label="Sections (Multiple)" name="sections"
                           >
                              <div className="student-div">
                                 <Tree
                                    disabled={enableField}
                                    checkable
                                    onExpand={onExpand}
                                    // expandedKeys={expandedKeys}
                                    autoExpandParent={true}
                                    onCheck={onCheck}
                                    checkedKeys={checkedKeys}
                                    onSelect={onSelect}
                                    selectedKeys={selectedKeys}
                                    treeData={treeData}
                                    style={{ paddingBottom: "15px", paddingTop: "15px" }}
                                    defaultExpandParent={true}
                                 />
                              </div>
                           </Form.Item>
                        </Col>
                        <Col xs={24} xl={16} >
                           <Row gutter={12}>
                              <Col xs={24} xl={24}>
                                 {props.mode === "add" ? <Form.Item label="Availability Date" name="availabilitydate"
                                    rules={[
                                       {
                                          required: true,
                                          message: 'Please choose Availability Date'
                                       },
                                    ]}
                                 >
                                    <RangePicker
                                       showTime={{ format: 'hh:mm A', use12Hours: true }}
                                       format={customFormat}
                                       defaultValue={FormatDate}
                                       value={FormatDate}
                                       // onCalendarChange={ handleChangeDate } 
                                       onChange={handleChangeDate}
                                    />
                                 </Form.Item> :
                                    <Form.Item label="Availability Date" name="availabilitydate" >
                                       <RangePicker
                                          showTime={{ format: 'hh:mm A', use12Hours: true }}
                                          format={customFormat}
                                          defaultValue={FormatDate}
                                          value={FormatDate}
                                          // onCalendarChange={ handleChangeDate } 
                                          onChange={handleChangeDate}
                                       />
                                    </Form.Item>
                                 }

                              </Col>
                           </Row>
                           <Row gutter={12}>
                              <Col xs={24} xl={12}>
                                 <Form.Item label="Subject" name="subject"
                                    rules={[
                                       {
                                          required: true,
                                          message: 'Please choose a Subject'
                                       },
                                    ]}
                                    initialValue={lessonSelector.subject}
                                 >
                                    <Select value={lessonSelector.subject} onChange={handleChangeSubject} disabled={enableField}>
                                       {
                                          subjectlist.map((filtered) => (
                                             <Select.Option value={filtered.subject_Code}>{filtered.description}</Select.Option>
                                          ))}
                                    </Select>
                                 </Form.Item>
                              </Col>
                              <Col xs={24} xl={12}>
                                 <Form.Item label="Student Viewing" name="studentviewing">
                                    <Radio.Group buttonStyle="solid"
                                       value={lessonSelector.studentviewing}
                                       defaultValue={lessonSelector.studentviewing}
                                       // defaultValue="notallowed" 
                                       onChange={handleChangeStudentViewing} >
                                       <Radio.Button value="allowed">Allowed</Radio.Button>
                                       <Radio.Button value="notallowed">Not Allowed</Radio.Button>
                                    </Radio.Group>
                                 </Form.Item>
                              </Col>
                           </Row>
                           <Row gutter={12}>
                              <Col xs={24} xl={12}>
                                 <Form.Item label="Term" name="term"
                                    rules={[
                                       {
                                          required: true,
                                          message: 'Please choose a Term'
                                       },
                                    ]} initialValue={lessonSelector.term}>
                                    <Select value={lessonSelector.term} onChange={handleChangeTerm} >
                                       {lookupSelector.terms.map((term) => (
                                          <Select.Option value={term.value}>{term.name}</Select.Option>
                                       ))}
                                    </Select>
                                 </Form.Item>
                              </Col>

                              <Col xs={24} xl={12}>
                                 <Form.Item label="Lesson Sharing" name="lessonsharing" >
                                    <Radio.Group buttonStyle="solid"
                                       value={lessonSelector.lessonsharing}
                                       defaultValue={lessonSelector.lessonsharing}
                                       // defaultValue="unshared" 
                                       onChange={handleChangeLessonSharing}
                                    >
                                       <Radio.Button value="shared">Shared</Radio.Button>
                                       <Radio.Button value="unshared">UnShared</Radio.Button>
                                    </Radio.Group>
                                 </Form.Item>
                              </Col>
                           </Row>
                           <Row gutter={12}>
                              <Col xs={24} xl={12}>
                                 <Form.Item label="What type of learning ?" name="typeoflearning"
                                    rules={[
                                       {
                                          required: true,
                                          message: 'Please Choose Type of Learning'
                                       },
                                    ]} initialValue={lessonSelector.learningtype}>
                                    <Select value={lessonSelector.learningtype} onChange={handleLearningType} >
                                       <Select.Option value="sync">Synchronous</Select.Option>
                                       <Select.Option value="async">Asynchronous</Select.Option>
                                    </Select>
                                 </Form.Item>
                              </Col>
                              <Col xs={24} xl={12}>
                                 <Form.Item label="What is the mode of learning ?" name="modeoflearning"
                                    rules={[
                                       {
                                          required: true,
                                          message: 'Please Choose Mode of Learning'
                                       },
                                    ]} initialValue={lessonSelector.learningmode}
                                 >
                                    <Radio.Group buttonStyle="solid"
                                       value={lessonSelector.learningmode}
                                       defaultValue={lessonSelector.learningmode}
                                       // defaultValue="unshared" 
                                       onChange={handleLearningMode}
                                    >

                                       {lessonSelector.learningtype === "async" ?
                                          <>
                                             <Radio.Button value="assignment">Assignment</Radio.Button>
                                             <Radio.Button value="reviewer">Reviewer</Radio.Button>
                                          </> :
                                          <>
                                             <Radio.Button value="hyflex">Hyflex</Radio.Button>
                                             <Radio.Button value="classroom">Classroom</Radio.Button>
                                          </>
                                       }
                                       <Radio.Button value="online">Online</Radio.Button>
                                    </Radio.Group>
                                 </Form.Item>
                              </Col>
                           </Row>
                           {(lessonSelector.learningmode === "online" || lessonSelector.learningmode === "hyflex") &&
                              <Row gutter={12}>
                                 <Col xs={24} xl={24}>
                                    <Form.Item label="Choose Platform" name="platform" >
                                       <Radio.Group buttonStyle="solid" value={lessonSelector.conference} onChange={handleChangeConference} >
                                          <Radio.Button value="brainee" >Brainee Live Class</Radio.Button>
                                          <Radio.Button value="googlemeet">Google Meet Live Class</Radio.Button>
                                          <Radio.Button value="zoom">Zoom Live Class</Radio.Button>
                                       </Radio.Group>
                                       {((lessonSelector.learningmode === "online" ||
                                          lessonSelector.learningmode === "hyflex") && lessonSelector.conference === "") &&
                                          <div style={{ color: "red" }}>Please Choose Mode of Learning</div>}
                                    </Form.Item>

                                 </Col>
                              </Row>}
                        </Col>
                     </Row>


                     <Row gutter={12}>
                        <Col className="gutter-row" span={24}>
                           <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Form.Item className="gutter-row">
                                 <div style={{ display: 'flex', paddingTop: '10px', justifyContent: 'flex-end' }}>
                                    {/* <Button shape="round" type="primary" icon={<DownloadOutlined />} onClick={() => moduleNotAvailable()}  >
                                       Generate Lesson with AI
                                    </Button> */}
                                    {/* <Button type="primary" onClick={ handleNextClick } style={{ display: 'flex', marginLeft: '10%' }}>Next Step</Button> */}
                                    <Button className='button-shadow' htmlType="submit" type="primary" shape='round' /* onClick={handleSaveClick}  */ style={{ display: 'flex', marginLeft: '10px' }}>Save</Button>
                                 </div>
                              </Form.Item>
                           </div>
                        </Col>
                     </Row>
                  </Form>
               </div>
            </div>
         </div>
      </div>
      <div className="notice-area">
         <div className="notice-item">
            <LessonBoard formData={formData} setFormData={setFormData} topics={topiclist} />
         </div>
      </div>
   </>

}

export default LessonCreatiionForm;