import React, { useState, useEffect, createRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Typography, Tooltip, Button, Select, message, Upload, Empty, Modal, Progress, Image, Breadcrumb } from 'antd';
import * as AntDIcons from '@ant-design/icons';
import './Assessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StickyBox from "react-sticky-box";
import axios from 'axios';
import uuid from 'react-uuid';
import AssessmentAssignTool from '../../components/assessment/AssessmentAssignTool';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import BraineeAssessmentTool from '../../components/assessment/BraineeAssessmentTool';
// import { CardRequestHelper } from './Helpers';
import { CardRequestHelper } from '../../helper/AQHelper';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';

import Split from 'react-split';
// import './SplitPanel.css';
// import Iframe from 'react-iframe';
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import { PDFReader } from 'reactjs-pdf-reader';
// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
// import { BrowserView, MobileView } from 'react-device-detect';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Text } = Typography;
var pdfFile = '';

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let tempAssOptions = null;
let defaultAssOptions = {
   id: '',
   assessment_id: '',
   assigned_by: '1',
   duration: '1',
   allowed_attempts: '1',
   passing_percentage: '50',
   allow_result_viewing: '0',
   enable_timer: '0',
   start_date: '',
   end_date: '',
   assigned_students: '',
   term: ''
}

export function BCreatePDF(props) {
   const location = useLocation();
   const navigate = useNavigate();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   // const[answerSheetData, setAnswerSheetData] = useState(questionData);
   const [answerSheetData, setAnswerSheetData] = useState(() => {
      var questionsheet = JSON.parse(localStorage.getItem(user_id + "bd_asd"));
      if (questionsheet !== null) return questionsheet;
      else return [];
   });
   const [drawerVisible, setDrawerVisible] = useState(false);
   const [questionSheetPDF, setQuestionSheetPDF] = useState("");
   const [showAssessmentSheet, setShowAssessmentSheet] = useState(true);
   const [showAssignment, setShowAssignment] = useState(false);
   const [assOptions, setAssOptions] = useState(() => {
      var assoptns = JSON.parse(localStorage.getItem(user_id + "bd_ass_options"));
      if (assoptns !== null) return assoptns;
      else return defaultAssOptions;
   });

   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: location.state.title,
      Term: location.state.term,
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: "",
      Level: "",
      Subject: "",
      Type: location.state.type,
      Instruction: "",
   });

   const [percentage, setPercentage] = React.useState(0);
   const [isUploading, setIsUploading] = React.useState(false);

   const [shouldScroll, setShouldScroll] = useState();
   const qtEndRef = createRef();

   useEffect(() => {
      Utils.verifyJwt();
      // const url = `${process.env.REACT_APP_API_SCHOOL_TERM}/list`
      // axios.get(url)
      // .then((response) => {
      //    console.log(response);
      //    if (response.data)
      //    {
      //       const data = response.data;
      //       setTerms(data);
      //    }

      // }, (error) => {
      //    console.log(error.message);
      // });

      pdfFile = '';

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');
   }, []);

   useEffect(() => {
      scrollToBottom();
   }, [shouldScroll]);

   const scrollToBottom = () => {
      qtEndRef.current?.scrollIntoView({ behavior: 'smooth' });
   }

   const handleOnDragEnd = (result) => {
      if (!result.destination) return;

      const items = Array.from(answerSheetData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setAnswerSheetData(items);

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const handleCardRequest = (method, type, data, idx) => {
      var asd = CardRequestHelper(method, type, data, idx, answerSheetData);

      //--Save to local storage in case something happens to the browser
      localStorage.removeItem(user_id + "bd_asd");
      localStorage.removeItem(user_id + "bd_ass_options");

      if (asd.length > 0) {
         localStorage.setItem(user_id + 'bd_asd', JSON.stringify(asd));
         localStorage.setItem(user_id + 'bd_ass_options', JSON.stringify(assOptions));
      }

      setAnswerSheetData(asd);

      if (method === 'add' || method === 'duplicate')
         setShouldScroll(asd);

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];

      //-- Hide drawer on button click
      // setDrawerVisible(false);
   }

   const upload_option = {
      onUploadProgress: (progressEvent) => {
         const { loaded, total } = progressEvent;
         let p = Math.floor((loaded * 100) / total)
         setPercentage(p)
         //console.log(`${percentage} %`)
      }
   }

   const handleUpload = (file) => {
      // const key = 'updatable';
      // message.loading({ content: 'Uploading your document...', key, duration: 0 });

      const formData = new FormData();
      formData.append('file', file);

      // Utils.getBase64(file, base64 => {
      //    setQuestionSheetPDF(base64);
      // });

      setPercentage(0);
      setIsUploading(true);

      // const url = `${process.env.REACT_APP_API_S3_UPLOAD}/upload?s3path=${process.env.REACT_APP_S3_ASSESSMENT_QUESTIONAIRE_PATH}`;
      // var subdomain = ""; //localStorage.getItem("lms-subdomain");
      const url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadASSFile?s3path=${process.env.REACT_APP_S3_ASSESSMENT_QUESTIONAIRE_PATH}`;
      axios.post(url, formData, upload_option).then(response => {
         console.log(response);
         if (response.data) {
            const data = response.data;
            setQuestionSheetPDF(data);
         }

         setIsUploading(false);
      }, (error) => {
         console.log(error.message);
         setIsUploading(false);
      });
   }

   const UploadPdf = () => {
      const props = {
         beforeUpload: file => {
            const isPDF = file.type === 'application/pdf';
            if (!isPDF) {
               message.error(`${file.name} is not a pdf file`);
            } else {
               setQuestionSheetPDF('');
               handleUpload(file);
            }
            //return isPDF || Upload.LIST_IGNORE;
         },
      };
      return (
         <Upload {...props} showUploadList={false} accept=".pdf">
            <Tooltip placement="left" title='Upload questionaire in PDF format'>
               <Button className='button-shadow' type="primary" size="middle" shape='round'>
                  <Text ellipsis style={{ color: "#fff" }}><AntDIcons.UploadOutlined />&nbsp;Upload Questionaire</Text>
               </Button>
            </Tooltip>
         </Upload>
      );
   };

   const handleSave = (assinfo) => {
      const key = "updatable";

      if (assinfo.title !== '' && assinfo.term !== '' && assinfo.subject !== '' && answerSheetData.length > 0 && questionSheetPDF !== '') {
         message.loading({ content: "Saving diagnostic...", key });

         localStorage.removeItem(user_id + 'bd_asd');
         localStorage.removeItem(user_id + 'bd_ass_options');

         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";

         var ass_info = {
            Id: "diagnostic_" + o2o_mode + "_" + uuid(),
            Title: assinfo.title,
            Term: assinfo.term,
            File_url: questionSheetPDF,
            Question_type: "1",
            Created_by: user_id,
            Copied_from: null,
            Question_sheet: JSON.stringify(answerSheetData),
            Level: assinfo.level,
            Subject: assinfo.subject,
            Type: location.state.type,
            Instruction: assInfo.instruction
         }

         const url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/upsert`
         axios.post(url, ass_info).then(response => {
            // console.log(response);
            if (response.data) {
               const data = response.data;
               message.success({ content: 'Diagnostic was created.', key });
               Modal.success({
                  title: 'Diagnostic Assessment',
                  content: 'Diagnostic was created.',
                  centered: true,
                  onOk: navigate(-1),
               });
            }
         }, (error) => {
            console.log(error.message);
            message.error({ content: 'Diagnostic creation failed.', key });
            Modal.error({
               title: 'Diagnostic Assessment',
               content: 'Diagnostic creation failed.',
               centered: true
            });
         });
      } else {
         Modal.info({
            content: (
               <>
                  Please complete the folowing before saving.
                  <ul>
                     <li>
                        Upload questionaire
                     </li>
                     <li>
                        Title
                     </li>
                     <li>
                        Level
                     </li>
                     <li>
                        Subject
                     </li>
                     <li>
                        Answer keys
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   const showHideAssignment = () => {
      setShowAssignment(!showAssignment);
      setShowAssessmentSheet(!showAssessmentSheet);
   }

   const handleSetOptions = (data) => {
      tempAssOptions = null;
      tempAssOptions = JSON.parse(JSON.stringify(data));
      setAssOptions(tempAssOptions);
   }

   const handleBack = (data) => {
      setShowAssignment(!showAssignment);
      setShowAssessmentSheet(!showAssessmentSheet);
   }

   function sPDFFile() {
      pdfFile = questionSheetPDF;
      return true;
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[65, 35]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate(`/bdiagnostic?type=${location.state.type}`, { state: { activetab: "1" } })}>Diagnostic Assessments</a></li>
                           <li><span>/</span></li>
                           <li className="active">Create</li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <a href="#" onClick={() => navigate(`/bdiagnostic?type=${location.state.type}`, { state: { activetab: "1" } })}>{location.state.type === 'pre' ? '(Pre) ' : '(Post) '} Diagnostic Assessment</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Create</Breadcrumb.Item>
                        </Breadcrumb>
                     </div>

                     <div className="pdf-all-page-container">
                        <StickyBox style={{ zIndex: 1000 }}>
                           <div style={{ textAlign: "right", paddingBottom: '5px' }}>{UploadPdf()}</div>
                        </StickyBox>
                        {
                           questionSheetPDF === ""
                              ?
                              <Empty description="You have not yet uploaded your questionaire" style={{ position: "relative" }} />
                              :
                              <>
                                 <div style={{ width: "100%", height: "calc(100vh - 90px)" }}>
                                    <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                       <Viewer
                                          pageLayout={{
                                             transformSize: ({ size }) => ({
                                                height: size.height + 30,
                                                width: size.width + 30,
                                             }),
                                          }}
                                          fileUrl={`${process.env.REACT_APP_API_S3_URL}/${questionSheetPDF}`}
                                          defaultScale={SpecialZoomLevel.PageWidth}
                                          initialPage={0}
                                       />
                                    </Worker>
                                 </div>
                                 {/* <BrowserView>
                                    <object data={`${process.env.REACT_APP_API_S3_URL}/${questionSheetPDF}`} width="100%" style={{ height: 'calc(100vh - 90px)' }}>
                                       <p>Brainee LMS PDF - View<a href={`${process.env.REACT_APP_API_S3_URL}/${questionSheetPDF}`}> PDF!</a></p>
                                    </object>
                                 </BrowserView>
                                 <MobileView>
                                    <div style={{ width : "100%", height:"calc(100vh - 90px)" }}>
                                       <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                          <Viewer 
                                             pageLayout={{transformSize: ({ size }) => ({
                                                height: size.height + 30,
                                                width: size.width + 30,
                                             }),}}  
                                             fileUrl={`${process.env.REACT_APP_API_S3_URL}/${questionSheetPDF}`}  
                                             defaultScale={SpecialZoomLevel.PageWidth}   
                                             initialPage={0} 
                                          />
                                       </Worker>
                                    </div>
                                 </MobileView> */}
                              </>
                           // <object data={`${process.env.REACT_APP_API_S3_URL}/${questionSheetPDF}`} type="application/pdf" width="100%" style={{ height: 'calc(100vh - 95px)' }}>
                           //    <p>Brainee LMS PDF - View<a href={`${process.env.REACT_APP_API_S3_URL}/${questionSheetPDF}`}> PDF!</a></p>
                           // </object>
                           // <RenderPDFViewer url={`${process.env.REACT_APP_API_S3_URL}/${questionSheetPDF}`} height={"calc(100vh - 90px)"} load={pdfFile !== questionSheetPDF ? sPDFFile() : false} />
                        }

                     </div>
                  </div>
               </div>

               <div>
                  <div className="notice-item">

                     {showAssignment &&
                        <div className='assign-assessment-container'>
                           <AssessmentAssignTool
                              mode={"normal"}
                              options={assOptions}
                              parentCallback={handleSetOptions}
                              backCallback={handleBack} />
                        </div>
                     }

                     {showAssessmentSheet &&
                        <>
                           <div className='answer-sheet-content'>
                              <SimpleBarReact>
                                 <StickyBox style={{ zIndex: 1000 }}>
                                    <BraineeAssessmentTool
                                       key={"createPDF"}
                                       data={assInfo}
                                       cardRequestCallback={handleCardRequest}
                                       saveCallback={handleSave}
                                       showToggle={true}
                                       mode={"create"}
                                       type={'pdf'}
                                       assessment_type={'diagnostic'}
                                    />
                                 </StickyBox>

                                 <DragDropContext onDragEnd={(e) => handleOnDragEnd(e)}>
                                    <Droppable droppableId="questions">
                                       {(provided) => (
                                          <ul className="questions" {...provided.droppableProps} ref={provided.innerRef}>
                                             {answerSheetData.map(({ id, type, points, correct, option_labels, image_url, question }, idx) => {

                                                if (type === "section") {
                                                   qcardIdx = 1;
                                                   idxCol.push(sectionIdx);
                                                   sectionIdx++;
                                                } else {
                                                   idxCol.push(qcardIdx);
                                                   qcardIdx++;
                                                }

                                                return (
                                                   <Draggable key={id} draggableId={id} index={idx}>
                                                      {(provided) => (
                                                         <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <BraineeQuestionCard
                                                               key={id}
                                                               index={idxCol[idx]}
                                                               id={idx}
                                                               type={type}
                                                               points={points}
                                                               correct={correct}
                                                               option_labels={option_labels}
                                                               image_url={image_url}
                                                               question={question}
                                                               question_type={'1'}
                                                               mode={'create'}
                                                               parentCallback={handleCardRequest}
                                                               assessment_type={'diagnostic'}
                                                            />
                                                         </li>
                                                      )}
                                                   </Draggable>
                                                );
                                             })}
                                             {provided.placeholder}
                                          </ul>
                                       )}
                                    </Droppable>
                                 </DragDropContext>

                                 <div ref={qtEndRef} />
                              </SimpleBarReact>
                           </div>
                        </>
                     }
                  </div>
               </div>
            </Split>
         </div>

         <Modal
            key="modal-upload"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" /> Upload File</div>}
            destroyOnClose={true}
            centered
            open={isUploading}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <span>Uploading...</span>
            <Progress
               percent={percentage}
               status="active"
               strokeColor={{
                  '0%': '#5A98D2',
                  '100%': '#70D0D4',
               }}
            />
         </Modal>
      </>
   )
}
