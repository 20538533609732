/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Typography, Divider, List, Button, Breadcrumb, Tooltip, Modal, Popconfirm, Segmented } from 'antd';
import axios from 'axios';

import Utils from '../../misc/Utils';
import Split from 'react-split';
// import { Editor } from '@tinymce/tinymce-react';
import Wysiwyg2 from '../../components/wysiwyg/wysiwyg2';
import { CheckCircleOutlined, VideoCameraOutlined } from '@ant-design/icons';

import './MasteryAssessment.css';
import '../../../node_modules/simplebar/src/simplebar.css';
import DocumentViewer from '../../components/document-viewer/DocumentViewer';

const { Title, Text } = Typography;

let g_recommendation_data = {
   id: null,
   description: '',
   documents: null,
   document_names: null,
   status: null,
}

export function MasteryRecommendation(props) {
   const location = useLocation();
   const navigate = useNavigate();
   const editorRef = useRef(null);

   const student_id = location.state.student_id;
   const assign_id = location.state.assign_id;
   const mastery_id = location.state.mastery_id;
   // const assignInfo = location.state.assignInfo;
   // const recommendationData = location.state.recommendation_data;
   const origin = location.state.activetab;
   const join_url = location.state.join_url;

   const [currentRecommendationDocI, setCurrentRecommendationDocI] = useState(null);
   const [currentRecommendationDocG, setCurrentRecommendationDocG] = useState(null);

   const [selectedDocumentIdx, setSelectedDocumentIdx] = useState(-1);
   const [recommendationDataAll, setRecommendationDataAll] = useState([]);
   const [recommendationData, setRecommendationData] = useState(g_recommendation_data);
   const [recommendationType, setRecommendationType] = useState('individual');

   useEffect(() => {
      Utils.verifyJwt();

      getRecommendations();
   }, []);

   const getRecommendations = () => {
      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/getrecommendations?student_id=${student_id}&assignment_id=${assign_id}`;
      axios.get(url).then(response => {
         if (response.data) {
            var data = response.data;
            setRecommendationDataAll(data);

            // handleShowRecommendation(data, data[0].is_group ? 'group' : 'individual');
            handleShowRecommendation(data, 'individual');
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Getting recommendations failed.',
            centered: true
         });
      });
   }

   const handleViewRecommendationDoc = (document, idx) => {
      setSelectedDocumentIdx(idx);

      if (recommendationType === 'individual')
         setCurrentRecommendationDocI(document);
      else
         setCurrentRecommendationDocG(document);
   }

   const handleJoinZoom = () => {
      window.open(join_url, "_blank").focus();
   }

   const handleCompleteRecommendation = () => {
      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/setrecommendationstatus?student_id=${student_id}&assignment_id=${assign_id}&status=2`;;

      if (recommendationData.status === 1) {
         axios.post(url).then(response => {
            if (response.data) {
               navigate(`/studentmasteryassessment/preview?assign_id=${assign_id}&mastery_id=${mastery_id}&origin=${origin}`);
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Mastery Assessment',
               content: 'Attempt to update recommendation status  failed.',
               centered: true
            });
         });
      }
   }

   const handleShowRecommendation = (data_all, type) => {
      var data = {
         id: null,
         description: '',
         documents: null,
         document_names: null,
         status: null,
      };
      var is_group = type === 'group' ? true : false;

      data_all.forEach(element => {
         if (element.is_group === is_group)
            data = element;
      });

      g_recommendation_data = {
         id: data.recommendation_id,
         description: data.description ?? '',
         documents: data.documents !== null ? data.documents.split(',') : null,
         document_names: data.document_names !== null ? data.document_names.split(',') : null,
         status: data.status,
      }

      setSelectedDocumentIdx(-1);
      setRecommendationData(g_recommendation_data);
      setRecommendationType(type);
   }

   return (
      <>
         {
            recommendationDataAll.length > 0
               ?
               <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
                  <Split
                     sizes={[65, 35]}
                     direction="horizontal"
                     cursor="col-resize"
                     className="split-flex"
                  >
                     <div style={{ width: `65%` }}>
                        <div className="dashboard-item">
                           <div className="dashboard-item-inner">
                              {/* <ul>
                                 <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                                 <li><span>/</span></li>
                                 <li><a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: origin } })}>Mastery Assessments</a></li>
                                 <li><span>/</span></li>
                                 <li><a href="#" onClick={() => navigate(`/studentmasteryassessment/preview?assign_id=${assign_id}&mastery_id=${mastery_id}&origin=${origin}&qt=${location.state.qt}`)}>Start</a></li>
                                 <li><span>/</span></li>
                                 <li className="active">Teacher's Recommendation</li>
                              </ul> */}
                              <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                                 <Breadcrumb.Item>
                                    <Link to="/home">Home</Link>
                                 </Breadcrumb.Item>
                                 <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                                 <Breadcrumb.Item>
                                    <a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: origin } })}>Mastery Assessment</a>
                                 </Breadcrumb.Item>
                                 <Breadcrumb.Item>
                                    <a href="#" onClick={() => navigate(`/studentmasteryassessment/preview?assign_id=${assign_id}&mastery_id=${mastery_id}&origin=${origin}&qt=${location.state.qt}`)}>Start</a>
                                 </Breadcrumb.Item>
                                 <Breadcrumb.Item>Teacher's Recommendation</Breadcrumb.Item>
                              </Breadcrumb>
                           </div>
                        </div>

                        <div className='recommendation_view'>
                           <DocumentViewer
                              document={recommendationType === 'individual' ? process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH + '/' + currentRecommendationDocI : process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH + '/' + currentRecommendationDocG}
                              documentlist={recommendationData.documents}
                              // pdfheight={"calc(100vh - 55px)"}
                              // videoheight={"calc(100svh - 60px)"}
                              dvheight={"calc(100vh - 55px)"}
                           />
                        </div>
                     </div>

                     <div style={{ width: `35%` }}>
                        <div className="notice-item">
                           <>
                              {  //assInfo.Question_sheet && 
                                 <div className='answer-sheet-content'>
                                    <Segmented
                                       value={recommendationType}
                                       block
                                       options={[
                                          {
                                             label: 'Class Recommendation',
                                             value: 'group'
                                          },
                                          {
                                             label: 'Teacher Recommendation',
                                             value: 'individual'
                                          }
                                       ]}
                                       onChange={(val) => handleShowRecommendation(recommendationDataAll, val)}
                                    />

                                    <Divider orientation="left">
                                       <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Recommendation</Title>
                                    </Divider>

                                    <Wysiwyg2
                                       // changeContentCallback={(e) => handleDescriptionChange(e)} 
                                       content={recommendationData.description ?? ''}
                                       height={350}
                                       contentHeight={310}
                                       disabled={true}
                                    />
                                    {/* <Editor
                                       apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                                       onInit={(evt, editor) => editorRef.current = editor}
                                       initialValue={recommendationData.description ?? ''}
                                       disabled={true}
                                       init={{
                                          menubar: false,
                                          height: 350,
                                          statusbar: false,
                                          plugins: [
                                             'advlist autolink lists link image charmap print preview anchor',
                                             'searchreplace visualblocks code',
                                             'insertdatetime media table paste code help wordcount emoticons'
                                          ],
                                          toolbar: false,
                                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                       }}
                                       value={recommendationData.description === null ?? ''}
                                    /> */}
                                    {
                                       recommendationType === 'individual'
                                          ?
                                          (recommendationData.status === 2 || recommendationData.status === null)
                                             ?
                                             <Button
                                                disabled={true}
                                                block
                                                type='primary'
                                                shape='round'
                                                style={{ marginTop: "10px" }}
                                             >
                                                <Text ellipsis style={{ color: "#ccc" }}><CheckCircleOutlined />&nbsp;{recommendationData.status === 2 ? 'Completed' : 'Done'}</Text>
                                             </Button>
                                             :
                                             <Popconfirm
                                                title={"Have you completed the teacher's recommendation?"}
                                                onConfirm={() => handleCompleteRecommendation()}
                                                okText="Yes"
                                                cancelText="No"
                                             >
                                                <Button
                                                   block
                                                   className={'button-shadow'}
                                                   type='primary'
                                                   shape='round'
                                                   style={{ marginTop: "10px" }}
                                                >
                                                   <Text ellipsis style={{ color: "#fff" }}><CheckCircleOutlined />&nbsp;Done</Text>
                                                </Button>
                                             </Popconfirm>
                                          :
                                          <></>
                                    }

                                    {
                                       join_url !== "" && join_url !== null &&
                                       <>
                                          <Divider orientation="left">
                                             <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Video Conference Session</Title>
                                          </Divider>
                                          <Button
                                             block
                                             className='button-shadow'
                                             type='primary'
                                             shape='round'
                                             // style={{ marginBottom: "15px" }}
                                             onClick={() => handleJoinZoom()}>
                                             <Text ellipsis style={{ color: "#fff" }}><VideoCameraOutlined />&nbsp;Join</Text>
                                          </Button>
                                       </>

                                    }
                                    <Divider orientation="left">
                                       <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>View Documents</Title>
                                    </Divider>
                                    {
                                       (recommendationData.documents !== null && recommendationData.documents !== '') &&
                                       <>
                                          <List
                                             size="small"
                                             dataSource={recommendationData.documents}
                                             renderItem={(item, idx) =>
                                                <>
                                                   <Tooltip placement="left" title={recommendationData.document_names[idx]}>
                                                      <List.Item
                                                         onClick={() => handleViewRecommendationDoc(item, idx)}
                                                         className={idx === selectedDocumentIdx ? 'document-file-selected' : 'document-file'}
                                                         style={{ textAlign: "left", whiteSpace: "nowrap", textOverflow: "ellipsis", margin: "5px" }}
                                                      >
                                                         {Utils.GetFileIcon(recommendationData.document_names[idx])} {recommendationData.document_names[idx]}
                                                      </List.Item>
                                                   </Tooltip>
                                                </>
                                             }
                                          />
                                       </>
                                    }

                                 </div>
                              }
                           </>
                        </div>
                     </div>
                  </Split>
               </div>
               :
               <></>
         }

      </>
   )
}
