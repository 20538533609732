import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, message } from 'antd';
import './Assessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import StickyBox from "react-sticky-box";

// import AllPagesPDFViewer from '../../components/pdf-viewer/all-pages';
import QuestionCard from '../../components/assessment/QuestionCard';
import AssessmentAssignTool from '../../components/assessment/AssessmentAssignTool';
import AssessmentTools from '../../components/assessment/AssessmentTools';

// import './Responsive.css';

const { Text, Title } = Typography;
const questionData = [
   {
      "id": "1",
      "type": "section",
      "correct": "Piliin ang tamang sagot",
   },
   {
      "id": "10",
      "type": "multiple_choice",
      "correct": ["0", "0", "0"],
      "points": "1",
      "option_labels": ["A", "B", "C"],
      "question": ""
   },
   {
      "id": "11",
      "type": "multiple_choice",
      "correct": ["0", "0", "0"],
      "points": "1",
      "option_labels": ["A", "B", "C"],
      "question": ""
   },
   {
      "id": "12",
      "type": "multiple_choice",
      "correct": ["0", "0", "0"],
      "points": "1",
      "option_labels": ["A", "B", "C"],
      "question": ""
   },
   {
      "id": "13",
      "type": "multiple_choice",
      "correct": ["0", "0", "0"],
      "points": "1",
      "option_labels": ["A", "B", "C"],
      "question": ""
   },
   {
      "id": "14",
      "type": "multiple_choice",
      "correct": ["0", "0", "0"],
      "points": "1",
      "option_labels": ["A", "B", "C"],
      "question": ""
   },
]
let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let tempAssOptions = null;

export function AssignPDF(props) {
   const [answerSheetData, setAnswerSheetData] = useState(questionData);
   const [questionSheetPDF, setQuestionSheetPDF] = useState("https://media.campuscloudph.com/ssapamp/uploads/lms_assessment/lms_assessment_online_163922682965702119/assessment_online_163922685061451080.pdf");
   const [showAssessmentSheet, setShowAssessmentSheet] = useState(true);
   const [showAssignment, setShowAssignment] = useState(false);
   const [assOptions, setAssOptions] = useState({
      id: '',
      assessment_id: '',
      assigned_by: '1',
      duration: '1',
      allowed_attempts: '1',
      passing_percentage: '50',
      allow_result_viewing: '0',
      enable_timer: '0',
      start_date: '',
      end_date: '',
      assigned_students: '',
      term: ''
   });
   const [assTTD, setAssTTD] = useState({ title: "", term: "", date: "" });

   const navigate = useNavigate();

   const handleCardRequest = (method, type, data, idx) => {
   }

   const handleSave = () => {
      message.success(JSON.stringify(answerSheetData));
   }

   const showHideAssignment = () => {
      setShowAssignment(!showAssignment);
      setShowAssessmentSheet(!showAssessmentSheet);
   }

   const handleSetOptions = (data) => {
      // message.info(JSON.stringify(data));
      tempAssOptions = null;
      tempAssOptions = JSON.parse(JSON.stringify(data));
      setAssOptions(tempAssOptions);
      // setShowAssignment(!showAssignment);
      // setShowAssessmentSheet(!showAssessmentSheet);
   }

   const handleBack = (data) => {
      message.info(JSON.stringify(data));
      setShowAssignment(!showAssignment);
      setShowAssessmentSheet(!showAssessmentSheet);
   }

   return (
      <>
         <div className="dashboard-area">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate(-1)}>Summative Assessments</a></li>
                     <li><span>/</span></li>
                     <li><a className="active" href="#">Assign</a></li>
                  </ul>
               </div>

               <div className="pdf-all-page-container">

                  <SimpleBarReact >
                     {/* <div style={{textAlign: "right"}}>{UploadPdf()}</div>*/}
                     {/* <AllPagesPDFViewer pdf={samplePDF}/> */}
                     {/* <AllPagesPDFViewer pdf={questionSheetPDF} /> */}
                  </SimpleBarReact>
               </div>
            </div>
         </div>

         <div className="notice-area" style={{ display: "block" }}>
            <div className="notice-item">

               {showAssignment &&
                  <div className='assign-assessment-container'>
                     <AssessmentAssignTool
                        mode={"normal"}
                        options={assOptions}
                        parentCallback={handleSetOptions}
                        backCallback={handleBack} />
                  </div>
               }

               {showAssessmentSheet &&
                  <>
                     <div className='answer-sheet-content'>
                        <SimpleBarReact>
                           <StickyBox style={{ zIndex: 1000 }}>
                              <AssessmentTools
                                 key={"assignPDF"}
                                 data={assTTD}
                                 cardRequestCallback={handleCardRequest}
                                 showHideAssCallback={showHideAssignment}
                                 saveCallback={handleSave}
                                 showToggle={true}
                                 mode={"assign"} />
                           </StickyBox>

                           <ul className="questions">
                              {answerSheetData.map(({ id, type, points, correct, option_labels }, idx) => {

                                 if (type == "section") {
                                    qcardIdx = 1;
                                    idxCol.push(sectionIdx);
                                    sectionIdx++;
                                 } else {
                                    idxCol.push(qcardIdx);
                                    qcardIdx++;
                                 }

                                 return (
                                    <li>
                                       <QuestionCard
                                          key={id}
                                          index={idxCol[idx]}
                                          id={idx}
                                          type={type}
                                          points={points}
                                          correct={correct}
                                          option_labels={option_labels}
                                          question_type={'1'}
                                          mode={'assign'}
                                          parentCallback={handleCardRequest} />
                                    </li>
                                 );
                              })}
                           </ul>
                        </SimpleBarReact>
                     </div>
                  </>
               }
            </div>
         </div>
      </>
   )
}
