/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import {
   Row, Col, Empty, Spin
} from 'antd';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { IntroSum } from '../components';

export function Grade8SummaryReport(props) {
   const [reportData, setReportData] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   const [vocabulary, setVocabulary] = useState(null);
   const [literature, setLiterature] = useState(null);
   const [readingComprehension, setReadingComprehension] = useState(null);
   const [grammar, setGrammar] = useState(null);
   const [viewingComprehension, setViewingComprehension] = useState(null);
   const [englishTotal, setEnglishTotal] = useState(null);

   const [geometry, setGeometry] = useState(null);
   const [patternsAndAlgebra, setPatternsAndAlgebra] = useState(null);
   const [statisticsAndProbability, setStatisticsAndProbability] = useState(null);
   const [mathTotal, setMathTotal] = useState(null);

   const [matter, setMatter] = useState(null);
   const [livingThings, setLivingThings] = useState(null);
   const [forceAndMotion, setForceAndMotion] = useState(null);
   const [earthAndSpace, setEarthAndSpace] = useState(null);
   const [earthAndSpace2, setEarthAndSpace2] = useState(null);
   const [scienceTotal, setScienceTotal] = useState(null);

   const [englishPCData, setEnglishPCData] = useState(null);
   const [mathPCData, setMathPCData] = useState(null);
   const [sciencePCData, setSciencePCData] = useState(null);
   const [VN, setVN] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();

      getReport(props.assignment_id, props.section_id);
   }, [props.assignment_id]);

   const getReport = (assignment_id, section_id) => {
      setDataLoading(true);

      var url = `${process.env.REACT_APP_API_CPH}/getdiagnosticreportdata?aid=${assignment_id}&sid=${section_id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            //-- English
            if (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') {
               var englishPreData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'pre');
               var englishPostData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'post');
               setVN(englishPreData.length);
            }

            //-- Math
            if (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') {
               var mathPreData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'pre');
               var mathPostData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'post');
               setVN(mathPreData.length);
            }

            //-- Science
            if (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') {
               var sciencePreData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'pre');
               var sciencePostData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'post');
               setVN(sciencePreData.length);
            }

            url = `${process.env.REACT_APP_API_CPH}/getdiagnosticsummarypercentdata?aid=${assignment_id}&sid=${section_id}`;
            axios.get(url).then((response) => {

               if (response.data) {
                  const data2 = response.data;

                  if (props.subject_id === null || props.subject_id === 'ems') {
                     setEnglishPCData(data2[0]);
                     extractDataForEnglish(englishPreData, englishPostData);

                     setMathPCData(data2[1]);
                     extractDataForMath(mathPreData, mathPostData);

                     setSciencePCData(data2[2]);
                     extractDataForScience(sciencePreData, sciencePostData);
                  } else {
                     if (props.subject_id === 'english') {
                        setEnglishPCData(data2[0]);
                        extractDataForEnglish(englishPreData, englishPostData);
                     }

                     if (props.subject_id === 'math') {
                        setMathPCData(data2[0]);
                        extractDataForMath(mathPreData, mathPostData);
                     }

                     if (props.subject_id === 'science') {
                        setSciencePCData(data2[0]);
                        extractDataForScience(sciencePreData, sciencePostData);
                     }
                  }

                  setReportData(data);
                  setDataLoading(false);
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const extractDataForEnglish = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _readingComprehension = {};
      var _viewingComprehension = {};
      var _vocabulary = {};
      var _literature = {};
      var _grammar = {};

      var _total = {};
      var _proficiency = '';

      //-- Reading Comprehension (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 +
            pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 11) / 2.45) > 1)
            _competent++;
         else if (((_sumVal - 11) / 2.45) > -0.51)
            _basic++;
         else if (((_sumVal - 11) / 2.45) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 +
               post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 11) / 2.45) > 1)
                  _competent++;
               else if (((_sumVal - 11) / 2.45) > -0.51)
                  _basic++;
               else if (((_sumVal - 11) / 2.45) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 6.29 ? 'Beginner' : (_postAve >= 6.3 && _postAve <= 8.41 ? 'Basic' : (_postAve >= 8.42 && _postAve <= 13 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 6.29 ? 'Beginner' : (_preAve >= 6.3 && _preAve <= 8.41 ? 'Basic' : (_preAve >= 8.42 && _preAve <= 13 ? 'Competent' : ''));
      }

      _readingComprehension = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Reading Comprehension (end)

      //-- Viewing Comprehension (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.25) / 0.96) > 1)
            _competent++;
         else if (((_sumVal - 1.25) / 0.96) > -0.51)
            _basic++;
         else if (((_sumVal - 1.25) / 0.96) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s14 + post_data[i].s15 + post_data[i].s16;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.25) / 0.96) > 1)
                  _competent++;
               else if (((_sumVal - 1.25) / 0.96) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.25) / 0.96) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 0.76 ? 'Beginner' : (_postAve >= 0.77 && _postAve <= 2.21 ? 'Basic' : (_postAve >= 2.22 && _postAve <= 3 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 0.76 ? 'Beginner' : (_preAve >= 0.77 && _preAve <= 2.21 ? 'Basic' : (_preAve >= 2.22 && _preAve <= 3 ? 'Competent' : ''));
      }

      _viewingComprehension = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Viewing Comprehension (end)      

      //-- Vocabulary (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 +
            pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 10) / 4.97) > 1)
            _competent++;
         else if (((_sumVal - 10) / 4.97) > -0.51)
            _basic++;
         else if (((_sumVal - 10) / 4.97) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s17 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 +
               post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34 + post_data[i].s35;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 10) / 4.97) > 1)
                  _competent++;
               else if (((_sumVal - 10) / 4.97) > -0.51)
                  _basic++;
               else if (((_sumVal - 10) / 4.97) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 7.51 ? 'Beginner' : (_postAve >= 7.52 && _postAve <= 14.97 ? 'Basic' : (_postAve >= 14.98 && _postAve <= 19 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 7.51 ? 'Beginner' : (_preAve >= 7.52 && _preAve <= 14.97 ? 'Basic' : (_preAve >= 14.98 && _preAve <= 19 ? 'Competent' : ''));
      }

      _vocabulary = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Vocabulary (end)

      //-- Literature (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4.5) / 2.08) > 1)
            _competent++;
         else if (((_sumVal - 4.5) / 2.08) > -0.51)
            _basic++;
         else if (((_sumVal - 4.5) / 2.08) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s36 + post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40 + post_data[i].s41 + post_data[i].s42 + post_data[i].s43 + post_data[i].s44;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4.5) / 2.08) > 1)
                  _competent++;
               else if (((_sumVal - 4.5) / 2.08) > -0.51)
                  _basic++;
               else if (((_sumVal - 4.5) / 2.08) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.45 ? 'Beginner' : (_postAve >= 3.46 && _postAve <= 6.58 ? 'Basic' : (_postAve >= 6.59 && _postAve <= 9 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.45 ? 'Beginner' : (_preAve >= 3.46 && _preAve <= 6.58 ? 'Basic' : (_preAve >= 6.59 && _preAve <= 9 ? 'Competent' : ''));
      }

      _literature = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Literature (end)

      //-- Grammar (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s45 + pre_data[i].s46 + pre_data[i].s47 + pre_data[i].s48 + pre_data[i].s49 + pre_data[i].s50 + pre_data[i].s51 + pre_data[i].s52 + pre_data[i].s53 +
            pre_data[i].s54 + pre_data[i].s55 + pre_data[i].s56 + pre_data[i].s57 + pre_data[i].s58 + pre_data[i].s59 + pre_data[i].s60;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 11) / 2.45) > 1)
            _competent++;
         else if (((_sumVal - 11) / 2.45) > -0.51)
            _basic++;
         else if (((_sumVal - 11) / 2.45) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s45 + post_data[i].s46 + post_data[i].s47 + post_data[i].s48 + post_data[i].s49 + post_data[i].s50 + post_data[i].s51 + post_data[i].s52 + post_data[i].s53 +
               post_data[i].s54 + post_data[i].s55 + post_data[i].s56 + post_data[i].s57 + post_data[i].s58 + post_data[i].s59 + post_data[i].s60;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 11) / 2.45) > 1)
                  _competent++;
               else if (((_sumVal - 11) / 2.45) > -0.51)
                  _basic++;
               else if (((_sumVal - 11) / 2.45) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 9.77 ? 'Beginner' : (_postAve >= 9.78 && _postAve <= 13.45 ? 'Basic' : (_postAve >= 13.46 && _postAve <= 16 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 9.77 ? 'Beginner' : (_preAve >= 9.78 && _preAve <= 13.45 ? 'Basic' : (_preAve >= 13.46 && _preAve <= 16 ? 'Competent' : ''));
      }

      _grammar = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Grammar (end)      

      _total = {
         vn: pre_data.length,
         prem: _vocabulary.prem + _literature.prem + _readingComprehension.prem + _grammar.prem + _viewingComprehension.prem,
         postm: _vocabulary.postm + _literature.postm + _readingComprehension.postm + _grammar.postm + _viewingComprehension.postm,
         min: _vocabulary.min + _literature.min + _readingComprehension.min + _grammar.min + _viewingComprehension.min,
         max: _vocabulary.max + _literature.max + _readingComprehension.max + _grammar.max + _viewingComprehension.max,
         sd: _vocabulary.sd + _literature.sd + _readingComprehension.sd + _grammar.sd + _viewingComprehension.sd,
         proficiency: _proficiency,
         beginner: _vocabulary.beginner + _literature.beginner + _readingComprehension.beginner + _grammar.beginner + _viewingComprehension.beginner,
         basic: _vocabulary.basic + _literature.basic + _readingComprehension.basic + _grammar.basic + _viewingComprehension.basic,
         competent: _vocabulary.competent + _literature.competent + _readingComprehension.competent + _grammar.competent + _viewingComprehension.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 25.8 ? 'Beginner' : (_total.postm >= 25.81 && _total.postm <= 40.56 ? 'Basic' : (_total.postm >= 40.57 && _total.postm <= 60 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 25.8 ? 'Beginner' : (_total.prem >= 25.81 && _total.prem <= 40.56 ? 'Basic' : (_total.prem >= 40.57 && _total.prem <= 60 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setEnglishTotal(_total);
      setViewingComprehension(_viewingComprehension);
      setVocabulary(_vocabulary);
      setLiterature(_literature);
      setReadingComprehension(_readingComprehension);
      setGrammar(_grammar);
   }

   const extractDataForMath = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _algebra = {};
      var _geometry = {};
      var _statistics = {};
      var _proficiency = '';
      var _total = 0;

      //-- Algebra (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 +
            pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 +
            pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 +
            pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 19.5) / 1.91) > 1)
            _competent++;
         else if (((_sumVal - 19.5) / 1.91) > -0.51)
            _basic++;
         else if (((_sumVal - 19.5) / 1.91) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 +
               post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 +
               post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 + post_data[i].s27 + post_data[i].s28 + post_data[i].s29 +
               post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34 + post_data[i].s35 + post_data[i].s36 + post_data[i].s37;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 19.5) / 1.91) > 1)
                  _competent++;
               else if (((_sumVal - 19.5) / 1.91) > -0.51)
                  _basic++;
               else if (((_sumVal - 19.5) / 1.91) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 18.5 ? 'Beginner' : (_postAve >= 18.51 && _postAve <= 21.4 ? 'Basic' : (_postAve >= 21.41 && _postAve <= 37 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 18.5 ? 'Beginner' : (_preAve >= 18.51 && _preAve <= 21.4 ? 'Basic' : (_preAve >= 21.41 && _preAve <= 37 ? 'Competent' : ''));
      }

      _algebra = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //--Algebra (end)

      //-- Geometry (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 + pre_data[i].s45 +
            pre_data[i].s46 + pre_data[i].s47 + pre_data[i].s48 + pre_data[i].s49;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 5.75) / 1.5) > 1)
            _competent++;
         else if (((_sumVal - 5.75) / 1.5) > -0.51)
            _basic++;
         else if (((_sumVal - 5.75) / 1.5) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s38 + post_data[i].s39 + post_data[i].s40 + post_data[i].s41 + post_data[i].s42 + post_data[i].s43 + post_data[i].s44 + post_data[i].s45 +
               post_data[i].s46 + post_data[i].s47 + post_data[i].s48 + post_data[i].s49;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 5.75) / 1.5) > 1)
                  _competent++;
               else if (((_sumVal - 5.75) / 1.5) > -0.51)
                  _basic++;
               else if (((_sumVal - 5.75) / 1.5) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 4.99 ? 'Beginner' : (_postAve >= 5 && _postAve <= 7.25 ? 'Basic' : (_postAve >= 7.26 && _postAve <= 12 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 4.99 ? 'Beginner' : (_preAve >= 5 && _preAve <= 7.25 ? 'Basic' : (_preAve >= 7.26 && _preAve <= 12 ? 'Competent' : ''));
      }

      _geometry = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Geometry (end)

      //-- Statistics and Probability (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s50 + pre_data[i].s51 + pre_data[i].s52 + pre_data[i].s53 + pre_data[i].s54 + pre_data[i].s55;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 3.5) / 0.58) > 1)
            _competent++;
         else if (((_sumVal - 3.5) / 0.58) > -0.51)
            _basic++;
         else if (((_sumVal - 3.5) / 0.58) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s50 + post_data[i].s51 + post_data[i].s52 + post_data[i].s53 + post_data[i].s54 + post_data[i].s55;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 3.5) / 0.58) > 1)
                  _competent++;
               else if (((_sumVal - 3.5) / 0.58) > -0.51)
                  _basic++;
               else if (((_sumVal - 3.5) / 0.58) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.2 ? 'Beginner' : (_postAve >= 3.21 && _postAve <= 4.08 ? 'Basic' : (_postAve >= 4.09 && _postAve <= 6 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.2 ? 'Beginner' : (_preAve >= 3.21 && _preAve <= 4.08 ? 'Basic' : (_preAve >= 4.09 && _preAve <= 6 ? 'Competent' : ''));
      }

      _statistics = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Statistics and Probability (end)

      _total = {
         vn: pre_data.length,
         prem: _geometry.prem + _algebra.prem + _statistics.prem,
         postm: _geometry.postm + _algebra.postm + _statistics.postm,
         min: _geometry.min + _algebra.min + _statistics.min,
         max: _geometry.max + _algebra.max + _statistics.max,
         sd: _geometry.sd + _algebra.sd + _statistics.sd,
         proficiency: _proficiency,
         beginner: _geometry.beginner + _algebra.beginner + _statistics.beginner,
         basic: _geometry.basic + _algebra.basic + _statistics.basic,
         competent: _geometry.competent + _algebra.competent + _statistics.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 25 ? 'Beginner' : (_total.postm >= 25.01 && _total.postm <= 30.2 ? 'Basic' : (_total.postm >= 30.21 && _total.postm <= 55 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 25 ? 'Beginner' : (_total.prem >= 25.01 && _total.prem <= 30.2 ? 'Basic' : (_total.prem >= 30.21 && _total.prem <= 55 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setMathTotal(_total);
      setGeometry(_geometry);
      setPatternsAndAlgebra(_algebra);
      setStatisticsAndProbability(_statistics);
   }

   const extractDataForScience = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _forceandmotion = {};
      var _matter = {};
      var _livingthings = {};
      var _earthandspace = {};
      var _proficiency = '';
      var _total = 0;

      //-- Force and Motion (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11 +
            pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 +
            pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 + pre_data[i].s27;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 15.75) / 3.69) > 1)
            _competent++;
         else if (((_sumVal - 15.75) / 3.69) > -0.51)
            _basic++;
         else if (((_sumVal - 15.75) / 3.69) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 + post_data[i].s11 +
               post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 +
               post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 + post_data[i].s27;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 15.75) / 3.69) > 1)
                  _competent++;
               else if (((_sumVal - 15.75) / 3.69) > -0.51)
                  _basic++;
               else if (((_sumVal - 15.75) / 3.69) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 13.9 ? 'Beginner' : (_postAve >= 13.91 && _postAve <= 19.4 ? 'Basic' : (_postAve >= 19.41 && _postAve <= 27 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 13.9 ? 'Beginner' : (_preAve >= 13.91 && _preAve <= 19.4 ? 'Basic' : (_preAve >= 19.41 && _preAve <= 27 ? 'Competent' : ''));
      }

      _forceandmotion = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Force and Motion (end)

      //-- Matter (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 3.5) / 1.29) > 1)
            _competent++;
         else if (((_sumVal - 3.5) / 1.29) > -0.51)
            _basic++;
         else if (((_sumVal - 3.5) / 1.29) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34 + post_data[i].s35 + post_data[i].s36;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 3.5) / 1.29) > 1)
                  _competent++;
               else if (((_sumVal - 3.5) / 1.29) > -0.51)
                  _basic++;
               else if (((_sumVal - 3.5) / 1.29) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2.85 ? 'Beginner' : (_postAve >= 2.86 && _postAve <= 4.79 ? 'Basic' : (_postAve >= 4.80 && _postAve <= 6 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 2.85 ? 'Beginner' : (_preAve >= 2.86 && _preAve <= 4.79 ? 'Basic' : (_preAve >= 4.80 && _preAve <= 6 ? 'Competent' : ''));
      }

      _matter = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Matter (end)

      //-- Living Things and their Environment (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 + pre_data[i].s45 + pre_data[i].s46 +
            pre_data[i].s47 + pre_data[i].s48 + pre_data[i].s49 + pre_data[i].s50 + pre_data[i].s51 + pre_data[i].s52 + pre_data[i].s53 + pre_data[i].s54 + pre_data[i].s55;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 10) / 1.2) > 1)
            _competent++;
         else if (((_sumVal - 10) / 1.2) > -0.51)
            _basic++;
         else if (((_sumVal - 10) / 1.2) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40 + post_data[i].s41 + post_data[i].s42 + post_data[i].s43 + post_data[i].s44 + post_data[i].s45 + post_data[i].s46 +
               post_data[i].s47 + post_data[i].s48 + post_data[i].s49 + post_data[i].s50 + post_data[i].s51 + post_data[i].s52 + post_data[i].s53 + post_data[i].s54 + post_data[i].s55;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 10) / 1.2) > 1)
                  _competent++;
               else if (((_sumVal - 10) / 1.2) > -0.51)
                  _basic++;
               else if (((_sumVal - 10) / 1.2) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 9.39 ? 'Beginner' : (_postAve >= 9.40 && _postAve <= 11.2 ? 'Basic' : (_postAve >= 11.21 && _postAve <= 19 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 9.39 ? 'Beginner' : (_preAve >= 9.40 && _preAve <= 11.2 ? 'Basic' : (_preAve >= 11.21 && _preAve <= 19 ? 'Competent' : ''));
      }

      _livingthings = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Living Things and their Environment (end)    

      //-- Earth and Space (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30 +
            pre_data[i].s56 + pre_data[i].s57 + pre_data[i].s58 + pre_data[i].s59 + pre_data[i].s60 + pre_data[i].s61 + pre_data[i].s62 + pre_data[i].s63 + pre_data[i].s64 + pre_data[i].s65;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 8) / 2.58) > 1)
            _competent++;
         else if (((_sumVal - 8) / 2.58) > -0.51)
            _basic++;
         else if (((_sumVal - 8) / 2.58) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s28 + post_data[i].s29 + post_data[i].s30 +
               post_data[i].s56 + post_data[i].s57 + post_data[i].s58 + post_data[i].s59 + post_data[i].s60 + post_data[i].s61 + post_data[i].s62 + post_data[i].s63 + post_data[i].s64 + post_data[i].s65;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 8) / 2.58) > 1)
                  _competent++;
               else if (((_sumVal - 8) / 2.58) > -0.51)
                  _basic++;
               else if (((_sumVal - 8) / 2.58) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 6.7 ? 'Beginner' : (_postAve >= 6.71 && _postAve <= 10.6 ? 'Basic' : (_postAve >= 10.61 && _postAve <= 13 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 6.7 ? 'Beginner' : (_preAve >= 6.71 && _preAve <= 10.6 ? 'Basic' : (_preAve >= 10.61 && _preAve <= 13 ? 'Competent' : ''));
      }

      _earthandspace = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Earth and Space (end)

      _total = {
         vn: pre_data.length,
         prem: _matter.prem + _livingthings.prem + _forceandmotion.prem + _earthandspace.prem,
         postm: _matter.postm + _livingthings.postm + _forceandmotion.postm + _earthandspace.postm,
         min: _matter.min + _livingthings.min + _forceandmotion.min + _earthandspace.min,
         max: _matter.max + _livingthings.max + _forceandmotion.max + _earthandspace.max,
         sd: _matter.sd + _livingthings.sd + _forceandmotion.sd + _earthandspace.sd,
         proficiency: _proficiency,
         beginner: _matter.beginner + _livingthings.beginner + _forceandmotion.beginner + _earthandspace.beginner,
         basic: _matter.basic + _livingthings.basic + _forceandmotion.basic + _earthandspace.basic,
         competent: _matter.competent + _livingthings.competent + _forceandmotion.competent + _earthandspace.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 27.9 ? 'Beginner' : (_total.postm >= 27.91 && _total.postm <= 38 ? 'Basic' : (_total.postm >= 38.01 && _total.postm <= 65 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 27.9 ? 'Beginner' : (_total.prem >= 27.91 && _total.prem <= 38 ? 'Basic' : (_total.prem >= 38.01 && _total.prem <= 65 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setScienceTotal(_total);
      setMatter(_matter);
      setLivingThings(_livingthings);
      setForceAndMotion(_forceandmotion);
      setEarthAndSpace(_earthandspace);
   }

   const generateMeanLevelRow = (label, data, istotal) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               {
                  istotal
                     ?
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
                     :
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; {label}</p>
               }

            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.vn}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{(data.prem).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.postm < 0 ? '' : (data.postm).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.min}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.max}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{(data.sd).toFixed(2)}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.proficiency}</p>
            </td>
         </tr>
      );
   }

   const generateFrequencyRow = (label, data, index) => {
      return (
         <>
            <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>{index}. Frequency of Proficiency Levels in {label}</strong></p>
            <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
               <tbody>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Count</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent</span></strong></p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Beginner</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.beginner}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'beginner')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Basic&nbsp;</strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.basic}</p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'basic')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Competent&nbsp;</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.competent}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'competent')}</p>
                     </td>
                  </tr>
               </tbody>
            </table>
         </>
      );
   }

   const generatePercentCorrectRow = (label, predata, postdata, index) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>{index}</strong></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>{label}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{predata !== null ? predata.toFixed(2) + '%' : ''}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}>{postdata !== null ? postdata.toFixed(2) + '%' : ''}</p>
            </td>
         </tr>
      );
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               (reportData !== null && reportData.length > 0)
                  ?
                  <div className="dashboard-item-inner3" style={{ padding: '7px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='summary-report'>
                        <div>

                           <IntroSum
                              grade_level={props.template}
                              section={props.section_desc}
                              school_name={reportData[0].school_name}
                              subject_id={props.subject_id}
                              subject={props.subject}
                              backgroundColor="#000000"
                              diagnostic_date={reportData[0].diagnostic_date}
                              diagnostic_type={reportData[0].diagnostic_type}
                           />

                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>SUMMARY OF RESULTS FOR {props.template}</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>MEAN LEVEL OF SCORES IN THE SUBJECT AREAS</strong></p>
                           <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                              <tbody>
                                 <tr>
                                    <td width="26%" nowrap style={{ width: '26.9%', borderTop: 'solid black 1.0pt', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }} />
                                    <td width="5%" nowrap style={{ width: '5.26%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>N</span></b></p>
                                    </td>
                                    <td width="15%" nowrap style={{ width: '15.56%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>Pretest Mean</span></b></p>
                                    </td>
                                    <td width="16%" style={{ width: '16.64%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>Post Test Mean</span></b></p>
                                    </td>
                                    <td width="6%" nowrap style={{ width: '6.22%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>Min</span></b></p>
                                    </td>
                                    <td width="6%" nowrap style={{ width: '6.62%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>Max</span></b></p>
                                    </td>
                                    <td width="4%" nowrap style={{ width: '4.92%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>SD</span></b></p>
                                    </td>
                                    <td width="17%" style={{ width: '17.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>Proficiency Level</span></b></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Reading Comprehension', readingComprehension, false)}
                                       {generateMeanLevelRow('Viewing Comprehension', viewingComprehension, false)}
                                       {generateMeanLevelRow('Vocabulary', vocabulary, false)}
                                       {generateMeanLevelRow('Literature', literature, false)}
                                       {generateMeanLevelRow('Grammar', grammar, false)}
                                       {generateMeanLevelRow('ENGLISH', englishTotal, true)}
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Algebra', patternsAndAlgebra, false)}
                                       {generateMeanLevelRow('Geometry', geometry, false)}
                                       {generateMeanLevelRow('Statistics and Probability', statisticsAndProbability, false)}
                                       {generateMeanLevelRow('MATH', mathTotal, true)}
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Force and Motion', forceAndMotion, false)}
                                       {generateMeanLevelRow('Matter', matter, false)}
                                       {generateMeanLevelRow('Living Things and their Environment', livingThings, false)}
                                       {generateMeanLevelRow('Earth and Space', earthAndSpace, false)}
                                       {generateMeanLevelRow('SCIENCE', scienceTotal, true)}
                                    </>
                                 }
                              </tbody>
                           </table>
                           <p style={{ marginBottom: '0in' }}><span lang="EN-US">&nbsp;</span></p>
                           <p style={{ marginBottom: '0in', textAlign: 'justify' }}><a name="_Hlk139734768"><b><span lang="EN-US">INTERPRETATION</span></b></a></p>
                           <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                              <tbody>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td width="35%" nowrap style={{ width: '35.32%', borderTop: 'solid black 1.0pt', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="17%" nowrap colSpan={3} style={{ width: '17.14%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>BEGINNER</span></b></p>
                                    </td>
                                    <td width="3%" nowrap style={{ width: '3.12%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="21%" nowrap colSpan={3} style={{ width: '21.06%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>BASIC</span></b></p>
                                    </td>
                                    <td width="3%" nowrap style={{ width: '3.12%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="20%" nowrap colSpan={3} style={{ width: '20.26%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>COMPETENT</span></b></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp; Reading Comprehension</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>6.29</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>6.3</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>8.41</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>8.42</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>13</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp; Viewing Comprehension</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0.76</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0.77</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>2.21</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>2.22</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>3</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp; Vocabulary</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>7.51</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>7.52</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>14.97</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>14.98</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>19</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp; Literature</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>3.45</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>3.46</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>6.58</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>6.59</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>9</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp; Grammar</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>9.77</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>9.78</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>13.45</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>13.46</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>16</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'black' }}>ENGLISH</span></b></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>25.8</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>25.81</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>40.56</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>40.57</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>60</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="100%" nowrap colSpan={12} style={{ width: '100.0%', border: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp; Algebra</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>18.5</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>18.6</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>21.4</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>21.43</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>37</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp; Geometry</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>4.99</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>5</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>7.25</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>7.26</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>12</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp; Statistics and Probability</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>3.2</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>3.21</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>4.08</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>4.09</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>6</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'black' }}>MATH</span></b></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>25</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>25.1</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>30.2</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>30.16</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>55</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="100%" nowrap colSpan={12} style={{ width: '100.0%', borderTop: 'none', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp; Force and Motion</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>13.9</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>13.91</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>19.4</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>19.45</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>27</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp; Matter</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>2.85</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>2.86</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>4.79</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>4.8</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>6</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp; Living Things</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>9.39</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>9.4</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>11.2</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>11.21</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>19</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp; Earth and Space</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>6.7</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>6.71</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>10.6</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>10.59</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>13</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.32%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'black' }}>SCIENCE</span></b></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.6%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>27.9</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>27.91</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>38</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>38.01</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.18%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US" style={{ color: 'black' }}>65</span></p>
                                          </td>
                                       </tr>
                                    </>
                                 }
                              </tbody>
                           </table>

                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><span lang="EN-US">&nbsp;</span></p>
                                 <p style={{ marginBottom: '0in' }}><span lang="EN-US">&nbsp;</span></p>
                                 {generateFrequencyRow('Reading Comprehension', readingComprehension, 1)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Viewing Comprehension', viewingComprehension, 2)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Vocabulary', vocabulary, 3)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Literature', literature, 4)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Grammar', grammar, 5)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('ENGLISH', englishTotal, 6)}
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Algebra', patternsAndAlgebra, 7)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Geometry', geometry, 8)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Statistics and Probability', statisticsAndProbability, 9)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('MATH', mathTotal, 10)}
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Force and Motion', forceAndMotion, 11)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Matter', matter, 12)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Living Things and their Environment', livingThings, 13)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Earth and Space', earthAndSpace, 14)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('SCIENCE', scienceTotal, 15)}
                              </>
                           }

                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><span lang="EN-US">&nbsp;</span></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percent Correct in English</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody>
                                       <tr>
                                          <td width="64%" colSpan={2} rowSpan={2} style={{ width: '64.58%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.72%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>PRETEST</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.7%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>POST TEST</span></b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="17%" style={{ width: '17.72%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US">Percent Correct for the Level</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.7%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US">Percent Correct for the Level</span></b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="59%" style={{ width: '59.98%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Reading Comprehension</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.7%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Read intensively to determine the author’s purpose.', englishPCData.pre_s1, englishPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Differentiate facts from opinions.', englishPCData.pre_s2, englishPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Differentiate facts from opinions.', englishPCData.pre_s3, englishPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Differentiate facts from opinions.', englishPCData.pre_s4, englishPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Evaluate the details that support assertions in a text.', englishPCData.pre_s5, englishPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Examine biases (for or against) made by the author.', englishPCData.pre_s6, englishPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Identify positions of a topic sentence.', englishPCData.pre_s7, englishPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Identify positions of a topic sentence.', englishPCData.pre_s8, englishPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Identify details that support the topic sentence.', englishPCData.pre_s9, englishPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Distinguish between general and specific statements.', englishPCData.pre_s10, englishPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Distinguish between general and specific statements.', englishPCData.pre_s11, englishPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Distinguish between general and specific statements.', englishPCData.pre_s12, englishPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Distinguish between general and specific statements.', englishPCData.pre_s13, englishPCData.post_s13, 13)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="59%" style={{ width: '59.98%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Viewing Comprehension</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.7%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Determine the issue and stand presented in the material viewed.', englishPCData.pre_s14, englishPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Judge the relevance and worth of ideas presented in the material viewed.', englishPCData.pre_s15, englishPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Analyze the elements that make up reality and fantasy based on a material viewed.', englishPCData.pre_s16, englishPCData.post_s16, 16)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="59%" style={{ width: '59.98%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Vocabulary Development</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.7%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Determine the meaning of idiomatic expressions by noting context clues and collocations.', englishPCData.pre_s17, englishPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Determine the meaning of idiomatic expressions by noting context clues and collocations.', englishPCData.pre_s18, englishPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Determine the meaning of idiomatic expressions by noting context clues and collocations.', englishPCData.pre_s19, englishPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Distinguish between and among verbal, situational, and dramatic types of irony and give examples of each.', englishPCData.pre_s20, englishPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Distinguish between and among verbal, situational, and dramatic types of irony and give examples of each.', englishPCData.pre_s21, englishPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Distinguish between and among verbal, situational, and dramatic types of irony and give examples of each.', englishPCData.pre_s22, englishPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Discriminate between literal and figurative language.', englishPCData.pre_s23, englishPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Discriminate between literal and figurative language.', englishPCData.pre_s24, englishPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Identify figures of speech that show emphasis (hyperbole and litotes).', englishPCData.pre_s25, englishPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Identify figures of speech that show emphasis (hyperbole and litotes).', englishPCData.pre_s26, englishPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Identify figures of speech that show emphasis (hyperbole and litotes).', englishPCData.pre_s27, englishPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Identify figures of speech that show emphasis (hyperbole and litotes).', englishPCData.pre_s28, englishPCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Explain the meaning of a word through structural analysis (prefixes, roots, suffixes).', englishPCData.pre_s29, englishPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Explain the meaning of a word through structural analysis (prefixes, roots, suffixes).', englishPCData.pre_s30, englishPCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Explain the meaning of a word through structural analysis (prefixes, roots, suffixes).', englishPCData.pre_s31, englishPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Arrive at meanings through context clues.', englishPCData.pre_s32, englishPCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Arrive at meanings through context clues.', englishPCData.pre_s33, englishPCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Arrive at meanings through context clues.', englishPCData.pre_s34, englishPCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Arrive at meanings through context clues.', englishPCData.pre_s35, englishPCData.post_s35, 35)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="59%" style={{ width: '59.98%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Literature</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.7%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Explain the literary devices used.', englishPCData.pre_s36, englishPCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Determine tone, mood, technique, and purpose of the author.', englishPCData.pre_s37, englishPCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Determine tone, mood, technique, and purpose of the author.', englishPCData.pre_s38, englishPCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Use conventions in citing sources.', englishPCData.pre_s39, englishPCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Use conventions in citing sources.', englishPCData.pre_s40, englishPCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Identify features of journalistic writing.', englishPCData.pre_s41, englishPCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Identify features of journalistic writing.', englishPCData.pre_s42, englishPCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Distinguish among types of journalistic writing (news report, opinion article, feature article, and sports news article).', englishPCData.pre_s43, englishPCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Distinguish among types of journalistic writing (news report, opinion article, feature article, and sports news article).', englishPCData.pre_s44, englishPCData.post_s44, 44)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="59%" style={{ width: '59.98%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Grammar Awareness</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.7%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Share ideas using opinion-marking signals.', englishPCData.pre_s45, englishPCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Use modals appropriately.', englishPCData.pre_s46, englishPCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Use modals appropriately.', englishPCData.pre_s47, englishPCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Use modals appropriately.', englishPCData.pre_s48, englishPCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Use modals appropriately.', englishPCData.pre_s49, englishPCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Use appropriate modifiers.', englishPCData.pre_s50, englishPCData.post_s50, 50)}
                                       {generatePercentCorrectRow('Use appropriate modifiers.', englishPCData.pre_s51, englishPCData.post_s51, 51)}
                                       {generatePercentCorrectRow('Use appropriate modifiers.', englishPCData.pre_s52, englishPCData.post_s52, 52)}
                                       {generatePercentCorrectRow('Use appropriate modifiers.', englishPCData.pre_s53, englishPCData.post_s53, 53)}
                                       {generatePercentCorrectRow('Use active and passive constructions in journalistic contexts.', englishPCData.pre_s54, englishPCData.post_s54, 54)}
                                       {generatePercentCorrectRow('Use active and passive constructions in journalistic contexts.', englishPCData.pre_s55, englishPCData.post_s55, 55)}
                                       {generatePercentCorrectRow('Use past and past perfect tenses in journalistic writing.', englishPCData.pre_s56, englishPCData.post_s56, 56)}
                                       {generatePercentCorrectRow('Use past and past perfect tenses in journalistic writing.', englishPCData.pre_s57, englishPCData.post_s57, 57)}
                                       {generatePercentCorrectRow('Use past and past perfect tenses in journalistic writing.', englishPCData.pre_s58, englishPCData.post_s58, 58)}
                                       {generatePercentCorrectRow('Use past and past perfect tenses in journalistic writing.', englishPCData.pre_s59, englishPCData.post_s59, 59)}
                                       {generatePercentCorrectRow('Use past and past perfect tenses in journalistic writing.', englishPCData.pre_s60, englishPCData.post_s60, 60)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percent Correct in Math</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody><tr>
                                       <td width="66%" colSpan={2} rowSpan={2} style={{ width: '66.4%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                       </td>
                                       <td width="17%" style={{ width: '17.06%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>PRETEST</span></b></p>
                                       </td>
                                       <td width="16%" style={{ width: '16.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>POST TEST</span></b></p>
                                       </td>
                                    </tr>
                                       <tr>
                                          <td width="17%" style={{ width: '17.06%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US">Percent Correct for the Level</span></b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.54%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US">Percent Correct for the Level</span></b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="61%" style={{ width: '61.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Patterns and Algebra</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.06%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.54%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Factors completely different types of polynomials.', mathPCData.pre_s1, mathPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Factors completely different types of polynomials.', mathPCData.pre_s2, mathPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Solves problems involving factors of polynomials.', mathPCData.pre_s3, mathPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Solves problems involving factors of polynomials.', mathPCData.pre_s4, mathPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Simplifies rational algebraic expressions.', mathPCData.pre_s5, mathPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Simplifies rational algebraic expressions.', mathPCData.pre_s6, mathPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Performs operations on rational algebraic expressions.', mathPCData.pre_s7, mathPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Performs operations on rational algebraic expressions.', mathPCData.pre_s8, mathPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Solves problems involving rational algebraic expressions.', mathPCData.pre_s9, mathPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Solves problems involving rational algebraic expressions.', mathPCData.pre_s10, mathPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Finds the slope of a line given two points, equation, and graph.', mathPCData.pre_s11, mathPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Finds the slope of a line given two points, equation, and graph.', mathPCData.pre_s12, mathPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Writes the linear equation ax + by = c in the form y + mx = b and vice versa', mathPCData.pre_s13, mathPCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Writes the linear equation ax + by = c in the form y + mx = b and vice versa', mathPCData.pre_s14, mathPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Finds the equation of a line given (a) two points; (b) the slope and a point; (c) the slope and its intercepts.', mathPCData.pre_s15, mathPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Finds the equation of a line given (a) two points; (b) the slope and a point; (c) the slope and its intercepts.', mathPCData.pre_s16, mathPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Solves problems involving linear equations in two variables.', mathPCData.pre_s17, mathPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Solves problems involving linear equations in two variables.', mathPCData.pre_s18, mathPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Categorizes when a given system of linear equations in two variables has graphs that are parallel, intersecting, and coinciding.', mathPCData.pre_s19, mathPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Categorizes when a given system of linear equations in two variables has graphs that are parallel, intersecting, and coinciding.', mathPCData.pre_s20, mathPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Solves problems involving systems of linear equations in two variables.', mathPCData.pre_s21, mathPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Solves problems involving systems of linear equations in two variables.', mathPCData.pre_s22, mathPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Differentiates linear inequalities in two variables from linear equations in two variables.', mathPCData.pre_s23, mathPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Differentiates linear inequalities in two variables from linear equations in two variables.', mathPCData.pre_s24, mathPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Solves problems involving linear inequalities in two variables.', mathPCData.pre_s25, mathPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Solves problems involving linear inequalities in two variables.', mathPCData.pre_s26, mathPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Solves a system of linear inequalities in two variables.', mathPCData.pre_s27, mathPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Solves a system of linear inequalities in two variables.', mathPCData.pre_s28, mathPCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Solves problems involving systems of linear inequalities in two variables.', mathPCData.pre_s29, mathPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Verifies if a given relation is a function.', mathPCData.pre_s30, mathPCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Verifies if a given relation is a function.', mathPCData.pre_s31, mathPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Determines dependent and independent variables.', mathPCData.pre_s32, mathPCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Determines dependent and independent variables.', mathPCData.pre_s33, mathPCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Finds the domain and range of a function.', mathPCData.pre_s34, mathPCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Finds the domain and range of a function.', mathPCData.pre_s35, mathPCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Solves problems involving linear functions.', mathPCData.pre_s36, mathPCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Solves problems involving linear functions.', mathPCData.pre_s37, mathPCData.post_s37, 37)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="61%" style={{ width: '61.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Geometry</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.06%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.54%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Transforms a statement into an equivalent if-then statement.', mathPCData.pre_s38, mathPCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Transforms a statement into an equivalent if-then statement.', mathPCData.pre_s39, mathPCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Determines the inverse, converse, and contrapositive of an if-then statement.', mathPCData.pre_s40, mathPCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Determines the inverse, converse, and contrapositive of an if-then statement.', mathPCData.pre_s41, mathPCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Applies theorems on triangle inequalities.', mathPCData.pre_s42, mathPCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Applies theorems on triangle inequalities.', mathPCData.pre_s43, mathPCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Proves inequalities in a triangle.', mathPCData.pre_s44, mathPCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Proves inequalities in a triangle.', mathPCData.pre_s45, mathPCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Proves properties of parallel lines cut by a transversal.', mathPCData.pre_s46, mathPCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Counts the number of occurrences of an outcome in an experiment: (a) table; (b) tree diagram; (c) systematic listing; and (d) fundamental counting principle.', mathPCData.pre_s47, mathPCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Counts the number of occurrences of an outcome in an experiment: (a) table; (b) tree diagram; (c) systematic listing; and (d) fundamental counting principle.', mathPCData.pre_s48, mathPCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Finds the probability of a simple event.', mathPCData.pre_s49, mathPCData.post_s49, 49)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="61%" style={{ width: '61.8%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Statistics and Probability</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.06%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.54%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Finds the probability of a simple event.', mathPCData.pre_s50, mathPCData.post_s50, 50)}
                                       {generatePercentCorrectRow('Finds the probability of a simple event.', mathPCData.pre_s51, mathPCData.post_s51, 51)}
                                       {generatePercentCorrectRow('Finds the probability of a simple event.', mathPCData.pre_s52, mathPCData.post_s52, 52)}
                                       {generatePercentCorrectRow('Finds the probability of a simple event.', mathPCData.pre_s53, mathPCData.post_s53, 53)}
                                       {generatePercentCorrectRow('Solves problems involving probabilities of simple events.', mathPCData.pre_s54, mathPCData.post_s54, 54)}
                                       {generatePercentCorrectRow('Solves problems involving probabilities of simple events.', mathPCData.pre_s55, mathPCData.post_s55, 55)}

                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percent Correct in Science</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody><tr>
                                       <td width="71%" colSpan={2} rowSpan={2} style={{ width: '71.2%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>LEARNING
                                             COMPETENCY</span></b></p>
                                       </td>
                                       <td width="14%" style={{ width: '14.7%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>PRETEST</span></b></p>
                                       </td>
                                       <td width="14%" style={{ width: '14.1%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US" style={{ color: 'white' }}>POST TEST</span></b></p>
                                       </td>
                                    </tr>
                                       <tr>
                                          <td width="14%" style={{ width: '14.7%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US">Percent Correct for the Level</span></b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.1%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span lang="EN-US">Percent Correct for the Level</span></b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="66%" style={{ width: '66.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Force and Motion</span></b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.7%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Investigate the relationship between the amount of force applied and the mass of the object to the amount of force applied and the mass of the object to the amount of force applied and the mass of the object to the amount of change in the objects motion.', sciencePCData.pre_s1, sciencePCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Investigate the relationship between the amount of force applied and the mass of the object to the amount of force applied and the mass of the object to the amount of force applied and the mass of the object to the amount of change in the objects motion.', sciencePCData.pre_s2, sciencePCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Infer that when a body exerts a force on another an equal amount of force is exerted back on it.', sciencePCData.pre_s3, sciencePCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Infer that when a body exerts a force on another an equal amount of force is exerted back on it.', sciencePCData.pre_s4, sciencePCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Demonstrate how a body responds to changes in motion.', sciencePCData.pre_s5, sciencePCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Demonstrate how a body responds to changes in motion.', sciencePCData.pre_s6, sciencePCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Relate the laws of motion to bodies in uniform circular motion.', sciencePCData.pre_s7, sciencePCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Relate the laws of motion to bodies in uniform circular motion.', sciencePCData.pre_s8, sciencePCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Infer the circular motion requires the application of constant force directed toward the center of the circle.', sciencePCData.pre_s9, sciencePCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Describe how work is related to power and energy.', sciencePCData.pre_s10, sciencePCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Relate speed and position of object to the amount of energy possessed by a body.', sciencePCData.pre_s11, sciencePCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Infer how the movement of particles of an object affects the speed of sound.', sciencePCData.pre_s12, sciencePCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Infer how the movement of particles of an object affects the speed of sound.', sciencePCData.pre_s13, sciencePCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Investigate the effect of temperature to speed of sound through fair testing.', sciencePCData.pre_s14, sciencePCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Investigate the effect of temperature to speed of sound through fair testing.', sciencePCData.pre_s15, sciencePCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Demonstrate the existence of the color components of visible light using a prism or diffraction grating.', sciencePCData.pre_s16, sciencePCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Explain the hierarchy of colors in relation to energy.', sciencePCData.pre_s17, sciencePCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Explain the hierarchy of colors in relation to energy.', sciencePCData.pre_s18, sciencePCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Differentiate between heat and temperature at the molecular level.', sciencePCData.pre_s19, sciencePCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Differentiate between heat and temperature at the molecular level.', sciencePCData.pre_s20, sciencePCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Infer the relationship between current and charge.', sciencePCData.pre_s21, sciencePCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Explain the advantages and disadvantages of series and parallel connections in homes.', sciencePCData.pre_s22, sciencePCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Explain the advantages and disadvantages of series and parallel connections in homes.', sciencePCData.pre_s23, sciencePCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Differentiate electrical power and electrical energy.', sciencePCData.pre_s24, sciencePCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Differentiate electrical power and electrical energy.', sciencePCData.pre_s25, sciencePCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Explain the function of circuit breakers, fuses, earthing, double insulation, and other safety devices in the home.', sciencePCData.pre_s26, sciencePCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Explain the function of circuit breakers, fuses, earthing, double insulation, and other safety devices in the home.', sciencePCData.pre_s27, sciencePCData.post_s27, 27)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="66%" style={{ width: '66.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Earth and Space</span></b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.7%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Using models or illustrations, explain how movements along faults generate earthquakes.', sciencePCData.pre_s28, sciencePCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Using models or illustrations, explain how movements along faults generate earthquakes.', sciencePCData.pre_s29, sciencePCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Explain the properties of solids, liquids, and gases based on the particle nature of matter.', sciencePCData.pre_s30, sciencePCData.post_s30, 30)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="66%" style={{ width: '66.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Matter</span></b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.7%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Explain physical changes in terms of the arrangement and motion of atoms and molecules.', sciencePCData.pre_s31, sciencePCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Explain physical changes in terms of the arrangement and motion of atoms and molecules.', sciencePCData.pre_s32, sciencePCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Determine the number of protons, neutrons, and electrons in a particular atom.', sciencePCData.pre_s33, sciencePCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Trace the development of the periodic table from observations based on similarities in properties of elements.', sciencePCData.pre_s34, sciencePCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Use the periodic table to predict the chemical behaviour of an element.', sciencePCData.pre_s35, sciencePCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Use the periodic table to predict the chemical behaviour of an element.', sciencePCData.pre_s36, sciencePCData.post_s36, 36)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="66%" style={{ width: '66.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Living Things</span></b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.7%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Explain ingestion, absorption, assimilation, and excretion.', sciencePCData.pre_s37, sciencePCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Explain ingestion, absorption, assimilation, and excretion.', sciencePCData.pre_s38, sciencePCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Explain how diseases of the digestive system are prevented, detected, and treated.', sciencePCData.pre_s39, sciencePCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Explain how diseases of the digestive system are prevented, detected, and treated.', sciencePCData.pre_s40, sciencePCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Healthful practices that affect the digestive system.', sciencePCData.pre_s41, sciencePCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Healthful practices that affect the digestive system.', sciencePCData.pre_s42, sciencePCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Compare mitosis and meiosis, and their role in the cell-division cycle.', sciencePCData.pre_s43, sciencePCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Compare mitosis and meiosis, and their role in the cell-division cycle.', sciencePCData.pre_s44, sciencePCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Explain the significance of meiosis in maintaining the chromosome number.', sciencePCData.pre_s45, sciencePCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Describe the transfer of energy through the trophic levels.', sciencePCData.pre_s46, sciencePCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Analyze the roles of organisms in the cycling of materials.', sciencePCData.pre_s47, sciencePCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Explain how materials cycle in an ecosystem.', sciencePCData.pre_s48, sciencePCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Explain how materials cycle in an ecosystem.', sciencePCData.pre_s49, sciencePCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Suggest ways to minimize human impact on the environment.', sciencePCData.pre_s50, sciencePCData.post_s50, 50)}
                                       {generatePercentCorrectRow('Suggest ways to minimize human impact on the environment.', sciencePCData.pre_s51, sciencePCData.post_s51, 51)}
                                       {generatePercentCorrectRow('Explain the concept of a species.', sciencePCData.pre_s52, sciencePCData.post_s52, 52)}
                                       {generatePercentCorrectRow('Predict penotypic expressions of traits following simple patterns of inheritance.', sciencePCData.pre_s53, sciencePCData.post_s53, 53)}
                                       {generatePercentCorrectRow('Predict penotypic expressions of traits following simple patterns of inheritance.', sciencePCData.pre_s54, sciencePCData.post_s54, 54)}
                                       {generatePercentCorrectRow('Explain the advantage of high biodiversity in maintaining the stability of an ecosystem.', sciencePCData.pre_s55, sciencePCData.post_s55, 55)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                          </td>
                                          <td width="66%" style={{ width: '66.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span lang="EN-US">Earth and Space</span></b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.7%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-US">&nbsp;</span></p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Demonstrate how underwater earthquakes generate tsunamis.', sciencePCData.pre_s56, sciencePCData.post_s56, 56)}
                                       {generatePercentCorrectRow('Demonstrate how underwater earthquakes generate tsunamis.', sciencePCData.pre_s57, sciencePCData.post_s57, 57)}
                                       {generatePercentCorrectRow('Explain how earthquake waves provide information about the interior of the earth.', sciencePCData.pre_s58, sciencePCData.post_s58, 58)}
                                       {generatePercentCorrectRow('Explain how earthquake waves provide information about the interior of the earth.', sciencePCData.pre_s59, sciencePCData.post_s59, 59)}
                                       {generatePercentCorrectRow('Explain how typhoons develop.', sciencePCData.pre_s60, sciencePCData.post_s60, 60)}
                                       {generatePercentCorrectRow('Explain how typhoons develop.', sciencePCData.pre_s61, sciencePCData.post_s61, 61)}
                                       {generatePercentCorrectRow('Infer why the Philippines is prone to typhoons.', sciencePCData.pre_s62, sciencePCData.post_s62, 62)}
                                       {generatePercentCorrectRow('Explain how landmasses and bodies of water affect typhoons.', sciencePCData.pre_s63, sciencePCData.post_s63, 63)}
                                       {generatePercentCorrectRow('Explain how landmasses and bodies of water affect typhoons.', sciencePCData.pre_s64, sciencePCData.post_s64, 64)}
                                       {generatePercentCorrectRow('Compare and contrast comets, meteors, and asteroids.', sciencePCData.pre_s65, sciencePCData.post_s65, 65)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                        </div>
                     </div>
                  </div >
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }

      </>
   );
}