export const tabItems = [
  {
    icon: 'ai',
    label: 'BraineeBot',
    name: 'BraineeBot',
  },
  {
    icon: 'web',
    label: 'Web Search',
    name: 'web',
  },    
  {
    icon: 'videos',
    label: 'videos',
    name: 'videos',
  },  
  {
    icon: 'images',
    label: 'images',
    name: 'images',
  },  
  {
    icon: 'texts',
    label: 'text',
    name: 'text',
  },  
  {
    icon: 'myfiles',
    label: 'My Files',
    name: 'My Files',
  },    
  {
    icon: 'thirdparty',
    label: 'Digital tools',
    name: 'Digital tools',
  },   
  {
    icon: 'background',
    label: 'background',
    name: 'background',
  },  
  {
    icon: 'vector',
    label: 'vector',
    name: 'vector',
  },     
  {
    icon: 'objects',
    label: 'objects',
    name: 'objects',
  },     
]
