import { LessonModel } from "../models/LessonModel";

var BlankLesson = {
  lessonid: "",
  title: "",
  techearName: "",
  subject: "", 
  overview :"",   
  term: "",
  availabilitydate: [],
  display_availabilitydate: "",
  educationallevel: "",
  studentviewing: "",
  lessonsharing: "",
  gradelevel: "",
  sectionlist: [],
  assigned_students: "",
  selected_studetns:"",
  filteredsection: [
  { 
    code: '000',
    label: 'NO RECORD',
    value: 'NORECORD' 
  }
],
  learningtype: "",
  learningmode: "",
  conference: "",
  conferencelink: "",
  classroomid: "",
  learningplan: "",
  type: "",
  sections: [],
};

var EmptyLesson = {
  lessonid: "",
  title: "",
  techearName: "",
  subject: "", 
  overview :"",   
  term: "",
  availabilitydate: [],
  display_availabilitydate: "",
  educationallevel: "",
  studentviewing: "notallowed",
  lessonsharing: "unshared",
  gradelevel: "",
  sectionlist: [],
  assigned_students: "",
  selected_studetns:"",
  filteredsection: [
  { 
    code: '000',
    label: 'NO RECORD',
    value: 'NORECORD' 
  }
],
  learningtype: "",
  learningmode: "",
  conference: "",
  conferencelink: "",
  classroomid: "",
  learningplan: "",
  type: "",
  sections: [],
};

const update_lesson_data = (state, payload) =>
{
  const tmpLessonState = state;
  
  return {...tmpLessonState,[payload.key]: payload.value};
  
}

const update_lesson_section = (state, payload) =>
{
  const tmpLessonState = state;
  
  return {...tmpLessonState,[payload.key]: {...payload.value}};
  
}


const add_lesson_item = (state, payload) =>
{
   const tmpLessonState = state;
   var newObject = [];
   var tmpObject = [];
    if (payload.Type === "TOPIC")
    {
      tmpObject  = [...tmpLessonState.sections];
      newObject = [...tmpObject, payload.title] 
      tmpLessonState.sections = newObject;
    }
    if (payload.Type === "LESSON")
    {
      tmpObject  = [...tmpLessonState.sections[payload.TopicIndex].contents];   
      newObject = [...tmpObject, payload.Item];
      tmpLessonState.sections[payload.TopicIndex].contents = newObject;
    }
    if (payload.Type === "LINKS")
    {
      tmpObject  = [...[...tmpLessonState.sections[payload.TopicIndex].contents].filter(item => item.id === payload.LessonIndex)[0].links];
      newObject = [...tmpObject, payload.Item];
      // Check content type
      var contentType = "";
      newObject.map((item) => {
         if (contentType === item.type || contentType === "")
             contentType = item.type;
         else
             contentType = "mixed"   
      });
      [...tmpLessonState.sections[payload.TopicIndex].contents].filter(item => item.id === payload.LessonIndex)[0].type = contentType;
      [...tmpLessonState.sections[payload.TopicIndex].contents].filter(item => item.id === payload.LessonIndex)[0].links = newObject;
    }   
  return tmpLessonState;
}

const edit_lesson_title = (state, payload) =>
{
   const tmpLessonState = state;
   var tmpObject = [];
    if (payload.Type === "TOPIC")
    {
     tmpLessonState.sections[payload.TopicIndex].title = payload.title;
    }
    if (payload.Type === "LESSON")
    {
      tmpObject  = [...tmpLessonState.sections[payload.TopicIndex].contents];  
      tmpObject.filter(item => item.id === payload.LessonIndex)[0].title = payload.title; 
      tmpLessonState.sections[payload.TopicIndex].contents = tmpObject;
    }        

  return tmpLessonState;
}

// const add_lesson_item = (state, payload) =>
// {
//    const tmpLessonState = state;
//    var newObject = [];
//    var tmpObject = [];
//     if (payload.Type === "TOPIC")
//     {
//       tmpObject  = [...tmpLessonState.sections];
//       newObject = [...tmpObject, payload.title] 
//       tmpLessonState.sections = newObject;
//     }
//     if (payload.Type === "LESSON")
//     {
//       tmpObject  = [...tmpLessonState.sections[payload.TopicIndex].contents];   
//       newObject = [...tmpObject, payload.Item];
//       tmpLessonState.sections[payload.TopicIndex].contents = newObject;
//     }
//     if (payload.Type === "LINKS")
//     {
//       tmpObject  = [...[...tmpLessonState.sections[payload.TopicIndex].contents].filter(item => item.id === payload.LessonIndex)[0].links];
//       newObject = [...tmpObject, payload.Item];
//       // Check content type
//       var contentType = "";
//       newObject.map((item) => {
//          if (contentType === item.type || contentType === "")
//              contentType = item.type;
//          else
//              contentType = "mixed"   
//       });
//       [...tmpLessonState.sections[payload.TopicIndex].contents].filter(item => item.id === payload.LessonIndex)[0].type = contentType;
//       [...tmpLessonState.sections[payload.TopicIndex].contents].filter(item => item.id === payload.LessonIndex)[0].links = newObject;
//     }            




//   return tmpLessonState;
// }

const add_lesson_section = (state, payload) =>
{
  // payload -> {sections} {content} {link}
   const tmpLessonState = state;
   var newObject = [];
   var tmpObject = [];
   var sectionObject = [];
   var sectionContainer = [];
   var contentObject = [];
   var contentContainer = [];
   var linkContainer = [];

   let tempDataSections = tmpLessonState.sections;   
   let sectioncount = tempDataSections.length;   
   
   if (sectioncount===-1 || sectioncount === 0) {       

      sectionObject = payload.sections;
      contentObject = payload.content;
      linkContainer.push(payload.link);
      contentContainer = {...contentObject, links: linkContainer};
      sectionContainer = {...sectionObject, contents:[contentContainer]};
      tempDataSections.push(sectionContainer);
    //  const linkcontent = contentrecord.links;
    //  linkcontent.push(links);
    
    //  const newsection={...tempsection,contents: contentrecord}
    //  let sectionlist = [];
    //  sectionlist.push(newsection);
    //  setTsectionarray(sectionlist)
    //  setSectionrecord({...sectionrecord,contents: contentrecord})
    //  var params = {key: `sections` ,value : sectionlist}
    //  dispatch(update_lesson_data(params))
   }
   else {
       // alert('Section is not empty anymore')
       let titletopic = payload.sections.title;
       let itemSection = tempDataSections.filter(item => item.title === titletopic);
       // get index where title is located
       let index =  tempDataSections.findIndex(item => item.title === titletopic);
      //  console.log(`Index: ${index}`)
       if (index>0) {
        //  console.log(`topic was found -- ${titletopic}`)
         let link1 = [...itemSection[0].contents[0].links];
         let plink = link1;
         plink.push(payload.link)
     
         tempDataSections[index].contents[0].links = plink;
        //  var params = {key: `sections` ,value : tempDataSections}
        //  dispatch(update_lesson_data(params))            
       }
       else {
        //  console.log('topic was not found')
         
         sectionObject = payload.sections;
         contentObject = payload.content;
         linkContainer.push(payload.link);

        contentContainer = {...contentObject, links: linkContainer};
        sectionContainer = {...sectionObject, contents:[contentContainer]};       

        tempDataSections.push(sectionContainer);
        //  setDump(tempDataSections)
        //  var params = {key: `sections` ,value : tempDataSections}
        //  dispatch(update_lesson_data(params))  
       }
   } 

   return {...tmpLessonState,sections: tempDataSections};
}


const set_lessonrec = (state, payload) =>
{
  const tmpLessonState = payload;
  
  return tmpLessonState;
  
}

const lessonrecordReducer = (state = LessonModel, action) => {
    switch(action.type){
        case 'UPDATE-LESSON-DATA':
            return update_lesson_data(state,action.payload) 
  
        case 'UPDATE-LESSON-SECTION':
            return update_lesson_section(state,action.payload)     
            
        case 'POST-LESSON-SECTION':
            return add_lesson_item(state,action.payload) 

        case 'EDIT-LESSON-TITLE':
          return edit_lesson_title(state,action.payload)

        case 'SET-LESSONREC':
            return set_lessonrec(state,action.payload);
            
        case 'INITIALIZE-LESSON':
          return BlankLesson;

        case 'RESET-LESSON':
          return EmptyLesson;          
        default:
            return state
    }
}

export default lessonrecordReducer;