import { MasteryAssessmentModel } from "../models/MasteryAssessmentModel";

var empty_mastery = {
   id: null,
   title: null,
   term: null,
   level: null,
   subject: null,
   formative_1: null,
   formative_1_file_url: null,
   formative_2: null,
   formative_2_file_url: null,
   formative_3: null,
   formative_3_file_url: null,
   summative: null,
   summative_file_url: null,
   study_plan_1: null,
   study_plan_2: null,
   mastery_level: null,
   mastery_competency: null,
   created_by: null,
   mode: null,
   subjectname: null,
   levelname: null,
   formative_1_instruction: null,
   formative_2_instruction: null,
   formative_3_instruction: null,
   // recommendation_id: null,
   // recommendation_desc: null,
   // recommendation_docs: null,
   // recommendation_doc_names: null,
}

const update_mastery_data = (state, payload) => {
   const tmpState = state;

   return { ...tmpState, [payload.key]: payload.value };

}

const set_mastery = (state, payload) => {
   const tmpState = payload;

   return tmpState;

}

const masteryAssessmentReducer = (state = MasteryAssessmentModel, action) => {
   switch (action.type) {
      case 'UPDATE-MASTERY-DATA':
         return update_mastery_data(state, action.payload);
      case 'SET-MASTERY':
         return set_mastery(state, action.payload);
      case 'RESET-MASTERY-DATA':
         return empty_mastery;
      default:
         return state
   }
}

export default masteryAssessmentReducer;