import React, { useState, useRef } from 'react';
import { Tooltip, Row, Col, List, Divider, Typography, Input } from 'antd';
import SimpleBarReact from "simplebar-react";
import DocumentViewer from '../../../components/document-viewer/DocumentViewer';
import Utils from '../../../misc/Utils';
// import { Editor } from '@tinymce/tinymce-react';
import Wysiwyg from '../../../components/wysiwyg/wysiwyg';

const { Title } = Typography;
// const { TextArea } = Input;

export default function ViewActivities(props) {
   const editorRef = useRef(null);

   const g_activity_data = props.data;

   const [currentActivityDoc, setCurrentActivityDoc] = useState(null);
   const [selectedDocumentIdx, setSelectedDocumentIdx] = useState(-1);

   const handleViewActivityDoc = (document, idx) => {
      setSelectedDocumentIdx(idx);
      setCurrentActivityDoc(document);
   }

   return (
      <>
         <Row>
            <Col xs={24} md={9} lg={9}>
               <div className='student_list_container' style={{ paddingRight: "10px" }}>
                  <Divider orientation="left">
                     <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Teacher Remarks</Title>
                  </Divider>
                  <Wysiwyg
                     // changeContentCallback={(e) => handleDescriptionChange(e)} 
                     content={g_activity_data.act_remarks}
                     height={300}
                     contentHeight={235}
                     disabled={true}
                  />
                  {/* <Editor
                     disabled={true}
                     apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                     onInit={(evt, editor) => editorRef.current = editor}
                     initialValue={g_activity_data.act_remarks}
                     init={{
                        menubar: false,
                        height: 350,
                        statusbar: false,
                        plugins: [
                           'advlist autolink lists link image charmap print preview anchor',
                           'searchreplace visualblocks code',
                           'insertdatetime media table paste code help wordcount emoticons'
                        ],
                        toolbar: false,
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                     }}
                  /> */}
                  <Divider orientation="left">
                     <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Submitted Activities</Title>
                  </Divider>
                  {
                     (g_activity_data.act_docs !== null && g_activity_data.act_docs !== '') &&
                     <SimpleBarReact style={{ height: "calc(100vh - 647px)", backgroundColor: "white", minHeight: "200px" }}>
                        <List
                           size="small"
                           dataSource={g_activity_data.act_docs}

                           renderItem={(item, idx) =>
                              <Tooltip placement="right" title={g_activity_data.act_doc_names[idx]}>
                                 <List.Item
                                    onClick={() => handleViewActivityDoc(item, idx)}
                                    className={idx === selectedDocumentIdx ? 'document-file-selected' : 'document-file'}
                                    style={{ textAlign: "left", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                                 >
                                    {Utils.GetFileIcon(g_activity_data.act_doc_names[idx])} {g_activity_data.act_doc_names[idx]}
                                 </List.Item>
                              </Tooltip>
                           }
                        />
                     </SimpleBarReact>
                  }

               </div>
            </Col>
            <Col xs={24} md={15} lg={15}>
               <div className='essay_list_container'>
                  <DocumentViewer
                     document={process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH + '/' + currentActivityDoc}
                     documentlist={g_activity_data.act_docs}
                     // pdfheight={"calc(100vh - 205px)"}
                     pdfshowdownload={false}
                     pdfshowprint={false}
                     // videoheight={"100%"}
                     ismodal={true}
                     dvheight={"calc(100vh - 185px)"}
                  />
               </div>
            </Col>
         </Row>
      </>
   );
}