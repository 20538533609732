import React, { useEffect, useRef, useState } from 'react'
import { Configuration, OpenAIApi } from 'openai';
import "./chatGPT.scss"
import { CopyOutlined, SendOutlined } from '@ant-design/icons';
import { Button, message, Image, Input, Avatar, Tooltip, Row, Col } from 'antd';
// import { setMinutes } from 'date-fns';
import SimpleBarReact from "simplebar-react";

export function ChatGPT(props) {
   const [storedValues, setStoredValues] = useState([]);
   const [newQuestion, setNewQuestion] = useState('');
   const [loading, setLoading] = useState(false);
   const [chatMessages, setMessages] = useState([{ "role": "system", "content": "You are a helpful assistant." },]);
   const [isTyping, setIsTyping] = useState(false);
   const { TextArea } = Input;
   const messagesEndRef = useRef(null);

   const copyText = (text) => {
      navigator.clipboard.writeText(text);
      const key = 'updatable';
      message.info({ content: 'Copied to clipboard!', key });
   };

   const configuration = new Configuration({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
   });

   const openai = new OpenAIApi(configuration);

   const generateResponse = async () => {
      if (newQuestion !== '' && newQuestion !== '\r' && newQuestion !== '\n') {
         setLoading(true);
         var tmpMessage = [...chatMessages];
         tmpMessage.push({ "role": "user", "content": newQuestion });
         setMessages(tmpMessage);

         try {
            var tmpStoreMessage = [...storedValues];
            tmpStoreMessage.push({
               type: "question",
               message: newQuestion
            })
            setStoredValues(tmpStoreMessage);
            setNewQuestion('');
            // Scroll down to the bottom
            setIsTyping(true);
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

            const response = await openai.createChatCompletion({
               model: "gpt-3.5-turbo",
               max_tokens: 500,
               temperature: 0.5,
               // top_p: 1,
               messages: tmpMessage,
               n: 1,
               stream: false,
            });
            setIsTyping(false);

            if (response.data.choices) {
               tmpStoreMessage.push({
                  type: "answer",
                  message: response.data.choices[0].message?.content.trim()
               })
               setStoredValues(tmpStoreMessage);
               tmpMessage.push({ "role": "assistant", "content": response.data.choices[0].message?.content });
               setMessages(tmpMessage);
               // Scroll down to the bottom
               messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
            }
         }
         catch (ex) {
            setIsTyping(false);
            message.warning("Error connecting to the server. Please try again.");
            //console.log(ex);
         }

         setLoading(false)
      } else
         setNewQuestion('');
   };

   const AI_Greeting = () => {
      var greeting_msg = "Greetings! I am brAInee BOT your personal AI assistant. I can help you with everything you need from creating lesson plan to quizzes and assessments. Just ask me anything and I will be glad to help.";
      // var greeting_msg = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores eveniet, itaque harum maiores vel sapiente in soluta optio accusamus natus perferendis omnis rerum quas, nulla explicabo deserunt voluptatibus, impedit odio quos similique ipsa libero architecto sit! Dignissimos expedita possimus explicabo sed, ad reprehenderit aliquid blanditiis distinctio cum exercitationem dolores nam suscipit debitis delectus ea perspiciatis facere, voluptatem amet eos ipsum? Mollitia aspernatur voluptate accusantium neque dolor possimus, autem quae iste, dignissimos quisquam dicta commodi nostrum quibusdam dolores doloremque fuga laborum aliquam iusto maiores! Fuga iure quae eveniet, quas, ut laudantium velit qui omnis totam quo veniam nulla cupiditate facere nostrum!";

      var tmpStoreMessage = [...storedValues];
      tmpStoreMessage.push({
         type: "answer",
         message: greeting_msg,
      });
      setStoredValues(tmpStoreMessage);
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
   }

   useEffect(() => {
      AI_Greeting();
   }, []);

   useEffect(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
   }, [isTyping]);

   return (
      <>
         {/* <Image src='../images/BraineeGPT.png' preview={false} height="70px" /> */}
         <div className="form-section">
            <div className="answer-container" >
               <SimpleBarReact style={{ maxHeight: "calc(100vh - 352px)" }}>
                  <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px", paddingBottom: "20px" }}>
                     <Image src='../images/BraineeGPT.png' preview={false} height="70px" />
                  </div>

                  {storedValues.map((value, index) => {
                     return (
                        <div className="answer-section" key={index}>
                           {value.type === "answer" &&
                              <>
                                 <div style={{ display: "flex", justifyContent: "left" }}>
                                    <div style={{ paddingLeft: "5px", marginTop: "10px" }}>
                                       <Avatar src="../images/brainee_bot_1.png" />
                                       {/* <Avatar src="../images/braineebot.png" /> */}
                                    </div>
                                    <div style={{ maxWidth: "65%" }}>
                                       <pre className="answer">{value.message}</pre>
                                    </div>
                                 </div>
                                 <div className="copy-icon">
                                    <Tooltip placement="top" title='Click to copy text'>
                                       <CopyOutlined onClick={() => copyText(value.message)} />
                                    </Tooltip>
                                 </div>
                              </>
                           }
                           {value.type === "question" &&
                              <div style={{ display: "flex", justifyContent: "right" }}>
                                 {/* <div style={{ paddingLeft: "5px", marginTop: "10px" }}>
                                 <Avatar icon={<UserOutlined />} />
                              </div> */}
                                 <div style={{ maxWidth: "65%" }}>
                                    <p className="question">{value.message}</p>
                                 </div>
                              </div>}
                        </div>
                     );
                  })}
                  {isTyping &&
                     <div style={{ display: "flex" }}>
                        <div style={{ paddingLeft: "5px", marginTop: "10px" }}>
                           {/* <Avatar src="../images/brainee_mascot.png" /> */}
                           <Avatar src="../images/braineebot.png" />
                        </div>
                        <div className="typing">
                           <div className="typing__dot" />
                           <div className="typing__dot" />
                           <div className="typing__dot" />
                        </div>
                     </div>}
                  <div ref={messagesEndRef} />
               </SimpleBarReact>
            </div>

            <TextArea
               allowClear
               showCount
               value={newQuestion}
               maxLength={1000}
               placeholder="Ask me anything..."
               onChange={(e) => setNewQuestion(e.target.value)}
               autoSize={{
                  maxRows: 3,
               }}
               style={{ border: "0px" }}
            // onKeyPress={(e) => {
            //    if (e.key === 'Enter') {
            //       generateResponse();
            //    }
            // }}
            />
            <Button className='button-shadow' size='middle' type="primary" shape='round' block loading={loading} onClick={() => generateResponse()}>
               <SendOutlined /> Send Message
            </Button>

            {/* <Input
               allowClear
               size='large'
               showCount
               value={newQuestion}
               maxLength={1000}
               placeholder="Ask me anything..."
               onChange={(e) => setNewQuestion(e.target.value)}
               onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                     generateResponse();
                  }
               }}
               style={{ borderTopLeftRadius: "7px", borderBottomLeftRadius: "7px" }}
               addonAfter={
                  <div style={{ width: "100%", height: "100%" }} onClick={() => generateResponse()}>
                     <Tooltip placement="top" title='Click to send message'>
                        <SendOutlined onClick={() => generateResponse()} style={{ cursor: "pointer" }} />
                     </Tooltip>
                  </div>
               }
            /> */}

            {/* <Row gutter={10}>
               <Col span={22}>
                  <Input
                     size='large'
                     showCount
                     value={newQuestion}
                     maxLength={100}
                     placeholder="Ask me anything..."
                     onChange={(e) => setNewQuestion(e.target.value)}
                     onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                           generateResponse();
                        }
                     }}
                     style={{ borderRadius: "7px" }}
                  />
               </Col>
               <Col span={2}>
                  <Button className='sendbutton button-shadow' size='large' type="primary" loading={loading} onClick={() => generateResponse()}>
                     <SendOutlined
                        style={{ fontSize: "26px", cursor: "pointer", marginTop: "" }}
                     />
                  </Button>
               </Col>
            </Row> */}


         </div>
      </>
   )
}