import { NoticeBoardModel } from "../models/NoticeBoardModels";

const update_notice_data = (state, payload) =>
{
  const tmpState = state;
  
  return {...tmpState,[payload.key]: payload.value};
  
}

const post_notice = (state, payload) =>
{
  const tmpState = payload;
  
  return tmpState;
  
}

const noticeboardReducer = (state = NoticeBoardModel, action) => {
    switch(action.type){     
        case 'UPDATE-NOTICE-DATA':
            return update_notice_data(state,action.payload)                
        case 'POST-NOTICE':
            return post_notice(state,action.payload)           
        default:
            return state
    }
}

export default noticeboardReducer;