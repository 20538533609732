import React,{ useContext } from 'react'
import classNames from 'classnames'
import PanelItemIcon from './PanelItemIcon'
import { CanvasContext } from '../../hooks/LessonPage'



function PanelItem(props) {
  const { setActiveTab, label, icon, name, activeTab, setPanelOpen } = props
  const { actions } = useContext(CanvasContext);
  const className = classNames({
    'panel-items-list-item': true,
    active: activeTab === name,
  })

  return (
    <div
      className={className}
      onClick={() => {
        setPanelOpen(true)
        setActiveTab(name)
        actions.setTargetRef(null)
      }}
    >
      {PanelItemIcon[icon].render()}
      <span>{label}</span>
    </div>
  )
}

export default PanelItem
