import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import rrulePlugin from '@fullcalendar/rrule'
import './Schedules.css';
import SideCalendar from '../side-calendar/SideCalendar';
import NoticeBoard from '../notice-board/NoticeBoard';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import LessonService from '../../services/LessonService';
import { message, Breadcrumb } from 'antd';
import moment from 'moment';

import { useNavigate } from "react-router-dom";

//import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
// import NoticeBoardList from '../notice-board/NoticeBoardList';
import { Tooltip } from "bootstrap";
import * as AntDIcons from '@ant-design/icons';

let tooltipInstance = null;

export default function Schedules() {
   const navigate = useNavigate();

   const [userSchedule, setUserSchedule] = useState([]);

   useEffect(() => {
      //setCurrentUsername(Utils.getCurrentUserInfo(Enums.UserInfo.fullname));
      Utils.verifyJwt();

      let user = Utils.getCurrentUserInfo(Enums.UserInfo.id);
      let role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

      //-- ToDo: If parent account, get child user id

      LessonService.getUserSchedule(user, role).then((schedules) => {
         var data = schedules.data
         let tmpSchedule = []
         data.map((item) => {
            var learningtype = item.learningType === "async" ? "Asynchronous" : "Synchronous"
            var rr = { freq: "weekly", byweekday: ["mo", "tu", "we", "th", "fr", "sa"], dtstart: moment(item.availabilityFromDate).format("YYYY-MM-DD HH:mm:ss"), until: moment(item.availabilityToDate).format("YYYY-MM-DD HH:mm:ss") }
            var titleDescription = `${item.lesson_Title} | ${learningtype}`
            var schedule = { title: titleDescription, rrule: rr }
            tmpSchedule.push(schedule)
         })
         setUserSchedule(tmpSchedule)
         // message.info("Calendar Loaded")
      }).catch((ex) => {
         message.error(ex.message)
      })
      Utils.getCurrentUserInfo(Enums.UserInfo.role)
   }, []);

   // actions
   const handleMouseEnter = (info) => {
      if (info.event.title) {
         tooltipInstance = new Tooltip(info.el, {
            title: info.event.title,
            html: true,
            placement: "top",
            trigger: "hover",
            container: "body"
         });

         tooltipInstance.show();
      }
   };

   const handleMouseLeave = (info) => {
      if (tooltipInstance) {
         tooltipInstance.dispose();
         tooltipInstance = null;
      }
   };

   return (
      <>
         <div className="dashboard-area" >
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                  <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Schedule Calendar</li>
                  </ul>        */}
                  <Breadcrumb style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <AntDIcons.HomeOutlined />
                        <a href="#" onClick={() => navigate('/home')}>Home</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        Schedule Calendar
                     </Breadcrumb.Item>
                  </Breadcrumb>
               </div>
               <div className="dashboard-item-inner3">
                  <FullCalendar
                     defaultView="dayGridMonth"
                     eventMouseEnter={handleMouseEnter}
                     eventMouseLeave={handleMouseLeave}
                     header={{
                        left: "prev,next",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay"
                     }}
                     plugins={[dayGridPlugin, timeGridPlugin, rrulePlugin]}
                     events={userSchedule}
                  />
               </div>
            </div>
         </div>
         <div className="notice-area">
            <div className="notice-item">
               {/* <SideCalendar /> */}
               <NoticeBoard />
               {/* <NoticeBoardList/> */}
            </div>
         </div>
      </>
   )
}