
import React, { useState, useEffect, useRef } from 'react';
import Enums from '../../misc/Enums';
import moment from 'moment';
import './EditLesson.scss'
import {
   Card,
   Input,
   Select,
   Skeleton,
   Button,
   Image,
   Popconfirm,
   Progress,
   DatePicker,
   Typography,
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import { Tooltip, Space, Row, Col } from 'antd';
import {
   DeleteOutlined,
   FileAddOutlined
} from '@ant-design/icons';
import ReactPlayer from 'react-player'
import LessonService from '../../services/LessonService';
import GoogleService from '../../services/GoogleService';
import Utils from '../../misc/Utils';
import { useSelector, useDispatch } from 'react-redux'
import { DisplayLessonLinks } from '.';
import { Empty, Upload, message } from 'antd';
// import { Editor } from '@tinymce/tinymce-react';
import { add_item, edit_title } from '../../actions';
import uuid from 'react-uuid'
import { Modal } from 'antd';
import { FileUploader } from "react-drag-drop-files";
import { useDropzone } from 'react-dropzone';
import Dropzone from 'react-dropzone';
// import FadeLoader from "react-spinners/FadeLoader";
// import { css } from "@emotion/react";
import Overlay from '../overlay/Overlay';

import SimpleBarReact from "simplebar-react";

const { Option } = Select;

const { TextArea } = Input;
const { Text } = Typography;

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;

export function EditLesson(props) {
   const [searchItems, setSearchItems] = React.useState([]);
   const [link, setLink] = React.useState('');
   const [items, setItems] = React.useState([]);
   const [linkDescription, setLinkDescription] = React.useState('');
   const [linkTitle, setLinkTitle] = React.useState('');
   const [linkImage, setLinkImage] = React.useState('');
   const [linkType, setLinkType] = React.useState('');
   const [linkFileType, setLinkFileType] = React.useState(1);
   const [linkOverview, setLinkOverview] = React.useState('');
   const [selectedOption, setSelectedOption] = useState("youtube");
   const [lessonTitle, setLessonTitle] = React.useState('');
   const [effectiveDate, seteffectiveDate] = React.useState('');
   const [lessonTitleEdit, setLessonTitleEdit] = React.useState('');
   const [effectiveDataEdit, setEffectiveDataEdit] = React.useState('');
   const [visible, setVisible] = React.useState(false);
   const [selectedItem, setSetSelectedItem] = React.useState({});
   const [selectedNewItem, setSelectedNewItem] = React.useState(true);
   const [selectedFileChange, setSelectedFileChange] = React.useState(false);
   const [isLoading, setIsLoading] = React.useState(false);
   const [isUploading, setIsUploading] = React.useState(false);

   const lessonState = useSelector(state => state.lesson)
   const editorRef = useRef(null);
   const dispatch = useDispatch();
   const [file, setFile] = useState(null);
   const [percentage, setPercentage] = React.useState(0);
   const [openAddModal, setOpenAddModal] = useState(false);
   const [uploadUUID, setUploadUUID] = React.useState('');

   const uploadHandle = (file) => {
      var currentFile = file[0]
      setFile(currentFile);

      var newItem = {
         type: "file",
         videoId: selectedNewItem ? uuid() : selectedItem.videoId,
         filename: currentFile.name,
         title: linkTitle,
         fileType: linkFileType,
         userId: 1,
         fileext: currentFile.type,
         description: linkDescription,
         dateCreated: moment(new Date()).format("MM/DD/YYYY"),
         image: Utils.checkFileImage(currentFile.type)
      }

      setSetSelectedItem(newItem)
      setLinkFileType(linkFileType)
      setLinkImage(Utils.checkFileImage(currentFile.type))
      setSelectedFileChange(true);
   };

   const fileTypes = ["JPG", "PNG", "GIF", "PPTX", "PPSX", "PDF", "XLSX", "DOC", "DOCX", "MP4"];
   useEffect(() => {
      var itemsContents = [...lessonState.sections[props.topicIdx].contents];
      var items = itemsContents.filter(item => item.id === props.lessonIdx)[0];
      setLessonTitle(items.title)
      seteffectiveDate(items.effectivedate)
      setItems(items.links)
   }, []);

   const onSearch = async (value, e) => {

      if (selectedOption !== Enums.ResourcesType.isMentimeter && selectedOption !== Enums.ResourcesType.isKahoot) {
         setIsLoading(true)
         GoogleService.searchGoogleService(selectedOption, value).then((response) => {
            if (response?.data) {
               setIsLoading(false)
               var varitems = response.data.items;
               var searchResult = [];
               if (selectedOption === Enums.ResourcesType.isYoutube) {
                  varitems.map((item) => {
                     var searchItem = {
                        type: Enums.ResourcesType.isYoutube, videoId: item.id.videoId, title: item.snippet.channelTitle,
                        description: item.snippet.description, image: item.snippet.thumbnails["default"].url
                     }
                     searchResult.push(searchItem);
                  })
               }
               if (selectedOption === Enums.ResourcesType.isGoogleImage) {
                  varitems.map((item) => {
                     var searchItem = {
                        type: Enums.ResourcesType.isGoogleImage, link: item.link, title: item.title,
                        description: item.snippet, image: item.link
                     }
                     searchResult.push(searchItem);
                  })
               }
               if (selectedOption === Enums.ResourcesType.isGoogleSearch) {
                  varitems.map((item) => {
                     var searchItem = {
                        type: Enums.ResourcesType.isGoogleSearch, link: item.link, title: item.title,
                        description: item.snippet, image: item.pagemap.cse_thumbnail ? item.pagemap.cse_image[0].src : ""
                     }
                     searchResult.push(searchItem);
                  })
               }
               setSearchItems(searchResult);
            }
            else {
               // No Data
               setIsLoading(false)
            }
         }).catch((ex) => {
            message.error(ex.message)
         })
      }
   }

   const getFileType = (fileext, userId, filename, uid) => {
      const subdomain = localStorage.getItem("lms-subdomain");
      let sourceFile = `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${userId}/${filename}`;

      if (uid !== "")
         sourceFile = `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${userId}/${uid}-${filename}`;

      setLink(encodeURI(sourceFile))
      if (fileext === Enums.FileType.ppt || fileext === Enums.FileType.ppsx || fileext === Enums.FileType.pptx || fileext === Enums.FileType.docx || fileext === Enums.FileType.doc || fileext === Enums.FileType.xlsx || fileext === Enums.FileType.xls) {
         setLinkType("msoffice")
         const msOfficeViewer = `${process.env.REACT_APP_MSOFFICE_VIEWER}${sourceFile}`;
         setLink(encodeURI(msOfficeViewer))
      }
      else if (fileext === Enums.FileType.pdf)
         setLinkType("pdf")
      else if (fileext === Enums.FileType.mp4)
         setLinkType("video")
      else if (fileext === Enums.FileType.png || fileext === Enums.FileType.jpeg || fileext === Enums.FileType.bmp)
         setLinkType("image")
      else
         setLinkType("others")
   }

   const searchOnclick = (item) => {
      if (selectedOption === Enums.ResourcesType.isYoutube) {
         var link = item.videoId
         const youtubeUrl = `${process.env.REACT_APP_YOUTUBE_URL}${link}`;
         setLink(youtubeUrl)
         setLinkType("youtube")
      }
      if (selectedOption === Enums.ResourcesType.isGoogleImage || selectedOption === Enums.ResourcesType.isGoogleSearch) {
         setLink(item.link)
         selectedOption === "google.image" ? setLinkType("image") : setLinkType("search")
      }

      if (selectedOption === Enums.ResourcesType.isResources) {
         getFileType(item.fileext, item.userId, item.filename, "")
      }
      setSetSelectedItem(item);
      setSelectedNewItem(false);
      setSelectedFileChange(false);

      setLinkDescription(item.description);
      setLinkTitle(item.title);
      setLinkImage(item.image)
      item.fileType !== "" ? setLinkFileType(item.fileType) : setLinkFileType("others")
   }

   const onClickAdd = () => {
      if (linkTitle !== "" && linkDescription !== "") {
         var newItem = { id: uuid(), type: linkType, url: link, description: linkDescription, title: linkTitle, image: linkImage }
         var params = { Type: "LINKS", TopicIndex: props.topicIdx, LessonIndex: props.lessonIdx, Item: newItem }
         dispatch(add_item(params));
         var newItems = [...[...lessonState.sections[props.topicIdx].contents].filter(item => item.id === props.lessonIdx)[0].links];
         setItems(newItems)
         props.refreshLesson();
         props.saveLesson()
      }
      else
         message.info("Please select resource to add.")
   }

   const option = {
      onUploadProgress: (progressEvent) => {
         const { loaded, total } = progressEvent;
         let p = Math.floor((loaded * 100) / total)
         setPercentage(p)
         //console.log(`${percentage} %`)
      }
   }

   const onUploadClick = async () => {

      if (linkTitle !== "" && linkDescription !== "" && file !== null) {
         var userId = Utils.getCurrentUserInfo(Enums.UserInfo.id)
         setIsUploading(true)
         const formdata = new FormData();
         formdata.append("Type", linkFileType)
         formdata.append("Title", linkTitle)
         formdata.append("Description", linkDescription)
         formdata.append("FileType", selectedItem.fileext)
         formdata.append("UserId", userId)
         const uid = uuid()

         // file.size
         if (!selectedNewItem) {
            formdata.append("Uuid", selectedItem.videoId !== "" ? selectedItem.videoId : uid)
            if (selectedFileChange) {
               formdata.append("File", file)
               formdata.append("FileName", file.name)
               formdata.append("FileType", file.type)
               getFileType(selectedItem.fileext, userId, file.name, uid)
            }
            else
               formdata.append("FileName", selectedItem.filename)
            formdata.append("FileType", selectedItem.fileext)
            getFileType(selectedItem.fileext, userId, selectedItem.filename, "")
         }
         else {
            setUploadUUID(uid)
            formdata.append("File", file)
            formdata.append("FileName", file.name)
            formdata.append("FileType", file.type)
            formdata.append("Uuid", uid)
            getFileType(selectedItem.fileext, userId, file.name, uid)
         }

         LessonService.postResources(formdata, option).then((response) => {
            if (response?.data) {
               message.info("Upload successful.")
               const subdomain = localStorage.getItem("lms-subdomain");
               getAllResources();
               setIsUploading(false)
               // initFormResource()
               setOpenAddModal(true)
            }
         }).catch((ex) => {
            message.error(ex);
            setIsUploading(false)
         });
      }
      else
         message.info("Invalid information provided.")

   }

   const getAllResources = () => {
      setIsLoading(true)
      LessonService.getResources().then((response) => {
         setIsLoading(false)
         var varitems = response.data;
         var searchResult = [];
         varitems.map((item) => {
            var searchItem = {
               type: "file",
               videoId: item.uuid,
               filename: item.filename,
               title: item.title,
               fileType: item.type,
               fileext: item.fileType,
               userId: item.userId,
               description: item.description,
               dateCreated: item.dateCreated,
               image: Utils.checkFileImage(item.fileType)
            }
            searchResult.push(searchItem);
         })
         setSearchItems(searchResult);
      }).catch((ex) => {
         setIsLoading(false)
         message.error(ex.message)
      });
   }

   const changeClick = async (value) => {
      setSearchItems([])
      setSelectedOption(value)
      setLinkDescription('');
      setLinkTitle('');
      setLinkImage('');
      setLinkType('');
      setLinkFileType(1);
      setSetSelectedItem({});
      if (value === Enums.ResourcesType.isResources) // Uploaded file selected
         getAllResources()
      else if (value === Enums.ResourcesType.isURL) {
         setLinkImage("../images/link-url.png")
         setLinkType("search")
      }
      else if (value === Enums.ResourcesType.isKahoot) {
         var item = {
            type: Enums.ResourcesType.isKahoot, videoId: uuid(), title: Enums.KahootInfo.title,
            description: Enums.KahootInfo.description, image: Enums.KahootInfo.image
         }
         setLink(encodeURI(Enums.KahootInfo.url))
         setSetSelectedItem(item);
         setSelectedNewItem(false);
         setSelectedFileChange(false);

         setLinkDescription(item.description);
         setLinkTitle(item.title);
         setLinkImage(item.image)
         setLinkType("search")
         setLinkFileType(1);
      }
      else if (value === Enums.ResourcesType.isMentimeter) {
         var item = {
            type: Enums.ResourcesType.isKahoot, videoId: uuid(), title: Enums.MentimeterInfo.title,
            description: Enums.MentimeterInfo.description, image: Enums.MentimeterInfo.image
         }
         setLink(encodeURI(Enums.MentimeterInfo.url))
         setSetSelectedItem(item);
         setSelectedNewItem(false);
         setSelectedFileChange(false);

         setLinkDescription(item.description);
         setLinkTitle(item.title);
         setLinkImage(item.image)
         setLinkType("search")
         setLinkFileType(1);
      }
      else if (value === Enums.ResourcesType.isCanva) {
         var item = {
            type: Enums.ResourcesType.isCanva, videoId: uuid(), title: Enums.CanvaInfo.title,
            description: Enums.CanvaInfo.description, image: Enums.CanvaInfo.image
         }
         setLink(encodeURI(Enums.CanvaInfo.url))
         setSetSelectedItem(item);
         setSelectedNewItem(false);
         setSelectedFileChange(false);

         setLinkDescription(item.description);
         setLinkTitle(item.title);
         setLinkImage(item.image)
         setLinkType("search")
         setLinkFileType(1);
      }
      else if (value === Enums.ResourcesType.isInsertLearning) {
         var item = {
            type: Enums.ResourcesType.isInsertLearning, videoId: uuid(), title: Enums.InsertLearningInfo.title,
            description: Enums.InsertLearningInfo.description, image: Enums.InsertLearningInfo.image
         }
         setLink(encodeURI(Enums.InsertLearningInfo.url))
         setSetSelectedItem(item);
         setSelectedNewItem(false);
         setSelectedFileChange(false);

         setLinkDescription(item.description);
         setLinkTitle(item.title);
         setLinkImage(item.image)
         setLinkType("search")
         setLinkFileType(1);
      }
      else if (value === Enums.ResourcesType.isFlipGrid) {
         var item = {
            type: Enums.ResourcesType.isFlipGrid, videoId: uuid(), title: Enums.FlipGridInfo.title,
            description: Enums.FlipGridInfo.description, image: Enums.FlipGridInfo.image
         }
         setLink(encodeURI(Enums.FlipGridInfo.url))
         setSetSelectedItem(item);
         setSelectedNewItem(false);
         setSelectedFileChange(false);

         setLinkDescription(item.description);
         setLinkTitle(item.title);
         setLinkImage(item.image)
         setLinkType("search")
         setLinkFileType(1);
      }
      else if (value === Enums.ResourcesType.isPhetSimulation) {
         var item = {
            type: Enums.ResourcesType.isPhetSimulation, videoId: uuid(), title: Enums.PhetSimulationInfo.title,
            description: Enums.PhetSimulationInfo.description, image: Enums.PhetSimulationInfo.image
         }
         setLink(encodeURI(Enums.PhetSimulationInfo.url))
         setSetSelectedItem(item);
         setSelectedNewItem(false);
         setSelectedFileChange(false);

         setLinkDescription(item.description);
         setLinkTitle(item.title);
         setLinkImage(item.image)
         setLinkType("search")
         setLinkFileType(1);
      }
      else if (value === Enums.ResourcesType.isSeeSaw) {
         var item = {
            type: Enums.ResourcesType.isSeeSaw, videoId: uuid(), title: Enums.SeeSaw.title,
            description: Enums.SeeSaw.description, image: Enums.SeeSaw.image
         }
         setLink(encodeURI(Enums.SeeSaw.url))
         setSetSelectedItem(item);
         setSelectedNewItem(false);
         setSelectedFileChange(false);

         setLinkDescription(item.description);
         setLinkTitle(item.title);
         setLinkImage(item.image)
         setLinkType("search")
         setLinkFileType(1);
      }
      else if (value === Enums.ResourcesType.isPadLet) {
         var item = {
            type: Enums.ResourcesType.isPadLet, videoId: uuid(), title: Enums.PadLet.title,
            description: Enums.PadLet.description, image: Enums.PadLet.image
         }
         setLink(encodeURI(Enums.PadLet.url))
         setSetSelectedItem(item);
         setSelectedNewItem(false);
         setSelectedFileChange(false);

         setLinkDescription(item.description);
         setLinkTitle(item.title);
         setLinkImage(item.image)
         setLinkType("search")
         setLinkFileType(1);
      }
   }

   const editOkTitle = () => {
      var params = { Type: "LESSON", TopicIndex: props.topicIdx, LessonIndex: props.lessonIdx, title: lessonTitleEdit, effectivedate: effectiveDataEdit }
      dispatch(edit_title(params));
      setLessonTitle(lessonTitleEdit)
      seteffectiveDate(effectiveDataEdit)
      setVisible(false)
      props.saveLesson()
   }

   const editTitleClick = (e) => {
      setVisible(true)
      setLessonTitleEdit(lessonTitle)
      setEffectiveDataEdit(effectiveDate)
   }


   const googleSection = () => {
      return (
         <div className="row row-cols-1 row-cols-sm-2 " style={{ display: "flex", position: "relative", paddingBottom: "20px" }}>
            <div className="col-xs-12 col-md-12 col-lg-5" style={{ height: "180px", paddingTop: "12px" }}>
               <div style={{ height: "180px", backgroundColor: "#ECECEC", borderRadius: "7px" }}>
                  {linkType === "youtube" && <ReactPlayer
                     controls={true}
                     width='100%'
                     height='100%'
                     url={link}
                  />}
                  {(linkType === "image" || linkType === "search") && <Image width="100%" height="100%" src={linkImage} />}
               </div>
            </div>
            <div className="col-xs-12 col-md-12 col-lg-7" >
               <div style={{ display: "flex", marginTop: "15px" }}>
                  <div>
                     <b><h8>Title:</h8></b>
                  </div>
                  <div style={{ marginLeft: "5px" }}>
                     {linkTitle}
                     {/*     <Skeleton.Input style={{ width: 445 , height : 20 , marginLeft : "10px" }} active={false} size={'default'} /> */}
                  </div>
               </div>
               <div style={{ display: "flex", marginTop: "5px" }}>
                  <div style={{
                     height: "120px", textAlign: "justify", textJustify: "inter-word", overflow: "auto",
                     textOverflow: "ellipsis"
                  }}>
                     <span><b><h8>Description: </h8></b> {linkDescription}</span>
                     {/*  <Skeleton.Input style={{ width: 400 , height : 100, marginLeft : "10px" }} active={false} size={'default'} /> */}
                  </div>
               </div>
               <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "16px" }}>
                  <div>
                     <Button className='button-shadow' type="primary" shape='round' onClick={() => onClickAdd()}>
                        Add Resources
                     </Button>
                  </div>
               </div>
            </div>
         </div>
      )
   }

   const initFormResource = () => {
      setSelectedNewItem(true);
      setLinkDescription('');
      setLinkTitle('');
      setLinkImage('');
      setLinkType('');
      setLinkFileType(1);
      setSetSelectedItem({});

   }

   const myResourcesSection = () => {
      return (
         <div style={{ display: "flex", position: "relative", height: "40%", marginBottom: "10px" }}>
            <div className='draganddrop'>
               <Dropzone onDrop={files => uploadHandle(files)}>
                  {({ getRootProps, getInputProps }) => (
                     <div className="container">
                        <div style={{ display: "block", padding: "10px 10px 10px 10px", border: "2px dashed #70D0D4" }}
                           {...getRootProps({
                              className: 'dropzone'
                           })}
                        >
                           <input {...getInputProps()} />
                           <div style={{ fontSize: "45px" }}>
                              <AntDIcons.CloudUploadOutlined />
                           </div>
                           <h7>Drag & Drop Your File Here </h7>
                        </div>
                     </div>
                  )}
               </Dropzone>
               <div style={{ display: "block", width: "100%", alignItems: "left", textAlign: "left" }}>
                  <div style={{ paddingTop: "5px", fontSize: "smaller" }}><Text ellipsis style={{ cursor: "default" }}>Filename: <Tooltip placement='top' title={selectedItem.filename}>{selectedItem.filename}</Tooltip></Text></div>
                  <div style={{ paddingTop: "5px", fontSize: "smaller" }}>File Type: {selectedItem.fileType === 1 ? "Private" : selectedItem.fileType === 2 ? "Public" : ""}</div>
                  <div style={{ paddingTop: "5px", fontSize: "smaller" }}>Date Created: {selectedItem.dateCreated}</div>
               </div>
            </div>
            <div style={{ height: "120px", width: "55%" }}>
               <div style={{ display: "flex", marginTop: "15px" }}>
                  <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                     <h8>Title:</h8>
                  </div>
                  <div style={{ marginLeft: "5px", width: '100%' }}>
                     <Input size="middle" value={linkTitle} onChange={(e) => setLinkTitle(e.target.value)} style={{ width: 'calc(100% - 20px)', borderRadius: "7px" }} />
                  </div>
               </div>
               <div style={{ marginTop: "5px", width: '100%' }}>
                  <div>
                     <span><h8>Description : </h8> </span>
                  </div>
                  <div style={{ width: 'calc(100% - 20px)' }}>
                     <TextArea size="middle" value={linkDescription} onChange={(e) => setLinkDescription(e.target.value)} rows={2} style={{ width: '100%' }} />
                  </div>
               </div>
               <div style={{ top: '20px', display: "flex" }}>
                  <div style={{ marginTop: '17px', textAlign: 'center', paddingRight: '5px' }}>
                     <span><h8>Type : </h8> </span>
                  </div>
                  <div>
                     <Select size="middle" onChange={(e) => setLinkFileType(e)} value={linkFileType === 1 ? "Private" : "Public"} style={{ marginTop: '12px' }}>
                        <Option value={1}>Private</Option>
                        <Option value={2}>Public</Option>
                     </Select>
                  </div>
               </div>
               <div style={{ justifyContent: "center", paddingTop: "15px" }}>
                  <Row gutter={8}>
                     <Col span={12} className="gutter-row">
                        <Button className='button-shadow' shape="round" size="medium" type="primary" onClick={() => initFormResource()} block>
                           <Text ellipsis style={{ color: "#fff" }}>New</Text>
                        </Button>
                     </Col>
                     <Col span={12} className="gutter-row">
                        <Button className='button-shadow' shape="round" size="medium" type="primary" onClick={() => onUploadClick()} block>
                           <Text ellipsis style={{ color: "#fff" }}>Upload</Text>
                        </Button>
                     </Col>
                     {/*                   <Col span={8} className="gutter-row">
                     <Button className='button-shadow' shape="round" size="small" type="primary" onClick={() => onClickAdd()} block>
                     <Text ellipsis style={{color: "#fff"}}>Add To Topic</Text>
                     </Button>
                  </Col> */}
                  </Row>
               </div>
            </div>
         </div>
      )
   }


   const UrlLinkSection = () => {
      return (
         <div style={{ position: "relative", height: "100%", marginBottom: "10px", marginTop: "10px" }}>
            <Row gutter={2} style={{ marginBottom: "10px" }}>
               <Col span={4}><h8>URL: </h8></Col>
               <Col span={20}><Input size="middle" onChange={(e) => setLink(e.target.value)} style={{ width: '100%', borderRadius: "7px" }} /></Col>
            </Row>
            <Row gutter={2} style={{ marginBottom: "10px" }}>
               <Col span={4}><h8>Title: </h8></Col>
               <Col span={20}><Input size="middle" onChange={(e) => setLinkTitle(e.target.value)} style={{ width: '100%', borderRadius: "7px" }} /></Col>
            </Row>
            <Row gutter={2} style={{ marginBottom: "10px" }}>
               <Col span={4}><h8>Description: </h8></Col>
               <Col span={20}><TextArea size="middle" onChange={(e) => setLinkDescription(e.target.value)} rows={3} style={{ width: '100%' }} /></Col>
            </Row>
            <Row gutter={2} style={{ marginTop: "16px", marginBottom: "12px", textAlign: "center" }}>
               <Col span={24}>
                  <Button className='button-shadow' shape="round" size="middle" type="primary" onClick={() => onClickAdd()}>
                     Add To Lesson
                  </Button>
               </Col>
            </Row>
         </div>
      )
   }


   const googleSearchCard = (item) => {
      return (
         <Card
            hoverable
            className='custom-card'
            onClick={() => searchOnclick(item)}
         >
            <div >
               <p><h9>{item.title} </h9></p>
               <div style={{ display: 'flex' }}>
                  <div style={{ width: '30%', paddingRight: '5px' }}><img src={item.image} height="100%" width="60px" alt="" ></img></div>
                  <div style={{ width: '70%', fontSize: "smaller" }}><p>{item.description} </p></div>
               </div>
            </div>
         </Card>
      )
   }

   const cardSkeleton = () => {
      return (
         <>
            <Card
               hoverable
               className='custom-card'
            >
               <div>
                  <div style={{ display: 'flex' }}>
                     <div style={{ width: '100px', paddingRight: '25px', paddingTop: '15px' }}><Skeleton.Image size="small" /></div>
                     <div style={{ width: '80%' }}>
                        <Skeleton size="small" active />
                     </div>
                  </div>
               </div>
            </Card>
         </>
      )
   }

   function confirmUploadDelete(uuid) {
      LessonService.deleteResources(uuid).then((response) => {
         if (response?.data) {
            message.info("File deleted.")
            getAllResources();
            initFormResource()
         }
      });
   }

   const cancelUploadAdd = () => {
      setOpenAddModal(false)
      initFormResource()
   }

   const confirmUploadAdd = () => {
      if (linkTitle !== "" && linkDescription !== "") {
         var newItem = { id: uuid(), type: linkType, url: link, description: linkDescription, title: linkTitle, image: linkImage }
         var params = { Type: "LINKS", TopicIndex: props.topicIdx, LessonIndex: props.lessonIdx, Item: newItem }
         dispatch(add_item(params));
         var newItems = [...[...lessonState.sections[props.topicIdx].contents].filter(item => item.id === props.lessonIdx)[0].links];
         setItems(newItems)
         props.refreshLesson();
         props.saveLesson()
         setOpenAddModal(false)
         initFormResource()
      }
      else
         message.info("Please select resource to add.")
   }

   const myResourcesCard = (item) => {
      return (

         <Card
            hoverable
            className='custom-card'
            onClick={() => searchOnclick(item)}
         >
            <div>
               <div><p><h7><b>Title : </b><span style={{ color: "blue" }}>{item.title}</span> </h7></p></div>
               <div style={{ display: 'flex' }}>
                  <div style={{ width: '30%', paddingRight: '5px' }}><img height="70px" width="70px" src={item.image} alt="" ></img></div>
                  <div style={{ width: '70%' }}>
                     <div><b>Description :</b> </div>
                     <div>{item.description} </div>
                     <div><b>Date Created :</b> </div>
                     <div>{moment(item.dateCreated).format('L')} </div>
                     <div><b>Permission Type :</b> </div>
                     <div>{item.fileType === 1 ? "Private" : "Public"} </div>
                  </div>
               </div>

               <div style={{
                  display: 'flex',
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-end"
               }}>
                  <div style={{ paddingRight: "7px" }}>
                     <Popconfirm placement="leftTop" title={'Add this item?'} onConfirm={() => confirmUploadAdd()} okText="Yes" cancelText="No">
                        <FileAddOutlined style={{ fontSize: '22px', color: '#70D0D4' }} />
                     </Popconfirm>
                  </div>
                  <div style={{ paddingRight: "7px" }}>
                     <Popconfirm placement="leftTop" title={'Delete this item?'} onConfirm={() => confirmUploadDelete(item.videoId)} okText="Yes" cancelText="No">
                        <DeleteOutlined style={{ fontSize: '22px', color: '#70D0D4' }} />
                     </Popconfirm>
                  </div>
               </div>
            </div>
         </Card>

      )
   }

   const dateEffectiveChange = (date, datestring) => {
      setEffectiveDataEdit(datestring)
   }


   return (
      <>
         <div style={{ display: "flex" }}>
            <div style={{ paddingRight: "5px" }}><span>Lesson name : </span></div>
            <div style={{ paddingRight: "10px" }}><h6>{lessonTitle}</h6></div>
            {/*           <div style={{paddingRight : "5px"}}><span>Effective date : </span></div>
          <div><h6>{effectiveDate}</h6></div> */}
            <div style={{ marginLeft: "10px" }}>
               <Tooltip placement="bottom" title='Edit Lesson Title' key={'1'}>
                  <AntDIcons.EditOutlined color='#4abec7' onClick={() => editTitleClick()} style={{ cursor: 'pointer' }} />
               </Tooltip>
            </div>
         </div>
         <div>
            <DisplayLessonLinks lessonLinks={items} lessonsIdx={props.lessonIdx} topicIdx={props.topicIdx} refreshLesson={props.refreshLesson} saveLesson={props.saveLesson} />
         </div>
         {/*           <div className="row row-cols-1 row-cols-sm-2 " style={{display : "flex", justifyContent : "space-between", marginTop : "5px", marginBottom : "5px"}}>
            <div className="col" ><h8>Search Label : </h8></div>
            <div className="col" ><h8>Digital Tools : </h8></div>
          </div> */}
         <div className="row row-cols-1 row-cols-sm-2 "
            style={{
               display: 'flex', width: '100%', paddingTop: "10px", paddingBottom: '10px', borderRadius: "7px", marginTop: "8px", marginLeft: "1px",
               boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"
            }}>
            <div className="col-xs-12 col-md-8"   >
               <div ><h8>Search Label</h8></div>
               <Input.Search
                  onSearch={(value, e) => {
                     onSearch(value, e)
                  }}
                  size='medium'
                  style={{ borderRadius: "7px" }}
                  allowClear />
            </div>
            <div className="col-xs-12 col-md-4" >
               <div ><h8>Digital Tools</h8></div>
               <Select defaultValue={selectedOption} onChange={changeClick} style={{ width: '100%' }} >
                  <Option value="youtube">Youtube.com</Option>
                  <Option value="google.image">Google Image</Option>
                  <Option value="google.search">Google Search</Option>
                  <Option value="url">External Link</Option>
                  <Option value="file">Upload File</Option>
                  <Option value="kahoot">Kahoot</Option>
                  <Option value="canva">Canva</Option>
                  <Option value="insertlearning">InsertLearning</Option>
                  <Option value="flipgrid">FlipGrid</Option>
                  <Option value="phetsimulation">PhetSimulation</Option>
                  <Option value="seesaw">SeeSaw</Option>
                  <Option value="padlet">Padlet</Option>
               </Select>
            </div>
         </div>

         {/* <div className="row row-cols-1 row-cols-sm-2 " style={{ display: 'flex', width: '100%', paddingTop: "8px", marginLeft: "1px", }}>
            <div className="col-xs-12 col-md-12 col-lg-8 " style={{ height: "100%", borderRadius: "7px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} >
               {selectedOption !== Enums.ResourcesType.isResources && selectedOption !== Enums.ResourcesType.isURL && googleSection()}
               {selectedOption === Enums.ResourcesType.isResources && myResourcesSection()}
               {selectedOption === Enums.ResourcesType.isURL && UrlLinkSection()}
            </div>
            <div className="col-xs-12 col-md-12 col-lg-4" >
               <div style={{ marginBottom: "5px" }} >
                  {(searchItems.length === 0 && !isLoading) && <div ><Empty className='searchSideEmpty' description={false} /></div>}

                  {(searchItems.length > 0 && !isLoading && selectedOption !== Enums.ResourcesType.isResources) ? <div className='searchSide'><SimpleBarReact style={{ height: "220px" }}>
                     {searchItems.map((item) => {
                        return (
                           googleSearchCard(item)
                        );
                     })}
                  </SimpleBarReact></div> : (isLoading && selectedOption !== Enums.ResourcesType.isResources) ? <div className='searchSide'> {cardSkeleton()} </div> : null
                  }

                  {(searchItems.length > 0 && !isLoading && selectedOption === Enums.ResourcesType.isResources) ? <div className='searchSide'><SimpleBarReact style={{ height: "220px" }}>
                     {searchItems.map((item) => {
                        return (
                           myResourcesCard(item)
                        );
                     })}
                  </SimpleBarReact></div> : (isLoading && selectedOption === Enums.ResourcesType.isResources) ? <div className='searchSide'> {cardSkeleton()} </div> : null
                  }
               </div>
            </div>
         </div> */}

         <Row gutter={24} style={{ width: '100%', paddingTop: "8px", marginLeft: "1px", }}>
            <Col xs={12} md={12} lg={16} style={{ height: "100%", borderRadius: "7px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
               {selectedOption !== Enums.ResourcesType.isResources && selectedOption !== Enums.ResourcesType.isURL && googleSection()}
               {selectedOption === Enums.ResourcesType.isResources && myResourcesSection()}
               {selectedOption === Enums.ResourcesType.isURL && UrlLinkSection()}
            </Col>
            <Col xs={12} md={12} lg={8}>
               {(searchItems.length === 0 && !isLoading) && <div ><Empty className='searchSideEmpty' description={false} /></div>}

               {(searchItems.length > 0 && !isLoading && selectedOption !== Enums.ResourcesType.isResources) ? <div className='searchSide'><SimpleBarReact style={{ height: "220px" }}>
                  {searchItems.map((item) => {
                     return (
                        googleSearchCard(item)
                     );
                  })}
               </SimpleBarReact></div> : (isLoading && selectedOption !== Enums.ResourcesType.isResources) ? <div className='searchSide'> {cardSkeleton()} </div> : null
               }

               {(searchItems.length > 0 && !isLoading && selectedOption === Enums.ResourcesType.isResources) ? <div className='searchSide'><SimpleBarReact style={{ height: "220px" }}>
                  {searchItems.map((item) => {
                     return (
                        myResourcesCard(item)
                     );
                  })}
               </SimpleBarReact></div> : (isLoading && selectedOption === Enums.ResourcesType.isResources) ? <div className='searchSide'> {cardSkeleton()} </div> : null
               }
            </Col>
         </Row>

         <Modal
            key="Modal-Edit-Mode"
            title="Edit Topic Description"
            destroyOnClose={true}
            centered
            open={visible}
            okButtonProps={{ style: { display: 'none' } }}
            // onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
         >
            <Input.Group compact>
               <div style={{ display: "flex", marginBottom: "5px" }}>
                  <div style={{ marginTop: "3px", paddingRight: "5px" }}>Topic Name : </div>
                  <div>
                     <Input style={{ width: '350px' }} value={lessonTitleEdit} onChange={(e) => setLessonTitleEdit(e.target.value)} />
                  </div>
               </div>
               <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                  <div>
                     <Button className='button-shadow' type="primary" onClick={() => editOkTitle()} style={{ borderRadius: "7px", margin: "auto" }}>Update Title</Button>
                  </div>
               </div>
            </Input.Group>
         </Modal>

         <Modal
            key="Modal-Edit-Mode"
            title="Upload Resources"
            destroyOnClose={true}
            centered
            open={isUploading}
            // footer={null}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}

         >
            <span>Uploading...</span>
            <Progress percent={percentage} status="active" />
         </Modal>

         <Modal
            title="Add Uploaded File ?"
            open={openAddModal}
            onOk={() => confirmUploadAdd()}
            onCancel={() => cancelUploadAdd()}
            okText="Yes"
            cancelText="Cancel"
            centered
         >
            <p>Add the uploaded file to your lesson ? </p>
         </Modal>
      </>
   );
}