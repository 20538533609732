/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import {
   Row, Col, Empty, Spin
} from 'antd';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import ProgressBar from "@ramonak/react-progress-bar";
import { IntroSum } from '../components';

export function MTG4MathSummaryReport(props) {
   const [reportData, setReportData] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   const [measurement, setMeasurement] = useState(null);
   const [number, setNumber] = useState(null);
   const [data, setData] = useState(null);
   const [total, setTotal] = useState(null);

   const [pcData, setPCData] = useState(null);
   // const [VN, setVN] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();

      ChartJS.register(
         CategoryScale,
         LinearScale,
         BarElement,
         Title,
         Tooltip,
         Legend
      );

      getReport(props.assignment_id, props.section_id);
   }, [props.assignment_id]);

   const getReport = (assignment_id, section_id) => {
      setDataLoading(true);

      var url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticreportdata?aid=${assignment_id}&sid=${section_id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            var preData = data.filter(item => item.diagnostic_type === 'pre');
            var postData = data.filter(item => item.diagnostic_type === 'post');
            // setVN(preData.length);

            url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticsummarypercentdata?aid=${assignment_id}&sid=${section_id}`;
            axios.get(url).then((response) => {

               if (response.data) {
                  const data2 = response.data;

                  setPCData(data2[0]);
                  extractData(preData, postData);

                  setReportData(data);
                  setDataLoading(false);
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const extractData = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _measurement = {};
      var _number = {};
      var _data = {};

      var _total = {};
      var _proficiency = '';

      //-- Measurement (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 + pre_data[i].s30;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 5.92) / 1.72) > 1)
            _competent++;
         else if (((_sumVal - 5.92) / 1.72) > -0.51)
            _basic++;
         else if (((_sumVal - 5.92) / 1.72) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20 + post_data[i].s30;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 5.92) / 1.72) > 1)
                  _competent++;
               else if (((_sumVal - 5.92) / 1.72) > -0.51)
                  _basic++;
               else if (((_sumVal - 5.92) / 1.72) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 5.05 ? 'Beginner' : (_postAve >= 5.06 && _postAve <= 7.64 ? 'Basic' : (_postAve >= 7.65 && _postAve <= 9 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 5.05 ? 'Beginner' : (_preAve >= 5.06 && _preAve <= 7.64 ? 'Basic' : (_preAve >= 7.65 && _preAve <= 9 ? 'Competent' : ''));
      }

      _measurement = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Measurement (end)

      //-- Number (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 +
            pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 +
            pre_data[i].s25 + pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 +
            pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 19.36) / 4.63) > 1)
            _competent++;
         else if (((_sumVal - 19.36) / 4.63) > -0.51)
            _basic++;
         else if (((_sumVal - 19.36) / 4.63) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13 +
               post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 +
               post_data[i].s25 + post_data[i].s26 + post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s34 + post_data[i].s35 + post_data[i].s36 +
               post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40;
            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 19.36) / 4.63) > 1)
                  _competent++;
               else if (((_sumVal - 19.36) / 4.63) > -0.51)
                  _basic++;
               else if (((_sumVal - 19.36) / 4.63) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 17.04 ? 'Beginner' : (_postAve >= 17.05 && _postAve <= 23.99 ? 'Basic' : (_postAve >= 24 && _postAve <= 28 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 17.04 ? 'Beginner' : (_preAve >= 17.05 && _preAve <= 23.99 ? 'Basic' : (_preAve >= 24 && _preAve <= 28 ? 'Competent' : ''));
      }

      _number = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Number (end)      

      //-- Data (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.46) / 0.82) > 1)
            _competent++;
         else if (((_sumVal - 2.46) / 0.82) > -0.51)
            _basic++;
         else if (((_sumVal - 2.46) / 0.82) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s31 + post_data[i].s32 + post_data[i].s33;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.46) / 0.82) > 1)
                  _competent++;
               else if (((_sumVal - 2.46) / 0.82) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.46) / 0.82) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2.04 ? 'Beginner' : (_postAve >= 2.05 && _postAve <= 2.87 ? 'Basic' : (_postAve >= 2.88 && _postAve <= 3 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 2.04 ? 'Beginner' : (_preAve >= 2.05 && _preAve <= 2.87 ? 'Basic' : (_preAve >= 2.88 && _preAve <= 3 ? 'Competent' : ''));
      }

      _data = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Data (end)

      _total = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _measurement.prem + _number.prem + _data.prem,
         postm: _measurement.postm + _number.postm + _data.postm,
         min: _measurement.min + _number.min + _data.min,
         max: _measurement.max + _number.max + _data.max,
         sd: _measurement.sd + _number.sd + _data.sd,
         proficiency: _proficiency,
         beginner: _measurement.beginner + _number.beginner + _data.beginner,
         basic: _measurement.basic + _number.basic + _data.basic,
         competent: _measurement.competent + _number.competent
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 26.18 ? 'Beginner' : (_total.postm >= 26.19 && _total.postm <= 34.87 ? 'Basic' : (_total.postm >= 34.88 && _total.postm <= 40 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 26.18 ? 'Beginner' : (_total.prem >= 26.19 && _total.prem <= 34.87 ? 'Basic' : (_total.prem >= 34.88 && _total.prem <= 40 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setTotal(_total);
      setMeasurement(_measurement);
      setNumber(_number);
      setData(_data);
   }

   const generateMeanLevelRow = (label, data, istotal) => {
      return (
         <tr>
            <td style={{ width: '20.56%', borderStyle: 'solid', borderWidth: '0.75pt', borderBottom: '1pt solid #cccccc', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
            </td>
            <td style={{ width: '4.78%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.vn}</p>
            </td>
            <td style={{ width: '11.4%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.prem <= 0 || data.prem === null ? '' : (data.prem).toFixed(2)}</p>
            </td>
            <td style={{ width: '11.68%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.postm <= 0 || data.postm === null ? '' : (data.postm).toFixed(2)}</p>
            </td>
            <td style={{ width: '10.8%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.min}</p>
            </td>
            <td style={{ width: '11.16%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.max}</p>
            </td>
            <td style={{ width: '14.52%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{(data.sd).toFixed(2)}</p>
            </td>
            <td style={{ width: '15.1%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.proficiency}</p>
            </td>
         </tr>
      );
   }

   const generateFrequencyRow = (label, data, index) => {
      return (
         <>
            <Row><Col span={24} style={{ textAlign: "left" }}><p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>{index}. {label !== "TOTAL" ? "Frequency of Proficiency Levels on " : ""} {label}</strong></p></Col></Row>
            <Row>
               <Col span={12}>
                  <table cellSpacing={0} cellPadding={0} style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Count</span></strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent</span></strong></p>
                        </td>
                     </tr>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Beginner</strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.beginner}</p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'beginner') === '100.00' ? '100' : (Utils.getPercentage(data, 'beginner') === '0.00' ? '0' : Utils.getPercentage(data, 'beginner'))}</p>
                        </td>
                     </tr>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Basic&nbsp;</strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.basic}</p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'basic') === '100.00' ? '100' : (Utils.getPercentage(data, 'basic') === '0.00' ? '0' : Utils.getPercentage(data, 'basic'))}</p>
                        </td>
                     </tr>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Competent&nbsp;</strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.competent}</p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'competent') === '100.00' ? '100' : (Utils.getPercentage(data, 'competent') === '0.00' ? '0' : Utils.getPercentage(data, 'competent'))}</p>
                        </td>
                     </tr>
                  </table>
               </Col>
               <Col span={12}>
                  <div style={{ marginLeft: "20px", marginRight: "20px", height: "200px" }}>
                     <Bar
                        options={{
                           responsive: true,
                           scales: {
                              y:
                              {
                                 min: 0,
                                 max: 100,
                                 stepSize: 1,
                              },
                              x:
                                 {},
                           }
                        }}

                        data={{
                           labels: ['Beginner', 'Basic', 'Competent'],
                           datasets: [
                              {
                                 label: label,
                                 backgroundColor: "#4F81BD",
                                 borderColor: "#4F81BD",
                                 data: [Utils.getPercentage(data, 'beginner'), Utils.getPercentage(data, 'basic'), Utils.getPercentage(data, 'competent')],
                              },
                           ],
                        }}
                     />
                  </div>
               </Col>
            </Row>
         </>
      );
   }

   const generatePercentCorrectRow = (label, predata, postdata, index) => {
      return (
         <tr>
            <td style={{ width: '11.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
                  <strong>{index}</strong>
               </p>
            </td>
            <td style={{ width: '330.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
                  {label}
               </p>
            </td>
            <td style={{ width: '43.35pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {/* {predata !== null ? predata.toFixed(2) + '%' : ''} */}
                  {/* {predata !== null ? (predata === 100 ? predata : (predata === 0 ? '0' : predata.toFixed(2))) : ''} */}
                  {
                     (predata !== null && predata !== 0) &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        // labelColor={pre_percent <= 40 ? "#000000" : "#FFFFFF"}
                        labelColor={"#FFFFFF"}
                        // labelAlignment={pre_percent <= 40 ? "outside" : "center"}
                        labelAlignment={"center"}
                        customLabel={predata !== 100 ? predata.toFixed(1) + "%" : predata + "%"}
                        completed={predata}
                        labelSize="12px"
                        animateOnRender
                     />
                  }
               </p>
            </td>
            <td style={{ width: '75.35pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {/* {postdata !== null ? (postdata === 100 ? postdata : (postdata === 0 ? '0' : postdata.toFixed(2))) : ''} */}
                  {
                     (postdata !== null && postdata !== 0) &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        // labelColor={pre_percent <= 40 ? "#000000" : "#FFFFFF"}
                        labelColor={"#FFFFFF"}
                        // labelAlignment={pre_percent <= 40 ? "outside" : "center"}
                        labelAlignment={"center"}
                        customLabel={postdata !== 100 ? postdata.toFixed(1) + "%" : postdata + "%"}
                        completed={postdata}
                        labelSize="12px"
                        animateOnRender
                     />
                  }
               </p>
            </td>
         </tr>
      );
   }

   const generateContentStandards = (label, beg1, beg2, bas1, bas2, comp1, comp2) => {
      return (
         <tr style={{ height: '14.5pt' }}>
            <td style={{ width: '39.3%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: '115%', fontSize: '12pt' }}><strong>{label}</strong></p>
            </td>
            <td style={{ width: '4.54%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{beg1}</p>
            </td>
            <td style={{ width: '3.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>-</p>
            </td>
            <td style={{ width: '9.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{beg2}</p>
            </td>
            <td style={{ width: '9.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{bas1}</p>
            </td>
            <td style={{ width: '3.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>-</p>
            </td>
            <td style={{ width: '9.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{bas2}</p>
            </td>
            <td style={{ width: '8.42%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{comp1}</p>
            </td>
            <td style={{ width: '3.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>-</p>
            </td>
            <td style={{ width: '6.1%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{comp2}</p>
            </td>
         </tr>
      );
   }

   const renderLabel = (label) => {
      return (
         <>
            <tr>
               <td colSpan={4} style={{ width: '11.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                  <p style={{ margin: '0pt 5.65pt', textAlign: 'left', fontSize: '11pt' }}>
                     <strong>{label}</strong>
                  </p>
               </td>
            </tr>
         </>
      );
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               (reportData !== null && reportData.length > 0)
                  ?
                  <div className="dashboard-item-inner3" style={{ margin: '20px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='summary-report'>
                        <div>

                           <IntroSum
                              grade_level={props.template}
                              section={props.section_desc}
                              school_name={reportData[0].school_name}
                              subject_id={props.subject_id}
                              subject={props.subject}
                              backgroundColor="#4F81BD"
                              diagnostic_date={reportData[0].diagnostic_date}
                              diagnostic_type={reportData[0].diagnostic_type}
                           />

                           <p style={{ marginTop: '10pt', marginBottom: '0pt', lineHeight: '115%', fontSize: '12pt' }}>
                              <strong>&nbsp;</strong>
                           </p>
                           <div style={{ pageBreakBefore: 'always' }}></div>
                           <p style={{ textAlign: 'center', fontSize: '14pt' }}><strong>Summary of Results for {props.subject}</strong></p>
                           <p style={{ textAlign: 'center' }}><strong>PRE TEST</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>MEAN LEVEL OF SCORES IN THE SUBJECT AREAS</strong></p>
                           <table style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr>
                                    <td style={{ textAlign: 'center', width: '25%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Area</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '4%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>N</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '10%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Pretest Mean</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '10%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Post Test Mean</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '10%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Min</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '11%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Max</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '14%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Standard Deviation</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '13%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Proficiency Level</span></strong>
                                    </td>
                                 </tr>
                                 {generateMeanLevelRow('Measurement and Geometry', measurement, false)}
                                 {generateMeanLevelRow('Number and Algebra', number, false)}
                                 {generateMeanLevelRow('Data and Probability', data, false)}
                                 {generateMeanLevelRow('TOTAL', total, true)}
                              </tbody>
                           </table>
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                           <table style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '39.3%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Content Standards</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Beginner</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Basic</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Competent</span></strong></p>
                                    </td>
                                 </tr>
                                 {generateContentStandards('Measurement and Geometry', 0, 5.05, 5.06, 7.64, 7.65, 9)}
                                 {generateContentStandards('Number and Algebra', 0, 17.04, 17.05, 23.99, 24, 28)}
                                 {generateContentStandards('Data and Probability', 0, 2.04, 2.05, 2.87, 2.88, 3)}
                                 {generateContentStandards('TOTAL', 0, 26.18, 26.19, 34.87, 34.88, 40)}
                              </tbody>
                           </table>
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                           {generateFrequencyRow('Measurement and Geometry', measurement, 1)}
                           {generateFrequencyRow('Number and Algebra', number, 2)}
                           {generateFrequencyRow('Data and Probability', data, 3)}
                           {generateFrequencyRow('TOTAL', total, 4)}
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>Percent of Correct</strong></p>
                           <table style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr>
                                    <td colSpan={2} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center' }}><strong><span style={{ color: '#ffffff' }}>LEARNING COMPETENCY</span></strong></p>
                                    </td>
                                    <td style={{ width: '19.9%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center' }}><strong><span style={{ color: '#ffffff' }}>Pre Test Percent Correct for the Level</span></strong></p>
                                    </td>
                                    <td style={{ width: '20.6%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center' }}><strong><span style={{ color: '#ffffff' }}>Post Test Percent Correct for the Level</span></strong></p>
                                    </td>
                                 </tr>

                                 {renderLabel("Part 1: Measurement and Geometry")}
                                 {generatePercentCorrectRow("Illustrate different angles (right, acute, and obtuse) using models.", pcData.pre_s1, pcData.post_s1, 1)}
                                 {generatePercentCorrectRow("Classify triangles and quadrilaterals according to sides and angles.", pcData.pre_s2, pcData.post_s2, 2)}
                                 {generatePercentCorrectRow("Differentiate different quadrilaterals.", pcData.pre_s3, pcData.post_s3, 3)}
                                 {generatePercentCorrectRow("Find the perimeter of quadrilaterals that are not squares or rectangles.", pcData.pre_s4, pcData.post_s4, 4)}
                                 {generatePercentCorrectRow("Find the perimeter of composite figures composed of triangles and quadrilaterals.", pcData.pre_s5, pcData.post_s5, 5)}
                                 {renderLabel("Part 2: Number and Algebra")}
                                 {generatePercentCorrectRow("Read and write numbers up to 1 000 000 in numerals and in words.", pcData.pre_s6, pcData.post_s6, 6)}
                                 {generatePercentCorrectRow("Determine (a.) the place value of a digit in a 6-digit number, (b.) the value of a digit, and (c.) the digit of number, given its place value.", pcData.pre_s7, pcData.post_s7, 7)}
                                 {generatePercentCorrectRow("Determine (a.) the place value of a digit in a 6-digit number, (b.) the value of a digit, and (c.) the digit of number, given its place value.", pcData.pre_s8, pcData.post_s8, 8)}
                                 {generatePercentCorrectRow("Compare numbers up to 1 000 000 using =, < and >.", pcData.pre_s9, pcData.post_s9, 9)}
                                 {generatePercentCorrectRow("Estimate the sum and difference of two 5- to 6-digit numbers by rounding the addends to the nearest large place value of the numbers.", pcData.pre_s10, pcData.post_s10, 10)}
                                 {generatePercentCorrectRow("Add numbers with sums up to 1 000 000 and subtracts numbers where both numbers are less than 1 000 000, with and without regrouping.", pcData.pre_s11, pcData.post_s11, 11)}
                                 {generatePercentCorrectRow("Multiply two numbers with and without regrouping: a. 3- to 4-digit numbers by a 1-digit number, and b. 2- to 3-digit numbers by 2-digit numbers, with products up to 1 000 000.", pcData.pre_s12, pcData.post_s12, 12)}
                                 {generatePercentCorrectRow("Estimate the result of multiplying two numbers where the product is less than 1 000 000.", pcData.pre_s13, pcData.post_s13, 13)}
                                 {generatePercentCorrectRow("Solve multi-step problems involving one or more of the four operations with results of calculations up to 1 000 000, including problems involving money.", pcData.pre_s14, pcData.post_s14, 14)}
                                 {generatePercentCorrectRow("Divide two numbers with and without regrouping a. 3- to 4-digit numbers by 1-digit numbers b. 2- to 3-digit numbers by 2-digit numbers", pcData.pre_s15, pcData.post_s15, 15)}
                                 {generatePercentCorrectRow("Estimate the quotient when dividing 3- to 4-digit dividends by 1- to 2-digit divisors, by first estimating the dividends and divisors using multiples of 10.", pcData.pre_s16, pcData.post_s16, 16)}
                                 {generatePercentCorrectRow("Perform two or more different operations by applying the MDAS rules.", pcData.pre_s17, pcData.post_s17, 17)}
                                 {renderLabel("Part 3: Measurement and Geometry")}
                                 {generatePercentCorrectRow("Convert common units of measure from larger to smaller units, and vice versa: a. meter and centimeter, b. kilometer and meter,  c. kilogram and gram, d. gram and milligram, and e. liter and milliliter.", pcData.pre_s18, pcData.post_s18, 18)}
                                 {generatePercentCorrectRow("Convert common units of measure from larger to smaller units, and vice versa: a. meter and centimeter, b. kilometer and meter,  c. kilogram and gram, d. gram and milligram, and e. liter and milliliter.", pcData.pre_s19, pcData.post_s19, 19)}
                                 {generatePercentCorrectRow("Solve problems involving conversion of units of length, mass, capacity, and time, including problems involving elapsed time in hours and minutes.", pcData.pre_s20, pcData.post_s20, 20)}
                                 {renderLabel("Part 4: Number and Algebra")}
                                 {generatePercentCorrectRow("Add and subtract similar fractions: a. two proper fractions, b. two mixed numbers, c. a mixed number and a proper fraction, d. a whole number and a proper fraction, and e. a whole number and a mixed number.", pcData.pre_s21, pcData.post_s21, 21)}
                                 {generatePercentCorrectRow("Compare dissimilar fractions using the symbols =, >, and <.", pcData.pre_s22, pcData.post_s22, 22)}
                                 {generatePercentCorrectRow("Order dissimilar fractions from smallest to largest, and vice versa.", pcData.pre_s23, pcData.post_s23, 23)}
                                 {generatePercentCorrectRow("Generate equivalent fractions using models.", pcData.pre_s24, pcData.post_s24, 24)}
                                 {generatePercentCorrectRow("Determine equivalent fractions.", pcData.pre_s25, pcData.post_s25, 25)}
                                 {generatePercentCorrectRow("Find all the factors of a given number up to 100.", pcData.pre_s26, pcData.post_s26, 26)}
                                 {generatePercentCorrectRow("Add and subtract dissimilar fractions using models.", pcData.pre_s27, pcData.post_s27, 27)}
                                 {generatePercentCorrectRow("Add and subtract dissimilar fractions:  a. two proper fractions, b. two mixed numbers, c. a mixed number and a proper fraction,  d. a whole number and a proper fraction, and e. a whole number and a mixed number.", pcData.pre_s28, pcData.post_s28, 28)}
                                 {generatePercentCorrectRow("Solve multi-step problems involving addition and/or subtraction of fractions.", pcData.pre_s29, pcData.post_s29, 29)}
                                 {renderLabel("Part 5: Measurement and Geometry")}
                                 {generatePercentCorrectRow("Identify symmetry with respect to a line.", pcData.pre_s30, pcData.post_s30, 30)}
                                 {renderLabel("Part 6: Data and Probability")}
                                 {generatePercentCorrectRow("Interpret data presented in a tabular form, or in a single line graph.", pcData.pre_s31, pcData.post_s31, 31)}
                                 {generatePercentCorrectRow("Interpret data presented in a tabular form, or in a single line graph.", pcData.pre_s32, pcData.post_s32, 32)}
                                 {generatePercentCorrectRow("Solve problems using data for at most two variables in a tabular form, or in a single line graph.", pcData.pre_s33, pcData.post_s33, 33)}
                                 {renderLabel("Part 7: Number and Algebra")}
                                 {generatePercentCorrectRow("Describe the rule used to generate a given simple pattern.", pcData.pre_s34, pcData.post_s34, 34)}
                                 {generatePercentCorrectRow("Complete a number sentence: a. to represent a property of operations (e.g., 4 + 3 = 3 + __) (commutative property of addition) b. to represent equivalent number facts (e.g., 4 + __ = 6 + 3)", pcData.pre_s35, pcData.post_s35, 35)}
                                 {generatePercentCorrectRow("Read and write decimal numbers with decimal parts to hundredths.", pcData.pre_s36, pcData.post_s36, 36)}
                                 {generatePercentCorrectRow("Determine: a. the place value to hundredths of a digit in a given decimal number, b. the value of a digit, and c. the digit of number, given its place value.", pcData.pre_s37, pcData.post_s37, 37)}
                                 {generatePercentCorrectRow("Determine  a. the place value to hundredths of a digit in a given decimal number, b. the value of a digit, and c. the digit of number, given its place value.", pcData.pre_s38, pcData.post_s38, 38)}
                                 {generatePercentCorrectRow("Convert decimal numbers to fractions, and fractions with denominators 10 or 100 to decimals.", pcData.pre_s39, pcData.post_s39, 39)}
                                 {generatePercentCorrectRow("Compare and order decimal numbers with decimal parts to hundredths.", pcData.pre_s40, pcData.post_s40, 40)}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div >
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }

      </>
   );
}