import React, { useState } from 'react';
import axios from 'axios';
import { Input, Tooltip, Row, Col, message, Upload, Image, Space } from 'antd';
import { CloseCircleTwoTone, LoadingOutlined, PlusOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Utils from '../../../misc/Utils';

import './ShortAnswer.css';

// const { TextArea } = Input;

export default function MatchingTypeImageItem(props) {
   var subdomain = localStorage.getItem("lms-subdomain");

   if (props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic')
      subdomain = "ccph";

   // const [loading, setLoading] = useState(false);
   const [loadingA, setLoadingA] = useState(false);
   const [loadingB, setLoadingB] = useState(false);

   let imgPath1 = '';
   let imgPath2 = '';

   if (props.image_url !== undefined && Array.isArray(props.image_url)) {
      if (props.assessment_type === 'summative' || props.assessment_type === 'formative') {
         imgPath1 = props.image_url[0].length > 0 ? `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.image_url[0].substring(props.image_url[0].indexOf('assessment'), props.image_url[0].length)}` : "";
         imgPath2 = props.image_url[1].length > 0 ? `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.image_url[1].substring(props.image_url[1].indexOf('assessment'), props.image_url[1].length)}` : "";
      }

      if (props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic') {
         imgPath1 = props.image_url[0].length > 0 ? `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.image_url[0].substring(props.image_url[0].indexOf('mastery-assessment'), props.image_url[0].length)}` : "";
         imgPath2 = props.image_url[1].length > 0 ? `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.image_url[1].substring(props.image_url[1].indexOf('mastery-assessment'), props.image_url[1].length)}` : "";
      }
   }

   const [imageURLCol1, setImageURLCol1] = useState(props.image_url !== undefined && Array.isArray(props.image_url) ? (props.image_url[0].includes('base64') ? props.image_url[0] : imgPath1) : '');
   const [imageURLCol2, setImageURLCol2] = useState(props.image_url !== undefined && Array.isArray(props.image_url) ? (props.image_url[1].includes('base64') ? props.image_url[1] : imgPath2) : '');
   const [column, setColumn] = useState('');
   const [alpha, setAlpha] = useState(props.correct !== undefined && props.correct !== '' ? props.correct : '');

   const handleChange = (command, value) => {
      const re = /^[A-Za-z]+$/;

      if ((value === "" || re.test(value)) && command === 'update-input-multi') {
         setAlpha(value);
      }

      props.parentCallback(command, value, props.id);
      // console.log(value);
   }

   const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
         message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
         message.error('Image must smaller than 2MB!');
      }

      // return isJpgOrPng && isLt2M;
      handleUploadImage(column, file);
   }

   const handleUploadImage = (col, file) => {
      if (col === 'a') {
         setImageURLCol1('');
         setLoadingA(true);
      }
      else {
         setImageURLCol2('');
         setLoadingB(true);
      }


      // setLoading(true);

      const formData = new FormData();
      formData.append('file', file);

      // const url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadASSFile?s3path=${process.env.REACT_APP_S3_ASSESSMENT_IMAGES_PATH}`;

      let url = '';

      if (props.assessment_type === 'mastery')
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadASSFile?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_IMAGES_PATH}`;

      if (props.assessment_type === 'diagnostic')
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadASSFile?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_IMAGES_PATH}`;

      if (props.assessment_type === 'summative')
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadASSFile?s3path=${process.env.REACT_APP_S3_ASSESSMENT_IMAGES_PATH}`;

      if (props.assessment_type === 'formative')
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadASSFile?s3path=${process.env.REACT_APP_S3_ASSESSMENT_IMAGES_PATH}`;

      axios.post(url, formData).then(response => {
         console.log(response);
         if (response.data) {
            const data = response.data;
            Utils.getBase64(file, imageUrl => {
               if (col === 'a')
                  setImageURLCol1(imageUrl);
               else
                  setImageURLCol2(imageUrl);

               setLoadingA(false);
               setLoadingB(false);
            });

            // props.parentCallback("update-image-url", data, props.id);
            props.parentCallback(col === 'a' ? "update-image-col-a" : "update-image-col-b", data, props.id);
         }
      }, (error) => {
         console.log(error.message);
      });
   };

   // const handleCallback = (col, imgb64) => {
   //    props.parentCallback(col === 'a' ? "update-image-col-a" : "update-image-col-b", imgb64, props.id);

   //    if (col === 'a')
   //       setImageURLCol1(imgb64);
   //    else
   //       setImageURLCol2(imgb64);
   // }

   // const handleUploadImage = (col, file) => {

   //    if (col === 'a')
   //       setImageURLCol1('');
   //    else
   //       setImageURLCol2('');

   //    const formData = new FormData();
   //    formData.append('file', file);

   //    Utils.getBase64(file, imageb64 =>
   //       handleCallback(col, imageb64),
   //    );
   // };

   const handleRemoveOption = (idx) => {
      props.parentCallback("remove", "", idx);
   }
   const uploadButtonA = (
      <div>
         {loadingA ? <LoadingOutlined /> : <PlusOutlined />}
         <div style={{ marginTop: 8 }}>Upload</div>
      </div>
   );

   const uploadButtonB = (
      <div>
         {loadingB ? <LoadingOutlined /> : <PlusOutlined />}
         <div style={{ marginTop: 8 }}>Upload</div>
      </div>
   );

   return (
      <Row width={'100%'} gutter={['12', '12']} style={{ padding: '5px' }}>
         <Col span={12} >
            <Row width={'100%'} gutter={['12', '12']}>
               <Col span={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Space direction='horizontal' size={'small'}>
                     <>{
                        props.mode.includes('-view')
                           ?
                           props.matched
                              ?
                              <><CheckOutlined style={{ fontSize: '22px', color: '#52c41a' }} />&nbsp;</>
                              :
                              <><CloseOutlined style={{ fontSize: '22px', color: '#eb2f96' }} />&nbsp;</>
                           :
                           <></>

                     }</>
                     <>{Utils.convertLetterToNumber(props.label)}</>
                     <div style={{ borderBottom: '1px solid #aaa', textAlign: 'left', width: '60px' }}>
                        <Input
                           pattern="[a-zA-Z]+"
                           bordered={false}
                           onChange={(e) => handleChange('update-input-multi', e.target.value)}
                           style={{ textAlign: 'center' }}
                           disabled={props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                           value={alpha}
                        />
                     </div>
                     <Upload
                        id={'colA' + props.id}
                        disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                        name={'colA' + props.id}
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        accept=".png,.jpg,.gif,.jpeg"
                        onClick={() => setColumn('a')}
                     >
                        {imageURLCol1.length > 0 ? <Image height={102.4} src={imageURLCol1} alt={props.id} preview={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false} /> : uploadButtonA}
                     </Upload>
                  </Space>
               </Col>
            </Row>
         </Col>
         <Col span={12} >
            <Row width={'100%'} gutter={['12', '12']} >
               <Col span={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Space direction='horizontal' size={'middle'}>
                     <>
                        <>{props.label}</>
                        <Upload
                           id={'colB' + props.id}
                           disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                           name={'colB' + props.id}
                           listType="picture-card"
                           className="avatar-uploader"
                           showUploadList={false}
                           beforeUpload={beforeUpload}
                           accept=".png,.jpg,.gif,.jpeg"
                           onClick={() => setColumn('b')}
                        >
                           {imageURLCol2.length > 0 ? <Image height={102.4} src={imageURLCol2} alt={props.id} preview={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false} /> : uploadButtonB}
                        </Upload>
                     </>
                     {
                        props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view")
                           ?
                           <></>
                           :
                           <Tooltip placement="left" title='Remove Option'>
                              <CloseCircleTwoTone twoToneColor="red" style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => handleRemoveOption(props.id)} />
                           </Tooltip>
                     }
                  </Space>
               </Col>
            </Row>
         </Col>
      </Row>
   )
}