import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import {createStore} from 'redux'
import allReducer from './reducers'
import { Provider } from 'react-redux'
import { unregister as unRegisterServiceWorker } from './serviceWorkerRegistration';

// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() s an extension that allows us to see state changes in the browser
const store = createStore(allReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
   <BrowserRouter>
     <Provider store={store}>
       <App />
     </Provider>
 </BrowserRouter>,
  document.getElementById('root')
);
unRegisterServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
