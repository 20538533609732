import React, { useEffect, useState, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
   Input, Typography, Space, Tooltip, Button, Row, Col, message, Form, Spin, Divider
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import axios from 'axios';
import uuid from 'react-uuid';
import Utils from '../../../misc/Utils';
import Enums from '../../../misc/Enums';
import './../Management.css';

const { Text, Title } = Typography;

export function LoginCredsStaff(props) {
   const [form] = Form.useForm();
   const navigate = useNavigate();

   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   var userID = props.userid;

   const [loginCreds, setLoginCreds] = useState(null);
   const [newUserName, setNewUserName] = useState(null);
   const [newPassword, setNewPassword] = useState(null);
   const [newParentUserName, setNewParentUserName] = useState(null);
   const [newParentPassword, setNewParentPassword] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin')
         navigate('/home');

      GetLoginCreds(userID);
   }, []);

   const GetLoginCreds = (id) => {
      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getstafflogincreds?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;

            setLoginCreds(data);
         }
      }, (error) => {
         // setSectionLoading(false);
         console.log(error.message);
      });
   }

   const onFinish = (values) => {
      const key = 'updatable';

      if (newUserName !== null || newPassword !== null || newParentUserName !== null || newParentPassword !== null) {
         let Data = {
            Userid: userID,
            Username: newUserName === null ? loginCreds.username : newUserName,
            Password: newPassword === null ? loginCreds.password : newPassword,
            Role: 'teacher',
         }

         // message.loading({ content: 'Updating login credentials...', key, duration: 0 });
         props.onUpdatingCallBack();

         var url = `${process.env.REACT_APP_API_MANAGEMENT}/updatestafflogincreds`;
         axios.post(url, Data).then(response => {
            console.log(response);
            if (response.data) {
               const data = response.data;

               setTimeout(() => {
                  message.success({ content: 'Update complete!', key, duration: 2 });
                  props.onSaveCompleteCallBack();
               }, 1000);
            }
         }, (error) => {
            console.log(error.message);
            setTimeout(() => {
               message.error({ content: 'Update failed!', key, duration: 2 });
            }, 1000);
         });
      } else {
         message.success({ content: 'No changes has been made.', key, duration: 2 });
         props.onCancelCallback();
      }
   }

   const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };

   const handleCancel = () => {
      props.onCancelCallback();
   }

   return (
      <>
         {loginCreds !== null
            ?
            <Form
               form={form}
               layout={"vertical"}
               colon={false}
               onFinish={onFinish}
               onFinishFailed={onFinishFailed}
            >
               <Divider orientation="center">
                  <Title level={5} style={{ textAlign: "center", color: "#252525" }}>Staff</Title>
               </Divider>

               <Row gutter={12}>
                  <Col xs={24} md={12} lg={12}>
                     <Form.Item
                        name="old_username"
                        label={<Text ellipsis >Current User Name</Text>}
                        initialValue={loginCreds.username}
                     >
                        <Input
                           disabled
                           size='middle'
                           style={{ borderRadius: 7 }}
                           value={loginCreds.username}
                        />
                     </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                     <Form.Item
                        name="new_username"
                        label={<Text ellipsis >New User Name</Text>}
                     >
                        <Input
                           allowClear
                           size='middle'
                           style={{ borderRadius: 7 }}
                           placeholder="Enter New User Name"
                           onBlur={(event) => setNewUserName(event.target.value)}
                        />
                     </Form.Item>
                  </Col>
               </Row>
               <Row gutter={12}>
                  <Col xs={24} md={12} lg={12}>
                     <Form.Item
                        name="old_password"
                        label={<Text ellipsis >Current Password</Text>}
                        initialValue={loginCreds.password}
                     >
                        <Input
                           disabled
                           size='middle'
                           style={{ borderRadius: 7 }}
                           value={loginCreds.password}
                        />
                     </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                     <Form.Item
                        name="new_password"
                        label={<Text ellipsis >New Password</Text>}
                     >
                        <Input
                           allowClear
                           size='middle'
                           style={{ borderRadius: 7 }}
                           placeholder="Enter New Password"
                           onBlur={(event) => setNewPassword(event.target.value)}
                        />
                     </Form.Item>
                  </Col>
               </Row>

               <Row gutter={12}>
                  <Col xs={24} md={24} lg={24} style={{ textAlign: "right" }}>
                     <Form.Item style={{ marginBottom: '0px' }}>
                        <Space direction='horizontal' size={'small'}>
                           <Button shape='round' onClick={() => { handleCancel(); }}>Cancel</Button>
                           <Button type='primary' shape='round' htmlType='submit'>Save</Button>
                        </Space>
                     </Form.Item>
                  </Col>
               </Row>
            </Form>
            :
            <div className='temp-answer-sheet-content2' style={{ height: "380px" }}>
               <Spin size='large' tip="Loading, please wait..." />
            </div>
         }

      </>
   );
}