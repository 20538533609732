import React, { useEffect, useState, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
   Input, Typography, Space, Tooltip, Button, Row, Col, message, Form, Spin, Divider
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import axios from 'axios';
import uuid from 'react-uuid';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import './Profile.scss';

const { Text, Title } = Typography;

export function LoginCreds(props) {
   const [form] = Form.useForm();
   const navigate = useNavigate();

   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   var userID = props.userid;

   const [loginCreds, setLoginCreds] = useState(null);
   const [currentUserName, setCurrentUserName] = useState('');
   const [currentPassword, setCurrentPassword] = useState('');
   const [newUserName, setNewUserName] = useState('');
   const [newPassword, setNewPassword] = useState('');
   const [confirmUserName, setConfirmUserName] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');

   useEffect(() => {
      Utils.verifyJwt();

      GetLoginCreds(userID);
   }, []);

   const GetLoginCreds = (id) => {
      if (user_role === 'Student' || user_role === 'Parent') {
         axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getlogincredsall?id=${id}`).then((response) => {
            if (response.data) {
               const data = response.data;

               setLoginCreds(data);
            }
         }, (error) => {
            console.log(error.message);
         });
      } else {
         axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getstafflogincreds?id=${id}`).then((response) => {
            if (response.data) {
               const data = response.data;

               setLoginCreds(data);
            }
         }, (error) => {
            console.log(error.message);
         });
      }
   }

   const onFinish = (values) => {
      const key = 'updatable';

      var validCreds = false;

      if (props.credtype === 'username')
         validCreds = currentUserName === (user_role === 'Student' ? loginCreds[0].username : loginCreds.username) && confirmUserName === newUserName;
      else
         validCreds = currentPassword === (user_role === 'Student' ? loginCreds[0].password : loginCreds.password) && confirmPassword === newPassword;

      // var validUsername = currentUserName === (user_role === 'Student' ? loginCreds[0].username : loginCreds.username) && (confirmUserName !== '' && newUserName !== '' && confirmUserName === newUserName);
      // var validPassword = currentPassword === (user_role === 'Student' ? loginCreds[0].password : loginCreds.password) && (confirmPassword !== '' && newPassword !== '' && confirmPassword === newPassword);

      if (validCreds) {
         props.onUpdatingCallBack();

         if (user_role !== 'Student' && user_role !== 'Parent') {
            let Data = {
               Userid: userID,
               Username: newUserName === '' ? loginCreds.username : newUserName,
               Password: newPassword === '' ? loginCreds.password : newPassword,
               Role: 'teacher',
            }

            var url = `${process.env.REACT_APP_API_MANAGEMENT}/updatestafflogincreds`;
            axios.post(url, Data).then(response => {
               console.log(response);
               if (response.data) {
                  const data = response.data;

                  setTimeout(() => {
                     message.success({ content: 'Update complete!', key, duration: 2 });
                     props.onSaveCompleteCallBack();
                  }, 1000);
               }
            }, (error) => {
               console.log(error.message);
               setTimeout(() => {
                  message.error({ content: 'Update failed!', key, duration: 2 });
               }, 1000);
            });
         } else {
            let Data = [];

            if (user_role === 'Student') {
               Data = [
                  {
                     Userid: userID,
                     Username: props.credtype === 'username' ? (newUserName === '' ? loginCreds[0].username : newUserName) : loginCreds[0].username,
                     Password: props.credtype === 'password' ? (newPassword === '' ? loginCreds[0].password : newPassword) : loginCreds[0].password,
                     Role: 'student',
                  },
                  {
                     Userid: userID,
                     Username: loginCreds[1].username,
                     Password: loginCreds[1].password,
                     Role: 'parent',
                  },
               ]
            }
            else {
               Data = [
                  {
                     Userid: userID,
                     Username: loginCreds[0].username,
                     Password: loginCreds[0].password,
                     Role: 'student',
                  },
                  {
                     Userid: userID,
                     Username: props.credtype === 'username' ? (newUserName === '' ? loginCreds[1].username : newUserName) : loginCreds[1].username,
                     Password: props.credtype === 'password' ? (newPassword === '' ? loginCreds[1].password : newPassword) : loginCreds[1].password,
                     Role: 'parent',
                  },
               ]
            }

            var url = `${process.env.REACT_APP_API_MANAGEMENT}/updatelogincredsall`;
            axios.post(url, Data).then(response => {
               console.log(response);
               if (response.data) {
                  const data = response.data;

                  setTimeout(() => {
                     message.success({ content: 'Update complete!', key, duration: 2 });
                     props.onSaveCompleteCallBack();
                  }, 1000);
               }
            }, (error) => {
               console.log(error.message);
               setTimeout(() => {
                  message.error({ content: 'Update failed!', key, duration: 2 });
               }, 1000);
            });
         }

      } else {
         if (props.credtype === 'username')
            message.success({ content: 'Please check if you enter the correct input for username.', key, duration: 2 });
         else
            message.success({ content: 'Please check if you enter the correct input for password.', key, duration: 2 });
         // props.onCancelCallback();
      }
   }

   const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };

   const handleCancel = () => {
      props.onCancelCallback();
   }

   return (
      <>
         {loginCreds !== null
            ?
            <Form
               form={form}
               layout={"vertical"}
               colon={false}
               onFinish={onFinish}
               onFinishFailed={onFinishFailed}
            >

               <Row gutter={12}>
                  {
                     props.credtype === 'username'
                        ?
                        <Col xs={24} md={24} lg={24}>
                           <Form.Item
                              name="old_username"
                              label={<Text ellipsis >Current Username</Text>}
                              rules={[{
                                 required: true,
                                 message: 'Enter current username'
                              }]}
                           >
                              <Input
                                 allowClear
                                 size='middle'
                                 placeholder="Enter Current Username"
                                 style={{ borderRadius: 7 }}
                                 onChange={(event) => setCurrentUserName(event.target.value)}
                              />
                           </Form.Item>
                        </Col>
                        :
                        <Col xs={24} md={24} lg={24}>
                           <Form.Item
                              name="old_password"
                              label={<Text ellipsis >Current Password</Text>}
                              rules={[{
                                 required: true,
                                 message: 'Enter current password'
                              }]}
                           >
                              <Input
                                 allowClear
                                 size='middle'
                                 placeholder="Enter Current Password"
                                 style={{ borderRadius: 7 }}
                                 onChange={(event) => setCurrentPassword(event.target.value)}
                              />
                           </Form.Item>
                        </Col>
                  }
               </Row>
               <Row gutter={12}>
                  {
                     props.credtype === 'username'
                        ?
                        <Col xs={24} md={24} lg={24}>
                           <Form.Item
                              name="new_username"
                              label={<Text ellipsis >New Username</Text>}
                              rules={[{
                                 required: true,
                                 message: 'Enter new username'
                              }]}
                           >
                              <Input
                                 allowClear
                                 size='middle'
                                 style={{ borderRadius: 7 }}
                                 placeholder="Enter New Username"
                                 onChange={(event) => setNewUserName(event.target.value)}
                              />
                           </Form.Item>
                        </Col>
                        :
                        <Col xs={24} md={24} lg={24}>
                           <Form.Item
                              name="new_password"
                              label={<Text ellipsis >New Password</Text>}
                              rules={[{
                                 required: true,
                                 message: 'Enter new password'
                              }]}
                           >
                              <Input
                                 allowClear
                                 size='middle'
                                 style={{ borderRadius: 7 }}
                                 placeholder="Enter New Password"
                                 onChange={(event) => setNewPassword(event.target.value)}
                              />
                           </Form.Item>
                        </Col>
                  }
               </Row>
               <Row gutter={12}>
                  {
                     props.credtype === 'username'
                        ?
                        <Col xs={24} md={24} lg={24}>
                           <Form.Item
                              name="confirm_username"
                              label={<Text ellipsis >Confirm User Name</Text>}
                              rules={[{
                                 required: true,
                                 message: 'Confirm username'
                              }]}
                           >
                              <Input
                                 allowClear
                                 size='middle'
                                 style={{ borderRadius: 7 }}
                                 placeholder="Confirm Username"
                                 onChange={(event) => setConfirmUserName(event.target.value)}
                              />
                           </Form.Item>
                        </Col>
                        :
                        <Col xs={24} md={24} lg={24}>
                           <Form.Item
                              name="confirm_password"
                              label={<Text ellipsis >Confirm Password</Text>}
                              rules={[{
                                 required: true,
                                 message: 'Confirm password'
                              }]}
                           >
                              <Input
                                 allowClear
                                 size='middle'
                                 style={{ borderRadius: 7 }}
                                 placeholder="Confirm Password"
                                 onChange={(event) => setConfirmPassword(event.target.value)}
                              />
                           </Form.Item>
                        </Col>
                  }
               </Row>

               <Row gutter={12}>
                  <Col xs={24} md={24} lg={24} style={{ textAlign: "right" }}>
                     <Form.Item style={{ marginBottom: '0px' }}>
                        <Space direction='horizontal' size={'small'}>
                           <Button shape='round' onClick={() => { handleCancel(); }}>Cancel</Button>
                           <Button type='primary' shape='round' htmlType='submit'>Save</Button>
                        </Space>
                     </Form.Item>
                  </Col>
               </Row>
            </Form>
            :
            <div className='temp-answer-sheet-content2' style={{ height: "380px" }}>
               <Spin size='large' tip="Loading, please wait..." />
            </div>
         }

      </>
   );
}