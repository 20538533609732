import { MasteryAssignmentModel } from "../models/MasteryAssignmentModel";

var empty_mastery_assignment = {
   id: null,
   title: null,
   mastery_assessment_id: null,
   assigned_by: null,
   start_date: null,
   end_date: null,
   term: null,
   allowed_attempts: null,
   passing_percentage: null,
   allow_result_viewing: null,
   enable_timer: null,
   subject: null,
   subject_desc: null,
   competency: null,
   assessment_type: null,
   status: null,
   teacher_intervene: null,
}

const update_mastery_assignment_data = (state, payload) =>
{
  const tmpState = state;
  
  return {...tmpState,[payload.key]: payload.value};
  
}

const set_mastery_assignment = (state, payload) =>
{
  const tmpState = payload;
  
  return tmpState;
  
}

const masteryAssignmentReducer = (state = MasteryAssignmentModel, action) => {
   switch(action.type){     
      case 'UPDATE-MASTERY-ASSIGNMENT-DATA':
         return update_mastery_assignment_data(state,action.payload);
      case 'SET-MASTERY-ASSIGNMENT':
         return set_mastery_assignment(state,action.payload);
      case 'RESET-MASTERY-ASSIGNMENT-DATA':
         return empty_mastery_assignment;
      default:
            return state
   }
}

export default masteryAssignmentReducer;