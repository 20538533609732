/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, createRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Typography, Tooltip, Button, message, Upload, Empty, Modal, Spin, Breadcrumb } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import './MasteryAssessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StickyBox from "react-sticky-box";
import axios from 'axios';
// import uuid from 'react-uuid';
import { useSelector, useDispatch } from 'react-redux';

// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import MasteryAssessmentToolsM from '../../components/assessment/MasteryAssessmentToolsM';
// import { CardRequestHelper } from './Helpers';
import { CardRequestHelper } from '../../helper/AQHelper';

// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import Split from 'react-split';
// import './SplitPanel.css';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';

import { update_mastery_data } from '../../actions';

// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { BrowserView, MobileView } from 'react-device-detect';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Text } = Typography;
var pdfFile = '';

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let tempAssOptions = null;
let defaultAssOptions = {
   id: '',
   assessment_id: '',
   assigned_by: '1',
   duration: '1',
   allowed_attempts: '1',
   passing_percentage: '50',
   allow_result_viewing: '0',
   enable_timer: '0',
   start_date: '',
   end_date: '',
   assigned_students: '',
   term: ''
}

export function MasteryEditPDF(props) {
   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const masteryrec = useSelector(state => state.masteryrec);
   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [answerSheetData, setAnswerSheetData] = useState(() => {
      var answer_sheet = JSON.parse(localStorage.getItem(user_id + "mastery_asd"));
      if (answer_sheet !== null) return answer_sheet;
      else return [];
   });
   const [questionSheetPDF, setQuestionSheetPDF] = useState("");
   const [showAssessmentSheet, setShowAssessmentSheet] = useState(true);
   const [assOptions, setAssOptions] = useState(() => {
      var assoptns = JSON.parse(localStorage.getItem(user_id + "mastery_options"));
      if (assoptns !== null) return assoptns;
      else return defaultAssOptions;
   });

   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: location.state.title,
      Level: "",
      Mastery_level: "",
      Subject: "",
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: "",
   });

   const [hasAnswers, setHasAnswers] = useState(false);

   // const subdomain = localStorage.getItem("lms-subdomain");
   const subdomain = "ccph";

   const [shouldScroll, setShouldScroll] = useState();
   const qtEndRef = createRef();

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin')
         navigate('/home');

      pdfFile = '';

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];

      checkIfHasAnswers(masteryrec.id);

      switch (location.state.type) {
         case "formative_1":
            setAnswerSheetData(JSON.parse(masteryrec.formative_1));

            if ((masteryrec.formative_1_file_url).includes('base64'))
               setQuestionSheetPDF(`${masteryrec.formative_1_file_url}`);
            else
               setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_1_file_url}`);

            break;
         case "formative_2":
            setAnswerSheetData(JSON.parse(masteryrec.formative_2));

            if ((masteryrec.formative_2_file_url).includes('base64'))
               setQuestionSheetPDF(`${masteryrec.formative_2_file_url}`);
            else
               setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_2_file_url}`);

            break;
         case "formative_3":
            setAnswerSheetData(JSON.parse(masteryrec.formative_3));

            if ((masteryrec.formative_3_file_url).includes('base64'))
               setQuestionSheetPDF(`${masteryrec.formative_3_file_url}`);
            else
               setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_3_file_url}`);

            break;
         case "summative":
            setAnswerSheetData(JSON.parse(masteryrec.summative));

            if ((masteryrec.summative_file_url).includes('base64'))
               setQuestionSheetPDF(`${masteryrec.summative_file_url}`);
            else
               setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.summative_file_url}`);

            break;
         default:
            setAnswerSheetData("");
            setQuestionSheetPDF("");
      }
   }, [masteryrec.id]);

   useEffect(() => {
      scrollToBottom();
   }, [shouldScroll]);

   const scrollToBottom = () => {
      qtEndRef.current?.scrollIntoView({ behavior: 'smooth' });
   }

   const checkIfHasAnswers = (assessment_id) => {
      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/hasanswers?assessment_id=${assessment_id}`;
      axios.get(url).then((response) => {
         // if (response.data) {
         var data = response.data;

         setHasAnswers(data);
         // getAssessmentInfo(assessment_id);
         // }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleOnDragEnd = (result) => {
      if (!result.destination) return;

      const items = Array.from(answerSheetData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setAnswerSheetData(items);

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const handleCardRequest = (method, type, data, idx) => {
      var asd = CardRequestHelper(method, type, data, idx, answerSheetData);

      //--Save to local storage in case something happens to the browser
      localStorage.removeItem(user_id + "mastery_asd");
      localStorage.removeItem(user_id + "mastery_options");

      // if (asd.length > 0) {
      //    localStorage.setItem(user_id + 'mastery_asd', JSON.stringify(asd));
      //    localStorage.setItem(user_id + 'mastery_options', JSON.stringify(assOptions));
      // }

      setAnswerSheetData(asd);

      if (method === 'add' || method === 'duplicate')
         setShouldScroll(asd);

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
   }

   const handleUpload = (file) => {
      // const key = 'updatable';
      // message.loading({ content: 'Uploading your document...', key, duration: 0 });

      // const formData = new FormData();
      // formData.append('file', file);


      // const url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMasteryFile?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}`;
      // axios.post(url, formData).then(response => {
      //    console.log(response);
      //    if (response.data) {
      //       const data = response.data;
      //       // var params = {key: location.state.type + '_file_url', value : `${process.env.REACT_APP_API_S3_URL}/${data}`};
      //       var params = { key: location.state.type + '_file_url', value: `${data}` };

      //       dispatch(update_mastery_data(params));

      //       setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${data}`);
      //    }

      //    setTimeout(() => {
      //       message.success({ content: 'File uploaded!', key, duration: 2 });
      //    }, 1000);
      // }, (error) => {
      //    console.log(error.message);
      //    // setTimeout(key, 100);
      //    setTimeout(() => {
      //       message.error({ content: 'File upload failed!', key, duration: 2 });
      //    }, 1000);
      // });

      const formData = new FormData();
      formData.append('file', file);

      Utils.getBase64(file, pdfb64 => {
         var params = { key: location.state.type + '_file_url', value: `${pdfb64}` };
         dispatch(update_mastery_data(params));
         setQuestionSheetPDF(pdfb64);
      });
   }

   const UploadPdf = () => {
      const props = {
         beforeUpload: file => {
            const isPDF = file.type === 'application/pdf';
            if (!isPDF) {
               message.error(`Only PDF files can be uploaded`);
            }
            //return isPDF || Upload.LIST_IGNORE;
            handleUpload(file);
         },
      };
      return (
         <Upload {...props} showUploadList={false} accept=".pdf">
            <Tooltip placement="left" title='Upload questionaire in PDF format'>
               <Button className='button-shadow' type="primary" size="middle" shape='round'>
                  <Text ellipsis style={{ color: "#fff" }}><CloudUploadOutlined />&nbsp;Upload Questionaire</Text>
               </Button>
            </Tooltip>
         </Upload>
      );
   };

   const handleSave = () => {
      if (answerSheetData.length > 0 && (questionSheetPDF !== '' || questionSheetPDF !== null)) {
         localStorage.removeItem(user_id + 'mastery_asd');
         localStorage.removeItem(user_id + 'mastery_options');

         var params = { key: location.state.type, value: JSON.stringify(answerSheetData) };
         dispatch(update_mastery_data(params));

         if (location.state.mode === 'create')
            navigate('/masteryassessment/create?qt=1');
         else
            navigate(`/masteryassessment/edit?id=${masteryrec.id}&subject=${location.state.subjectid}&subjectname=${location.state.subjectname}`);

      } else {
         Modal.info({
            title: 'Mastery Assessment',
            content: (
               <>
                  Please complete the folowing before saving.
                  <ul>
                     <li>
                        Upload questionaire
                     </li>
                     {/* <li>
                     Mastery Level
                  </li> */}
                     <li>
                        Answer keys
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   function sPDFFile() {
      pdfFile = questionSheetPDF;
      return true;
   }

   const docs = [
      // { uri: questionSheetPDF },
      {
         // uri: "../images/_images_pdf.pdf",
         // uri: `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${g_report_card_url}`,
         uri: questionSheetPDF,
         // fileType: 'pdf',
         fileName: location.state.title,
      },
   ];

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subjects</a></li>
                           {location.state.mode === 'edit'
                              ?
                              <>
                                 <li><span>/</span></li>
                                 <li><a href="#" onClick={() => navigate(`/masteryassessmentsubject?id=${location.state.subjectid}&name=${location.state.subjectname}`)}>Mastery Assessment ({location.state.subjectname})</a></li>
                              </>
                              :
                              <></>
                           }
                           <li><span>/</span></li>
                           {location.state.mode === 'create'
                              ?
                              <li><a href="#" onClick={() => navigate('/masteryassessment/create?qt=1')}>Create</a></li>
                              :
                              <li><a href="#" onClick={() => navigate(`/masteryassessment/edit?id=${masteryrec.id}&subject=${location.state.subjectid}&subjectname=${location.state.subjectname}`)}> Edit</a></li>
                           }

                           <li><span>/</span></li>
                           <li><a className="active">{location.state.title} (Edit)</a></li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subject</a>
                           </Breadcrumb.Item>
                           {
                              location.state.mode === 'edit'
                                 ?
                                 <>
                                    <Breadcrumb.Item>
                                       <a href="#" onClick={() => navigate(`/masteryassessmentsubject?id=${location.state.subjectid}&name=${location.state.subjectname}`)}>Mastery Assessment ({location.state.subjectname})</a>
                                    </Breadcrumb.Item>
                                 </>
                                 :
                                 <></>
                           }
                           {
                              location.state.mode === 'create'
                                 ?
                                 <Breadcrumb.Item>
                                    <a href="#" onClick={() => navigate('/masteryassessment/create?qt=2')}>Create</a>
                                 </Breadcrumb.Item>
                                 :
                                 <Breadcrumb.Item>
                                    <a href="#" onClick={() => navigate(`/masteryassessment/edit?id=${masteryrec.id}&subject=${location.state.subjectid}&subjectname=${location.state.subjectname}`)}> Edit</a>
                                 </Breadcrumb.Item>
                           }
                           <Breadcrumb.Item>{location.state.title} (Edit)</Breadcrumb.Item>
                        </Breadcrumb>
                     </div>

                     <div className="pdf-all-page-container" style={{ height: 'calc(100vh - 90px)' }}>
                        {/* <SimpleBarReact> */}
                        <StickyBox style={{ zIndex: 1000 }}>
                           <div style={{ textAlign: "right" }}>{UploadPdf()}</div>
                        </StickyBox>
                        {
                           questionSheetPDF === "" || questionSheetPDF === null
                              ?
                              <Empty description="You have not yet uploaded your questionaire" style={{ position: "relative" }} image="../images/brainee_mascot.png" />
                              :
                              <>
                                 <div style={{ width: "100%", height: "calc(100vh - 90px)" }}>
                                    <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                       <Viewer
                                          pageLayout={{
                                             transformSize: ({ size }) => ({
                                                height: size.height + 30,
                                                width: size.width + 30,
                                             }),
                                          }}
                                          fileUrl={questionSheetPDF}
                                          defaultScale={SpecialZoomLevel.PageWidth}
                                          initialPage={0}
                                       />
                                    </Worker>
                                 </div>
                                 {/* <BrowserView>
                                    <object data={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} width="100%" style={{ height: 'calc(100vh - 90px)' }}>
                                       <p>Brainee LMS PDF - View<a href={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`}> PDF!</a></p>
                                    </object>
                                 </BrowserView>
                                 <MobileView>
                                    <div style={{ width : "100%", height:"calc(100vh - 90px)" }}>
                                       <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                          <Viewer 
                                             pageLayout={{transformSize: ({ size }) => ({
                                                height: size.height + 30,
                                                width: size.width + 30,
                                             }),}}  
                                             fileUrl={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`}  
                                             defaultScale={SpecialZoomLevel.PageWidth}   
                                             initialPage={0} 
                                          />
                                       </Worker>
                                    </div>
                                 </MobileView> */}
                                 {/* <object data={questionSheetPDF} width="100%" style={{ height: 'calc(100vh - 90px)' }}>
                                    <p>Brainee LMS PDF - <a href={questionSheetPDF}> Show PDF</a></p>
                                 </object> */}
                              </>
                        }
                        {/* </SimpleBarReact> */}
                     </div>
                  </div>
               </div>

               <div>
                  <div className="notice-item">

                     {showAssessmentSheet && answerSheetData
                        ?
                        <>
                           <div className='answer-sheet-content'>
                              <SimpleBarReact>
                                 <StickyBox style={{ zIndex: 1000 }}>
                                    <MasteryAssessmentToolsM
                                       key={"createPDF"}
                                       data={assInfo}
                                       cardRequestCallback={handleCardRequest}
                                       saveCallback={handleSave}
                                       showToggle={true}
                                       mode={"create"}
                                       type={'pdf'}
                                    />
                                 </StickyBox>

                                 {hasAnswers //-- Disable drag & drop if already there are answers
                                    ?
                                    <>
                                       <ul className="questions" style={{ paddingLeft: "0px", paddingRight: "15px" }}>
                                          {answerSheetData.map(({ id, type, points, correct, option_labels, image_url }, idx) => {

                                             if (type === "section") {
                                                qcardIdx = 1;
                                                idxCol.push(sectionIdx);
                                                sectionIdx++;
                                             } else {
                                                idxCol.push(qcardIdx);
                                                qcardIdx++;
                                             }

                                             return (
                                                <li>
                                                   <BraineeQuestionCard
                                                      key={id}
                                                      index={idxCol[idx]}
                                                      id={idx}
                                                      type={type}
                                                      points={points}
                                                      correct={correct}
                                                      option_labels={option_labels}
                                                      image_url={image_url}
                                                      question_type={'1'}
                                                      mode={'edit'}
                                                      parentCallback={handleCardRequest}
                                                      card_bg={location.state.card_bg}
                                                      assessment_type={'mastery'}
                                                   />
                                                </li>
                                             );
                                          })}
                                       </ul>
                                    </>
                                    :
                                    <>
                                       <DragDropContext onDragEnd={(e) => handleOnDragEnd(e)}>
                                          <Droppable droppableId="questions">
                                             {(provided) => (
                                                <ul className="questions" {...provided.droppableProps} ref={provided.innerRef} style={{ paddingRight: "15px" }}>
                                                   {answerSheetData.map(({ id, type, points, correct, option_labels, image_url }, idx) => {

                                                      if (type === "section") {
                                                         qcardIdx = 1;
                                                         idxCol.push(sectionIdx);
                                                         sectionIdx++;
                                                      } else {
                                                         idxCol.push(qcardIdx);
                                                         qcardIdx++;
                                                      }

                                                      return (
                                                         <Draggable key={id} draggableId={id} index={idx}>
                                                            {(provided) => (
                                                               <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                  <BraineeQuestionCard
                                                                     key={id}
                                                                     index={idxCol[idx]}
                                                                     id={idx}
                                                                     type={type}
                                                                     points={points}
                                                                     correct={correct}
                                                                     option_labels={option_labels}
                                                                     image_url={image_url}
                                                                     question_type={'1'}
                                                                     mode={'edit'}
                                                                     parentCallback={handleCardRequest}
                                                                     card_bg={location.state.card_bg}
                                                                     assessment_type={'mastery'}
                                                                  />
                                                               </li>
                                                            )}
                                                         </Draggable>
                                                      );
                                                   })}
                                                   {provided.placeholder}
                                                </ul>
                                             )}
                                          </Droppable>
                                       </DragDropContext>
                                    </>
                                 }

                                 <div ref={qtEndRef} />
                              </SimpleBarReact>
                           </div>
                        </>
                        :
                        <div className='temp-answer-sheet-content2'>
                           <Spin size='large' tip="Loading, please wait..." />
                        </div>
                     }
                  </div>
               </div>
            </Split >
         </div >
      </>
   )
}
