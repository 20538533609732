export const ThirdPartyItems = [
    {
      icon: '/images/thirdparty/Kahoot.png',
      name: 'Kahoot!',
      url : "https://kahoot.it"
    },
    {
      icon: '/images/thirdparty/Mentimeter.png',
      name: 'Mentimeter',
      url : "https://www.mentimeter.com"
    },  
    {
      icon: '/images/thirdparty/GoogleForms.png',
      name: 'GoogleForms',
      url : "https://workspace.google.com/intl/en_ph"
    },   
    {
        icon: '/images/thirdparty/Canva.png',
        name: 'Canva',
        url : "https://www.canva.com"
    },       
    {
        icon: '/images/thirdparty/Quizziz.png',
        name: 'Quizziz',
        url : "https://quizizz.com/?lng=en"
    },   
    {
        icon: '/images/thirdparty/AntConc.png',
        name: 'Ant Conc',
        url : "https://www.laurenceanthony.net/software/antconc"
    },    
    {
        icon: '/images/thirdparty/Padlet.png',
        name: 'Padlet',
        url : "https://padlet.com"
    },     
    {
        icon: '/images/thirdparty/Equatio.png',
        name: 'Equatio',
        url : "https://www.texthelp.com/products/equatio"
    },                          
  ]
  