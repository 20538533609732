

// import React, { useState } from "react";
// import SimpleBarReact from "simplebar-react";
// import { PDFReader } from 'reactjs-pdf-reader';
// import { Spin } from 'antd';

 
// function RenderPDFViewer(props) {

//    const [pdfLoaded, setPdfLoaded] = useState(false);

//    return (
//       <>
//          <div className="pdf-all-page-container" style={{ height: props.height }}>
//             <div className='pdf_container' style={{ height: props.height }}>
//                <SimpleBarReact style={{ height: '100%' }}>
//                   <PDFReader
//                      url={props.url}
//                      showAllPage={true}
//                      // scale={1}
//                      // width="100%"
//                      onDocumentComplete={() => setPdfLoaded(true)}
//                   />
//                </SimpleBarReact>
//             </div>

//             {/* <div className='pdf_container pdf_overlay' style={{ display: pdfLoaded ? 'none' : '', height: props.height }}> */}
//             <div className='container_loading pdf_overlay' style={{ display: pdfLoaded ? 'none' : '', height: props.height }}>
//                <Spin size='large' tip="Loading, please wait..." />
//             </div>
//             {/* </div> */}
//          </div>
//       </>
//    );
// };

import React from "react";
import ViewSDKClient from "./ViewSDKClient";

const RenderPDFViewer = ({ url, height, load = true, showdownload = true, showprint = false, showannotations = false, showpagecontrols = false, showfullscreen = false }) => {
   const loadPDF = () => {
      if (load) {
         const viewSDKClient = new ViewSDKClient();

         viewSDKClient.ready().then(() => {
            viewSDKClient.previewFile(
               "pdf-div",
               {
                  defaultViewMode: "FIT_WIDTH",
                  showAnnotationTools: showannotations,
                  showLeftHandPanel: false,
                  showPageControls: showpagecontrols,
                  showDownloadPDF: showdownload,
                  showPrintPDF: showprint,
                  showFullScreen: showfullscreen,
                  showZoomControl: true
               },
               url
            );
         });
      }

   };

   return (
      <div id="pdf-div" className="border border-gray-100" onDocumentLoad={loadPDF()} style={{ height, borderRadius: "7px" }} ></div>
   );
};
export default RenderPDFViewer;