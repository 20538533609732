/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import {
   Row, Col, Empty, Spin
} from 'antd';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { IntroSum } from '../components';

export function Grade9SummaryReport(props) {
   const [reportData, setReportData] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   const [vocabulary, setVocabulary] = useState(null);
   const [literature, setLiterature] = useState(null);
   const [readingComprehension, setReadingComprehension] = useState(null);
   const [grammar, setGrammar] = useState(null);
   const [viewingComprehension, setViewingComprehension] = useState(null);
   const [writing, setWriting] = useState(null);
   const [englishTotal, setEnglishTotal] = useState(null);

   const [geometry, setGeometry] = useState(null);
   const [patternsAndAlgebra, setPatternsAndAlgebra] = useState(null);
   const [mathTotal, setMathTotal] = useState(null);

   const [matter, setMatter] = useState(null);
   const [livingThings, setLivingThings] = useState(null);
   const [forceAndMotion, setForceAndMotion] = useState(null);
   const [earthAndSpace, setEarthAndSpace] = useState(null);
   const [scienceTotal, setScienceTotal] = useState(null);

   const [englishPCData, setEnglishPCData] = useState(null);
   const [mathPCData, setMathPCData] = useState(null);
   const [sciencePCData, setSciencePCData] = useState(null);
   const [VN, setVN] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();

      getReport(props.assignment_id, props.section_id);
   }, [props.assignment_id]);

   const getReport = (assignment_id, section_id) => {
      setDataLoading(true);

      var url = `${process.env.REACT_APP_API_CPH}/getdiagnosticreportdata?aid=${assignment_id}&sid=${section_id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            //-- English
            if (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') {
               var englishPreData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'pre');
               var englishPostData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'post');
               setVN(englishPreData.length);
            }

            //-- Math
            if (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') {
               var mathPreData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'pre');
               var mathPostData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'post');
               setVN(mathPreData.length);
            }

            //-- Science
            if (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') {
               var sciencePreData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'pre');
               var sciencePostData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'post');
               setVN(sciencePreData.length);
            }

            url = `${process.env.REACT_APP_API_CPH}/getdiagnosticsummarypercentdata?aid=${assignment_id}&sid=${section_id}`;
            axios.get(url).then((response) => {

               if (response.data) {
                  const data2 = response.data;

                  if (props.subject_id === null || props.subject_id === 'ems') {
                     setEnglishPCData(data2[0]);
                     extractDataForEnglish(englishPreData, englishPostData);

                     setMathPCData(data2[1]);
                     extractDataForMath(mathPreData, mathPostData);

                     setSciencePCData(data2[2]);
                     extractDataForScience(sciencePreData, sciencePostData);
                  } else {
                     if (props.subject_id === 'english') {
                        setEnglishPCData(data2[0]);
                        extractDataForEnglish(englishPreData, englishPostData);
                     }

                     if (props.subject_id === 'math') {
                        setMathPCData(data2[0]);
                        extractDataForMath(mathPreData, mathPostData);
                     }

                     if (props.subject_id === 'science') {
                        setSciencePCData(data2[0]);
                        extractDataForScience(sciencePreData, sciencePostData);
                     }
                  }

                  setReportData(data);
                  setDataLoading(false);
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const extractDataForEnglish = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _readingComprehension = {};
      var _viewingComprehension = {};
      var _vocabulary = {};
      var _literature = {};
      var _writing = {};
      var _grammar = {};

      var _total = {};
      var _proficiency = '';

      //-- Reading Comprehension (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.75) / 0.5) > 1)
            _competent++;
         else if (((_sumVal - 1.75) / 0.5) > -0.51)
            _basic++;
         else if (((_sumVal - 1.75) / 0.5) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.75) / 0.5) > 1)
                  _competent++;
               else if (((_sumVal - 1.75) / 0.5) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.75) / 0.5) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.49 ? 'Beginner' : (_postAve >= 1.5 && _postAve <= 2.25 ? 'Basic' : (_postAve >= 2.26 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.49 ? 'Beginner' : (_preAve >= 1.5 && _preAve <= 2.25 ? 'Basic' : (_preAve >= 2.26 && _preAve <= 4 ? 'Competent' : ''));
      }

      _readingComprehension = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Reading Comprehension (end)

      //-- Viewing Comprehension (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 3.75) / 0.5) > 1)
            _competent++;
         else if (((_sumVal - 3.75) / 0.5) > -0.51)
            _basic++;
         else if (((_sumVal - 3.75) / 0.5) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 3.75) / 0.5) > 1)
                  _competent++;
               else if (((_sumVal - 3.75) / 0.5) > -0.51)
                  _basic++;
               else if (((_sumVal - 3.75) / 0.5) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.49 ? 'Beginner' : (_postAve >= 3.5 && _postAve <= 4.25 ? 'Basic' : (_postAve >= 4.26 && _postAve <= 5 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.49 ? 'Beginner' : (_preAve >= 3.5 && _preAve <= 4.25 ? 'Basic' : (_preAve >= 4.26 && _preAve <= 5 ? 'Competent' : ''));
      }

      _viewingComprehension = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Viewing Comprehension (end)      

      //-- Vocabulary (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 7) / 2.83) > 1)
            _competent++;
         else if (((_sumVal - 7) / 2.83) > -0.51)
            _basic++;
         else if (((_sumVal - 7) / 2.83) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 7) / 2.83) > 1)
                  _competent++;
               else if (((_sumVal - 7) / 2.83) > -0.51)
                  _basic++;
               else if (((_sumVal - 7) / 2.83) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 5.58 ? 'Beginner' : (_postAve >= 5.59 && _postAve <= 9.83 ? 'Basic' : (_postAve >= 9.84 && _postAve <= 11 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 5.58 ? 'Beginner' : (_preAve >= 5.59 && _preAve <= 9.83 ? 'Basic' : (_preAve >= 9.84 && _preAve <= 11 ? 'Competent' : ''));
      }

      _vocabulary = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Vocabulary (end)

      //-- Literature (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.2) / 1.41) > 1)
            _competent++;
         else if (((_sumVal - 2.2) / 1.41) > -0.51)
            _basic++;
         else if (((_sumVal - 2.2) / 1.41) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.2) / 1.41) > 1)
                  _competent++;
               else if (((_sumVal - 2.2) / 1.41) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.2) / 1.41) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.49 ? 'Beginner' : (_postAve >= 1.5 && _postAve <= 3.61 ? 'Basic' : (_postAve >= 3.62 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.49 ? 'Beginner' : (_preAve >= 1.5 && _preAve <= 3.61 ? 'Basic' : (_preAve >= 3.62 && _preAve <= 4 ? 'Competent' : ''));
      }

      _literature = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Literature (end)

      //-- Writing (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s25 + pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.5) / 1) > 1)
            _competent++;
         else if (((_sumVal - 1.5) / 1) > -0.51)
            _basic++;
         else if (((_sumVal - 1.5) / 1) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s25 + post_data[i].s26 + post_data[i].s27 + post_data[i].s28;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.5) / 1) > 1)
                  _competent++;
               else if (((_sumVal - 1.5) / 1) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.5) / 1) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 0.99 ? 'Beginner' : (_postAve >= 1 && _postAve <= 2.5 ? 'Basic' : (_postAve >= 2.51 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 0.99 ? 'Beginner' : (_preAve >= 1 && _preAve <= 2.5 ? 'Basic' : (_preAve >= 2.51 && _preAve <= 4 ? 'Competent' : ''));
      }

      _writing = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Writing (end)    

      //-- Grammar (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 +
            pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 +
            pre_data[i].s45 + pre_data[i].s46 + pre_data[i].s47 + pre_data[i].s48 + pre_data[i].s49 + pre_data[i].s50;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 15) / 3.37) > 1)
            _competent++;
         else if (((_sumVal - 15) / 3.37) > -0.51)
            _basic++;
         else if (((_sumVal - 15) / 3.37) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34 + post_data[i].s35 + post_data[i].s36 +
               post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40 + post_data[i].s41 + post_data[i].s42 + post_data[i].s43 + post_data[i].s44 +
               post_data[i].s45 + post_data[i].s46 + post_data[i].s47 + post_data[i].s48 + post_data[i].s49 + post_data[i].s50;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 15) / 3.37) > 1)
                  _competent++;
               else if (((_sumVal - 15) / 3.37) > -0.51)
                  _basic++;
               else if (((_sumVal - 15) / 3.37) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 13.3 ? 'Beginner' : (_postAve >= 13.31 && _postAve <= 18.4 ? 'Basic' : (_postAve >= 18.41 && _postAve <= 22 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 13.3 ? 'Beginner' : (_preAve >= 13.31 && _preAve <= 18.4 ? 'Basic' : (_preAve >= 18.41 && _preAve <= 22 ? 'Competent' : ''));
      }

      _grammar = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Grammar (end)     

      _total = {
         vn: pre_data.length,
         prem: _vocabulary.prem + _literature.prem + _readingComprehension.prem + _grammar.prem + _viewingComprehension.prem + _writing.prem,
         postm: _vocabulary.postm + _literature.postm + _readingComprehension.postm + _grammar.postm + _viewingComprehension.postm + _writing.postm,
         min: _vocabulary.min + _literature.min + _readingComprehension.min + _grammar.min + _viewingComprehension.min + _writing.min,
         max: _vocabulary.max + _literature.max + _readingComprehension.max + _grammar.max + _viewingComprehension.max + _writing.max,
         sd: _vocabulary.sd + _literature.sd + _readingComprehension.sd + _grammar.sd + _viewingComprehension.sd + _writing.sd,
         proficiency: _proficiency,
         beginner: _vocabulary.beginner + _literature.beginner + _readingComprehension.beginner + _grammar.beginner + _viewingComprehension.beginner + _writing.beginner,
         basic: _vocabulary.basic + _literature.basic + _readingComprehension.basic + _grammar.basic + _viewingComprehension.basic + _writing.basic,
         competent: _vocabulary.competent + _literature.competent + _readingComprehension.competent + _grammar.competent + _viewingComprehension.competent + _writing.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 24.6 ? 'Beginner' : (_total.postm >= 24.61 && _total.postm <= 33.3 ? 'Basic' : (_total.postm >= 33.31 && _total.postm <= 50 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 24.6 ? 'Beginner' : (_total.prem >= 24.61 && _total.prem <= 33.3 ? 'Basic' : (_total.prem >= 33.31 && _total.prem <= 50 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setEnglishTotal(_total);
      setViewingComprehension(_viewingComprehension);
      setVocabulary(_vocabulary);
      setLiterature(_literature);
      setReadingComprehension(_readingComprehension);
      setWriting(_writing);
      setGrammar(_grammar);
   }

   const extractDataForMath = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _algebra = {};
      var _geometry = {};
      var _proficiency = '';
      var _total = 0;

      //-- Algebra (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 +
            pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 +
            pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 +
            pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 18) / 2.16) > 1)
            _competent++;
         else if (((_sumVal - 18) / 2.16) > -0.51)
            _basic++;
         else if (((_sumVal - 18) / 2.16) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 +
               post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 +
               post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 + post_data[i].s27 + post_data[i].s28 + post_data[i].s29 +
               post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 18) / 2.16) > 1)
                  _competent++;
               else if (((_sumVal - 18) / 2.16) > -0.51)
                  _basic++;
               else if (((_sumVal - 18) / 2.16) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 16.9 ? 'Beginner' : (_postAve >= 16.91 && _postAve <= 20.2 ? 'Basic' : (_postAve >= 20.21 && _postAve <= 34 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 16.9 ? 'Beginner' : (_preAve >= 16.91 && _preAve <= 20.2 ? 'Basic' : (_preAve >= 20.21 && _preAve <= 34 ? 'Competent' : ''));
      }

      _algebra = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //--Algebra (end)

      //-- Geometry (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 +
            pre_data[i].s45 + pre_data[i].s46 + pre_data[i].s47 + pre_data[i].s48 + pre_data[i].s49 + pre_data[i].s50 + pre_data[i].s51 + pre_data[i].s52 + pre_data[i].s53 + pre_data[i].s54 + pre_data[i].s55 +
            pre_data[i].s56 + pre_data[i].s57 + pre_data[i].s58 + pre_data[i].s59 + pre_data[i].s60;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 10.5) / 2.38) > 1)
            _competent++;
         else if (((_sumVal - 10.5) / 2.38) > -0.51)
            _basic++;
         else if (((_sumVal - 10.5) / 2.38) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s35 + post_data[i].s36 + post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40 + post_data[i].s41 + post_data[i].s42 + post_data[i].s43 + post_data[i].s44 +
               post_data[i].s45 + post_data[i].s46 + post_data[i].s47 + post_data[i].s48 + post_data[i].s49 + post_data[i].s50 + post_data[i].s51 + post_data[i].s52 + post_data[i].s53 + post_data[i].s54 + post_data[i].s55 +
               post_data[i].s56 + post_data[i].s57 + post_data[i].s58 + post_data[i].s59 + post_data[i].s60;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 10.5) / 2.38) > 1)
                  _competent++;
               else if (((_sumVal - 10.5) / 2.38) > -0.51)
                  _basic++;
               else if (((_sumVal - 10.5) / 2.38) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 4.99 ? 'Beginner' : (_postAve >= 5 && _postAve <= 7.25 ? 'Basic' : (_postAve >= 7.26 && _postAve <= 12 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 4.99 ? 'Beginner' : (_preAve >= 5 && _preAve <= 7.25 ? 'Basic' : (_preAve >= 7.26 && _preAve <= 12 ? 'Competent' : ''));
      }

      _geometry = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Geometry (end)

      _total = {
         vn: pre_data.length,
         prem: _geometry.prem + _algebra.prem,
         postm: _geometry.postm + _algebra.postm,
         min: _geometry.min + _algebra.min,
         max: _geometry.max + _algebra.max,
         sd: _geometry.sd + _algebra.sd,
         proficiency: _proficiency,
         beginner: _geometry.beginner + _algebra.beginner,
         basic: _geometry.basic + _algebra.basic,
         competent: _geometry.competent + _algebra.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 9.3 ? 'Beginner' : (_total.postm >= 9.31 && _total.postm <= 12.9 ? 'Basic' : (_total.postm >= 12.91 && _total.postm <= 26 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 9.3 ? 'Beginner' : (_total.prem >= 9.31 && _total.prem <= 12.9 ? 'Basic' : (_total.prem >= 12.91 && _total.prem <= 26 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setMathTotal(_total);
      setGeometry(_geometry);
      setPatternsAndAlgebra(_algebra);
   }

   const extractDataForScience = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;


      var _livingthings = {};
      var _matter = {};
      var _earthandspace = {};
      var _forceandmotion = {};
      var _proficiency = '';
      var _total = 0;

      //-- Living Things (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 +
            pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 5) / 2.16) > 1)
            _competent++;
         else if (((_sumVal - 5) / 2.16) > -0.51)
            _basic++;
         else if (((_sumVal - 5) / 2.16) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 +
               post_data[i].s9 + post_data[i].s10 + post_data[i].s11;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 5) / 2.16) > 1)
                  _competent++;
               else if (((_sumVal - 5) / 2.16) > -0.51)
                  _basic++;
               else if (((_sumVal - 5) / 2.16) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.91 ? 'Beginner' : (_postAve >= 3.92 && _postAve <= 7.16 ? 'Basic' : (_postAve >= 7.17 && _postAve <= 11 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.91 ? 'Beginner' : (_preAve >= 3.92 && _preAve <= 7.16 ? 'Basic' : (_preAve >= 7.17 && _preAve <= 11 ? 'Competent' : ''));
      }

      _forceandmotion = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Living Things (end)

      //-- Matter (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 +
            pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 9.5) / 1.29) > 1)
            _competent++;
         else if (((_sumVal - 9.5) / 1.29) > -0.51)
            _basic++;
         else if (((_sumVal - 9.5) / 1.29) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 +
               post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 9.5) / 1.29) > 1)
                  _competent++;
               else if (((_sumVal - 9.5) / 1.29) > -0.51)
                  _basic++;
               else if (((_sumVal - 9.5) / 1.29) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 8.7 ? 'Beginner' : (_postAve >= 8.71 && _postAve <= 11.1 ? 'Basic' : (_postAve >= 11.11 && _postAve <= 15 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 8.7 ? 'Beginner' : (_preAve >= 8.71 && _preAve <= 11.1 ? 'Basic' : (_preAve >= 11.11 && _preAve <= 15 ? 'Competent' : ''));
      }

      _matter = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Matter (end)

      //-- Earth and Space (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35 +
            pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 9.25) / 1.71) > 1)
            _competent++;
         else if (((_sumVal - 9.25) / 1.71) > -0.51)
            _basic++;
         else if (((_sumVal - 9.25) / 1.71) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34 + post_data[i].s35 +
               post_data[i].s36 + post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40 + post_data[i].s41;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 9.25) / 1.71) > 1)
                  _competent++;
               else if (((_sumVal - 9.25) / 1.71) > -0.51)
                  _basic++;
               else if (((_sumVal - 9.25) / 1.71) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 8.39 ? 'Beginner' : (_postAve >= 8.40 && _postAve <= 10.96 ? 'Basic' : (_postAve >= 10.97 && _postAve <= 15 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 8.39 ? 'Beginner' : (_preAve >= 8.40 && _preAve <= 10.96 ? 'Basic' : (_preAve >= 10.97 && _preAve <= 15 ? 'Competent' : ''));
      }

      _earthandspace = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Earth and Space (end)

      //-- Force and Motion (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 + pre_data[i].s45 + pre_data[i].s46 + pre_data[i].s47 + pre_data[i].s48 + pre_data[i].s49 +
            pre_data[i].s50 + pre_data[i].s51 + pre_data[i].s52 + pre_data[i].s53 + pre_data[i].s54 + pre_data[i].s55 + pre_data[i].s56 + pre_data[i].s57 + pre_data[i].s58 + pre_data[i].s59 + pre_data[i].s60;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 7) / 1.41) > 1)
            _competent++;
         else if (((_sumVal - 7) / 1.41) > -0.51)
            _basic++;
         else if (((_sumVal - 7) / 1.41) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s42 + post_data[i].s43 + post_data[i].s44 + post_data[i].s45 + post_data[i].s46 + post_data[i].s47 + post_data[i].s48 + post_data[i].s49 +
               post_data[i].s50 + post_data[i].s51 + post_data[i].s52 + post_data[i].s53 + post_data[i].s54 + post_data[i].s55 + post_data[i].s56 + post_data[i].s57 + post_data[i].s58 + post_data[i].s59 + post_data[i].s60;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 7) / 1.41) > 1)
                  _competent++;
               else if (((_sumVal - 7) / 1.41) > -0.51)
                  _basic++;
               else if (((_sumVal - 7) / 1.41) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 6.29 ? 'Beginner' : (_postAve >= 6.30 && _postAve <= 8.41 ? 'Basic' : (_postAve >= 8.42 && _postAve <= 19 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 6.29 ? 'Beginner' : (_preAve >= 6.30 && _preAve <= 8.41 ? 'Basic' : (_preAve >= 8.42 && _preAve <= 19 ? 'Competent' : ''));
      }

      _livingthings = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Force and Motion (end)      

      _total = {
         vn: 18,
         prem: _matter.prem + _livingthings.prem + _forceandmotion.prem + _earthandspace.prem,
         postm: _matter.postm + _livingthings.postm + _forceandmotion.postm + _earthandspace.postm,
         min: _matter.min + _livingthings.min + _forceandmotion.min + _earthandspace.min,
         max: _matter.max + _livingthings.max + _forceandmotion.max + _earthandspace.max,
         sd: _matter.sd + _livingthings.sd + _forceandmotion.sd + _earthandspace.sd,
         proficiency: _proficiency,
         beginner: _matter.beginner + _livingthings.beginner + _forceandmotion.beginner + _earthandspace.beginner,
         basic: _matter.basic + _livingthings.basic + _forceandmotion.basic + _earthandspace.basic,
         competent: _matter.competent + _livingthings.competent + _forceandmotion.competent + _earthandspace.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 27.4 ? 'Beginner' : (_total.postm >= 27.41 && _total.postm <= 33.6 ? 'Basic' : (_total.postm >= 33.61 && _total.postm <= 60 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 27.4 ? 'Beginner' : (_total.prem >= 27.41 && _total.prem <= 33.6 ? 'Basic' : (_total.prem >= 33.61 && _total.prem <= 60 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setScienceTotal(_total);
      setMatter(_matter);
      setLivingThings(_livingthings);
      setForceAndMotion(_forceandmotion);
      setEarthAndSpace(_earthandspace);
   }

   const generateMeanLevelRow = (label, data, istotal) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               {
                  istotal
                     ?
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
                     :
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; {label}</p>
               }

            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.vn}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{(data.prem).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.postm < 0 ? '' : (data.postm).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.min}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.max}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{(data.sd).toFixed(2)}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.proficiency}</p>
            </td>
         </tr>
      );
   }

   const generateFrequencyRow = (label, data, index) => {
      return (
         <>
            <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>{index}. Frequency of Proficiency Levels in {label}</strong></p>
            <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
               <tbody>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Count</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent</span></strong></p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Beginner</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.beginner}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'beginner')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Basic&nbsp;</strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.basic}</p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'basic')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Competent&nbsp;</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.competent}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'competent')}</p>
                     </td>
                  </tr>
               </tbody>
            </table>
         </>
      );
   }

   const generatePercentCorrectRow = (label, predata, postdata, index) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>{index}</strong></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>{label}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{predata !== null ? predata.toFixed(2) + '%' : ''}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}>{postdata !== null ? postdata.toFixed(2) + '%' : ''}</p>
            </td>
         </tr>
      );
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               (reportData !== null && reportData.length > 0)
                  ?
                  <div className="dashboard-item-inner3" style={{ padding: '7px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='summary-report'>
                        <div>

                           <IntroSum
                              grade_level={props.template}
                              section={props.section_desc}
                              school_name={reportData[0].school_name}
                              subject_id={props.subject_id}
                              subject={props.subject}
                              backgroundColor="#000000"
                              diagnostic_date={reportData[0].diagnostic_date}
                              diagnostic_type={reportData[0].diagnostic_type}
                           />

                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>SUMMARY OF RESULTS FOR {props.template}</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>MEAN LEVEL OF SCORES IN THE SUBJECT AREAS</strong></p>
                           <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                              <tbody>
                                 <tr>
                                    <td width="26%" nowrap style={{ width: '26.82%', borderTop: 'solid black 1.0pt', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }} />
                                    <td width="5%" nowrap style={{ width: '5.26%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>N</span></b></p>
                                    </td>
                                    <td width="15%" nowrap style={{ width: '15.56%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Pretest Mean</span></b></p>
                                    </td>
                                    <td width="16%" style={{ width: '16.7%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Post Test Mean</span></b></p>
                                    </td>
                                    <td width="6%" nowrap style={{ width: '6.22%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Min</span></b></p>
                                    </td>
                                    <td width="6%" nowrap style={{ width: '6.62%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Max</span></b></p>
                                    </td>
                                    <td width="4%" nowrap style={{ width: '4.92%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>SD</span></b></p>
                                    </td>
                                    <td width="17%" style={{ width: '17.9%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Proficiency Level</span></b></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Reading Comprehension', readingComprehension, false)}
                                       {generateMeanLevelRow('Viewing Comprehension', viewingComprehension, false)}
                                       {generateMeanLevelRow('Vocabulary', vocabulary, false)}
                                       {generateMeanLevelRow('Literature', literature, false)}
                                       {generateMeanLevelRow('Writing Composition', writing, false)}
                                       {generateMeanLevelRow('Grammar', grammar, false)}
                                       {generateMeanLevelRow('ENGLISH', englishTotal, true)}
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Algebra', patternsAndAlgebra, false)}
                                       {generateMeanLevelRow('Geometry', geometry, false)}
                                       {generateMeanLevelRow('MATH', mathTotal, true)}
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Living Things and their Environment', livingThings, false)}
                                       {generateMeanLevelRow('Matter', matter, false)}
                                       {generateMeanLevelRow('Earth and Space', earthAndSpace, false)}
                                       {generateMeanLevelRow('Force and Motion', forceAndMotion, false)}
                                       {generateMeanLevelRow('SCIENCE', scienceTotal, true)}
                                    </>
                                 }
                              </tbody>
                           </table>
                           <p style={{ marginBottom: '0in' }}><span lang="EN-US">&nbsp;</span></p>
                           <p style={{ marginBottom: '0in' }}><a name="_Hlk139735254"><b><span lang="EN-US">INTERPRETATION</span></b></a></p>
                           <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                              <tbody>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td width="35%" nowrap style={{ width: '35.24%', borderTop: 'solid black 1.0pt', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="17%" nowrap colSpan={3} style={{ width: '17.14%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>BEGINNER</span></b></p>
                                    </td>
                                    <td width="3%" nowrap style={{ width: '3.12%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="21%" nowrap colSpan={3} style={{ width: '21.08%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>BASIC</span></b></p>
                                    </td>
                                    <td width="3%" nowrap style={{ width: '3.12%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="20%" nowrap colSpan={3} style={{ width: '20.28%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>COMPETENT</span></b></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Reading Comprehension</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>1.49</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>1.5</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.25</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.26</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>4</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Viewing Comprehension</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.49</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.5</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>4.25</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>4.26</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>5</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Vocabulary</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>5.58</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>5.59</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>9.83</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>9.84</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>11</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Literature</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>1.49</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>1.5</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.61</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.62</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>4</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Writing Composition</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0.99</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>1</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.5</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.51</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>4</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Grammar</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>13.3</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>13.3</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>18.4</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>18.41</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>22</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>ENGLISH</span></b></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>24.6</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>24.6</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>33.3</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>33.31</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>50</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="100%" nowrap colSpan={12} style={{ width: '100.0%', borderTop: 'none', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Patterns and Algebra</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>16.9</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>16.91</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>20.2</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>20.17</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>34</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Geometry</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>9.3</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>9.31</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>12.9</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>12.91</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>26</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>MATH</span></b></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>28.5</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>28.51</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>31.6</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>31.61</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>60</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="100%" nowrap colSpan={12} style={{ width: '100.0%', borderTop: 'none', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Living Things</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.91</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.92</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.16</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.17</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>11</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Matter</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>8.7</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>8.71</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>11.1</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>11.11</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>15</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Earth and Space</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>8.39</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>8.4</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>10.96</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>10.97</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>15</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Force and Motion</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.29</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.3</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>8.41</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>8.42</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>19</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="35%" nowrap style={{ width: '35.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>SCIENCE</span></b></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>27.4</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>27.41</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>33.6</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="10%" nowrap style={{ width: '10.1%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>33.62</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.0%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.2%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>60</span></p>
                                          </td>
                                       </tr>
                                    </>
                                 }
                              </tbody>
                           </table>

                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 {generateFrequencyRow('Reading Comprehension', readingComprehension, 1)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Viewing Comprehension', viewingComprehension, 2)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Vocabulary', vocabulary, 3)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Literature', literature, 4)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Writing Composition', writing, 5)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Grammar', grammar, 6)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('ENGLISH', englishTotal, 7)}
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Algebra', patternsAndAlgebra, 8)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Geometry', geometry, 9)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('MATH', mathTotal, 10)}
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Living Things and their Environment', livingThings, 11)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Matter', matter, 12)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Earth and Space', earthAndSpace, 13)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Force and Motion', forceAndMotion, 14)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('SCIENCE', scienceTotal, 15)}
                              </>
                           }

                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percentage of Correct Answers in the English Learning Competencies</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody><tr>
                                       <td width="63%" colSpan={2} rowSpan={2} style={{ width: '63.28%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                       </td>
                                       <td width="18%" style={{ width: '18.58%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>PRETEST</span></b></p>
                                       </td>
                                       <td width="18%" valign="top" style={{ width: '18.14%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>POST TEST</span></b></p>
                                       </td>
                                    </tr>
                                       <tr>
                                          <td width="18%" style={{ width: '18.58%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.14%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Reading Comprehension</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.14%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Explain the literary devices used.', englishPCData.pre_s1, englishPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Determine tone, mood, technique, and purpose of the author.', englishPCData.pre_s2, englishPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Relate text content to particular social issues, concerns, or dispositions in real life.', englishPCData.pre_s3, englishPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Judge the relevance and worth of ideas, soundness of author’s reasoning, and the effectiveness of the presentation.', englishPCData.pre_s4, englishPCData.post_s4, 4)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Viewing Comprehension</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.14%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Infer thoughts, feelings, and intentions in the material viewed.', englishPCData.pre_s5, englishPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Assess the relevance and worth of ideas presented in the material viewed.', englishPCData.pre_s6, englishPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Draw generalizations and conclusions from the material viewed.', englishPCData.pre_s7, englishPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Interpret the message conveyed in a material viewed.', englishPCData.pre_s8, englishPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Formulate predictions based on the material viewed.', englishPCData.pre_s9, englishPCData.post_s9, 9)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Vocabulary</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.14%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Provide words or expressions appropriate for a given situation.', englishPCData.pre_s10, englishPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Provide words or expressions appropriate for a given situation.', englishPCData.pre_s11, englishPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Explain how words are derived from names of persons and places.', englishPCData.pre_s12, englishPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Arrive at meaning of words through word formation (clipping, blending, acronymy, compounding, folk etymology, etc.).', englishPCData.pre_s13, englishPCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Note types of context clue (restatement, definition, synonyms, antonyms) used for a given word or expression.', englishPCData.pre_s14, englishPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Note types of context clue (restatement, definition, synonyms, antonyms) used for a given word or expression.', englishPCData.pre_s15, englishPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Note types of context clue (restatement, definition, synonyms, antonyms) used for a given word or expression.', englishPCData.pre_s16, englishPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Give the appropriate communicative styles for various situations (intimate, casual, conversational, consultative, frozen).', englishPCData.pre_s17, englishPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Give the appropriate communicative styles for various situations (intimate, casual, conversational, consultative, frozen).', englishPCData.pre_s18, englishPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Give the appropriate communicative styles for various situations (intimate, casual, conversational, consultative, frozen).', englishPCData.pre_s19, englishPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Determine the vocabulary or jargons expected of a communicative style.', englishPCData.pre_s20, englishPCData.post_s20, 20)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Literature</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.14%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Determine tone, mood, technique, and purpose of the author.', englishPCData.pre_s21, englishPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Determine tone, mood, technique, and purpose of the author.', englishPCData.pre_s22, englishPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Determine tone, mood, technique, and purpose of the author.', englishPCData.pre_s23, englishPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Explain the literary devices used.', englishPCData.pre_s24, englishPCData.post_s24, 24)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Writing and Composition</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.14%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Distinguish between and among informative, journalistic, and literary writing.', englishPCData.pre_s25, englishPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Identify types and features of poetry.', englishPCData.pre_s26, englishPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Distinguish the features present in poetry and in prose.', englishPCData.pre_s27, englishPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Distinguish the features present in poetry and in prose.', englishPCData.pre_s28, englishPCData.post_s28, 28)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Grammar Awareness</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.14%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Use appropriate punctuation marks and capitalization to convey meaning.', englishPCData.pre_s29, englishPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Use appropriate punctuation marks and capitalization to convey meaning.', englishPCData.pre_s30, englishPCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Use appropriate punctuation marks and capitalization to convey meaning.', englishPCData.pre_s31, englishPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Use interjections to convey meaning.', englishPCData.pre_s32, englishPCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Use interjections to convey meaning.', englishPCData.pre_s33, englishPCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Use interjections to convey meaning.', englishPCData.pre_s34, englishPCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Use interjections to convey meaning.', englishPCData.pre_s35, englishPCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Use adverbs in narration.', englishPCData.pre_s36, englishPCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Use adverbs in narration.', englishPCData.pre_s37, englishPCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Use conditionals in expressing arguments.', englishPCData.pre_s38, englishPCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Use conditionals in expressing arguments.', englishPCData.pre_s39, englishPCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Use past conditionals in expressing arguments.', englishPCData.pre_s40, englishPCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Use past conditionals in expressing arguments.', englishPCData.pre_s41, englishPCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Use past conditionals in expressing arguments.', englishPCData.pre_s42, englishPCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Use verbals.', englishPCData.pre_s43, englishPCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Use verbals.', englishPCData.pre_s44, englishPCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Use verbals.', englishPCData.pre_s45, englishPCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Use active and passive constructions.', englishPCData.pre_s46, englishPCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Use active and passive constructions.', englishPCData.pre_s47, englishPCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Express permission, obligation, and prohibition.', englishPCData.pre_s48, englishPCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Express permission, obligation, and prohibition.', englishPCData.pre_s49, englishPCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Change direct to indirect speech and vice versa.', englishPCData.pre_s50, englishPCData.post_s50, 50)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percentage of Correct Answers in the Math Learning Competencies</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody><tr>
                                       <td width="60%" colSpan={2} rowSpan={2} style={{ width: '60.96%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                       </td>
                                       <td width="19%" style={{ width: '19.72%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>PRETEST</span></b></p>
                                       </td>
                                       <td width="19%" style={{ width: '19.32%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>POST TEST</span></b></p>
                                       </td>
                                    </tr>
                                       <tr>
                                          <td width="19%" style={{ width: '19.72%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.32%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="56%" style={{ width: '56.36%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Patterns and Algebra</b></p>
                                          </td>
                                          <td width="19%" style={{ width: '19.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="19%" style={{ width: '19.32%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Illustrates quadratic equations.', mathPCData.pre_s1, mathPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Illustrates quadratic equations.', mathPCData.pre_s2, mathPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Solves quadratic equations.', mathPCData.pre_s3, mathPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Solves quadratic equations.', mathPCData.pre_s4, mathPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Characterizes the roots of a quadratic equation using the discriminant.', mathPCData.pre_s5, mathPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Characterizes the roots of a quadratic equation using the discriminant.', mathPCData.pre_s6, mathPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Describes the relationship between the coefficients and the roots of a quadratic equation.', mathPCData.pre_s7, mathPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Describes the relationship between the coefficients and the roots of a quadratic equation.', mathPCData.pre_s8, mathPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Solves equations transformable to quadratic equations (including rational algebraic equations).', mathPCData.pre_s9, mathPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Solves equations transformable to quadratic equations (including rational algebraic equations).', mathPCData.pre_s10, mathPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Solves problems involving quadratic equations and rational algebraic equations.', mathPCData.pre_s11, mathPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Solves problems involving quadratic equations and rational algebraic equations.', mathPCData.pre_s12, mathPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Solves quadratic inequalities.', mathPCData.pre_s13, mathPCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Solves quadratic inequalities.', mathPCData.pre_s14, mathPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Solves problems involving quadratic inequalities.', mathPCData.pre_s15, mathPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Solves problems involving quadratic inequalities.', mathPCData.pre_s16, mathPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Represents a quadratic function.', mathPCData.pre_s17, mathPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Represents a quadratic function.', mathPCData.pre_s18, mathPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Transforms the quadratic function defined by y = ax2 + bx + c into the form y = a(x – h)2 + k.', mathPCData.pre_s19, mathPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Transforms the quadratic function defined by y = ax2 + bx + c into the form y = a(x – h)2 + k.', mathPCData.pre_s20, mathPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Determines the equation of a quadratic function given: (a) a table of values; (b) graph; (c) zeros.', mathPCData.pre_s21, mathPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Determines the equation of a quadratic function.', mathPCData.pre_s22, mathPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Solves problems involving quadratic functions.', mathPCData.pre_s23, mathPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Solves problems involving quadratic functions.', mathPCData.pre_s24, mathPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Illustrates situations that involve the following variations: (a) direct; (b) inverse; (c) joint; (d) combined.', mathPCData.pre_s25, mathPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Illustrates situations that involve the following variations: (a) direct; (b) inverse; (c) joint; (d) combined.', mathPCData.pre_s26, mathPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Illustrates expressions with rational exponents.', mathPCData.pre_s27, mathPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Illustrates expressions with rational exponents.', mathPCData.pre_s28, mathPCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Illustrates expressions with rational exponents.', mathPCData.pre_s29, mathPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Illustrates expressions with rational exponents.', mathPCData.pre_s30, mathPCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Simplifies radical expressions using the laws of radicals.', mathPCData.pre_s31, mathPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Simplifies radical expressions using the laws of radicals.', mathPCData.pre_s32, mathPCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Solves problems involving radicals.', mathPCData.pre_s33, mathPCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Solves problems involving radicals.', mathPCData.pre_s34, mathPCData.post_s34, 34)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Geometry</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.14%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Identifies quadrilaterals that are parallelograms.', mathPCData.pre_s35, mathPCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Identifies quadrilaterals that are parallelograms.', mathPCData.pre_s36, mathPCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Determines the conditions that guarantee a quadrilateral a parallelogram.', mathPCData.pre_s37, mathPCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Determines the conditions that guarantee a quadrilateral a parallelogram.', mathPCData.pre_s38, mathPCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Uses properties to find measures of angles, sides and other quantities involving parallelograms.', mathPCData.pre_s39, mathPCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Uses properties to find measures of angles, sides and other quantities involving parallelograms.', mathPCData.pre_s40, mathPCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Uses properties to find measures of angles, sides and other quantities involving parallelograms.', mathPCData.pre_s41, mathPCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Uses properties to find measures of angles, sides and other quantities involving parallelograms.', mathPCData.pre_s42, mathPCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Uses properties to find measures of angles, sides and other quantities involving parallelograms.', mathPCData.pre_s43, mathPCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Uses properties to find measures of angles, sides and other quantities involving parallelograms.', mathPCData.pre_s44, mathPCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Illustrates similarity of figures.', mathPCData.pre_s45, mathPCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Illustrates similarity of figures.', mathPCData.pre_s46, mathPCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Applies the theorems to show that given triangles are similar.', mathPCData.pre_s47, mathPCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Applies the theorems to show that given triangles are similar.', mathPCData.pre_s48, mathPCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Illustrates the six trigonometric ratios: sine, cosine, tangent, secant, cosecant, and cotangent.', mathPCData.pre_s49, mathPCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Illustrates the six trigonometric ratios: sine, cosine, tangent, secant, cosecant, and cotangent.', mathPCData.pre_s50, mathPCData.post_s50, 50)}
                                       {generatePercentCorrectRow('Finds the trigonometric ratios of special angles.', mathPCData.pre_s51, mathPCData.post_s51, 51)}
                                       {generatePercentCorrectRow('Finds the trigonometric ratios of special angles.', mathPCData.pre_s52, mathPCData.post_s52, 52)}
                                       {generatePercentCorrectRow('Illustrates angles of elevation and angles of depression.', mathPCData.pre_s53, mathPCData.post_s53, 53)}
                                       {generatePercentCorrectRow('Illustrates angles of elevation and angles of depression.', mathPCData.pre_s54, mathPCData.post_s54, 54)}
                                       {generatePercentCorrectRow('Illustrates laws of sines and cosines.', mathPCData.pre_s55, mathPCData.post_s55, 55)}
                                       {generatePercentCorrectRow('Illustrates laws of sines and cosines.', mathPCData.pre_s56, mathPCData.post_s56, 56)}
                                       {generatePercentCorrectRow('Solves problems involving oblique triangles.', mathPCData.pre_s57, mathPCData.post_s57, 57)}
                                       {generatePercentCorrectRow('Solves problems involving oblique triangles.', mathPCData.pre_s58, mathPCData.post_s58, 58)}
                                       {generatePercentCorrectRow('Graphs a quadratic function.', mathPCData.pre_s59, mathPCData.post_s59, 59)}
                                       {generatePercentCorrectRow('Graphs a quadratic function.', mathPCData.pre_s60, mathPCData.post_s60, 60)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percentage of Correct Answers in the Science Learning Competencies</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody><tr>
                                       <td width="67%" colSpan={2} rowSpan={2} style={{ width: '67.6%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                       </td>
                                       <td width="16%" style={{ width: '16.46%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>PRETEST</span></b></p>
                                       </td>
                                       <td width="15%" style={{ width: '15.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>POST TEST</span></b></p>
                                       </td>
                                    </tr>
                                       <tr>
                                          <td width="16%" style={{ width: '16.46%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.94%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="63%" style={{ width: '63.0%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Living Things</b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.46%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="15%" style={{ width: '15.94%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Explain how the respiratory and circulatory systems work together to transport nutrients, gases, and other molecules to and from the different parts of the body.', sciencePCData.pre_s1, sciencePCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Explain how the respiratory and circulatory systems work together to transport nutrients, gases, and other molecules to and from the different parts of the body.', sciencePCData.pre_s2, sciencePCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Infer how one’s lifestyle can affect the functioning of respiratory and circulatory systems.', sciencePCData.pre_s3, sciencePCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Infer how one’s lifestyle can affect the functioning of respiratory and circulatory systems.', sciencePCData.pre_s4, sciencePCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Describe the location of genes in chromosomes.', sciencePCData.pre_s5, sciencePCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Describe the location of genes in chromosomes.', sciencePCData.pre_s6, sciencePCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Explain the different patterns of non-Mendelian inheritance.', sciencePCData.pre_s7, sciencePCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Explain the different patterns of non-Mendelian inheritance.', sciencePCData.pre_s8, sciencePCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Relate species extinction to the failure of populations of organisms to adapt to abrupt changes in the environment.', sciencePCData.pre_s9, sciencePCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Differentiate basic features and importance of photosynthesis and respiration.', sciencePCData.pre_s10, sciencePCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Differentiate basic features and importance of photosynthesis and respiration.', sciencePCData.pre_s11, sciencePCData.post_s11, 11)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Matter</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.14%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Explain the formation of ionic and covalent bonds.', sciencePCData.pre_s12, sciencePCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Explain the formation of ionic and covalent bonds.', sciencePCData.pre_s13, sciencePCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Recognize different types of compounds (ionic or covalent) based on their properties such as melting point, hardness, polarity, and electrical and thermal conductivity.', sciencePCData.pre_s14, sciencePCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Recognize different types of compounds (ionic or covalent) based on their properties such as melting point, hardness, polarity, and electrical and thermal conductivity.', sciencePCData.pre_s15, sciencePCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Explain properties of metals in terms of their structure.', sciencePCData.pre_s16, sciencePCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Explain properties of metals in terms of their structure.', sciencePCData.pre_s17, sciencePCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Explain how ions are formed.', sciencePCData.pre_s18, sciencePCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Explain how ions are formed.', sciencePCData.pre_s19, sciencePCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Explain how the structure of the carbon atom affects the type of bonds it forms.', sciencePCData.pre_s20, sciencePCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Recognize the general classes and uses of organic compounds.', sciencePCData.pre_s21, sciencePCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Recognize the general classes and uses of organic compounds.', sciencePCData.pre_s22, sciencePCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Use the mole concept to express mass of substances.', sciencePCData.pre_s23, sciencePCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Use the mole concept to express mass of substances.', sciencePCData.pre_s24, sciencePCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Determine the percentage composition of a compound given its chemical formula and vice-versa.', sciencePCData.pre_s25, sciencePCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Determine the percentage composition of a compound given its chemical formula and vice-versa.', sciencePCData.pre_s26, sciencePCData.post_s26, 26)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Earth and Space</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.14%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Describe the different types of volcanoes.', sciencePCData.pre_s27, sciencePCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Describe the different types of volcanoes.', sciencePCData.pre_s28, sciencePCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Differentiate between active and inactive volcanoes.', sciencePCData.pre_s29, sciencePCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Differentiate between active and inactive volcanoes.', sciencePCData.pre_s30, sciencePCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Explain what happens when volcanoes erupt.', sciencePCData.pre_s31, sciencePCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Explain what happens when volcanoes erupt.', sciencePCData.pre_s32, sciencePCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Illustrate how energy from volcanoes may be tapped from human use.', sciencePCData.pre_s33, sciencePCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Illustrate how energy from volcanoes may be tapped from human use.', sciencePCData.pre_s34, sciencePCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Explain how different factors affect the climate of an area.', sciencePCData.pre_s35, sciencePCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Explain how different factors affect the climate of an area.', sciencePCData.pre_s36, sciencePCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Infer the characteristics of stars based on the characteristics of the sun.', sciencePCData.pre_s37, sciencePCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Infer the characteristics of stars based on the characteristics of the sun.', sciencePCData.pre_s38, sciencePCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Infer that the arrangement of stars in a group (constellation) does not change.', sciencePCData.pre_s39, sciencePCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Infer that the arrangement of stars in a group (constellation) does not change.', sciencePCData.pre_s40, sciencePCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Observe the position of a constellation changes in the course of the night.', sciencePCData.pre_s41, sciencePCData.post_s41, 41)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Force and Motion</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.14%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Describe the horizontal and vertical motions of a projectile.', sciencePCData.pre_s42, sciencePCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Describe the horizontal and vertical motions of a projectile.', sciencePCData.pre_s43, sciencePCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Investigate the relationship between the angle of release and the height and range of the projectile.', sciencePCData.pre_s44, sciencePCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Investigate the relationship between the angle of release and the height and range of the projectile.', sciencePCData.pre_s45, sciencePCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Relate impulse and momentum to collision of object.', sciencePCData.pre_s46, sciencePCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Relate impulse and momentum to collision of object.', sciencePCData.pre_s47, sciencePCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Infer that the total momentum before and after collision is equal.', sciencePCData.pre_s48, sciencePCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Infer that the total momentum before and after collision is equal.', sciencePCData.pre_s49, sciencePCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Examine effects and predict causes of collision-related damages/injuries.', sciencePCData.pre_s50, sciencePCData.post_s50, 50)}
                                       {generatePercentCorrectRow('Explain energy transformation in various activities/events.', sciencePCData.pre_s51, sciencePCData.post_s51, 51)}
                                       {generatePercentCorrectRow('Perform activities to demonstrate conservation of mechanical energy.', sciencePCData.pre_s52, sciencePCData.post_s52, 52)}
                                       {generatePercentCorrectRow('Perform activities to demonstrate conservation of mechanical energy.', sciencePCData.pre_s53, sciencePCData.post_s53, 53)}
                                       {generatePercentCorrectRow('Infer that the total mechanical energy remains the same during any process.', sciencePCData.pre_s54, sciencePCData.post_s54, 54)}
                                       {generatePercentCorrectRow('Infer that the total mechanical energy remains the same during any process.', sciencePCData.pre_s55, sciencePCData.post_s55, 55)}
                                       {generatePercentCorrectRow('Construct a model to demonstrate that heat can do work.', sciencePCData.pre_s56, sciencePCData.post_s56, 56)}
                                       {generatePercentCorrectRow('Construct a model to demonstrate that heat can do work.', sciencePCData.pre_s57, sciencePCData.post_s57, 57)}
                                       {generatePercentCorrectRow('Infer that heat transfer can be used to do work, and that work involves the release of heat.', sciencePCData.pre_s58, sciencePCData.post_s58, 58)}
                                       {generatePercentCorrectRow('Infer that heat transfer can be used to do work, and that work involves the release of heat.', sciencePCData.pre_s59, sciencePCData.post_s59, 59)}
                                       {generatePercentCorrectRow('Explain how heat transfer and energy transformation make heat engines like geothermal plants work.', sciencePCData.pre_s60, sciencePCData.post_s60, 60)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                        </div>
                     </div>
                  </div >
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }

      </>
   );
}