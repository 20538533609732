
import { useContext } from 'react'
import { CanvasContext } from '../../hooks/LessonPage'
import SearchServiceGoogleAPI from '../../LessonComponents/SearchServiceGoogleAPI'
function VideosPanel() {
  const { state, actions } = useContext(CanvasContext);

  const setItem = (param) =>
  {
    var data = {type : "VIDEO",
                 url : "https://www.youtube.com/watch?v="+ param.videoId, 
                 videoimage : param.image,
                 videoid : param.videoId
                }
     actions?.addElement(data);
  }
  return (
    <>
       <SearchServiceGoogleAPI type={0} add={setItem}/>
    </>
  )
}
export default VideosPanel
