export function MeanLevelRow(props) {
   return (
      <>
         <tr>
            <td style={{ width: '20.56%', borderStyle: 'solid', borderWidth: '0.75pt', borderBottom: '1pt solid #cccccc', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{props.label}</strong></p>
            </td>
            <td style={{ width: '4.78%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{props.data.vn}</p>
            </td>
            <td style={{ width: '11.4%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{props.data.prem <= 0 || props.data.prem === null ? '' : (props.data.prem).toFixed(2)}</p>
            </td>
            <td style={{ width: '11.68%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{props.data.postm <= 0 || props.data.postm === null ? '' : (props.data.postm).toFixed(2)}</p>
            </td>
            <td style={{ width: '10.8%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{props.data.min}</p>
            </td>
            <td style={{ width: '11.16%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{props.data.max}</p>
            </td>
            <td style={{ width: '14.52%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{(props.data.sd).toFixed(2)}</p>
            </td>
            <td style={{ width: '15.1%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{props.data.proficiency}</p>
            </td>
         </tr>
      </>
   );
}