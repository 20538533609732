/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useEffect, useState, useReducer } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
   Empty, Input, Tabs, Table, Typography, Space, Tooltip, Button, Select, Row, Col, message,
   Popconfirm, Radio, Tag, Drawer, notification, Modal, Breadcrumb, Spin, Segmented
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
// import AssessmentAssignTool from '../../components/assessment/AssessmentAssignTool';
import BraineeAssignTool from '../../components/assessment/BraineeAssignTool';
import SimpleBarReact from "simplebar-react";
import axios from 'axios';
import uuid from 'react-uuid';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
const { TabPane } = Tabs;
const { Text } = Typography;

export function BDiagnostic() {
   const navigate = useNavigate();
   const location = useLocation();
   const aTab = location.state !== null ? location.state.activetab : "1";

   const search = window.location.search;
   const query = new URLSearchParams(search);
   const diagnosticType = query.get('type');

   const [dataContainer, setDataContainer] = useState([]);
   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   const [questionTypeModalVisible, setQuestionTypeModalVisible] = useState(false);
   const [questionType, setQuestionType] = useState(1);
   const [showMasterList, setShowMasterList] = useState(false);
   const [selectedAssessment, setSelectedAssessment] = useState("");

   const [assOptions, setAssOptions] = useState(null);

   const [assignVisible, setAssignVisible] = useState(false);
   const [assTitle, setAssTitle] = useState('');
   const [assTerm, setAssTerm] = useState('');
   // const [terms, setTerms] = useState([]);
   const [masterList, setMasterList] = useState([]);
   // const [currentList, setCurrentList] = useState([]);
   // const [upcomingList, setUpcomingList] = useState([]);
   // const [pastList, setPastList] = useState([]);
   const [deleteList, setDeletedList] = useState([]);
   const [activeTab, setActiveTab] = useState("1");
   const [listLoading, setListLoading] = useState(true);
   const [processing, setProcessing] = useState(false);
   const [assignedList, setAssignedList] = useState([]);

   // const user_full_name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);
   // const [userList, setUserList] = useState([]);
   const [user_id, setUser_Id] = useState(Utils.getCurrentUserInfo(Enums.UserInfo.id));

   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);
   // const user_other_data = JSON.parse(Utils.getCurrentUserInfo(Enums.UserInfo.otherdata));

   // const subdomain = localStorage.getItem("lms-subdomain");
   const subdomain = "ccph";

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher') {
         navigate('/home');
      } else {
         setActiveTab(() => {
            refreshList(aTab);
            return aTab
         });
      }
   }, [diagnosticType]);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const masterListColumns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'term',
         key: 'term',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'shared',
         key: 'shared',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'created_by',
         key: 'created_by',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: false,
         },
         render: title => (
            <Tooltip placement="topLeft" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '35%'
      },
      // {
      //    title: 'Term',
      //    key: 'term_name',
      //    dataIndex: 'term_name',
      //    sorter: (a, b) => { return a.term_name.localeCompare(b.term_name) },
      //    ...getColumnSearchProps('term_name'),
      //    ellipsis: {
      //       showTitle: false,
      //    },
      //    render: term_name => (
      //       <Tooltip placement="topLeft" title={term_name}>
      //          <span style={{ cursor: "default" }}>{term_name}</span>
      //       </Tooltip>
      //    ),
      //    width: '9%'
      // },
      {
         title: 'Level',
         key: 'level_desc',
         dataIndex: 'level_desc',
         sorter: (a, b) => { return a.level_desc.localeCompare(b.level_desc) },
         ...getColumnSearchProps('level_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: level_desc => (
            <Tooltip placement="topLeft" title={level_desc}>
               <span style={{ cursor: "default" }}>{level_desc}</span>
            </Tooltip>
         ),
         width: '9%'
      },
      {
         title: 'Subject',
         key: 'subject_desc',
         dataIndex: 'subject_desc',
         sorter: (a, b) => { return a.subject_desc.localeCompare(b.subject_desc) },
         ...getColumnSearchProps('subject_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: subject => (
            <Tooltip placement="topLeft" title={subject}>
               <span style={{ cursor: "default" }}>{subject}</span>
            </Tooltip>
         ),
         width: '17%'
      },
      {
         title: 'Created By',
         key: 'created_by_name',
         dataIndex: 'created_by_name',
         sorter: (a, b) => { return a.created_by_name.localeCompare(b.created_by_name) },
         ...getColumnSearchProps('created_by_name'),
         ellipsis: {
            showTitle: false,
         },
         render: created_by_name => (
            <Tooltip placement="topLeft" title={created_by_name}>
               <span style={{ cursor: "default" }}>{created_by_name}</span>
            </Tooltip>
         ),
         width: '14%',
         hidden: subdomain.toLowerCase() === 'ccph' ? false : true
      },
      {
         title: 'Date Created',
         key: 'date_created',
         dataIndex: 'date_created',
         sorter: (a, b) => { return a.date_created.localeCompare(b.date_created) },
         width: '12%'
      },
      {
         title: 'Question Type',
         key: 'question_type',
         dataIndex: 'question_type',
         hidden: true,
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  {user_role.toLowerCase() === 'admin' &&
                     <Tooltip placement="top" title='Edit'><AntDIcons.EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssessment(payload.id, payload.question_type)} /></Tooltip>
                  }
                  <Tooltip placement="top" title='Assign'><AntDIcons.UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleAssignAssessment(payload.id, payload.title, payload.term, payload.question_type)} /></Tooltip>
                  {user_role.toLowerCase() === 'admin' &&
                     <Tooltip placement="top" title='Duplicate'>
                        <Popconfirm
                           title="Create a copy of this assessment?"
                           onConfirm={() => handleCopyAssessment(payload.id)}
                           okText="Yes"
                           cancelText="No"
                        >
                           <AntDIcons.CopyOutlined color={'#4abec7'} style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                     </Tooltip>
                  }
                  {user_role.toLowerCase() === 'admin' &&
                     <Tooltip placement="top" title='Remove'>
                        <Popconfirm
                           title="Are you sure you want to delete this assessment?"
                           onConfirm={() => handleDeleteAssessment(payload.id)}
                           okText="Yes"
                           cancelText="No"
                        >
                           <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                     </Tooltip>
                  }
                  <Tooltip placement="top" title='Preview'>
                     <AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleAssessmentPreview(payload.id, payload.question_type, payload.origin)} />
                  </Tooltip>
               </Space>
            </>
         },
         width: '14%'
      },
   ].filter(item => !item.hidden);

   const assigned_ass_columns = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.title}>
               <b><Text>{object.title}</Text></b>
               <p style={{ marginBottom: '0px' }}>{object.start_date + ' - ' + object.end_date}</p>
            </Tooltip>
         ),
         width: '35%'
      },
      {
         title: 'Level',
         key: 'level_desc',
         dataIndex: 'level_desc',
         sorter: (a, b) => { return a.level_desc.localeCompare(b.level_desc) },
         ...getColumnSearchProps('level_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: level_desc => (
            <Tooltip placement="topLeft" title={level_desc}>
               <span style={{ cursor: "default" }}>{level_desc}</span>
            </Tooltip>
         ),
         width: '9%'
      },
      {
         title: 'Subject',
         key: 'subject_desc',
         dataIndex: 'subject_desc',
         sorter: (a, b) => { return a.subject_desc.localeCompare(b.subject_desc) },
         ...getColumnSearchProps('subject_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: subject => (
            <Tooltip placement="topLeft" title={subject}>
               <span style={{ cursor: "default" }}>{subject}</span>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Assigned By',
         key: 'assigned_by_name',
         ...getColumnSearchProps('assigned_by_name'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.assigned_by_name}>
               <Text>{object.assigned_by_name}</Text>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Assigned To',
         key: 'assigned_to_sections',
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            // <Tooltip placement="topLeft" title={payload.section_desc}>
            <>
               {
                  (payload.assigned_to_sections !== null && payload.assigned_to_sections !== '') &&
                  <Text>
                     <div dangerouslySetInnerHTML={{ __html: payload.assigned_to_sections.replace(new RegExp(',', 'g'), '<br />') }} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} />
                  </Text>
               }
            </>
            // </Tooltip>
         ),
         width: '15%'
      },
      {
         title: 'Date Assigned',
         key: 'date_created',
         dataIndex: 'date_created',
         sorter: (a, b) => { return a.date_created.localeCompare(b.date_created) },
         width: '14%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               {
                  activeTab === '2' &&
                  <Space size="small">
                     <Tooltip placement="top" title='Edit Assigment'><AntDIcons.UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssessmentAssign(payload.id, payload.title, payload.question_type)} /></Tooltip>
                     <Tooltip placement="top" title='View Tracker'><AntDIcons.ProfileOutlined style={{ cursor: 'pointer' }} onClick={() => handleViewTracker(payload.id, activeTab)} /></Tooltip>
                  </Space>
               }

               {
                  activeTab === '3' &&
                  <Space size="small">
                     <Tooltip placement="top" title='Edit Assigment'><AntDIcons.UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssessmentAssign(payload.id, payload.title)} /></Tooltip>
                     <Tooltip placement="top" title='Remove'>
                        <Popconfirm
                           title="Are you sure you want to delete this record?"
                           onConfirm={() => handleDeleteAssignment(payload.id)}
                           // onCancel={cancel}
                           okText="Yes"
                           cancelText="No">

                           <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                     </Tooltip>
                  </Space>
               }

               {
                  activeTab === '4' &&
                  <Space size="small">
                     <Tooltip placement="top" title='Edit Assigment'><AntDIcons.UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssessmentAssign(payload.id, payload.title)} /></Tooltip>
                     <Tooltip placement="top" title='Remove'>
                        <Popconfirm
                           title="Are you sure you want to delete this record?"
                           onConfirm={() => handleDeleteAssignment(payload.id)}
                           // onCancel={cancel}
                           okText="Yes"
                           cancelText="No">

                           <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                     </Tooltip>
                     <Tooltip placement="top" title='View Tracker'><AntDIcons.ProfileOutlined style={{ cursor: 'pointer' }} onClick={() => handleViewTracker(payload.id, activeTab)} /></Tooltip>
                  </Space>
               }
            </>
         },
         width: '7%'
      },
   ].filter(item => !item.hidden);

   const recycle_bin_columns = [
      {
         title: 'Title',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: false,
         },
         render: title => (
            <Tooltip placement="topLeft" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '54%'
      },
      {
         title: 'Question Type',
         key: 'question_type',
         ...getColumnSearchProps('question_type'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            object.question_type.toString() === '1' ? <Text>PDF</Text> : <Text>CREATE</Text>
         ),
         width: '10%'
      },
      {
         title: 'Origin',
         key: 'origin',
         dataIndex: 'origin',
         sorter: (a, b) => { return a.origin.localeCompare(b.origin) },
         width: '13%'
      },
      {
         title: 'Date Deleted',
         key: 'date_deleted',
         dataIndex: 'date_deleted',
         sorter: (a, b) => { return a.date_deleted.localeCompare(b.date_deleted) },
         width: '13%'
      },

      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='View'>
                     <AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleAssignAssessmentView(payload.assessment_id, payload.question_type, payload.origin)} />
                  </Tooltip>
                  {/* {payload.created_by.toString() === user_id && */}
                  <Tooltip placement="top" title='Restore'>
                     <Popconfirm
                        title="Do you want to restore this assessment?"
                        onConfirm={() => payload.origin.toLowerCase() === 'main' ? handleRestoreAssessment(payload.id) : handleRestoreAssignedAssessment(payload.id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <AntDIcons.UndoOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
                  {/* } */}
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const handleCancelModal = () => {
      setQuestionTypeModalVisible(false);
      setShowMasterList(false);
      setQuestionType(1);
   }

   const handleCopyAssessment = (id) => {
      var assinfo = null;
      setListLoading(true);

      axios.get(`${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getinfo?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;

            var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
            var utc = new Date().toJSON().slice(0, 19);

            assinfo = {
               Id: "ass_" + o2o_mode + "_" + uuid(),
               Title: data.title + " - (" + utc + ")",
               Term: data.term,
               File_url: data.file_url,
               Question_type: data.question_type,
               Created_by: user_id,
               Copied_from: data.id,
               Question_sheet: data.question_sheet,
               Level: data.level,
               Subject: data.subject,
               Type: data.type,
            };

            //-- Insert duplicated assessment
            const url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/upsert`
            axios.post(url, assinfo).then(response => {
               if (response.data) {
                  const data = response.data;
                  Modal.success({
                     title: 'Diagnostic Assessment',
                     content: 'A copy of your assessment has been created.',
                     centered: true,
                     onOk: getMasterList(),
                  });
               }
            }, (error) => {
               console.log(error.message);
               setListLoading(false);
               Modal.error({
                  title: 'Diagnostic Assessment',
                  content: 'Assessment duplication failed.',
                  centered: true
               });
            });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Diagnostic Assessment',
            content: 'Assessment duplication failed.',
            centered: true
         });
      });
   }

   const handleDeleteAssessment = (id) => {
      setListLoading(true);

      axios.post(`${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/delete?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Diagnostic Assessment',
               content: 'Your assessment has been deleted.',
               centered: true,
               onOk: refreshList(activeTab)
            });
         }
      }, (error) => {
         setListLoading(false);
         Modal.error({
            title: 'Summative Assessment',
            content: 'Delete assessment failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleDeleteAssignment = (id) => {
      setListLoading(true);

      axios.post(`${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/deleteassigned?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Diagnostic Assessment',
               content: 'Your assigned assessment has been deleted.',
               centered: true,
               onOk: refreshList(activeTab)
            });
         }
      }, (error) => {
         setListLoading(false);
         Modal.error({
            title: 'Diagnostic Assessment',
            content: 'Delete assigned assessment failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleRestoreAssessment = (id) => {
      setListLoading(true);

      axios.post(`${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/undelete?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            setListLoading(false);

            Modal.success({
               title: 'Diagnostic Assessment',
               content: 'Your assessment has been restored.',
               centered: true,
               onOk: refreshList(activeTab)
            });
         }
      }, (error) => {
         setListLoading(false);
         Modal.error({
            title: 'Diagnostic Assessment',
            content: 'Restore assessment failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleRestoreAssignedAssessment = (id) => {
      setListLoading(true);

      axios.post(`${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/undeleteassigned?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Diagnostic Assessment',
               content: 'Your assigned assessment has been restored.',
               centered: true,
               onOk: refreshList(activeTab)
            });
         }
      }, (error) => {
         setListLoading(false);
         Modal.error({
            title: 'Diagnostic Assessment',
            content: 'Resotre assigned assessment failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleCreateAssessment = () => {
      setQuestionTypeModalVisible(false);

      if (questionType === 1) {
         navigate('/bdiagnostic/create-pdf', { state: { title: assTitle, term: assTerm, type: diagnosticType } });
      } else if (questionType === 2) {
         navigate('/bdiagnostic/create-manual', { state: { title: assTitle, term: assTerm, type: diagnosticType } });
      }
   };

   const handleEditAssessment = (id, q_type) => {
      if (q_type.toString() === '1') {
         navigate('/bdiagnostic/edit-pdf', { state: { assessmentID: id, type: diagnosticType } });
      }
      else if (q_type.toString() === '2') {
         navigate('/bdiagnostic/edit-manual', { state: { assessmentID: id, type: diagnosticType } });
      }
   };

   const handleAssignAssessmentView = (id, q_type, origin) => {
      if (q_type.toString() === '1') {
         navigate('/bdiagnostic/view-pdf', { state: { id, origin, type: diagnosticType } });
      }
      else if (q_type.toString() === '2') {
         navigate('/bdiagnostic/view-manual', { state: { id, origin, type: diagnosticType } });
      }
   }

   const handleAssessmentPreview = (id, q_type, origin) => {
      if (q_type.toString() === '1') {
         navigate('/bdiagnostic/preview-pdf', { state: { id, origin, type: diagnosticType } });
      }
      else if (q_type.toString() === '2') {
         navigate('/bdiagnostic/preview-manual', { state: { id, origin, type: diagnosticType } });
      }
   }

   const handleViewTracker = (assign_id, origin) => {
      navigate(`/bdiagnostic/tracker?id=${assign_id}&origin=${origin}&type=${diagnosticType}`);
   }

   const handleQuestionType = (e) => {
      setQuestionType(e.target.value);
   };

   const rowSelection = {
      selectedAssessment,
      onChange: (selectedRowKeys, selectedRows) => {
         if (selectedRows[0].key !== undefined) {
            console.log(selectedRows[0].key);
         }

         setSelectedAssessment(selectedRows[0].id);
      },
      type: 'radio'
   };

   const handleAssignAssessment = (ass_id, title, term, mode, level, subject) => {
      let ass_options_default = {
         id: '',
         assessment_id: ass_id,
         assigned_by: user_id,
         duration: '1',
         allowed_attempts: '1',
         passing_percentage: '50',
         allow_result_viewing: '0',
         enable_timer: '0',
         start_date: '',
         end_date: '',
         assigned_students: '',
         assigned_students_with_section: '',
         term: term,
         level,
         randomize_questions: '0',
         mode,
         subject
      }

      setAssOptions(ass_options_default);
      setAssTitle(title);
      setAssignVisible(true);
   };

   const handleEditAssessmentAssign = (ass_id, title, mode) => {
      axios.get(`${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/assignmentinfo?id=${ass_id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            let ass_options = {
               id: data.id,
               assessment_id: data.assessment_id,
               assigned_by: data.assigned_by,
               duration: data.duration.toString(),
               allowed_attempts: data.allowed_attempts.toString(),
               passing_percentage: data.passing_percentage.toString(),
               allow_result_viewing: data.allow_result_viewing.toString(),
               enable_timer: data.enable_timer.toString(),
               start_date: data.start_date,
               end_date: data.end_date,
               assigned_students: data.assigned_students,
               assigned_students_with_section: data.assigned_students_with_section,
               term: data.term,
               level: data.level,
               randomize_questions: data.randomize_questions,
               mode,
            };

            setAssOptions(ass_options);
            setAssTitle(title);
            setAssignVisible(true);
         }
      }, (error) => {
         console.log(error.message);
      });
   };

   const handleSetOptions = (data) => {
      // message.info(JSON.stringify(data));
      let tempAssOptions = null;
      tempAssOptions = JSON.parse(JSON.stringify(data));
      setAssOptions(tempAssOptions);
   }

   const handleSaveAssignment = (data) => {
      var assignmentData = data;

      if (data.id === '') { //--New assignment
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         assignmentData.id = "bd_assign_" + o2o_mode + "_" + uuid();
      }

      const url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/upsertassignment`
      axios.post(url, assignmentData).then(response => {
         if (response.data) {
            notification.success({
               message: `Diagnostic Assessment`,
               description:
                  'Assessment was assigned successfully',
            });

            setAssignVisible(false);
         }
      }, (error) => {
         console.log(error.message);
         notification.error({
            message: `Diagnostic Assessment`,
            description:
               'Assessment assignment failed',
         });
      });
   }

   const onDrawerClose = () => {
      setAssignVisible(false);
   }

   const renderDrawer = () => {
      return <>
         <Drawer
            title={'Brainee Diagnostic'}
            placement="right"
            size={"default"}
            closable={false}
            onClose={onDrawerClose}
            open={assignVisible}
            zIndex={1001}
            extra={
               <Space>
                  <Button type='primary' shape='round' onClick={onDrawerClose}>Close</Button>
               </Space>
            }
         >
            <SimpleBarReact style={{ height: "calc(100vh - 110px)" }}>
               {
                  <BraineeAssignTool
                     title={assTitle}
                     mode={"drawer"}
                     options={assOptions}
                     parentCallback={handleSetOptions}
                     saveCallback={handleSaveAssignment}
                     type={diagnosticType}
                     level={assOptions.level}
                     subject={assOptions.subject}
                  />
               }
            </SimpleBarReact>
         </Drawer>
      </>
   }

   const getMasterList = () => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getlist?t=${diagnosticType}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setListLoading(false);
            setMasterList(data);
            setDataContainer(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getAssigned = (temporal) => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getassigned?t=${temporal}&dt=${diagnosticType}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setListLoading(false);
            // setCurrentList(data);
            setAssignedList(data);
            setDataContainer(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getRecycleBin = () => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdeleted?t=${diagnosticType}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setListLoading(false);
            setDeletedList(data);
            setDataContainer(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleTabChange = (key) => {
      switch (key) {
         case "1":
            getMasterList();
            setActiveTab("1");
            break;
         case "2":
            getAssigned('current');
            setActiveTab("2");
            break;
         case "3":
            getAssigned('upcoming')
            setActiveTab("3");
            break;
         case "4":
            getAssigned('past')
            setActiveTab("4");
            break;
         case "5":
            getRecycleBin();
            setActiveTab("5");
            break;
         default:
            throw new Error("Unsupported key");
      }
   }

   const refreshList = (tab) => {
      switch (tab) {
         case "1":
            getMasterList();
            break;
         case "2":
            getAssigned('current')
            break;
         case "3":
            getAssigned('upcoming')
            break;
         case "4":
            getAssigned('past')
            break;
         case "5":
            getRecycleBin();
            break;
         default:
            throw new Error("Unsupported");
      }
   }

   // const handleUserChange = (id) => {
   //    setUser_Id(id);
   //    refreshList(activeTab, id);
   // }

   const displayPaneTable = (columns, data) => {
      return (
         <>
            {listLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading, please wait..." />
               </div>
               :
               (data.length > 0 && !listLoading)
                  ?
                  <Table
                     loading={listLoading}
                     size='small'
                     rowKey={'uid'}
                     columns={columns}
                     dataSource={data}
                     pagination={{ position: ['topCenter'] }}
                     scroll={{
                        x: '1000px',
                        y: 'calc(100vh - 18.5rem)',
                     }}
                     footer={() => 'Total Records: ' + data.length}
                  />
                  :
                  <div className='center-empty-space'>
                     <Empty image="../images/brainee_mascot.png" description="No records found" />
                  </div>
            }
         </>
      )
   }

   const searchList = (str) => {
      var filerData = dataContainer.filter(p => p.title.toLowerCase().includes(str.toLowerCase()) ||
         p.subject_desc.toLowerCase().includes(str.toLowerCase()) ||
         p.created_by_name.toLowerCase().includes(str.toLowerCase()));

      switch (activeTab) {
         case "1":
            setMasterList(filerData);
            break;
         case "2": case "3": case "4":
            setAssignedList(filerData);
            break
         // case "2":
         //    setCurrentList(filerData);
         //    break;
         // case "3":
         //    setUpcomingList(filerData);
         //    break;
         // case "4":
         //    setPastList(filerData);
         //    break;
         case "5":
            setDeletedList(filerData);
            break;
         default:
            throw new Error("Unsupported key");
      }
   }

   return (
      <>
         {assOptions && renderDrawer()}
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <Spin size='large' spinning={processing} tip="Processing request...Please wait.">
               <div className="dashboard-item">
                  <div className="dashboard-item-inner">
                     {/* <ul>
                        {
                           <ul>
                              <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                              <li><span>/</span></li>
                              <li className="active">Brainee LMS</li>
                              <li><span>/</span></li>
                              <li className="active">Diagnostic Assessment</li>
                           </ul>
                        }
                     </ul> */}
                     <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                        <Breadcrumb.Item>
                           <Link to="/home">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                        <Breadcrumb.Item>{diagnosticType === 'pre' ? '(Pre) ' : '(Post) '} Diagnostic Assessment</Breadcrumb.Item>
                     </Breadcrumb>
                  </div>

                  <div className='search-columns-3'>
                     <Space direction='horizontal' size={'small'}><div></div></Space>
                     <Space direction='horizontal' size={'small'}>
                        <Input addonBefore={<AntDIcons.SearchOutlined />} placeholder="Search Assessment..." onChange={(e) => searchList(e.target.value)} />
                     </Space>
                     {
                        user_role.toLowerCase() === 'admin' &&
                        <Tooltip placement="bottom" title='Create new summative assessment'>
                           <Button className='button-shadow' type="primary" size="large" shape='round' style={{ maxWidth: '25ch' }} onClick={() => setQuestionTypeModalVisible(true)}>
                              <Text ellipsis style={{ color: "#fff" }}><AntDIcons.PlusOutlined />&nbsp;Create Diagnostic</Text>
                           </Button>
                        </Tooltip>
                     }
                  </div>

                  <div className="dashboard-item-inner3">
                     <Tabs defaultActiveKey={aTab} centered onChange={(key) => handleTabChange(key)}>
                        <TabPane
                           tab={(<Tooltip title="List of all created diagnostic assessments">
                              <span>Assessment List</span>
                           </Tooltip>
                           )}
                           key="1"
                        >
                           {displayPaneTable(masterListColumns, masterList)}
                        </TabPane>
                        <TabPane
                           tab={(
                              <Tooltip title="Current assigned diagnostic assessments">
                                 <span>Current</span>
                              </Tooltip>
                           )}
                           key="2"
                        >
                           {displayPaneTable(assigned_ass_columns, assignedList)}
                        </TabPane>
                        <TabPane
                           tab={(
                              <Tooltip title="Upcoming assigned diagnostic assessments">
                                 <span>Upcoming</span>
                              </Tooltip>
                           )}
                           key="3"
                        >
                           {displayPaneTable(assigned_ass_columns, assignedList)}
                        </TabPane>
                        <TabPane
                           tab={(
                              <Tooltip title="Past assigned diagnostic assessments">
                                 <span>Past</span>
                              </Tooltip>
                           )}
                           key="4"
                        >
                           {displayPaneTable(assigned_ass_columns, assignedList)}
                        </TabPane>
                        <TabPane
                           tab={(
                              <Tooltip title="List of all deleted diagnostic assessments">
                                 <span><AntDIcons.RestOutlined /> Recycle Bin</span>
                              </Tooltip>
                           )}
                           key="5"
                        >
                           {displayPaneTable(recycle_bin_columns, deleteList)}
                        </TabPane>
                     </Tabs>
                  </div>
               </div>
            </Spin>
         </div>

         <Modal
            title="Select Questionaire Option"
            centered
            destroyOnClose={true}
            open={questionTypeModalVisible}
            onOk={() => handleCreateAssessment()}
            onCancel={() => setQuestionTypeModalVisible(false)}
            cancelButtonProps={{ shape: "round" }}
            okButtonProps={{ shape: "round" }}
            width={"300px"}
         >
            <Segmented
               key={'uid'}
               value={questionType}
               block
               options={[
                  {
                     label: 'Upload',
                     value: 1,
                  },
                  {
                     label: 'Create',
                     value: 2,
                  },
               ]}
               onChange={(val) => setQuestionType(val)}
            />
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '20px' }}>
               <Text strong>
                  {
                     questionType === 1
                        ?
                        'Upload a pdf format questionaire'
                        :
                        'Use Brainee assessment tools'
                  }
               </Text>
            </div>
         </Modal>
      </>
   );
}
