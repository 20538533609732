/* eslint-disable default-case */
import React, { useState, useEffect, useRef } from 'react';
import {
   Table, Space, Button, Select, Input, Row, Col, Tag, DatePicker, Breadcrumb, Tooltip
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import { Link } from 'react-router-dom';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import moment from 'moment';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { SchoolTerms } from '../../constants';
import { useDownloadExcel } from 'react-export-table-to-excel';

const { Option } = Select;

// const { Text } = Typography;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

export function ClassroomAttendanceReports() {
   // const navigate = useNavigate();
   const tableRef = useRef(null);

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [sectionLoading, setSectionLoading] = useState(false);
   const [term, setTerm] = useState(null);
   const [level, setLevel] = useState(null);
   const [section, setSection] = useState([]);
   const [sectionList, setSectionList] = useState([]);
   const [listLoading, setListLoading] = useState(false);
   const [levelList, setLevelList] = useState([]);

   const [attendanceList, setAttendanceList] = useState([]);
   const [dateRange, setDateRange] = useState({
      start_date: null,
      end_date: null,
   });
   const [studentsLoading, setStudentsLoading] = useState(false);
   const [studentList, setStudentList] = useState([]);
   const [selectedStudent, setSelectedStudent] = useState(null);

   const subdomain = localStorage.getItem("lms-subdomain");
   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const dateFormat = 'MMMM D, YYYY';
   const customFormat = value => `${value.format(dateFormat)}`;

   useEffect(() => {
      Utils.verifyJwt();

      // if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
      //    navigate('/home');

      if (user_role !== 'Student')
         getLevels();
      else
         setSelectedStudent(user_id);
   }, []);

   const getLevels = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/levellist`).then((response) => {
         if (response.data) {
            const data = response.data;
            let levels = [];

            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               levels.push(obj);
            });

            setLevelList(levels);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   function getStatusColor(status) {
      var retVal = "";

      switch (status) {
         case "present":
            retVal = "green";
            break;
         case "absent":
            retVal = "red";
            break;
         case "late":
            retVal = "gold";
            break;
         case "half-day":
            retVal = "blue";
            break;
         default:
            retVal = "orange";
      }

      return retVal;
   }

   const AttendanceListColumns = [
      {
         title: 'Date',
         key: 'attendance_date',
         dataIndex: 'attendance_date',
         width: '15%'
      },
      {
         title: 'Day',
         key: 'day_name',
         dataIndex: 'day_name',
         width: '15%'
      },
      {
         title: 'Status',
         key: 'status',
         sorter: (a, b) => { return a.status.localeCompare(b.status) },
         ...getColumnSearchProps('status'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (

            object.status !== null
               ?
               <Tag
                  style={{ fontSize: 12, cursor: 'default' }}
                  color={getStatusColor(object.status)}
               >
                  {object.status.toUpperCase()}
               </Tag>
               :
               ''

         ),
         width: '15%'
      },
      {
         title: 'Checked By',
         key: 'checked_by',
         sorter: (a, b) => { return a.status.localeCompare(b.checked_by) },
         ...getColumnSearchProps('checked_by'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="right" title={object.checked_by}>
               <span style={{ cursor: "default" }}>
                  {
                     object.checked_by !== null
                        ?
                        object.checked_by.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </span>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Remarks',
         key: 'remarks',
         render: object => (
            <TextArea
               disabled={true}
               key={object.student_id}
               allowClear={true}
               id={object.student_id}
               rows={1}
               autoSize={{
                  minRows: 1,
                  maxRows: 3,
               }}
               defaultValue={object.remarks}
               style={{ borderRadius: '7px' }}
            />
         ),
         width: '35%'
      },

   ].filter(item => !item.hidden);

   const handleTermChange = (val) => {
      setAttendanceList([]);
      setSection(null);
      setSelectedStudent(null);
      setStudentList([]);
      setTerm(val);
   }

   const handleLevelChange = (val) => {
      setAttendanceList([]);
      setSection(null);
      setSelectedStudent(null);
      setSectionList([]);
      setStudentList([]);
      setLevel(val);
      setSectionLoading(true);

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${val}`).then((response) => {
         if (response.data) {
            const data = response.data;

            // var sectionlist = [];
            // data.map((item) => {
            //    let obj = { 'value': item.value, 'name': item.name }
            //    sectionlist.push(obj);
            // });

            setSectionList(data);
            setSectionLoading(false);
         }
      }, (error) => {
         setSectionLoading(false);
         console.log(error.message);
      });
   }

   const handleSectionChange = (val) => {
      setAttendanceList([]);
      setSelectedStudent(null);
      setStudentList([]);
      setStudentsLoading(true);
      setSection(val);

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/studentlist?level=${level}&section=${val}`).then((response) => {
         if (response.data) {
            const data = response.data;
            setStudentList(data);
            setStudentsLoading(false);
         }
      }, (error) => {
         setStudentsLoading(false);
         console.log(error.message);
      });
   }

   const handleSearchAttendance = () => {
      setAttendanceList([]);
      setListLoading(true);

      let userType = user_role === 'Student' || user_role === "Parent" ? 'student' : 'staff';

      var url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/getclassroomattendancerpt?t=${term}&id=${selectedStudent}&sd=${dateRange.start_date}&ed=${dateRange.end_date}`;
      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            //-- Create list based on selected date range
            var d1 = new Date(dateRange.start_date);
            var d2 = new Date(dateRange.end_date);

            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var attendanceData = [];

            for (var d = d1; d <= d2; d.setDate(d.getDate() + 1)) {
               var day_name = days[d.getDay()];
               var tmpAttendanceData = {
                  attendance_date: moment(d).format("MMMM DD, YYYY"),
                  day_name,
                  status: day_name !== 'Saturday' && day_name !== 'Sunday' ? 'NOT CHECKED' : null,
                  remarks: null,
                  checked_by: null
               }

               for (var i = 0; i < data.length; i++) {
                  var attDate = moment(new Date(data[i].attendance_date)).format("MMMM DD, YYYY");
                  var curDate = moment(d).format("MMMM DD, YYYY");

                  if (attDate === curDate) {
                     tmpAttendanceData.status = data[i].status;
                     tmpAttendanceData.remarks = data[i].remarks;
                     tmpAttendanceData.checked_by = data[i].checked_by;
                     break;
                  }
               }

               attendanceData.push(tmpAttendanceData);
            }

            setAttendanceList(attendanceData);
            setListLoading(false);
         }
      }, (error) => {
         setListLoading(false);
         console.log(error.message);
      });
   }

   const onDateTimeChange = (value, dateString) => {
      setAttendanceList([]);
      // console.log('Selected Time: ', value);
      // console.log('Formatted Selected Time: ', dateString);
      setDateRange({
         start_date: moment(dateString[0]).format("YYYY-MM-DD"),
         end_date: moment(dateString[1]).format("YYYY-MM-DD")
      });
   }

   const disabledDate = (current) => {
      // Can not select days before today and today
      return current && current > moment().endOf('day');
   };

   const handleStudentChange = (val) => {
      setAttendanceList([]);
      setSelectedStudent(val);
   }

   const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename: "classroom_attendance_report",
      sheet: 'Report'
   });

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Attendance</a></li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        {/* <AntDIcons.HomeOutlined /> */}
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Attendance</Breadcrumb.Item>
                     <Breadcrumb.Item>Classroom Attendance Report</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
            </div>

            {
               user_role !== 'Student' &&
               <Row gutter={[10, 10]} style={{ paddingBottom: "20px" }} >
                  <Col sm={24} md={5} lg={5}>
                     <Select
                        name='term'
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Term"
                        onChange={(val) => handleTermChange(val)}
                     >
                        {SchoolTerms.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>
                  </Col>
                  <Col sm={24} md={5} lg={5}>
                     <Select
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Level"
                        onChange={(val) => handleLevelChange(val)}
                        value={level}
                     >
                        {levelList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>
                  </Col>
                  <Col sm={24} md={5} lg={5}>
                     <Select
                        loading={sectionLoading}
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Section"
                        value={section}
                        onChange={(val) => handleSectionChange(val)}
                     >
                        {sectionList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>
                  </Col>
                  <Col sm={24} md={9} lg={9}>
                     <Select
                        loading={listLoading}
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Student"
                        value={selectedStudent}
                        onChange={(val) => handleStudentChange(val)}
                     >
                        {studentList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>
                  </Col>
               </Row>
            }

            <Row gutter={12}>
               <Col sm={24} md={12} lg={12}>
                  <div className="search-columns-2">
                     <RangePicker
                        style={{ width: "100%" }}
                        format={customFormat}
                        onChange={onDateTimeChange}
                        disabledDate={disabledDate}
                     />
                     <Button className='button-shadow' type='primary' shape='round' onClick={() => handleSearchAttendance()} icon={<AntDIcons.SearchOutlined />}>Search</Button>
                  </div>
               </Col>
               <Col sm={24} md={12} lg={12} style={{ textAlign: "right" }}>
                  {
                     attendanceList.length > 0 &&
                     <Button className='button-shadow' type='primary' shape='round' onClick={onDownload} icon={<AntDIcons.DownloadOutlined />}>Download Report</Button>
                  }
               </Col>
            </Row>

            <Row gutter={12} style={{ overflow: "auto" }} >
               <Col span={24}>
                  <div className="dashboard-item-inner3">
                     <Table
                        ref={tableRef}
                        rowKey={'uid'}
                        loading={listLoading}
                        size='small'
                        columns={AttendanceListColumns}
                        dataSource={attendanceList}
                        pagination={false}
                        scroll={{
                           x: '1000px',
                           y: 'calc(100svh - 14rem)',
                        }}
                        footer={() => 'Total Records: ' + attendanceList.length}
                     />
                  </div>
               </Col>
            </Row>
         </div>
         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}
      </>
   );
}

