import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Form, Select, DatePicker, Modal, Row, Col, Breadcrumb } from 'antd';
import { Navigate, useLocation, useNavigate, Link } from "react-router-dom";
// import { Editor } from '@tinymce/tinymce-react';
// import SideCalendar from '../../side-calendar/SideCalendar';
// import NoticeBoard from '../NoticeBoard';
import { useSelector, useDispatch } from 'react-redux';
// import axios from 'axios';
// import uuid from 'react-uuid';
import NoticeService from '../../../services/NoticeService';
import {
   update_notice_data
} from '../../../actions';
import Utils from '../../../misc/Utils';
import Enums from '../../../misc/Enums';
// import NoticeBoardList from '../NoticeBoardList';
// import { faLeftRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import NoticeBoardSlide from '../../../components/notice-board/NoticeBoardSlide';
import Wysiwyg from '../../../components/wysiwyg/wysiwyg';

function EditNotice(props) {
   const navigate = useNavigate();
   const [title, setTitle] = useState("")
   const [educlevel, setEducLevel] = useState()
   const [gradelevel, setGradeLevel] = useState()
   const [section, setSection] = useState()
   const [sectionList, setSectionList] = useState([])
   const [contentdata, setContentData] = useState("")
   const [disablesection, setDisableSection] = useState(false)
   const [disablelevel, setDisableLevel] = useState(false)
   const [data, setData] = useState()

   const [done, setDone] = useState(false)
   const [effectivityDate, setEffectivityDate] = useState();
   // const [data,setData] = useState(false)
   // const [tables,setTables] = useState({
   //     subjects: [],
   //     acadPrograms: [],
   //     acadProgramLevels: [],
   //     terms: [],
   //     classSections: []
   //   });
   const editorRef = useRef(null);
   const [form] = Form.useForm();
   const dispatch = useDispatch();
   const location = useLocation();
   const lookupSelector = useSelector(state => state.lookup);
   const noticeSelector = useSelector(state => state.noticeboard);

   const { RangePicker } = DatePicker;
   const dateFormat2 = 'MMM D, YYYY h:mm a';
   const dateFormat = 'YYYY-MM-DD HH:mm';
   const customFormat = value => `${value.format(dateFormat)}`;
   // const start_date = moment(noticeSelector.start_date, dateFormat);
   // const end_date  = moment(noticeSelector.end_date, dateFormat);

   useEffect(() => {
      let item = noticeSelector;
      setData(item);
      distribute();
      let effdate = [moment(noticeSelector.start_date, dateFormat), moment(noticeSelector.end_date, dateFormat)];
      setEffectivityDate(effdate);
   }, []);

   // const FormatDate = () => {
   //    if (effectivityDate && effectivityDate.length > 0) {
   //       const d1 = effectivityDate[0];
   //       const d2 = effectivityDate[1];
   //       const arr = [moment(d1, dateFormat), moment(d2, dateFormat)];
   //       return arr;
   //    } else {
   //       console.log('no value');
   //       return  [];
   //    }
   // }

   const handleChangeDate = (date, dateString) => {
      const d = dateString[0];//'Jan 21, 2022, 7:07:00 am';
      const d2 = dateString[1];//'Jan 21, 2022, 7:07:00 am';
      const dvalue = d + ' - ' + d2;
      setEffectivityDate(dateString);
   }

   const handleFormValuesChange = (changedValues) => {
      const formFieldName = Object.keys(changedValues)[0];
      if (formFieldName === "educationallevel") {
         setEducLevel(changedValues[formFieldName]);
         form.setFieldsValue({ level: undefined });
         form.setFieldsValue({ sections: undefined });
         setSectionList([])
      } else if (formFieldName === "level") {
         setGradeLevel(changedValues[formFieldName]);
         form.setFieldsValue({ sections: undefined });
         setSectionList([])
      }
   };

   const distribute = () => {
      setTitle(noticeSelector.title)
      setContentData(noticeSelector.content)
      // setSectionList(lookupSelector.classSections)
      setGradeLevel(noticeSelector.gradelevellist)
      if (noticeSelector.acadlevellist === 'ALL' || noticeSelector.acadlevellist === '99') {
         setDisableSection(true)
         setDisableLevel(true)
         setSectionList([])
      } else {
         setDisableSection(false)
         setDisableLevel(false)
      }

      if (noticeSelector.gradelevellist === '99') {
         setDisableSection(true)
         setSectionList([])
      } else {
         setDisableSection(false)
         let tempsections = lookupSelector.classSections;
         let filtered = tempsections.filter(item => item.code === `${noticeSelector.gradelevellist}`);
         setSectionList(filtered)
      }

      setEducLevel(noticeSelector.acadlevellist)
   }

   const handleChangeTitle = (e) => {
      setTitle(e)
      var params = { key: `title`, value: e }
      dispatch(update_notice_data(params))
   }


   const handleChangeEducLevel = (e) => {
      setEducLevel(e)
      var params = { key: `acadlevellist`, value: e }
      dispatch(update_notice_data(params))
      if (e === 99) {
         setDisableLevel(true)
         setDisableSection(true)
      } else {
         setDisableLevel(false)
         setDisableSection(false)
      }
   }

   const handleChangeLevel = (e) => {
      let tempsections = lookupSelector.classSections;
      let filtered = tempsections.filter(item => item.code === `${e}`);
      setGradeLevel(e)
      setSectionList(filtered)
      var params = { key: `gradelevellist`, value: e }
      dispatch(update_notice_data(params))
      if (e === 99) {
         setDisableSection(true)
      } else {
         setDisableSection(false)
      }
   }

   const handleChangeSection = (e) => {
      setSection(e);
      var params = { key: `sectionlist`, value: e }
      dispatch(update_notice_data(params))
   }

   // const handleChangeEditor = (content,editor) => {
   //     setContent(content);
   //     var params = {key: `content` ,value : content}
   //     dispatch(update_notice_data(params))
   // }

   const onFinish = (values) => {
      console.log('Success:', values);
      let user = Utils.getCurrentUserInfo(Enums.UserInfo.id)
      var notice_id = noticeSelector.id;
      let gradeLevel = noticeSelector.gradelevellist;
      let sections = noticeSelector.sectionlist;
      let educLevel = noticeSelector.educlevel;
      let effective_start_date = effectivityDate[0];
      let effective_end_date = effectivityDate[1];

      if (typeof educLevel === 'undefined') {
         educLevel = "";
         console.log('is undefined');
      }

      if (typeof gradeLevel === 'undefined') {
         gradeLevel = "";
         console.log('is undefined');
      }
      if (typeof sections === 'undefined') {
         sections = "";
         console.log('is undefined');
      }
      let notice_default = {
         id: notice_id,
         title: noticeSelector.title,
         content: contentdata,
         active: 0,
         issection: 0,
         sectionlist: sections,
         isgradelevel: 0,
         gradelevellist: gradeLevel,
         isacad: 0,
         acadlevellist: noticeSelector.acadlevellist,
         staffid: user,
         start_date: effective_start_date,
         end_date: effective_end_date,
      }
      NoticeService.saveNotice(notice_default).then(response => {
         console.log(response)
         if (response.data) {
            Modal.success({
               title: 'Update Notice Board',
               content: 'Notice Record was updated.',
               centered: true
            });
            setDone(true)
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Update Notice Board',
            content: 'Notice Record update failed.',
            centered: true
         });
         setDone(false)
      });

   };

   return (
      <>
         <NoticeBoardSlide />
         {done && <Navigate to={'/notice'} replace state={{}} />}
         <div className="dashboard-area">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li><a className="active" href="#" onClick={() => navigate('/notice')}>Notice Board</a></li>
                     <li><span>/</span></li>
                     <li><a className="active" >Edit</a></li>
                  </ul> */}
                  <Breadcrumb style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate('/home')}>Home</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <a className="active" href="#" onClick={() => navigate('/notice')}>Notice Board</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Edit</Breadcrumb.Item>
                  </Breadcrumb>
                  <div className='CommonForm'>
                     <Row gutter={12}>
                        <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>

                        </Col>
                     </Row>
                     <Form
                        form={form}
                        className='DetailsForm'
                        colon={false} onFinish={onFinish}
                        // onFinishFailed={onFinishFailed} 
                        onValuesChange={handleFormValuesChange}
                        autoComplete="off"
                     >
                        <Row gutter={12}>
                           <Col xs={24} xl={12}>
                              <Form.Item label="Title" name="title" style={{ display: 'inline' }}
                                 // rules={[
                                 //     { 
                                 //     required: true,
                                 //     message: 'Title should not be blank!'
                                 //     },
                                 // ]} 
                                 initialValue={noticeSelector.title}
                              >
                                 <Input
                                    size="small"
                                    placeholder="Title"
                                    value={noticeSelector.title}
                                    style={{ color: '#000000' }}
                                    onChange={(event) => handleChangeTitle(event.target.value)}
                                 />

                              </Form.Item>
                           </Col>
                           <Col xs={24} xl={12}>
                              <Form.Item label="Educational Level" name="educationallevel" style={{ display: 'inline' }}
                                 // rules={[
                                 //     { 
                                 //     required: true,
                                 //     message: 'Please choose an Educational Level!'
                                 //     },
                                 //     ]}
                                 initialValue={noticeSelector.acadlevellist}
                              >
                                 <Select defaultValue={''} onChange={handleChangeEducLevel}>
                                    <Select.Option value={'99'}>{'All'}</Select.Option>
                                    {lookupSelector.acadPrograms.map((level) => (
                                       <Select.Option value={level.programCode}>{level.description}</Select.Option>
                                    ))}
                                 </Select>
                              </Form.Item>
                           </Col>
                        </Row>
                        {/* <Row gutter={24}>
                                       
                        </Row> */}
                        <Row gutter={12} style={{ paddingBottom: "5px" }}>
                           <Col xs={24} xl={12}>
                              <Form.Item label="Grade Level" name="level" style={{ display: 'inline' }}
                                 // rules={[
                                 // { 
                                 // required: true,
                                 // message: 'Please choose a Level!'
                                 // },
                                 // ]} 
                                 initialValue={noticeSelector.gradelevellist === '' ? '99' : noticeSelector.gradelevellist}
                              >
                                 <Select
                                    style={{ width: '100npm%' }}
                                    value={gradelevel}
                                    onChange={(e) => {
                                       handleChangeLevel(e);
                                    }}
                                    disabled={disablelevel}>
                                    <Select.Option value={'99'}>{'All'}</Select.Option>
                                    {
                                       lookupSelector.acadProgramLevels.filter(item => item.programCode === `${educlevel}`).map((filtered) => (
                                          <Select.Option value={filtered.value}>{filtered.label}</Select.Option>
                                       ))}
                                 </Select>
                              </Form.Item>
                           </Col>
                           <Col xs={24} xl={12}>
                              <Form.Item label="Sections" name="sections" style={{ display: 'inline' }}
                                 // rules={[
                                 // { 
                                 // required: true,
                                 // message: 'Please choose a Section!'
                                 // },
                                 // ]}
                                 initialValue={noticeSelector.gradelevellist === '99' ? '' : noticeSelector.sectionlist}
                              >
                                 <Select
                                    placeholder="Please select"
                                    mode="multiple"
                                    allowClear
                                    showArrow
                                    style={{ width: '100%' }}
                                    // options={lookupSelector.classSections}
                                    options={noticeSelector.gradelevellist === '99' ? '' : sectionList}
                                    onChange={handleChangeSection}
                                    disabled={disablesection}
                                 />

                              </Form.Item>
                           </Col>
                        </Row>

                        <Row gutter={12} style={{ paddingBottom: "10px" }}>
                           <Col className="gutter-row" span={24}>
                              <Form.Item label="Effectivity Date" name="effectivedate" style={{ display: 'inline' }}>
                                 <RangePicker
                                    showTime={{ format: 'hh:mm A', use12Hours: true }}
                                    format={dateFormat2}
                                    // defaultValue={ FormatDate } 
                                    onCalendarChange={handleChangeDate}
                                    defaultValue={[moment(noticeSelector.start_date, dateFormat), moment(noticeSelector.end_date, dateFormat)]}
                                 />
                              </Form.Item>
                           </Col>
                        </Row>
                        {/* <Row gutter={24}>
                           
                        </Row> */}
                        <Row gutter={12}>
                           {/* <div style={{position: "relative", height : "40%", padding : "10px" }}>
                            */}
                           <Col className="gutter-row" span={24}>
                              <label>Content</label>
                              <Wysiwyg 
                                 changeContentCallback={(e) => setContentData(e)} 
                                 content={noticeSelector.content} 
                                 height={400}
                                 contentHeight={360}
                              />
                              {/* <Editor
                                 apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                                 onInit={(evt, editor) => editorRef.current = editor}
                                 onEditorChange={(content) => setContentData(content)}
                                 initialValue={noticeSelector.content}
                                 init={{
                                    menubar: true,
                                    height: 220,
                                    plugins: [
                                       'advlist autolink lists link image charmap print preview anchor',
                                       'searchreplace visualblocks code fullscreen',
                                       'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                       'bold italic backcolor | alignleft aligncenter ' +
                                       'alignright alignjustify | bullist numlist outdent indent | ' +
                                       'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                 }}
                              /> */}
                           </Col>
                           {/* </div> */}
                        </Row>
                        <Row gutter={12}>
                           <Col className="gutter-row" span={24}>
                              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                 <Form.Item className="gutter-row">
                                    <Button style={{ marginLeft: "auto", marginRight: 10, marginTop: 10, background: '#4abec7', color: '#fff' }}
                                       type="primary"
                                       htmlType="submit"
                                       shape='round'
                                    // onClick={ handleNextClick }
                                    >
                                       Save
                                    </Button>
                                 </Form.Item>
                              </div>
                           </Col>
                        </Row>
                     </Form>
                  </div>
               </div>
            </div>
         </div>
         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}
      </>
   );
}

export default EditNotice;