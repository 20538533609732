import React, { useEffect, useState, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
   Divider, Input, Tabs, Table, Typography, Space, Tooltip, Button, Select, Row, Col, message, Popconfirm,
   Radio, Tag, Drawer, notification, Modal, DatePicker, Upload, Form, Spin, List, Tree
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import SimpleBarReact from "simplebar-react";
import axios from 'axios';
// import uuid from 'react-uuid';
// import { SchoolTerms, Levels } from '../../../constants'
import Utils from '../../../misc/Utils';
import Enums from '../../../misc/Enums';
// import { Editor } from '@tinymce/tinymce-react';
import StickyBox from "react-sticky-box";
import moment from 'moment';
import Wysiwyg2 from '../../../components/wysiwyg/wysiwyg2';

export default function Edit(props) {
   const [form] = Form.useForm();
   const editorRef = useRef(null);
   const navigate = useNavigate();

   const [levelList, setLevelList] = useState([]);
   const [secsubLoading, setSecSubLoading] = useState(false);
   const [title, setTitle] = useState("");
   const [level, setLevel] = useState(null);
   const [section, setSection] = useState([]);
   const [subject, setSubject] = useState(null);
   const [description, setDescription] = useState(null);
   const [uploadFileList, setUploadFileList] = useState([]);
   const [subjectList, setSubjectList] = useState([]);
   const [sectionList, setSectionList] = useState([]);
   const [homeworkDate, setHomeworkDate] = useState('');
   const [submissionDate, setSubmissionDate] = useState('');
   const [homeworkData, setHomeworkData] = useState(null);
   const [uploadedFiles, setUploadedFiles] = useState([]);
   const [subdomain, setSubdomain] = useState(localStorage.getItem("lms-subdomain"));

   const [checkedKeys, setCheckedKeys] = useState([]);
   const [selectedKeys, setSelectedKeys] = useState([]);
   const [treeData, setTreeData] = useState([]);
   const [expandedKeys, setExpandedKeys] = useState([]);
   const [assignedStudents, setAssignedStudents] = useState(null);
   const [assignedStudentsWithSection, setAssignedStudentsWithSection] = useState(null);

   const { Option } = Select;

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const dateFormat = 'MMM D, YYYY h:mm a';
   const customFormat = value => `${value.format(dateFormat)}`;

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'co-teacher')
         navigate('/home');

      getLevels();
      // getHomeworkInfo(props.homeworkID);
   }, [props.homeworkID]);

   const getLevels = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/levellist`).then((response) => {
         if (response.data) {
            const data = response.data;
            var tmp_levels = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               tmp_levels.push(obj);
            });

            setLevelList(tmp_levels);
            getHomeworkInfo(props.homeworkID);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getHomeworkInfo = (id) => {
      axios.get(`${process.env.REACT_APP_API_HOMEWORK}/info?id=${id}`).then((response) => {
         if (response.data) {
            const homeworkdata = response.data[0];

            axios.get(`${process.env.REACT_APP_API_LOOKUP}/subjectlist?level=${homeworkdata.class_id}&school_year=16`).then((response) => {
               if (response.data) {
                  const data = response.data;

                  var subjectlist = [];
                  data.map((item) => {
                     let obj = { 'value': item.value, 'name': item.name }
                     subjectlist.push(obj);
                  });

                  setSubjectList(subjectlist);

                  setExpandedKeys([]);

                  setSubject(homeworkdata.subject_id);
                  setTitle(homeworkdata.title);
                  setLevel(homeworkdata.class_id);
                  setHomeworkDate(homeworkdata.homework_date);
                  setSubmissionDate(homeworkdata.submit_date);
                  setDescription(homeworkdata.description);
                  setUploadedFiles(homeworkdata.document.split(','));
                  setAssignedStudents(homeworkdata.students);
                  setAssignedStudentsWithSection(homeworkdata.assigned_students);
                  setHomeworkData(homeworkdata);

                  axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/studentlistperleveljson?level=${homeworkdata.class_id}`).then((response) => {
                     if (response.data) {
                        const data = response.data;

                        setTreeData(data);

                        var students = homeworkdata.assigned_students.split(',');
                        setCheckedKeys(students);
                     }
                  }, (error) => {
                     console.log(error.message);
                  });
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const upload_props = {
      onRemove: file => {
         setUploadFileList(() => {
            const index = uploadFileList.indexOf(file.originFileObj);
            const newFileList = uploadFileList.slice();
            newFileList.splice(index, 1);
            return newFileList;
         });

      },

      beforeUpload: file => {
         const isPNG = file.type === 'image/png';
         const isJPG = file.type === 'image/jpeg';
         const isBMP = file.type === 'image/bmp';
         const isDOC = file.type === 'application/msword' || file.type === 'application/vnd.msword';
         const isDOCX = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
         const isXLS = file.type === 'application/msexcel' || file.type === 'application/vnd.msexcel';
         const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
         const isPPT = file.type === 'application/vnd.ms-powerpoint';
         const isPPTX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
         const isPPSX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow';
         const isPDF = file.type === 'application/pdf';

         const fileValid = isPNG || isJPG || isBMP || isDOC || isDOCX || isXLS || isXLSX || isPPT || isPPTX || isPDF || isPPSX;

         const allowedFileSize = 100;

         if (fileValid) {
            const isLtMB = file.size / 1024 / 1024 <= allowedFileSize;

            if (isLtMB) {
               var list = uploadFileList;
               list.push(file);
               setUploadFileList(list);
            } else {
               Modal.error({
                  title: 'Homework',
                  content: `File should be less than ${allowedFileSize} MB`,
                  centered: true
               });
            }

            // console.log(uploadFileList);
         } else {
            Modal.error({
               title: 'Homework',
               content: 'You are not allowed to upload that file type',
               centered: true
            });
         }

         return false;
      },

      uploadFileList,
   };

   const handleLevelCreateChange = (val) => {
      setSection([]);
      setSubject(null);
      setLevel(val);
      setSecSubLoading(true);

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/subjectlist?level=${val}&school_year=16`).then((response) => {
         if (response.data) {
            const data = response.data;

            var subjectlist = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               subjectlist.push(obj);
            });

            setSubjectList(subjectlist);

            // axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${val}`).then((response) => {
            //    if (response.data) {
            //       const data = response.data;

            //       var sectionlist = [];
            //       data.map((item) => {
            //          let obj = { 'value': item.value, 'name': item.name }
            //          sectionlist.push(obj);
            //       });

            //       setSectionList(sectionlist);
            //       setSecSubLoading(false);
            //    }
            // }, (error) => {
            //    console.log(error.message);
            // });
            axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/studentlistperleveljson?level=${val}`).then((response) => {
               if (response.data) {
                  const data = response.data;
                  setTreeData(data);
                  setCheckedKeys([]);
                  setExpandedKeys([]);

                  setSecSubLoading(false);
               }
            }, (error) => {
               console.log(error.message);
               setTreeData([]);
               setCheckedKeys([]);
               setExpandedKeys([]);

               setSecSubLoading(false);
            });


         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleDescriptionChange = (val) => {
      setDescription(val);
   }

   const handleFormValuesChange = (changedValues) => {
      const formFieldName = Object.keys(changedValues)[0];

      if (formFieldName === "level") {
         setLevel(changedValues[formFieldName]);
         form.setFieldsValue({ section: undefined });
         form.setFieldsValue({ subject: undefined });
         setSubjectList([]);
         setSectionList([]);
         setAssignedStudents([]);
         setAssignedStudentsWithSection([]);
      }
   };

   const handleRemoveUploaded = (item) => {
      var idx = uploadedFiles.indexOf(item);
      var newList = uploadedFiles.slice();
      newList.splice(idx, 1);
      setUploadedFiles(newList);
   }

   const handleCancelCreate = () => {
      setAssignedStudents([]);
      setAssignedStudentsWithSection([]);
      setUploadFileList([]);
      setSection([]);
      setSubject(null);
      setLevel(null);
      form.resetFields();
      props.onCancelCallback();
      // var sectns = homeworkData.sections.split(',');
      // var arrayOfNumbers = (homeworkData.sections.split(',')).map(Number);
      // setSection(arrayOfNumbers);
   }

   const onFinish = (values) => {
      // form.resetFields();
      // let user = Utils.getCurrentUserInfo(Enums.UserInfo.id)
      // var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
      // var homework_id = "homework_" + o2o_mode + "_" + uuid();  
      let hwData = {
         id: homeworkData.id,
         class_id: level,
         session_id: 0, //api will take care of this
         title: title,
         homework_date: moment(homeworkDate).format('YYYY-MM-DD HH:mm:ss'),
         submit_date: moment(submissionDate).format('YYYY-MM-DD HH:mm:ss'),
         subject_id: subject,
         description: description,
         created_by: homeworkData.created_by,
         document: uploadedFiles.join(","),
         sections: '', //section.join(','),
         assigned_students: assignedStudentsWithSection,
         students: assignedStudents,
      }

      props.onFinishCallback(hwData, uploadFileList)
   }

   const onExpand = (expandedKeysValue) => {
      setExpandedKeys(expandedKeysValue);
   };

   const onCheck = (checkedKeysValue) => {
      setCheckedKeys(checkedKeysValue);

      //-- (start) with section ------------------------------
      const selectedStudsWithSection = checkedKeysValue.filter((e) => {
         return e.includes('_');
         //return e.includes('|');
      });

      setAssignedStudentsWithSection(selectedStudsWithSection.length > 0 ? selectedStudsWithSection.join(',') : '');
      //-- (end) with section ------------------------------

      if (selectedStudsWithSection.length > 0) {
         //-- Remove the section part
         for (var i = 0; i < selectedStudsWithSection.length; i++) {
            selectedStudsWithSection[i] = selectedStudsWithSection[i].substring(selectedStudsWithSection[i].indexOf('_') + 1, selectedStudsWithSection[i].length);
            //selectedStudsWithSection[i] = selectedStudsWithSection[i].substring(selectedStudsWithSection[i].indexOf('|') + 1, selectedStudsWithSection[i].length);
         }

         var selected = [...new Set(selectedStudsWithSection)]; //-- Remove duplicate students
         setAssignedStudents(selected.join(','));
      } else {
         setAssignedStudents('');
      }
   };

   const onSelect = (selectedKeysValue, info) => {
      setSelectedKeys(selectedKeysValue);
   };

   return (
      <>
         {homeworkData !== null
            ?
            <Form
               form={form}
               layout={"vertical"}
               colon={false}
               onFinish={onFinish}
               onValuesChange={handleFormValuesChange}
            >
               <Row gutter={12}>
                  <Col xs={24} md={16} lg={16}>
                     <Form.Item
                        name="title"
                        label="Title"
                        rules={[{
                           required: true,
                           message: 'Title should not be blank'
                        }]}
                        initialValue={title}
                     >
                        <Input
                           allowClear
                           size='middle'
                           style={{ borderRadius: 7 }}
                           placeholder="Enter homework title"
                           onBlur={(event) => setTitle(event.target.value)}
                           value={title}
                        />
                     </Form.Item>
                  </Col>
                  <Col xs={24} md={8} lg={8}>
                     <Form.Item
                        name="homework_date"
                        label="Homework Date"
                        rules={[{
                           required: true,
                           message: 'Please enter homework date'
                        }]}
                        initialValue={moment(homeworkDate)}
                     >
                        <DatePicker
                           style={{ width: "100%" }}
                           showTime={{ format: 'hh:mm A', use12Hours: true }}
                           format={customFormat}
                           onBlur={(event) => setHomeworkDate(event.target.value)}
                           value={moment(homeworkDate)}
                        />
                     </Form.Item>
                  </Col>
               </Row>

               <Row gutter={12}>
                  <Col xs={24} md={8} lg={8}>
                     <Form.Item
                        name="level"
                        label="Level"
                        rules={[{
                           required: true,
                           message: 'Please select level'
                        }]}
                        initialValue={level}
                     >
                        <Select
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select Level"
                           onChange={(val) => handleLevelCreateChange(val)}
                           value={level}
                        >
                           {levelList.map(({ value, name }, idx) => {
                              return <Option value={value}>{name}</Option>
                           })}
                        </Select>
                     </Form.Item>
                  </Col>
                  <Col xs={24} md={8} lg={8}>
                     <Form.Item
                        name="subject"
                        label="Subject"
                        rules={[{
                           required: true,
                           message: 'Please select subject'
                        }]}
                        initialValue={subject}
                     >
                        <Select
                           loading={secsubLoading}
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select Subject"
                           value={subject}
                           onChange={(val) => setSubject(val)}
                        >
                           {subjectList.map(({ value, name }, idx) => {
                              return <Option value={value}>{name}</Option>
                           })}
                        </Select>
                     </Form.Item>
                  </Col>
                  <Col xs={24} md={8} lg={8}>
                     <Form.Item
                        name="submission_date"
                        label="Submission Date"
                        rules={[{
                           required: true,
                           message: 'Please enter submission date'
                        }]}
                        initialValue={moment(submissionDate)}
                     >
                        <DatePicker
                           style={{ width: "100%" }}
                           showTime={{ format: 'hh:mm A', use12Hours: true }}
                           format={customFormat}
                           onBlur={(event) => setSubmissionDate(event.target.value)}
                           value={moment(submissionDate)}
                        />
                     </Form.Item>
                  </Col>
               </Row>

               <Row gutter={12} >
                  <Col sm={24} md={8} lg={8}>
                     <Form.Item name="select_students" label="Select Students" style={{ marginBottom: '5px' }}>
                        <SimpleBarReact style={{ maxHeight: "250px", overflow: "auto" }}>
                           <div className="student-div" style={{ height: '250px' }}>
                              <Tree
                                 checkable
                                 onExpand={onExpand}
                                 autoExpandParent={true}
                                 onCheck={onCheck}
                                 checkedKeys={checkedKeys}
                                 onSelect={onSelect}
                                 selectedKeys={selectedKeys}
                                 treeData={treeData}
                                 style={{ paddingBottom: "15px", paddingTop: "15px" }}
                                 defaultExpandParent={true}
                              />

                           </div>

                        </SimpleBarReact>
                     </Form.Item>
                  </Col>
                  <Col xs={24} md={8} lg={8}>
                     <label>Description</label>
                     <Wysiwyg2
                        changeContentCallback={(e) => handleDescriptionChange(e)}
                        content={description}
                        height={250}
                        contentHeight={135}
                     />
                     {/* <Editor
                        apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                        onInit={(evt, editor) => editorRef.current = editor}
                        onFocusOut={(e) => handleDescriptionChange(e.target.innerHTML)}
                        initialValue={description}
                        init={{
                           menubar: false,
                           height: 250,
                           statusbar: true,
                           plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code',
                              'insertdatetime media table paste code help wordcount emoticons'
                           ],
                           toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | emoticons | help',
                           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                     /> */}
                  </Col>
                  <Col sm={24} md={8} lg={8}>
                     <Form.Item name="attached_documents" label="Attach Document(s)">
                        <SimpleBarReact style={{ maxHeight: "250px", overflow: "auto" }}>
                           <StickyBox style={{ zIndex: 1000 }}>
                              <Upload
                                 style={{ display: "block" }}
                                 maxCount={3}
                                 multiple
                                 {...upload_props}
                                 listType="picture"
                                 defaultFileList={[...uploadFileList]}
                                 className="upload-list-inline"
                                 accept=".doc,.docx,application/msword,
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                .xls,.xlsx,.ppt,.pptx,.ppsx,
                                .png,.jpg,.pdf,.bmp"
                              >
                                 <Button shape='round' icon={<AntDIcons.CloudUploadOutlined />} block>Select File</Button>
                              </Upload>
                           </StickyBox>
                        </SimpleBarReact>
                     </Form.Item>
                  </Col>
               </Row>

               <Row gutter={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <Col span={24}>
                     <label>Uploaded Documents</label>
                     <>
                        <SimpleBarReact style={{ maxHeight: "60px", overflow: "auto" }}>
                           <List
                              size="small"
                              // header={<div>Header</div>}
                              // footer={<div>Footer</div>}
                              bordered
                              dataSource={uploadedFiles}
                              renderItem={item =>
                                 <List.Item
                                    actions={[
                                       // <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleRemoveUploaded({item})}/>
                                       <Tooltip placement="top" title='Remove'>
                                          <Popconfirm
                                             title="Are you sure you want to remove this document?"
                                             onConfirm={() => handleRemoveUploaded(item)}
                                             okText="Yes"
                                             cancelText="No"
                                          >
                                             <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                          </Popconfirm>
                                       </Tooltip>
                                    ]}>
                                    <a target={"_blank"} href={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${process.env.REACT_APP_S3_HOMEWORKS_TEACHERS_PATH}/${item}`}>{item}</a>
                                 </List.Item>}
                           />
                        </SimpleBarReact>
                     </>
                  </Col>
               </Row>

               <Row gutter={12}>
                  <Col span={24} style={{ textAlign: "right" }}>
                     <Space direction='horizontal'>
                        <Button shape='round' onClick={() => { handleCancelCreate(); }}>Cancel</Button>
                        <Button type='primary' shape='round' htmlType='submit'>Save</Button>
                     </Space>
                  </Col>
               </Row>
            </Form>
            :
            <div className='temp-answer-sheet-content2' style={{ height: "480px" }}>
               <Spin size='large' tip="Loading, please wait..." />
            </div>
         }
      </>
   );
}