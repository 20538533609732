function TemplateIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.5 10V5a.5.5 0 0 0-.5-.5h-4.5V10h5zm0 1.5h-5v8H19a.5.5 0 0 0 .5-.5v-7.5zm-6.5-7H5a.5.5 0 0 0-.5.5v14c0 .28.22.5.5.5h8v-15zM5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2z"
      ></path>
    </svg>
  )
}

function ObjectIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M3.5 20.61h6v-6h-6v6zm-.5-7.5h7a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1zm11.54-3.5h5.92L17.5 4.06l-2.96 5.55zm7.63.03a1 1 0 0 1-.88 1.47H13.7a1 1 0 0 1-.88-1.47l3.79-7.11a1 1 0 0 1 1.76 0l3.8 7.11zM17.5 20.81a3.2 3.2 0 1 0 0-6.4 3.2 3.2 0 0 0 0 6.4zM4.68 7.4l2 2 3.38-3.37a1.41 1.41 0 1 0-2-2L6.68 5.4 5.32 4.02a1.42 1.42 0 0 0-2 2L4.68 7.4zm-2.12 0l-.3-.31a2.91 2.91 0 1 1 4.12-4.13l.3.31.31-.3a2.92 2.92 0 0 1 4.13 4.12L7.39 10.8a1 1 0 0 1-1.41 0L2.56 7.4zM17.5 22.3a4.7 4.7 0 1 1 0-9.4 4.7 4.7 0 0 1 0 9.4z"
      ></path>
    </svg>
  )
}

function TextIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18 5.5h-5.25V18c0 .28.22.5.5.5h2a.75.75 0 1 1 0 1.5h-6.5a.75.75 0 1 1 0-1.5h2a.5.5 0 0 0 .5-.5V5.5H6a.5.5 0 0 0-.5.5v1.25a.75.75 0 0 1-1.5 0V5.5C4 4.67 4.67 4 5.5 4h13c.83 0 1.5.67 1.5 1.5v1.75a.75.75 0 1 1-1.5 0V6a.5.5 0 0 0-.5-.5z"
      ></path>
    </svg>
  )
}

function ImageIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm0 1.5a.5.5 0 0 0-.5.5v14c0 .28.22.5.5.5h14a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5H5zm5.75 10.1l3.05-4.15a2 2 0 0 1 3.22-.01L21 15.78V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-.09l3.82-5.25a2 2 0 0 1 3.22 0l.7.95zm3.6 4.9H19a.5.5 0 0 0 .5-.5v-2.72l-3.69-4.94a.5.5 0 0 0-.8 0l-3.33 4.53 2.68 3.63zm-5.51-4.96a.5.5 0 0 0-.81 0l-3.44 4.74a.5.5 0 0 0 .41.22h7.5l-3.66-4.96zM8.5 10a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
      ></path>
    </svg>
  )
}

function VideoIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2zm0 1.5a.5.5 0 0 0-.5.5v10s.224.5.5.5h16s.5-.224.5-.5V7a.5.5 0 0 0-.5-.5H4zM14.5 12L10 9.5v5l4.5-2.5z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

function BackgroundIcon() {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M506.283,161.216l-245.333-128c-3.093-1.621-6.784-1.621-9.877,0l-245.333,128C2.219,163.051,0,166.677,0,170.667
       c0,3.989,2.219,7.616,5.739,9.451l245.333,128c1.536,0.811,3.243,1.216,4.928,1.216s3.392-0.405,4.928-1.216l245.333-128
       c3.52-1.835,5.739-5.461,5.739-9.451S509.781,163.051,506.283,161.216z M256,286.656L33.728,170.667L256,54.677l222.272,115.989
       L256,286.656z"
      />

      <path
        fill="currentColor"
        d="M506.283,246.528l-35.669-18.603c-5.205-2.667-11.669-0.704-14.4,4.523c-2.731,5.227-0.704,11.669,4.523,14.4L478.272,256
       L256,371.989L33.728,256l17.557-9.131c5.227-2.731,7.253-9.173,4.523-14.4c-2.731-5.248-9.195-7.189-14.4-4.523L5.739,246.549
       C2.219,248.384,0,252.011,0,256s2.219,7.616,5.739,9.451l245.333,128c1.536,0.811,3.243,1.216,4.928,1.216
       s3.392-0.405,4.928-1.216l245.333-128c3.52-1.835,5.739-5.461,5.739-9.451S509.781,248.384,506.283,246.528z"
      />

      <path
        fill="currentColor"
        d="M506.283,331.861l-35.669-18.603c-5.205-2.667-11.669-0.704-14.4,4.523c-2.731,5.227-0.704,11.669,4.523,14.4
       l17.536,9.152L256,457.323L33.728,341.333l17.557-9.131c5.227-2.731,7.253-9.173,4.523-14.4c-2.731-5.227-9.195-7.211-14.4-4.523
       L5.739,331.883C2.219,333.717,0,337.344,0,341.333c0,3.989,2.219,7.616,5.739,9.451l245.333,128
       c1.536,0.811,3.243,1.216,4.928,1.216s3.392-0.405,4.928-1.216l245.333-128c3.52-1.835,5.739-5.461,5.739-9.451
       C512,337.344,509.781,333.717,506.283,331.861z"
      />
    </svg>
  )
}
function ProjectIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="24" height="24">
      <path
        fill="currentColor"
        d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16z"
      ></path>
    </svg>
  )
}
function LayerIcon() {
  return (
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 256 256" enable-background="new 0 0 256 256" xml="preserve" width="30" height="30">
  <g><g><g><path fill="#FFFFFF" d="M117.5,11.5c-1.3,0.2-5.5,0.8-9.4,1.4c-22.6,3.3-46.7,15.7-63.8,32.8C-0.4,90.5-1.6,161.9,41.8,207.7c17.9,19,40.5,31.2,66.1,35.6c17.3,3,41.2,1.3,57.5-4.2c61.9-20.7,95.1-86.7,74.5-148.1c-15.4-46.2-58.8-78.1-108.3-79.5C125.1,11.2,118.8,11.2,117.5,11.5z M146.2,28c20.4,3.4,43.5,16,57.3,31.1c23.6,25.9,32.6,61,23.8,95c-2.4,9.5-10.6,26.3-16.7,34.3c-14.7,19.3-33.8,32-58.2,38.7c-6.1,1.7-9.9,2-24.1,2c-15.4,0.1-17.7-0.2-26.1-2.4c-10.4-2.8-23.9-8.9-32.7-14.7c-7.8-5.3-21.9-19.4-26.9-27.2c-10.8-16.4-16-32.2-16.8-51.2c-0.7-14.5,0.6-24.7,4.4-36.2C46.5,48.2,94.9,19.4,146.2,28z"/><path fill="#000000" d="M171.1,76.3c-7.6,6.4-4.5,19.6,5.1,21.4c3.7,0.8,8.5-1,11.2-4c2.4-2.7,3.1-10.2,1.1-14C185.4,73.6,176.4,71.9,171.1,76.3z"/><path fill="#FFFFFF" d="M83.9,129.9c-7.9,28.8-14.3,52.9-14.3,53.6c0,0.9,2.5,1.1,11,0.9l11-0.3l3.3-13.4l3.3-13.3H112h13.7l3.7,13.6l3.6,13.6h11.5c9.1,0,11.5-0.3,11.5-1.2c0-0.8-6.6-24.7-14.5-53.2c-8-28.5-14.5-52-14.5-52.2c0-0.2-6.5-0.4-14.4-0.4H98.2L83.9,129.9z M116.6,116.5c2.5,10.3,5,19.5,5.3,20.4c0.5,1.6-0.4,1.7-9.8,1.7h-10.3l3-12c1.7-6.6,3.8-15.9,4.8-20.6c0.9-4.8,1.9-8.5,2-8.4C111.7,97.7,114,106.2,116.6,116.5z"/><path fill="#FFFFFF" d="M167.9,107.3c-0.4,0.3-0.7,17.8-0.7,38.9v38.3h10.8h10.8v-38.9v-38.9h-10.1C173.1,106.7,168.2,107,167.9,107.3z"/></g></g></g>
  </svg>
  )
}

function WebIcon()
{
  return(
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
<g>
	<path fill="#FFFFFF" d="M230.396,389.574c-35.522,0-69.865-14.233-95.169-39.253c-8.437,3.633-17.598,5.544-26.832,5.544
		c-37.351,0-67.735-30.385-67.735-67.744c0-3.991,0.382-8.039,1.13-12.087C15.517,256.599,0,226.052,0,193.212
		c0-51.649,38.985-95.495,89.707-102.087c12.705-33.238,44.35-55.209,80.367-55.209c31.433,0,60.176,17.176,75.254,44.366
		c10.86-3.755,22.183-5.657,33.782-5.657c57.144,0,103.631,46.479,103.631,103.615c0,2.398-0.106,4.837-0.317,7.421
		c25.67,10.047,43.065,35.05,43.065,63.029c0,39.667-34.53,71.45-74.726,67.378C327.63,360.913,281.037,389.574,230.396,389.574z
		 M138.641,330.26l4.308,4.658c22.89,24.76,53.949,38.399,87.447,38.399c46.365,0,88.87-27.304,108.289-69.572l2.666-5.828
		l6.283,1.236c3.381,0.667,6.779,1.008,10.104,1.008c28.385,0,51.486-23.101,51.486-51.486c0-23.044-15.534-43.431-37.765-49.568
		l-6.861-1.886l0.967-7.047c0.618-4.56,0.91-8.348,0.91-11.949c0-48.17-39.196-87.358-87.374-87.358
		c-11.957,0-23.573,2.398-34.546,7.121l-7.397,3.186l-3.251-7.364c-11.177-25.296-36.237-41.651-63.842-41.651
		c-30.694,0-57.469,19.663-66.63,48.934l-1.642,5.251l-5.487,0.423c-44.886,3.447-80.05,41.415-80.05,86.431
		c0,29.263,14.631,56.364,39.139,72.507l4.91,3.235l-1.544,5.674c-1.227,4.544-1.853,9.072-1.853,13.493
		c0,28.385,23.093,51.486,51.478,51.486c8.616,0,17.143-2.178,24.678-6.292L138.641,330.26z"/>
</g>
</svg>
  )
}

function ThirdParty()
{
  return (
<svg fill="#000000" height="24" width="24" xmlns="http://www.w3.org/2000/svg" 
	 viewBox="0 0 24.637 24.637" xml="preserve">
<g>
	<path fill="#FFFFFF" d="M18.537,6.945H1.432C0.641,6.945,0,7.582,0,8.369v14.262c0,0.785,0.641,1.426,1.432,1.426h17.105
		c0.785,0,1.426-0.641,1.426-1.426V8.369C19.963,7.582,19.322,6.945,18.537,6.945z M6.817,8.016c0.395,0,0.712,0.318,0.712,0.713
		c0,0.393-0.317,0.713-0.712,0.713c-0.392,0-0.71-0.32-0.71-0.713C6.107,8.334,6.426,8.016,6.817,8.016z M4.639,8.016
		c0.391,0,0.71,0.318,0.71,0.713c0,0.393-0.319,0.713-0.71,0.713c-0.397,0-0.717-0.32-0.717-0.713
		C3.922,8.334,4.241,8.016,4.639,8.016z M2.494,8.016c0.396,0,0.715,0.318,0.715,0.713c0,0.393-0.318,0.713-0.715,0.713
		c-0.39,0-0.709-0.32-0.709-0.713C1.785,8.334,2.104,8.016,2.494,8.016z M18.537,22.631H1.432V10.527h17.105
		C18.537,10.527,18.537,22.631,18.537,22.631z M18.537,9.101H8.559V8.387h9.979L18.537,9.101L18.537,9.101z"/>
	<path fill="#FFFFFF" d="M23.209,0.58H6.102c-0.79,0-1.426,0.637-1.426,1.426v4.133h1.426V4.162h17.107v12.104h-2.483v1.426h2.483
		c0.786,0,1.428-0.641,1.428-1.426V2.006C24.637,1.217,23.995,0.58,23.209,0.58z M7.17,3.076c-0.394,0-0.711-0.316-0.711-0.711
		c0-0.398,0.317-0.713,0.711-0.713c0.393,0,0.713,0.314,0.713,0.713C7.883,2.76,7.562,3.076,7.17,3.076z M9.309,3.076
		c-0.396,0-0.713-0.316-0.713-0.711c0-0.398,0.316-0.713,0.713-0.713c0.394,0,0.714,0.314,0.714,0.713
		C10.022,2.76,9.702,3.076,9.309,3.076z M11.494,3.076c-0.396,0-0.717-0.316-0.717-0.711c0-0.398,0.32-0.713,0.717-0.713
		c0.389,0,0.707,0.314,0.707,0.713C12.201,2.76,11.883,3.076,11.494,3.076z M23.209,2.734h-9.98V2.019h9.98V2.734z"/>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
</g>
</svg>    
  )
}

function MyFiles()
{
  return(
<svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill="#FFFFFF" id="Vector" d="M9 6H9.33687C9.58146 6 9.70385 6 9.81893 6.02763C9.92097 6.05213 10.0189 6.09263 10.1084 6.14746C10.2093 6.20928 10.2959 6.29591 10.4688 6.46875L13.5315 9.53149C13.7044 9.70444 13.7904 9.79044 13.8523 9.89135C13.9071 9.98082 13.9482 10.0786 13.9727 10.1807C14 10.2946 14 10.4155 14 10.6552V18M9 6H4.59961C4.03956 6 3.75981 6 3.5459 6.10899C3.35774 6.20487 3.20487 6.35774 3.10899 6.5459C3 6.75981 3 7.04004 3 7.6001V19.4001C3 19.9601 3 20.2398 3.10899 20.4537C3.20487 20.6419 3.35774 20.7952 3.5459 20.8911C3.7596 21 4.03902 21 4.598 21L12.4011 21C12.96 21 13.2405 21 13.4542 20.8911C13.6423 20.7952 13.7948 20.6421 13.8906 20.4539C13.9996 20.24 14 19.9599 14 19.3999V18M9 6V9.4C9 9.96005 9 10.2399 9.10899 10.4538C9.20487 10.642 9.35774 10.7952 9.5459 10.8911C9.7596 11 10.039 11 10.598 11H13.9996M10 6.0001V4.6001C10 4.04005 10 3.75981 10.109 3.5459C10.2049 3.35774 10.3577 3.20487 10.5459 3.10899C10.7598 3 11.0396 3 11.5996 3H16M16 3H16.3369C16.5815 3 16.7038 3 16.8189 3.02763C16.921 3.05213 17.0189 3.09263 17.1084 3.14746C17.2093 3.20928 17.2959 3.29592 17.4688 3.46875L20.5315 6.53149C20.7044 6.70444 20.7904 6.79044 20.8523 6.89135C20.9071 6.98082 20.9482 7.07863 20.9727 7.18066C21 7.29458 21 7.41552 21 7.65515V16.3999C21 16.9599 20.9996 17.24 20.8906 17.4539C20.7948 17.6421 20.6429 17.7952 20.4548 17.8911C20.2411 18 19.961 18 19.402 18H14M16 3V6.4C16 6.96005 16 7.23988 16.109 7.4538C16.2049 7.64196 16.3577 7.79524 16.5459 7.89111C16.7596 8 17.039 8 17.598 8H20.9996" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>    
  )
}

const PanelIcons = {
  project: {
    render: () => <ProjectIcon />,
  },
  vector: {
    render: () => <TemplateIcon />,
  },
  objects: {
    render: () => <ObjectIcon />,
  },
  images: {
    render: () => <ImageIcon />,
  },
  videos: {
    render: () => <VideoIcon />,
  },
  ai: {
    render: () => <LayerIcon />,
  },
  texts: {
    render: () => <TextIcon />,
  },
  background: {
    render: () => <BackgroundIcon />,
  },
  web: {
    render: () => <WebIcon />,
  },  
  thirdparty :
  {
    render : () => <ThirdParty/>
  },  
  myfiles :
  {
    render : () => <MyFiles/>
  }  
}

export default PanelIcons
