import React from "react"
import VideoImageElement from "./Components/VideoImageElement"
import TextElement from "./Components/TextElement"
import ObjectElement from "./Components/ObjectElement"
import FilesElement from "./Components/FileElement"
const componentMap = {
  TEXT: TextElement,
  IMAGE: VideoImageElement,
  VIDEO: VideoImageElement,
  OBJECT: ObjectElement,
  WEB: VideoImageElement,
  FILE:  FilesElement
}

const CanvasComponent = props => {
  const { dimension, position, id, type, metadata, backgroundcolor } = props
  const [isReadOnly, setIsReadOnly] = React.useState(true)

  const getComponent = () => {
    const Component = type && componentMap[type]
    if (!Component || !id) return null
    return (
      <Component
        key={id}
        id={id}
        type={type}
        position={position}
        dimension={dimension}
        metadata={metadata}
        isreadonly={isReadOnly}
        backgroundcolor={backgroundcolor}
      />
    )
  }



  return (
    getComponent()
  )
}

export default CanvasComponent
