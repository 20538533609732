import React, { useState, useEffect } from "react"
import ReactHtmlParser from "react-html-parser"
import "react-quill/dist/quill.snow.css"
import "./TextElements.scss"


const TextElement = props => {
  const { metadata, id, iseeadonly, dimension,position, type } = props
  const [text, setText] = useState("");
  const elementRef = React.useRef(null)
  const [showGrids, setShowGrids] = React.useState(false)


  useEffect(() => {
    setText(metadata.content)
  }, []);


  return (
      <div
          ref={elementRef}  
            style={{
              position : "absolute",
              left: position?.left || 0, 
              top: position?.top || 0,
              transform : dimension?.transform || "translate(1px, 1px)",
              width: dimension?.width || 0, 
              height: dimension?.height || 0,                    
              fontFamily: "Arial",
              fontSize: "13px",
              padding: 0,
              float : "left",
              border: `2px solid ${
                showGrids 
                  ? "#21DEE5"
                  : "transparent"
              }`,               
            }}
      >
        <div className="textDecoration" style={{height : "100%", width : "100%", textAlign : "left", wordWrap : "break-word"}}>
           {ReactHtmlParser(metadata.content || "")}
        </div>
      </div>
  )
}

export default TextElement
