/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unreachable */
/* eslint-disable no-eval */
/* eslint-disable default-case */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
   Input, Table, Typography, Space, Tooltip, Button, Badge, Tag, Descriptions, Divider,
   message, Select, Row, Col, Dropdown, Menu, Modal, List, Avatar, Spin, InputNumber, Breadcrumb
} from 'antd';
import { SearchOutlined, EyeOutlined, EditOutlined, PlusOutlined, CheckCircleOutlined, MinusCircleTwoTone, UserOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import moment from 'moment';
// import QuestionCard from '../../components/assessment/QuestionCard';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import SimpleBarReact from "simplebar-react";
import './MasteryAssessment.css';
import './Tracker.css';
// import { MasteryTerms, MasteryCompetency, MasteryGradeLevels, MasterySubjects } from '../../constants'
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { useSelector, useDispatch } from 'react-redux';
import { set_mastery } from '../../actions';
// import { Editor } from '@tinymce/tinymce-react';
import Wysiwyg2 from '../../components/wysiwyg/wysiwyg2';
import ZoomPicker from '../../components/zoom-picker/ZoomPicker';
import CreateRecommendations from './Components/CreateRecommendations';
import EditRecommendations from './Components/EditRecommendations';
import DocumentViewer from '../../components/document-viewer/DocumentViewer';
import uuid from 'react-uuid';
import ViewRecommendations from './Components/ViewRecommendations';
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import { PDFReader } from 'reactjs-pdf-reader';
// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Text, Title } = Typography;
const { TextArea } = Input;

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let essayIdx = -1;
let assessment_type = "";
let g_current_student_name = null;
let g_current_student_id = null;
let g_mastery_result = {
   rec_id: null,
   rec_desc: null,
   rec_docs: null,
   rec_doc_names: null,
   rec_status: null,
};
let g_activity = {
   act_docs: null,
   act_doc_names: null,
   act_remarks: null,
   act_study_plan_type: null,
}

let g_recommendation_data = {
   recommendation_id: null,
   mastery_assignment_id: null,
   description: null,
   documents: null,
   document_names: null,
   student_id: -1,
}

let pdfFile = '';
let g_section = '';

export function MasteryTracker() {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const editorRef = useRef(null);

   const { Option } = Select;

   const search = window.location.search;
   const query = new URLSearchParams(search);

   const assign_id = query.get('assign_id');
   const assessment_id = query.get('assessment_id');
   const origin = query.get('origin');
   const subject = query.get('subject');
   const subjectname = query.get('subjectname');

   // const subdomain = localStorage.getItem("lms-subdomain");
   const subdomain = "ccph";

   const masteryrec = useSelector(state => state.masteryrec);

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   const [assignInfo, setAssignInfo] = useState(null);
   const [trackerList, setTrackerList] = useState([]);
   const [legendCount, setLegendCount] = useState({
      submitted: 0,
      started: 0,
      not_started: 0,
      viewing: 0,
      incomplete: 0,
   });
   const [trackerLoading, setTrackerLoading] = useState(false);
   const [subjectList, setSubjectList] = useState([]);
   const [masteryResult, setMasteryResult] = useState([]);
   const [activeExpRow, setActiveExpRow] = useState();

   const [checkEssayModalVisible, setCheckEssayModalVisible] = useState(false);
   const [selectedItem, setSelectedItem] = useState('');
   const [selectedStudentName, setSelectedStudentName] = useState("");
   const [essayScore, setEssayScore] = useState(0);
   const [essayScoreMulti, setEssayScoreMulti] = useState([]);
   const [evaluating, setEvaluating] = useState(false);
   const [essayIndex, setEssayIndex] = useState(-1);
   const [assessmentType, setAssessmentType] = useState("");
   const [essayEvalTitle, setEssayEvalTitle] = useState("");
   const [allowingProgress, setAllowingProgress] = useState(false);
   const [essayComments, setEssayComments] = useState([]);

   const [tmpEssayScore, setTmpEssayScore] = useState({
      value: 0,
      points: 0,
   });

   const [tmpEssayComment, setTmpEssayComment] = useState("");

   const [currentRecommendationDoc, setCurrentRecommendationDoc] = useState(null);
   const [viewRecommendationModalVisible, setViewRecommendationModalVisible] = useState(false);
   const [recommendationType, setRecommendationType] = useState(null);

   const [currentStudyPlanActiviyDoc, setCurrentStudyPlanActiviyDoc] = useState(null);
   const [viewStudyPlanActivityModalVisible, setViewStudyPlanActivityModalVisible] = useState(false);

   const [activityUpdating, setActivityUpdating] = useState(false);

   const [essayStudentIdx, setEssayStudentIdx] = useState(-1);

   const [creating, setCreating] = useState(false);
   const [updating, setUpdating] = useState(false);
   const [createRecommendationModalVisible, setCreateRecommendationModalVisible] = useState(false);
   const [editRecommendationModalVisible, setEditRecommendationModalVisible] = useState(false);

   const [selectedDocumentIdx, setSelectedDocumentIdx] = useState(-1);
   const [showQuestionaire, setShowQuestionaire] = useState(false);

   const [MasteryTerms, setMasteryTerms] = useState([]);
   const [MasteryCompetency, setMasteryCompetency] = useState([]);
   const [MasteryGradeLevels, setMasteryGradeLevels] = useState([]);
   const [MasterySubjects, setMasterySubjects] = useState([]);

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);
   const user_other_data = JSON.parse(Utils.getCurrentUserInfo(Enums.UserInfo.otherdata));
   const g_zoom_meeting_id = user_other_data.ZoomAccount;
   const g_email = user_other_data.Email;

   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: "",
      Term: "",
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: [],
      Level: "",
      Subject: "",
      Level_desc: "",
      Subject_desc: ""
   });

   const [answerInfo, setAnswerInfo] = useState({
      Id: "",
      Answers: "",
      Score: "",
      Total_points: "",
      Start_date: "",
      End_date: "",
      Essay_multi_comments: "",
   });

   // const [zoomPickerLoading, setZoomPickerLoading] = useState(false);
   const [zoomPickerModal, setZoomPickerModal] = useState(false);

   const [showColorLegendModal, setShowColorLegendModal] = useState(false);
   const [showColorLegendSPModal, setShowColorLegendSPModal] = useState(false);

   const CancelToken = axios.CancelToken;
   const source = CancelToken.source();

   useEffect(() => {
      Utils.verifyJwt();

      // const unloadCallback = (event) => {
      // //   event.preventDefault();
      // //   event.returnValue = "";
      // // localStorage.clear();
      //   return "";
      // };

      // window.addEventListener("beforeunload", unloadCallback);
      // return () => window.removeEventListener("beforeunload", unloadCallback);

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'co-teacher') {
         navigate('/home');
      }
      else {
         getMasteryLookUps();
      }

      return () => {
         source.cancel()
      }
   }, [assign_id]);

   useEffect(() => {
      essayIdx = -1;
      idxCol = [];
      qcardIdx = 1;
   },);

   const getMasteryLookUps = () => {
      var _masteryTerms = [];
      var _masteryCompetency = [];
      var _masteryGradeLevels = [];
      var _masterySubjects = [];

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasteryterms`, {
         cancelToken: source.token
      }).then((response) => {
         if (response.data) {
            const data = response.data;
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               _masteryTerms.push(obj);
            });

            axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterysubjects`, {
               cancelToken: source.token
            }).then((response) => {
               if (response.data) {
                  const data = response.data;
                  data.map((item) => {
                     let obj = { 'value': item.value, 'name': item.name }
                     _masterySubjects.push(obj);
                  });

                  axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterygradelevels`, {
                     cancelToken: source.token
                  }).then((response) => {
                     if (response.data) {
                        const data = response.data;
                        data.map((item) => {
                           let obj = { 'value': item.value, 'name': item.name }
                           _masteryGradeLevels.push(obj);
                        });

                        axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterycompetency`, {
                           cancelToken: source.token
                        }).then((response) => {
                           if (response.data) {
                              const data = response.data;
                              data.map((item) => {
                                 let obj = { 'value': item.value, 'name': item.name }
                                 _masteryCompetency.push(obj);
                              });
                           }

                           getMasteryInfo(assessment_id);

                           setMasteryTerms(_masteryTerms);
                           setMasteryCompetency(_masteryCompetency);
                           setMasteryGradeLevels(_masteryGradeLevels);
                           setMasterySubjects(_masterySubjects);
                        }, (error) => {
                           if (axios.isCancel(error)) {
                              console.log('Successfully aborted!');
                           } else {
                              console.log(error.message);
                           }
                        });
                     }
                  }, (error) => {
                     if (axios.isCancel(error)) {
                        console.log('Successfully aborted!');
                     } else {
                        console.log(error.message);
                     }
                  });
               }
            }, (error) => {
               if (axios.isCancel(error)) {
                  console.log('Successfully aborted!');
               } else {
                  console.log(error.message);
               }
            });
         }
      }, (error) => {
         if (axios.isCancel(error)) {
            console.log('Successfully aborted!');
         } else {
            console.log(error.message);
         }
      });
   }

   const getMasteryInfo = (id) => {
      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/info?id=${id}`, {
         cancelToken: source.token
      }).then((response) => {
         if (response.data) {
            const data = response.data;

            var mastery_data = {
               id: data.id,
               title: data.title,
               term: data.term,
               level: data.level,
               subject: data.subject,
               formative_1: data.formative_1,
               formative_1_file_url: data.formative_1_file_url,
               formative_2: data.formative_2,
               formative_2_file_url: data.formative_2_file_url,
               formative_3: data.formative_3,
               formative_3_file_url: data.formative_3_file_url,
               summative: data.summative,
               summative_file_url: data.summative_file_url,
               study_plan_1: data.study_plan_1,
               study_plan_2: data.study_plan_2,
               mastery_level: data.mastery_level,
               mastery_competency: data.competency,
               created_by: data.created_by,
               mode: data.mode,
               // recommendation_id: data.recommendation_id,
               // recommendation_desc: data.recommendation_desc,
               // recommendation_docs: data.recommendation_docs,
               // recommendation_doc_names: data.recommendation_doc_names,
            }

            dispatch(set_mastery(mastery_data));

            getSubjectList(data.level);
         }
      }, (error) => {
         if (axios.isCancel(error)) {
            console.log('Successfully aborted!');
         } else {
            console.log(error.message);
         }
      });
   }

   const getSubjectList = (grade_level) => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/subjectlist?level=${grade_level}&school_year=16`).then((response) => {
         if (response.data) {
            const data = response.data;
            var slist = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               slist.push(obj);
            });

            setSubjectList(slist);

            getAssignInfo(assign_id);
            getTrackerList(assign_id);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getAssignInfo = (assign_id) => {
      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/assigninfo?id=${assign_id}`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setAssignInfo(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const tracker_columns = [
      {
         title: 'Answer Id',
         key: 'answer_id',
         dataIndex: 'answer_id',
         hidden: true,
      },
      {
         title: 'Student Id',
         key: 'student_id',
         dataIndex: 'student_id',
         hidden: true,
      },
      {
         title: 'Id',
         key: 'assign_id',
         dataIndex: 'assign_id',
         hidden: true,
      },
      {
         title: 'Assessment Id',
         key: 'assessment_id',
         dataIndex: 'assessment_id',
         hidden: true,
      },
      {
         title: 'Submit Status',
         key: 'submit_status',
         dataIndex: 'submit_status',
         hidden: true,
      },
      {
         title: 'Browser',
         key: 'browser',
         dataIndex: 'browser',
         hidden: true,
      },
      {
         title: 'Browser Version',
         key: 'browser_version',
         dataIndex: 'browser_version',
         hidden: true,
      },
      {
         title: 'OS',
         key: 'os_platform',
         dataIndex: 'os_platform',
         hidden: true,
      },
      {
         title: 'Name',
         key: 'fullname',
         sorter: (a, b) => { return a.fullname.localeCompare(b.fullname) },
         ...getColumnSearchProps('fullname'),
         ellipsis: {
            // showTitle: false,
         },
         render: object => (
            <Tooltip
               // style={{ cursor: 'pointer' }}
               placement="topLeft"
               title={
                  <Space direction='vertical'>
                     <span>Browser: {object.browser}</span>
                     <span>Browser Version: {object.browser_version}</span>
                     <span>OS: {object.os_platform}</span>
                  </Space>
               }
            >
               {object.fullname !== null
                  ?
                  <span style={{ cursor: 'default' }}>
                     {object.fullname.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                  </span>
                  :
                  <></>
               }

            </Tooltip>
         ),
         width: '30%'
      },
      {
         title: 'Section',
         key: 'section',
         sorter: (a, b) => { return a.section.localeCompare(b.section) },
         ...getColumnSearchProps('section'),
         ellipsis: {
            // showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.section}>
               <Text>{object.section}</Text>
            </Tooltip>
         ),
         width: '15%'
      },
      {
         title: 'Progress',
         key: 'assessment_type',
         // sorter: (a, b) => { return a.assessment_type.localeCompare(b.assessment_type) },
         sorter: (a, b) => { return (a.assessment_type === null ? '' : a.assessment_type).localeCompare(b.assessment_type === null ? '' : b.assessment_type) },
         // dataIndex: 'status',
         ellipsis: {
            // showTitle: true,
         },
         render: object => (
            object.answer_id === null
               ?
               <Tag style={{ fontSize: 12 }} color='red'>
                  {'Not Started'}
               </Tag>
               :
               <>
                  {
                     object.submit_status === 1
                        ?
                        <>
                           <Tag style={{ fontSize: 12 }} color={object.for_evaluation ? 'purple' : 'green'}>
                              {(object.assessment_type.includes("study_plan") ? "Completed " : (object.for_evaluation ? "For Evaluation " : "Submitted ")) + Utils.GetTypeLabel(object.assessment_type)}
                           </Tag>
                        </>
                        :
                        <>
                           {
                              object.status === 1
                                 ?
                                 <Tag style={{ fontSize: 12 }} color={'gold'}>
                                    {(object.assessment_type.includes("study_plan") ? "Done studying " : "Answering ") + Utils.GetTypeLabel(object.assessment_type)}
                                 </Tag>
                                 :
                                 <Tag style={{ fontSize: 12 }} color={'gold'}>
                                    {(object.assessment_type.includes("study_plan") ? "Studying " : "Answering ") + Utils.GetTypeLabel(object.assessment_type)}
                                 </Tag>
                           }
                        </>

                  }
               </>
         ),
         width: '29%'
      },
      {
         title: 'Formative Level',
         key: 'formative_level',
         ellipsis: {
            // showTitle: true,
         },
         render: object => (
            object.answer_id === null
               ?
               ''
               :
               <>
                  {
                     RenderFormativeStatus(object)
                  }
               </>

         ),
         width: '12%'
      },
      {
         title: 'Study Plan',
         key: 'study_plan',
         ellipsis: {
            // showTitle: true,
         },
         render: object => (
            object.answer_id === null
               ?
               ''
               :
               <>
                  {
                     RenderStudyPlanStatus(object)
                  }
               </>
         ),
         width: '10%'
      },
      {
         title: 'Duration',
         key: 'duration',
         render: object => (
            <Text>
               {
                  object.start_date !== null && object.end_date !== null
                     ?
                     new Date(moment(object.end_date).diff(object.start_date)).toISOString().substring(11, 19)
                     :
                     ''
               }
            </Text>
         ),
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const RenderFormativeStatus = (object) => {
      var l1_color, l2_color, l3_color;

      const getColor = (percentage) => {
         return Utils.GetProficiency(percentage) === "BEGINNER" ? '#6395F9' : (Utils.GetProficiency(percentage) === 'PROFICIENT' ? '#5AD8A6' : '#8593AE');
      }

      l1_color = object.formative_1_answer_id === null ? '#e5e5e5' : (object.formative_1_submit_status === 0 ? 'gold' : (object.assessment_type === 'formative_1' && object.for_evaluation ? 'purple' : getColor(object.formative_1_percentage)));
      l2_color = object.formative_1_percentage > 0 && Utils.GetProficiency(object.formative_1_percentage) === 'COMPETENT' ? '#85fcf2' : (object.formative_2_answer_id === null ? '#e5e5e5' : (object.formative_2_submit_status === 0 ? 'gold' : (object.assessment_type === 'formative_2' && object.for_evaluation ? 'purple' : getColor(object.formative_2_percentage))));
      l3_color = object.formative_3_answer_id === null ? '#e5e5e5' : (object.formative_3_submit_status === 0 ? 'gold' : (object.assessment_type === 'formative_3' && object.for_evaluation ? 'purple' : getColor(object.formative_3_percentage)));

      return (
         <>
            {
               <Space size={'middle'} style={{ width: "100%" }}>
                  <Tooltip placement='top' title='Show legend'><Badge count={1} color={l1_color} onClick={() => setShowColorLegendModal(true)} style={{ cursor: 'pointer' }}></Badge></Tooltip>
                  <Tooltip placement='top' title='Show legend'><Badge count={2} color={l2_color} onClick={() => setShowColorLegendModal(true)} style={{ cursor: 'pointer' }}></Badge></Tooltip>
                  <Tooltip placement='top' title='Show legend'><Badge count={3} color={l3_color} onClick={() => setShowColorLegendModal(true)} style={{ cursor: 'pointer' }}></Badge></Tooltip>
               </Space>
            }
         </>
      )
   }

   const RenderStudyPlanStatus = (object) => {
      var s1_color, s2_color;

      s1_color = object.study_plan_1_submit_status === 1 ? 'green' : (object.study_plan_1_status === -1 || object.study_plan_1_status === 1 ? 'gold' : '#e5e5e5');
      s2_color = object.study_plan_2_submit_status === 1 ? 'green' : (object.study_plan_2_status === -1 || object.study_plan_2_status === 1 ? 'gold' : '#e5e5e5');

      return (
         <Space size={'middle'} style={{ width: "100%" }}>
            <Tooltip placement='top' title='Show legend'><Badge count={1} color={s1_color} onClick={() => setShowColorLegendSPModal(true)} style={{ cursor: 'pointer' }}></Badge></Tooltip>
            <Tooltip placement='top' title='Show legend'><Badge count={2} color={s2_color} onClick={() => setShowColorLegendSPModal(true)} style={{ cursor: 'pointer' }}></Badge></Tooltip>
         </Space>
      )
   }

   const handleViewAnswer = (student_name, student_id, assessment_type) => {
      let subject = MasterySubjects.find(x => x.value === masteryrec.subject);
      let level = MasteryGradeLevels.find(x => x.value === masteryrec.level);

      //-- Get card background based on subject and level
      // let bg_image = Utils.GetCardBackground(subject.name.toLowerCase(), level.name.toLowerCase());
      let bg_image = Utils.GetCardBackground(subject.name.toLowerCase(), assessment_type);

      // navigate('/masteryassessment/teacher-view-ans-pdf', { state: { student_name, assessment_id, assignInfo, student_id, assessment_type, origin } });
      if (assignInfo.question_type.toString() === '1') {
         navigate('/masteryassessment/teacher-view-ans-pdf', { state: { student_name, assessment_id, assignInfo, student_id, assessment_type, origin, card_bg: bg_image, subject, subjectname } });
      }
      else if (assignInfo.question_type.toString() === '2') {
         navigate('/masteryassessment/teacher-view-ans-manual', { state: { student_name, assessment_id, assignInfo, student_id, assessment_type, origin, card_bg: bg_image, subject, subjectname } });
      }
   }

   const getTrackerList = (assign_id) => {
      // setActiveExpRow([]);
      setTrackerLoading(true);

      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/masteryassessmenttracker?assign_id=${assign_id}`).then((response) => {
         console.log(response);

         if (response.data) {
            setTrackerLoading(false);

            const data = response.data;

            g_section = data[0].section;
            var legend = {
               // submitted: 0,
               // started: 0,
               // not_started: 0,
               // viewing: 0,
               // incomplete: 0,
               // beginner_after_level3: 0,

               level1: 0,
               level2: 0,
               level3: 0,
               studyplan1: 0,
               studyplan2: 0,
               completed: 0,
               not_started: 0,
               students_for_recommendation: 0,
               started: 0,
            }

            for (var i = 0; i < data.length; i++) {
               data[i].key = i;

               //-- insert additional fields for essay checking
               data[i].score = 0;
               data[i].essay_score = 0;
               data[i].essay_score_multi = null;
               data[i].total_points = 0;
               data[i].assessment_submit_status = 0;
               data[i].essay_multi_comments = null;
               data[i].essay_total_points = 0;

               // if (data[i].submit_status === 1 && (data[i].assessment_type === 'formative_3' || data[i].assessment_type === 'summative'))
               //    legend.submitted++;

               // if (data[i].submit_status === 1 && (data[i].assessment_type !== 'formative_3' && data[i].assessment_type !== 'summative')) {
               //    // if (origin === '4') 
               //    legend.incomplete++;
               // }

               // if (data[i].submit_status === 0) {
               //    if (data[i].assessment_type.includes("study_plan"))
               //       legend.viewing++;
               //    else
               //       legend.started++;

               //    // if (origin === '4') 
               //    legend.incomplete++;
               // }

               // if (data[i].submit_status !== 1 && data[i].submit_status !== 0)
               //    legend.not_started++;

               // if (data[i].submit_status === 1 && data[i].assessment_type === 'formative_3' && data[i].proficiency === 'Beginner')
               //    legend.beginner_after_level3++;

               if (data[i].submit_status === 0 && data[i].assessment_type === 'formative_1')
                  legend.level1++;

               if (data[i].submit_status === 0 && data[i].assessment_type === 'formative_2')
                  legend.level2++;

               if (data[i].submit_status === 0 && data[i].assessment_type === 'formative_3')
                  legend.level3++;

               if (data[i].submit_status === 0 && data[i].assessment_type === 'study_plan_1')
                  legend.studyplan1++;

               if (data[i].submit_status === 0 && data[i].assessment_type === 'study_plan_2')
                  legend.studyplan2++;

               if (data[i].submit_status === 1 && (data[i].assessment_type === 'formative_3' || data[i].assessment_type === 'summative'))
                  legend.completed++;

               if (data[i].submit_status !== 1 && data[i].submit_status !== 0)
                  legend.not_started++;

               if (data[i].submit_status === 1 && data[i].assessment_type === 'formative_3' && data[i].proficiency === 'Beginner' && data[i].teacher_recommendation_id === null)
                  legend.students_for_recommendation++;

               if (data[i].submit_status === 0 && data[i].assessment_type === 'summative')
                  legend.started++;
            }

            var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/masteryscores?assessment_id=${assessment_id}&assignment_id=${assign_id}&assessment_type=${assessment_type}`;
            axios.get(url).then(response => {
               if (response.data) {
                  var scores = response.data;

                  for (var i = 0; i < data.length; i++) {
                     for (var ii = 0; ii < scores.length; ii++) {
                        if (scores[ii].student_id === data[i].student_id) {
                           data[i].score = scores[ii].score;
                           data[i].essay_score = scores[ii].essay_score;
                           data[i].total_points = scores[ii].total_points;
                           data[i].essay_score_multi = scores[ii].essay_score_multi;
                           data[i].assessment_submit_status = scores[ii].assessment_submit_status;
                           data[i].essay_multi_comments = scores[ii].essay_multi_comments;
                           data[i].essay_total_points = scores[ii].essay_total_points;
                        }
                     }
                  }

                  // console.log(data);

                  setLegendCount(legend);
                  setTrackerList(data);

                  if (activeExpRow !== undefined) {
                     if (activeExpRow.length > 0) {
                        getMasteryResult(assessment_id, assign_id, g_current_student_id, true, g_current_student_name);
                     }
                  }
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const filterTrackerList = (assign_id, filterby) => {
      const key = 'updatable';
      message.loading({ content: "Filtering Tracker...", key });

      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/masterytrackerfiltered?assign_id=${assign_id}&filter_by=${filterby}`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;

            message.success({ content: "Tracker updated", key, duration: 1 });

            for (var i = 0; i < data.length; i++) {
               data[i].key = i;
            }

            setTrackerList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleUpdateTracker = () => {
      getTrackerList(assign_id);
   }

   const handleFilterTracker = (status_count, filterby) => {
      if (status_count > 0) {
         setActiveExpRow([]);
         filterTrackerList(assign_id, filterby);
      }
   }

   const AllowToProceed = (allow, student_id, result_idx, assessment_type, assignment_id) => {
      setAllowingProgress(true);

      axios.post(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/allowtoproceed?student_id=${student_id}&allow=${allow}&assessment_type=${assessment_type}&assignment_id=${assignment_id}`).then((response) => {
         console.log(response);

         if (response.data) {
            var tempresult = masteryResult;
            tempresult[result_idx].allow_to_proceed = allow;
            setMasteryResult(tempresult);
            setAllowingProgress(false);
         }
      }, (error) => {

         setAllowingProgress(false);
         console.log(error.message);
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Promotion failed. Please try again.',
            centered: true,
         });
      });
   }

   const getMasteryResult = (mastery_id, assign_id, student_id, expanded, student_name) => {
      setMasteryResult([]);

      if (expanded) {
         axios.get(`${process.env.REACT_APP_API_STUDENT_MASTERY_ASSESSMENT}/masteryresult?assessment_id=${mastery_id}&assignment_id=${assign_id}&student_id=${student_id}`).then((response) => {
            if (response.data) {
               const mastery_result_data = response.data;

               g_current_student_id = student_id;
               g_current_student_name = student_name;
               g_mastery_result = {
                  rec_id: mastery_result_data[0].recommendation_id,
                  rec_desc: mastery_result_data[0].recommendation_desc,
                  rec_docs: mastery_result_data[0].recommendation_docs !== null ? mastery_result_data[0].recommendation_docs.split(',') : null,
                  rec_doc_names: mastery_result_data[0].recommendation_doc_names !== null ? mastery_result_data[0].recommendation_doc_names.split(',') : null,
                  rec_status: mastery_result_data[0].recommendation_status,
               };

               setMasteryResult(mastery_result_data);
               RenderMasteryResult(mastery_result_data, student_name, student_id);
            }
         }, (error) => {
            console.log(error.message);
         });
      }
   }

   const RenderMasteryResult = (mastery_result, student_name, student_id) => {
      return <>
         {
            mastery_result.length > 0
               ?
               <Row gutter={12} style={{ width: "80%", textAlign: "center" }} key={'uid'}>
                  <Col xs={24} xl={24}>
                     <Descriptions bordered size='small' column={4} style={{ backgroundColor: "#e5fafd" }}>
                        {
                           masteryrec.summative === null
                              ?
                              <>
                                 <Descriptions.Item label='Formative Level 1' span={2} style={{ fontSize: 12, width: "25%" }}>
                                    <Space direction='horizontal' size={"small"}>
                                       {masteryrec.formative_1 !== null
                                          ?
                                          <>
                                             {
                                                mastery_result[0].mastery_answer_id !== null
                                                   ?
                                                   <>
                                                      {
                                                         mastery_result[0].submit_status === 1
                                                            ?
                                                            <>
                                                               {
                                                                  mastery_result[0].essay_score === 0 && mastery_result[0].essay_score_multi === "[]" && mastery_result[0].essay_total_points > 0
                                                                     ?
                                                                     <Tag color={"purple"} style={{ fontSize: 13 }}>For teacher evaluation</Tag>
                                                                     :
                                                                     <>
                                                                        <Tag color={"green"} style={{ fontSize: 11 }}>Score: {mastery_result[0].score + "/" + mastery_result[0].total_points + ' (' + mastery_result[0].percentage + '%)'}</Tag>
                                                                        <Tag
                                                                           // color={"green"} 
                                                                           color={Utils.GetProficiency(mastery_result[0].percentage) === "BEGINNER" ? '#6395F9' : (Utils.GetProficiency(mastery_result[0].percentage) === 'PROFICIENT' ? '#5AD8A6' : '#8593AE')}
                                                                           style={{ fontSize: 11 }}
                                                                        >
                                                                           {Utils.GetProficiency(mastery_result[0].percentage)}
                                                                        </Tag>
                                                                        <Tooltip placement="top" title={'View ' + Utils.GetTypeLabel(mastery_result[0].assessment_type) + " Answer"}>
                                                                           <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleViewAnswer(student_name, student_id, mastery_result[0].assessment_type)}>
                                                                              <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                                           </Button>
                                                                        </Tooltip>
                                                                     </>
                                                               }
                                                            </>
                                                            :
                                                            <Tag color={"gold"} style={{ fontSize: 11 }}>Answering</Tag>
                                                      }
                                                   </>

                                                   :
                                                   <Tag style={{ fontSize: 11 }}>Not Started</Tag>
                                             }
                                          </>
                                          :
                                          <Tag color={"gray"} style={{ fontSize: 11 }}>Not Available</Tag>
                                       }
                                    </Space>
                                 </Descriptions.Item>
                                 <Descriptions.Item label='Study Plan 1' span={2} style={{ fontSize: 12, width: "25%" }}>
                                    <Space direction='horizontal' size={"small"}>
                                       {masteryrec.study_plan_1 !== null
                                          ?
                                          <>
                                             {
                                                mastery_result[4].mastery_answer_id !== null
                                                   ?
                                                   <>
                                                      {
                                                         mastery_result[4].submit_status === 1
                                                            ?
                                                            <>
                                                               <Tag color={"green"} style={{ fontSize: 11 }}>Completed</Tag>
                                                               <Tooltip placement="top" title={moment(mastery_result[4].start_date).format("MMM. DD, YYYY hh:mm:ss A") + ' To ' + moment(mastery_result[4].end_date).format("MMM. DD, YYYY hh:mm:ss A")}>
                                                                  <Tag color={"green"} style={{ fontSize: 11 }}>
                                                                     Duration {Math.floor((Math.abs(new Date(mastery_result[4].end_date) - new Date(mastery_result[4].start_date)) / 1000 / 60))} min(s)
                                                                  </Tag>
                                                               </Tooltip>
                                                            </>
                                                            :
                                                            <Space size={'small'}>
                                                               {
                                                                  mastery_result[4].status === 1
                                                                     ?
                                                                     <Tag color={"gold"} style={{ fontSize: 11 }}>Done Studying</Tag>
                                                                     :
                                                                     <Tag color={"gold"} style={{ fontSize: 11 }}>Studying</Tag>
                                                               }

                                                               {assignInfo.teacher_intervene === 1 && mastery_result[4].status === 1 &&
                                                                  <>
                                                                     {mastery_result[4].allow_to_proceed === 0 || mastery_result[4].allow_to_proceed === null
                                                                        ?
                                                                        <Button className='button-shadow' shape='round' type='primary' size='small' loading={allowingProgress} onClick={() => AllowToProceed(1, student_id, 4, 'study_plan_1', assignInfo.id)}>
                                                                           Promote to Level 2
                                                                        </Button>
                                                                        :
                                                                        <Button className='button-shadow' shape='round' type='primary' size='small' loading={allowingProgress} onClick={() => AllowToProceed(0, student_id, 4, 'study_plan_1', assignInfo.id)}>Cancel Promotion</Button>
                                                                     }
                                                                  </>
                                                               }

                                                            </Space>
                                                      }

                                                      {
                                                         mastery_result[4].study_plan_activity_docs !== null &&
                                                         <Tooltip placement="top" title="View Submitted Activity">
                                                            <Button className='button-shadow' shape='round' type='primary' size='small' onClick={() => handleViewStudyPlanActivity(mastery_result[4], "study_plan_1")}><EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} /></Button>
                                                         </Tooltip>
                                                      }
                                                   </>

                                                   :
                                                   <Tag style={{ fontSize: 11 }}>Not Viewed</Tag>
                                             }
                                          </>
                                          :
                                          <Tag color={"gray"} style={{ fontSize: 11 }}>Not Available</Tag>
                                       }
                                    </Space>
                                 </Descriptions.Item>

                                 <Descriptions.Item label='Formative Level 2' span={2} style={{ fontSize: 12, width: "25%" }}>
                                    <Space direction='horizontal' size={"small"}>
                                       {masteryrec.formative_2 !== null
                                          ?
                                          <>
                                             {
                                                mastery_result[1].mastery_answer_id !== null
                                                   ?
                                                   <>
                                                      {
                                                         mastery_result[1].submit_status === 1
                                                            ?
                                                            <>
                                                               {
                                                                  mastery_result[1].status === 2 //--Check if exempted
                                                                     ?
                                                                     <Tag color={"#85fcf2"} style={{ fontSize: 11 }}>EXEMPTED</Tag>
                                                                     :
                                                                     <>
                                                                        {
                                                                           mastery_result[1].essay_score === 0 && mastery_result[1].essay_score_multi === "[]" && mastery_result[1].essay_total_points > 0
                                                                              ?
                                                                              <Tag color={"purple"} style={{ fontSize: 13 }}>For teacher evaluation</Tag>
                                                                              :
                                                                              <>
                                                                                 {/* <Tag color={mastery_result[1].percentage > 60 ? "green" : "red"} style={{ fontSize: 11 }}>Score: {mastery_result[1].score + "/" + mastery_result[1].total_points + ' (' + mastery_result[1].percentage + '%)'}</Tag> */}
                                                                                 <Tag color={"green"} style={{ fontSize: 11 }}>Score: {mastery_result[1].score + "/" + mastery_result[1].total_points + ' (' + mastery_result[1].percentage + '%)'}</Tag>
                                                                                 <Tag
                                                                                    // color={"green"} 
                                                                                    color={Utils.GetProficiency(mastery_result[1].percentage) === "BEGINNER" ? '#6395F9' : (Utils.GetProficiency(mastery_result[1].percentage) === 'PROFICIENT' ? '#5AD8A6' : '#8593AE')}
                                                                                    style={{ fontSize: 11 }}
                                                                                 >
                                                                                    {Utils.GetProficiency(mastery_result[1].percentage)}
                                                                                 </Tag>
                                                                                 <Tooltip placement="top" title={'View ' + Utils.GetTypeLabel(mastery_result[1].assessment_type) + " Answer"}>
                                                                                    <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleViewAnswer(student_name, student_id, mastery_result[1].assessment_type)}>
                                                                                       <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                                                    </Button>
                                                                                 </Tooltip>
                                                                              </>
                                                                        }
                                                                     </>
                                                               }

                                                            </>
                                                            :
                                                            <Tag color={"gold"} style={{ fontSize: 11 }}>Answering</Tag>
                                                      }
                                                   </>

                                                   :
                                                   <Tag style={{ fontSize: 11 }}>Not Started</Tag>
                                             }
                                          </>
                                          :
                                          <Tag color={"gray"} style={{ fontSize: 11 }}>Not Available</Tag>
                                       }
                                    </Space>
                                 </Descriptions.Item>
                                 <Descriptions.Item label='Study Plan 2' span={2} style={{ fontSize: 12, width: "25%" }}>
                                    <Space direction='horizontal' size={"small"}>
                                       {masteryrec.study_plan_2 !== null
                                          ?
                                          <>
                                             {
                                                mastery_result[5].mastery_answer_id !== null
                                                   ?
                                                   <>
                                                      {
                                                         mastery_result[5].submit_status === 1
                                                            ?
                                                            <>
                                                               <Tag color={"green"} style={{ fontSize: 11 }}>Completed</Tag>
                                                               <Tooltip placement="top" title={moment(mastery_result[5].start_date).format("MMM. DD, YYYY hh:mm:ss A") + ' To ' + moment(mastery_result[5].end_date).format("MMM. DD, YYYY hh:mm:ss A")}>
                                                                  <Tag color={"green"} style={{ fontSize: 11 }}>
                                                                     Duration {Math.floor((Math.abs(new Date(mastery_result[5].end_date) - new Date(mastery_result[5].start_date)) / 1000 / 60))} min(s)
                                                                  </Tag>
                                                               </Tooltip>
                                                            </>
                                                            :
                                                            <Space size={'small'}>
                                                               {
                                                                  mastery_result[5].status === 1
                                                                     ?
                                                                     <Tag color={"gold"} style={{ fontSize: 11 }}>Done Studying</Tag>
                                                                     :
                                                                     <Tag color={"gold"} style={{ fontSize: 11 }}>Studying</Tag>
                                                               }

                                                               {assignInfo.teacher_intervene === 1 && mastery_result[5].status === 1 &&
                                                                  <>
                                                                     {mastery_result[5].allow_to_proceed === 0 || mastery_result[5].allow_to_proceed === null
                                                                        ?
                                                                        <Button className='button-shadow' shape='round' type='primary' size='small' loading={allowingProgress} onClick={() => AllowToProceed(1, student_id, 5, 'study_plan_2', assignInfo.id)}>Promote to Level 3</Button>
                                                                        :
                                                                        <Button className='button-shadow' shape='round' type='primary' size='small' loading={allowingProgress} onClick={() => AllowToProceed(0, student_id, 5, 'study_plan_2', assignInfo.id)}>Cancel Promotion</Button>
                                                                     }
                                                                  </>
                                                               }
                                                            </Space>
                                                      }

                                                      {
                                                         mastery_result[5].study_plan_activity_docs !== null &&
                                                         <>
                                                            <Tooltip placement="top" title="View Submitted Activity">
                                                               <Button className='button-shadow' shape='round' type='primary' size='small' onClick={() => handleViewStudyPlanActivity(mastery_result[5], "study_plan_2")}><EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} /></Button>
                                                            </Tooltip>
                                                         </>
                                                      }
                                                   </>

                                                   :
                                                   <Tag style={{ fontSize: 11 }}>Not Viewed</Tag>
                                             }
                                          </>
                                          :
                                          <Tag color={"gray"} style={{ fontSize: 11 }}>Not Available</Tag>
                                       }
                                    </Space>
                                 </Descriptions.Item>

                                 <Descriptions.Item label='Formative Level 3' span={2} style={{ fontSize: 12, width: "25%" }}>
                                    <Space direction='horizontal' size={"small"}>
                                       {masteryrec.formative_3 !== null
                                          ?
                                          <>
                                             {
                                                mastery_result[2].mastery_answer_id !== null
                                                   ?
                                                   <>
                                                      {
                                                         mastery_result[2].submit_status === 1
                                                            ?
                                                            <>
                                                               {
                                                                  mastery_result[2].essay_score === 0 && mastery_result[2].essay_score_multi === "[]" && mastery_result[2].essay_total_points > 0
                                                                     ?
                                                                     <Tag color={"purple"} style={{ fontSize: 11 }}>For teacher evaluation</Tag>
                                                                     :
                                                                     <>
                                                                        {/* <Tag color={mastery_result[2].percentage > 60 ? "green" : "red"} style={{ fontSize: 11 }}>Score: {mastery_result[2].score + "/" + mastery_result[2].total_points + ' (' + mastery_result[2].percentage + '%)'}</Tag> */}
                                                                        <Tag color={"green"} style={{ fontSize: 11 }}>Score: {mastery_result[2].score + "/" + mastery_result[2].total_points + ' (' + mastery_result[2].percentage + '%)'}</Tag>
                                                                        <Tag
                                                                           // color={"green"} 
                                                                           color={Utils.GetProficiency(mastery_result[2].percentage) === "BEGINNER" ? '#6395F9' : (Utils.GetProficiency(mastery_result[2].percentage) === 'PROFICIENT' ? '#5AD8A6' : '#8593AE')}
                                                                           style={{ fontSize: 11 }}
                                                                        >
                                                                           {Utils.GetProficiency(mastery_result[2].percentage)}
                                                                        </Tag>
                                                                        <Tooltip placement="top" title={'View ' + Utils.GetTypeLabel(mastery_result[2].assessment_type) + " Answer"}>
                                                                           <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleViewAnswer(student_name, student_id, mastery_result[2].assessment_type)}>
                                                                              <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                                           </Button>
                                                                        </Tooltip>
                                                                     </>
                                                               }
                                                            </>
                                                            :
                                                            <Tag color={"gold"} style={{ fontSize: 11 }}>Answering</Tag>
                                                      }
                                                   </>
                                                   :
                                                   <Tag style={{ fontSize: 11 }}>Not Started</Tag>
                                             }
                                          </>
                                          :
                                          <Tag color={"gray"} style={{ fontSize: 11 }}>Not Available</Tag>
                                       }
                                    </Space>
                                 </Descriptions.Item>

                                 {mastery_result[2].submit_status === 1 //&& Utils.GetProficiency(mastery_result[2].percentage) !== "COMPETENT"
                                    ?
                                    <Descriptions.Item label='Recommendation' span={2} style={{ fontSize: 12, width: "25%" }}>
                                       <Space direction='horizontal' size={"small"}>
                                          {
                                             g_mastery_result.rec_status !== 0
                                                ?
                                                g_mastery_result.rec_status === 1
                                                   ?
                                                   <Tag color={"gold"} style={{ fontSize: 11 }}>Viewing</Tag>
                                                   :
                                                   <Tag color={"green"} style={{ fontSize: 11 }}>Completed</Tag>
                                                :
                                                <></>
                                          }
                                          {
                                             g_mastery_result.rec_desc !== null
                                                ?
                                                <>
                                                   <Tooltip placement="top" title={'View Recommendation'}>
                                                      <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleViewRecommendation('individual')}>
                                                         <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                      </Button>
                                                   </Tooltip>
                                                   {
                                                      g_mastery_result.rec_status < 2 &&
                                                      <Tooltip placement="top" title={'Edit Recommendation'}>
                                                         <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleEditRecommendation('individual')}>
                                                            <EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                         </Button>
                                                      </Tooltip>
                                                   }

                                                </>
                                                :
                                                <Tooltip placement="top" title={'Create Recommendation'}>
                                                   <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleCreateRecommendation('individual')}>
                                                      <PlusOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                   </Button>
                                                </Tooltip>
                                             // <Tag color={"gray"} style={{ fontSize: 11 }}>Not Available</Tag>
                                          }
                                       </Space>
                                    </Descriptions.Item>
                                    :
                                    <></>
                                 }
                              </>
                              :
                              <>
                                 <Descriptions.Item label='Summative' span={2} style={{ fontSize: 14, width: "10%" }}>
                                    <Space direction='horizontal' size={"small"}>
                                       {masteryrec.summative !== null
                                          ?
                                          <>
                                             {
                                                mastery_result[3].mastery_answer_id !== null
                                                   ?
                                                   <>
                                                      {
                                                         mastery_result[3].submit_status === 1
                                                            ?
                                                            <>
                                                               {
                                                                  mastery_result[3].essay_score === 0 && mastery_result[3].essay_score_multi === "[]" && mastery_result[3].essay_total_points > 0
                                                                     ?
                                                                     <Tag color={"purple"} style={{ fontSize: 13 }}>For teacher evaluation</Tag>
                                                                     :
                                                                     <>
                                                                        {/* <Tag color={mastery_result[3].percentage > 79 ? "green" : "red"} style={{ fontSize: 11 }}>Score: {mastery_result[3].score + "/" + mastery_result[3].total_points}</Tag> */}
                                                                        <Tag color={"green"} style={{ fontSize: 11 }}>Score: {mastery_result[3].score + "/" + mastery_result[3].total_points}</Tag>
                                                                        <Tag
                                                                           // color={"green"} 
                                                                           color={Utils.GetProficiency(mastery_result[3].percentage) === "BEGINNER" ? '#6395F9' : (Utils.GetProficiency(mastery_result[3].percentage) === 'PROFICIENT' ? '#5AD8A6' : '#8593AE')}
                                                                           style={{ fontSize: 11 }}
                                                                        >
                                                                           {Utils.GetProficiency(mastery_result[3].percentage)}
                                                                        </Tag>
                                                                        <Tooltip placement="top" title={'View ' + Utils.GetTypeLabel(mastery_result[3].assessment_type) + " Answer"}>
                                                                           <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleViewAnswer(student_name, student_id, mastery_result[3].assessment_type)}>
                                                                              <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                                           </Button>
                                                                        </Tooltip>
                                                                     </>
                                                               }
                                                            </>
                                                            :
                                                            <Tag color={"gold"} style={{ fontSize: 11 }}>Answering</Tag>
                                                      }
                                                   </>

                                                   :
                                                   <Tag style={{ fontSize: 11 }}>Not Started</Tag>
                                             }
                                          </>
                                          :
                                          <Tag color={"gray"} style={{ fontSize: 11 }}>Not Available</Tag>
                                       }
                                    </Space>
                                 </Descriptions.Item>
                                 <Descriptions.Item span={2} style={{ fontSize: 14, width: "10%" }}></Descriptions.Item>

                              </>
                        }

                     </Descriptions>

                  </Col>
               </Row>
               :
               <Row style={{ height: "133px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <Col>
                     <Spin size='middle' tip="Loading data, please wait..." />
                  </Col>
               </Row>
         }

      </>
   }

   // const handleCheckEssay = () => {
   //    setCheckEssayModalVisible(true);
   // }

   const handleCancelCheckEssay = () => {
      setAssInfo({
         Id: "",
         Title: "",
         Term: "",
         File_url: "",
         Question_type: "1",
         Created_by: "",
         Date_created: "",
         Question_sheet: [],
         Level: "",
         Subject: "",
         Level_desc: "",
         Subject_desc: ""
      });

      setAnswerInfo({
         Id: "",
         Answers: "",
         Score: "",
         Total_points: "",
         Start_date: "",
         End_date: "",
         Essay_multi_comments: "",
      });

      // setSelectedItem('');
      setEssayStudentIdx(-1);
      setCheckEssayModalVisible(false);
      setShowQuestionaire(false);
   }

   const getAssessmentInfo = (assessment_id, assign_id, student_id) => {
      var qs = eval("masteryrec." + assessmentType);
      var file_url = eval("masteryrec." + assessmentType + "_file_url");

      var ass_info = {
         Id: masteryrec.id,
         Title: masteryrec.title,
         Term: masteryrec.term,
         File_url: file_url,
         Question_type: "",
         Created_by: masteryrec.created_by,
         Date_created: masteryrec.date_created,
         Question_sheet: JSON.parse(qs),
         Level: masteryrec.level,
         Subject: masteryrec.subject,
         // Level_desc: data.level_desc,
         // Subject_desc: data.subject_desc
      }

      setAssInfo(ass_info);
      getAnswerInfo(assessment_id, assign_id, student_id, assessmentType);
   }

   const getAnswerInfo = (assessment_id, assignment_id, student_id, assessment_type) => {
      setAnswerInfo({
         Id: "",
         Answers: "",
         Score: "",
         Total_points: "",
         Start_date: "",
         End_date: "",
         Essay_multi_comments: "",
      });

      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/answerinfo?assessment_id=${assessment_id}&assignment_id=${assignment_id}&student_id=${student_id}&assessment_type=${assessment_type}`;
      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            var answer_info = {
               Id: data.id,
               Answers: JSON.parse(data.answer),
               Score: data.score + data.essay_score,
               Total_points: data.total_points,
               Start_date: moment(data.start_date).format("MMM DD, YYYY hh:mm a"),
               End_date: moment(data.end_date).format("MMM DD, YYYY hh:mm a"),
               Essay_multi_comments: data.essay_multi_comments,
            };

            setAnswerInfo(answer_info);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleStudentClick = (assessment_id, assignment_id, student_id, student_name, essay_score, essay_score_multi, essay_multi_comments, idx) => {
      setAssInfo({
         Id: "",
         Title: "",
         Term: "",
         File_url: "",
         Question_type: "1",
         Created_by: "",
         Date_created: "",
         Question_sheet: [],
         Level: "",
         Subject: "",
         Level_desc: "",
         Subject_desc: ""
      });

      setAnswerInfo({
         Id: "",
         Answers: "",
         Score: "",
         Total_points: "",
         Start_date: "",
         End_date: "",
         Essay_multi_comments: "",
      });

      setShowQuestionaire(false);
      setEssayStudentIdx(idx);
      essayIdx = -1;

      // message.info("Hey " + student_id);
      setSelectedStudentName(student_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()));
      setEssayScore(essay_score);

      let e_s_m = essay_score_multi !== "" && essay_score_multi !== null ? JSON.parse(essay_score_multi) : [];
      setEssayScoreMulti(e_s_m);

      let e_m_c = essay_multi_comments !== "" && essay_multi_comments !== null ? JSON.parse(essay_multi_comments) : [];
      setEssayComments(e_m_c);

      setSelectedItem(assessment_id);
      getAssessmentInfo(assessment_id, assignment_id, student_id);
   }

   const handleSaveEssayScore = (id) => {
      essayIdx = -1;
      setEvaluating(true);

      //--Replace all null with '0'
      essayScoreMulti.forEach((item, i) => {
         if (item === null)
            essayScoreMulti[i] = '0';
      });

      essayComments.forEach((item, i) => {
         if (item === null)
            essayComments[i] = '';
      });

      let score = JSON.stringify(essayScoreMulti);
      let comments = JSON.stringify(essayComments);

      // var total_score = essayScoreMulti.reduce((a, b) => (a !== null ? parseInt(a) : 0) + (b !== null ? parseInt(b) : 0));
      var total_score = essayScoreMulti.reduce((a, b) => parseInt(a) + parseInt(b));
      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/addessayscoremulti?answer_id=${id}&score=${score}&total_score=${total_score}&comments=${comments}`;

      axios.post(url).then(response => {
         if (response.data) {
            setEvaluating(false);

            setAnswerInfo({
               Id: "",
               Answers: "",
               Score: "",
               Total_points: "",
               Start_date: "",
               End_date: "",
               Essay_multi_comments: "",
            });

            Modal.success({
               title: 'Mastery Assessment',
               content: 'Open-Ended/Essay evaluation added.',
               centered: true,
               onOk: () => { getTrackerList(assign_id); },
            });
         }
      }, (error) => {
         setEvaluating(false);
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Open-Ended/Essay evaluation failed.',
            centered: true,
         });
      });
   }

   // const handleSetEssayScoreMulti = (val, max_score) => {
   //    if (parseInt(val) > parseInt(max_score)) {
   //       val = max_score;

   //       Modal.error({
   //          title: 'Mastery Assessment',
   //          content: 'The value you entered exceeds the maximum allowed points! The system will automatically set the points to maximum value.',
   //          centered: true,
   //       });
   //    }

   //    essayIdx = -1;
   //    let tmpEssayScore = [...essayScoreMulti];

   //    tmpEssayScore[essayIndex] = val === '' ? '0' : val.toString();
   //    setEssayScoreMulti(tmpEssayScore);
   // }

   // const handleSetEssayComment = (val) => {
   //    essayIdx = -1;
   //    let tmpEssayComments = [...essayComments];
   //    tmpEssayComments[essayIndex] = val;
   //    setEssayComments(tmpEssayComments);
   // }

   const handleSetEssayScore = (idx) => {
      if (tmpEssayScore.value !== null) {
         let val = -1;
         if (parseInt(tmpEssayScore.value) > parseInt(tmpEssayScore.points)) {
            val = tmpEssayScore.points;

            Modal.error({
               title: 'Assessment',
               content: 'The value you entered exceeds the maximum allowed points! The system will automatically set the points to maximum value.',
               centered: true,
            });
         } else {
            val = tmpEssayScore.value;
         }

         let essayScores = [...essayScoreMulti];
         essayScores[parseInt(idx)] = val === '' ? '0' : val.toString();
         setEssayScoreMulti(essayScores);
         // setEssayScoreMulti({...essayScoreMulti, [parseInt(idx)]: val === '' ? '0' : val.toString()});
      }
   }

   const handleSetEssayComment = (idx) => {

      // let tmpEssayComments = {...essayComments, [idx]: tmpEssayComment};
      let tmpEssayComments = [...essayComments];
      tmpEssayComments[idx] = tmpEssayComment;
      setEssayComments(tmpEssayComments);
   }

   const handleClickEssayScore = (id) => {
      essayIdx = -1;
      setEssayIndex(parseInt(id));
   }

   const handleMenuClick = (e) => {
      switch (e.key) {
         case '1':
            assessment_type = 'formative_1';
            break;
         case '2':
            assessment_type = 'formative_2';
            break;
         case '3':
            assessment_type = 'formative_3';
            break;
         case '4':
            assessment_type = 'summative';
            break;
      }

      setAssessmentType(assessment_type);

      //-- Reset score values before updating with new ones
      for (var i = 0; i < trackerList.length; i++) {
         trackerList[i].score = 0;
         trackerList[i].essay_score = 0;
         trackerList[i].total_points = 0;
         trackerList[i].essay_score_multi = null;
         trackerList[i].assessment_submit_status = 0;
         trackerList[i].essay_multi_comments = null;
         trackerList[i].essay_total_points = 0;
      }

      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/masteryscores?assessment_id=${assessment_id}&assignment_id=${assign_id}&assessment_type=${assessment_type}`;
      axios.get(url).then(response => {
         if (response.data) {
            var scores = response.data;

            for (var i = 0; i < trackerList.length; i++) {
               for (var ii = 0; ii < scores.length; ii++) {
                  if (scores[ii].student_id === trackerList[i].student_id) {
                     trackerList[i].score = scores[ii].score;
                     trackerList[i].essay_score = scores[ii].essay_score;
                     trackerList[i].total_points = scores[ii].total_points;
                     trackerList[i].essay_score_multi = scores[ii].essay_score_multi;
                     trackerList[i].assessment_submit_status = scores[ii].assessment_submit_status;
                     trackerList[i].essay_multi_comments = scores[ii].essay_multi_comments;
                     trackerList[i].essay_total_points = scores[ii].essay_total_points;
                  }
               }
            }

            setEssayEvalTitle(" Check Open-Ended/Essay for " + Utils.GetTypeLabel(assessment_type));
            setCheckEssayModalVisible(true);

            console.log(trackerList);
         }
      }, (error) => {
         console.log(error.message);
      });
   };

   const menu_formative = (
      <Menu
         onClick={handleMenuClick}
         items={[
            {
               label: 'Formative Level 1',
               key: '1',
               icon: <CheckCircleOutlined />,
            },
            {
               label: 'Formative Level 2',
               key: '2',
               icon: <CheckCircleOutlined />,
            },
            {
               label: 'Formative Level 3',
               key: '3',
               icon: <CheckCircleOutlined />,
            },
         ]}
      />
   );

   const menu_summative = (
      <Menu
         onClick={handleMenuClick}
         items={[
            {
               label: 'Summative',
               key: '4',
               icon: <CheckCircleOutlined />,
            },
         ]}
      />
   );

   const handleViewStudyPlanActivity = (data, study_plan_type) => {
      g_activity = {
         act_docs: data.study_plan_activity_docs.split(','),
         act_doc_names: data.study_plan_activity_doc_names.split(','),
         act_remarks: data.study_plan_activity_remarks,
         act_study_plan_type: study_plan_type
      }

      setViewStudyPlanActivityModalVisible(true);
   }

   const handleViewStudyPlanActivityDoc = (document, idx) => {
      setSelectedDocumentIdx(idx);
      setCurrentStudyPlanActiviyDoc(document);
   }

   const handleCloseViewStudyPlanActivity = () => {
      setViewStudyPlanActivityModalVisible(false);
      setCurrentStudyPlanActiviyDoc(null);
      setSelectedDocumentIdx(-1);
   }

   const handleSetActivityRemarks = (value) => {
      g_activity.act_remarks = value;
      // setActivityUpdating(true);
   }

   const handleSaveActivityRemarks = () => {
      setActivityUpdating(true);

      var activity_data = {
         student_id: g_current_student_id,
         assessment_id: assignInfo.mastery_assessment_id,
         assignment_id: assignInfo.id,
         study_plan_type: g_activity.act_study_plan_type,
         remarks: g_activity.act_remarks,
      }

      //-- Update mastery result data
      if (g_activity.act_study_plan_type === 'study_plan_1') {
         masteryResult[4].study_plan_activity_remarks = g_activity.act_remarks;
      }

      if (g_activity.act_study_plan_type === 'study_plan_2') {
         masteryResult[5].study_plan_activity_remarks = g_activity.act_remarks;
      }
      //-----------------------------

      axios.post(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/setactivityremarks`, activity_data).then((response) => {
         console.log(response);

         if (response.data) {
            setActivityUpdating(false);

            Modal.success({
               title: 'Mastery Assessment',
               content: 'Study plan activity feedback sent.',
               centered: true,
            });
         }
      }, (error) => {
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Study plan activity feedback send failed.',
            centered: true,
         });
         setActivityUpdating(false);
         console.log(error.message);
      });
   }

   const handleZoomPicker = () => {
      setZoomPickerModal(true);
   }

   const handleOpenZoom = () => {
      let url = `${process.env.REACT_APP_API_ZOOM}/zoomgetmeeting?id=${g_zoom_meeting_id}`;
      axios.get(url, { headers: {} }).then((response) => {
         if (response.data) {
            let zoomMeeting = response.data;

            // //-- Add observer to breakout room (section name) where current lesson is assigned
            // var teacherEmail = g_email;
            // var classSection = g_section;

            // var rooms = zoomMeeting.settings.breakout_room.rooms;

            // for (var i = 0; i < rooms.length; i++) {
            //    var participants = rooms[i].participants;
            //    var itemIdx = participants.findIndex(item => item === teacherEmail);

            //    //-- Remove observer from other breakout rooms if included
            //    if (itemIdx >= 0)
            //       participants.splice(itemIdx, 1);

            //    //-- Add observer to breakout room where current lesson is assigned
            //    if (rooms[i].name === classSection) {
            //       rooms[i].participants.push(teacherEmail);
            //    }

            //    //--Update room participants
            //    rooms[i].participants = participants;
            // }

            // //-- (Start) Update breakout rooms data
            // const options = {
            //    "settings": {
            //       "breakout_room": {
            //          "rooms": rooms,
            //       }
            //    }
            // }

            // url = `${process.env.REACT_APP_API_ZOOM}/zoomupdatemeeting?id=${g_zoom_meeting_id}`;
            // axios.patch(url, options, { headers: {} }).then((response) => {
            //    if (response.data) {
            //       window.open(zoomMeeting.join_url, "_blank").focus();
            //    }
            // }, (error) => {
            //    Modal.error({
            //       title: 'Mastery Assessment',
            //       content: 'Looks like there was an error joining zoom meeting',
            //       centered: true,
            //    });
            // });
            // //-- (End) Update breakout rooms data
            window.open(zoomMeeting.join_url, "_blank").focus();
         }
      }, (error) => {
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Looks like there was an error joining zoom meeting',
            centered: true,
         });
      });
   }

   const handleViewRecommendation = (type) => {
      setRecommendationType(type);
      setViewRecommendationModalVisible(true);
   }

   // const handleViewRecommendationDoc = (document) => {
   //    setCurrentRecommendationDoc(document);
   // }

   const handleCloseViewRecommendation = () => {
      setViewRecommendationModalVisible(false);
      setCurrentRecommendationDoc(null);
      setSelectedDocumentIdx(-1);
   }

   const handleCreateRecommendation = (type) => {
      setRecommendationType(type);
      setCreateRecommendationModalVisible(true);
   }

   const handleEditRecommendation = (type) => {
      setRecommendationType(type);

      g_recommendation_data = {
         recommendation_id: g_mastery_result.rec_id,
         mastery_assignment_id: assign_id,
         description: g_mastery_result.rec_desc,
         documents: g_mastery_result.rec_docs,
         document_names: g_mastery_result.rec_doc_names,
         student_id: g_current_student_id,
      }

      setEditRecommendationModalVisible(true);
   }

   const handleCancelCreateRecommendation = () => {
      setRecommendationType(null);

      setCreateRecommendationModalVisible(false);
   }

   const handleCancelEditRecommendation = () => {
      setRecommendationType(null);
      setEditRecommendationModalVisible(false);
   }

   const handleOnFinishCreateRecommendation = (upload_files, recommendation_data) => {
      setCreating(true);

      var url;

      if (upload_files.length > 0) {
         var formData = new FormData();

         upload_files.forEach(file => {
            // the name has to be 'files' so that .NET could properly bind it (Phuck this sheet! Took me the whole day to find this sheet!)
            formData.append('files', file);
         });

         var uploadedfiles = "";
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMultiple?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}`;
         axios.post(url, formData).then(response => {

            if (response.data) {
               uploadedfiles = response.data;

               recommendation_data.documents = uploadedfiles;
               recommendation_data.mastery_assignment_id = assign_id;
               recommendation_data.student_id = g_current_student_id ?? -1;

               url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/upsertrecommendation?type=${recommendationType}`;
               axios.post(url, recommendation_data).then(response => {
                  setCreating(false);
                  if (response.data) {
                     Modal.success({
                        title: 'Mastery Assessment',
                        content: 'Recommendation created.',
                        centered: true,
                        onOk: () => { setCreateRecommendationModalVisible(false) },
                     });
                  }

                  setRecommendationType(null);

                  getMasteryResult(assessment_id, assign_id, g_current_student_id, true, g_current_student_name);
               }, (error) => {
                  setCreating(false);
                  setRecommendationType(null);
                  Modal.error({
                     title: 'Mastery Assessment',
                     content: 'Recommendation creation failed.',
                     centered: true,
                     onOk: () => { setCreateRecommendationModalVisible(false) },
                  });
               });

               setCreating(false);
               setCreateRecommendationModalVisible(false);

            }
         }, (error) => {
            // console.log(error.message);
            setCreateRecommendationModalVisible(false);
         });
      } else {
         recommendation_data.mastery_assignment_id = assign_id;
         recommendation_data.student_id = g_current_student_id ?? -1;

         url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/upsertrecommendation?type=${recommendationType}`;
         axios.post(url, recommendation_data).then(response => {
            setCreating(false);
            if (response.data) {
               Modal.success({
                  title: 'Mastery Assessment',
                  content: 'Recommendation created.',
                  centered: true,
                  onOk: () => { setCreateRecommendationModalVisible(false) },
               });

               setRecommendationType(null);

               if (recommendationType === 'individual')
                  getMasteryResult(assessment_id, assign_id, g_current_student_id, true, g_current_student_name);
            }
         }, (error) => {
            setRecommendationType(null);
            setCreating(false);
            Modal.error({
               title: 'Mastery Assessment',
               content: 'Recommendation creation failed.',
               centered: true,
               onOk: () => { setCreateRecommendationModalVisible(false) },
            });
         });
      }
   }

   const handleOnFinishEditRecommendation = (upload_files, recommendation_data) => {
      setRecommendationType(null);
      setUpdating(true);
      var url;

      if (upload_files.length > 0) {
         var formData = new FormData();

         upload_files.forEach(file => {
            // the name has to be 'files' so that .NET could properly bind it (Phuck this sheet! Took me the whole day to find this sheet!)
            formData.append('files', file);
         });

         var uploadedfiles = "";
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMultiple?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}`;
         axios.post(url, formData).then(response => {

            if (response.data) {
               uploadedfiles = response.data;

               // recommendation_data.recommendation_docs = uploadedfiles;
               recommendation_data.mastery_assignment_id = assign_id;
               recommendation_data.student_id = g_current_student_id;
               recommendation_data.documents += recommendation_data.documents !== "" ? "," + uploadedfiles : uploadedfiles;

               url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/upsertrecommendation?type=${recommendationType}`;
               axios.post(url, recommendation_data).then(response => {
                  setUpdating(false);
                  if (response.data) {
                     Modal.success({
                        title: 'Mastery Assessment',
                        content: 'Recommendation updated.',
                        centered: true,
                        onOk: () => { setEditRecommendationModalVisible(false) },
                     });

                     getMasteryResult(assessment_id, assign_id, g_current_student_id, true, g_current_student_name);
                  }
               }, (error) => {
                  setUpdating(false);
                  Modal.error({
                     title: 'Mastery Assessment',
                     content: 'Recommendation update failed.',
                     centered: true,
                     onOk: () => { setEditRecommendationModalVisible(false) },
                  });
               });

               setUpdating(false);
               setEditRecommendationModalVisible(false);

            }
         }, (error) => {
            // console.log(error.message);
            setCreateRecommendationModalVisible(false);
         });
      } else {
         recommendation_data.mastery_assignment_id = assign_id;
         recommendation_data.student_id = g_current_student_id;

         url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/upsertrecommendation?type=${recommendationType}`;
         axios.post(url, recommendation_data).then(response => {
            setUpdating(false);
            if (response.data) {
               Modal.success({
                  title: 'Mastery Assessment',
                  content: 'Recommendation updated.',
                  centered: true,
                  onOk: () => { setEditRecommendationModalVisible(false) },
               });

               getMasteryResult(assessment_id, assign_id, g_current_student_id, true, g_current_student_name);
            }
         }, (error) => {
            setUpdating(false);
            Modal.error({
               title: 'Mastery Assessment',
               content: 'Recommendation update failed.',
               centered: true,
               onOk: () => { setEditRecommendationModalVisible(false) },
            });
         });
      }
   }

   const handleShowQuestionaire = () => {
      // handleStudentClick(assessment_id, assign_id, g_current_student_name, g_current_student_name, essayScore, essayScoreMulti, essayComments, essayStudentIdx);
      setShowQuestionaire(!showQuestionaire);
   }

   const handleClassRecommendation = () => {
      setRecommendationType('group');

      if (trackerList[0].recommendation_id === null || trackerList[0].recommendation_id === '')
         setCreateRecommendationModalVisible(true);
      else {
         g_recommendation_data = {
            recommendation_id: trackerList[0].recommendation_id,
            mastery_assignment_id: assign_id,
            description: trackerList[0].recommendation_desc,
            documents: trackerList[0].recommendation_docs !== null && trackerList[0].recommendation_docs !== '' ? trackerList[0].recommendation_docs.split(',') : [],
            document_names: trackerList[0].recommendation_doc_names !== null && trackerList[0].recommendation_doc_names !== '' ? trackerList[0].recommendation_doc_names.split(',') : [],
            student_id: -1,
         }

         setEditRecommendationModalVisible(true);
      }
   }

   function sPDFFile() {
      pdfFile = assInfo.File_url;
      return true;
   }

   const dataFormative = [
      {
         avatar: <Avatar size={'small'} style={{ backgroundColor: '#6395F9' }} />,
         title: 'Submitted (Beginner)',
      },
      {
         avatar: <Avatar size={'small'} style={{ backgroundColor: '#5AD8A6' }} />,
         title: 'Submitted (Proficient)',
      },
      {
         avatar: <Avatar size={'small'} style={{ backgroundColor: '#8593AE' }} />,
         title: 'Submitted (Competent)',
      },
      {
         avatar: <Avatar size={'small'} style={{ backgroundColor: 'gold' }} />,
         title: 'Answering',
      },
      {
         avatar: <Avatar size={'small'} style={{ backgroundColor: 'purple' }} />,
         title: 'For Evaluation',
      },
      {
         avatar: <Avatar size={'small'} style={{ backgroundColor: '#85fcf2' }} />,
         title: 'Exempted',
      },
      {
         avatar: <Avatar size={'small'} style={{ backgroundColor: '#e5e5e5' }} />,
         title: 'Not Started',
      },
   ];

   const dataStudyPlan = [
      {
         avatar: <Avatar size={'small'} style={{ backgroundColor: 'gold' }} />,
         title: 'Studying/Done Studying',
      },
      {
         avatar: <Avatar size={'small'} style={{ backgroundColor: 'green' }} />,
         title: 'Completed',
      },
      {
         avatar: <Avatar size={'small'} style={{ backgroundColor: '#e5e5e5' }} />,
         title: 'Not Viewed',
      },
   ];

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subjects</a></li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate(`/masteryassessmentsubject?id=${subject}&name=${subjectname}`, { state: { activetab: origin } })}>Mastery Assessment ({subjectname})</a></li>
                     <li><span>/</span></li>
                     <li className="active">Tracker</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subject</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate(`/masteryassessmentsubject?id=${subject}&name=${subjectname}`, { state: { activetab: origin } })}>Mastery Assessment ({subjectname})</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Tracker</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
               <SimpleBarReact style={{ height: "calc(100vh - 55px)" }}>
                  <div className="dashboard-item-inner3">
                     {assignInfo !== null && trackerList !== null
                        ?
                        <>
                           <Descriptions bordered size='small'
                              column={{
                                 xxl: 4,
                                 xl: 3,
                                 lg: 3,
                                 md: 3,
                                 sm: 2,
                                 xs: 1,
                              }}>
                              <Descriptions.Item label="Competency Title" span={4} style={{ fontSize: 13 }} >
                                 <b>{masteryrec.title}</b>
                              </Descriptions.Item>
                              <Descriptions.Item label="Competency Code" span={2} style={{ fontSize: 13 }}>
                                 <Select
                                    disabled
                                    bordered={false}
                                    size='default'
                                    style={{ textAlign: "left", color: "rgba(0, 0, 0, 1)" }}
                                    allowClear
                                    placeholder="Select Competency"
                                    value={masteryrec.mastery_competency}
                                 >
                                    {MasteryCompetency.map(({ value, name }, idx) => {
                                       return <Option value={value}>{name}</Option>
                                    })}
                                 </Select>
                              </Descriptions.Item>
                              <Descriptions.Item label="Term" span={2} style={{ fontSize: 13 }}>
                                 <Select
                                    disabled
                                    bordered={false}
                                    showArrow
                                    size='default'
                                    style={{ textAlign: "left" }}
                                    allowClear
                                    placeholder="Select Term"
                                    value={masteryrec.term}
                                 >
                                    {MasteryTerms.map(({ value, name }, idx) => {
                                       return <Option value={value}>{name}</Option>
                                    })}
                                 </Select>
                              </Descriptions.Item>
                              <Descriptions.Item label="Grade Level" span={2} style={{ fontSize: 13 }}>
                                 <Select
                                    disabled
                                    bordered={false}
                                    showArrow
                                    size='default'
                                    style={{ textAlign: "left" }}
                                    allowClear
                                    placeholder="Select Level"
                                    value={masteryrec.level}
                                 >
                                    {MasteryGradeLevels.map(({ value, name }, idx) => {
                                       return <Option value={value}>{name}</Option>
                                    })}
                                 </Select>
                              </Descriptions.Item>
                              <Descriptions.Item label="Subject" span={2} style={{ fontSize: 13 }}>
                                 <Select
                                    disabled
                                    bordered={false}
                                    showArrow
                                    size='default'
                                    style={{ textAlign: "left" }}
                                    allowClear
                                    placeholder="Select Subject"
                                    value={masteryrec.subject}
                                 >
                                    {MasterySubjects.map(({ value, name }, idx) => {
                                       return <Option value={value}>{name}</Option>
                                    })}
                                 </Select>
                              </Descriptions.Item>
                              <Descriptions.Item label="Actions" span={2} style={{ fontSize: 13 }}>
                                 {/* <Space size={'small'}> */}
                                 <Row style={{ gap: '5px' }}>
                                    <Dropdown overlay={masteryrec.summative === null ? menu_formative : menu_summative} placement='bottomCenter' trigger={['click']} arrow>
                                       <Button className='button-shadow' size='small' shape='round' type='primary' style={{ fontSize: 12 }}>Check Open-Ended/Essay</Button>
                                    </Dropdown>
                                    <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleClassRecommendation()} style={{ fontSize: 12 }}>Class Recommendation</Button>
                                    <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleOpenZoom()} style={{ fontSize: 12 }}>Open Zoom</Button>
                                    <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleUpdateTracker()} loading={trackerLoading} style={{ fontSize: 12 }}>Update Tracker</Button>
                                 </Row>
                                 {/* </Space> */}
                              </Descriptions.Item>
                              <Descriptions.Item label="Schedule" span={2} style={{ fontSize: 13 }}>
                                 <b>{moment(assignInfo.start_date).format("MMM. DD, YYYY hh:mm A") + " To " + moment(assignInfo.end_date).format("MMM. DD, YYYY hh:mm A")}</b>
                              </Descriptions.Item>
                           </Descriptions>

                           <Row style={{ paddingTop: 20, paddingBottom: '15px', gap: '20px', justifyContent: 'center' }}>
                              {/* {
                                 masteryrec.summative === null &&
                                 <>
                                    <Badge count={legendCount.level1} style={{ backgroundColor: 'gold' }} overflowCount={999}>
                                       <Tag color={"gold"} style={{ cursor: legendCount.level1 > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.level1, 'level1')}>Level 1</Tag>
                                    </Badge>
                                    <Badge count={legendCount.level2} style={{ backgroundColor: 'gold' }} overflowCount={999}>
                                       <Tag color={"gold"} style={{ cursor: legendCount.level2 > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.level2, 'level2')}>Level 2</Tag>
                                    </Badge>
                                    <Badge count={legendCount.level3} style={{ backgroundColor: 'gold' }} overflowCount={999}>
                                       <Tag color={"gold"} style={{ cursor: legendCount.level3 > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.level3, 'level3')}>Level 3</Tag>
                                    </Badge>
                                    <Badge count={legendCount.studyplan1} style={{ backgroundColor: 'blue' }} overflowCount={999}>
                                       <Tag color={"blue"} style={{ cursor: legendCount.studyplan1 > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.studyplan1, 'studyplan1')}>Study Plan 1</Tag>
                                    </Badge>
                                    <Badge count={legendCount.studyplan2} style={{ backgroundColor: 'blue' }} overflowCount={999}>
                                       <Tag color={"blue"} style={{ cursor: legendCount.studyplan2 > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.studyplan2, 'studyplan2')}>Study Plan 2</Tag>
                                    </Badge>
                                 </>
                              }

                              <Badge count={legendCount.completed} style={{ backgroundColor: 'green' }} overflowCount={999}>
                                 <Tag color={"green"} style={{ cursor: legendCount.completed > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.completed, 'completed')}>Completed</Tag>
                              </Badge>

                              {
                                 masteryrec.summative !== null &&
                                 <>
                                    <Badge count={legendCount.started} style={{ backgroundColor: 'gold' }} overflowCount={999}>
                                       <Tag color={"gold"} style={{ cursor: legendCount.started > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.started, 'started')}>Answering</Tag>
                                    </Badge>
                                 </>
                              }

                              <Badge count={legendCount.not_started} style={{ backgroundColor: 'gray' }} overflowCount={999}>
                                 <Tag style={{ cursor: legendCount.not_started > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.not_started, 'notstarted')}>Not Started</Tag>
                              </Badge>

                              {masteryrec.summative === null &&
                                 <>
                                    <Badge count={legendCount.students_for_recommendation} style={{ backgroundColor: 'red' }} overflowCount={999}>
                                       <Tag color={"red"} style={{ cursor: legendCount.students_for_recommendation > 0 ? 'pointer' : 'default' }} onClick={() => handleFilterTracker(legendCount.students_for_recommendation, 'forrecommendation')}>Students for Recommendation</Tag>
                                    </Badge>
                                 </>
                              } */}

                           </Row>

                           <Table
                              // scroll={{
                              //    x: '1200px',
                              // }}
                              style={{ fontSize: '12px' }}
                              size='small'
                              columns={tracker_columns}
                              dataSource={trackerList}
                              pagination={false}
                              expandable={{
                                 expandIcon: ({ expanded, onExpand, record }) =>
                                    expanded
                                       ? (<MinusCircleTwoTone twoToneColor="#70D0D4" onClick={e => onExpand(record, e)} />)
                                       : (<Tooltip placement="right" title={"Click to show the progress details of " + record.fullname}><PlusCircleTwoTone twoToneColor="#70D0D4" onClick={e => onExpand(record, e)} /></Tooltip>),
                                 backgroundColor: "#70D0D4",
                                 expandedRowRender: (record, index, indent, expanded) => (
                                    <> {RenderMasteryResult(masteryResult, record.fullname, record.student_id)} </>
                                 ),
                                 rowExpandable: (record) => true,
                                 expandedRowKeys: activeExpRow,
                                 onExpand: (expanded, record) => {
                                    const keys = [];
                                    if (expanded) {
                                       keys.push(record.key);
                                    }
                                    console.log(keys);
                                    setActiveExpRow(keys);
                                    getMasteryResult(assessment_id, assign_id, record.student_id, expanded, record.fullname);
                                 }
                              }}
                              scroll={{
                                 x: '1000px',
                                 y: 'calc(100vh - 22rem)',
                              }}
                              footer={() => 'Total Records: ' + trackerList.length}
                           />
                        </>
                        :
                        <>
                           <div className='modal-document-viewer'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                        </>
                     }
                  </div>
               </SimpleBarReact>
            </div>
         </div>

         <Modal
            title={essayEvalTitle}
            centered
            destroyOnClose={true}
            open={checkEssayModalVisible}
            onOk={() => handleCancelCheckEssay()}
            onCancel={() => handleCancelCheckEssay()}
            width='80%'
            okButtonProps={{ shape: "round" }}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            style={{ minWidth: "350px" }}
         >
            <Row>
               <Col span={8}>
                  <div className='student_list_container'>
                     <SimpleBarReact style={{ height: "calc(100vh - 185px)" }}>
                        <List
                           itemLayout="horizontal"
                           dataSource={trackerList}
                           renderItem={(item, idx) => (
                              <List.Item
                                 style={{ paddingLeft: "15px", paddingRight: "15px" }}
                                 className={idx === essayStudentIdx ? 'essay_student_selected' : 'essay_student'} //'essay_student '
                                 onClick={() => handleStudentClick(item.mastery_assessment_id, item.assign_id, item.student_id, item.fullname, item.essay_score, item.essay_score_multi, item.essay_multi_comments, idx)}
                              >
                                 <List.Item.Meta
                                    style={{ cursor: "pointer" }}
                                    avatar={<Avatar size={45} icon={<UserOutlined />} />}
                                    title={
                                       <Tag
                                          style={{ fontSize: 13, cursor: 'pointer' }}
                                          color={item.assessment_submit_status === 1 && item.essay_total_points > 0 ? 'green' : 'red'}
                                       >
                                          {item.fullname.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                                       </Tag>
                                    }
                                    description={"Score: " + item.essay_score} //(item.essay_score === -1 ? 'No Score' : item.essay_score)}
                                 />
                              </List.Item>
                           )}
                        />
                     </SimpleBarReact>
                  </div>
               </Col>
               <Col span={16}>
                  <div className="essay_list_container_two" style={{ visibility: showQuestionaire ? 'visible' : 'hidden' }}>
                     <div className="pdf-all-page-container">
                        <div style={{ width: "100%", height: "calc(100vh - 250px)" }}>
                           <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                              <Viewer
                                 pageLayout={{
                                    transformSize: ({ size }) => ({
                                       height: size.height + 30,
                                       width: size.width + 30,
                                    }),
                                 }}
                                 fileUrl={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${assInfo.File_url}`}
                                 defaultScale={SpecialZoomLevel.PageWidth}
                                 initialPage={0}
                              />
                           </Worker>
                        </div>
                     </div>
                     {/* <RenderPDFReader url={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${assInfo.File_url}`} height={"calc(100vh - 250px)"} /> */}
                     {/* <object data={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${assInfo.File_url}`} type="application/pdf" width="100%" style={{ height: 'calc(100vh - 250px)' }}>
                        <p>Brainee LMS PDF - View<a href={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${assInfo.File_url}`}> PDF!</a></p>
                     </object> */}
                     {/* <RenderPDFViewer url={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${assInfo.File_url}`} height={"calc(100vh - 250px)"} load={pdfFile !== assInfo.File_url ? sPDFFile() : false} /> */}
                     <Row gutter={12} gap={5} style={{ paddingTop: "10px" }}>
                        <Col xs={24} md={24} lg={24}>
                           <Button className='button-shadow'
                              size='middle'
                              type='primary'
                              shape='round'
                              block
                              onClick={() => handleShowQuestionaire()}
                           >
                              Hide Questionaire
                           </Button>
                        </Col>
                     </Row>
                  </div>
                  {
                     // showQuestionaire
                     //    ?

                     //    :
                     answerInfo.Id !== ""
                        ?
                        <>
                           <Spin spinning={evaluating} tip="Adding evaluation, please wait...">
                              <div className='essay_list_container' style={{ visibility: !showQuestionaire ? 'visible' : 'hidden' }}>
                                 <SimpleBarReact style={{ height: "calc(100vh - 225px)" }}>
                                    {
                                       assInfo.Question_sheet.map(({ id, type, points, correct, option_labels, question, image_url, template, question_image, question_audio, question_video }, idx) => {
                                          if (type === "section") {
                                             qcardIdx = 1;
                                             idxCol.push(sectionIdx);
                                             sectionIdx++;
                                          } else {
                                             idxCol.push(qcardIdx);
                                             qcardIdx++;
                                          }

                                          //-- Check if answer and correct are matched 
                                          var matched = false;

                                          if (type === "long_answer") {
                                             let subject = MasterySubjects.find(x => x.value === masteryrec.subject);
                                             let level = MasteryGradeLevels.find(x => x.value === masteryrec.level);

                                             //-- Get card background based on subject and level
                                             // let card_bg = Utils.GetCardBackground(subject.name.toLowerCase(), level.name.toLowerCase());
                                             let card_bg = Utils.GetCardBackground(subject.name.toLowerCase(), type);

                                             essayIdx++;
                                             let essay_score = parseInt(essayScoreMulti[essayIdx]);
                                             let essay_comment = essayComments[essayIdx];

                                             return (
                                                <li>
                                                   <BraineeQuestionCard
                                                      key={id}
                                                      question_key_id={id}
                                                      index={idxCol[idx]}
                                                      id={idx}
                                                      type={type}
                                                      points={points}
                                                      correct={correct}
                                                      answers={answerInfo.Answers[idx].answer}
                                                      option_labels={option_labels}
                                                      image_url={image_url}
                                                      question_type={assignInfo.question_type.toString()}
                                                      mode={'tracker-view'}
                                                      matched={matched}
                                                      question={question}
                                                      card_bg={card_bg}
                                                      template={template}
                                                      question_image={question_image}
                                                      question_audio={question_audio}
                                                      question_video={question_video}
                                                      assessment_type={'mastery'}
                                                   />
                                                   {/* <Descriptions bordered size='small' column={4} style={{ marginBottom: "20px", marginLeft: '20px', marginRight: '20px' }}>
                                                      <Descriptions.Item label={"Points (Max: " + points + ")"} span={4} style={{ fontSize: 13 }}>
                                                         <InputNumber
                                                            id={essayIdx}
                                                            size='middle'
                                                            max={points}
                                                            min={0}
                                                            style={{ width: "100%", borderRadius: "7px" }}
                                                            defaultValue={essay_score}
                                                            onFocus={(e) => handleClickEssayScore(e.target.id)}
                                                            // onBlur={(e) => handleSetEssayScoreMulti(e.target.value, points)}
                                                            onChange={(val) => handleSetEssayScoreMulti(val, points)}
                                                         />
                                                      </Descriptions.Item>
                                                      <Descriptions.Item label="Comments/Remarks" span={4} style={{ fontSize: 13 }}>
                                                         <TextArea
                                                            allowClear
                                                            id={essayIdx}
                                                            name={essayIdx}
                                                            rows={5}
                                                            onFocus={(e) => handleClickEssayScore(e.target.id)}
                                                            onBlur={(e) => handleSetEssayComment(e.target.value)}
                                                            defaultValue={essay_comment}
                                                         />
                                                      </Descriptions.Item>
                                                   </Descriptions> */}
                                                   <Descriptions bordered size='small' column={4} style={{ marginBottom: "20px", marginLeft: '20px', marginRight: '20px' }}>
                                                      <Descriptions.Item label={"Points (Max: " + points + ")"} span={4} style={{ fontSize: 13 }}>
                                                         <div id={essayIdx} onBlur={(e) => handleSetEssayScore(e.target.id)}>
                                                            <InputNumber
                                                               id={essayIdx}
                                                               size='middle'
                                                               max={points}
                                                               min={0}
                                                               style={{ width: "100%", borderRadius: "7px" }}
                                                               defaultValue={essay_score}
                                                               onChange={(value) => setTmpEssayScore({ value, points })}
                                                            />
                                                         </div>
                                                      </Descriptions.Item>
                                                      <Descriptions.Item label="Comments/Remarks" span={4} style={{ fontSize: 13 }}>
                                                         <div id={essayIdx} onBlur={(e) => handleSetEssayComment(e.target.id)}>
                                                            <TextArea
                                                               allowClear
                                                               id={essayIdx}
                                                               name={essayIdx}
                                                               rows={5}
                                                               onChange={(e) => setTmpEssayComment(e.target.value)}
                                                               defaultValue={essay_comment}
                                                            />
                                                         </div>
                                                      </Descriptions.Item>
                                                   </Descriptions>
                                                </li>
                                             )
                                          }

                                          else
                                             return (<></>)
                                       })
                                    }
                                 </SimpleBarReact>
                                 {
                                    essayIdx > -1
                                       ?
                                       <>
                                          {
                                             masteryrec.mode === 1
                                                ?
                                                <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                                                   <Row gutter={[10, 10]} style={{ width: "100%" }}>
                                                      <Col xs={24} md={12} lg={12}>
                                                         <Button className='button-shadow'
                                                            size='middle'
                                                            type='primary'
                                                            shape='round'
                                                            block
                                                            onClick={() => handleShowQuestionaire()}
                                                         >
                                                            Show Questionaire
                                                         </Button>
                                                      </Col>
                                                      <Col xs={24} md={12} lg={12}>
                                                         <Button className='button-shadow'
                                                            size='middle'
                                                            type='primary'
                                                            shape='round'
                                                            block
                                                            onClick={() => handleSaveEssayScore(answerInfo.Id)}
                                                         >
                                                            Save
                                                         </Button>
                                                      </Col>
                                                   </Row>
                                                </div>
                                                :
                                                <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                                                   <Row gutter={[10, 10]} style={{ width: "100%", marginLeft: '20px', marginRight: '20px' }}>
                                                      <Col xs={24} sm={24} md={24} lg={24}>
                                                         <Button className='button-shadow'
                                                            size='middle'
                                                            type='primary'
                                                            shape='round'
                                                            block
                                                            onClick={() => handleSaveEssayScore(answerInfo.Id)}
                                                         >
                                                            Save
                                                         </Button>
                                                      </Col>
                                                   </Row>
                                                </div>
                                          }

                                       </>
                                       :
                                       <></>
                                 }
                              </div>
                           </Spin>
                        </>
                        :
                        <div className='essay_list_container'></div>
                  }
               </Col>
            </Row>
         </Modal>

         <Modal
            title={"View Recommendation"}
            centered
            destroyOnClose={true}
            open={viewRecommendationModalVisible}
            onOk={() => handleCloseViewRecommendation()}
            onCancel={() => handleCloseViewRecommendation()}
            width='90%'
            okButtonProps={{ shape: "round" }}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            style={{ minWidth: "350px" }}
         >
            <ViewRecommendations
               // data={g_mastery_result} 
               student_id={g_current_student_id}
               assignment_id={assign_id}
            />
         </Modal>

         <Modal
            title={"Student Activity"}
            centered
            destroyOnClose={true}
            open={viewStudyPlanActivityModalVisible}
            onOk={() => handleCloseViewStudyPlanActivity()}
            onCancel={() => handleCloseViewStudyPlanActivity()}
            width='90%'
            okButtonProps={{ shape: "round" }}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            style={{ minWidth: "350px" }}
         >
            <Spin spinning={activityUpdating} tip={"Updating, please wait..."}>
               <Row>
                  <Col xs={24} md={9} lg={9}>
                     <div className='student_list_container' style={{ paddingRight: "10px" }}>
                        <SimpleBarReact style={{ height: "calc(100svh - 185px" }}>
                           <Divider orientation="left">
                              <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Give your feedback</Title>
                           </Divider>
                           <Wysiwyg2
                              changeContentCallback={(e) => handleSetActivityRemarks(e)}
                              content={g_activity.act_remarks}
                              height={250}
                              contentHeight={135}
                           />
                           {/* <Editor
                              apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                              onInit={(evt, editor) => editorRef.current = editor}
                              initialValue={g_activity.act_remarks}
                              onFocusOut={(e) => handleSetActivityRemarks(e.target.innerHTML)}
                              init={{
                                 menubar: true,
                                 height: 250,
                                 statusbar: false,
                                 plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code',
                                    'insertdatetime media table paste code help wordcount emoticons'
                                 ],
                                 toolbar: false,
                                 content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                              }}
                           /> */}
                           <Button
                              style={{ marginTop: "10px" }}
                              className='button-shadow'
                              shape='round'
                              type='primary'
                              block
                              onClick={() => handleSaveActivityRemarks()}
                           >
                              Send Feedback
                           </Button>
                           {/* </Space> */}
                           <Divider orientation="left">
                              <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Submitted Activities</Title>
                           </Divider>
                           <SimpleBarReact style={{ height: "calc(100vh - 589px)", backgroundColor: "#fff", minHeight: "200px" }}>
                              <List
                                 key={uuid}
                                 size="small"
                                 dataSource={g_activity.act_docs}
                                 renderItem={(item, idx) =>
                                    <Tooltip placement="right" title={g_activity.act_doc_names[idx]}>
                                       <List.Item
                                          onClick={() => handleViewStudyPlanActivityDoc(item, idx)}
                                          className={idx === selectedDocumentIdx ? 'document-file-selected' : 'document-file'}
                                          style={{ textAlign: "left", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                                       >
                                          {Utils.GetFileIcon(g_activity.act_doc_names[idx])} {g_activity.act_doc_names[idx]}
                                       </List.Item>
                                    </Tooltip>
                                 }
                              />
                           </SimpleBarReact>
                        </SimpleBarReact>
                     </div>
                  </Col>
                  <Col xs={24} md={15} lg={15}>
                     <div className='essay_list_container'>
                        <DocumentViewer
                           document={process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH + '/' + currentStudyPlanActiviyDoc}
                           documentlist={g_activity.act_docs}
                           // pdfheight={"calc(100vh - 220px)"}
                           pdfshowdownload={true}
                           pdfshowprint={true}
                           // videoheight={"100%"}
                           ismodal={true}
                           dvheight={"calc(100vh - 220px)"}
                        />
                     </div>
                  </Col>
               </Row>
            </Spin>
         </Modal>

         <Modal
            title="Zoom Picker"
            destroyOnClose={true}
            centered
            open={zoomPickerModal}
            onOk={() => setZoomPickerModal(false)}
            // onCancel={() => setZoomPickerModal(false)}
            okText={"Close"}
            // cancelText={"Refresh List"}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round", style: { display: 'none' } }}
            width='75%'
         >
            <div className='student_list_container'>
               <ZoomPicker id={assessment_id} title={masteryrec.title} />
            </div>
         </Modal>

         <Modal
            title={recommendationType === 'individual' ? "Create Recommendation" : "Create Class Recommendation"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancelCreateRecommendation(); }}
            open={createRecommendationModalVisible}
            okText="Send"
            cancelText="Cancel"
            width='85%'
            // footer={null}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <Spin spinning={creating} tip="Creating recommendation, please wait...">
               <CreateRecommendations
                  onCancelCallback={handleCancelCreateRecommendation}
                  onFinishCallback={handleOnFinishCreateRecommendation}
               />
            </Spin>
         </Modal>

         <Modal
            title={recommendationType === 'individual' ? "Edit Teacher Recommendation" : "Edit Class Recommendation"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancelEditRecommendation(); }}
            open={editRecommendationModalVisible}
            okText="Send"
            cancelText="Cancel"
            // width={window.innerWidth - 400}
            width='85%'
            // footer={null}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <Spin spinning={updating} tip="Updating recommendation, please wait...">
               <EditRecommendations
                  data={g_recommendation_data}
                  onCancelCallback={handleCancelEditRecommendation}
                  onFinishCallback={handleOnFinishEditRecommendation}
               />
            </Spin>
         </Modal>

         <Modal
            title="Color Legend - Formative Level"
            destroyOnClose={true}
            centered
            open={showColorLegendModal}
            onOk={() => setShowColorLegendModal(false)}
            okText={"Close"}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round", style: { display: 'none' } }}
            width='25%'
         >
            <Row>
               <Col span={24}>
                  <List
                     itemLayout="horizontal"
                     size="small"
                     // bordered
                     dataSource={dataFormative}
                     renderItem={(item) => (
                        <List.Item>
                           <List.Item.Meta
                              avatar={item.avatar}
                              title={item.title}
                           />
                        </List.Item>
                     )}
                  />
               </Col>
            </Row>
         </Modal>

         <Modal
            title="Color Legend - Study Plan"
            destroyOnClose={true}
            centered
            open={showColorLegendSPModal}
            onOk={() => setShowColorLegendSPModal(false)}
            okText={"Close"}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round", style: { display: 'none' } }}
            width='25%'
         >
            <Row>
               <Col span={24}>
                  <List
                     itemLayout="horizontal"
                     size="small"
                     // bordered
                     dataSource={dataStudyPlan}
                     renderItem={(item) => (
                        <List.Item>
                           <List.Item.Meta
                              avatar={item.avatar}
                              title={item.title}
                           />
                        </List.Item>
                     )}
                  />
               </Col>
            </Row>
         </Modal>
      </>
   );
}