import React, { useState, useEffect } from 'react';
import { Form, Input, Tabs, Table, Typography, Space, Tooltip, Button, Select, Row, Col, Alert, Modal, message, Popconfirm, Breadcrumb } from 'antd';
import * as AntDIcons from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useLocation, useParams, Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import uuid from 'react-uuid';
import {
   post_lookup, post_notice
} from '../../actions';
import SkeletonTable from '../lesson/SkeletonTable';
// import SideCalendar from '../side-calendar/SideCalendar';
// import NoticeBoard from './NoticeBoard';
import NoticeBoardSlide from './NoticeBoardSlide';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import moment from 'moment';

function NoticeList(props) {
   const dateFormat = 'MMM. D, YYYY h:mm a';

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   const [createview, setCreateView] = useState(false)
   const [editview, setEditView] = useState(false)
   const [noticedefault, setNoticeDefault] = useState(null)
   const [title, setTitle] = useState("")
   const [key, setKey] = useState(null);
   const [data, setData] = useState([])
   const [loading, setLoading] = useState(true)
   const navigate = useNavigate();
   // const NoticeDS = [
   //    {
   //       id: "00001",
   //       title: "TITLE001",
   //       content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nisl mi, malesuada quis ex nec, mollis suscipit lorem. ",
   //       active: 1,
   //       staffid: 1,
   //    },
   //    {
   //       id: "00002",
   //       title: "TITLE002",
   //       content: "Aenean tristique odio iaculis turpis euismod commodo. Integer placerat pellentesque porta.",
   //       active: 1,
   //       staffid: 1,
   //    },
   // ];
   const dispatch = useDispatch();

   useEffect(() => {
      let urllink = `${process.env.REACT_APP_API_LESSON_LOOKUP}`
      console.log(urllink)
      axios.get(urllink).then((response) => {
         console.log(response)
         dispatch(post_lookup(response.data))
      })
      getNoticeList()
   }, []
   )
   const handleEdit = () => {

   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getNoticeList = () => {
      let user = Utils.getCurrentUserInfo(Enums.UserInfo.id)
      axios.get(`${process.env.REACT_APP_LMS_API}api/NoticeBoard/listNoticeListPerUser?userid=${user}`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setData(response.data);
            setTimeout(() => {
               setLoading(false)
               console.log('stop loading')
            }, 2000);
         }
      }, (error) => {
         console.log(error.message);
         setLoading(false)
         console.log('stop loading')
      });
   }

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
               </Button>
               <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: 'Title',
         key: 'title',
         ...getColumnSearchProps('title'),
         render: payload => {
            return <div>
               <b>{payload.title}</b>
            </div>
         },
         width: "25%",
         // render: text => <a>{text}</a>,
      },
      {
         title: 'Content',
         dataIndex: 'content',
         key: 'content',
         // width: '30%',
         ellipsis: {
            showTitle: false,
         },
         render: content => (
            //    <Tooltip placement="topLeft" title={content}>               
            //        {content}
            //   </Tooltip>
            <div dangerouslySetInnerHTML={{ __html: content }} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} />

         ),
         width: "35%",
      },
      {
         title: 'Start Date',
         key: 'start',
         render: payload => {
            return <div>
               {payload.start_date}
               {/* {moment(payload.start_date, dateFormat)} */}
            </div>
         },
         width: "15%",
         // render: text => <a>{text}</a>,
      },
      {
         title: 'End Date',
         key: 'end',
         render: payload => {
            return <div>
               {payload.end_date}
               {/* {moment(payload.end_date, dateFormat)} */}
            </div>
         },
         width: "15%",
         // render: text => <a>{text}</a>,
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <span>
               <Space size="small">
                  <Tooltip placement="bottom" title='Edit'>
                     <AntDIcons.EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditNoticeBoard(payload.id, payload.title, payload.content)} />
                  </Tooltip>
                  <Tooltip placement="bottom" title='Remove'>
                     <Popconfirm
                        title="Are you sure to delete this Record?"
                        onConfirm={() => handleDeleteNoticeBoard(payload.id)}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No">
                        <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
                  {/* <Tooltip placement="bottom" title='View'><AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} /></Tooltip> */}
               </Space>
            </span>
         },
         width: "10%",
      },
   ];

   const handleChangeTitle = () => {

   }

   const handleEditNoticeBoard = (notice_id, title, content) => {

      axios.get(`${process.env.REACT_APP_LMS_API}api/NoticeBoard/getNotice?id=${notice_id}`).then((response) => {
         if (response.data) {
            console.log(response.data)
            const data = response.data;
            let noticedata = {
               id: data.id,
               title: data.title,
               content: data.content,
               active: 0,
               issection: 0,
               sectionlist: data.sectionList,
               isgradelevel: 0,
               gradelevellist: data.gradeLevelList,
               isacad: 0,
               acadlevellist: data.acadLevelList,
               staffid: 0,
               start_date: data.start_date,
               end_date: data.end_date,
            };
            setNoticeDefault(noticedata);
            dispatch(post_notice(noticedata))
            setEditView(true)
         }
      }, (error) => {
         console.log(error.message);
      });

      //   let notice_default = {
      //      id: notice_id,
      //      title: '',
      //      content: '',
      //      active: 0,
      //      issection: 0,
      //      sectionlist: [],
      //      isgradelevel: 0,
      //      gradelevellist: [],
      //      isacad: 0,
      //      acadlevellist: [],
      //      staffid: 0
      //   } 


      //   setNoticeDefault(notice_default);
   };

   const handleDeleteNoticeBoard = (key) => {
      console.log(key);
      //   message.success('Click on Yes (' + key + ')');
      axios.post(`${process.env.REACT_APP_LMS_API}api/NoticeBoard/deleteNotice?id=${key}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Notice Board',
               content: 'Your record has been deleted.',
               centered: true,
               // onOk: refreshList(activeTab)
            });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Notice Board',
            content: 'Error deleting record.',
            centered: true,
         });
      });
      getNoticeList()
   }

   const handleDuplicateNoticeBoard = (key) => {
      // console.log(e);
      // message.success('Click on Yes (' + key + ')');

      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
      var newlessonid = "notice_" + o2o_mode + "_" + uuid();

      axios.post(`${process.env.REACT_APP_LMS_API}api/NoticeBoard/duplicateNotice?noticeid=${key}&newnoticeid=${newlessonid}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Duplicate Lesson',
               content: 'Your lesson has been duplicated.',
               centered: true,
               // onOk: refreshList(activeTab)
            });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Duplicate Lesson',
            content: 'Error duplicating lesson.',
            centered: true,
            // onOk: refreshList(activeTab)
         });
      });
   }

   const handleClick = () => {
      setCreateView(true)
   }
   return (
      // <div>
      <>
         {createview && <Navigate to={'/notice/create'} replace state={{}} />}
         {editview && <Navigate to={'/notice/edit'} replace state={{}} />}

         <NoticeBoardSlide />

         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Notice Board</a></li>
                  </ul> */}
                  <Breadcrumb style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate('/home')}>Home</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Notice Board</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               <Row gutter={12}>
                  <Col className="gutter-row" span={24}>
                     <div className='search-columns-2'>
                        {/* <Form.Item style={{ display: 'inline', marginBottom: '0px' }} >
                           <Input
                              size="large"
                              style={{ borderRadius: 7 }}
                              placeholder="Enter Notice Title..."
                              defaultValue={title}
                              value={title}
                              onChange={(event) => handleChangeTitle(event.target.value)}
                           />
                        </Form.Item> */}

                        <Form.Item>
                           <Button className='button-shadow' type="primary" size="large" shape='round' icon={<AntDIcons.PlusOutlined />} onClick={handleClick}>
                              Create Notice
                           </Button>
                        </Form.Item>
                     </div>
                  </Col>
               </Row>

               <div className="dashboard-item-inner3">
                  {/* <SkeletonTable loading={loading} columns={columns} rowCount={1} />
                           {
                              !loading &&
                              <Table 
                              size='small' 
                              rowKey={'uid'}
                              columns={columns} 
                              dataSource={loading ? [] : data}
                              pagination={{ pageSize: 5 }}
                              />
                           } */}
                  <Table
                     loading={loading}
                     size='small'
                     rowKey={'uid'}
                     columns={columns}
                     dataSource={loading ? [] : data}
                     pagination={{ position: ['topCenter'] }}
                     // style={{ minWidth: "1000px" }}
                     scroll={{
                        // y: 'calc(100svh - 20rem)',
                        x: '1000px',
                        y: 'calc(100svh - 16rem)',
                     }}
                     footer={() => 'Total Records: ' + data.length}
                  />
               </div>

            </div>
         </div>
         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}

      </>
      // </div>
   );
}

export default NoticeList;