import { useContext, useState } from 'react'
// import { CanvasContext } from '../../EditLessonPage'
import { CanvasContext } from '../../hooks/LessonPage'
import SearchServiceGoogleAPI from '../../LessonComponents/SearchServiceGoogleAPI'
import SearchObjectsAPI from '../../LessonComponents/SearchObjectsAPI';

// import { getPixabayImages, PixabayImage } from '@services/pixabay'
// import { useDebounce } from 'use-debounce'
// import { useHandlers } from '@/uibox'

function ImagesPanel() {
  const { actions } = useContext(CanvasContext);

  const setItem = (param) =>
  {
      var data = {type : "IMAGE", url : param.image, defaultContent : ""}
      actions?.addElement(data);
  }

  return (
    <>
      {/* <SearchServiceGoogleAPI type={2} add={setItem}/> */}
      <SearchObjectsAPI add={setItem} type={"image"}/>
    </>
  )
}
export default ImagesPanel
