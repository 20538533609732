import React, { useEffect, useRef, useState, useContext } from 'react'
import { LeftCircleOutlined, SendOutlined, PlusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Button, message, Skeleton, Input, Row, Col, Form, Select,Checkbox } from 'antd';
import { Configuration, OpenAIApi } from 'openai';
import "./AIPanels.scss";
import ReactHtmlParser from "react-html-parser"
import { CanvasContext } from '../../hooks/LessonPage';

function AIPanel() {
   const { actions } = useContext(CanvasContext);
   const [form] = Form.useForm();
   const { Option } = Select;
   const [subject, setSubject] = useState('');
   const [numslides, setNumSlides] = useState(1);
   const [language, setLanguage] = useState('english');
   const [topic, setTopic] = useState('');
   const [gradeLevel, setGradeLevel] = useState('');
   const [running, setRunning] = React.useState(false);
   const [xhr, setXhr] = React.useState(null);
   const [lessonTitle, setLessonTitle] = useState('');
   const [slideSelected, setSlideSelected] = useState(false);
   const [lessonDescription, setLessonDescription] = useState('');
   const [message, setMessage] = useState('');
   const [slides, setSlides] = useState([]);
   const [tmpSlides, setTmpSlides] = useState([]);
   const [isLoading, setIsLoading] = React.useState(false);
   const [done, setDone] = React.useState(false);


   const configuration = new Configuration({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
   });

   const openai = new OpenAIApi(configuration);


   useEffect(() => {
      if (done)
         generatethumbnails(slides)
   }, [slides, done]);

   const setItem = () => {
      if (!slideSelected)
        actions?.addAIElements(slides);
      else
        actions?.addAIElements(tmpSlides);
   }

   const handleCheckChange = (event, idx) => {

      var slidesTemp = tmpSlides
      if (event.target.checked)
      {
         slidesTemp.push({...slides[idx], index : idx})  
         setTmpSlides(slidesTemp)
      }
      else
      {
         slidesTemp = slidesTemp.filter((item) => item.index !== idx)
         setTmpSlides(slidesTemp)
      }

      setSlideSelected(slidesTemp.length > 0)
   }

   const onFinish = () => {

      var retVal = `Generate ${numslides} slides for topic ${lessonTitle} that describe the ${lessonDescription} with 5 bullet points on each slide with title
                - It should be based on Philippines DepEd curriculum
                - Bullet points should only have maximum of 7 words
                - It should be suitable for ${gradeLevel} students.
                -  Keep language level suitable for ${gradeLevel} students.
                - Translate the lesson plan in ${language}
                -  Provide valid JSON output in array format
                  - Provide a valid and meaningful title for the first column as 'topic'
                  - next column is 'topic_description' representing the description of the topic
                  - next column is 'bullet_points' representing the list of the bullet points for this topic`

      generateBotResponse(retVal)

   }

   const generatethumbnails = (data) => {
      var subcontent = slides
      return (
         <>

            <Row gutter={[10, 10]} style={{ marginTop: "5px", marginBottom: "20px" }}>
               <Col sm={24} md={12} lg={12}>
                  <Button  size='middle' type="default" shape='round' block
                     onClick={() => setDone(false)}
                  >
                     <LeftCircleOutlined />Back
                  </Button>
               </Col>
               <Col sm={24} md={12} lg={12}>
                  <Button  size='middle' type="default" shape='round' block
                     onClick={() => setItem()}
                  >
                     <PlusCircleOutlined /> {slideSelected ? "Add Slide(s)"  : "Add All"}
                  </Button>
               </Col>
            </Row>
            <Row >
            {slides.map((item, idx) => {
               return (
                  <div style={{ margin: "10px" }}>
                     <div style={{color : "white", fontWeight : "bold", display : "flex"}}>
                     <div style={{paddingRight : "5px"}}>{`Slide ${idx+1}`}</div>
                     <Checkbox
                           id={idx}
                           // defaultChecked={props.correct == "1" ? true : false} 
                           // checked={props.correct === "1" ? true : false}
                           onChange={(e) => handleCheckChange(e, idx)}
                         >
                         
                        </Checkbox>                          
                     </div>
                     <div className='container_ai'>
                        <div className='thumname_ai' >

                           {/* This is video container */}
                           <div className='image_container'>
                              <img style={{ position: "relative", width: "100%", height: "100%" }} src={item.videoimage}></img>
                              {/* <span class="play-btn"></span> */}
                           </div>
                           {/* Header container */}
                           <div className='header_container' >
                              <div style={{ height: "100%", width: "100%" }}>
                                 {ReactHtmlParser(`<h1><strong class="ql-font-Inter">${item.topic}</strong></h1>`)}
                              </div>
                           </div>
                           <div className='sub-topic_container' >
                              <div style={{ height: "100%", width: "100%" }}>
                                 {ReactHtmlParser(item.subtopics)}
                              </div>
                           </div>
                        </div>                      
                        {/* <div
                           // onClick={(e) => configClick(e,i)}
                           style={{
                              position: "absolute",
                              cursor: 'pointer',
                              display: "block",
                              zIndex: "3",
                              top: "5px",
                              left: "255px",
                              width: "30px",
                              height: "22px",
                              textAlign: "center",
                              color: "gray",
                              margin: "0px"
                           }}><i style={{ fontSize: "20px" }} class="fas fa-gear"></i>
                        </div> */}
                     </div>
                  </div>
               )
            })}
            </Row>
         </>
      )
   }

   const generateBotResponse = async (questions) => {
      // if (newQuestion !== '' && newQuestion !== '\r' && newQuestion !== '\n') {

      var api = ""
      const headers = {}
      const googleAPIKey = `${process.env.REACT_APP_GOOGLE_KEY}`

      setIsLoading(true)
      setDone(false)
      setTmpSlides([])
      //  setBotSearching(true);
      //  var tmpMessage = [];
      //  tmpMessage.push({ "role": "user", "content": questions });
      //  setMessages(tmpMessage);
      setMessage('Generating...please wait.')

      try {
         const response = await openai.createChatCompletion({
            model: "gpt-4o",
            max_tokens: 1500,
            temperature: 0.5,
            top_p: 1,
            messages: [
               { role: "system", content: "Provide output in valid JSON" },
               { role: "user", content: questions }
            ],
            n: 1,
            stream: false,
            response_format: { type: "json_object" }
         });
         setMessage('Almost there! ')
         if (response.data.choices) {
            var responseBot = response.data.choices[0].message?.content.trim()
            var objectResponse = JSON.parse(responseBot);
            var tmpObjectResponse = [...objectResponse.slides]
            var ctr = 0
            for (var slide of objectResponse.slides) {
               const cx = `${process.env.REACT_APP_ENGINE_ID}` 
               var topic = "Relevant Slide images for " + lessonTitle + ' - ' + slide.topic + " - " + slide.topic_description + " for " + gradeLevel + " students"
               api = `${process.env.REACT_APP_YOUTUBE_API}=${googleAPIKey}&q=${topic}&maxResults=1&part=snippet`;
               // api = `${process.env.REACT_APP_SEARCH_API}=${googleAPIKey}&cx=${cx}&q=${topic}&searchType=image&maxResults=20&part=snippet`;
               const response = await axios.get(api, {
                  transformRequest: (data, headers) => {
                     delete headers.common['Authorization'];
                     delete headers.common['Subdomain'];
                     return data;
                  }
               })
               if (response) {
                  // var urlId = response.data.items[0].link
                  // var image = response.data.items[0].link
                  var urlId = response.data.items[0].id.videoId
                  var image = response.data.items[0].snippet.thumbnails["default"].url                  
                  var subctr = 0
                  var subtopic_content = "<ul>"
                  for (var subtopic of slide.bullet_points) {
                     subtopic_content += `<li><h4> ‣ ${subtopic}</h4></li>`
                     subctr += 1
                  }
                  subtopic_content += "</ul>"
                  tmpObjectResponse[ctr] = { ...slide, "url": urlId, "videoimage": image, "subtopics": subtopic_content }
               }
               ctr += 1
            }

            var s = ""
            setIsLoading(false)
            setSlides(tmpObjectResponse)
            setDone(true)
         }
      }
      catch (ex) {
         // setIsTyping(false);
         // message.warning("Error connecting to the server. Please try again.");
         setIsLoading(false)
         setDone(false)
         //console.log(ex);
      }

      //  setBotSearching(false)
      // } 
   };

   const aiForm = () => {
      return (
         <>
            <Form 
               form={form}
               layout={"vertical"}
               colon={false}
               className='aiform'
               onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            >
               {/* <SimpleBarReact key={'uid'} style={{ maxHeight: "calc(100vh - 317px)" }}> */}
               <Form.Item name="Slides" label={<label style={{ color: "black",  marginBottom: "1px"  }}>Number of Slides</label>}
                  rules={[{
                     required: true,
                     message: 'Slides should not be blank'
                  }]}
                  initialValue={numslides}
                  style={{ marginBottom: "5px" }}
               >
                  <Select
                     key={'uid'}
                     showArrow
                     size='large'
                     style={{ width: '100%' }}
                     allowClear
                     placeholder="Select number of slides"
                     // defaultValue={'araling panlipunan'}
                     onChange={(val) => setNumSlides(val)}
                  >
                     <Option value={1}>1</Option>
                     <Option value={2}>2</Option>
                     <Option value={3}>3</Option>
                     <Option value={4}>4</Option>
                     <Option value={5}>5</Option>
                     <Option value={6}>6</Option>
                     <Option value={7}>7</Option>
                     <Option value={8}>8</Option>
                     <Option value={9}>9</Option>
                     <Option value={10}>10</Option>
                  </Select>
               </Form.Item>
               <Form.Item name="subject" label={<label style={{ color: "black",  marginBottom: "1px"  }}>Subject</label>}
                  rules={[{
                     required: true,
                     message: 'Subject should not be blank'
                  }]}
                  initialValue={subject}
                  style={{ marginBottom: "5px" }}
               >
                  <Select
                     key={'uid'}
                     showArrow
                     size='large'
                     style={{ width: '100%' }}
                     allowClear
                     placeholder="Select subject"
                     // defaultValue={'araling panlipunan'}
                     onChange={(val) => setSubject(val)}
                  >
                     <Option value={'arts'}>Arts</Option>
                     <Option value={'araling panlipunan'}>Araling Panlipunan</Option>
                     <Option value={'edukasyong pantahanan at pangkabuhayan'}>Edukasyong Pantahanan at Pangkabuhayan</Option>
                     <Option value={'edukasyong sa pagpapakatao'}>Edukasyon sa Pagpapakatao</Option>
                     <Option value={'english'}>English</Option>
                     <Option value={'filipino'}>Filipino</Option>
                     <Option value={'mathematics'}>Mathematics</Option>
                     <Option value={'music'}>Music</Option>
                     <Option value={'science'}>Science</Option>
                     <Option value={'technology and livelihood education'}>Technology and Livelihood Education</Option>
                  </Select>
               </Form.Item>
               <Form.Item name="gradelevel" label={<label style={{ color: "black",  marginBottom: "1px"  }}>Grade Level</label>}
                  rules={[{
                     required: true,
                     message: 'Grade level should not be blank'
                  }]}
                  initialValue={gradeLevel}
                  style={{ marginBottom: "5px" }}
               >
                  <Select
                     key={'uid'}
                     showArrow
                     size='large'
                     style={{ width: '100%' }}
                     allowClear
                     placeholder="Select grade level"
                     // defaultValue={'kinder'}
                     onChange={(val) => setGradeLevel(val)}
                  >
                     <Option value={'kinder'}>Kinder</Option>
                     <Option value={'grade 1'}>Grade 1</Option>
                     <Option value={'grade 2'}>Grade 2</Option>
                     <Option value={'grade 3'}>Grade 3</Option>
                     <Option value={'grade 4'}>Grade 4</Option>
                     <Option value={'grade 5'}>Grade 5</Option>
                     <Option value={'grade 6'}>Grade 6</Option>
                     <Option value={'grade 7'}>Grade 7</Option>
                     <Option value={'grade 8'}>Grade 8</Option>
                     <Option value={'grade 9'}>Grade 9</Option>
                     <Option value={'grade 10'}>Grade 10</Option>
                     <Option value={'grade 11'}>Grade 11</Option>
                     <Option value={'grade 12'}>Grade 12</Option>
                  </Select>
               </Form.Item>
               <Form.Item name="title" label={<label style={{ color: "black",  marginBottom: "1px"  }}>Topic</label>}
                  rules={[{
                     required: true,
                     message: 'Topic should not be blank'
                  }]}
                  initialValue={topic}
                  style={{ marginBottom: "5px" }}
               >
                  <Input
                     key={'uid'}
                     allowClear
                     size='large'
                     style={{ borderRadius: 7 }}
                     placeholder="Enter your topic here"
                     onBlur={(event) => setLessonTitle(event.target.value)}
                  />
               </Form.Item>
               <Form.Item name="description" label={<label style={{ color: "black",  marginBottom: "1px"  }}>Topic Description</label>}
                  // rules={[{
                  //    required: true,
                  //    message: 'Lesson Description should not be blank'
                  // }]}
                  initialValue={lessonDescription}
                  style={{ marginBottom: "5px" }}
               >
                  <Input
                     key={'uid'}
                     allowClear
                     size='large'
                     style={{ borderRadius: 7 }}
                     placeholder="Describe your topic"
                     onBlur={(event) => setLessonDescription(event.target.value)}
                  />
               </Form.Item>

               <Form.Item name="translation" label={<label style={{ color: "black",  marginBottom: "1px"  }}>Translation</label>}
                  rules={[{
                     required: true,
                     message: 'Translation should not be blank'
                  }]}
                  initialValue={language}
                  style={{ marginBottom: "45px" }}
               >
                  <Select
                     key={'uid'}
                     showArrow
                     size='large'
                     style={{ width: '100%' }}
                     allowClear
                     placeholder="Select translation"
                     // defaultValue={'english'}
                     onChange={(val) => setLanguage(val)}
                  >
                     <Option value={'english'}>English</Option>
                     <Option value={'tagalog'}>Filipino</Option>
                     {/* <Option value={'japanese'}>Japanese</Option>
                           <Option value={'arabic'}>Arabic</Option> */}
                  </Select>
               </Form.Item>

               <Row gutter={[10, 10]} style={{ marginTop: "5px" }}>
                  <Col sm={24} md={12} lg={12}>
                     {
                        running
                           ?
                           <Button className='button-shadow' size='middle' type="primary" shape='round' block loading={running}
                              htmlType='submit'
                           >
                              Generating...
                           </Button>
                           :
                           <Button size='middle' type="default" shape='round' block loading={running} style={{ color: "black" }}
                              htmlType='submit'
                           >
                              <SendOutlined />Generate
                           </Button>
                     }
                  </Col>
                  <Col sm={24} md={12} lg={12}>
                     <Button className='button-shadow' size='middle' type="primary" shape='round' block disabled={!running} onClick={() => xhr?.abort()}>
                        Stop
                     </Button>
                  </Col>
               </Row>
               {/* </SimpleBarReact>  */}
            </Form>
         </>
      )
   }



   return (
      <>
         <div style={{ padding: '2rem 2rem 1rem' }}>
            {(!isLoading && !done) && aiForm()}
            {(isLoading && !done) && <div className='result-container'>
               <div style={{ color: "white", textAlign: "center", fontSize: "20px" }}>{message}</div>
               <div className='image-container' >
                  <Skeleton.Image active className='image-result' style={{ width: "250px", height: "180px" }} />
               </div>
               {/* <div style={{ width : '250px', margin : 'auto', paddingTop : '10px'}}>
                  <div className=' author-style'  ><Skeleton.Input active style={{width : "250px" }}/></div>
                  <div className=' description-style' ><Skeleton.Input active  style={{width : "250px" }}/></div>    
               </div>            */}
            </div>}
            {done && generatethumbnails()}
         </div>
      </>
   )
}
export default AIPanel
