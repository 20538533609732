export const MasteryAssessmentModel = {
   id: null,
   title: null,
   term: null,
   level: null,
   subject: null,
   formative_1: null,
   formative_1_file_url: null,
   formative_1_passing_percentage: null,
   formative_2: null,
   formative_2_file_url: null,
   formative_2_passing_percentage: null,
   formative_3: null,
   formative_3_file_url: null,
   formative_3_passing_percentage: null,
   summative: null,
   summative_file_url: null,
   summative_passing_percentage: null,
   study_plan_1: null,
   study_plan_2: null,
   mastery_level: null,
   mastery_competency: null,
   created_by: null,
   mode: null,
   subjectname: null,
   levelname: null,
   formative_1_instruction: null,
   formative_2_instruction: null,
   formative_3_instruction: null,
   // recommendation_id: null,
   // recommendation_desc: null,
   // recommendation_docs: null,
   // recommendation_doc_names: null,
};



