import React from "react"
import Iframe from "react-iframe";
import { CloudDownloadOutlined, EyeOutlined} from '@ant-design/icons';
import {
    Button,
    Tooltip
  } from 'antd';

const ViewMSOfficeFile = props => {
    const [downloadStatus, setDownloadStatus] = React.useState(false);

    const _downloadFile = (url) => {
        setDownloadStatus(true)
        var name= url.split('/').pop().split('-').pop()
        fetch(url)
           .then(response => {
              response.blob().then(blob => {
                 let url = window.URL.createObjectURL(blob);
                 let a = document.createElement('a');
                 a.href = url;
                 a.download = name;
                 a.click();
                 setDownloadStatus(false)
              });
           });
     };
  
    const renderFile = () =>
    {
      return(<>
        <div  style={{width : "100%", height : "100%"}}>
            {/* <div 
                    style={{
                        alignItems: 'center',
                        backgroundColor: '#eeeeee',
                        border: '1px solid white',
                        borderRadius: '2px',
                        top: '16px',
                        display: 'flex',
                        right: '10px',
                        padding: '4px',
                        position: 'absolute',
                        // transform: 'translate(-50%, 0)',
                        zIndex: 1,
                        backgroundColor : "transparent"
                }}                        
            >
            <div style={{marginRight : "5px"}}>
                <Tooltip title="View File">
                    <Button 
                        size='large' 
                        style={{width : "40px", height : "40px" , fontSize : "15px", borderRadius : "5px"}}
                        type="primary"
                        shape="round" 
                        icon={<EyeOutlined  style={{ fontSize: '16px'}}  />} 
                        onClick={()=> window.open(`${process.env.REACT_APP_MSOFFICE_VIEWER}${props.filename}`, "_blank")}
                        />
                </Tooltip>
            </div>                           
            <div>
                <Tooltip title="Download File">
                    <Button 
                        size='large' 
                        style={{width : "40px", height : "40px" , fontSize : "15px", borderRadius : "5px"}}
                        type="primary"
                        shape="round" 
                        icon={<CloudDownloadOutlined style={{ fontSize: '16px'}}  />}                                   
                        disabled={downloadStatus}
                        onClick={() => _downloadFile(props.filename)} 
                        />
                </Tooltip>
            </div>
            </div>  */}
            <div style={{ display: "flex", pointerEvents : props.readonly === true ? "none" : "" }}>
                <Iframe url={`${process.env.REACT_APP_MSOFFICE_VIEWER}${props.filename}`}
                    position="absolute"
                    width="100%"
                    id="frameOffice"
                    height="100%"
                />
            </div>
        </div>      
      </>
      )     
    }

  
    return (renderFile())
  }
  
  export default ViewMSOfficeFile