/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Button, Input, Space, Tooltip, Table, Breadcrumb } from 'antd';
import * as AntDIcons from '@ant-design/icons';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { useNavigate, Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

export function Survey(props) {
   const navigate = useNavigate();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   const [surveyAssignedList, setSurveyAssignedList] = useState([]);
   const [surveyListLoading, setSurveyListLoading] = useState(false);


   useEffect(() => {
      Utils.verifyJwt();

      getSurveys();

   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const ReportCardsListColumns = [
      {
         title: '',
         dataIndex: 'survey_link',
         key: 'survey_link',
         hidden: true,
      },
      {
         title: 'Teacher',
         key: 'surveyee_name',
         dataIndex: 'surveyee_name',
         sorter: (a, b) => { return a.surveyee_name.localeCompare(b.surveyee_name) },
         width: '20%'
      },
      {
         title: 'Description',
         key: 'description',
         dataIndex: 'description',
         sorter: (a, b) => { return a.description.localeCompare(b.description) },
         width: '25%'
      },
      {
         title: 'Level',
         key: 'level_name',
         dataIndex: 'level_name',
         sorter: (a, b) => { return a.level_name.localeCompare(b.level_name) },
         width: '15%'
      },
      {
         title: 'Section',
         key: 'section_name',
         dataIndex: 'section_name',
         sorter: (a, b) => { return a.section_name.localeCompare(b.section_name) },
         width: '15%'
      },
      {
         title: 'Assigned By',
         key: 'assigned_by_name',
         dataIndex: 'assigned_by_name',
         sorter: (a, b) => { return a.assigned_by_name.localeCompare(b.assigned_by_name) },
         width: '20%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return (
               <>
                  <Space size="small">
                     <Tooltip placement="top" title={'View Survey'}>
                        <AntDIcons.EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }}
                           onClick={() => handleViewSurvey(payload.survey_link)}
                        />
                     </Tooltip>
                  </Space>
               </>
            );
         },
         width: '25%'
      },
   ].filter(item => !item.hidden);

   const getSurveys = () => {
      setSurveyListLoading(true);
      axios.get(`${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/getsurveyassignedstudent?studentid=${user_id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            setSurveyAssignedList(data);
            setSurveyListLoading(false);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleViewSurvey = (link) => {
      window.open(link, '_blank').focus();
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Teacher Survey</a></li>
                  </ul> */}

                  <Breadcrumb style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate('/home')}>Home</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Teacher Survey</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               <Row gutter={12} style={{ overflow: "auto" }} >
                  <Col span={24}>
                     <div className="dashboard-item-inner3">
                        <Table
                           rowKey={'uid'}
                           loading={surveyListLoading}
                           size='small'
                           columns={ReportCardsListColumns}
                           dataSource={surveyAssignedList}
                           pagination={{ pageSize: 10, position: ['topRight', 'bottomRight'] }}
                           style={{ minWidth: '650px' }}
                        />
                     </div>
                  </Col>
               </Row>
            </div>
         </div>

         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}
      </>
   );
}