import axios from 'axios';


const loginService = (request) => {
   const headers = {};
   let api = `${process.env.REACT_APP_LMS_API_LOGIN}/login`;
   return axios.post(api, request, { headers: headers });
}

const logout = (user) => {
   const headers = {};
   let api = `${process.env.REACT_APP_LMS_API_LOGIN}/logout?user=${user}`;
   return axios.post(api, { headers: headers });
}

const changePasswordService = (request) => {
   const headers = {};
   let api = `${process.env.REACT_APP_LMS_API_LOGIN}/changePassword`;
   return axios.post(api, request, { headers: headers });
}

const changeUsernameService = (request) => {
   const headers = {};
   let api = `${process.env.REACT_APP_LMS_API_LOGIN}/changeUsername`;
   return axios.post(api, request, { headers: headers });
}

const jwtValidation = () => {
   const url = `${process.env.REACT_APP_LMS_API_LOGIN}/checkToken`;
   return axios.get(url);
}

export default {
   loginService,
   changePasswordService,
   jwtValidation,
   logout,
};