import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { UndoOutlined } from '@ant-design/icons';
import { Button, Descriptions, message, Popconfirm, Typography, Select, Modal, Breadcrumb } from 'antd';
import StickyBox from "react-sticky-box";
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import './Assessment.css';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import Utils from '../../misc/Utils';
import Split from 'react-split';
// import './SplitPanel.css';

const { Text, Paragraph } = Typography;
const { Option } = Select

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];

export function BViewManual(props) {
   const location = useLocation();
   const navigate = useNavigate();

   const [origin, setOrigin] = useState(location.state.origin);
   const [id, setId] = useState(location.state.id);
   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: "",
      Term: "",
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: "",
      Level: "",
      Subject: "",
      Level_desc: "",
      Subject_desc: ""
   });

   useEffect(() => {
      Utils.verifyJwt();
      getAssessmentInfo(id);
   }, []);

   const getAssessmentInfo = (id) => {
      axios.get(`${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getinfo?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            setAssInfo({
               Id: data.id,
               Title: data.title,
               Term: data.term,
               File_url: data.file_url,
               Question_type: data.question_type,
               Created_by: data.created_by,
               Date_created: data.date_created,
               Question_sheet: JSON.parse(data.question_sheet),
               Level: data.level,
               Subject: data.subject,
               Level_desc: data.level_desc,
               Subject_desc: data.subject_desc
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleRestoreAssessment = (id) => {
      axios.post(`${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/undelete?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Diagnostic Assessment',
               content: 'Your assessment has been restored.',
               centered: true,
            });

            navigate(`/bdiagnostic?type=${location.state.type}`, { state: { activetab: "5" } })
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Diagnostic Assessment',
            content: 'Assessment restore failed.',
            centered: true
         });
      });
   }

   const handleRestoreAssignedAssessment = (id) => {
      axios.post(`${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/undeleteassigned?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Diagnostic Assessment',
               content: 'Your assigned assessment has been restored.',
               centered: true,
            });

            navigate(`/bdiagnostic?type=${location.state.type}`, { state: { activetab: "5" } })
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Diagnostic Assessment',
            content: 'Assigned assessment restore failed.',
            centered: true
         });
      });
   }

   const handleCardRequest = () => {

   }

   const handleRestore = () => {
      if (origin.toLowerCase() === 'main') {
         handleRestoreAssessment(id);
      } else {
         handleRestoreAssignedAssessment(id);
      }
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate(`/bdiagnostic?type=${location.state.type}`, { state: { activetab: "1" } })}>Diagnostic Assessments</a></li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate(`/bdiagnostic?type=${location.state.type}`, { state: { activetab: "5" } })}>Recycle Bin</a></li>
                           <li><span>/</span></li>
                           <li className="active">View</li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <a href="#" onClick={() => navigate(`/bdiagnostic?type=${location.state.type}`, { state: { activetab: "1" } })}>{location.state.type === 'pre' ? '(Pre) ' : '(Post) '} Diagnostic Assessment</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>
                           <a href="#" onClick={() => navigate(`/bdiagnostic?type=${location.state.type}`, { state: { activetab: "5" } })}>Recycle Bin</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>View</Breadcrumb.Item>
                        </Breadcrumb>
                     </div>

                     {assInfo.Question_sheet &&
                        <div className='answer-sheet-content2'>
                           <SimpleBarReact>
                              <ul className="questions">
                                 {assInfo.Question_sheet.map(({ id, type, points, correct, option_labels, question, image_url, template, question_image, question_audio, question_video }, idx) => {

                                    if (type == "section") {
                                       qcardIdx = 1;
                                       idxCol.push(sectionIdx);
                                       sectionIdx++;
                                    } else {
                                       idxCol.push(qcardIdx);
                                       qcardIdx++;
                                    }

                                    return (
                                       <li>
                                          <BraineeQuestionCard
                                             key={id}
                                             index={idxCol[idx]}
                                             id={idx}
                                             type={type}
                                             points={points}
                                             correct={correct}
                                             option_labels={option_labels}
                                             question={question}
                                             image_url={image_url}
                                             question_type={'2'}
                                             mode={'view'}
                                             parentCallback={handleCardRequest}
                                             template={template}
                                             question_image={question_image}
                                             question_audio={question_audio}
                                             question_video={question_video} 
                                             assessment_type={'diagnostic'}
                                          />
                                       </li>
                                    );
                                 })}
                              </ul>
                           </SimpleBarReact>
                        </div>
                     }
                  </div>
               </div>

               <div>
                  <div className="notice-item">
                     <div className='answer-sheet-content'>
                        <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", width: "100%" }} column={4}>
                           <Descriptions.Item label={"Title"} span={4} style={{ textAlign: "center" }} >
                              <Paragraph style={{ textAlign: "center", width: "100%" }}>{assInfo.Title}</Paragraph>
                           </Descriptions.Item>
                           <Descriptions.Item label={"Level"} span={2} style={{ textAlign: "center", width: "50%" }} >
                              <Paragraph style={{ textAlign: "center", width: "100%" }}>{assInfo.Level_desc}</Paragraph>
                           </Descriptions.Item>
                           <Descriptions.Item label={"Subject"} span={2} style={{ textAlign: "center", width: "50%" }} >
                              <Paragraph style={{ textAlign: "center", width: "100%" }}>{assInfo.Subject_desc}</Paragraph>
                           </Descriptions.Item>
                        </Descriptions>
                        <StickyBox style={{ zIndex: 1000 }}>
                           <Popconfirm
                              title="Are you sure you want to restore this assessment?"
                              onConfirm={() => handleRestore()}
                              // onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                           >

                              <Button className='button-shadow' type='primary' block shape='round' style={{ marginBottom: "15px" }}>
                                 <Text ellipsis style={{ color: "#fff" }}><UndoOutlined />&nbsp;Restore</Text>
                              </Button>
                           </Popconfirm>
                        </StickyBox>
                     </div>
                  </div>
               </div>
            </Split>
         </div>
      </>
   )
}
