/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import './MasteryAssessment.css';
import {
   Button, Input, Form, Select, Tag, Row, Col, Descriptions, Space, Tooltip,
   Modal, Spin, Breadcrumb
} from 'antd';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { EyeOutlined } from '@ant-design/icons';
// import uuid from 'react-uuid';
import axios from 'axios';
// import { MasteryTerms, Levels, MasteryCompetency, MasteryGradeLevels, MasterySubjects } from '../../constants'
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { set_mastery } from '../../actions';

import Utils from '../../misc/Utils';
// import Enums from '../../misc/Enums';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

var g_modal_title = "";
var g_modal_study_plan = "";

export function MasteryPreview() {
   const navigate = useNavigate();
   const location = useLocation();
   const dispatch = useDispatch();

   const search = window.location.search;
   const query = new URLSearchParams(search);

   const id = query.get('id');
   const subject = query.get('subject');
   const subjectname = query.get('subjectname');
   const mode = query.get('mode');

   const [form] = Form.useForm();

   // const { Text, Paragraph } = Typography;
   const { Option } = Select;
   const { TextArea } = Input

   // const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   // const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);
   const masteryrec = useSelector(state => state.masteryrec);

   // const [subjectList, setSubjectList] = useState([]);
   const [modalVisible, setModalVisible] = useState(false);

   const [MasteryTerms, setMasteryTerms] = useState([]);
   const [MasteryCompetency, setMasteryCompetency] = useState([]);
   const [MasteryGradeLevels, setMasteryGradeLevels] = useState([]);
   const [MasterySubjects, setMasterySubjects] = useState([]);

   // const dateFormat = 'MMM D YYYY, h:mm a';
   // const customFormat = value => `${value.format(dateFormat)}`;
   // const subdomain = localStorage.getItem("lms-subdomain");
   const subdomain = "ccph";

   useEffect(() => {
      Utils.verifyJwt();

      getMasteryLookUps();
      // else 
      //    getSubjectList(masteryrec.level);
   }, []);

   const getMasteryLookUps = () => {
      var _masteryTerms = [];
      var _masteryCompetency = [];
      var _masteryGradeLevels = [];
      var _masterySubjects = [];

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasteryterms`).then((response) => {
         if (response.data) {
            const data = response.data;
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               _masteryTerms.push(obj);
            });

            axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterysubjects`).then((response) => {
               if (response.data) {
                  const data = response.data;
                  data.map((item) => {
                     let obj = { 'value': item.value, 'name': item.name }
                     _masterySubjects.push(obj);
                  });

                  axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterygradelevels`).then((response) => {
                     if (response.data) {
                        const data = response.data;
                        data.map((item) => {
                           let obj = { 'value': item.value, 'name': item.name }
                           _masteryGradeLevels.push(obj);
                        });

                        axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterycompetency`).then((response) => {
                           if (response.data) {
                              const data = response.data;
                              data.map((item) => {
                                 let obj = { 'value': item.value, 'name': item.name }
                                 _masteryCompetency.push(obj);
                              });
                           }

                           if (masteryrec.id === null)
                              getMasteryInfo(id);

                           setMasteryTerms(_masteryTerms);
                           setMasteryCompetency(_masteryCompetency);
                           setMasteryGradeLevels(_masteryGradeLevels);
                           setMasterySubjects(_masterySubjects);
                        }, (error) => {
                           console.log(error.message);
                        });
                     }
                  }, (error) => {
                     console.log(error.message);
                  });
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getMasteryInfo = (id) => {
      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/info?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;

            var mastery_data = {
               id: data.id,
               title: data.title,
               term: data.term,
               level: data.level,
               subject: data.subject,
               formative_1: data.formative_1,
               formative_1_file_url: data.formative_1_file_url,
               formative_2: data.formative_2,
               formative_2_file_url: data.formative_2_file_url,
               formative_3: data.formative_3,
               formative_3_file_url: data.formative_3_file_url,
               summative: data.summative,
               summative_file_url: data.summative_file_url,
               study_plan_1: data.study_plan_1,
               study_plan_2: data.study_plan_2,
               mastery_level: data.mastery_level,
               mastery_competency: data.competency,
               created_by: data.created_by,
               formative_1_instruction: data.formative_1_instruction,
               formative_2_instruction: data.formative_2_instruction,
               formative_3_instruction: data.formative_3_instruction,
            }

            dispatch(set_mastery(mastery_data));

            // getSubjectList(data.level);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   // const getSubjectList = (grade_level) => {
   //    axios.get(`${process.env.REACT_APP_API_LOOKUP}/subjectlist?level=${grade_level}&school_year=16`).then((response) => {
   //       if (response.data) {
   //          const data = response.data;
   //          var slist = [];
   //          data.map((item) => {
   //             let obj = { 'value': item.value, 'name': item.name }
   //             slist.push(obj);
   //          });

   //          setSubjectList(slist);
   //       }
   //    }, (error) => {
   //       console.log(error.message);
   //    });
   // }

   const handlePreviewStudyPlan = (title, type) => {
      g_modal_title = title;

      switch (type) {
         case "study_plan_1":
            g_modal_study_plan = masteryrec.study_plan_1.includes('base64') ? masteryrec.study_plan_1 : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.study_plan_1}`;
            break;
         case "study_plan_2":
            g_modal_study_plan = masteryrec.study_plan_2.includes('base64') ? masteryrec.study_plan_2 : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.study_plan_2}`;
            break;
         default:
            g_modal_study_plan = "";
      }

      setModalVisible(true);
   }

   const handlePreviewAssessment = (title, type) => {
      let subject = MasterySubjects.find(x => x.value === masteryrec.subject);
      let level = MasteryGradeLevels.find(x => x.value === masteryrec.level);

      //-- Get card background based on subject and level
      // let bg_image = Utils.GetCardBackground(subject.name.toLowerCase(), level.name.toLowerCase());
      let bg_image = Utils.GetCardBackground(subject.name.toLowerCase(), type);

      if (mode === '1')
         navigate('/masteryassessment/preview-pdf', { state: { title, type, card_bg: bg_image, subject: subject.name, level: level.name, subjectid: subject, id: masteryrec.id, mode } });
      else
         navigate('/masteryassessment/preview-manual', { state: { title, type, card_bg: bg_image, subject: subject.name, level: level.name, subjectid: subject, id: masteryrec.id, mode } });
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subjects</a></li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate(`/masteryassessmentsubject?id=${subject}&name=${subjectname}`)}>Mastery Assessment ({subjectname})</a></li>
                           <li><span>/</span></li>
                           <li><a className="active">Preview</a></li>
                        </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subject</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate(`/masteryassessmentsubject?id=${subject}&name=${subjectname}`)}>Mastery Assessment ({subjectname})</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Preview</Breadcrumb.Item>
                  </Breadcrumb>

                  {
                     masteryrec.id !== null && MasteryCompetency.length > 0
                        ?
                        <div className='mastery_details_div'>
                           <Form
                              form={form}
                              layout="vertical"
                              className='DetailsForm'
                              colon={false}
                              autoComplete="off"
                           >
                              <Row gutter={12}>
                                 <Col xs={24} xl={18}>
                                    <Form.Item label="Title" name="mastery_title" initialValue={masteryrec.title}>
                                       <TextArea
                                          allowClear
                                          rows={5}
                                          style={{ color: '#000000', borderRadius: 7 }}
                                          disabled
                                       />
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} xl={6}>
                                    <Row gutter={12}>
                                       <Col xs={24} xl={24}>
                                          <Form.Item label="Competency" name="competency" initialValue={masteryrec.mastery_competency}>
                                             <Select
                                                size='default'
                                                style={{ width: '100%', textAlign: "left" }}
                                                allowClear
                                                placeholder="Select Competency"
                                                disabled
                                                value={masteryrec.mastery_competency}
                                             >
                                                {MasteryCompetency.map(({ value, name }, idx) => {
                                                   return <Option value={value}>{name}</Option>
                                                })}
                                             </Select>
                                          </Form.Item>
                                       </Col>
                                       {/* <Col xs={24} xl={24}>
                                    <Form.Item label = "Mastery Level" name="mastery_level" 
                                       rules={[
                                       { 
                                          required: true,
                                          message: 'Required'
                                       },
                                       ]} 
                                       initialValue = { masteryrec.mastery_level }
                                    >
                                       <Select 
                                          showArrow 
                                          size='default' 
                                          style={{width: '100%', textAlign:"left"}} 
                                          allowClear 
                                          placeholder="Select Mastery Level" 
                                          onChange={(val) => handleLevel(val)}
                                          value={masteryrec.mastery_level}
                                       >
                                          {MasteryLevels.map(({value, name}, idx) => { 
                                             return <Option value={value}>{name}</Option>
                                          })}
                                       </Select>
                                    </Form.Item>   
                                 </Col> */}
                                       <Col xs={24} xl={24}>
                                          <Form.Item label="Term" name="term" initialValue={masteryrec.term}>
                                             <Select
                                                disabled
                                                showArrow
                                                size='default'
                                                style={{ width: '100%', textAlign: "left" }}
                                                allowClear
                                                placeholder="Select Term"
                                                value={masteryrec.term}
                                             >
                                                {MasteryTerms.map(({ value, name }, idx) => {
                                                   return <Option value={value}>{name}</Option>
                                                })}
                                             </Select>
                                          </Form.Item>
                                       </Col>
                                    </Row>

                                 </Col>
                              </Row>

                              <Row gutter={12}>
                                 <Col xs={24} xl={10}>
                                    <Form.Item label="Grade Level" name="gradelevel" initialValue={masteryrec.level}>
                                       <Select
                                          disabled
                                          showArrow
                                          size='default'
                                          style={{ width: '100%', textAlign: "left" }}
                                          allowClear
                                          placeholder="Select Level"
                                          value={masteryrec.level}
                                       >
                                          {MasteryGradeLevels.map(({ value, name }, idx) => {
                                             return <Option value={value}>{name}</Option>
                                          })}
                                       </Select>
                                    </Form.Item>
                                 </Col>

                                 <Col xs={24} xl={14}>
                                    <Form.Item label="Subject" name="subject" initialValue={masteryrec.subject}>
                                       <Select
                                          disabled
                                          showArrow
                                          size='default'
                                          style={{ width: '100%', textAlign: "left" }}
                                          allowClear
                                          placeholder="Select Subject"
                                          value={masteryrec.subject}
                                       >
                                          {MasterySubjects.map(({ value, name }, idx) => {
                                             return <Option value={value}>{name}</Option>
                                          })}
                                       </Select>
                                    </Form.Item>
                                 </Col>
                              </Row>

                              <Row gutter={12} style={{ marginBottom: "20px" }}>
                                 {masteryrec.summative === null
                                    ?
                                    <>
                                       <Col xs={24} md={12} xl={12}>
                                          <Descriptions bordered size='small' column={2}>
                                             {
                                                <>
                                                   <Descriptions.Item label={<Tag color={masteryrec.formative_1 === null ? "red" : "green"} style={{ fontSize: 13 }}>Formative Level 1</Tag>} span={2} style={{ fontSize: 16, width: "50%" }}>
                                                      <Space direction='horizontal' size={"small"}>
                                                         {masteryrec.formative_1 !== null &&
                                                            <>
                                                               <Tooltip placement="top" title='Preview Formative Level 1'>
                                                                  <Button className='button-shadow' shape='round' type='primary' size='small' icon={<EyeOutlined />}
                                                                     onClick={() => handlePreviewAssessment('Formative Level 1', 'formative_1')}
                                                                  ></Button>
                                                               </Tooltip>
                                                            </>
                                                         }
                                                      </Space>
                                                   </Descriptions.Item>
                                                   <Descriptions.Item label={<Tag color={masteryrec.formative_2 === null ? "red" : "green"} style={{ fontSize: 13 }}>Formative Level 2</Tag>} span={2} style={{ fontSize: 16, width: "50%" }}>
                                                      <Space direction='horizontal' size={"small"}>
                                                         {masteryrec.formative_2 !== null &&
                                                            <>
                                                               <Tooltip placement="top" title='Preview Formative Level 2'>
                                                                  <Button className='button-shadow' shape='round' type='primary' size='small' icon={<EyeOutlined />}
                                                                     onClick={() => handlePreviewAssessment('Formative Level 2', 'formative_2')}
                                                                  ></Button>
                                                               </Tooltip>
                                                            </>
                                                         }
                                                      </Space>
                                                   </Descriptions.Item>
                                                   <Descriptions.Item label={<Tag color={masteryrec.formative_3 === null ? "red" : "green"} style={{ fontSize: 13 }}>Formative Level 3</Tag>} span={4} style={{ fontSize: 16, width: "50%" }}>
                                                      <Space direction='horizontal' size={"small"}>
                                                         {masteryrec.formative_3 !== null &&
                                                            <>
                                                               <Tooltip placement="top" title='Preview Formative Level 3'>
                                                                  <Button className='button-shadow' shape='round' type='primary' size='small' icon={<EyeOutlined />}
                                                                     onClick={() => handlePreviewAssessment('Formative Level 3', 'formative_3')}
                                                                  ></Button>
                                                               </Tooltip>
                                                            </>
                                                         }
                                                      </Space>
                                                   </Descriptions.Item>
                                                </>
                                             }


                                          </Descriptions>

                                       </Col>

                                       <Col xs={24} md={12} xl={12}>
                                          <Descriptions bordered size='small' column={2}>
                                             {
                                                <>

                                                   <Descriptions.Item label={<Tag color={masteryrec.study_plan_1 === null || masteryrec.study_plan_1 === '' ? "red" : "green"} style={{ fontSize: 13 }}>Study Plan 1</Tag>} span={2} style={{ fontSize: 13, width: "50%" }}>
                                                      <Space direction='horizontal' size={"small"}>
                                                         {masteryrec.study_plan_1 !== null &&
                                                            <>
                                                               <Tooltip placement="top" title='Preview Study Plan 1'>
                                                                  <Button className='button-shadow' shape='round' type='primary' size='small' icon={<EyeOutlined />}
                                                                     onClick={() => handlePreviewStudyPlan('Study Plan 1', 'study_plan_1')}
                                                                  ></Button>
                                                               </Tooltip>
                                                            </>
                                                         }
                                                      </Space>
                                                   </Descriptions.Item>

                                                   <Descriptions.Item label={<Tag color={masteryrec.study_plan_2 === null || masteryrec.study_plan_2 === '' ? "red" : "green"} style={{ fontSize: 13 }}>Study Plan 2</Tag>} span={2} style={{ fontSize: 13, width: "50%" }}>
                                                      <Space direction='horizontal' size={"small"}>
                                                         {masteryrec.study_plan_2 !== null &&
                                                            <>
                                                               <Tooltip placement="top" title='Preview Study Plan 2'>
                                                                  <Button className='button-shadow' shape='round' type='primary' size='small' icon={<EyeOutlined />}
                                                                     onClick={() => handlePreviewStudyPlan('Study Plan 2', 'study_plan_2')}
                                                                  ></Button>
                                                               </Tooltip>
                                                            </>
                                                         }
                                                      </Space>
                                                   </Descriptions.Item>
                                                </>
                                             }


                                          </Descriptions>

                                       </Col>
                                    </>
                                    :
                                    <Col xs={24} xl={24}>
                                       <>
                                          <Descriptions.Item label={<Tag color={masteryrec.summative === null ? "red" : "green"} style={{ fontSize: 13 }}>Summative</Tag>} span={4} style={{ fontSize: 16, width: "50%" }}>
                                             <Space direction='horizontal' size={"small"}>
                                                {masteryrec.summative !== null &&
                                                   <>
                                                      <Tooltip placement="top" title='Preview Summative'>
                                                         <Button className='button-shadow' shape='round' type='primary' size='small' icon={<EyeOutlined />}
                                                            onClick={() => handlePreviewAssessment('Summative', 'summative')}
                                                         ></Button>
                                                      </Tooltip>
                                                   </>
                                                }
                                             </Space>
                                          </Descriptions.Item>
                                       </>
                                    </Col>
                                 }
                              </Row>
                           </Form>
                        </div>
                        :
                        <div className='temp-answer-sheet-content2'>
                           <Spin size='large' tip="Loading, please wait..." />
                        </div>
                  }
               </div>
            </div>
         </div>


         {/* Modals */}
         <Modal
            title={"Preview " + g_modal_title}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalVisible}
            // width={window.innerWidth - 300}
            width='75%'

            onCancel={() => { setModalVisible(false); }}
            onOk={() => { setModalVisible(false); }}
         >
            <div style={{ width: "100%", height: "calc(100vh - 200px)" }}>
               <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                  <Viewer
                     pageLayout={{
                        transformSize: ({ size }) => ({
                           height: size.height + 30,
                           width: size.width + 30,
                        }),
                     }}
                     fileUrl={g_modal_study_plan}
                     defaultScale={SpecialZoomLevel.PageWidth}
                     initialPage={0}
                  />
               </Worker>
            </div>
            {/* <div className="pdf-all-page-container" style={{ height: "calc(100vh-200px" }}>
                     <RenderPDFViewer url={g_modal_study_plan} height={"calc(100vh - 208px)"} showdownload={true} showprint={true} showpagecontrols={true} showfullscreen={true} />
                  </div> */}
            {/* <RenderPDFReader url={g_modal_study_plan} height={"calc(100vh - 200px)"} /> */}
            {/* <object data={g_modal_study_plan} width="100%" type="application/pdf" style={{ height: 'calc(100vh - 208px)' }}>
                     <p>Brainee LMS PDF - View<a href={g_modal_study_plan}> PDF!</a></p>
                  </object> */}
         </Modal>
      </>
   );
}