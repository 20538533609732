import './Login.css';
import { useState } from 'react';
// import { Navigate } from "react-router-dom";
import { Button, Alert, Input, Row, Col, Modal, Typography } from 'antd'
import LoginService from '../../services/LoginService';
// import { Radio } from 'antd';
import { Layout, Menu, Breadcrumb } from 'antd';
// import * as AntDIcons from '@ant-design/icons';
// import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import jwtDecode from 'jwt-decode';

import { ExclamationCircleOutlined, UserOutlined, EyeTwoTone, EyeInvisibleOutlined, LockOutlined } from '@ant-design/icons';

const { Header, Content, Footer } = Layout;
const { confirm } = Modal;
const { Text } = Typography;

export default function Login() {
   const [userName, setUsername] = useState('');
   const [password, setPassword] = useState('');
   const [invalidLogin, setInvalidLogin] = useState(false);
   const [userType, setUserType] = useState('1');
   const [signInLoading, setSignInLoading] = useState(false);
   const [signInText, setSignInText] = useState("Sign In");
   // const [passwordVisible, setPasswordVisible] = useState(false);
   const [subdomain, setSubdomain] = useState(localStorage.getItem("lms-subdomain"));
   const [invalidLoginMsg, setInvalidLoginMsg] = useState(null);

   const version = `${process.env.REACT_APP_VERSION}`;
   // const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const redirectLogin = () => {
      setSignInLoading(true);
      setInvalidLogin(false);
      setSignInText("Signing in...");

      var request = { UserName: userName, Password: password, UserType: userType }

      if (userName !== '' && password !== '') {
         LoginService.loginService(request).then((response) => {
            if (response.data !== "" && response.data !== "already logged in") {
               setInvalidLogin(false);
               localStorage.setItem('lms-jwt', response.data);

               var decodedString = jwtDecode(response.data);
               var other_data = JSON.parse(decodedString[Enums.UserInfo.otherdata]);

               if (decodedString.role === 'Registrar' || decodedString.role === 'Interviewer')
                  window.location.href = '/applicantlist';
               else if (decodedString.role === 'Student' && other_data.Status === '0')
                  window.location.href = '/studentassessment';
               else
                  window.location.href = '/home';
            }
            else {
               if (response.data === "already logged in")
                  setInvalidLoginMsg("Your account is already logged in.");
               else
                  setInvalidLoginMsg('Your authentication information is invalid');

               setSignInText("Sign In");
               setSignInLoading(false);
               setInvalidLogin(true);
            }
         }).catch((e) => {
            var ex = e;
            setSignInText("Sign In");
            setSignInLoading(false);
            setInvalidLogin(true);
         })
      } else {
         setSignInText("Sign In");
         setSignInLoading(false);
         setInvalidLogin(true);
      }

      // window.location.href = '/home';
   }

   const alertOnClose = () => {
      setInvalidLogin(false);
   }

   const showConfirm = () => {
      if (userName !== '' && password !== '') {
         confirm({
            title: 'Privacy Policy',
            icon: <ExclamationCircleOutlined />,
            content: <>By clicking "Continue", you agree to our <a href='#' onClick={() => window.open('/privacy-policy', '_blank').focus()}>Privacy Statement</a></>,
            okText: 'Continue',
            centered: true,
            onOk() {
               //  console.log('OK');
               redirectLogin();
            },
            onCancel() {
               console.log('Cancel');
            },
         });
      } else {
         setSignInText("Sign In");
         setSignInLoading(false);
         setInvalidLogin(true);
      }
   };

   return (
      <>
         <Layout className="layout" style={{ backgroundColor: "white", height: "100vh" }}>
            <Header style={{ height: "70px", backgroundColor: "white", borderBottom: "5px", borderBottomColor: "black", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
               <div className="header-logo" >
                  <img src="/images/brainee_logo_new.png" height="52px"></img>
               </div>
            </Header>
            <Content style={{ minHeight: "480px", overflow: "auto", justifyContent: "center", alignItems: "center", display: "flex" }}>
               <div id="loginform">
                  <Row>
                     {/* <Col style={{width:"50%", display:"flex", alignItems:"center", justifyContent:"center", backgroundColor:"#FFF", padding:"10px"}}>
                     <h3 id="headerTitle" style={{color:"#4abec7", paddingTop:"10px"}}>Sign In</h3>
                  </Col> */}
                     <Col style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FFF", padding: "10px", borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }}>
                        <img src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/assets/login_logo.png`} width="150px"></img>
                     </Col>
                  </Row>
                  {/* <div style={{width:"100%", paddingTop:"5px", justifyContent:"center", alignItems:"center", display:"flex"}}><img src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/assets/login_logo.png`} width="100px"></img></div>
               <h3 id="headerTitle" style={{color:"#4abec7", paddingTop:"10px"}}>Sign In</h3> */}
                  {invalidLogin ?
                     <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "20px" }}>
                        <Alert
                           message="Authentication failed"
                           description={invalidLoginMsg}
                           type="error"
                           closable
                           onClose={alertOnClose}
                           style={{ borderRadius: "7px" }}
                        />
                     </div>
                     :
                     <></>
                  }
                  <div style={{ padding: "25px" }}>
                     <Row gutter={12} style={{ marginBottom: "20px" }}>
                        <Col span={24}>
                           <label>User Name</label>
                           <Input
                              disabled={signInLoading}
                              allowClear
                              style={{ borderRadius: "7px" }}
                              size='large'
                              type="text"
                              autocomplete="false"
                              value={userName}
                              onChange={(e) => setUsername(e.target.value)}
                              prefix={<UserOutlined />}
                              onKeyPress={(e) => {
                                 if (e.key === 'Enter') {
                                    redirectLogin();
                                 }
                              }}
                           />
                        </Col>
                     </Row>
                     <Row gutter={12} style={{ marginBottom: "30px" }}>
                        <Col span={24}>
                           <label>Password </label>
                           <Input.Password
                              disabled={signInLoading}
                              allowClear
                              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                              style={{ borderRadius: "7px" }}
                              size='large'
                              autocomplete="false"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              prefix={<LockOutlined />}
                              onPressEnter={() => redirectLogin()}
                           // onKeyPress={(e) => {
                           //    if (e.key === 'Enter') {
                           //       redirectLogin();
                           //    }
                           // }}

                           />
                        </Col>
                     </Row>
                     {/* <OtherMethods /> */}

                     {/*                   <Row gutter={24} style={{marginBottom:"20px"}}>
                     <Col span={24} style={{display:"flex", justifyContent: "center"}}>
                        <Radio.Group defaultValue="1" onChange={(e) => setUserType(e.target.value)} buttonStyle="solid">
                           <Radio.Button value="1">School</Radio.Button>
                           <Radio.Button value="2">Student</Radio.Button>
                           <Radio.Button value="3">Parent</Radio.Button>
                        </Radio.Group>
                     </Col>
                  </Row> */}
                     <Row gutter={12}>
                        <Col span={24}>
                           <Button className='button-shadow' shape='round' size='large' block loading={signInLoading} type='primary'
                              onClick={() => redirectLogin()}
                              onPressEnter={() => redirectLogin()}
                           >
                              {signInText}
                           </Button>
                           {/* <p className="forgot-password text-right">
                              Forgot <a href="#">password</a>?
                           </p> */}
                        </Col>
                     </Row>

                     <Row gutter={12} style={{ paddingTop: '15px' }}>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                           <Text style={{ fontSize: '12px' }}>By clicking "Sign In", you agree to our <a href='#' onClick={() => window.open('/privacy-policy', '_blank').focus()}>Privacy Statement</a></Text>
                        </Col>
                     </Row>
                  </div>
               </div>
            </Content>
            <Footer style={{ textAlign: 'center', backgroundColor: "white", height: "70px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>Brainee ©2022 Powered by Campus Cloud IT Consultancy - Ver.{version}</Footer>
         </Layout>
      </>
   )
}

