 export const SubjectModelBlank = 
    {
     title : '',
     techearName : '',
     subject : '',
     overview : '',
     sections : []
 }



export const SubjectModel = 
  {
    title : 'Consonant Vv and Number Concept Asynchronous (Peace)',
    techearName : 'Euguene Viray',
    subject : 'English',
    overview : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin mauris odio, fringilla a tempor ultricies, volutpat nec libero. Duis facilisis dapibus dui eu faucibus. Nam commodo commodo risus nec pretium.',
    sections : [
        {
            id : 3,
            title : 'Terms and Motivation',
            contents :
            [
                {
                    id : 1,
                    type : 'video',
                    title : 'Associative Law of addition',
                    links : [
                        {
                            id: 0, 
                            type : "videolink",
                            url : 'https://www.youtube.com/watch?v=oTKVXJ7TcbA',
                            image : 'https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2019/11/23171201/Subject-combination.jpg'
                        },
                        {
                            id: 1, 
                            type : "videolink",
                            url : 'https://vimeo.com/160480',
                            image : 'https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2019/11/23171201/Subject-combination.jpg'
                        },
                        {
                            id: 2, 
                            type : "videolink",
                            url : 'https://www.youtube.com/watch?v=lob0bmworcM',
                            image : 'https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2019/11/23171201/Subject-combination.jpg'
                        },
                        {
                            id: 3, 
                            type : "videolink",
                            url : 'https://www.youtube.com/watch?v=CyaVL49hqmI',
                            image : 'https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2019/11/23171201/Subject-combination.jpg'
                        },
                        {
                            id: 4, 
                            type : "videolink",
                            url : 'https://www.youtube.com/watch?v=19c4c3SwtS8',
                            image : 'https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2019/11/23171201/Subject-combination.jpg'
                        }]
                },
                {
                    id : 2,
                    type : 'collaborative',
                    title : 'Associative Law of addition',
                    links : []
                },
                {
                    id : 3,
                    type : 'images',
                    title : 'Associative Law of addition',
                    links : [
                        {
                            id: 0, 
                            type : "image",
                            url : 'https://i.ytimg.com/vi/fTGuTEQCsZY/maxresdefault.jpg',
                            image : ''
                        },
                        {
                            id: 1, 
                            type : "image",
                            url : 'https://www.vhv.rs/dpng/d/469-4694045_pikachu-png-file-pokemon-the-highest-grossing-franchise.png',
                            image : ''
                        },
                        {
                            id: 2, 
                            type : "image",
                            url : 'https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg',
                            image : ''
                        },
                        {
                            id: 3, 
                            type : "image",
                            url : 'https://cdn1.vectorstock.com/i/1000x1000/99/95/doodle-math-algebra-and-geometry-school-equation-vector-26889995.jpg',
                            image : ''
                        }]
                },
                {
                    id : 4,
                    type : 'slideshow',
                    title : 'Associative Law of addition',
                    links : [{
                             id : 0 , 
                             type : "file",
                             url :'https://view.officeapps.live.com/op/embed.aspx?src=https://calibre-ebook.com/downloads/demos/demo.docx',
                             image : ''
                            }]
                },                                                
            ]
        },
        {
            id : 2,
            title : 'The Set of Real Numbers',
            contents :
            [
                {
                    id : 1,
                    type : 'video',
                    title : 'Associative Law of addition',
                    links : []
                },
                {
                    id : 2,
                    type : 'collaborative',
                    title : 'Associative Law of addition',
                    links : []
                },
                {
                    id : 3,
                    type : 'images',
                    title : 'Associative Law of addition',
                    links : []
                },
                {
                    id : 4,
                    type : 'slideshow',
                    title : 'Associative Law of addition',
                    links : []
                },                                                
            ]
        },     
        {
            id : 1,
            title : 'Algebraic Operations',
            contents :
            [
                {
                    id : 1,
                    type : 'video',
                    title : 'Associative Law of addition',
                    links : []
                },
                {
                    id : 2,
                    type : 'collaborative',
                    title : 'Associative Law of addition',
                    links : []
                },
                {
                    id : 3,
                    type : 'images',
                    title : 'Associative Law of addition',
                    links : []
                },
                {
                    id : 4,
                    type : 'slideshow',
                    title : 'Associative Law of addition',
                    links : []
                },                                                
            ]
        },              
    ],  
}