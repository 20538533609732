/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import {
   Row, Col, Empty, Spin
} from 'antd';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { IntroSum } from '../components';

export function Grade7SummaryReport(props) {
   const [reportData, setReportData] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   const [vocabulary, setVocabulary] = useState(null);
   const [literature, setLiterature] = useState(null);
   const [writing, setWriting] = useState(null);
   const [grammar, setGrammar] = useState(null);
   const [viewingComprehension, setViewingComprehension] = useState(null);
   const [englishTotal, setEnglishTotal] = useState(null);

   const [numberSense, setNumberSense] = useState(null);
   const [geometry, setGeometry] = useState(null);
   const [patternsAndAlgebra, setPatternsAndAlgebra] = useState(null);
   const [measurement, setMeasurement] = useState(null);
   const [statisticsAndProbability, setStatisticsAndProbability] = useState(null);
   const [mathTotal, setMathTotal] = useState(null);

   const [matter, setMatter] = useState(null);
   const [livingThings, setLivingThings] = useState(null);
   const [forceAndMotion, setForceAndMotion] = useState(null);
   const [earthAndSpace, setEarthAndSpace] = useState(null);
   const [scienceTotal, setScienceTotal] = useState(null);

   const [englishPCData, setEnglishPCData] = useState(null);
   const [mathPCData, setMathPCData] = useState(null);
   const [sciencePCData, setSciencePCData] = useState(null);
   const [VN, setVN] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();

      getReport(props.assignment_id, props.section_id);
   }, [props.assignment_id]);

   const getReport = (assignment_id, section_id) => {
      setDataLoading(true);

      var url = `${process.env.REACT_APP_API_CPH}/getdiagnosticreportdata?aid=${assignment_id}&sid=${section_id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            //-- English
            if (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') {
               var englishPreData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'pre');
               var englishPostData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'post');
               setVN(englishPreData.length);
            }

            //-- Math
            if (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') {
               var mathPreData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'pre');
               var mathPostData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'post');
               setVN(mathPreData.length);
            }

            //-- Science
            if (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') {
               var sciencePreData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'pre');
               var sciencePostData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'post');
               setVN(sciencePreData.length);
            }

            url = `${process.env.REACT_APP_API_CPH}/getdiagnosticsummarypercentdata?aid=${assignment_id}&sid=${section_id}`;
            axios.get(url).then((response) => {

               if (response.data) {
                  const data2 = response.data;

                  if (props.subject_id === null || props.subject_id === 'ems') {
                     setEnglishPCData(data2[0]);
                     extractDataForEnglish(englishPreData, englishPostData);

                     setMathPCData(data2[1]);
                     extractDataForMath(mathPreData, mathPostData);

                     setSciencePCData(data2[2]);
                     extractDataForScience(sciencePreData, sciencePostData);
                  } else {
                     if (props.subject_id === 'english') {
                        setEnglishPCData(data2[0]);
                        extractDataForEnglish(englishPreData, englishPostData);
                     }

                     if (props.subject_id === 'math') {
                        setMathPCData(data2[0]);
                        extractDataForMath(mathPreData, mathPostData);
                     }

                     if (props.subject_id === 'science') {
                        setSciencePCData(data2[0]);
                        extractDataForScience(sciencePreData, sciencePostData);
                     }
                  }

                  setReportData(data);
                  setDataLoading(false);
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const extractDataForEnglish = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _vocabulary = {};
      var _literature = {};
      var _writing = {};
      var _grammar = {};
      var _viewingComprehension = {};
      var _total = {};
      var _proficiency = '';

      //-- Viewing Comprehension (start)      

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 9.81) / 3.87) > 1)
            _competent++;
         else if (((_sumVal - 9.81) / 3.87) > -0.51)
            _basic++;
         else if (((_sumVal - 9.81) / 3.87) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 9.81) / 3.87) > 1)
                  _competent++;
               else if (((_sumVal - 9.81) / 3.87) > -0.51)
                  _basic++;
               else if (((_sumVal - 9.81) / 3.87) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2 ? 'Beginner' : (_postAve >= 2.01 && _postAve <= 7.77 ? 'Basic' : (_postAve >= 7.78 && _postAve <= 6 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 2 ? 'Beginner' : (_preAve >= 2.01 && _preAve <= 7.77 ? 'Basic' : (_preAve >= 7.78 && _preAve <= 6 ? 'Competent' : ''));
      }

      _viewingComprehension = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Viewing Comprehension (end)

      //-- Vocabulary (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 +
            pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 +
            pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 9.43) / 3.48) > 1)
            _competent++;
         else if (((_sumVal - 9.43) / 3.48) > -0.51)
            _basic++;
         else if (((_sumVal - 9.43) / 3.48) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 +
               post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 +
               post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 9.43) / 3.48) > 1)
                  _competent++;
               else if (((_sumVal - 9.43) / 3.48) > -0.51)
                  _basic++;
               else if (((_sumVal - 9.43) / 3.48) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 7.68 ? 'Beginner' : (_postAve >= 7.69 && _postAve <= 12.9 ? 'Basic' : (_postAve >= 12.91 && _postAve <= 24 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 7.68 ? 'Beginner' : (_preAve >= 7.69 && _preAve <= 12.9 ? 'Basic' : (_preAve >= 12.91 && _preAve <= 24 ? 'Competent' : ''));
      }

      _vocabulary = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Vocabulary (end)      

      //-- Literature (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.06) / 1.48) > 1)
            _competent++;
         else if (((_sumVal - 2.06) / 1.48) > -0.51)
            _basic++;
         else if (((_sumVal - 2.06) / 1.48) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34 + post_data[i].s35 + post_data[i].s36;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.06) / 1.48) > 1)
                  _competent++;
               else if (((_sumVal - 2.06) / 1.48) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.06) / 1.48) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.31 ? 'Beginner' : (_postAve >= 1.32 && _postAve <= 3.54 ? 'Basic' : (_postAve >= 3.55 && _postAve <= 6 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.31 ? 'Beginner' : (_preAve >= 1.32 && _preAve <= 3.54 ? 'Basic' : (_preAve >= 3.55 && _preAve <= 6 ? 'Competent' : ''));
      }

      _literature = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Literature (end)

      //-- Writing (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 3.69) / 0.79) > 1)
            _competent++;
         else if (((_sumVal - 3.69) / 0.79) > -0.51)
            _basic++;
         else if (((_sumVal - 3.69) / 0.79) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40 + post_data[i].s41 + post_data[i].s42 + post_data[i].s43;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 3.69) / 0.79) > 1)
                  _competent++;
               else if (((_sumVal - 3.69) / 0.79) > -0.51)
                  _basic++;
               else if (((_sumVal - 3.69) / 0.79) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.29 ? 'Beginner' : (_postAve >= 3.30 && _postAve <= 4.48 ? 'Basic' : (_postAve >= 4.49 && _postAve <= 7 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.29 ? 'Beginner' : (_preAve >= 3.30 && _preAve <= 4.48 ? 'Basic' : (_preAve >= 4.49 && _preAve <= 7 ? 'Competent' : ''));
      }

      _writing = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Writing (end)

      //-- Grammar (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s44 + pre_data[i].s45 + pre_data[i].s46 + pre_data[i].s47 +
            pre_data[i].s48 + pre_data[i].s49 + pre_data[i].s50 + pre_data[i].s51 + pre_data[i].s52 + pre_data[i].s53 +
            pre_data[i].s54 + pre_data[i].s55 + pre_data[i].s56 + pre_data[i].s57 + pre_data[i].s58 + pre_data[i].s59 + pre_data[i].s60;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 9.81) / 3.87) > 1)
            _competent++;
         else if (((_sumVal - 9.81) / 3.87) > -0.51)
            _basic++;
         else if (((_sumVal - 9.81) / 3.87) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s44 + post_data[i].s45 + post_data[i].s46 + post_data[i].s47 +
               post_data[i].s48 + post_data[i].s49 + post_data[i].s50 + post_data[i].s51 + post_data[i].s52 + post_data[i].s53 +
               post_data[i].s54 + post_data[i].s55 + post_data[i].s56 + post_data[i].s57 + post_data[i].s58 + post_data[i].s59 + post_data[i].s60;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 9.81) / 3.87) > 1)
                  _competent++;
               else if (((_sumVal - 9.81) / 3.87) > -0.51)
                  _basic++;
               else if (((_sumVal - 9.81) / 3.87) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 7.87 ? 'Beginner' : (_postAve >= 7.88 && _postAve <= 13.7 ? 'Basic' : (_postAve >= 13.71 && _postAve <= 17 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 7.87 ? 'Beginner' : (_preAve >= 7.88 && _preAve <= 13.7 ? 'Basic' : (_preAve >= 13.71 && _preAve <= 17 ? 'Competent' : ''));
      }

      _grammar = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Grammar (end)      

      _total = {
         vn: pre_data.length,
         prem: _vocabulary.prem + _literature.prem + _writing.prem + _grammar.prem + _viewingComprehension.prem,
         postm: _vocabulary.postm + _literature.postm + _writing.postm + _grammar.postm + _viewingComprehension.postm,
         min: _vocabulary.min + _literature.min + _writing.min + _grammar.min + _viewingComprehension.min,
         max: _vocabulary.max + _literature.max + _writing.max + _grammar.max + _viewingComprehension.max,
         sd: _vocabulary.sd + _literature.sd + _writing.sd + _grammar.sd + _viewingComprehension.sd,
         proficiency: _proficiency,
         beginner: _vocabulary.beginner + _literature.beginner + _writing.beginner + _grammar.beginner + _viewingComprehension.beginner,
         basic: _vocabulary.basic + _literature.basic + _writing.basic + _grammar.basic + _viewingComprehension.basic,
         competent: _vocabulary.competent + _literature.competent + _writing.competent + _grammar.competent + _viewingComprehension.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 22.7 ? 'Beginner' : (_total.postm >= 22.71 && _total.postm <= 35.5 ? 'Basic' : (_total.postm >= 35.51 && _total.postm <= 60 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 22.7 ? 'Beginner' : (_total.prem >= 22.71 && _total.prem <= 35.5 ? 'Basic' : (_total.prem >= 35.51 && _total.prem <= 60 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setEnglishTotal(_total);
      setViewingComprehension(_viewingComprehension);
      setVocabulary(_vocabulary);
      setLiterature(_literature);
      setWriting(_writing);
      setGrammar(_grammar);
   }

   const extractDataForMath = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _numbersense = {};
      var _geometry = {};
      var _algebra = {};
      var _measurement = {};
      var _statistics = {};
      var _proficiency = '';
      var _total = 0;

      //-- Number Sense (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 +
            pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 8.81) / 2.26) > 1)
            _competent++;
         else if (((_sumVal - 8.81) / 2.26) > -0.51)
            _basic++;
         else if (((_sumVal - 8.81) / 2.26) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 +
               post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 8.81) / 2.26) > 1)
                  _competent++;
               else if (((_sumVal - 8.81) / 2.26) > -0.51)
                  _basic++;
               else if (((_sumVal - 8.81) / 2.26) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 7.67 ? 'Beginner' : (_postAve >= 7.68 && _postAve <= 11.1 ? 'Basic' : (_postAve >= 11.12 && _postAve <= 19 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 7.67 ? 'Beginner' : (_preAve >= 7.68 && _preAve <= 11.1 ? 'Basic' : (_preAve >= 11.12 && _preAve <= 19 ? 'Competent' : ''));
      }

      _numbersense = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Number Sense (end)

      //-- Measurement (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.19) / 1.11) > 1)
            _competent++;
         else if (((_sumVal - 2.19) / 1.11) > -0.51)
            _basic++;
         else if (((_sumVal - 2.19) / 1.11) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.19) / 1.11) > 1)
                  _competent++;
               else if (((_sumVal - 2.19) / 1.11) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.19) / 1.11) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.63 ? 'Beginner' : (_postAve >= 1.64 && _postAve <= 3.3 ? 'Basic' : (_postAve >= 3.31 && _postAve <= 5 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.63 ? 'Beginner' : (_preAve >= 1.64 && _preAve <= 3.3 ? 'Basic' : (_preAve >= 3.31 && _preAve <= 5 ? 'Competent' : ''));
      }

      _measurement = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Measurement (end)

      //-- Patterns and Algebra (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s25 + pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 +
            pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 +
            pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 + pre_data[i].s45 + pre_data[i].s46;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 11.19) / 1.11) > 1)
            _competent++;
         else if (((_sumVal - 11.19) / 1.11) > -0.51)
            _basic++;
         else if (((_sumVal - 11.19) / 1.11) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s25 + post_data[i].s26 + post_data[i].s27 + post_data[i].s28 + post_data[i].s29 +
               post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34 + post_data[i].s35 + post_data[i].s36 +
               post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40 + post_data[i].s41 + post_data[i].s42 + post_data[i].s43 + post_data[i].s44 + post_data[i].s45 + post_data[i].s46;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 11.19) / 1.11) > 1)
                  _competent++;
               else if (((_sumVal - 11.19) / 1.11) > -0.51)
                  _basic++;
               else if (((_sumVal - 11.19) / 1.11) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 10.6 ? 'Beginner' : (_postAve >= 10.61 && _postAve <= 12.3 ? 'Basic' : (_postAve >= 12.31 && _postAve <= 22 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 10.6 ? 'Beginner' : (_preAve >= 10.61 && _preAve <= 12.3 ? 'Basic' : (_preAve >= 12.31 && _preAve <= 22 ? 'Competent' : ''));
      }

      _algebra = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Patterns and Algebra (end)

      //-- Geometry (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s47 + pre_data[i].s48 + pre_data[i].s49 + pre_data[i].s50 + pre_data[i].s51 + pre_data[i].s52 + pre_data[i].s53 + pre_data[i].s54 + pre_data[i].s55 + pre_data[i].s56;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 6.88) / 0.81) > 1)
            _competent++;
         else if (((_sumVal - 6.88) / 0.81) > -0.51)
            _basic++;
         else if (((_sumVal - 6.88) / 0.81) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s47 + post_data[i].s48 + post_data[i].s49 + post_data[i].s50 + post_data[i].s51 + post_data[i].s52 + post_data[i].s53 + post_data[i].s54 + post_data[i].s55 + post_data[i].s56;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 6.88) / 0.81) > 1)
                  _competent++;
               else if (((_sumVal - 6.88) / 0.81) > -0.51)
                  _basic++;
               else if (((_sumVal - 6.88) / 0.81) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 6.47 ? 'Beginner' : (_postAve >= 6.48 && _postAve <= 7.69 ? 'Basic' : (_postAve >= 7.70 && _postAve <= 10 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 6.47 ? 'Beginner' : (_preAve >= 6.48 && _preAve <= 7.69 ? 'Basic' : (_preAve >= 7.70 && _preAve <= 10 ? 'Competent' : ''));
      }

      _geometry = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Geometry (end)

      //-- Statistics and Probability (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s57 + pre_data[i].s58 + pre_data[i].s59 + pre_data[i].s60;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.25) / 0.45) > 1)
            _competent++;
         else if (((_sumVal - 2.25) / 0.45) > -0.51)
            _basic++;
         else if (((_sumVal - 2.25) / 0.45) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s57 + post_data[i].s58 + post_data[i].s59 + post_data[i].s60;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.25) / 0.45) > 1)
                  _competent++;
               else if (((_sumVal - 2.25) / 0.45) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.25) / 0.45) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2.02 ? 'Beginner' : (_postAve >= 2.03 && _postAve <= 2.7 ? 'Basic' : (_postAve >= 2.71 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _postAve >= 0 && _preAve <= 2.02 ? 'Beginner' : (_preAve >= 2.03 && _preAve <= 2.7 ? 'Basic' : (_preAve >= 2.71 && _preAve <= 4 ? 'Competent' : ''));
      }

      _statistics = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Statistics and Probability (end)

      _total = {
         vn: pre_data.length,
         prem: _numbersense.prem + _geometry.prem + _algebra.prem + _measurement.prem + _statistics.prem,
         postm: _numbersense.postm + _geometry.postm + _algebra.postm + _measurement.postm + _statistics.postm,
         min: _numbersense.min + _geometry.min + _algebra.min + _measurement.min + _statistics.min,
         max: _numbersense.max + _geometry.max + _algebra.max + _measurement.max + _statistics.max,
         sd: _numbersense.sd + _geometry.sd + _algebra.sd + _measurement.sd + _statistics.sd,
         proficiency: _proficiency,
         beginner: _numbersense.beginner + _geometry.beginner + _algebra.beginner + _measurement.beginner + _statistics.beginner,
         basic: _numbersense.basic + _geometry.basic + _algebra.basic + _measurement.basic + _statistics.basic,
         competent: _numbersense.competent + _geometry.competent + _algebra.competent + _measurement.competent + _statistics.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 31.2 ? 'Beginner' : (_total.postm >= 31.21 && _total.postm <= 37.5 ? 'Basic' : (_total.postm >= 37.51 && _total.postm <= 60 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 31.2 ? 'Beginner' : (_total.prem >= 31.21 && _total.prem <= 37.5 ? 'Basic' : (_total.prem >= 37.51 && _total.prem <= 60 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setMathTotal(_total);
      setNumberSense(_numbersense);
      setGeometry(_geometry);
      setPatternsAndAlgebra(_algebra);
      setMeasurement(_measurement);
      setStatisticsAndProbability(_statistics);
   }

   const extractDataForScience = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _matter = {};
      var _livingthings = {};
      var _forceandmotion = {};
      var _earthandspace = {};
      var _proficiency = '';
      var _total = 0;

      //-- Matter (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 +
            pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 7.3) / 2) > 1)
            _competent++;
         else if (((_sumVal - 7.3) / 2) > -0.51)
            _basic++;
         else if (((_sumVal - 7.3) / 2) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 +
               post_data[i].s8 + post_data[i].s9 + post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 7.3) / 2) > 1)
                  _competent++;
               else if (((_sumVal - 7.3) / 2) > -0.51)
                  _basic++;
               else if (((_sumVal - 7.3) / 2) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 6.12 ? 'Beginner' : (_postAve >= 6.13 && _postAve <= 9.13 ? 'Basic' : (_postAve >= 9.14 && _postAve <= 13 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 6.12 ? 'Beginner' : (_preAve >= 6.13 && _preAve <= 9.13 ? 'Basic' : (_preAve >= 9.14 && _preAve <= 13 ? 'Competent' : ''));
      }

      _matter = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Matter (end)

      //-- Living Things and their Environment (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 +
            pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 +
            pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 +
            pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 11.31) / 2.33) > 1)
            _competent++;
         else if (((_sumVal - 11.31) / 2.33) > -0.51)
            _basic++;
         else if (((_sumVal - 11.31) / 2.33) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 +
               post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 +
               post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 +
               post_data[i].s34 + post_data[i].s35 + post_data[i].s36 + post_data[i].s37 + post_data[i].s38;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 11.31) / 2.33) > 1)
                  _competent++;
               else if (((_sumVal - 11.31) / 2.33) > -0.51)
                  _basic++;
               else if (((_sumVal - 11.31) / 2.33) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 10.1 ? 'Beginner' : (_postAve >= 10.11 && _postAve <= 13.6 ? 'Basic' : (_postAve >= 13.61 && _postAve <= 25 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 10.1 ? 'Beginner' : (_preAve >= 10.11 && _preAve <= 13.6 ? 'Basic' : (_preAve >= 13.61 && _preAve <= 25 ? 'Competent' : ''));
      }

      _livingthings = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Living Things and their Environment (end)

      //-- Force and Motion (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 + pre_data[i].s45 + pre_data[i].s46 +
            pre_data[i].s47 + pre_data[i].s48 + pre_data[i].s49 + pre_data[i].s50 + pre_data[i].s51 + pre_data[i].s52 + pre_data[i].s53 + pre_data[i].s54 + pre_data[i].s55 +
            pre_data[i].s56 + pre_data[i].s57 + pre_data[i].s58 + pre_data[i].s59 + pre_data[i].s60 + pre_data[i].s61 + pre_data[i].s62;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 12.5) / 1.63) > 1)
            _competent++;
         else if (((_sumVal - 12.5) / 1.63) > -0.51)
            _basic++;
         else if (((_sumVal - 12.5) / 1.63) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s39 + post_data[i].s40 + post_data[i].s41 + post_data[i].s42 + post_data[i].s43 + post_data[i].s44 + post_data[i].s45 + post_data[i].s46 +
               post_data[i].s47 + post_data[i].s48 + post_data[i].s49 + post_data[i].s50 + post_data[i].s51 + post_data[i].s52 + post_data[i].s53 + post_data[i].s54 + post_data[i].s55 +
               post_data[i].s56 + post_data[i].s57 + post_data[i].s58 + post_data[i].s59 + post_data[i].s60 + post_data[i].s61 + post_data[i].s62;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 12.5) / 1.63) > 1)
                  _competent++;
               else if (((_sumVal - 12.5) / 1.63) > -0.51)
                  _basic++;
               else if (((_sumVal - 12.5) / 1.63) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 11.7 ? 'Beginner' : (_postAve >= 11.71 && _postAve <= 14.1 ? 'Basic' : (_postAve >= 14.11 && _postAve <= 24 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 11.7 ? 'Beginner' : (_preAve >= 11.71 && _preAve <= 14.1 ? 'Basic' : (_preAve >= 14.11 && _preAve <= 24 ? 'Competent' : ''));
      }

      _forceandmotion = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Force and Motion (end)

      //-- Earth and Space (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s63 + pre_data[i].s64 + pre_data[i].s65 + pre_data[i].s66 + pre_data[i].s67 + pre_data[i].s68 + pre_data[i].s69 + pre_data[i].s70;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4.56) / 1.5) > 1)
            _competent++;
         else if (((_sumVal - 4.56) / 1.5) > -0.51)
            _basic++;
         else if (((_sumVal - 4.56) / 1.5) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s63 + post_data[i].s64 + post_data[i].s65 + post_data[i].s66 + post_data[i].s67 + post_data[i].s68 + post_data[i].s69 + post_data[i].s70;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4.56) / 1.5) > 1)
                  _competent++;
               else if (((_sumVal - 4.56) / 1.5) > -0.51)
                  _basic++;
               else if (((_sumVal - 4.56) / 1.5) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 3.8 ? 'Beginner' : (_postAve >= 3.81 && _postAve <= 6.06 ? 'Basic' : (_postAve >= 6.07 && _postAve <= 8 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 3.8 ? 'Beginner' : (_preAve >= 3.81 && _preAve <= 6.06 ? 'Basic' : (_preAve >= 6.07 && _preAve <= 8 ? 'Competent' : ''));
      }

      _earthandspace = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Earth and Space (end)

      _total = {
         vn: pre_data.length,
         prem: _matter.prem + _livingthings.prem + _forceandmotion.prem + _earthandspace.prem,
         postm: _matter.postm + _livingthings.postm + _forceandmotion.postm + _earthandspace.postm,
         min: _matter.min + _livingthings.min + _forceandmotion.min + _earthandspace.min,
         max: _matter.max + _livingthings.max + _forceandmotion.max + _earthandspace.max,
         sd: _matter.sd + _livingthings.sd + _forceandmotion.sd + _earthandspace.sd,
         proficiency: _proficiency,
         beginner: _matter.beginner + _livingthings.beginner + _forceandmotion.beginner + _earthandspace.beginner,
         basic: _matter.basic + _livingthings.basic + _forceandmotion.basic + _earthandspace.basic,
         competent: _matter.competent + _livingthings.competent + _forceandmotion.competent + _earthandspace.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 28.3 ? 'Beginner' : (_total.postm >= 28.31 && _total.postm <= 34.9 ? 'Basic' : (_total.postm >= 34.91 && _total.postm <= 70 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 28.3 ? 'Beginner' : (_total.prem >= 28.31 && _total.prem <= 34.9 ? 'Basic' : (_total.prem >= 34.91 && _total.prem <= 70 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setScienceTotal(_total);
      setMatter(_matter);
      setLivingThings(_livingthings);
      setForceAndMotion(_forceandmotion);
      setEarthAndSpace(_earthandspace);
   }

   const generateMeanLevelRow = (label, data, istotal) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               {
                  istotal
                     ?
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
                     :
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; {label}</p>
               }

            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.vn}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{(data.prem).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.postm < 0 ? '' : (data.postm).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.min}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.max}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{(data.sd).toFixed(2)}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.proficiency}</p>
            </td>
         </tr>
      );
   }

   const generateFrequencyRow = (label, data, index) => {
      return (
         <>
            <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>{index}. Frequency of Proficiency Levels in {label}</strong></p>
            <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
               <tbody>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Count</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent</span></strong></p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Beginner</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.beginner}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'beginner')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Basic&nbsp;</strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.basic}</p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'basic')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Competent&nbsp;</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.competent}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'competent')}</p>
                     </td>
                  </tr>
               </tbody>
            </table>
         </>
      );
   }

   const generatePercentCorrectRow = (label, predata, postdata, index) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>{index}</strong></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>{label}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{predata !== null ? predata.toFixed(2) + '%' : ''}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}>{postdata !== null ? postdata.toFixed(2) + '%' : ''}</p>
            </td>
         </tr>
      );
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               (reportData !== null && reportData.length > 0)
                  ?
                  <div className="dashboard-item-inner3" style={{ padding: '7px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='summary-report'>
                        <div>

                           <IntroSum
                              grade_level={props.template}
                              section={props.section_desc}
                              school_name={reportData[0].school_name}
                              subject_id={props.subject_id}
                              subject={props.subject}
                              backgroundColor="#000000"
                              diagnostic_date={reportData[0].diagnostic_date}
                              diagnostic_type={reportData[0].diagnostic_type}
                           />

                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>SUMMARY OF RESULTS FOR {props.template}</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>MEAN LEVEL OF SCORES IN THE SUBJECT AREAS</strong></p>
                           <table border={1} cellSpacing={0} cellPadding={0} width="101%" style={{ width: '101.7%', borderCollapse: 'collapse', border: 'none' }}>
                              <tbody>
                                 <tr>
                                    <td width="29%" nowrap style={{ width: '29.9%', borderTop: 'solid black 1.0pt', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }} />
                                    <td width="5%" nowrap style={{ width: '5.4%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>N</span></b></p>
                                    </td>
                                    <td width="11%" nowrap style={{ width: '11.12%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Pretest Mean</span></b></p>
                                    </td>
                                    <td width="11%" style={{ width: '11.48%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Post Test Mean</span></b></p>
                                    </td>
                                    <td width="6%" nowrap style={{ width: '6.88%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>MIN</span></b></p>
                                    </td>
                                    <td width="7%" nowrap style={{ width: '7.54%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>MAX</span></b></p>
                                    </td>
                                    <td width="5%" nowrap style={{ width: '5.02%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>SD</span></b></p>
                                    </td>
                                    <td width="22%" style={{ width: '22.68%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Proficiency Level</span></b></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Viewing Comprehension', viewingComprehension, false)}
                                       {generateMeanLevelRow('Vocabulary', vocabulary, false)}
                                       {generateMeanLevelRow('Literature', literature, false)}
                                       {generateMeanLevelRow('Writing', writing, false)}
                                       {generateMeanLevelRow('Grammar', grammar, false)}
                                       {generateMeanLevelRow('ENGLISH', englishTotal, true)}
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Number Sense', numberSense, false)}
                                       {generateMeanLevelRow('Measurement', measurement, false)}
                                       {generateMeanLevelRow('Algebra', patternsAndAlgebra, false)}
                                       {generateMeanLevelRow('Geometry', geometry, false)}
                                       {generateMeanLevelRow('Statistics and Probability', statisticsAndProbability, false)}
                                       {generateMeanLevelRow('MATH', mathTotal, true)}
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Matter', matter, false)}
                                       {generateMeanLevelRow('Living Things and their Environment', livingThings, false)}
                                       {generateMeanLevelRow('Force and Motion', forceAndMotion, false)}
                                       {generateMeanLevelRow('Earth and Space', earthAndSpace, false)}
                                       {generateMeanLevelRow('SCIENCE', scienceTotal, true)}
                                    </>
                                 }
                              </tbody>
                           </table>
                           <p style={{ marginBottom: '0in', textAlign: 'justify' }}><b><span lang="EN-US" style={{ fontFamily: '"Tw Cen MT",sans-serif' }}>&nbsp;</span></b></p>
                           <p style={{ marginBottom: '0in', textAlign: 'justify' }}><a name="_Hlk139734130"><b><span lang="EN-US">INTERPRETATION</span></b></a></p>
                           <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                              <tbody>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td width="34%" nowrap style={{ width: '34.78%', borderTop: 'solid black 1.0pt', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="16%" nowrap colSpan={3} style={{ width: '16.86%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>BEGINNER</span></b></p>
                                    </td>
                                    <td width="3%" nowrap style={{ width: '3.08%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="22%" nowrap colSpan={3} style={{ width: '22.26%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>BASIC</span></b></p>
                                    </td>
                                    <td width="3%" nowrap style={{ width: '3.08%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="19%" nowrap colSpan={3} style={{ width: '19.96%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>COMPETENT</span></b></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Viewing Comprehension</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.01</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.77</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.78</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Vocabulary</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.68</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.69</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>12.9</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>12.92</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>24</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Literature</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>1.31</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>1.32</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.54</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.55</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Writing</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.29</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.3</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>4.48</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>4.49</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Grammar</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.87</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.88</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>13.7</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>13.69</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>17</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>ENGLISH</span></b></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>22.7</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>22.71</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>35.5</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>35.46</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>60</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="100%" nowrap colSpan={12} style={{ width: '100.0%', border: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Number Sense</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.67</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.68</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>11.1</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>11.08</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>19</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Measurement</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>1.63</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>1.64</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.3</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.31</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>5</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Algebra</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>10.6</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>10.61</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>12.3</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>12.31</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>22</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Geometry</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.47</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.48</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.69</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.7</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>10</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Statistics and Probability</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.02</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.03</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.7</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.71</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>4</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>MATH</span></b></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>31.2</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>31.21</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>37.5</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>37.51</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>60</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="100%" nowrap colSpan={12} style={{ width: '100.0%', borderTop: 'none', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Matter</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.12</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.13</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>9.13</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>9.14</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>13</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Living Things</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>10.1</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>10.11</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>13.6</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>13.65</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>25</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Force and Motion</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>11.7</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>11.71</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>14.1</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>14.14</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>24</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Earth and Space</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.8</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.81</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.06</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.07</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>8</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.78%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>SCIENCE</span></b></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.54%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>28.3</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>28.31</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.4%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>34.9</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.08%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.94%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>34.94</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.08%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>70</span></p>
                                          </td>
                                       </tr>
                                    </>
                                 }
                              </tbody>
                           </table>

                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp;</span></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US" style={{ color: 'black' }}>&nbsp;</span></b></p>
                                 {generateFrequencyRow('Viewing Comprehension', viewingComprehension, 1)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Vocabulary', vocabulary, 2)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Literature', literature, 3)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Writing', writing, 4)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Grammar', grammar, 5)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('ENGLISH', englishTotal, 6)}
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Number Sense', numberSense, 7)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Geometry', geometry, 8)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Patterns and Algebra', patternsAndAlgebra, 9)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Measurement', measurement, 10)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Statistics and Probability', statisticsAndProbability, 11)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('MATH', mathTotal, 12)}
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Matter', matter, 13)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Living Things and their Environment', livingThings, 14)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Force and Motion', forceAndMotion, 15)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Earth and Space', earthAndSpace, 16)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('SCIENCE', scienceTotal, 17)}
                              </>
                           }

                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percent Correct in English</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody><tr>
                                       <td width="63%" colSpan={2} rowSpan={2} style={{ width: '63.12%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                       </td>
                                       <td width="18%" style={{ width: '18.66%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>PRETEST</span></b></p>
                                       </td>
                                       <td width="18%" style={{ width: '18.22%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>POST TEST</span></b></p>
                                       </td>
                                    </tr>
                                       <tr>
                                          <td width="18%" style={{ width: '18.66%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.22%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.52%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Viewing Comprehension</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.66%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.22%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Give the meaning of given signs and symbols (road signs, prohibited signs, etc.).', englishPCData.pre_s1, englishPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Give the meaning of given signs and symbols (road signs, prohibited signs, etc.).', englishPCData.pre_s2, englishPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Give the meaning of given signs and symbols (road signs, prohibited signs, etc.).', englishPCData.pre_s3, englishPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Predict the gist of the material viewed based on the title, pictures, and excerpts of the material viewed.', englishPCData.pre_s4, englishPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Raise questions about a material viewed.', englishPCData.pre_s5, englishPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Determine the key message conveyed in the material viewed.', englishPCData.pre_s6, englishPCData.post_s6, 6)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.52%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Vocabulary Development</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.66%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.22%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Distinguish between slang and colloquial expressions in conversations.', englishPCData.pre_s7, englishPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Distinguish between slang and colloquial expressions in conversations.', englishPCData.pre_s8, englishPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Distinguish between slang and colloquial expressions in conversations.', englishPCData.pre_s9, englishPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Distinguish between slang and colloquial expressions in conversations.', englishPCData.pre_s10, englishPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Use appropriate idiomatic expressions in a variety of basic interpersonal communicative situations.', englishPCData.pre_s11, englishPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Use appropriate idiomatic expressions in a variety of basic interpersonal communicative situations.', englishPCData.pre_s12, englishPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Use appropriate idiomatic expressions in a variety of basic interpersonal communicative situations.', englishPCData.pre_s13, englishPCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Select an appropriate colloquial or idiomatic word or expression as a substitute for another word or expression.', englishPCData.pre_s14, englishPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Select an appropriate colloquial or idiomatic word or expression as a substitute for another word or expression.', englishPCData.pre_s15, englishPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Discriminate between literal and figurative language.', englishPCData.pre_s16, englishPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Discriminate between literal and figurative language.', englishPCData.pre_s17, englishPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Identify figures of speech that show comparison (simile, metaphor, personification, (irony, oxymoron, paradox ).', englishPCData.pre_s18, englishPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Identify figures of speech that show comparison (simile, metaphor, personification, (irony, oxymoron, paradox ).', englishPCData.pre_s19, englishPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Identify figures of speech that show comparison (simile, metaphor, personification, (irony, oxymoron, paradox ).', englishPCData.pre_s20, englishPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Identify collocations used in a selection.', englishPCData.pre_s21, englishPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Identify collocations used in a selection.', englishPCData.pre_s22, englishPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Identify words or expressions with part-whole (partitive) relations.', englishPCData.pre_s23, englishPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Identify words or expressions with part-whole (partitive) relations.', englishPCData.pre_s24, englishPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Use lexical and contextual cues in understanding unfamiliar words and expressions.', englishPCData.pre_s25, englishPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Use lexical and contextual cues in understanding unfamiliar words and expressions.', englishPCData.pre_s26, englishPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Give the various meanings of identified homonymous or polysemous words or expressions.', englishPCData.pre_s27, englishPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Give the various meanings of identified homonymous or polysemous words or expressions.', englishPCData.pre_s28, englishPCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Create or expand word clines.', englishPCData.pre_s29, englishPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Create or expand word clines.', englishPCData.pre_s30, englishPCData.post_s30, 30)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.52%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Literature</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.66%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.22%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Identify the distinguishing features of proverbs, myths, and legends.', englishPCData.pre_s31, englishPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Explain the literary devices used.', englishPCData.pre_s32, englishPCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Determine the tone, mood, technique, and purpose of the author.', englishPCData.pre_s33, englishPCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Determine the tone, mood, technique, and purpose of the author.', englishPCData.pre_s34, englishPCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Determine the tone, mood, technique, and purpose of the author.', englishPCData.pre_s35, englishPCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Discover the conflicts presented in literary selections and the need to resolve those conflicts in non-violent ways.', englishPCData.pre_s36, englishPCData.post_s36, 36)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.52%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Writing and Composition</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.66%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.22%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Recognize the common purposes for writing.', englishPCData.pre_s37, englishPCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Identify key ideas.', englishPCData.pre_s38, englishPCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Identify supporting details.', englishPCData.pre_s39, englishPCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Recognize the parts of a simple paragraph.', englishPCData.pre_s40, englishPCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Recognize the parts of a simple paragraph.', englishPCData.pre_s41, englishPCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Recognize the parts of a simple paragraph.', englishPCData.pre_s42, englishPCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Identify basic features and kinds of paragraph.', englishPCData.pre_s43, englishPCData.post_s43, 43)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="58%" style={{ width: '58.52%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Grammar Awareness</b></p>
                                          </td>
                                          <td width="18%" style={{ width: '18.66%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="18%" style={{ width: '18.22%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Observe correct subject-verb agreement.', englishPCData.pre_s44, englishPCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Observe correct subject-verb agreement.', englishPCData.pre_s45, englishPCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Observe correct subject-verb agreement.', englishPCData.pre_s46, englishPCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Observe correct subject-verb agreement.', englishPCData.pre_s47, englishPCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Use phrases, clauses, and sentences appropriately and meaningfully.', englishPCData.pre_s48, englishPCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Use phrases, clauses, and sentences appropriately and meaningfully.', englishPCData.pre_s49, englishPCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Link sentences using logical connectors that signal chronological and logical sequence and summation.', englishPCData.pre_s50, englishPCData.post_s50, 50)}
                                       {generatePercentCorrectRow('Use the passive and active voice meaningfully in varied contexts.', englishPCData.pre_s51, englishPCData.post_s51, 51)}
                                       {generatePercentCorrectRow('Use the passive and active voice meaningfully in varied contexts.', englishPCData.pre_s52, englishPCData.post_s52, 52)}
                                       {generatePercentCorrectRow('Use the passive and active voice meaningfully in varied contexts.', englishPCData.pre_s53, englishPCData.post_s53, 53)}
                                       {generatePercentCorrectRow('Use the passive and active voice meaningfully in varied contexts.', englishPCData.pre_s54, englishPCData.post_s54, 54)}
                                       {generatePercentCorrectRow('Use imperatives and prepositions when giving instructions. Use imperatives and prepositions when giving instructions.', englishPCData.pre_s55, englishPCData.post_s55, 55)}
                                       {generatePercentCorrectRow('Use imperatives and prepositions when giving instructions.', englishPCData.pre_s56, englishPCData.post_s56, 56)}
                                       {generatePercentCorrectRow('Use imperatives and prepositions when giving instructions.', englishPCData.pre_s57, englishPCData.post_s57, 57)}
                                       {generatePercentCorrectRow('Use verbs when giving information and making explanations.', englishPCData.pre_s58, englishPCData.post_s58, 58)}
                                       {generatePercentCorrectRow('Formulate who, what, when, where, why, and how questions.', englishPCData.pre_s59, englishPCData.post_s59, 59)}
                                       {generatePercentCorrectRow('Formulate who, what, when, where, why, and how questions.', englishPCData.pre_s60, englishPCData.post_s60, 60)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percent Correct in Math</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody>
                                       <tr>
                                          <td width="71%" colSpan={2} rowSpan={2} style={{ width: '71.18%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.72%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>PRETEST</span></b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.1%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>POST TEST</span></b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="14%" style={{ width: '14.72%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.1%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                                          </td>
                                          <td width="66%" style={{ width: '66.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Number Sense</b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Number Sense: Illustrates the union and intersection of sets and the difference of two sets.', mathPCData.pre_s1, mathPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Number Sense: Illustrates the union and intersection of sets and the difference of two sets.', mathPCData.pre_s2, mathPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Number Sense: solves problems involving sets. ', mathPCData.pre_s3, mathPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Number Sense: solves problems involving sets. ', mathPCData.pre_s4, mathPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Number Sense:  Represents the absolute value of a number on a number line as the distance of a number from 0.', mathPCData.pre_s5, mathPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Number Sense:  Represents the absolute value of a number on a number line as the distance of a number from 0.', mathPCData.pre_s6, mathPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Number Sense: Performs fundamental operations on integers', mathPCData.pre_s7, mathPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Number Sense: Performs fundamental operations on integers', mathPCData.pre_s8, mathPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Expresses rational numbers from fraction form to decimal form and vice versa.', mathPCData.pre_s9, mathPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Number Sense: Expresses rational numbers from fraction form to decimal form and vice versa', mathPCData.pre_s10, mathPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Number Sense: Performs operations on rational numbers', mathPCData.pre_s11, mathPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Number Sense: Performs operations on rational numbers', mathPCData.pre_s12, mathPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Number Sense: Estimates the square root of a whole number to the nearest hundredth', mathPCData.pre_s13, mathPCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Number Sense: Estimates the square root of a whole number to the nearest hundredth', mathPCData.pre_s14, mathPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Plots irrational numbers (up to square roots) on a number line.', mathPCData.pre_s15, mathPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Number Sense: Determines between what two integers the square root of a number is.', mathPCData.pre_s16, mathPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Number Sense: Writes numbers in scientific notation and vice versa.', mathPCData.pre_s17, mathPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Number Sense: Writes numbers in scientific notation and vice versa.', mathPCData.pre_s18, mathPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Solves problems involving real numbers.', mathPCData.pre_s19, mathPCData.post_s19, 19)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                                          </td>
                                          <td width="66%" style={{ width: '66.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Measurement</b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Measurement: Solves problems involving conversion of units of measurement', mathPCData.pre_s20, mathPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Measurement: Approximates the measures of quantities particularly length , weight/mass, volume, time, angle and temperature and rate.', mathPCData.pre_s21, mathPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Measurement: Converts measurements from one unit to another in both Metric and English systems. ', mathPCData.pre_s22, mathPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Measurement: Converts measurements from one unit to another in both Metric and English systems. ', mathPCData.pre_s23, mathPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Measurement: Converts measurements from one unit to another in both Metric and English systems. ', mathPCData.pre_s24, mathPCData.post_s24, 24)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                                          </td>
                                          <td width="66%" style={{ width: '66.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Patterns and Algebra</b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Patterns and Algebra: Translates English phrases to mathematical phrases and vice versa', mathPCData.pre_s25, mathPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Translates English phrases to mathematical phrases and vice versa', mathPCData.pre_s26, mathPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Finds the solution of linear equation or inequality in one variable', mathPCData.pre_s27, mathPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Finds the solution of linear equation or inequality in one variable. ', mathPCData.pre_s28, mathPCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Evaluates algebraic expressions for given values of the variables.', mathPCData.pre_s29, mathPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Evaluates algebraic expressions for given values of the variables.', mathPCData.pre_s30, mathPCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Classifies algebraic expressions which are polynomials according to degree and number of terms.', mathPCData.pre_s31, mathPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Classifies algebraic expressions which are polynomials according to degree and number of terms.', mathPCData.pre_s32, mathPCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Adds and subtracts polynomials.', mathPCData.pre_s33, mathPCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Adds and subtracts polynomials.', mathPCData.pre_s34, mathPCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Multiplies polynomials.', mathPCData.pre_s35, mathPCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Divides polynomials.', mathPCData.pre_s36, mathPCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Uses models and algebraic methods to find the: (a) product of two binomials', mathPCData.pre_s37, mathPCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Uses models and algebraic methods to find the: (a) product of two binomials', mathPCData.pre_s38, mathPCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Uses models and algebraic methods to find the: (b) product of the sum and difference of two terms', mathPCData.pre_s39, mathPCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Uses models and algebraic methods to find the (c) square of a binomial', mathPCData.pre_s40, mathPCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Uses models and algebraic methods to find the (c) square of a binomial', mathPCData.pre_s41, mathPCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Multiplies polynomials', mathPCData.pre_s42, mathPCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Translates English sentences to mathematical sentences and vice versa.', mathPCData.pre_s43, mathPCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Finds the solution of linear equation or inequality in one variable.', mathPCData.pre_s44, mathPCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Finds the solution of linear equation or inequality in one variable.', mathPCData.pre_s45, mathPCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Patterns and Algebra: Finds the solution of linear equation or inequality in one variable.', mathPCData.pre_s46, mathPCData.post_s46, 46)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                                          </td>
                                          <td width="66%" style={{ width: '66.58%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Geometry</b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.72%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.1%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Geometry: Classifies the different kinds of angles.', mathPCData.pre_s47, mathPCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Geometry: Classifies the different kinds of angles.', mathPCData.pre_s48, mathPCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Geometry: Solves problems involving sides and angles of a polygon.', mathPCData.pre_s49, mathPCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Geometry: Derives relationships of geometric figures using measurements and by inductive reasoning; supplementary angles, complementary angles, congruent angles, vertical angles, adjacent angles, linear pairs, perpendicular lines, and parallel lines.', mathPCData.pre_s50, mathPCData.post_s50, 50)}
                                       {generatePercentCorrectRow('Translates English phrases to mathematical phrases and vice versa', mathPCData.pre_s51, mathPCData.post_s51, 51)}
                                       {generatePercentCorrectRow('Solves problems involving real numbers.', mathPCData.pre_s52, mathPCData.post_s52, 52)}
                                       {generatePercentCorrectRow('Solves problems involving algebraic expression ', mathPCData.pre_s53, mathPCData.post_s53, 53)}
                                       {generatePercentCorrectRow('Solves linear equation or inequality in one variable involving absolute value by: (a) graphing; and (b) algebraic methods.', mathPCData.pre_s54, mathPCData.post_s54, 54)}
                                       {generatePercentCorrectRow('Expresses rational numbers from fraction form to decimal form and vice versa.', mathPCData.pre_s55, mathPCData.post_s55, 55)}
                                       {generatePercentCorrectRow('Expresses rational numbers from fraction form to decimal form and vice versa.', mathPCData.pre_s56, mathPCData.post_s56, 56)}
                                       {generatePercentCorrectRow('Calculates the measures of central tendency of ungrouped and grouped data.', mathPCData.pre_s57, mathPCData.post_s57, 57)}
                                       {generatePercentCorrectRow('Evaluates algebraic expressions for given values of the variables.', mathPCData.pre_s58, mathPCData.post_s58, 58)}
                                       {generatePercentCorrectRow('Solves problems involving real numbers.', mathPCData.pre_s59, mathPCData.post_s59, 59)}
                                       {generatePercentCorrectRow('Performs operations on rational numbers', mathPCData.pre_s60, mathPCData.post_s60, 60)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percent Correct in Science</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody>
                                       <tr>
                                          <td width="65%" colSpan={2} rowSpan={2} style={{ width: '65.94%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.28%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>PRETEST</span></b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>POST TEST</span></b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="17%" style={{ width: '17.28%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.78%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="61%" style={{ width: '61.34%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Matter</b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.28%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="16%" style={{ width: '16.78%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Describe the components of a scientific investigation.', sciencePCData.pre_s1, sciencePCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Describe the components of a scientific investigation.', sciencePCData.pre_s2, sciencePCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Investigate properties of unsaturated or saturated solutions.', sciencePCData.pre_s3, sciencePCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Investigate properties of unsaturated or saturated solutions.', sciencePCData.pre_s4, sciencePCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Express concentrations of solutions quantitatively by preparing different concentrations of mixtures according to uses and availability of materials.', sciencePCData.pre_s5, sciencePCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Express concentrations of solutions quantitatively by preparing different concentrations of mixtures according to uses and availability of materials.', sciencePCData.pre_s6, sciencePCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Distinguish mixtures from substances based on a set of properties.', sciencePCData.pre_s7, sciencePCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Distinguish mixtures from substances based on a set of properties.', sciencePCData.pre_s8, sciencePCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Recognize that substances are classified into elements and compounds.', sciencePCData.pre_s9, sciencePCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Recognize that substances are classified into elements and compounds.', sciencePCData.pre_s10, sciencePCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Investigate properties of acidic and basic mixtures using natural indicators.', sciencePCData.pre_s11, sciencePCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Investigate properties of acidic and basic mixtures using natural indicators.', sciencePCData.pre_s12, sciencePCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Describe some properties of metals and non-metals such as luster, malleability, ductility, and conductivity.', sciencePCData.pre_s13, sciencePCData.post_s13, 13)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="61%" style={{ width: '61.34%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Living Things</b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.28%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="16%" style={{ width: '16.78%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Identify parts of the microscope and their functions.', sciencePCData.pre_s14, sciencePCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Identify parts of the microscope and their functions.', sciencePCData.pre_s15, sciencePCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Identify parts of the microscope and their functions.', sciencePCData.pre_s16, sciencePCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Focus specimens using the compound microscope.', sciencePCData.pre_s17, sciencePCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Focus specimens using the compound microscope.', sciencePCData.pre_s18, sciencePCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Describe the different levels of biological organization from cell to biosphere.', sciencePCData.pre_s19, sciencePCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Describe the different levels of biological organization from cell to biosphere.', sciencePCData.pre_s20, sciencePCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Differentiate plant and animal cells according to presence or absence of certain organelles.', sciencePCData.pre_s21, sciencePCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Differentiate plant and animal cells according to presence or absence of certain organelles.', sciencePCData.pre_s22, sciencePCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Explain why the cell is considered the basic structural and functional unit of all organisms.', sciencePCData.pre_s23, sciencePCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Explain why the cell is considered the basic structural and functional unit of all organisms.', sciencePCData.pre_s24, sciencePCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Identify beneficial and harmful microorganisms.', sciencePCData.pre_s25, sciencePCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Identify beneficial and harmful microorganisms.', sciencePCData.pre_s26, sciencePCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Differentiate asexual from sexual reproduction in terms.', sciencePCData.pre_s27, sciencePCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Differentiate asexual from sexual reproduction in terms.', sciencePCData.pre_s28, sciencePCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Describe the process of fertilization.', sciencePCData.pre_s29, sciencePCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Describe the process of fertilization.', sciencePCData.pre_s30, sciencePCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Differentiate biotic from abiotic components of an ecosystem.', sciencePCData.pre_s31, sciencePCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Differentiate biotic from abiotic components of an ecosystem.', sciencePCData.pre_s32, sciencePCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Describe the different ecological relationships found in an ecosystem.', sciencePCData.pre_s33, sciencePCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Describe the different ecological relationships found in an ecosystem.', sciencePCData.pre_s34, sciencePCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Predict the effect of changes in one population on other populations in the ecosystem.', sciencePCData.pre_s35, sciencePCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Predict the effect of changes in one population on other populations in the ecosystem.', sciencePCData.pre_s36, sciencePCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Predict the effect of changes in abiotic factors on the ecosystem.', sciencePCData.pre_s37, sciencePCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Predict the effect of changes in abiotic factors on the ecosystem.', sciencePCData.pre_s38, sciencePCData.post_s38, 38)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="61%" style={{ width: '61.34%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Force and Motion</b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.28%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="16%" style={{ width: '16.78%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Describe the motion of an object in terms of distance or displacement, speed or velocity, and acceleration.', sciencePCData.pre_s39, sciencePCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Describe the motion of an object in terms of distance or displacement, speed or velocity, and acceleration.', sciencePCData.pre_s40, sciencePCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Differentiate quantities in terms of magnitude and direction.', sciencePCData.pre_s41, sciencePCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Differentiate quantities in terms of magnitude and direction.', sciencePCData.pre_s42, sciencePCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Create and interpret visual representation of the motion of objects such as tape charts and motion graphs.', sciencePCData.pre_s43, sciencePCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Create and interpret visual representation of the motion of objects such as tape charts and motion graphs.', sciencePCData.pre_s44, sciencePCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Infer that waves carry energy.', sciencePCData.pre_s45, sciencePCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Infer that waves carry energy.', sciencePCData.pre_s46, sciencePCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Differentiate transverse from longitudinal waves, and mechanical from electromagnetic waves.', sciencePCData.pre_s47, sciencePCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Differentiate transverse from longitudinal waves, and mechanical from electromagnetic waves.', sciencePCData.pre_s48, sciencePCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Relate the characteristics of waves.', sciencePCData.pre_s49, sciencePCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Describe the characteristics of sound using the concepts of wavelength, velocity, and amplitude.', sciencePCData.pre_s50, sciencePCData.post_s50, 50)}
                                       {generatePercentCorrectRow('Describe the characteristics of sound using the concepts of wavelength, velocity, and amplitude.', sciencePCData.pre_s51, sciencePCData.post_s51, 51)}
                                       {generatePercentCorrectRow('Explain sound production in the human voice box, and how pitch, loudness, and quality of sound vary from one person to another.', sciencePCData.pre_s52, sciencePCData.post_s52, 52)}
                                       {generatePercentCorrectRow('Describe how organisms produce, transmit, and receive sound of various frequencies (infrasonic, audible, and ultrasonic sound).', sciencePCData.pre_s53, sciencePCData.post_s53, 53)}
                                       {generatePercentCorrectRow('Describe how organisms produce, transmit, and receive sound of various frequencies (infrasonic, audible, and ultrasonic sound).', sciencePCData.pre_s54, sciencePCData.post_s54, 54)}
                                       {generatePercentCorrectRow('Relate characteristics of light such as color and intensity to frequency and wavelength.', sciencePCData.pre_s55, sciencePCData.post_s55, 55)}
                                       {generatePercentCorrectRow('Relate characteristics of light such as color and intensity to frequency and wavelength.', sciencePCData.pre_s56, sciencePCData.post_s56, 56)}
                                       {generatePercentCorrectRow('Infer that light travels in a straight line.', sciencePCData.pre_s57, sciencePCData.post_s57, 57)}
                                       {generatePercentCorrectRow('Infer that light travels in a straight line.', sciencePCData.pre_s58, sciencePCData.post_s58, 58)}
                                       {generatePercentCorrectRow('Infer the conditions necessary for heat transfer to occur.', sciencePCData.pre_s59, sciencePCData.post_s59, 59)}
                                       {generatePercentCorrectRow('Infer the conditions necessary for heat transfer to occur.', sciencePCData.pre_s60, sciencePCData.post_s60, 60)}
                                       {generatePercentCorrectRow('Describe the different types of charging processes.', sciencePCData.pre_s61, sciencePCData.post_s61, 61)}
                                       {generatePercentCorrectRow('Describe the different types of charging processes.', sciencePCData.pre_s62, sciencePCData.post_s62, 62)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="61%" style={{ width: '61.34%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Earth and Space</b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.28%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="16%" style={{ width: '16.78%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Explain the importance of earthing or grounding.', sciencePCData.pre_s63, sciencePCData.post_s63, 63)}
                                       {generatePercentCorrectRow('Explain the importance of earthing or grounding.', sciencePCData.pre_s64, sciencePCData.post_s64, 64)}
                                       {generatePercentCorrectRow('Recognize that soil, water, rocks, coal, and other fossil fuels are Earth materials that people use as resources.', sciencePCData.pre_s65, sciencePCData.post_s65, 65)}
                                       {generatePercentCorrectRow('Discuss how energy from the Sun interacts with the layers of the atmosphere.', sciencePCData.pre_s66, sciencePCData.post_s66, 66)}
                                       {generatePercentCorrectRow('Discuss how energy from the Sun interacts with the layers of the atmosphere.', sciencePCData.pre_s67, sciencePCData.post_s67, 67)}
                                       {generatePercentCorrectRow('Account for the occurrence of land and sea breezes, monsoons, and intertropical convergence zone (ITCZ).', sciencePCData.pre_s68, sciencePCData.post_s68, 68)}
                                       {generatePercentCorrectRow('Account for the occurrence of land and sea breezes, monsoons, and intertropical convergence zone (ITCZ).', sciencePCData.pre_s69, sciencePCData.post_s69, 69)}
                                       {generatePercentCorrectRow('Describe the effects of certain weather systems in the Philippines.', sciencePCData.pre_s70, sciencePCData.post_s70, 70)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                        </div>
                     </div>
                  </div >
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }

      </>
   );
}
