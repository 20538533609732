import { useState, useContext } from 'react'
import { Flex} from 'theme-ui'
import { Popover, Menu } from 'antd';
import { CloseCircleOutlined, CopyOutlined, DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { CanvasContext } from '../hooks/LessonPage';
import CanvasComponent from '../CanvasComponent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function FooterMenu(props) {
  const [option, setOption] = useState({ zoomValue: 100 })
  const { state,actions } = useContext(CanvasContext)
  const [open, setOpen] = useState(false);


  const items = [
    {
      key: 'delete',
      label: 'Delete Item',
      icon: <DeleteOutlined />,
    },
    {
      key: 'duplicate',
      label: 'Duplicate Item',
      icon: <CopyOutlined />,
    },
  ];

  const onClick = (e, idx) => {
    // console.log('click ', e.key);
    // actions.deleteCanvas(idx)

    // console.log('click ', e.key);
    if (e.key === "delete")
       actions.deleteCanvas(idx)
    else if (e.key === "duplicate")
       actions.cloneCanvas(idx)    
  };

  const thumbnail = (canvasData, idx) =>
  {
    return(
        <div className='canvas' id='imgItem' style={{ 
                zIndex :"1",
                // position :"relative",
                cursor : "pointer",
                backgroundColor : canvasData.backgroundcolor ,
                backgroundImage :  (`url(${canvasData.backgroundimage})`) ,
                backgroundSize : "100% 100%",
                transform : "scale(0.142,0.122)",
                transformOrigin : "1px 1px",
                // border : "1px solid black",
                position: "relative",
                display : "flex" , 
                width : "820px", 
                justifyContent : "center",
                alignItems : "center",
                height  :"620px" , 
                margin :"auto",
                overflow : "hidden" }}
                onClick={() => actions.setSelectedIdxCanvas(idx)}
                >
                
              {canvasData?.data?.map(canvas => {
              return <CanvasComponent {...canvas} />;
              })}
              <div style={{ zIndex :"9999",width : "820px",height  :"620px" , backgroundColor : "transparent" }}></div>
          </div>  
        // })        
    )    
  }


  const onZoomChange = (event) => {
    // handlers.zoomHandler.zoomToRatio(event.target.value / 100)
    props.ZoomChange(event.target.value / 100)
  }
   
  const configClick = (e,i) =>
  {
    setOpen(true)
    actions.setSelectedIdxCanvas(i)
  }

    const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    display: "flex",
    paddingLeft: '5px',
    //border: "solid 1px",
    // styles we need to apply on draggables
    ...draggableStyle
 });  

  const zoomString = parseFloat(option.zoomValue.toFixed(2))

  const _onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
       return;
    }
    actions.updateDataListOrder(result)

 }

  return (
    <>  
    <Flex
      sx={{
        borderRadius: '6px',
        padding: '0 1rem',
        alignItems: 'center',
        background: '#fff',
        height: '120px',
        overflow : 'auto'
      }}
    >
    <DragDropContext onDragEnd={_onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
      {(provided, snapshot) => (
        <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ display: "flex", marginTop: "5px" }}
        >
      {state?.canvasListData?.map((data,i) => {
       return(<>
        <Draggable key={i} draggableId={i.toString()} index={i}>
           {(provided, snapshot) => (
           <div >
              <div
                 ref={provided.innerRef}
                 {...provided.draggableProps}

                 style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                 )}
               >      
              
                
                <Flex sx={{paddingRight: '1rem',}}>
                      <div style={{zIndex : "1" , 
                                  position:"relative" ,
                                  width: "120px", 
                                  height: "80px", 
                                  borderRadius : "5px", 
                                  border : `1px solid ${i === state?.canvasListDataIdx 
                                                          ? "#10828A"
                                                          : "#CFCFCF"                                                         
                        }`}}   {...provided.dragHandleProps}>
                        {thumbnail(data, i)} 
                          <Popover
                              content={<div style={{border : "none"}}>
                                  <Menu
                                      theme={"light"}
                                      onClick={(e) => onClick(e,i)}
                                      style={{ minWidth: 0, flex: "auto" , border : "none" , padding : "0px", margin : "0px"}}
                                      mode="inline"
                                      items={items}
                                    />
                              </div>}
                              trigger="click"
                              open={i === state?.canvasListDataIdx ? open : false}
                              onOpenChange={handleOpenChange}
                            >
                            <div
                                  onClick={(e) => configClick(e,i)}
                                  style={{  position : "absolute",
                                        cursor : 'pointer',
                                        display :"block",
                                        zIndex : "3",
                                        top : "2px",
                                        left : "90px",
                                        width : "30px",
                                        height : "22px",
                                        textAlign : "center",
                                        color : "gray",
                                        margin : "0px"
                                    }}>
                                      {/* <MenuOutlined /> */}
                                      <i style={{fontSize : "20px"}} class="fas fa-gear"></i>
                          </div>   
                        </Popover> 
                      </div>
                 </Flex>  
              </div>
            </div>
           )}
          </Draggable>              
              </>)
       })}   
        {provided.placeholder}
       </div>
        )}
        </Droppable>
      </DragDropContext>
      <Flex>
      <div style={{display : "flex", width: "120px", height: "80px", borderRadius : "5px", border : "1px dashed gray"}} onClick={() => actions.addCanvas()}>
        <div style={{display : "flex", justifyContent : "center", 
             alignItems : "center", margin : "auto", height :"32px" , width : "32px"}}>
               <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M17 15V6.668a1 1 0 1 0-2 0V15H6.668a1 1 0 1 0 0 2H15v8.332a1 1 0 1 0 2 0V17h8.332a1 1 0 1 0 0-2zm0 0"></path></svg>
         </div>
      </div>
      </Flex>
    </Flex>
      </>
  )
}

function PagesIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.75"
        y="4.75"
        width="17.5"
        height="17.5"
        rx="1.25"
        stroke="currentColor"
        strokeWidth="1.5"
      ></rect>
      <path
        d="M4.75 1a.75.75 0 0 0 0 1.5H19.4c1.16 0 2.1.94 2.1 2.1v14.65a.75.75 0 0 0 1.5 0V4.2A3.2 3.2 0 0 0 19.8 1H4.75z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

function ExpandIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.636 10.43L18.5 6.565v2.687a.75.75 0 0 0 1.5 0V5.497c0-.966-.53-1.495-1.497-1.495h-3.749a.75.75 0 0 0 0 1.5h2.687l-3.867 3.867a.75.75 0 0 0 1.06 1.061zm-5.27 3.139l-3.867 3.867v-2.688a.75.75 0 0 0-1.5 0v3.75c0 .967.527 1.5 1.493 1.5h3.753a.75.75 0 0 0 0-1.5H6.558l3.869-3.869a.75.75 0 0 0-1.061-1.06z"
        fill="currentColor"
      ></path>
    </svg>
  )
}
export default FooterMenu
