import React, { useState, useEffect, useRef } from 'react';
import { Table, Dropdown, Menu, Button, Input, Tabs, Form, Select, Radio, DatePicker, Space, Modal, Row, Col, Comment, Avatar, List, Tooltip, message } from 'antd';
import { UserOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import uuid from 'react-uuid';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import DiscussionService from '../../services/DiscussionService';

function Discussions(props) {
   const [comments, setComments] = useState([])
   const [submitting, setSubmitting] = useState(false)
   const [textvalue, setTextValue] = useState()
   const [lessonid, setLessonId] = useState()
   const [username, setUserName] = useState()
   const [userid, setUserId] = useState()
   const dateFormat = 'YYYY-MM-DD HH:mm'
   const [commentDefault, setcommentDefault] = useState({
      actions: [<span>Reply to</span>],
      author: '',
      avatar: '',
      content: '',
      datetime: moment().fromNow(),
   })
   const { TextArea } = Input;
   const editorRef = useRef(null);
   const [form] = Form.useForm();
   const actions = [<span>Reply to</span>];

   useEffect(() => {
      let userid = Utils.getCurrentUserInfo(Enums.UserInfo.id)
      let user = Utils.getCurrentUserInfo(Enums.UserInfo.fullname)
      setUserName(user)
      setUserId(userid)
      setLessonId(props.Id)
      console.log(props.Id)
      getDiscussions(props.Id, userid)
   }, []);

   const getDiscussions = (lessonid, userid) => {
      DiscussionService.getDiscussions(lessonid, userid).then((response) => {
         let discussions = response.data
         console.log(discussions)
         let discussionsdata = []

         discussions.map((item) => {
            console.log(item.created_At)
            let _object = {
               actions: [<span>Reply to</span>],
               author: item.userName,
               avatar: '',
               content: (<p>{item.content}</p>),
               datetime: moment(item.created_At, dateFormat).fromNow()
            }

            console.log(`item id ${item.id}`)
            console.log(`content ${item.content}`)
            discussionsdata.push(_object);
         })
         setComments(discussionsdata)

      }).catch((ex) => {
         message.error(ex.message)
      })
   }

   const handleSubmit = (e) => {
      if (!e) {
         return
      }

      setSubmitting(true)
      console.log('done pushing')
      setTimeout(() => {
         console.log('set timeout starts')
         let tempcomments = commentDefault
         let tmpcomment = { ...tempcomments, content: <p>{textvalue}</p> }
         let commentarr = comments
         let txtvalue = textvalue
         commentarr.push(tmpcomment)
         setComments(commentarr)
         setTextValue("")
         setSubmitting(false)

         let user = Utils.getCurrentUserInfo(Enums.UserInfo.id)
         let role = Utils.getCurrentUserInfo(Enums.UserInfo.role)
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         var discussion_id = "disc_" + o2o_mode + "_" + uuid();

         let default_body = {
            id: discussion_id,
            lessonId: lessonid,
            topicId: '',
            contentId: '',
            userId: user,
            role: role,
            content: txtvalue
         }

         DiscussionService.saveDiscussions(default_body).then(response => {
            console.log(response)
            if (response.data) {
               console.log('discussion saved');
               // Modal.success({
               // content: 'Discussion Record was created.',
               // centered: true
               // });
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               // icon: <ExclamationCircleOutlined />,
               title: 'Save Discussion',
               content: 'Discussion Record creation failed.',
               centered: true
            });
         });

      }, 2000);
      setTextValue("")
   }

   const handleRefresh = () => {
      getDiscussions(props.Id, userid);
   }

   const CommentList = () => {
      return (
         <List
            className="comment-list"
            header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
            itemLayout="vertical"
            dataSource={comments}
            renderItem={item => (
               <li>
                  <Comment
                     // actions={item.actions}
                     author={username}
                     avatar={<Avatar icon={<UserOutlined />} />}
                     content={item.content}
                     datetime={item.datetime}
                  />
               </li>
            )}
         />
      )
   }

   return (
      <>
         {comments.length > 0 &&
            <Row gutter={12}>
               <Col className="gutter-row" span={24}>
                  <div>
                     <CommentList comments={comments} />
                  </div>
               </Col>
            </Row>
         }
         <Form.Item>
            <TextArea
               key={1}
               value={textvalue}
               maxLength={100}
               rows={4}
               onChange={(event) => setTextValue(event.target.value)}
            />
         </Form.Item>
         <Form.Item>
            <Space direction='horizontal' size={"small"}>
               <Button className='button-shadow' shape='round' loading={submitting} onClick={(e) => handleSubmit(e)} type="primary">
                  Add Comment
               </Button>
               <Button className='button-shadow' shape='round' loading={submitting} onClick={() => handleRefresh()} type="primary">
                  Refresh
               </Button>
            </Space>
         </Form.Item>
      </>
   );
}

export default Discussions;