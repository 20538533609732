const Enums = 
{
    HttpStatusCodes: {
        /**
         * Returns the HTTP status ok value.
         */
        get httpStatusOk() {
          return 200;
        },
    
        /**
         * Returns the HTTP status server error value.
         */
        get httpStatusInternalServerError() {
          return 500;
        },
    
        get httpStatusUnauthorizedError() {
          return 401;
        }
      },
    ResourcesType : 
    {
        get isGoogleSearch()
        {
            return "google.search"
        },
        get isGoogleImage()
        {
            return "google.image"
        },
        get isYoutube()
        {
            return "youtube"
        },
        get isURL()
        {
            return "url"
        },        
        get isResources()
        {
            return "file"
        },
        get isKahoot()
        {
            return "kahoot"
        },
        get isMentimeter()
        {
            return "mentimeter"
        },
        get isCanva()
        {
            return "canva"
        },
        get isInsertLearning()
        {
            return "insertlearning"
        },
        get isFlipGrid()
        {
            return "flipgrid"
        },
        get isPhetSimulation()
        {
            return "phetsimulation"
        },
        get isSeeSaw()
        {
            return "seesaw"
        },
        get isPadLet()
        {
            return "padlet"
        }                                                          
    },
    FileType :
    {
        get doc()
        {
            return "application/msword"
        },   
        get docx()
        {
            return "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        },  
        get pdf()
        {
            return "application/pdf"
        },  
        get ppt()
        {
            return "application/vnd.ms-powerpoint"
        },  
        get pptx()
        {
            return "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        },  
        get ppsx()
        {
            return "application/vnd.openxmlformats-officedocument.presentationml.slideshow"
        },          
        get xlsx()
        {
            return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },  
        get xls()
        {
            return "application/vnd.ms-excel"
        },  
        get png()
        {
            return "image/png"
        },   
        get jpeg()
        {
            return "image/jpeg"
        },  
        get bmp()
        {
            return "image/bmp"
        },        
        get mp4()
        {
            return "video/mp4"
        },                                                                                
    },
    FileTypeImage :
    {
        get ppt()
        {
            return "/images/msppt.png"
        },
        get ppsx()
        {
            return "/images/msppt.png"
        },        
        get excel()
        {
            return "/images/msexcel.png"
        },      
        get word()
        {
            return "/images/msword.png"
        },  
        get pdf()
        {
            return "/images/pdf.png"
        },  
        get img()
        {
            return "/images/img.png"
        },        
        get mp4()
        {
            return "/images/mp4.png"
        },         
        get others()
        {
            return "/images/others.png"
        },                                                                               
    },
    KahootInfo :
    {
        get title()
        {
            return "Kahoot! | Learning games | Make learning awesome!"
        },
        get description()
        {
            return "Kahoot! is a game-based learning platform,[3] used as educational technology in schools and other educational institutions. Its learning games, \"kahoots\", are user-generated multiple-choice quizzes that can be accessed via a web browser or the Kahoot app. Kahoot! can be used to review students' knowledge, for formative assessment,[4] or as a break from traditional classroom activities.[5] Kahoot! also includes trivia quizzes.[6] This educational platform is similar to other technological learning tools such as Wooflash, Socrative or Quizlet."
        },
        get url()
        {
            return "https://www.kahoot.it"
        },
        get image()
        {
            return "https://kahoot.com/files/2019/02/BrandAssets-Logo-CoverImage.png"
        }
    }  
    ,
    MentimeterInfo :
    {
        get title()
        {
            return "Mentimeter"
        },
        get description()
        {
            return "Mentimeter (or Menti for short) is a Swedish company based in Stockholm that develops and maintains an eponymous app used to create presentations with real-time feedback"
        },
        get url()
        {
            return "https://www.mentimeter.com/login"
        },
        get image()
        {
            return "https://roi4cio.com/uploads/roi/company/Mentimeter_Logotype.png"
        }
    },
    CanvaInfo :
    {
        get title()
        {
            return "Canva"
        },
        get description()
        {
            return "Canva is an Australian graphic design platform, used to create social media graphics, presentations, posters, documents and other visual content. The app includes templates for users to use."
        },
        get url()
        {
            return "https://www.canva.com/"
        },
        get image()
        {
            return "https://dka575ofm4ao0.cloudfront.net/pages-transactional_logos/retina/12162/WORDMARK_LOGO_-_GRADIENT_-_RGB.png"
        }
    },
    InsertLearningInfo :
    {
        get title()
        {
            return "InsertLearning"
        },
        get description()
        {
            return "InsertLearning was founded by two high school teachers who wanted to create a richer learning experience for their students. Our mission is to help teachers turn the Internet into an interactive learning experience."
        },
        get url()
        {
            return "https://insertlearning.com/"
        },
        get image()
        {
            return "https://insertlearning.com/images/wordmark@2x.png"
        }
    },
    FlipGridInfo :
    {
        get title()
        {
            return "FlipGrid"
        },
        get description()
        {
            return "Flipgrid is a video discussion platform that helps educators see and hear from every student in class and foster a fun and supportive social learning environment."
        },
        get url()
        {
            return "https://info.flipgrid.com/"
        },
        get image()
        {
            return "/images/flipgrid.jpeg"
        }
    },     
    PhetSimulationInfo :
    {
        get title()
        {
            return "PhetSimulation"
        },
        get description()
        {
            return "Founded in 2002 by Nobel Laureate Carl Wieman, the PhET Interactive Simulations project at the University of Colorado Boulder creates free interactive math and science simulations. PhET sims are based on extensive education research and engage students through an intuitive, game-like environment where students learn through exploration and discovery."
        },
        get url()
        {
            return "https://phet.colorado.edu/"
        },
        get image()
        {
            return "/images/phet-logo-trademarked.png"
        }
    },       
    SeeSaw :
    {
        get title()
        {
            return "SeeSaw"
        },
        get description()
        {
            return "The Best Interactive Learning Platform for PreK-5"
        },
        get url()
        {
            return "https://app.seesaw.me/#/login"
        },
        get image()
        {
            return "https://images.squarespace-cdn.com/content/v1/55a6e4bde4b0b0ebce635c9d/1586328185568-XOLO3XR72BL8Y4TD7HWM/logo.png?format=1500w"
        }
    }, 
    PadLet :
    {
        get title()
        {
            return "Padlet"
        },
        get description()
        {
            return "Padlet is an educational technology startup company based in San Francisco, California and Singapore. Padlet provides a cloud-based software-as-a-service, hosting a real-time collaborative web platform in which users can upload, organize, and share content to virtual bulletin boards called \"padlets\"."
        },
        get url()
        {
            return "https://padlet.com/"
        },
        get image()
        {
            return "https://padlet-uploads.storage.googleapis.com/5587727/AEV1LKtFkyKgLU1DzT_Ocg/ef1210d4305560b0eb3acf6500d5099c.png"
        }
    },           
    UserInfo :
    {
        get fullname()
        {
            return "unique_name"
        },
        get role()
        {
            return "role"
        },
        get id()
        {
            return "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"
        },
        get username()
        {
            return "primarysid"
        },
        get otherdata() 
        {
          return "http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"
        }    
    }  
}

// Lock object to prevent modification (true static).
Object.freeze(Enums);

export default Enums;