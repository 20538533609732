import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Modal, Image, Space } from 'antd';
import React from 'react';

export default function ProcessingModal(props) {
   const antIcon = (
      <LoadingOutlined
         style={{
            fontSize: 24,
         }}
         spin
      />
   );
   return (
      <>
         <Modal
            key="modal-processing"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" /> {props.title}</div>}
            destroyOnClose={true}
            centered
            open={props.processing}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            width='25%'
            style={{ minWidth: "350px" }}
         >
            <div style={{ textAlign: 'center' }}>
               <Space>
                  <Spin /><i>Processing request, please wait...</i>
               </Space>
            </div>
         </Modal>
      </>
   );
}