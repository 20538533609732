import React,{ useEffect, useState,useRef } from 'react'
import CanvasComponent from './CanvasComponent';
import Utils from '../../../misc/Utils';
import "./LessonViewer.scss"
import {message, Pagination
 } from 'antd';
 import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
// import "./FileElement.scss"
// import PDFLessonViewer from "../../LessonComponents/PDFLessonViewer";
// import ViewMSOfficeFile from "../../LessonComponents/ViewMSOfficeFile";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import "../Viewer/Components/FileElement.scss"

// export const CanvasContext = React.createContext({});  

export default function LessonViewer2(props) {

    // const [zoomValue, setZoomValue] = useState(1);
    // const [lessonId, setLessonId] = useState("")
    // const [canvasData, setCanvasData] = useState([])
    const [canvasListData, setCanvasListData] = useState([])
    const [canvasListDataIdx, setCanvasListDataIdx] = useState(0)
    // const [bodyBackgroundColor, setBodyBackgroundColor] = useState("white")
    // const [bodyBackgroundImage, setBodyBackgroundImage] = useState("")
    // const [scaleWidth, setScaleWidth] = useState(1)
    // const [scaleHeight, setScaleHeight] = useState(1)
    const [scale, setScale] = useState(1)
    // const [isLoaded, setIsLoaded] = useState(false)
    const containerRef = useRef(null)
    const divWrapper = useRef(null)
    // // const [showPointer, setShowPointer] = useState(false)
    // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showGrids, setShowGrids] = React.useState(false)
    // const [current, setCurrent] = useState(0);
    // // const onChange = (page) => {
    // //   console.log(page);
    // //   setCurrent(page);
    // // };
    // const pageLayout = {
    //     transformSize: ({ size }) => ({
    //         height: size.height + 30,
    //         width: size.width + 30,
    //     }),
    // };
 

//     useEffect(() => {
//       const handleResize = () => {
//           setWindowWidth(window.innerWidth);

//           var elHeight = containerRef.current?.clientHeight;
//           var elWidth = containerRef.current?.clientWidth;  
          
//           var scale;
//           var width = divWrapper.current?.clientWidth/ elWidth
//           var height =  divWrapper.current?.clientHeight / elHeight
//           scale = Math.min(
//             divWrapper.current.offsetWidth/ elWidth,    
//             divWrapper.current.offsetHeight / elHeight
//           );
    

//           scale = scale > 1 ? 1 : scale
//           var minHeight = scale === 0 ? scaleHeight : height-0.2
//           var minWidth = scale === 0 ? scaleWidth : scale-0.03

//           setScale(minWidth)
//           setScaleWidth(minWidth)
//           setScaleHeight(minHeight)          
//       };

//       window.addEventListener("resize", handleResize);
//       return () => {
//           window.removeEventListener("resize", handleResize);
//       };
//   }, []);    

  

// const setSelectedIdxCanvas = (idx) => {
//     // set default in List Canvas
//     var canvas = canvasListData[idx-1].data
//     setCanvasListDataIdx(idx-1)
//     setCanvasData(canvas)
//     setCurrent(idx)
// }


    // useEffect(() => {
    //     let data = props.sections
    //     setCanvasListDataIdx(0)
    //     if (data.sections === null)
    //        data.sections = []
          
    //     // data = Utils.convertKeysToLowerCase(data)
    //     var canvasDataT = []
    //     var canvasListDataT = data.sections[props.lessonIdx].contents[props.topicIdx]?.data
    //     canvasDataT = [...{...canvasListDataT[0]}?.data]
    //     setCanvasData(canvasDataT)
    //     setCanvasListData([...canvasListDataT])        
    //     setIsLoaded(true)

        // if (data.sectionversion === 0)
        // {
        //   var listData = {backgroundcolor : "white", data : canvasData}
        //   setCanvasListData([...canvasData, listData])
        // }
        // else
        // {
        //   var canvasListDataT = data.sections[props.lessonIdx].contents[props.topicIdx]?.data
        //   var canvasDataT = []
        //   if (canvasListDataT)
        //   {
        //      if (canvasListDataT.length > 0)
        //       {
        //         canvasDataT = [...{...canvasListDataT[0]}?.data]
        //         setCanvasData(canvasDataT)
        //         setCanvasListData([...canvasListDataT])
        //       }
        //      else
        //      {
        //       var listData = {backgroundcolor : "white", data : canvasDataT}
        //       setCanvasListData([...canvasDataT, listData])
        //      } 
        //   } 
        //   else
        //   {
        //     var listData = {backgroundcolor : "white", data : canvasDataT}
        //     setCanvasListData([...canvasDataT, listData])
        //   }
  
        //   setCanvasData(canvasDataT)  
        //   setIsLoaded(true)
        // }      
  
    //  }, [props.lessonIdx,props.topicIdx]);


 
    return( 
        <>
           {/* {<CanvasContext.Provider value={context}>      */}
             <div class="scaleable-wrapper" id="scaleable-wrapper" ref={divWrapper}>
                <div class="very-specific-design" id="very-specific-design" ref={containerRef}
                  style={{
                    backgroundColor : canvasListData.length > 0 ?  canvasListData[canvasListDataIdx].backgroundcolor : "white" ,
                    backgroundImage : canvasListData.length > 0 ?  (`url(${canvasListData[canvasListDataIdx].backgroundimage})`) : "" ,                  
                    transform : `translate(-50%, -50%) scale(${scale})`,
                  }}
                >              
                      {/* {canvasData.map((canvas) => {
                          return <CanvasComponent {...canvas} />;
                      })}  */}
               <div 
                //   ref={elementRef}                 
                style={{ position : "relative", 
                    left: "50px", 
                    top:  "50px",
                    width:  "3144px", 
                    height:   "2263px",    
                    transform : "translate(-1176.313px, -869.849px) scale(0.25, 0.25)",                 
                    fontFamily: "Arial",
                    fontSize: "13px",
                    padding: 0,
                    float : "left",
                    border: `2px solid ${
                        showGrids 
                        ? "#21DEE5"
                        : "transparent"
                    }`,                            
                    }}>     
                    <div style={{ display: "flex" }}>
                    <iframe style={{position : "absolute", width :  "100%", height : "100%"}} src={`${process.env.REACT_APP_MSOFFICE_VIEWER}https://lms.campuscloudph.com/localhost/1/55724cd-7df-b130-c133-23acc3f5e86f-Contemporary wedding photo album.pptx`} title="PPT"></iframe>
                    </div>                                 
                            {/* <div  style={{ width : "100%", height : "100%", backgroundColor : "white" }}>
                            <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                <Viewer pageLayout={pageLayout}  fileUrl={"https://lms.campuscloudph.com/localhost/1/dd4702-0e5-b7b-377-d62a2f4fadc-1f20dd-6a1-33b7-aa81-7d2ef052dfee-STEPS%20-%20Brainee%20User%20Guide%20SY%2024-25.pdf"}  defaultScale={SpecialZoomLevel.PageWidth} />
                            </Worker>
                            </div> */}
                   </div>                                 

                </div>  
              </div>                
                {/* <div
                  style={{
                    display : "flex",
                    width : "100%",
                    justifyContent : "right",
                    alignContent : "center",
                    // backgroundColor  : "red"
                  }}
                >
                    <div>
                        <Pagination
                            total={canvasListData.length}
                            onChange={setSelectedIdxCanvas}
                            current={current}
                            defaultPageSize={1}
                            responsive
                            showQuickJumper
                            showTotal={(total) => `Total ${total} items`}
                        />   
                    </div>
                </div>                          */}
        {/* </CanvasContext.Provider>}        */}
        </>
    )
}
