import DefaultToolbox from './DefaultToolbox/DefaultToolbox'

function Toolbox({isEditEnable}) {
  return (
    <>
      <DefaultToolbox isEditEnable={isEditEnable}/>
    </>
  )
}

export default Toolbox
