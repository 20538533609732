import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, Row, Col, List, Divider, Typography, Segmented, Modal } from 'antd';
import SimpleBarReact from "simplebar-react";
// import { Editor } from '@tinymce/tinymce-react';
import DocumentViewer from '../../../components/document-viewer/DocumentViewer';
import Utils from '../../../misc/Utils';
import axios from 'axios';
import Wysiwyg from '../../../components/wysiwyg/wysiwyg';

const { Title } = Typography;

let g_recommendation_data = {
   id: null,
   description: '',
   documents: null,
   document_names: null,
   status: null,
}

export default function ViewRecommendations(props) {
   const editorRef = useRef(null);

   // const g_recommendation_data = props.data;

   const student_id = props.student_id;
   const assignment_id = props.assignment_id;

   // const [currentRecommendationDoc, setCurrentRecommendationDoc] = useState(null);

   const [currentRecommendationDocI, setCurrentRecommendationDocI] = useState(null);
   const [currentRecommendationDocG, setCurrentRecommendationDocG] = useState(null);

   const [selectedDocumentIdx, setSelectedDocumentIdx] = useState(-1);
   const [recommendationDataAll, setRecommendationDataAll] = useState([]);
   const [recommendationData, setRecommendationData] = useState(g_recommendation_data);
   const [recommendationType, setRecommendationType] = useState('individual');

   useEffect(() => {
      Utils.verifyJwt();

      getRecommendations();
   }, []);

   const getRecommendations = () => {
      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/getrecommendations?student_id=${student_id}&assignment_id=${assignment_id}`;
      axios.get(url).then(response => {
         if (response.data) {
            var data = response.data;
            setRecommendationDataAll(data);

            // handleShowRecommendation(data, data[0].is_group ? 'group' : 'individual');
            handleShowRecommendation(data, 'individual');
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Getting recommendations failed.',
            centered: true
         });
      });
   }

   const handleShowRecommendation = (data_all, type) => {
      //-- Set empty data for when there is no match found in the data
      var data = {
         id: null,
         description: '',
         documents: null,
         document_names: null,
         status: null,
      };
      var is_group = type === 'group' ? true : false;

      data_all.forEach(element => {
         if (element.is_group === is_group)
            data = element;
      });


      g_recommendation_data = {
         id: data.recommendation_id,
         description: data.description ?? '',
         documents: data.documents !== null ? data.documents.split(',') : null,
         document_names: data.document_names !== null ? data.document_names.split(',') : null,
         status: data.status,
      }

      setRecommendationData(g_recommendation_data);
      setRecommendationType(type);
      // setCurrentRecommendationDocI(null);
   }

   // const handleViewRecommendationDoc = (document, idx) => {
   //    setSelectedDocumentIdx(idx);
   //    setCurrentRecommendationDoc(document);
   // }

   const handleViewRecommendationDoc = (document, idx) => {
      setSelectedDocumentIdx(idx);

      if (recommendationType === 'individual')
         setCurrentRecommendationDocI(document);
      else
         setCurrentRecommendationDocG(document);
   }

   return (
      <>
         <Row>
            <Col xs={24} md={15} lg={15}>
               <div className='essay_list_container'>
                  <DocumentViewer
                     // document={currentRecommendationDoc}
                     // documentlist={g_recommendation_data.rec_docs}
                     document={recommendationType === 'individual' ? process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH + '/' + currentRecommendationDocI : process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH + '/' + currentRecommendationDocG}
                     documentlist={recommendationData.documents}
                     // pdfheight={"calc(100vh - 205px)"}
                     pdfshowdownload={false}
                     pdfshowprint={false}
                     // videoheight={"100%"}
                     ismodal={true}
                     dvheight={"calc(100vh - 185px)"}
                  />
               </div>
            </Col>
            <Col xs={24} md={9} lg={9}>

               <div className='student_list_container' style={{ paddingLeft: "10px" }}>
                  <SimpleBarReact style={{ height: "calc(100svh - 185px" }}>
                     <Segmented
                        value={recommendationType}
                        block
                        options={[
                           {
                              label: 'Class Recommendation',
                              value: 'group'
                           },
                           {
                              label: 'Teacher Recommendation',
                              value: 'individual'
                           }
                        ]}
                        onChange={(val) => handleShowRecommendation(recommendationDataAll, val)}
                     />
                     <Divider orientation="left">
                        <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Recommendations</Title>
                     </Divider>
                     <Wysiwyg 
                        // changeContentCallback={(e) => handleDescriptionChange(e)} 
                        content={recommendationData.description} 
                        height={300}
                        contentHeight={235}
                        disabled={true}
                     />
                     {/* <Editor
                        disabled={true}
                        apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                        onInit={(evt, editor) => editorRef.current = editor}
                        // initialValue={g_recommendation_data.rec_desc}
                        initialValue={recommendationData.description}
                        init={{
                           menubar: false,
                           height: 300,
                           statusbar: false,
                           plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code',
                              'insertdatetime media table paste code help wordcount emoticons'
                           ],
                           toolbar: false,
                           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                        value={recommendationData.description}
                     /> */}
                     <Divider orientation="left">
                        <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Documents</Title>
                     </Divider>
                     {
                        // (g_recommendation_data.rec_docs !== null && g_recommendation_data.rec_docs !== '') &&
                        (recommendationData.documents !== null && recommendationData.documents !== '') &&
                        <SimpleBarReact
                           style={{ height: "calc(100vh - 627px)", backgroundColor: "white", minHeight: "200px" }}
                        // onClick={() => setSelectedDocumentIdx(-1)}
                        >
                           <List
                              size="small"
                              // dataSource={g_recommendation_data.rec_docs}
                              dataSource={recommendationData.documents}
                              renderItem={(item, idx) =>
                                 // <Tooltip placement="right" title={g_recommendation_data.rec_doc_names[idx]}>
                                 <Tooltip placement="left" title={recommendationData.document_names[idx]}>
                                    <List.Item
                                       onClick={() => handleViewRecommendationDoc(item, idx)}
                                       className={idx === selectedDocumentIdx ? 'document-file-selected' : 'document-file'}
                                       style={{ textAlign: "left", whiteSpace: "nowrap", textOverflow: "ellipsis", margin: "5px" }}
                                    >
                                       {/* {Utils.GetFileIcon(g_recommendation_data.rec_doc_names[idx])} {g_recommendation_data.rec_doc_names[idx]} */}
                                       {Utils.GetFileIcon(recommendationData.document_names[idx])} {recommendationData.document_names[idx]}
                                    </List.Item>
                                 </Tooltip>
                              }
                           />
                        </SimpleBarReact>
                     }
                  </SimpleBarReact>
               </div>
            </Col>
         </Row>
      </>
   );
}