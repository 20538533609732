import { SubjectModel, SubjectModelBlank } from "../models";



// This is where we update the order of the lesson state
const update_lesson_order = (state, payload) =>
{

  // state = payload;
   const tmpLessonState = state;
   const tmpLessonContent = [...tmpLessonState.sections[payload.TopicIndex].contents];
   const [removed] = tmpLessonContent.splice(payload.SourceIdx, 1);
   tmpLessonContent.splice(payload.DestinationIdx, 0, removed);
   tmpLessonState.sections[payload.TopicIndex].contents = tmpLessonContent;

  return tmpLessonState;
}

const update_topic_order = (state, payload) =>
{

  // state = payload;
   const tmpLessonState = state;
   const tmpTopicSection = [...tmpLessonState.sections];
   const [removed] = tmpTopicSection.splice(payload.SourceIdx, 1);
   tmpTopicSection.splice(payload.DestinationIdx, 0, removed);
   tmpLessonState.sections = tmpTopicSection;

  return tmpLessonState;
}

const update_link_order = (state, payload) =>
{
   const tmpLessonState = state;
   
   const tmpLessonContentLinks =  [...[...tmpLessonState.sections[payload.TopicIndex].contents].filter(item => item.id === payload.LessonIndex)[0].links];
   const [removed] = tmpLessonContentLinks.splice(payload.SourceIdx, 1);
   tmpLessonContentLinks.splice(payload.DestinationIdx, 0, removed);
   [...tmpLessonState.sections[payload.TopicIndex].contents].filter(item => item.id === payload.LessonIndex)[0].links = tmpLessonContentLinks;

  return tmpLessonState;
}


const delete_item = (state, payload) =>
{
   const tmpLessonState = state;
   var tmpObject = [];
   var newObject = [];
    if (payload.Type === "TOPIC")
    {
      tmpObject  = [...tmpLessonState.sections];
       newObject = tmpObject.filter(item => item.id !== payload.SourceIdx); 
      tmpLessonState.sections = newObject;
    }
    if (payload.Type === "LESSON")
    {
      tmpObject  = [...tmpLessonState.sections[payload.TopicIndex].contents];
       newObject = tmpObject.filter(item => item.id !== payload.SourceIdx); 
      tmpLessonState.sections[payload.TopicIndex].contents = newObject;
    }
    if (payload.Type === "LINKS")
    {
      
      tmpObject  = [...[...tmpLessonState.sections[payload.TopicIndex].contents].filter(item => item.id === payload.LessonIndex)[0].links];
       newObject = tmpObject.filter(item => item.id !== payload.SourceIdx); 
      [...tmpLessonState.sections[payload.TopicIndex].contents].filter(item => item.id === payload.LessonIndex)[0].links = newObject;
    }            

  


  return tmpLessonState;
}

const add_item = (state, payload) =>
{
   const tmpLessonState = state;
   var newObject = [];
   var tmpObject = [];
    if (payload.Type === "TOPIC")
    {
      tmpObject  = [...tmpLessonState.sections];
      newObject = [...tmpObject, payload.title] 
      tmpLessonState.sections = newObject;
    }
    if (payload.Type === "LESSON")
    {
      tmpObject  = [...tmpLessonState.sections[payload.TopicIndex].contents];   
      newObject = [...tmpObject, payload.Item];
      tmpLessonState.sections[payload.TopicIndex].contents = newObject;
    }
    if (payload.Type === "LINKS")
    {
      tmpObject  = [...[...tmpLessonState.sections[payload.TopicIndex].contents].filter(item => item.id === payload.LessonIndex)[0].links];
      newObject = [...tmpObject, payload.Item];
      // Check content type
      var contentType = "";
      newObject.map((item) => {
         if (contentType === item.type || contentType === "")
             contentType = item.type;
         else
             contentType = "mixed"   
      });
      [...tmpLessonState.sections[payload.TopicIndex].contents].filter(item => item.id === payload.LessonIndex)[0].type = contentType;
      [...tmpLessonState.sections[payload.TopicIndex].contents].filter(item => item.id === payload.LessonIndex)[0].links = newObject;
    }  
    if (payload.Type === "QUIZ")
    {
      tmpObject  = [...tmpLessonState.sections[payload.TopicIndex].contents];   
      newObject = [...tmpObject, payload.Item];
      tmpLessonState.sections[payload.TopicIndex].contents = newObject;
    }          




  return tmpLessonState;
}


const edit_title = (state, payload) =>
{
   const tmpLessonState = state;
   var tmpObject = [];
    if (payload.Type === "TOPIC")
    {
     tmpLessonState.sections[payload.TopicIndex].title = payload.title;
    }
    if (payload.Type === "LESSON")
    {
      tmpObject  = [...tmpLessonState.sections[payload.TopicIndex].contents];  
      tmpObject.filter(item => item.id === payload.LessonIndex)[0].title = payload.title; 
      tmpObject.filter(item => item.id === payload.LessonIndex)[0].effectivedate = payload.effectivedate; 
      tmpLessonState.sections[payload.TopicIndex].contents = tmpObject;
    }     
    if (payload.Type === "QUIZ")
    {
      tmpObject  = [...tmpLessonState.sections[payload.TopicIndex].contents];  
      tmpObject.filter(item => item.id === payload.LessonIndex)[0].title = payload.title; 
      tmpObject.filter(item => item.id === payload.LessonIndex)[0].effectivedate = payload.effectivedate; 
      tmpObject.filter(item => item.id === payload.LessonIndex)[0].otherdesc = payload.otherdesc; 
      tmpObject.filter(item => item.id === payload.LessonIndex)[0].otherid = payload.otherid; 
      tmpObject.filter(item => item.id === payload.LessonIndex)[0].otherid2 = payload.otherid2; 
      tmpObject.filter(item => item.id === payload.LessonIndex)[0].q_type = payload.q_type; 
      tmpLessonState.sections[payload.TopicIndex].contents = tmpObject;
    }       
    if (payload.Type === "QUIZ-DATE")
    {
      tmpObject  = [...tmpLessonState.sections[payload.TopicIndex].contents];  
      tmpObject.filter(item => item.id === payload.LessonIndex)[0].effectivedate = payload.effectivedate; 
      tmpLessonState.sections[payload.TopicIndex].contents = tmpObject;
    }          

  return tmpLessonState;
}

const set_lesson = (state, payload) =>
{
  const tmpLessonState = payload;
  
  return tmpLessonState;
  
}

const lessonReducer = (state = SubjectModelBlank, action) => {

    switch(action.type){
        case 'UPDATE-LINK-ORDER':
            return update_link_order(state,action.payload)         
        case 'UPDATE-LESSON-ORDER':
            return update_lesson_order(state,action.payload)
        case 'UPDATE-TOPIC-ORDER':
            return update_topic_order(state,action.payload)      
        case 'DELETE-ITEM':
          return delete_item(state,action.payload)  
        case 'ADD-ITEM':
          return add_item(state,action.payload)              
        case 'EDIT-TITLE':
          return edit_title(state,action.payload) 
        case 'SET-LESSON':
            return set_lesson(state,action.payload);
        default:
            return state
    }
}
export default lessonReducer