import React, { useState } from 'react';
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "./wysiwyg.scss";

export default function Wysiwyg(props) {

   const [editorValue, setEditorValue] = useState('');

   const Quill = ReactQuill.Quill;

   var Font = Quill.import("formats/font");
   Font.whitelist = ["Inter", "Italianno", "Monoton", "Pacifico", "Righteous", "Roboto", "Sevillana"];
   Quill.register(Font, true);

   var Size = Quill.import('attributors/style/size');
   Size.whitelist = ['14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'];
   Quill.register(Size, true);

   var modules = {
      toolbar: [
         { header: "1" },
         { header: "2" },
         // { size: ['small', false, 'large', 'huge'] },
         { 'font': Font.whitelist },
         { 'size': Size.whitelist },
         "bold", "italic", "underline", "strike", "blockquote",
         { color: [] }, { background: [] },
         { align: [] },
         { list: "ordered" },
         { list: "bullet" },
         { indent: "-1" },
         { indent: "+1" },
         'link', 'image', 'video'
         // [{ header: "1" }, { header: "2" }],
         // [{size: ['small', false, 'large', 'huge']}],
         // [{ 'font': Font.whitelist }],
         // ["bold", "italic", "underline", "strike", "blockquote"],
         // [{ color: [] }, { background: [] }],
         // [
         // { align: [] },
         // { list: "ordered" },
         // { list: "bullet" },
         // { indent: "-1" },
         // { indent: "+1" },
         // ],
         // ['link', 'image', 'video'],
         // // [{ script: "sub" }, { script: "super" }, 'formula',],
         // // ['clean']
      ],
      clipboard: {
         // toggle to add extra line breaks when pasting HTML:
         matchVisual: false,
      }
   };

   var noToolbar = {
      toolbar: false,
      clipboard: {
         // toggle to add extra line breaks when pasting HTML:
         matchVisual: false,
      }
   }

   var formats = [
      "header",
      "size",
      "font",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "background",
      //  "code",
      "align",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "video",
      "formula",
      "script",
      "color",
      "clean"
   ];

   const handleUpdateContent = () => {
      props.changeContentCallback(editorValue);
   }

   const handleChangeContent = (value) => {
      // props.changeContentCallback(value);
      setEditorValue(value);
   }
   return (
      <div style={{ height: props.height, background: "white", borderRadius: "7px", border: "1px solid #ccc" }} onBlur={handleUpdateContent}>
         <ReactQuill
            id={'brainee' + props.id}
            key={'brainee' + props.id}
            style={{
               width: "100%",
               //   backgroundSize: "auto 100%",
               //   backgroundRepeat: "no-repeat",
               maxHeight: props.disabled ? props.height - 10 : props.contentHeight,
               height: props.disabled ? props.height : props.contentHeight,
               // overflow: "auto",
               backgroundColor: "white",
               borderRadius: "7px",
            }}
            theme='snow'
            //  value={props.content}
            defaultValue={props.content}
            onChange={handleChangeContent}
            // onBlur={(range, source, quill) => {
            //    handleChangeContent(quill.getHTML());
            //   }}
            modules={props.disabled ? noToolbar : modules}
            formats={formats}
            readOnly={props.disabled}
         />
      </div>
   );
}