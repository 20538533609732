import React, { useContext, useRef } from "react"

import {Circle, Star, Triangle,Square, 
  Shape1,
  Shape2, 
  Shape3, 
  Shape4, 
  Shape5,
  Shape6,
  Shape7,
  Shape8,
  Shape9,
  Shape10,
  Shape11,
  Shape12,
  Shape13,
  Shape14,
  Shape15,
  Shape16,
  Shape17,
  Shape18,
  Shape19,
  Shape20,
} from '../../LessonComponents/Shape'


const ShapeMap = {
  circle: Circle,
  star: Star,
  triangle: Triangle,
  square: Square,
  shape1 : Shape1,
  shape2 : Shape2,
  shape3 : Shape3,
  shape4 : Shape4,
  shape5 : Shape5,
  shape6 : Shape6,
  shape7 : Shape7,
  shape8 : Shape8,
  shape9 : Shape9,
  shape10 : Shape10,
  shape11 : Shape11,
  shape12 : Shape12,
  shape13 : Shape13,
  shape14 : Shape14,
  shape15 : Shape15,
  shape16 : Shape16,
  shape17 : Shape17,
  shape18 : Shape18,
  shape19 : Shape19,
  shape20 : Shape20, 
}

const ObjectElement = props => {
  const { metadata, id, position, dimension, type, backgroundcolor  } = props
  const elementRef = React.useRef(null)
  const [showGrids, setShowGrids] = React.useState(false)

  const renderShape = () =>
  {
    return(
  <div 
   ref={elementRef}                   
   style={{ position : "absolute", 
            left: position?.left || 0, 
            top: position?.top || 0,
            width: dimension?.width || 0, 
            height: dimension?.height || 0,    
            transform : dimension?.transform || "translate(1px, 1px)",              
            fontFamily: "Arial",
            fontSize: "13px",
            padding: 0,
            float : "left",
            border: `2px solid ${
              showGrids 
                ? "#21DEE5"
                : "transparent"
            }`,                            
          }}>  
        {getShapeType()}
     </div>
    )     
  }

  const getShapeType = () => {
    const Component = type && ShapeMap[metadata?.content]
    if (!Component || !id) return null
    return (
      <Component backgroundColor={metadata.backgroundcolor}/>
    )
  }

  return (renderShape())
}

export default ObjectElement
