import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { Descriptions, message, Typography, Select, Spin, Breadcrumb } from 'antd';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import './Assessment.css';
import './Answer.css';
// import QuestionCard from '../../components/assessment/QuestionCard';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import Split from 'react-split';
import Utils from '../../misc/Utils';
// import * as AntDIcons from '@ant-design/icons';
import moment from 'moment';

const { Text, Paragraph } = Typography;
const { Option } = Select

let ansIdx = 0;
let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let downloadData = '';
let essayIdx = -1;

export function TeacherFormativeViewManual(props) {
   const location = useLocation();
   const navigate = useNavigate();

   const [id, setId] = useState(location.state.id);
   const assignInfo = location.state.assignInfo;
   const assessmentId = location.state.assessment_id;
   const studentId = location.state.student_id;
   const studentName = location.state.student_name;

   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: "",
      Term: "",
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: "",
      Level: "",
      Subject: "",
      Level_desc: "",
      Subject_desc: ""
   });

   const [answerInfo, setAnswerInfo] = useState({
      Id: "",
      Answers: "",
      Score: "",
      Total_points: "",
      Start_date: "",
      End_date: ""
   });

   useEffect(() => {
      Utils.verifyJwt();

      essayIdx = -1;

      getAssessmentInfo(assessmentId);
   }, [location.state.assessment_id]);

   const getAssessmentInfo = (id) => {
      ansIdx = 0;

      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/info?id=${id}&at=formative`).then((response) => {
         if (response.data) {
            const data = response.data;
            setAssInfo({
               Id: data.id,
               Title: data.title,
               Term: data.term,
               File_url: data.file_url,
               Question_type: data.question_type,
               Created_by: data.created_by,
               Date_created: data.date_created,
               Question_sheet: JSON.parse(data.question_sheet),
               Level: data.level,
               Subject: data.subject,
               Level_desc: data.level_desc,
               Subject_desc: data.subject_desc
            });

            getAnswerInfo(assessmentId, assignInfo.id, studentId);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getAnswerInfo = (assessment_id, assignment_id, student_id) => {
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/answerinfo2?assessment_id=${assessment_id}&assignment_id=${assignment_id}&student_id=${student_id}&at=formative`).then((response) => {
         if (response.data) {
            const data = response.data;
            var answers = JSON.parse(data.answer);

            if (answers[0].idx !== undefined) {
               answers.sort((a, b) => {
                  return a.idx - b.idx;
               });
            }
            // setAnswers(JSON.parse(data.answer));
            setAnswerInfo({
               Id: data.id,
               Answers: answers,
               Score: data.score,
               Total_points: data.total_points,
               Start_date: moment(data.start_date).format("MMM DD, YYYY hh:mm a"),
               End_date: moment(data.end_date).format("MMM DD, YYYY hh:mm a"),
               Essay_score: data.essay_score,
               Essay_score_multi: data.essay_score_multi !== "" && data.essay_score_multi !== null ? JSON.parse(data.essay_score_multi) : [],
               Essay_multi_comments: data.essay_multi_comments !== "" && data.essay_multi_comments !== null ? JSON.parse(data.essay_multi_comments) : [],
            })
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleCardRequest = () => {

   }

   const handleConsiderAnswer = (question_key_id, answer_to_consider) => {
      const key = 'updatable';
      message.loading("Updating correct answers...", key);

      var data = {
         Assessment_id: assessmentId,
         Question_id: question_key_id,
         Answer_to_consider: answer_to_consider[0],
         Assignment_id: assignInfo.id,
         Student_id: studentId
      }

      axios.post(`${process.env.REACT_APP_API_ASSESSMENT}/consideranswer?at=formative`, data).then((response) => {
         console.log(response);

         //if (response.data) {
         const data = response.data;
         getAnswerInfo(assessmentId, assignInfo.id, studentId);
         message.success({ content: "Update complete!", key, duration: 1 });
         //}
      }, (error) => {
         console.log(error.message);
      });
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/formativeassessment', { state: { activetab: "1" } })}>Formative Assessments</a></li>
                           <li><span>/</span></li>
                           <li className="active">View Answer</li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              {/* <AntDIcons.HomeOutlined /> */}
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <Link to="/formativeassessment" state={{ activetab: '1' }}>Formative Assessments</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <Link to="/formativeassessmenttracker" state={{ assign_id: assignInfo.id, origin }}>Tracker</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>View Answer</Breadcrumb.Item>
                        </Breadcrumb>
                     </div>
                     {answerInfo.Answers.length
                        ?
                        <div className='answer-sheet-content2'>
                           <SimpleBarReact>
                              <ul className="questions">
                                 {assInfo.Question_sheet.map(({ id, type, points, correct, option_labels, question, image_url, template, question_image, question_audio, question_video }, idx) => {

                                    if (type == "section") {
                                       qcardIdx = 1;
                                       idxCol.push(sectionIdx);
                                       sectionIdx++;
                                    } else {
                                       idxCol.push(qcardIdx);
                                       qcardIdx++;
                                    }

                                    //-- Check if answer and correct are matched 
                                    var matched = false;

                                    try {
                                       if (type !== "long_answer" && type !== "section") {
                                          if (type === "short_answer") {
                                             for (var i = 0; i < correct.length; i++) {
                                                //-- Remove all white spaces before comparing
                                                var tmp_correct = correct[i].replace(/\s/g, '');
                                                var tmp_answer = answerInfo.Answers[idx].answer[0].replace(/\s/g, '');

                                                // if (correct[i].toLowerCase().trim() === answerInfo.Answers[idx].answer[0].toLowerCase().trim()) {
                                                if (tmp_correct.toLowerCase() === tmp_answer.toLowerCase()) {
                                                   matched = true;
                                                   break;
                                                }
                                             }
                                          } else {
                                             var cntMatched = 0;
                                             for (var i = 0; i < correct.length; i++) {
                                                if (correct[i] === answerInfo.Answers[idx].answer[i]) {
                                                   cntMatched++;
                                                }
                                             }

                                             if (cntMatched === correct.length)
                                                matched = true;
                                          }

                                          //-- Generate student answers for download --
                                          // if (idx === 0) {
                                          //    downloadData = '';
                                          // }

                                          if (downloadData === '') {
                                             downloadData += 'Student Name: ' + studentName + '\n';
                                             downloadData += 'Assessment Title: ' + assInfo.Title + '\n\n';
                                             downloadData += "Answers \n\n"
                                          }

                                          var tmp = '';

                                          if (type === "short_answer") {
                                             downloadData += idxCol[idx] + '. ' + question.replace(/(&nbsp;|<([^>]+)>)/ig, "") + '\n';
                                             downloadData += 'Answer: ' + answerInfo.Answers[idx].answer[0].toLowerCase().trim() + '\n';

                                             if (!matched) {
                                                for (var i = 0; i < correct.length; i++) {
                                                   if (tmp !== '')
                                                      tmp += ' or ';

                                                   tmp += correct[i].toLowerCase().trim();
                                                }

                                                downloadData += 'Correct Answer: ' + tmp + ' \n\n';
                                             } else {
                                                downloadData += '\n';
                                             }
                                          } else {
                                             downloadData += idxCol[idx] + '. ' + question.replace(/(&nbsp;|<([^>]+)>)/ig, "") + '\n';
                                             downloadData += 'Answer: ';

                                             for (var i = 0; i < correct.length; i++) {
                                                if (answerInfo.Answers[idx].answer[i] !== '0') {
                                                   tmp += option_labels[i] + '\n';
                                                   break;
                                                }
                                             }

                                             if (tmp === '')
                                                tmp += '\n';

                                             downloadData += tmp;

                                             if (!matched) {
                                                for (var i = 0; i < correct.length; i++) {
                                                   if (correct[i] === '1') {
                                                      downloadData += 'Correct Answer: ' + option_labels[i] + '\n\n';
                                                      break;
                                                   }
                                                }
                                             } else {
                                                downloadData += '\n';
                                             }
                                          }
                                          //----------------------------------------------------
                                       }

                                       if (type === "long_answer") {
                                          essayIdx++;
                                       }

                                       return (
                                          <li>
                                             <BraineeQuestionCard
                                                key={id}
                                                question_key_id={id}
                                                index={idxCol[idx]}
                                                id={idx}
                                                type={type}
                                                points={points}
                                                correct={correct}
                                                answers={answerInfo.Answers[idx].answer}
                                                option_labels={option_labels}
                                                question={question}
                                                image_url={image_url}
                                                question_type={'2'}
                                                mode={'teacher-view'}
                                                matched={matched}
                                                considerAnswerCallback={handleConsiderAnswer}
                                                template={template}
                                                question_image={question_image}
                                                question_audio={question_audio}
                                                question_video={question_video}
                                                assessment_type={'formative'}
                                                essay_score={answerInfo.Essay_score_multi[essayIdx]}
                                                essay_comment={answerInfo.Essay_multi_comments[essayIdx]}
                                             />
                                             {/* <QuestionCard
                                                key={id}
                                                question_key_id={id}
                                                index={idxCol[idx]}
                                                id={idx}
                                                type={type}
                                                points={points}
                                                correct={correct}
                                                answers={answerInfo.Answers[idx].answer}
                                                option_labels={option_labels}
                                                question={question}
                                                image_url={image_url}
                                                question_type={'2'}
                                                mode={'teacher-view'}
                                                matched={matched}
                                                considerAnswerCallback={handleConsiderAnswer} /> */}
                                          </li>
                                       );
                                    }
                                    catch (ex) {
                                       console.log(ex);
                                       return (<></>);
                                    }


                                 })}
                              </ul>
                           </SimpleBarReact>
                        </div>
                        :
                        <div className='temp-answer-sheet-content2'>
                           <Spin size='large' tip="Loading, please wait..." />
                        </div>
                     }
                  </div>
               </div>
               <div>
                  <div className="notice-item">
                     {answerInfo.Answers.length > 0
                        ?
                        <div className='answer-sheet-content'>
                           <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", width: "100%" }} column={4}>
                              <Descriptions.Item label={"Student Name"} span={4} style={{ textAlign: "center" }} >
                                 <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{studentName}</b></Paragraph>
                              </Descriptions.Item>
                              <Descriptions.Item label={"Title"} span={4} style={{ textAlign: "center" }} >
                                 <Paragraph style={{ textAlign: "center", width: "100%" }}>{assInfo.Title}</Paragraph>
                              </Descriptions.Item>
                              <Descriptions.Item label={"Score"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                 <Paragraph style={{ textAlign: "center", width: "100%" }}>{(answerInfo.Score + answerInfo.Essay_score) + " of " + answerInfo.Total_points}</Paragraph>
                              </Descriptions.Item>
                              <Descriptions.Item label={"Duration"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                 <Paragraph style={{ textAlign: "center", width: "100%" }}>{Math.floor((Math.abs(new Date(answerInfo.End_date) - new Date(answerInfo.Start_date)) / 1000 / 60))} min(s)</Paragraph>
                              </Descriptions.Item>
                              <Descriptions.Item label={"Date"} span={4} style={{ textAlign: "center", width: "50%" }}>
                                 <Paragraph style={{ textAlign: "center", width: "100%" }}>{answerInfo.Start_date + " - " + answerInfo.End_date}</Paragraph>
                              </Descriptions.Item>
                           </Descriptions>
                        </div>
                        :
                        <div className='temp-answer-sheet-content2'>
                           <Spin size='large' tip="Loading, please wait..." />
                        </div>
                     }
                  </div>
               </div>
            </Split>
         </div>
      </>
   )
}
