import axios from 'axios';
import jwtEncode from 'jwt-encode';
import qs from 'qs';

const SendRequest = (data, zoom_email) => {
   let zoom_api_url = 'https://api.zoom.us/v2/users/' + zoom_email + '/meetings';

   const headers = [
      'authorization: Bearer ' + GenerateJWTKey(),
      'content-type: application/json',
   ];

   let postFields = data;

   axios.get(zoom_api_url, postFields, {headers: headers}).then((response) => {
      if (response.data) {
         var data = response.data;
         console.log(data);
      }
   }, (error) => {
      console.log(error.message);
   });
}

const GenerateJWTKey = () => {
   let key = `${process.env.REACT_APP_ZOOM_API_KEY}`;
   let secret = `${process.env.REACT_APP_ZOOM_API_SECRET}`;
   let time = new Date().getTime() + 3600;

   let payload = {
      "iss": key,
      "exp": time,
   };

   return jwtEncode(payload, secret);
}

const CreateMeeting = (data) => {
   let zoom_account = "erwin.m.nora@gmail.com";
   let zoom_api_url = 'https://api.zoom.us/v2/users/' + zoom_account + '/meetings';
   let startTime = new Date().getTime();

   const options = {
      "topic": data.title,
      "type": 2,
      "start_time": startTime,
      "duration": 60,
      "timezone": "Asia/Manila",
      "password": "cloudph",
      "agenda": "",
      "schedule_for": zoom_account,
      "settings": {
         "join_before_host": false,
         "host_video": true,
         "participant_video": true,
         "mute_upon_entry": false,
         "enforce_login": false,
         "auto_recording": "none",
         "alternative_hosts": "",
      }
   }
  
   // let response = SendRequest(params, zoom_account);
   // return response;

   let token = 'eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOm51bGwsImlzcyI6IkJLelJ5UDhqU2Ntemd1NzhIUjM2OGciLCJleHAiOjE2NjI2NTYwNzUsImlhdCI6MTY2MjA1MTI3NX0.pnPnEQ3ze95DbzKJ-anG6ATD3GTLzCAmbMOmw-5jtNY';
   token = GenerateJWTKey();

   const headers = {
      'Authorization': 'bearer ' + GenerateJWTKey(),
      'User-Agent': 'zoom-api-jwt-request',
      'Content-Type': 'application/json',
   };

   // let strOptions = qs.stringify(options);

   axios.post(zoom_api_url, options, {headers: headers}).then((response) => {
      if (response.data) {
         var data = response.data;
         console.log(data);
      }
   }, (error) => {
      console.log(error.message);
   });
}

export default {
   CreateMeeting,
   GenerateJWTKey,
};