import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Typography } from 'antd';
import * as AntDIcons from '@ant-design/icons';
import { Tooltip, Popconfirm } from 'antd';
// import EllipsisText from "react-ellipsis-text";
import { useSelector, useDispatch } from 'react-redux'
import { CloseCircleTwoTone, CompassTwoTone, VideoCameraTwoTone, PictureTwoTone, GoogleOutlined, CloudTwoTone } from '@ant-design/icons';
import { update_links_order, delete_item } from '../../actions';
import "./DisplayLessonLinks.scss"


export function DisplayLessonLinks(props) {
   const [items, setItems] = React.useState([]);

   var lessonsIdx = props.lessonsIdx;
   var TopicIdx = props.topicIdx;

   const lessonState = useSelector(state => state.lesson)
   const dispatch = useDispatch();
   const { Text, Paragraph } = Typography;

   useEffect(() => {
      setItems(props.lessonLinks)
   }, [props.lessonLinks]);

   const _onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
         return;
      }

      var params = { TopicIndex: TopicIdx, LessonIndex: lessonsIdx, SourceIdx: result.source.index, DestinationIdx: result.destination.index }
      dispatch(update_links_order(params));
      var newItems = [...[...lessonState.sections[TopicIdx].contents].filter(item => item.id === lessonsIdx)[0].links];
      setItems(newItems)
      props.saveLesson()
   }


   const getItemStyle = (isDragging, draggableStyle) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      display: "flex",
      paddingLeft: '5px',
      //border: "solid 1px",
      // styles we need to apply on draggables
      ...draggableStyle
   });

   const getImageSource = (item) => {
      switch (item.type) {
         case "youtube": case "videolink": case "search": case "file":
            return item.image;
         case "image":
            return item.url;
         default:
            return item.image;
      }
   }

   const handleDeleteRequest = (id) => {
      var params = { Type: "LINKS", TopicIndex: TopicIdx, LessonIndex: lessonsIdx, SourceIdx: id }
      dispatch(delete_item(params));
      var newItems = [...[...lessonState.sections[TopicIdx].contents].filter(item => item.id === lessonsIdx)[0].links];
      setItems(newItems)
      props.saveLesson()
   }

   return <>
      <div style={{ marginTop: "5px", marginBottom: "5px" }} ><h8>My Resources</h8></div>
      <div style={{
         overflowX: "auto", marginTop: "1px", paddingBottom: "5px",
         borderRadius: "7px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", minHeight: '130px'
      }}>
         <DragDropContext onDragEnd={_onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
               {(provided, snapshot) => (
                  <div
                     {...provided.droppableProps}
                     ref={provided.innerRef}
                     style={{ display: "flex", marginTop: "5px" }}
                  >
                     {items.map((item, index) => (
                        <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                           {(provided, snapshot) => (
                              <div style={{ margin: "10px" }}>
                                 <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}

                                    style={getItemStyle(
                                       snapshot.isDragging,
                                       provided.draggableProps.style
                                    )}
                                 >
                                    <div className='item-container'  {...provided.dragHandleProps}>
                                       <Tooltip placement="bottom" title={item.title}>
                                          <div  >
                                             <img src={getImageSource(item)} style={{ height: "70px", borderTopLeftRadius: "7px", borderTopRightRadius: '7px' }} />
                                          </div>
                                          <div className='item-title'><Text ellipsis>{item.title}</Text></div>
                                          <div style={{ position: "absolute", left: "60px", top: "3px" }}>
                                             <Popconfirm
                                                title="Remove this item?"
                                                onConfirm={() => handleDeleteRequest(item.id)}
                                                okText="Yes"
                                                cancelText="No">
                                                <CloseCircleTwoTone twoToneColor={"#70D0D4"} style={{ fontSize: '120%' }} />
                                             </Popconfirm>
                                          </div>
                                       </Tooltip>
                                    </div>

                                 </div>
                              </div>
                           )}
                        </Draggable>
                     ))}
                     {provided.placeholder}
                  </div>
               )}
            </Droppable>
         </DragDropContext>
      </div>
   </>
}