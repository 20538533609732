import React, { useState } from 'react';
import { Drawer, Space, Button } from 'antd';
import SimpleBarReact from "simplebar-react";
import NoticeBoard from '../../components/notice-board/NoticeBoard';
import uuid from 'react-uuid';

function NoticeBoardSlide(props) {

   const [visible, setVisible] = useState(false);

   const handleClick = () => {
      setVisible(true);
   }

   const onDrawerClose = () => {
      setVisible(!visible);
   }

   return (
      <>
         {
            visible === false &&
            // <div className="chat-gpt-floating-button"
            //    onClick={() => handleClick()}
            // />
            <div className="notice-board-floating-button"
               onClick={() => handleClick()}
            >
               Notice Board
            </div>
         }

         <Drawer
            title="Notice Board"
            placement="right"
            size={"default"}
            // width={250}
            closable={false}
            onClose={onDrawerClose}
            open={visible}
            zIndex={1001}
            extra={
               <Space>
                  <Button type='primary' shape='round' onClick={onDrawerClose}>Close</Button>
               </Space>
            }
         >
            {visible && <NoticeBoard uid={uuid()} />}
         </Drawer>
      </>
   );
}

export default NoticeBoardSlide;