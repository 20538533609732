import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
   Input, Tabs, Table, Typography, Space, Tooltip, Button,
   Badge, Row, Col, Tag, Breadcrumb
} from 'antd';
import { SearchOutlined, FormOutlined, EyeOutlined } from '@ant-design/icons';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import Highlighter from 'react-highlight-words';
import axios from 'axios';

import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { useDispatch } from 'react-redux';
import { reset_mastery_data, set_mastery_assignment, reset_mastery_assignment_data } from '../../actions';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';

const { TabPane } = Tabs;
const { Text } = Typography;

export function MasteryStudentAssessment() {
   const navigate = useNavigate();
   const location = useLocation();
   const dispatch = useDispatch();

   const aTab = location.state !== null ? location.state.activetab : "1";

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   const [currentList, setCurrentList] = useState([]);
   const [upcomingList, setUpcomingList] = useState([]);
   const [pastList, setPastList] = useState([]);
   const [activeTab, setActiveTab] = useState(aTab);
   const [listLoading, setListLoading] = useState(true);
   const [assignedSubjects, setAssignedSubjects] = useState(null);
   const [subject, setSubject] = useState(0);
   // const [masteryList, setMasteryList] = useState([]);

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);

   useEffect(() => {
      Utils.verifyJwt();

      // const unloadCallback = (event) => {
      // //   event.preventDefault();
      // //   event.returnValue = "";
      // // localStorage.clear();
      //   return "";
      // };

      // window.addEventListener("beforeunload", unloadCallback);
      // return () => window.removeEventListener("beforeunload", unloadCallback);

      // axios.get(`${process.env.REACT_APP_API_STUDENT_MASTERY_ASSESSMENT}/masterysubjectassignments?student_id=${user_id}&time_tense=1`).then((response) => {
      //    console.log(response);

      //    if (response.data) {
      //       const data = response.data;

      //       setAssignedSubjects(data);
      //    }
      // }, (error) => {
      //    console.log(error.message);
      // });

      getSubjectCounts(user_id, aTab);

      setActiveTab(() => {
         // refreshList(aTab);
         getMasteryList(user_id, 0, aTab);
         return aTab
      });
   }, []);

   const getSubjectCounts = (student_id, time_tense) => {
      axios.get(`${process.env.REACT_APP_API_STUDENT_MASTERY_ASSESSMENT}/masterysubjectassignments?student_id=${student_id}&time_tense=${time_tense}`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;

            setAssignedSubjects(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
               </Button>
               <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const assigned_ass_columns = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: 'Allow',
         key: 'allow_result_viewing',
         dataIndex: 'allow_result_viewing',
         hidden: true,
      },

      {
         title: 'Assessment Id',
         key: 'mastery_assessment_id',
         dataIndex: 'mastery_assessment_id',
         hidden: true,
      },
      {
         title: 'Start Date',
         key: 'start_date',
         dataIndex: 'start_date',
         hidden: true,
      },
      {
         title: 'End Date',
         key: 'end_date',
         dataIndex: 'end_date',
         hidden: true,
      },
      {
         title: 'Term',
         dataIndex: 'term',
         key: 'term',
         width: '9%'
      },
      {
         title: 'Subject',
         key: 'subject_desc',
         ...getColumnSearchProps('subject_desc'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.subject_desc}>
               <Text>{object.subject_desc}</Text>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Code',
         key: 'competency',
         ...getColumnSearchProps('competency'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.competency}>
               <Text>{object.competency}</Text>
            </Tooltip>
         ),
         width: '7%'
      },
      {
         title: 'Competency Title',
         key: 'title',
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft"
               title={
                  <>
                     <b>{object.title}</b>
                     <p>{object.start_date + ' - ' + object.end_date}</p>
                  </>
               }
            >
               <div style={{ width: "100%", cursor: "default" }}>
                  <b><Text>{object.title}</Text></b>
                  <p style={{ marginBottom: '0px' }}>{object.start_date + ' - ' + object.end_date}</p>
               </div>
            </Tooltip>
         ),
         width: '28%'
      },

      {
         title: 'Type',
         dataIndex: 'assessment_type',
         key: 'assessment_type',
         width: '7%'
      },
      {
         title: 'Assigned By',
         key: 'assigned_by',
         ...getColumnSearchProps('assigned_by'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.assigned_by}>
               <Text>{object.assigned_by}</Text>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Status',
         key: 'status',
         ...getColumnSearchProps('status'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <>
               {/* {object.status === 'Completed' || object.status === 'Incomplete' &&
               // <Tag color={object.status === 'Completed' ? "green" : "red"}>{object.status}</Tag>
               <Tag>{object.status}</Tag>
            } */}
               {object.status === 'Completed'
                  ?
                  <Tag color={"green"}>{object.status}</Tag>
                  :
                  <>
                     {object.status === 'Not Started'
                        ?
                        <Tag color={"red"}>{object.status}</Tag>
                        :
                        <Tag color={"gold"}>{object.status}</Tag>
                     }
                  </>
               }
            </>
         ),
         width: '10%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">

                  {activeTab === '1' &&
                     <>
                        {payload.status === 'Incomplete' || payload.status === 'Not Started'
                           ?
                           <Tooltip placement="top" title='Take Mastery'>
                              <FormOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleShowMastery(payload.id, payload.mastery_assessment_id)} />
                           </Tooltip>
                           :
                           <Tooltip placement="top" title='View Mastery Result'>
                              <EyeOutlined
                                 color='#4abec7'
                                 style={{ cursor: 'pointer' }}
                                 onClick={() => handleShowMastery(payload.id, payload.mastery_assessment_id)}
                              />
                           </Tooltip>
                        }
                     </>
                  }
                  {activeTab === '3' &&
                     <Tooltip
                        placement="top"
                        title='View Mastery Result'
                     >
                        <EyeOutlined
                           color='#4abec7'
                           style={{ cursor: 'pointer' }}
                           // onClick={() => handleViewAnswer(payload.id, payload.mastery_assessment_id, payload.question_type, activeTab, payload.attempts, payload.allow_result_viewing)}
                           onClick={() => payload.status === "Completed" ? handleShowMastery(payload.id, payload.mastery_assessment_id) : handleViewAnswer(payload.id, payload.mastery_assessment_id, payload.question_type, activeTab, payload.attempts, payload.allow_result_viewing)}
                        />
                     </Tooltip>
                  }
               </Space>
            </>
         },
         width: '6%'
      },
   ].filter(item => !item.hidden);

   const assigned_ass_columns_upcoming = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: 'Allow',
         key: 'allow_result_viewing',
         dataIndex: 'allow_result_viewing',
         hidden: true,
      },

      {
         title: 'Assessment Id',
         key: 'mastery_assessment_id',
         dataIndex: 'mastery_assessment_id',
         hidden: true,
      },
      {
         title: 'Start Date',
         key: 'start_date',
         dataIndex: 'start_date',
         hidden: true,
      },
      {
         title: 'End Date',
         key: 'end_date',
         dataIndex: 'end_date',
         hidden: true,
      },
      {
         title: 'Term',
         dataIndex: 'term',
         key: 'term',
         width: '9%'
      },
      {
         title: 'Subject',
         key: 'subject_desc',
         ...getColumnSearchProps('subject_desc'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.subject_desc}>
               <Text>{object.subject_desc}</Text>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Code',
         key: 'competency',
         ...getColumnSearchProps('competency'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.competency}>
               <Text>{object.competency}</Text>
            </Tooltip>
         ),
         width: '7%'
      },
      {
         title: 'Competency Title',
         key: 'title',
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft"
               title={
                  <>
                     <b>{object.title}</b>
                     <p>{object.start_date + ' - ' + object.end_date}</p>
                  </>
               }
            >
               <div style={{ width: "100%", cursor: "default" }}>
                  <b><Text>{object.title}</Text></b>
                  <p style={{ marginBottom: '0px' }}>{object.start_date + ' - ' + object.end_date}</p>
               </div>
            </Tooltip>
         ),
         width: '44%'
      },

      {
         title: 'Type',
         dataIndex: 'assessment_type',
         key: 'assessment_type',
         width: '7%'
      },
      {
         title: 'Assigned By',
         key: 'assigned_by',
         ...getColumnSearchProps('assigned_by'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.assigned_by}>
               <Text>{object.assigned_by}</Text>
            </Tooltip>
         ),
         width: '12%'
      },
   ].filter(item => !item.hidden);

   const getMasteryList = (student_id, subject_id, time_tense) => {
      setListLoading(true);
      axios.get(`${process.env.REACT_APP_API_STUDENT_MASTERY_ASSESSMENT}/listmastery?student_id=${student_id}&subject_id=${subject_id}&time_tense=${time_tense}`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;

            if (parseInt(time_tense) === 1)
               setCurrentList(data);
            if (parseInt(time_tense) === 2)
               setUpcomingList(data);
            if (parseInt(time_tense) === 3)
               setPastList(data);

            setListLoading(false);
         }
      }, (error) => {
         setListLoading(false);
         console.log(error.message);
      });
   }

   const handleTabChange = (key) => {
      setSubject(0);
      getSubjectCounts(user_id, key);
      getMasteryList(user_id, 0, key);
      setActiveTab(key);
   }

   const handleShowMastery = (assign_id, mastery_id) => {
      var params = { key: ``, value: '' };
      dispatch(reset_mastery_data(params));
      dispatch(reset_mastery_assignment_data(params));

      var assignInfo = null;

      if (activeTab === '1')
         assignInfo = currentList.filter(element => element.id === assign_id)[0];

      if (activeTab === '2')
         assignInfo = upcomingList.filter(element => element.id === assign_id)[0];

      if (activeTab === '3')
         assignInfo = pastList.filter(element => element.id === assign_id)[0];

      dispatch(set_mastery_assignment(assignInfo));

      // navigate('/studentmasteryassessment/preview', {state: {assign_id, mastery_id, assignInfo, origin: activeTab}});
      navigate(`/studentmasteryassessment/preview?assign_id=${assign_id}&mastery_id=${mastery_id}&origin=${activeTab}&qt=${assignInfo.mode}`);
      // navigate(`/studentmasteryassessment/preview?origin=${activeTab}`);
   }

   const handleViewAnswer = (id, assessment_id, q_type, origin, attempts, allow) => {
      var assignInfo = null;

      if (origin === '1')
         assignInfo = currentList.filter(element => element.id === id)[0];
      else
         assignInfo = pastList.filter(element => element.id === id)[0];

      navigate('/studentmasteryassessment/view-result', { state: { assign_id: assignInfo.id, mastery_id: assessment_id, assignInfo, origin: activeTab } });

      // if (allow === 1)
      //    navigate('/studentmasteryassessment/view-result', {state: {assign_id: assignInfo.id, mastery_id: assessment_id, assignInfo}});
      // else {
      //    Modal.error({
      //       content: 'Viewing of exam result is not yet allowed.',
      //       centered: true
      //    });
      // }
   }

   const handleSubjectClick = (subject) => {
      setSubject(subject);
      getMasteryList(user_id, subject, activeTab);
   }

   const RenderSubjectAssignmentCounts = () => {
      var all_subjects_count = 0;

      return <>
         <div style={{ overflow: 'auto' }}>
            <Row style={{ paddingTop: "20px", minWidth: '600px' }} >
               <Col span={24}>
                  <Space size={'middle'}>
                     {/* <Text>Subjects</Text> */}
                     {assignedSubjects.map(({ id, subject, total_counts }, idx) => {
                        all_subjects_count += total_counts;
                        // return <Badge count={ total_counts } style={{ backgroundColor: '#2DB3BD' }} overflowCount={999}>
                        //    <Tag color={"#2DB3BD"} style={{cursor: total_counts > 0 ? 'pointer' : 'default' }}>{ subject }</Tag>
                        // </Badge>
                        return <Badge count={total_counts} style={{ backgroundColor: 'green' }} overflowCount={999}>
                           <Tag
                              color={total_counts > 0 ? "green" : ""}
                              style={{ cursor: total_counts > 0 ? 'pointer' : 'default' }}
                              onClick={() => handleSubjectClick(id)}
                           >
                              {subject}
                           </Tag>
                        </Badge>
                     })}

                     <Badge count={all_subjects_count} style={{ backgroundColor: 'green' }} overflowCount={999}>
                        <Tag
                           color={all_subjects_count > 0 ? "green" : ""}
                           style={{ cursor: all_subjects_count > 0 ? 'pointer' : 'default' }}
                           onClick={() => handleSubjectClick(0)}
                        >
                           {"All"}
                        </Tag>
                     </Badge>
                  </Space>
               </Col>
            </Row>
         </div>
      </>
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li className="active">Mastery Assessments</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>Mastery Assessments</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               <div className="dashboard-item-inner3">
                  {assignedSubjects !== null && RenderSubjectAssignmentCounts()}
                  <Tabs defaultActiveKey={aTab} centered onChange={(key) => handleTabChange(key)}>
                     <TabPane style={{ overflow: "auto" }}
                        tab={(
                           <span>Current</span>
                        )}
                        key="1"
                     >
                        <Table
                           rowKey={'uid'}
                           loading={listLoading}
                           size='small'
                           columns={assigned_ass_columns}
                           dataSource={currentList}
                           pagination={false}
                           scroll={{
                              x: '1000px',
                              y: 'calc(100svh - 14.4rem)',
                           }}
                           footer={() => 'Records: ' + currentList.length}
                        />
                     </TabPane>
                     <TabPane style={{ overflow: "auto" }}
                        tab={(
                           <span>Upcoming</span>
                        )}
                        key="2"
                     >
                        <Table
                           rowKey={'uid'}
                           loading={listLoading}
                           size='small'
                           columns={assigned_ass_columns_upcoming}
                           dataSource={upcomingList}
                           pagination={false}
                           scroll={{
                              x: '1000px',
                              y: 'calc(100svh - 14.4rem)',
                           }}
                           footer={() => 'Records: ' + upcomingList.length}
                        />
                     </TabPane>
                     <TabPane style={{ overflow: "auto" }}
                        tab={(
                           <span>Past</span>
                        )}
                        key="3"
                     >
                        <Table
                           rowKey={'uid'}
                           loading={listLoading}
                           size='small'
                           columns={assigned_ass_columns}
                           dataSource={pastList}
                           pagination={false}
                           scroll={{
                              x: '1000px',
                              y: 'calc(100svh - 14.4rem)',
                           }}
                           footer={() => 'Records: ' + pastList.length}
                        />
                     </TabPane>
                  </Tabs>
               </div>
            </div>
         </div>

         {/* <div className="notice-area" style={{ display: "block" }}>
            <div className="notice-item">
               <img src="../images/brainee_ma.png" width="100%" style={{ padding: "5px", maxWidth: '600px' }}></img>
               <NoticeBoard />
            </div>
         </div> */}
      </>
   );
}