import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Select, Input, Row, Col, Tag,
   Modal, Typography, Spin, Breadcrumb,
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
// import { Levels } from '../../constants';

import { StudentEdit } from './Components/StudentEdit';
import { StudentAdd } from './Components/StudentAdd';
import { StudentImport } from './Components/StudentImport';
import uuid from 'react-uuid'

import * as XLSX from 'xlsx';

const { Option } = Select;
const { Text, Paragraph } = Typography;

var studentID = null;

export function StudentInfo() {
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [levelList, setLevelList] = useState([]);

   // const [studentID, setStudentID] = useState(null);
   const [level, setLevel] = useState(null);
   const [section, setSection] = useState([]);
   const [sectionList, setSectionList] = useState([]);
   const [studentList, setStudentList] = useState([]);
   const [studentCredentialsList, setStudentCredentialsList] = useState([]);
   const [studentForSectionChangeList, setStudentForSectionChangeList] = useState([]);

   const [sectionLoading, setSectionLoading] = useState(false);
   const [searchLoading, setSearchLoading] = useState(false);
   const [credsListLoading, setCredsListLoading] = useState(false);

   const [updating, setUpdating] = useState(false);
   const [editModalVisible, setEditModalVisible] = useState(false);
   const [addModalVisible, setAddModalVisible] = useState(false);
   const [importModalVisible, setImportModalVisible] = useState(false);
   const [credentialsModalVisible, setCredentialsModalVisible] = useState(false);

   const [studentsForSectionChange, setStudentsForSectionChange] = useState(null);
   const [sectionChangeModalVisible, setSectionChangeModalVisible] = useState(false);
   const [addToSectionModalVisible, setAddToSectionModalVisible] = useState(false);

   const [newSection, setNewSection] = useState([]);
   const [newSectionList, setNewSectionList] = useState([]);

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'co-teacher')
         navigate('/home');

      getLevels();
   }, []);

   const getLevels = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/levellist`).then((response) => {
         if (response.data) {
            const data = response.data;
            var tmp_levels = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               tmp_levels.push(obj);
            });

            setLevelList(tmp_levels);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: 'Last Name',
         key: 'last_name',
         // dataIndex: 'last_name',
         sorter: (a, b) => { return a.last_name.localeCompare(b.last_name) },
         ...getColumnSearchProps('last_name'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="right" title={payload.last_name}>
               <span style={{ cursor: "default" }}>
                  {
                     payload.last_name !== null
                        ?
                        payload.last_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </span>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'First Name',
         key: 'first_name',
         // dataIndex: 'first_name',
         sorter: (a, b) => { return a.first_name.localeCompare(b.first_name) },
         ...getColumnSearchProps('firstname'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="right" title={payload.first_name}>
               <span style={{ cursor: "default" }}>
                  {
                     payload.first_name !== null
                        ?
                        payload.first_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </span>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Gender',
         key: 'gender',
         ...getColumnSearchProps('gender'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.gender}>
               <Text>
                  {
                     payload.gender !== null
                        ?
                        payload.gender.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </Text>
            </Tooltip>
         ),
         width: '10%'
      },
      {
         title: 'Date of Birth',
         key: 'date_of_birth',
         dataIndex: 'date_of_birth',
         sorter: (a, b) => { return (a.date_of_birth === null ? '' : a.date_of_birth).localeCompare(b.date_of_birth === null ? '' : b.date_of_birth) },
         width: '15%'
      },
      {
         title: 'Level',
         key: 'level',
         dataIndex: 'level',
         sorter: (a, b) => { return (a.level === null ? '' : a.level).localeCompare(b.level === null ? '' : b.level) },
         width: '15%'
      },
      {
         title: 'Section',
         key: 'section',
         dataIndex: 'section',
         sorter: (a, b) => { return (a.section === null ? '' : a.section).localeCompare(b.section === null ? '' : b.section) },
         width: '15%'
      },
      {
         title: 'Active',
         key: 'is_active',
         dataIndex: 'is_active',
         sorter: (a, b) => { return a.is_active.localeCompare(b.is_active) },
         render: is_active => (
            <Tag style={{ fontSize: 12 }} color={is_active === 'no' ? 'red' : 'green'}>
               {is_active.toUpperCase()}
            </Tag>
         ),
         width: '10%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Edit Profile'>
                     <AntDIcons.EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEdit(payload.id)} />
                  </Tooltip>
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const creds_columns = [
      {
         title: 'Last Name',
         key: 'last_name',
         // dataIndex: 'last_name',
         sorter: (a, b) => { return a.last_name.localeCompare(b.last_name) },
         ...getColumnSearchProps('last_name'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="right" title={payload.last_name}>
               <span style={{ cursor: "default" }}>
                  {
                     payload.last_name !== null
                        ?
                        payload.last_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </span>
            </Tooltip>
         ),
         width: '11%'
      },
      {
         title: 'First Name',
         key: 'first_name',
         // dataIndex: 'first_name',
         sorter: (a, b) => { return a.first_name.localeCompare(b.first_name) },
         ...getColumnSearchProps('firstname'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="right" title={payload.first_name}>
               <span style={{ cursor: "default" }}>
                  {
                     payload.first_name !== null
                        ?
                        payload.first_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </span>
            </Tooltip>
         ),
         width: '14%'
      },
      {
         title: 'Gender',
         key: 'gender',
         ...getColumnSearchProps('gender'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.gender}>
               <Text>
                  {
                     payload.gender !== null
                        ?
                        payload.gender.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </Text>
            </Tooltip>
         ),
         width: '6%'
      },
      {
         title: 'Email',
         key: 'email',
         ellipsis: {
            showTitle: false,
         },
         sorter: (a, b) => { return (a.email === null ? '' : a.email).localeCompare(b.email === null ? '' : b.email) },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.email}>
               <Text>
                  {payload.email}
               </Text>
            </Tooltip>
         ),
         width: '13%'
      },
      {
         title: 'Level',
         key: 'level',
         dataIndex: 'level',
         sorter: (a, b) => { return (a.level === null ? '' : a.level).localeCompare(b.level === null ? '' : b.level) },
         width: '6%'
      },
      {
         title: 'Section',
         key: 'section',
         dataIndex: 'section',
         sorter: (a, b) => { return (a.section === null ? '' : a.section).localeCompare(b.section === null ? '' : b.section) },
         width: '6%'
      },
      {
         title: 'Student Username',
         key: 'student_username',
         dataIndex: 'student_username',
         width: '9%'
      },
      {
         title: 'Student Password',
         key: 'student_password',
         dataIndex: 'student_password',
         width: '9%'
      },
      {
         title: 'Parent Username',
         key: 'parent_username',
         dataIndex: 'parent_username',
         width: '9%'
      },
      {
         title: 'Parent Password',
         key: 'parent_password',
         dataIndex: 'parent_password',
         width: '9%'
      },
      {
         title: 'Zoom Account',
         key: 'zoom_email',
         ellipsis: {
            showTitle: false,
         },
         sorter: (a, b) => { return (a.zoom_email === null ? '' : a.zoom_email).localeCompare(b.zoom_email === null ? '' : b.zoom_email) },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.zoom_email}>
               <Text>
                  {payload.zoom_email}
               </Text>
            </Tooltip>
         ),
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const change_section_columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: 'Last Name',
         key: 'last_name',
         // dataIndex: 'last_name',
         sorter: (a, b) => { return a.last_name.localeCompare(b.last_name) },
         ...getColumnSearchProps('last_name'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="right" title={payload.last_name}>
               <span style={{ cursor: "default" }}>
                  {
                     payload.last_name !== null
                        ?
                        payload.last_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </span>
            </Tooltip>
         ),
         width: '30%'
      },
      {
         title: 'First Name',
         key: 'first_name',
         // dataIndex: 'first_name',
         sorter: (a, b) => { return a.first_name.localeCompare(b.first_name) },
         ...getColumnSearchProps('firstname'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="right" title={payload.first_name}>
               <span style={{ cursor: "default" }}>
                  {
                     payload.first_name !== null
                        ?
                        payload.first_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </span>
            </Tooltip>
         ),
         width: '30%'
      },
      // {
      //    title: 'Gender',
      //    key: 'gender',
      //    ...getColumnSearchProps('gender'),
      //    ellipsis: {
      //       showTitle: false,
      //    },
      //    render: payload => (
      //       <Tooltip placement="topLeft" title={payload.gender}>
      //          <Text>
      //             {
      //                payload.gender !== null
      //                   ?
      //                   payload.gender.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
      //                   :
      //                   ''
      //             }
      //          </Text>
      //       </Tooltip>
      //    ),
      //    width: '10%'
      // },
      // {
      //    title: 'Date of Birth',
      //    key: 'date_of_birth',
      //    dataIndex: 'date_of_birth',
      //    sorter: (a, b) => { return (a.date_of_birth === null ? '' : a.date_of_birth).localeCompare(b.date_of_birth === null ? '' : b.date_of_birth) },
      //    width: '15%'
      // },
      {
         title: 'Level',
         key: 'level',
         dataIndex: 'level',
         sorter: (a, b) => { return (a.level === null ? '' : a.level).localeCompare(b.level === null ? '' : b.level) },
         width: '15%'
      },
      {
         title: 'Section',
         key: 'section',
         dataIndex: 'section',
         sorter: (a, b) => { return (a.section === null ? '' : a.section).localeCompare(b.section === null ? '' : b.section) },
         width: '15%'
      },
      {
         title: 'Active',
         key: 'is_active',
         dataIndex: 'is_active',
         sorter: (a, b) => { return a.is_active.localeCompare(b.is_active) },
         render: is_active => (
            <Tag style={{ fontSize: 12 }} color={is_active === 'no' ? 'red' : 'green'}>
               {is_active.toUpperCase()}
            </Tag>
         ),
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const handleLevelChange = (val) => {
      setSection(null)
      setSectionList([]);
      setNewSectionList([]);
      setLevel(val);
      setSectionLoading(true);

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${val}`).then((response) => {
         if (response.data) {
            const data = response.data;

            var sectionlist = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               sectionlist.push(obj);
            });

            setSectionList(sectionlist);
            setNewSectionList(sectionlist);
            setSectionLoading(false);
         }
      }, (error) => {
         setSectionLoading(false);
         console.log(error.message);
      });
   }

   const handleSectionChange = (val) => {
      setSection(val);
   }

   const handleGetStudentList = () => {
      setSearchLoading(true);
      setStudentList([]);

      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getstudentlist?level=${level}&section=${section}`).then((response) => {
         if (response.data) {
            const data = response.data;

            //--add key to the student list 
            for (var i = 0; i < data.length; i++) {
               data[i].key = data[i].id;
            }

            setStudentList(data);
            setStudentForSectionChangeList(data);
            setSearchLoading(false);
         }
      }, (error) => {
         setSearchLoading(false);
         console.log(error.message);
      });
   }

   const handleAddNewStudent = () => {
      setAddModalVisible(true);
   }

   const handleEdit = (id) => {
      // setStudentID(id);
      studentID = id;
      setEditModalVisible(true);
   }

   const handleSave = () => {
      handleGetStudentList();
      setUpdating(false);
      setEditModalVisible(false);
      setAddModalVisible(false);
   }

   const handleCancel = () => {
      setEditModalVisible(false);
      setAddModalVisible(false);
      setImportModalVisible(false);
      setCredentialsModalVisible(false);
      setSectionChangeModalVisible(false);
      setAddToSectionModalVisible(false);
   }

   const handleUpdating = () => {
      setUpdating(true);
   }

   const handleImportComplete = () => {
      setUpdating(false);
      setImportModalVisible(false);
   }

   const handleExportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(studentCredentialsList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Student Credentials");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "Brainee_Student_Info.xlsx");
   }

   const handleShowCredentials = () => {
      setStudentCredentialsList([]);
      setCredentialsModalVisible(true);
      setCredsListLoading(true);

      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getstudentinfoforexport?level=${level}&section=${section}`).then((response) => {
         if (response.data) {
            const data = response.data;

            setStudentCredentialsList(data);
            setCredsListLoading(false);
         }
      }, (error) => {
         setCredsListLoading(false);
         console.log(error.message);
      });
   }

   const handleShowChangeSection = () => {
      setNewSection(null);
      setStudentsForSectionChange([]);
      setSectionChangeModalVisible(true);
   }

   const handleChangeSection = () => {
      if (studentsForSectionChange.length > 0 && newSection !== null) {
         var selectedStudents = studentsForSectionChange.join(',');

         axios.post(`${process.env.REACT_APP_API_MANAGEMENT}/changesection?ids=${selectedStudents}&oldsection=${section}&newsection=${newSection}`).then((response) => {
            if (response.data) {
               const data = response.data;

               if (data.Code === 1) {
                  Modal.error({
                     title: 'Student Management',
                     content: 'Ooops! It seems that there was a problem encountered while processing your request.',
                     centered: true,
                     onOk: setSectionChangeModalVisible(false),
                  });
               }
               else {
                  Modal.success({
                     title: 'Student Management',
                     content: 'Change of section successful.',
                     centered: true,
                     onOk: setSectionChangeModalVisible(false),
                  });
               }

               //-- Refresh student list
               handleGetStudentList();
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Student Management',
               content: 'Ooops! It seems that there was a problem encountered while processing your request.',
               centered: true,
               onOk: setSectionChangeModalVisible(false),
            });
         });
      }
      else {
         Modal.info({
            title: 'Student Management',
            content: <>
               <p>
                  Please supply the following parameters:
               </p>
               Students
               <br />
               New Section
            </>,
            centered: true,
         });
      }
   }

   const handleShowAddToSection = () => {
      setNewSection(null);
      setStudentsForSectionChange([]);
      setAddToSectionModalVisible(true);
   }

   const handleAddSection = () => {
      if (studentsForSectionChange.length > 0 && newSection !== null) {
         var selectedStudents = studentsForSectionChange.join(',');

         axios.post(`${process.env.REACT_APP_API_MANAGEMENT}/addtosection?ids=${selectedStudents}&level=${level}&newsection=${newSection}`).then((response) => {
            if (response.data) {
               const data = response.data;

               if (data.Code === 1) {
                  Modal.error({
                     title: 'Student Management',
                     content: 'Ooops! It seems that there was a problem encountered while processing your request.',
                     centered: true,
                     // onOk: setAddToSectionModalVisible(false),
                  });
               }
               else {
                  Modal.success({
                     title: 'Student Management',
                     content: 'Add to section successful.',
                     centered: true,
                     onOk: setAddToSectionModalVisible(false),
                  });
               }

               //-- Refresh student list
               // handleGetStudentList();
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Student Management',
               content: 'Ooops! It seems that there was a problem encountered while processing your request.',
               centered: true,
               // onOk: setAddToSectionModalVisible(false),
            });
         });
      }
      else {
         Modal.info({
            title: 'Student Management',
            content: <>
               <p>
                  Please supply the following parameters:
               </p>
               Students
               <br />
               Section
            </>,
            centered: true,
         });
      }
   }

   const rowSelection = {
      selectedRowKeys: studentsForSectionChange,
      onChange: (selectedRowKeys, selectedRows) => {
         setStudentsForSectionChange(selectedRowKeys);
      },
   };

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Management</li>
                     <li><span>/</span></li>
                     <li className="active">Student Information</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Management</Breadcrumb.Item>
                     <Breadcrumb.Item>Student Information</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
            </div>

            <Row gutter={12} style={{ paddingBottom: "15px" }}>
               <Col className="gutter-row" span={24} style={{ textAlign: "left" }}>
                  {/* <Tooltip placement="top" title='Create Mastery Assessment'> */}
                  <Space>
                     {
                        user_role === 'Admin' &&
                        <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => handleAddNewStudent()}>
                           <Text ellipsis style={{ color: "#fff" }}><AntDIcons.UserAddOutlined />&nbsp;Add New Student</Text>
                        </Button>
                     }
                     {
                        user_id === '1' &&
                        <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => setImportModalVisible(true)}>
                           <Text ellipsis style={{ color: "#fff" }}><AntDIcons.UsergroupAddOutlined />&nbsp;Import New Students</Text>
                        </Button>
                     }
                  </Space>
                  {/* </Tooltip> */}
               </Col>
            </Row>

            <Row gutter={12} >
               <Col span={24}>
                  <div className='search-columns-3'>
                     <Select
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Level"
                        onChange={(val) => handleLevelChange(val)}
                        value={level}
                     >
                        {levelList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>

                     <Select
                        loading={sectionLoading}
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Section"
                        value={section}
                        onChange={(val) => handleSectionChange(val)}
                     >
                        <Option value={-1}>All</Option>
                        {sectionList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>

                     <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleGetStudentList()} icon={<AntDIcons.SearchOutlined />}>Search</Button>
                  </div>
               </Col>
            </Row>

            {
               studentList.length > 0 &&
               <Row gutter={12} style={{ marginTop: '15px' }} >
                  <Col span={24}>
                     {/* <Button className='button-shadow' type='primary' shape='round' onClick={() => handleExport()} icon={<AntDIcons.ExportOutlined />}>Export Student Credentials</Button> */}
                     <Space>
                        <Button className='button-shadow' type='primary' shape='round' onClick={() => handleShowCredentials()}>Show Credentials</Button>
                        <Button className='button-shadow' type='primary' shape='round' onClick={() => handleShowChangeSection()}>Change Section</Button>
                        <Button className='button-shadow' type='primary' shape='round' onClick={() => handleShowAddToSection()}>Add To Section</Button>
                     </Space>
                  </Col>
               </Row>
            }

            <Row gutter={12} style={{ marginTop: '15px' }} >
               <Col span={24}>
                  <div className="dashboard-item-inner3">
                     {
                        !searchLoading
                           ?
                           <Table
                              rowKey={'student-list'}
                              // loading={searchLoading}
                              size='small'
                              columns={columns}
                              dataSource={studentList}
                              pagination={false}
                              scroll={{
                                 y: 'calc(100svh - 18rem)',
                                 x: '1000px'
                              }}
                              footer={() => 'Total Records: ' + studentList.length}
                           />
                           :
                           <div className='center-empty-space'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                     }
                  </div>
               </Col>
            </Row>
         </div>

         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}

         {/* Modals */}

         <Modal
            title={"Add New Student"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            open={addModalVisible}
            width='75%'
            // footer={null}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <StudentAdd
               onUpdatingCallBack={handleUpdating}
               onSaveCompleteCallBack={handleSave}
               onCancelCallback={handleCancel}
            />
         </Modal>

         <Modal
            title={"Edit Student Information"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            open={editModalVisible}
            width='75%'
            // footer={null}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <Spin spinning={updating} tip={"Updating, please wait..."}>
               <StudentEdit
                  studentID={studentID}
                  onUpdatingCallBack={handleUpdating}
                  onSaveCompleteCallBack={handleSave}
                  onCancelCallback={handleCancel}
               />
            </Spin>
         </Modal>

         <Modal
            title={"Import New Students"}
            centered
            destroyOnClose={true}
            onCancel={() => { setImportModalVisible(false); }}
            open={importModalVisible}
            // footer={null}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            okText={'Import'}
         >
            <Spin spinning={updating} tip={"Updating, please wait..."}>
               <StudentImport
                  onUpdatingCallBack={handleUpdating}
                  onSaveCompleteCallBack={handleImportComplete}
                  onCancelCallback={handleCancel}
               />
            </Spin>
         </Modal>

         <Modal
            title={"Student Credentials"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            onOk={() => handleExportToExcel()}
            open={credentialsModalVisible}
            width='85%'
            okButtonProps={{ shape: 'round', type: 'primary' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Export'}
            cancelText={'Close'}
         >
            <Table
               rowKey={'uid'}
               loading={credsListLoading}
               size='small'
               columns={creds_columns}
               dataSource={studentCredentialsList}
               pagination={false}
               scroll={{
                  y: 'calc(100svh - 20rem)',
                  x: '1500px'
               }}
            />
         </Modal>

         <Modal
            title={"Change Section"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            onOk={() => handleChangeSection()}
            open={sectionChangeModalVisible}
            width='70%'
            okButtonProps={{ shape: 'round', type: 'primary' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Change'}
            cancelText={'Close'}
         >
            <Row gutter={12} style={{ marginBottom: '15px' }} >
               <Col span={24}>
                  <Table
                     rowSelection={rowSelection}
                     size='small'
                     columns={change_section_columns}
                     dataSource={studentForSectionChangeList}
                     pagination={false}
                     scroll={{
                        y: 'calc(100svh - 20rem)',
                        x: '800px'
                     }}
                  />
               </Col>
            </Row>

            <Row gutter={12}>
               <Col span={24} style={{ display: "flex", justifyContent: "right" }}>
                  <Space>
                     <Text>Change To</Text>
                     <Select
                        showArrow
                        size='middle'
                        style={{ width: '300px' }}
                        allowClear
                        placeholder="Select New Section"
                        value={newSection}
                        onChange={(val) => setNewSection(val)}
                     >
                        <Option value={-1}>All</Option>
                        {newSectionList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>
                  </Space>
               </Col>
            </Row>
         </Modal>

         <Modal
            title={"Add To Section"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            onOk={() => handleAddSection()}
            open={addToSectionModalVisible}
            width='70%'
            okButtonProps={{ shape: 'round', type: 'primary' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Add'}
            cancelText={'Close'}
         >
            <Row gutter={12} style={{ marginBottom: '15px' }} >
               <Col span={24}>
                  <Table
                     rowSelection={rowSelection}
                     size='small'
                     columns={change_section_columns}
                     dataSource={studentForSectionChangeList}
                     pagination={false}
                     scroll={{
                        y: 'calc(100svh - 20rem)',
                        x: '800px'
                     }}
                  />
               </Col>
            </Row>

            <Row gutter={12}>
               <Col span={24} style={{ display: "flex", justifyContent: "right" }}>
                  <Space>
                     <Text>Add To</Text>
                     <Select
                        showArrow
                        size='middle'
                        style={{ width: '300px' }}
                        allowClear
                        placeholder="Select Section"
                        value={newSection}
                        onChange={(val) => setNewSection(val)}
                     >
                        <Option value={-1}>All</Option>
                        {newSectionList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>
                  </Space>
               </Col>
            </Row>
         </Modal>
      </>
   );
}