import React, { useState, useEffect } from 'react';
import '../../../node_modules/simplebar/src/simplebar.css';
import { Empty, Divider, Spin } from 'antd';
// import * as AntDIcons from '@ant-design/icons';
// import { ColumnsType } from 'antd/es/table';
// import PropTypes from 'prop-types';
// import Highlighter from 'react-highlight-words';
// import { useSelector, useDispatch } from 'react-redux';
// import { Navigate, useLocation, useParams,Link, useNavigate } from "react-router-dom";
import SimpleBarReact from "simplebar-react";
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import axios from 'axios';

// const { Title } = Typography;

function NoticeBoard(props) {

   const [dataContent, setDataContent] = useState([])
   const [loading, setLoading] = useState(true)

   useEffect(() => {
      Utils.verifyJwt();
      getNoticeList()
   }, [props.uid]);

   const getNoticeList = () => {
      let user = Utils.getCurrentUserInfo(Enums.UserInfo.id)
      let role = Utils.getCurrentUserInfo(Enums.UserInfo.role)
      let url = `${process.env.REACT_APP_LMS_API}api/NoticeBoard/listEntityNoticeList?userid=${user}&role=${role}`
      setLoading(true)
      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setDataContent(data);
            setTimeout(() => {
               setLoading(false)
               console.log('stop loading')
            }, 2000);
         }
      }, (error) => {
         console.log(error.message);
         setLoading(false)
         console.log('stop loading')
      });
   }
   return (
      <div>
         <div className="notice-item-inner3">
            {/* <div className="notice-item-inner4">
               <h4>Notice Board</h4>	
               <Divider orientation="center">
                  <Title level={4} style={{ textAlign: "center", color: "#252525" }}>Notice Board</Title>
               </Divider>
            </div> */}

            {
               loading
                  ?
                  <div className='center-empty-space'>
                     <Spin size='large' tip="Loading notice board, please wait..." />
                  </div>
                  :
                  <>
                     {dataContent.length > 0
                        ?
                        <SimpleBarReact autoHide={true} style={{ height: 'calc(100vh - 130px)' }}>
                           {
                              dataContent.map((item, i) => (
                                 <>
                                    <div className="notice-item-inner5">
                                       <div>
                                          <h4 style={{ color: '#5997D1' }}>{item.title}</h4>
                                          <div dangerouslySetInnerHTML={{ __html: item.content }} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} />
                                       </div>
                                    </div>

                                    {
                                       // (i > 0 && i < dataContent.length - 1) &&
                                       <Divider />
                                    }
                                 </>
                              ))
                           }
                        </SimpleBarReact>
                        :
                        <div className='center-empty-space'>
                           <Empty
                              description='No Announcements'
                              image="../images/brainee_mascot.png"
                           />
                        </div>
                     }
                  </>
            }
         </div>
      </div>
   );
}

export default NoticeBoard;