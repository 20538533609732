import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Select, Input, Row, Col, Tag,
   Modal, Typography, Spin, Breadcrumb, Empty
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { StaffEdit } from './Components/StaffEdit';
import { StaffAdd } from './Components/StaffAdd';

const { Option } = Select;
const { Text } = Typography;

var staffID = null;

export function StaffInfo() {
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [staffList, setStaffList] = useState([]);
   const [searchLoading, setSearchLoading] = useState(false);

   const [updating, setUpdating] = useState(false);
   const [editModalVisible, setEditModalVisible] = useState(false);
   const [addModalVisible, setAddModalVisible] = useState(false);

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      GetStaffList();
   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: 'Employee ID',
         key: 'employee_id',
         ...getColumnSearchProps('employee_id'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.employee_id}>
               <Text>
                  {
                     payload.employee_id
                  }
               </Text>
            </Tooltip>
         ),
         width: '19%'
      },
      {
         title: 'Last Name',
         key: 'last_name',
         // dataIndex: 'last_name',
         sorter: (a, b) => { return a.last_name.localeCompare(b.last_name) },
         ...getColumnSearchProps('last_name'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="right" title={payload.last_name}>
               <span style={{ cursor: "default" }}>
                  {
                     payload.last_name !== null
                        ?
                        payload.last_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </span>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'First Name',
         key: 'first_name',
         // dataIndex: 'first_name',
         sorter: (a, b) => { return a.first_name.localeCompare(b.first_name) },
         ...getColumnSearchProps('first_name'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="right" title={payload.first_name}>
               <span style={{ cursor: "default" }}>
                  {
                     payload.first_name !== null
                        ?
                        payload.first_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </span>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Gender',
         key: 'gender',
         ...getColumnSearchProps('gender'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.gender}>
               <Text>
                  {
                     payload.gender !== null
                        ?
                        payload.gender.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </Text>
            </Tooltip>
         ),
         width: '10%'
      },
      {
         title: 'Date of Birth',
         key: 'date_of_birth',
         dataIndex: 'date_of_birth',
         sorter: (a, b) => { return (a.date_of_birth === null ? '' : a.date_of_birth).localeCompare(b.date_of_birth === null ? '' : b.date_of_birth) },
         width: '12%'
      },
      {
         title: 'Active',
         key: 'is_active',
         dataIndex: 'is_active',
         sorter: (a, b) => { return a.is_active.localeCompare(b.is_active) },
         render: is_active => (
            <Tag style={{ fontSize: 12 }} color={is_active === 'No' ? 'red' : 'green'}>
               {is_active.toUpperCase()}
            </Tag>
         ),
         width: '9%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Edit Profile'>
                     <AntDIcons.EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEdit(payload.id)} />
                  </Tooltip>
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const GetStaffList = () => {
      setSearchLoading(true);

      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getstafflist`).then((response) => {
         if (response.data) {
            const data = response.data;

            setStaffList(data);
            setSearchLoading(false);
         }
      }, (error) => {
         setSearchLoading(false);
         console.log(error.message);
      });
   }

   const handleAddNewStaff = () => {
      setAddModalVisible(true);
   }

   const handleEdit = (id) => {
      staffID = id;
      setEditModalVisible(true);
   }

   const handleSave = () => {
      GetStaffList();
      setUpdating(false);
      setEditModalVisible(false);
      setAddModalVisible(false);
   }

   const handleCancel = () => {
      setEditModalVisible(false);
      setAddModalVisible(false);
   }

   const handleUpdating = () => {
      setUpdating(true);
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Management</li>
                     <li><span>/</span></li>
                     <li className="active">Staff Information</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Management</Breadcrumb.Item>
                     <Breadcrumb.Item>Staff Information</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
            </div>

            <Row gutter={12}>
               <Col className="gutter-row" span={24} style={{ textAlign: "left" }}>
                  {/* <Tooltip placement="top" title='Create Mastery Assessment'> */}
                  <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => handleAddNewStaff()}>
                     <Text ellipsis style={{ color: "#fff" }}><AntDIcons.UserAddOutlined />&nbsp;Add New Staff</Text>
                  </Button>
                  {/* </Tooltip> */}
               </Col>
            </Row>

            <Row gutter={12} style={{ marginTop: '15px' }} >
               <Col span={24}>
                  <div className="dashboard-item-inner3">
                     {
                        searchLoading
                           ?
                           <div className='center-empty-space'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                           :
                           (staffList.length > 0 && !searchLoading)
                              ?
                              <Table
                                 rowKey={'uid'}
                                 // loading={searchLoading}
                                 size='small'
                                 columns={columns}
                                 dataSource={staffList}
                                 pagination={false}
                                 scroll={{
                                    y: 'calc(100svh - 12rem)',
                                    x: '1000px'
                                 }}
                                 footer={() => 'Total Records: ' + staffList.length}
                              />
                              :
                              <div className='center-empty-space'>
                                 <Empty image="../images/brainee_mascot.png" description="No records found" />
                              </div>
                     }

                  </div>
               </Col>
            </Row>
         </div>

         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}

         {/* Modals */}
         <Modal
            title={"Add New Staff"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            open={addModalVisible}
            width='75%'
            // footer={null}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <StaffAdd
               onUpdatingCallBack={handleUpdating}
               onSaveCompleteCallBack={handleSave}
               onCancelCallback={handleCancel}
            />
         </Modal>

         <Modal
            title={"Edit Staff Information"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            open={editModalVisible}
            width='75%'
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         // footer={null}
         >
            <Spin spinning={updating} tip={"Updating, please wait..."}>
               <StaffEdit
                  staffID={staffID}
                  onUpdatingCallBack={handleUpdating}
                  onSaveCompleteCallBack={handleSave}
                  onCancelCallback={handleCancel}
               />
            </Spin>
         </Modal>
      </>
   );
}