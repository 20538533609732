import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { Link } from 'react-router-dom';
import { Create } from './Components/Create';
import { View } from './Components/View';
import { Empty, Breadcrumb } from 'antd';

export function SchoolCalendar(props) {
   // const navigate = useNavigate();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [currentDocument, setCurrentDocument] = useState(null);
   const [showView, setShowView] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role === 'Admin' || user_role === 'Teacher')
         getSchedule();
      else
         getScheduleStudent();
   }, []);

   const getSchedule = () => {
      const url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/getschedule?type=school&level=-1&section=-1&uploader=${user_id}`;
      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;
            setCurrentDocument(data.file_url);
            setShowView(true);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getScheduleStudent = () => {
      const url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/getschedulestudent?type=school&id=${user_id}`;
      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;
            setCurrentDocument(data.file_url);
            setShowView(true);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleFinishUploading = (document) => {
      setCurrentDocument(null);
      setShowView(false);
      getSchedule();
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Schedule</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">School Calendar</a></li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        {/* <AntDIcons.HomeOutlined /> */}
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        Schedule
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        Lesson Calendar
                     </Breadcrumb.Item>
                  </Breadcrumb>
               </div>
               {
                  (user_role === 'Admin' || user_role === 'Teacher' || user_role === 'Co-Teacher') &&
                  <div style={{ paddingBottom: '10px' }}>
                     <Create
                        type='school'
                        level={-1}
                        button_label='School Calendar'
                        section={-1}
                        onFinishCreateCallback={handleFinishUploading}
                     />
                  </div>
               }
               {
                  showView
                     ?
                     <View
                        type='school'
                        uploader={user_id}
                        document={currentDocument}
                        showsearchtools={false}
                        emptydescription='School calendar not available.'
                     />
                     :
                     <div className='document-viewer'>
                        <Empty
                           description={'School calendar not available.'}
                           image="../images/brainee_mascot.png"
                        />
                     </div>
               }
            </div>
         </div >
         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}

      </>
   );
}