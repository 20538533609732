import React,{ useContext, useEffect, useRef, useState } from 'react'
import { Box, Grid, Flex } from 'theme-ui'
import { CloudUploadOutlined } from '@ant-design/icons';
import { CanvasContext } from '../../hooks/LessonPage'
import LessonService from '../../../../services/LessonService'
import Utils from "../../../../misc/Utils"
import moment from 'moment';
import {
    DeleteOutlined,
    FileAddOutlined,
    SearchOutlined
 } from '@ant-design/icons';
import {
    Card,
    Popconfirm,
    Skeleton,
    Button,
    Typography,
    Tooltip,
    Input,
    Row,
    Col,
    Select,
    message,
    Modal,
    Progress
 } from 'antd';
import "./MyFilesPanel.scss"
// import "../../LessonComponents/SearchServiceGoogleAPI.scss";
// import "../../LessonComponents/SearchServiceGoogleAPI.scss";
import Dropzone from 'react-dropzone';
import Enums from '../../../../misc/Enums';
import uuid from 'react-uuid'

function MyFilesPanel(props) {

  const { state, actions } = useContext(CanvasContext);
  const [searchItems, setSearchItems] = React.useState([]);
  const [origSearchItems, setOrigSearchItems] = React.useState([]);
  const [displayItems, setDisplayItems] = React.useState([]);
  const [displayItemsStartIdx, setDisplayItemsStartIdx] = React.useState(0);
  const [isLoading, setIsLoading] =  React.useState(false);
  const [selectedItem, setSetSelectedItem] = React.useState({});
  const [linkDescription, setLinkDescription] = React.useState('');
  const [linkTitle, setLinkTitle] = React.useState('');
  const [linkFileType, setLinkFileType] = React.useState(1);
  const [linkImage, setLinkImage] = React.useState('');
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [selectedNewItem, setSelectedNewItem] = React.useState(true);
  const [selectedFileChange, setSelectedFileChange] = React.useState(false);
  const [link, setLink] = React.useState('');
  const [linkType, setLinkType] = React.useState('');
  const [uploadUUID, setUploadUUID] = React.useState('');
  const [percentage, setPercentage] = React.useState(0);
  const [openAddResources, setOpenAddResources] = useState(false);
  const [searchText, setSearchText] = React.useState("");
  const { Text } = Typography;
  const { Option } = Select;
  const elementRef = React.useRef(null)

  const { TextArea } = Input;
  useEffect(() => {
    getAllResources()
    setDisplayItemsStartIdx(0)
    setSearchText("")
  }, []);

  const initFormResource = () => {
    // setSelectedNewItem(true);
    setLinkDescription('');
    setLinkTitle('');
    // setLinkImage('');
    setLinkType('');
    setLinkFileType(1);
    setSetSelectedItem({});

 }  

  const getAllResources = () => {
    setIsLoading(true)
    LessonService.getResources().then((response) => {
       setIsLoading(false)
       var varitems = response.data;
       var searchResult = [];
       varitems.map((item) => {
          var searchItem = {
             type: "file",
             videoId: item.uuid,
             filename: item.filename,
             title: item.title,
             fileType: item.type,
             fileext: item.fileType,
             userId: item.userId,
             description: item.description,
             dateCreated: item.dateCreated,
             image: Utils.checkFileImage(item.fileType)
          }          
          searchResult.push(searchItem);
       })
       setSearchItems(searchResult);
       setOrigSearchItems(searchResult)
       setDisplayItems(searchResult.slice(0, 10))
    }).catch((ex) => {
       setIsLoading(false)
    //    message.error(ex.message)
    var s = ""
    });
}

const myResourcesCard = (item) => {
   var userId = Utils.getCurrentUserInfo(Enums.UserInfo.id)
   var fullLink=getFullFileLink(item.userId, item.filename)
   var filetype = getFileTypeLink(item.fileext)
   // getFileType(item.fileext, userId, item.filename, "")
    return (
     <div style={{margin : "25px"}}>
       <Card
          hoverable
          className='custom-card'
        //   onClick={() => searchOnclick(item)}
       >
          <div style={{border : "1px solid black", borderRadius : "5px", margin : "5px", padding : "5px"}}>
             <div ><p><h7><b>Title : </b><span style={{ color: "blue" }}>{item.title}</span> </h7></p></div>
             <div style={{ display: 'flex' }}>
                <div style={{ width: '30%', paddingRight: '5px' }}><img height="70px" width="70px" src={item.image} alt="" ></img></div>
                <div style={{ width: '70%' }}>
                   <div><b>Description :</b> </div>
                   <div>{item.description} </div>
                   <div><b>Date Created :</b> </div>
                   <div>{moment(item.dateCreated).format('L')} </div>
                   <div><b>Permission Type :</b> </div>
                   <div>{item.fileType === 1 ? "Private" : "Public"} </div>
                </div>
             </div>

             <div style={{
                display: 'flex',
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-end"
             }}>
                <div style={{ paddingRight: "7px" }}>
                   <Popconfirm placement="leftTop" title={'Add this item?'} onConfirm={() => setItem(item.image, fullLink, filetype, item.title)}  okText="Yes" cancelText="No">
                      <FileAddOutlined style={{ fontSize: '22px', color: '#70D0D4' }} />
                   </Popconfirm>
                </div>
                <div style={{ paddingRight: "7px" }}>
                   <Popconfirm placement="leftTop" title={'Delete this item?'} onConfirm={() => confirmUploadDelete(item.videoId)}  okText="Yes" cancelText="No">
                      <DeleteOutlined style={{ fontSize: '22px', color: '#70D0D4' }} />
                   </Popconfirm>
                </div>
             </div>
          </div>
       </Card>
       </div>
    )
 }

 function confirmUploadDelete(uuid) {
   LessonService.deleteResources(uuid).then((response) => {
      if (response?.data) {
         message.info("File deleted.")
         getAllResources();
         initFormResource()
      }
   }).catch((ex) => {
      var mess = ex.message
   });
}

const uploadHandle = (file) => {
   var currentFile = file[0]
   setFile(currentFile);

   var newItem = {
      type: "file",
      videoId: selectedNewItem ? uuid() : selectedItem.videoId,
      filename: currentFile.name,
      title: linkTitle,
      fileType: linkFileType,
      userId: 1,
      fileext: currentFile.type,
      description: linkDescription,
      dateCreated: moment(new Date()).format("MM/DD/YYYY"),
      image: Utils.checkFileImage(currentFile.type)
   }

   setSetSelectedItem(newItem)
   setLinkFileType(linkFileType)
   setLinkImage(Utils.checkFileImage(currentFile.type))
   setSelectedFileChange(true);
};

 const myResourcesSection = () => {
    return (
      <>
       <div style={{ display: "flex", position: "relative", height: "40%", marginBottom: "10px" }}>
          <div className='draganddrop'>
             <Dropzone onDrop={files => uploadHandle(files)}>
                {({ getRootProps, getInputProps }) => (
                   <div className="container">
                      <div style={{ display: "block", padding: "10px 10px 10px 10px", border: "2px dashed #70D0D4" }}
                         {...getRootProps({
                            className: 'dropzone'
                         })}
                      >
                         <input {...getInputProps()} />
                         <div style={{ fontSize: "45px" }}>
                            <CloudUploadOutlined />
                         </div>
                         <h7>Drag & Drop Your File Here </h7>
                      </div>
                   </div>
                )}
             </Dropzone>
             <div style={{ display: "block", width: "100%", alignItems: "left", textAlign: "left" }}>
                <div style={{ paddingTop: "5px", fontSize: "smaller" }}><Text ellipsis style={{ cursor: "default" }}>Filename: <Tooltip placement='top' title={selectedItem.filename}>{selectedItem.filename}</Tooltip></Text></div>
                <div style={{ paddingTop: "5px", fontSize: "smaller" }}>File Type: {selectedItem.fileType === 1 ? "Private" : selectedItem.fileType === 2 ? "Public" : ""}</div>
                <div style={{ paddingTop: "5px", fontSize: "smaller" }}>Date Created: {selectedItem.dateCreated}</div>
             </div>
          </div>
          <div style={{ height: "120px", width: "55%" }}>
             <div style={{ display: "flex", marginTop: "15px" }}>
                <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                   <h8>Title:</h8>
                </div>
                <div style={{ marginLeft: "5px", width: '100%' }}>
                   <Input size="middle" value={linkTitle} onChange={(e) => setLinkTitle(e.target.value)} style={{ width: 'calc(100% - 20px)', borderRadius: "7px" }} />
                </div>
             </div>
             <div style={{ marginTop: "5px", width: '100%' }}>
                <div>
                   <span><h8>Description : </h8> </span>
                </div>
                <div style={{ width: 'calc(100% - 20px)' }}>
                   <TextArea size="middle" value={linkDescription} onChange={(e) => setLinkDescription(e.target.value)} rows={2} style={{ width: '100%' }} />
                </div>
             </div>
             <div style={{ top: '20px', display: "flex" }}>
                <div style={{ marginTop: '17px', textAlign: 'center', paddingRight: '5px' }}>
                   <span><h8>Type : </h8> </span>
                </div>
                <div>
                   <Select size="middle" onChange={(e) => setLinkFileType(e)} value={linkFileType === 1 ? "Private" : "Public"} style={{ marginTop: '12px' }}>
                      <Option value={1}>Private</Option>
                      <Option value={2}>Public</Option>
                   </Select>
                </div>
             </div>
             <div style={{ justifyContent: "center", paddingTop: "15px" }}>
                <Row gutter={8}>
                   <Col span={12} className="gutter-row">
                      <Button className='button-shadow' shape="round" size="medium" type="primary" onClick={() => initFormResource()} block>
                         <Text ellipsis style={{ color: "#fff" }}>New</Text>
                      </Button>
                   </Col>
                   <Col span={12} className="gutter-row">
                      <Button className='button-shadow' shape="round" size="medium" type="primary" onClick={() => onUploadClick()} block>
                         <Text ellipsis style={{ color: "#fff" }}>Upload</Text>
                      </Button>
                   </Col>
                   {/*                   <Col span={8} className="gutter-row">
                   <Button className='button-shadow' shape="round" size="small" type="primary" onClick={() => onClickAdd()} block>
                   <Text ellipsis style={{color: "#fff"}}>Add To Topic</Text>
                   </Button>
                </Col> */}
                </Row>
             </div>
          </div>
       </div>
      {isUploading && <div style={{marginTop : "5px"}}>
         <span>Uploading...</span>
         <Progress percent={percentage} status="active" />
      </div>}
      </>
    )
 }

 const onUploadClick = async () => {

    if (linkTitle !== "" && linkDescription !== "" && file !== null) {
       var userId = Utils.getCurrentUserInfo(Enums.UserInfo.id)
       setIsUploading(true)
       const formdata = new FormData();
       formdata.append("Type", linkFileType)
       formdata.append("Title", linkTitle)
       formdata.append("Description", linkDescription)
       formdata.append("FileType", selectedItem.fileext)
       formdata.append("UserId", userId)
       const uid = uuid()

       // file.size
       if (!selectedNewItem) {
          formdata.append("Uuid", selectedItem.videoId !== "" ? selectedItem.videoId : uid)
          if (selectedFileChange) {
             formdata.append("File", file)
             formdata.append("FileName", file.name)
             formdata.append("FileType", file.type)
             getFileType(selectedItem.fileext, userId, file.name, uid)
          }
          else
             formdata.append("FileName", selectedItem.filename)
          formdata.append("FileType", selectedItem.fileext)
          getFileType(selectedItem.fileext, userId, selectedItem.filename, "")
       }
       else {
          setUploadUUID(uid)
          formdata.append("File", file)
          formdata.append("FileName", file.name)
          formdata.append("FileType", file.type)
          formdata.append("Uuid", uid)
          getFileType(selectedItem.fileext, userId, file.name, uid)
       }

       LessonService.postResources(formdata, option).then((response) => {
          if (response?.data) {
             message.info("Upload successful.")
             const subdomain = localStorage.getItem("lms-subdomain");
             getAllResources();
             setIsUploading(false)
             setOpenAddResources(false)
             // initFormResource()
            //  setOpenAddModal(true)
          }
       }).catch((ex) => {
          message.error(ex);
          setIsUploading(false)
       });
    }
    else
       message.info("Invalid information provided.")

 } 


 const getFullFileLink = ( userId, filename) => {
   const subdomain = localStorage.getItem("lms-subdomain");
   return `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${userId}/${filename}`;
 }

 const getFileTypeLink = (fileext) => {
   var linkType = "others"

   if (fileext === Enums.FileType.ppt || fileext === Enums.FileType.ppsx || fileext === Enums.FileType.pptx || fileext === Enums.FileType.docx || fileext === Enums.FileType.doc || fileext === Enums.FileType.xlsx || fileext === Enums.FileType.xls) {
      linkType = "msoffice"
   }
   else if (fileext === Enums.FileType.pdf)
      linkType = "pdf"
   else if (fileext === Enums.FileType.mp4)
      linkType = "video"
   else if (fileext === Enums.FileType.png || fileext === Enums.FileType.jpeg || fileext === Enums.FileType.bmp)
      linkType = "image"

   return linkType
 }

 const getFileType = (fileext, userId, filename, uid) => {
    const subdomain = localStorage.getItem("lms-subdomain");
    let sourceFile = `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${userId}/${filename}`;

    if (uid !== "")
       sourceFile = `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${userId}/${uid}-${filename}`;

    setLink(encodeURI(sourceFile))
    if (fileext === Enums.FileType.ppt || fileext === Enums.FileType.ppsx || fileext === Enums.FileType.pptx || fileext === Enums.FileType.docx || fileext === Enums.FileType.doc || fileext === Enums.FileType.xlsx || fileext === Enums.FileType.xls) {
       setLinkType("msoffice")
       const msOfficeViewer = `${process.env.REACT_APP_MSOFFICE_VIEWER}${sourceFile}`;
       setLink(encodeURI(msOfficeViewer))
    }
    else if (fileext === Enums.FileType.pdf)
       setLinkType("pdf")
    else if (fileext === Enums.FileType.mp4)
       setLinkType("video")
    else if (fileext === Enums.FileType.png || fileext === Enums.FileType.jpeg || fileext === Enums.FileType.bmp)
       setLinkType("image")
    else
       setLinkType("others")
 }

 const option = {
    onUploadProgress: (progressEvent) => {
       const { loaded, total } = progressEvent;
       let p = Math.floor((loaded * 100) / total)
       setPercentage(p)
       //console.log(`${percentage} %`)
    }
 }

 

  const setItem = (image, link, filetype, title) =>
  {
    var data = {type : "FILE", url : image, defaultcontent : title, filetype : filetype, filename : link}
    actions?.addElement(data);
  }

  const onSearch = async (e) =>
  {     
   var filter  = origSearchItems.filter(p => p.title.toLowerCase().includes(searchText.toLowerCase()))
   setSearchItems(filter)
   setDisplayItemsStartIdx(0)
   setDisplayItems(filter.slice(0, 10))
  }

 const setItemIdx = (direction) =>
 {
   if (direction === "prev")
   {            
      var idx = displayItemsStartIdx
      if (idx-10 >= 0)
          idx -= 10
      setDisplayItemsStartIdx(idx)
      setDisplayItems(searchItems.slice(idx, idx+10))
   }
   else if (direction === "next")
   {            
      var totalToDisplay = 10
      var idx = displayItemsStartIdx
      if (idx+10 < searchItems.length)
      {
            idx += 10
            setDisplayItemsStartIdx(idx)
            setDisplayItems(searchItems.slice(idx+1, idx+totalToDisplay))            
      }
      else
      {
         totalToDisplay = setSearchItems.length - idx
      }   
      // setDisplayItemsStartIdx(idx)
      // var array = searchItems.slice(idx+1, totalToDisplay)
      // setDisplayItems(searchItems.slice(idx+1, idx+totalToDisplay))
   }
 }

  return (
    <>
    <div className="logo">
       <div style={{color : 'white' , fontWeight : 'bold', width : '100%', fontSize : '18px' }}>
           MY RESOURCES
       </div>
       <div style={{color : 'white' , fontWeight : 'bold', width : '100%', fontSize : '18px', marginTop : "10px", marginBottom : "10px" }}>
       <Button type="primary" icon={<CloudUploadOutlined />} size={"medium"} onClick={() => setOpenAddResources(!openAddResources)}>
            Upload File
        </Button>    
        </div>
        <div className='container-search'>
                <div className='search-group'>
                   <Input size="large"  onChange={(e) => setSearchText(e.target.value)}  onPressEnter={onSearch}
                        value={searchText} placeholder="Search Keywords" prefix={<SearchOutlined />} style={{borderRadius : '5px'}} />
                </div>
         </div>        
         <div style={{display : "flex", 
                      marginLeft  :"20px",
                      marginRight  :"20px", 
                      marginTop : "10px",
                      justifyContent : "space-between"}}>
            <div style={{display  :"flex"}}>
               <div>
               <Button type="primary" style={{marginRight : '5px'}} onClick={() => setItemIdx("prev")}>Previous</Button>
               </div>
               <div>
               <Button type="primary" onClick={() => setItemIdx("next")} >Next</Button>
               </div>
            </div>
             <div style={{display : "flex", textAlign : "center", marginLeft : "5px", color : "white"}}>Page {displayItemsStartIdx+1} of {searchItems.length}</div>
         </div>
    </div>

    {!isLoading ? <div className='result-container-myfiles'>
        {displayItems.map((item) => {
        return (
            myResourcesCard(item)
        );
        })}
    </div>:
    <div className='result-container-myfiles'>
       <div className='image-container' > 
        <Skeleton.Image active className='image-result' style={{width : "250px" , height : "180px"}}/>
       </div>
       <div style={{ width : '250px', margin : 'auto', paddingTop : '10px'}}>
          <div className=' author-style'  ><Skeleton.Input active style={{width : "250px" }}/></div>
          <div className=' description-style' ><Skeleton.Input active  style={{width : "250px" }}/></div>    
       </div>           
    </div>
    }
             {/* <Modal
            title="Add Uploaded File ?"
            open={openAddModal}
            // onOk={() => confirmUploadAdd()}
            // onCancel={() => cancelUploadAdd()}
            okText="Yes"
            cancelText="Cancel"
            centered
         >
            <p>Add the uploaded file to your lesson ? </p>
         </Modal> */}

         <Modal
            title="Add Resources"
            centered
            destroyOnClose={true}
            open={openAddResources}
            onOk={() => setOpenAddResources(false)}
            onCancel={() => setOpenAddResources(false)}
            cancelButtonProps={{ shape: "round" }}
            okButtonProps={{ shape: "round" }}
         >
           {myResourcesSection()}
         </Modal>         
</>
  )
}

export default MyFilesPanel
