import React, { useState } from 'react';
import { Modal, Image } from 'antd';
import { ChatGPT2 } from './chatGPT2';
// import { ChatGPT } from './chatGPT';

export function ChatGPTFB(props) {

   const [visibleAI, setVisibleAI] = useState(false);

   const handleClick = () => {
      setVisibleAI(true);
   }

   return (
      <>
         {
            visibleAI === false &&
            <div className="chat-gpt-floating-button"
               onClick={() => handleClick()}
            />
         }

         <Modal
            key="Modal-AI"
            title={<><Image src='../images/brainee_bot.png' preview={false} height="30px" />&nbsp;Brainee Bot</>}
            // title="Brainee Bot"
            destroyOnClose={true}
            centered
            open={visibleAI}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ shape: 'round', type: 'primary', className: "button-shadow" }}
            onCancel={() => setVisibleAI(false)}
            cancelText="Close"
            width={"95%"}
            style={{ minWidth: "350px", maxWidth: "95%" }}
         // footer={false}
         >
            <ChatGPT2 />
         </Modal>
      </>
   );
}