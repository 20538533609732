import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'antd';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { useNavigate, Link } from 'react-router-dom';
import { Create } from './Components/Create';
import { View } from './Components/View';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';

export function ClassSchedule(props) {
   const navigate = useNavigate();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [currentDocument, setCurrentDocument] = useState(null);
   const [level, setLevel] = useState(null);
   const [section, setSection] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();

   }, []);

   const handleFinishUploading = (document) => {
      setCurrentDocument(document);
   }

   const handleChangeSearchCriteria = (level, section) => {
      setLevel(level);
      setSection(section);
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Schedule</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Class Schedule</a></li>
                  </ul> */}

                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        {/* <AntDIcons.HomeOutlined /> */}
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        Schedule
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        Class Schedule
                     </Breadcrumb.Item>
                  </Breadcrumb>
               </div>
               {
                  (user_role === 'Admin' || user_role === 'Teacher' || user_role === 'Co-Teacher') &&
                  <div style={{ paddingBottom: '10px' }}>
                     <Create
                        type='class'
                        level={level}
                        button_label='Class Schedule'
                        section={section}
                        onFinishCreateCallback={handleFinishUploading}
                     />
                  </div>
               }
               {
                  // currentDocument !== null
                  // ?
                  <View
                     type='class'
                     uploader={user_id}
                     document={currentDocument}
                     onChangeSearchCriteria={handleChangeSearchCriteria}
                     showsearchtools={true}
                     emptydescription='Class schedule not available.'
                  />
                  // :
                  // <div className='document-viewer'>
                  //    <Empty
                  //       description={'Class schedule not available.'}
                  //    // image="../images/brainee_mascot.png"
                  //    />
                  // </div>
               }

            </div>
         </div>

         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}
      </>
   );
}