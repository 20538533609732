/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import './MasteryAssessment.css';
import {
   Button, Input, Form, Select, Tag, Typography, Row, Col, Descriptions, Space,
   Tooltip, Modal, Spin, Breadcrumb
} from 'antd';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { EyeOutlined, HomeOutlined } from '@ant-design/icons';
import axios from 'axios';
// import { MasteryTerms, Levels, MasteryCompetency, MasteryGradeLevels, MasterySubjects } from '../../constants'
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import SimpleBarReact from "simplebar-react";
// import { PDFReader } from 'reactjs-pdf-reader';
// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';

import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

var g_modal_title = "";
var g_modal_study_plan = "";

export function MasteryStudentViewResult() {
   const navigate = useNavigate();
   const location = useLocation();
   const dispatch = useDispatch();

   const [form] = Form.useForm();

   const { Text, Paragraph } = Typography;
   const { Option } = Select;
   const { TextArea } = Input

   const origin = location.state.origin;
   const assign_info = location.state.assignInfo;
   const student_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);
   const student_name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);
   // const masteryrec = useSelector(state => state.masteryrec);

   const [modalVisible, setModalVisible] = useState(false);
   const [masteryResult, setMasteryResult] = useState([]);
   // const [masteryProficiencies, setMasteryProficiencies] = useState([]);

   const [MasteryTerms, setMasteryTerms] = useState([]);
   const [MasteryCompetency, setMasteryCompetency] = useState([]);
   const [MasteryGradeLevels, setMasteryGradeLevels] = useState([]);
   const [MasterySubjects, setMasterySubjects] = useState([]);

   const [masteryrec, setMasteryRec] = useState([]);

   const dateFormat = 'MMM D YYYY, h:mm a';

   useEffect(() => {
      Utils.verifyJwt();

      getMasteryLookUps();

   }, [location.state.mastery_id]);

   const getMasteryLookUps = () => {
      var _masteryTerms = [];
      var _masteryCompetency = [];
      var _masteryGradeLevels = [];
      var _masterySubjects = [];

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasteryterms`).then((response) => {
         if (response.data) {
            const data = response.data;
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               _masteryTerms.push(obj);
            });

            axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterysubjects`).then((response) => {
               if (response.data) {
                  const data = response.data;
                  data.map((item) => {
                     let obj = { 'value': item.value, 'name': item.name }
                     _masterySubjects.push(obj);
                  });

                  axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterygradelevels`).then((response) => {
                     if (response.data) {
                        const data = response.data;
                        data.map((item) => {
                           let obj = { 'value': item.value, 'name': item.name }
                           _masteryGradeLevels.push(obj);
                        });

                        axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterycompetency`).then((response) => {
                           if (response.data) {
                              const data = response.data;
                              data.map((item) => {
                                 let obj = { 'value': item.value, 'name': item.name }
                                 _masteryCompetency.push(obj);
                              });
                           }

                           getMasteryInfo(location.state.mastery_id);

                           setMasteryTerms(_masteryTerms);
                           setMasteryCompetency(_masteryCompetency);
                           setMasteryGradeLevels(_masteryGradeLevels);
                           setMasterySubjects(_masterySubjects);
                        }, (error) => {
                           console.log(error.message);
                        });
                     }
                  }, (error) => {
                     console.log(error.message);
                  });
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getMasteryInfo = (id) => {
      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/info?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;

            var mastery_data = {
               id: data.id,
               title: data.title,
               term: data.term,
               level: data.level,
               subject: data.subject,
               formative_1: data.formative_1,
               formative_1_file_url: data.formative_1_file_url,
               formative_2: data.formative_2,
               formative_2_file_url: data.formative_2_file_url,
               formative_3: data.formative_3,
               formative_3_file_url: data.formative_3_file_url,
               summative: data.summative,
               summative_file_url: data.summative_file_url,
               study_plan_1: data.study_plan_1,
               study_plan_2: data.study_plan_2,
               mastery_level: data.mastery_level,
               mastery_competency: data.competency,
               created_by: data.created_by,
            }

            // dispatch(set_mastery(mastery_data));
            setMasteryRec(mastery_data);

            getMasteryResult(location.state.mastery_id, location.state.assign_id, student_id);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getMasteryResult = (mastery_id, assign_id, student_id) => {
      axios.get(`${process.env.REACT_APP_API_STUDENT_MASTERY_ASSESSMENT}/masteryresult?assessment_id=${mastery_id}&assignment_id=${assign_id}&student_id=${student_id}`).then((response) => {
         if (response.data) {
            const mastery_result_data = response.data;

            setMasteryResult(mastery_result_data);

            // axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/listproficiencies`).then((response) => {
            //    if (response.data) {
            //       const data = response.data;

            //       setMasteryProficiencies(data);
            //    }
            // }, (error) => {
            //    console.log(error.message);
            // });


         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleViewAnswer = (student_name, student_id, assessment_type) => {
      var assessment_id = location.state.mastery_id;
      navigate('/masteryassessment/student-view-ans-pdf', { state: { student_name, assessment_id, assignInfo: assign_info, student_id, assessment_type, activetab: origin } });
   }

   return (
      <>
         {masteryResult.length > 0
            ?
            <>
               <NoticeBoardSlide />
               <div className="dashboard-area-all">
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: origin } })}>Mastery Assessments</a></li>
                           <li><span>/</span></li>
                           <li><a className="active">View Result</a></li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: origin } })}>Mastery Assessments</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>View Result</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className='mastery_details_div'>
                           <Form
                              form={form}
                              layout="vertical"
                              className='DetailsForm'
                              colon={false}
                              autoComplete="off"
                           >
                              <Row gutter={12}>
                                 <Col xs={24} xl={18}>
                                    <Form.Item label="Competency Title" name="mastery_title" initialValue={masteryrec.title}>
                                       <TextArea
                                          allowClear
                                          rows={5}
                                          style={{ color: '#000000', borderRadius: 7 }}
                                          disabled
                                       />
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} xl={6}>
                                    <Row gutter={12}>
                                       <Col xs={24} xl={24}>
                                          <Form.Item label="Competency Code" name="competency" initialValue={masteryrec.mastery_competency}>
                                             <Select
                                                size='default'
                                                style={{ width: '100%', textAlign: "left" }}
                                                allowClear
                                                placeholder="Select Competency"
                                                disabled
                                                value={masteryrec.mastery_competency}
                                             >
                                                {MasteryCompetency.map(({ value, name }, idx) => {
                                                   return <Option value={value} key={idx.toString()}>{name}</Option>
                                                })}
                                             </Select>
                                          </Form.Item>
                                       </Col>
                                       <Col xs={24} xl={24}>
                                          <Form.Item label="Term" name="term" initialValue={masteryrec.term}>
                                             <Select
                                                disabled
                                                showArrow
                                                size='default'
                                                style={{ width: '100%', textAlign: "left" }}
                                                allowClear
                                                placeholder="Select Term"
                                                value={masteryrec.term}
                                             >
                                                {MasteryTerms.map(({ value, name }, idx) => {
                                                   return <Option value={value} key={idx.toString()}>{name}</Option>
                                                })}
                                             </Select>
                                          </Form.Item>
                                       </Col>
                                    </Row>

                                 </Col>
                              </Row>

                              <Row gutter={12}>
                                 <Col xs={24} xl={10}>
                                    <Form.Item label="Grade Level" name="gradelevel" initialValue={masteryrec.level}>
                                       <Select
                                          disabled
                                          showArrow
                                          size='default'
                                          style={{ width: '100%', textAlign: "left" }}
                                          allowClear
                                          placeholder="Select Level"
                                          value={masteryrec.level}
                                       >
                                          {MasteryGradeLevels.map(({ value, name }, idx) => {
                                             return <Option value={value} key={idx.toString()}>{name}</Option>
                                          })}
                                       </Select>
                                    </Form.Item>
                                 </Col>

                                 <Col xs={24} xl={14}>
                                    <Form.Item label="Subject" name="subject" initialValue={masteryrec.subject}>
                                       <Select
                                          disabled
                                          showArrow
                                          size='default'
                                          style={{ width: '100%', textAlign: "left" }}
                                          allowClear
                                          placeholder="Select Subject"
                                          value={masteryrec.subject}
                                       >
                                          {MasterySubjects.map(({ value, name }, idx) => {
                                             return <Option value={value} key={idx.toString()}>{name}</Option>
                                          })}
                                       </Select>
                                    </Form.Item>
                                 </Col>
                              </Row>

                              <Row gutter={12} style={{ marginBottom: "20px" }}>
                                 <Col xs={24} xl={24}>
                                    <Descriptions bordered size='small' column={2}>
                                       <Descriptions.Item label="Assessment Type" span={2} style={{ fontSize: 14, width: "50%", textAlign: "center" }}>
                                          Status
                                       </Descriptions.Item>
                                       {
                                          masteryrec.summative === null
                                             ?
                                             <>
                                                <Descriptions.Item label='Formative Level 1' span={2} style={{ fontSize: 14, width: "50%" }}>
                                                   <Space direction='horizontal' size={"small"}>
                                                      {masteryrec.formative_1_file_url !== null
                                                         ?
                                                         <>
                                                            {
                                                               masteryResult !== null
                                                                  ?
                                                                  <>
                                                                     {
                                                                        masteryResult[0].mastery_answer_id !== null
                                                                           ?
                                                                           <>
                                                                              {
                                                                                 masteryResult[0].submit_status === 1
                                                                                    ?
                                                                                    <>
                                                                                       {
                                                                                          masteryResult[0].essay_score === 0 && masteryResult[0].essay_score_multi === "[]" && masteryResult[0].essay_total_points > 0
                                                                                             ?
                                                                                             <Tag color={"gold"} style={{ fontSize: 13 }}>For teacher evaluation</Tag>
                                                                                             :
                                                                                             <>
                                                                                                {/* <Tag color={"green"} style={{fontSize: 13}}>{ masteryProficiencies.length > 0 ? '(' + masteryResult[0].percentage + '%) ' + Utils.GetProficiencyScore(masteryResult[0].percentage) : '' }</Tag> */}
                                                                                                <Tag color={masteryResult[0].percentage > 60 ? "green" : "red"} style={{ fontSize: 11 }}>Score: {masteryResult[0].score + "/" + masteryResult[0].total_points}</Tag>
                                                                                                {/* <Tag color={masteryResult[0].percentage > 60 ? "green" : "red" }  style={{fontSize: 11}}>Percentage: { masteryResult[0].percentage + "%" }</Tag>
                                                                                                <Tag color={masteryResult[0].percentage > 60 ? "green" : "red" }  style={{fontSize: 13}}>{ masteryResult[0].percentage > 60 ? "PASSED" : "FAILED" }</Tag> */}
                                                                                                <Tag color={"green"} style={{ fontSize: 11 }}>{Utils.GetProficiency(masteryResult[0].percentage)}</Tag>
                                                                                                <Tooltip placement="top" title={'View ' + Utils.GetTypeLabel(masteryResult[0].assessment_type) + " Answer"}>
                                                                                                   <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleViewAnswer(student_name, student_id, masteryResult[0].assessment_type)}>
                                                                                                      <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                                                                   </Button>
                                                                                                </Tooltip>
                                                                                             </>
                                                                                       }
                                                                                    </>
                                                                                    :
                                                                                    <Tag color={"gold"} style={{ fontSize: 13 }}>Answering</Tag>
                                                                              }
                                                                           </>
                                                                           :
                                                                           <Tag color={"red"} style={{ fontSize: 13 }}>Not Started</Tag>
                                                                     }
                                                                  </>
                                                                  :
                                                                  <Tag color={"red"} style={{ fontSize: 13 }}>Not Started</Tag>
                                                            }
                                                         </>
                                                         :
                                                         <Tag color={"gray"} style={{ fontSize: 13 }}>Not Available</Tag>
                                                      }
                                                   </Space>
                                                </Descriptions.Item>

                                                <Descriptions.Item label='Formative Level 2' span={2} style={{ fontSize: 14, width: "50%" }}>
                                                   <Space direction='horizontal' size={"small"}>
                                                      {masteryrec.formative_2_file_url !== null
                                                         ?
                                                         <>
                                                            {
                                                               masteryResult !== null
                                                                  ?
                                                                  <>
                                                                     {
                                                                        masteryResult[1].mastery_answer_id !== null
                                                                           ?
                                                                           <>
                                                                              {
                                                                                 masteryResult[1].submit_status === 1
                                                                                    ?
                                                                                    <>
                                                                                       {
                                                                                          masteryResult[1].status === 2
                                                                                             ?
                                                                                             <Tag color={"blue"} style={{ fontSize: 13 }}>EXEMPTED</Tag>
                                                                                             :
                                                                                             <>
                                                                                                {
                                                                                                   masteryResult[1].essay_score === 0 && masteryResult[1].essay_score_multi === "[]" && masteryResult[1].essay_total_points > 0
                                                                                                      ?
                                                                                                      <Tag color={"gold"} style={{ fontSize: 13 }}>for teacher evaluation</Tag>
                                                                                                      :
                                                                                                      <>
                                                                                                         {/* <Tag color={"green"} style={{fontSize: 13}}>{ masteryProficiencies.length > 0 ? '(' + masteryResult[0].percentage + '%) ' + Utils.GetProficiencyScore(masteryResult[0].percentage) : '' }</Tag> */}
                                                                                                         <Tag color={masteryResult[1].percentage > 60 ? "green" : "red"} style={{ fontSize: 11 }}>Score: {masteryResult[1].score + "/" + masteryResult[1].total_points}</Tag>
                                                                                                         {/* <Tag color={masteryResult[1].percentage > 60 ? "green" : "red" }  style={{fontSize: 11}}>Percentage: { masteryResult[1].percentage + "%" }</Tag>
                                                                                                         <Tag color={masteryResult[1].percentage > 60 ? "green" : "red" }  style={{fontSize: 13}}>{ masteryResult[1].percentage > 60 ? "PASSED" : "FAILED" }</Tag> */}
                                                                                                         <Tag color={"green"} style={{ fontSize: 11 }}>{Utils.GetProficiency(masteryResult[1].percentage)}</Tag>
                                                                                                         <Tooltip placement="top" title={'View ' + Utils.GetTypeLabel(masteryResult[1].assessment_type) + " Answer"}>
                                                                                                            <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleViewAnswer(student_name, student_id, masteryResult[1].assessment_type)}>
                                                                                                               <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                                                                            </Button>
                                                                                                         </Tooltip>
                                                                                                      </>
                                                                                                }
                                                                                             </>
                                                                                       }
                                                                                    </>
                                                                                    :
                                                                                    <Tag color={"gold"} style={{ fontSize: 13 }}>Answering</Tag>
                                                                              }
                                                                           </>

                                                                           :
                                                                           <Tag color={"red"} style={{ fontSize: 13 }}>Not Started</Tag>
                                                                     }
                                                                  </>
                                                                  :
                                                                  <Tag color={"red"} style={{ fontSize: 13 }}>Not Started</Tag>
                                                            }
                                                         </>
                                                         :
                                                         <Tag color={"gray"} style={{ fontSize: 13 }}>Not Available</Tag>
                                                      }
                                                   </Space>
                                                </Descriptions.Item>

                                                <Descriptions.Item label='Formative Level 3' span={4} style={{ fontSize: 14, width: "50" }}>
                                                   <Space direction='horizontal' size={"small"}>
                                                      {masteryrec.formative_3_file_url !== null
                                                         ?
                                                         <>
                                                            {
                                                               masteryResult !== null
                                                                  ?
                                                                  <>
                                                                     {
                                                                        masteryResult[2].mastery_answer_id !== null
                                                                           ?
                                                                           <>
                                                                              {
                                                                                 masteryResult[2].submit_status === 1
                                                                                    ?
                                                                                    <>
                                                                                       {
                                                                                          masteryResult[2].essay_score === 0 && masteryResult[2].essay_score_multi === "[]" && masteryResult[2].essay_total_points > 0
                                                                                             ?
                                                                                             <Tag color={"gold"} style={{ fontSize: 13 }}>For teacher evaluation</Tag>
                                                                                             :
                                                                                             <>
                                                                                                {/* <Tag color={"green"} style={{fontSize: 13}}>{ masteryProficiencies.length > 0 ? '(' + masteryResult[0].percentage + '%) ' + Utils.GetProficiencyScore(masteryResult[0].percentage) : '' }</Tag> */}
                                                                                                <Tag color={masteryResult[2].percentage > 60 ? "green" : "red"} style={{ fontSize: 11 }}>Score: {masteryResult[2].score + "/" + masteryResult[2].total_points}</Tag>
                                                                                                {/* <Tag color={masteryResult[2].percentage > 60 ? "green" : "red" }  style={{fontSize: 11}}>Percentage: { masteryResult[2].percentage + "%" }</Tag>
                                                                                                <Tag color={masteryResult[2].percentage > 60 ? "green" : "red" }  style={{fontSize: 13}}>{ masteryResult[2].percentage > 60 ? "PASSED" : "FAILED" }</Tag> */}
                                                                                                <Tag color={"green"} style={{ fontSize: 11 }}>{Utils.GetProficiency(masteryResult[2].percentage)}</Tag>
                                                                                                <Tooltip placement="top" title={'View ' + Utils.GetTypeLabel(masteryResult[2].assessment_type) + " Answer"}>
                                                                                                   <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleViewAnswer(student_name, student_id, masteryResult[2].assessment_type)}>
                                                                                                      <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                                                                   </Button>
                                                                                                </Tooltip>
                                                                                             </>
                                                                                       }
                                                                                    </>
                                                                                    :
                                                                                    <Tag color={"gold"} style={{ fontSize: 13 }}>Answering</Tag>
                                                                              }
                                                                           </>

                                                                           :
                                                                           <Tag color={"red"} style={{ fontSize: 13 }}>Not Started</Tag>
                                                                     }
                                                                  </>
                                                                  :
                                                                  <Tag color={"red"} style={{ fontSize: 13 }}>Not Started</Tag>
                                                            }
                                                         </>
                                                         :
                                                         <Tag color={"gray"} style={{ fontSize: 13 }}>Not Available</Tag>
                                                      }
                                                   </Space>
                                                </Descriptions.Item>
                                             </>
                                             :
                                             <Descriptions.Item label='Summative' span={2} style={{ fontSize: 14, width: "50%" }}>
                                                <Space direction='horizontal' size={"small"}>
                                                   {
                                                      masteryrec.summative_file_url !== null
                                                         ?
                                                         <>
                                                            {
                                                               masteryResult !== null
                                                                  ?
                                                                  <>
                                                                     {
                                                                        masteryResult[3].mastery_answer_id !== null
                                                                           ?
                                                                           <>
                                                                              {
                                                                                 masteryResult[3].submit_status === 1
                                                                                    ?
                                                                                    <>
                                                                                       {
                                                                                          masteryResult[3].essay_score === 0 && masteryResult[3].essay_score_multi === "[]" && masteryResult[3].essay_total_points > 0
                                                                                             ?
                                                                                             <Tag color={"gold"} style={{ fontSize: 13 }}>For teacher evaluation</Tag>
                                                                                             :
                                                                                             <>
                                                                                                {/* <Tag color={"green"} style={{fontSize: 13}}>{ masteryProficiencies.length > 0 ? '(' + masteryResult[0].percentage + '%) ' + Utils.GetProficiencyScore(masteryResult[0].percentage) : '' }</Tag> */}
                                                                                                <Tag color={masteryResult[3].percentage > 79 ? "green" : "red"} style={{ fontSize: 11 }}>Score: {masteryResult[3].score + "/" + masteryResult[3].total_points}</Tag>
                                                                                                <Tag color={masteryResult[3].percentage > 79 ? "green" : "red"} style={{ fontSize: 11 }}>Percentage: {masteryResult[3].percentage + "%"}</Tag>
                                                                                                <Tag color={masteryResult[3].percentage > 79 ? "green" : "red"} style={{ fontSize: 13 }}>{masteryResult[3].percentage > 79 ? "PASSED" : "FAILED"}</Tag>
                                                                                                <Tooltip placement="top" title={'View ' + Utils.GetTypeLabel(masteryResult[3].assessment_type) + " Answer"}>
                                                                                                   <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleViewAnswer(student_name, student_id, masteryResult[3].assessment_type)}>
                                                                                                      <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                                                                   </Button>
                                                                                                </Tooltip>
                                                                                             </>
                                                                                       }
                                                                                    </>
                                                                                    :
                                                                                    <Tag color={"gold"} style={{ fontSize: 13 }}>Answering</Tag>
                                                                              }
                                                                           </>

                                                                           :
                                                                           <Tag color={"red"} style={{ fontSize: 13 }}>Not Started</Tag>
                                                                     }
                                                                  </>
                                                                  :
                                                                  <Tag color={"red"} style={{ fontSize: 13 }}>Not Started</Tag>
                                                            }
                                                         </>
                                                         :
                                                         <Tag color={"gray"} style={{ fontSize: 13 }}>Not Available</Tag>
                                                   }
                                                </Space>
                                             </Descriptions.Item>
                                       }

                                    </Descriptions>

                                 </Col>

                                 <Col xs={24} md={12} xl={12}>
                                    <Descriptions bordered size='small' column={2}>
                                       {
                                          masteryrec.summative === null
                                             ?
                                             <Descriptions.Item label="Study Plan" span={2} style={{ fontSize: 14, width: "50%", textAlign: "center" }}>
                                                Status
                                             </Descriptions.Item>
                                             :
                                             <Descriptions.Item label="" span={2} style={{ fontSize: 14, width: "50%", textAlign: "center" }}>
                                             </Descriptions.Item>
                                       }

                                       {
                                          masteryrec.summative === null
                                             ?
                                             <>
                                                <Descriptions.Item label='Study Plan 1' span={2} style={{ fontSize: 14, width: "50%" }}>
                                                   <Space direction='horizontal' size={"small"}>
                                                      {masteryrec.study_plan_1 !== null
                                                         // ?
                                                         //    <Tag color={"green"} style={{fontSize: 13}}>Not Viewed</Tag>
                                                         // :
                                                         //    <Tag color={"gray"} style={{fontSize: 13}}>Not Available</Tag>
                                                         ?
                                                         <>
                                                            {
                                                               masteryResult !== null
                                                                  ?
                                                                  <>
                                                                     {
                                                                        masteryResult[4].mastery_answer_id !== null
                                                                           ?
                                                                           <>
                                                                              {
                                                                                 masteryResult[4].submit_status === 1
                                                                                    ?
                                                                                    <>
                                                                                       <Tag color={"green"} style={{ fontSize: 13 }}>Viewed</Tag>
                                                                                    </>
                                                                                    :
                                                                                    <Tag color={"gold"} style={{ fontSize: 13 }}>Viewing</Tag>
                                                                              }
                                                                           </>

                                                                           :
                                                                           <Tag color={"red"} style={{ fontSize: 13 }}>Not Viewed</Tag>
                                                                     }
                                                                  </>
                                                                  :
                                                                  <Tag color={"red"} style={{ fontSize: 13 }}>Not Viewed</Tag>
                                                            }
                                                         </>
                                                         :
                                                         <Tag color={"gray"} style={{ fontSize: 13 }}>Not Available</Tag>
                                                      }
                                                   </Space>
                                                </Descriptions.Item>

                                                <Descriptions.Item label='Study Plan 2' span={2} style={{ fontSize: 14, width: "50%" }}>
                                                   <Space direction='horizontal' size={"small"}>
                                                      {masteryrec.study_plan_2 !== null
                                                         ?
                                                         <>
                                                            {
                                                               masteryResult !== null
                                                                  ?
                                                                  <>
                                                                     {
                                                                        masteryResult[5].mastery_answer_id !== null
                                                                           ?
                                                                           <>
                                                                              {
                                                                                 masteryResult[5].submit_status === 1
                                                                                    ?
                                                                                    <>
                                                                                       <Tag color={"green"} style={{ fontSize: 13 }}>Viewed</Tag>
                                                                                    </>
                                                                                    :
                                                                                    <Tag color={"gold"} style={{ fontSize: 13 }}>Viewing</Tag>
                                                                              }
                                                                           </>

                                                                           :
                                                                           <Tag color={"red"} style={{ fontSize: 13 }}>Not Viewed</Tag>
                                                                     }
                                                                  </>
                                                                  :
                                                                  <Tag color={"red"} style={{ fontSize: 13 }}>Not Viewed</Tag>
                                                            }
                                                         </>
                                                         :
                                                         <Tag color={"gray"} style={{ fontSize: 13 }}>Not Available</Tag>
                                                      }
                                                   </Space>
                                                </Descriptions.Item>
                                             </>
                                             :
                                             <></>
                                       }

                                    </Descriptions>
                                 </Col>
                              </Row>
                           </Form>
                        </div>
                     </div>
                  </div>
               </div>

               {/* <div className="notice-area">
                  <div className="notice-item">
                     <img src="../images/brainee_ma.png" width="100%" style={{ padding: "5px", maxWidth: '600px' }}></img>
                     <NoticeBoard />
                  </div>
               </div> */}


               {/* Modals */}
               <Modal
                  title={"Preview " + g_modal_title}
                  okText={"Close"}
                  cancelButtonProps={{ style: { display: 'none' } }}
                  okButtonProps={{ shape: "round" }}
                  centered
                  destroyOnClose={true}
                  visible={modalVisible}
                  // width={window.innerWidth - 300}
                  width='75%'

                  onCancel={() => { setModalVisible(false); }}
                  onOk={() => { setModalVisible(false); }}
               >
                  <div className="pdf-all-page-container">
                     <div style={{ width: "100%", height: "calc(100vh - 208px)" }}>
                        <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                           <Viewer
                              pageLayout={{
                                 transformSize: ({ size }) => ({
                                    height: size.height + 30,
                                    width: size.width + 30,
                                 }),
                              }}
                              fileUrl={g_modal_study_plan}
                              defaultScale={SpecialZoomLevel.PageWidth}
                              initialPage={0}
                           />
                        </Worker>
                     </div>
                     {/* <object data={g_modal_study_plan} width="100%" type="application/pdf" style={{ height: 'calc(100vh - 208px)' }}>
                           <p>Brainee LMS PDF - View<a href={g_modal_study_plan}> PDF!</a></p>
                        </object> */}
                     {/* <RenderPDFViewer url={g_modal_study_plan} height={"calc(100vh - 208px)"} showdownload={true} showprint={true} showpagecontrols={true} showfullscreen={true} /> */}
                  </div>
                  {/* <RenderPDFReader url={g_modal_study_plan} height={"calc(100vh - 208px)"} /> */}

               </Modal>
            </>
            :
            <>
               <div className="dashboard-area">
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/studentmasteryassessment')}>Mastery Assessments</a></li>
                           <li><span>/</span></li>
                           <li><a className="active">View Result</a></li>
                        </ul> */}
                        <Breadcrumb style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              <HomeOutlined />
                              <a href="#" onClick={() => navigate('/home')}>Home</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: origin } })}>Mastery Assessment</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>View Result</Breadcrumb.Item>
                        </Breadcrumb>
                     </div>
                  </div>

                  <div className='temp-answer-sheet-content2'>
                     <Spin size='large' tip="Loading, please wait..." />
                  </div>
               </div>
               <div className="notice-area">
                  <div className="notice-item">
                     <div className='temp-answer-sheet-content2'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  </div>
               </div>
            </>
         }
      </>


   );

}