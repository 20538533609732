/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Image, Radio, Form, Divider, Row, Col, Typography, Input, DatePicker, Space, Button, Modal, Popconfirm } from 'antd';
import { EyeOutlined, DownOutlined } from '@ant-design/icons';
import Utils from '../../misc/Utils';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import "./Application.scss"
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
import moment from 'moment';

const { Title } = Typography;

export function ReviewApplication() {
   const navigate = useNavigate();
   const location = useLocation();

   // const subdomain = localStorage.getItem("lms-subdomain");

   const search = window.location.search;
   const query = new URLSearchParams(search);
   const application_id = query.get('id');
   // const application_status = query.get('status');
   const application_status = location.state.status;

   const [applicationInfo, setApplicationInfo] = useState(null);
   const [applicationOtherInfo, setApplicationOtherInfo] = useState(null);
   const [rc, setRC] = useState(null);
   const [bc, setBC] = useState(null);
   const [modalVisible, setModalVisible] = useState(false);
   const [docToView, setDocToView] = useState(null);
   const [courseOffering, setCourseOffering] = useState([]);

   useEffect(() => {
      Utils.verifyJwt();

      getApplicationInfo(application_id);
   }, []);

   const getApplicationInfo = (id) => {
      var url = `${process.env.REACT_APP_API_CPH}/getcourseoffering`;

      axios.get(url).then((response) => {
         if (response.data) {
            setCourseOffering(response.data);

            var url = `${process.env.REACT_APP_API_CPH}/getapplicationinfo?id=${id}`;

            axios.get(url).then((response) => {
               if (response.data) {
                  let data = response.data;

                  let docs = JSON.parse(data.documents);

                  setBC(docs.birth_certificate);
                  setRC(docs.report_card);
                  setApplicationOtherInfo(JSON.parse(data.other_info));
                  setApplicationInfo(data);
               }
            }, (error) => {
               // setLoading(false);
               console.log(error.message);
            });
         }
      }, (error) => {
         // setLoading(false);
         console.log(error.message);
      });
   }

   const renderPreviewer = (document) => {
      // if (document !== null && document !== '') {

      //    var type = document.includes('pdf') ? 'pdf' : 'image';

      //    if (type === 'pdf')
      //       return <>
      //          <RenderPDFViewer url={document} height={"calc(100vh - 185px)"} showdownload={false} showprint={false} showpagecontrols={true} showfullscreen={true} />
      //       </>

      //    if (type === 'image')
      //       return <>
      //          <div
      //             className={'modal-document-viewer'}
      //             style={{ height: "calc(100vh - 195px)" }}
      //          >
      //             <Image
      //                src={document}
      //                style={{ minHeight: "calc(100vh - 195px)", maxHeight: "calc(100vh - 195px)" }}
      //             />
      //          </div>
      //       </>
      // }

      return <>
         <div
            className={'modal-document-viewer'}
            style={{ height: "calc(100vh - 195px)" }}
         >
            <Image
               // src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${document}`}
               src={document}
               style={{ minHeight: "calc(100vh - 195px)", maxHeight: "calc(100vh - 195px)" }}
            />
         </div>
      </>
   }

   const handleViewDoc = (doctoview) => {
      setDocToView(doctoview);
      setModalVisible(true);
   }

   const handleUpdateStatus = (newstatus) => {
      var url = `${process.env.REACT_APP_API_CPH}/updateapplicationstatus?id=${application_id}&status=${newstatus}`;
      axios.post(url).then((response) => {
         if (response.data) {

            Modal.success({
               title: 'Online Application',
               content: 'Application was successfuly rejected.',
               centered: true,
               onOk: navigate('/applicantlist')
            });
         }
      }, (error) => {
         Modal.error({
            title: 'Online Application',
            content: 'Operation failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleAcceptApplication = () => {
      var url = `${process.env.REACT_APP_API_CPH}/acceptapplication?id=${application_id}`;
      axios.post(url).then((response) => {
         if (response.data !== -1) {

            Modal.success({
               title: 'Online Application',
               content: 'Application was successfuly accepted.',
               centered: true,
               onOk: navigate('/applicantlist')
            });
         } else {
            Modal.error({
               title: 'Online Application',
               content: 'Operation failed.',
               centered: true
            });
         }
      }, (error) => {
         Modal.error({
            title: 'Online Application',
            content: 'Operation failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const _downloadFile = (url, name) => {
      // setDownloadStatus(true);

      var re = new RegExp(' ', 'g');
      var file_name = name.replace(re, '_');
      var pos = url.lastIndexOf('.');
      var ext = url.slice(pos);

      fetch(url)
         .then(response => {
            response.blob().then(blob => {
               let url = window.URL.createObjectURL(blob);
               let a = document.createElement('a');
               a.href = url;
               a.download = file_name + ext;
               a.click();
               // setDownloadStatus(false);
            });
         });
      /*setDownloadStatus(true)
         FileSaver.saveAs(currentS3ContentLink, downloadOfficeFileName)
      setDownloadStatus(false) */
   };

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <ul>
                     <li className="active"><a href="#" onClick={() => navigate('/applicantlist')}>Student Applicants</a></li>
                     <li><span>/</span></li>
                     {
                        application_status === 'pending'
                           ?
                           <li className="active">Review Application</li>
                           :
                           <li className="active">Applicant Information</li>
                     }

                  </ul>

               </div>

               <div className="dashboard-item-inner3">
                  {
                     (applicationInfo !== null && applicationOtherInfo !== null) &&
                     <div style={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: 'auto', backgroundColor: '#F2F2F2' }}>
                        <div style={{ marginTop: '35px', marginBottom: '20px', width: '100%', maxWidth: '1000px', height: '100%', border: '1px solid #ccc', borderRadius: '7px', backgroundColor: '#FFF', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                           <div className='block-container'>
                              <div className="login-header align-left" style={{ margin: '35px 35px' }}>
                                 <h3><span>APPLICATION</span> FORM</h3>
                                 <p className="text-muted" style={{ fontSize: '18px' }}>Learner's Profile Form</p>
                              </div>

                              <div className='align-right' style={{ width: '165px', height: '165px', paddingTop: '35px', paddingRight: '35px' }}>
                                 <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '7px',
                                    height: '125px',
                                    border: '2px solid rgba(30, 30, 30, 0.1)',
                                 }}>
                                    {/* <Image src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${applicationInfo.picture}`} preview={false} height={'125px'} /> */}
                                    <Image src={applicationInfo.picture} preview={true} height={'100%'} />
                                 </div>
                              </div>
                           </div>

                           <Form
                              layout='vertical'
                              style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}
                           >
                              <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                                 <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Course Offering</Title>
                              </Divider>
                              <Row gutter={[12, 12]}>
                                 <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item
                                       name={'first_choice'}
                                       label="First Choice"
                                       rules={[{ required: true, message: 'First Choice is required' },]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.first_choice}
                                    >
                                       <Radio.Group
                                          name='rfirst_choice'
                                          disabled
                                       >
                                          <Space direction='vertical'>
                                             {courseOffering.map(({ value, name }, idx) => {
                                                return <Radio value={value}>{name}</Radio>
                                             })}
                                          </Space>
                                       </Radio.Group>
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item
                                       name={'second_choice'}
                                       label="Second Choice"
                                       rules={[{ required: true, message: 'Second Choice is required' },]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.second_choice}
                                    >
                                       <Radio.Group
                                          name='rsecond_choice'
                                          disabled
                                       >
                                          <Space direction='vertical'>
                                             {courseOffering.map(({ value, name }, idx) => {
                                                return <Radio value={value}>{name}</Radio>
                                             })}
                                          </Space>
                                       </Radio.Group>
                                    </Form.Item>
                                 </Col>
                              </Row>

                              <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                                 <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Learner's Profile</Title>
                              </Divider>

                              <Row gutter={12}>
                                 <Col xs={24} sm={24} md={24} lg={24}>
                                    <h6>Name</h6>
                                 </Col>
                              </Row>
                              <Row gutter={[12, 12]}>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'last_name'}
                                       label="Last Name, Extension (eg. Jr., Sr.)"
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationInfo.last_name}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'first_name'}
                                       label="First Name"
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationInfo.first_name}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'middle_name'}
                                       label="Middle Name"
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationInfo.middle_name}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                              </Row>
                              <Row gutter={12}>
                                 <Col xs={24} sm={24} md={24} lg={24}>
                                    <h6>Complete Permanent Mailing Address</h6>
                                 </Col>
                              </Row>
                              <Row gutter={[12, 12]}>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'number_street'}
                                       label="Number, Street"
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.number_street}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'barangay'}
                                       label="Barangay"
                                       // rules={[{ required: true, message: 'Barangay is required' },]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.barangay}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'district'}
                                       label="District"
                                       //rules={[{ required: true, },]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.district}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                              </Row>
                              <Row gutter={[12, 12]}>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'city_municipality'}
                                       label="City/Municipality"
                                       // rules={[{ required: true, message: 'City/Municipality is required' },]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.city_municipality}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'province'}
                                       label="Province"
                                       //rules={[{ required: true, },]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.province}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'region'}
                                       label="Region"
                                       // rules={[{ required: true, message: 'Region is required' },]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.region}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                              </Row>
                              <Row gutter={[12, 12]}>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'email'}
                                       label="Email Address"
                                       // rules={[{ required: true, type: 'email', message: 'Email is required' }]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationInfo.email}
                                    >
                                       <Input style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'contact_no'}
                                       label="Contact No."
                                       // rules={[{ required: true, message: 'Contact No. is required' }]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationInfo.contact_number}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'nationality'}
                                       label="Nationality"
                                       // rules={[{ required: true, message: 'Nationality is required' }]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.nationality}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                              </Row>

                              <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                                 <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Personal Information</Title>
                              </Divider>

                              <Row gutter={[12, 12]}>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'gender'}
                                       label="Sex"
                                       // rules={[{ required: true, message: 'Gender is required' }]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationInfo.gender}
                                    >
                                       <Radio.Group
                                          name='rgender'
                                          disabled
                                       // onChange={(e) => onShowResultChange(e)}
                                       >
                                          {/* <Space direction='vertical'> */}
                                          <Radio value="male">Male</Radio>
                                          <Radio value="female">Female</Radio>
                                          {/* </Space> */}
                                       </Radio.Group>
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'civil_status'}
                                       label="Civil Status"
                                       // rules={[{ required: true, message: 'Civil Status is required' }]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.civil_status}
                                    >
                                       <Radio.Group
                                          disabled
                                          name='rcivilstatus'
                                       // onChange={(e) => onShowResultChange(e)}
                                       >
                                          {/* <Space direction='vertical'> */}
                                          <Radio value="single">Single</Radio>
                                          <Radio value="married">Married</Radio>
                                          <Radio value="widower">Widower</Radio>
                                          <Radio value="separated">Separated</Radio>
                                          <Radio value="solo_parent">Solo Parent</Radio>
                                          {/* </Space> */}
                                       </Radio.Group>
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item
                                       name={'employment_status'}
                                       label="Employment Status"
                                       // rules={[{ required: true, message: 'Employment Status is required' }]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.employment_status}
                                    >
                                       <Radio.Group
                                          disabled
                                          name='remploymentstatus'
                                       // onChange={(e) => onShowResultChange(e)}
                                       >
                                          {/* <Space direction='vertical'> */}
                                          <Radio value="employed">Employed</Radio>
                                          <Radio value="unemployed">Unemployed</Radio>
                                          {/* </Space> */}
                                       </Radio.Group>
                                    </Form.Item>
                                 </Col>
                              </Row>

                              <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#eee', margin: '0px 0px' }} />

                              <Row gutter={[12, 12]}>
                                 <Col xs={24} sm={24} md={6} lg={6}>
                                    <Form.Item
                                       name={'birth_date'}
                                       label="Birth Date"
                                       // rules={[{ required: true, message: 'Birth Date is required' }]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={moment(applicationInfo.birth_date)}
                                    >
                                       <DatePicker
                                          style={{ width: "100%" }}
                                          format={'MMMM D, YYYY'}
                                          disabled
                                          // value={moment(applicationOtherInfo.birth_date)}
                                       />
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} sm={24} md={18} lg={18}>
                                    <Form.Item
                                       name={'birth_place'}
                                       label="Birth Place (City/Municipality, Province, Region)"
                                       // rules={[{ required: true, message: 'Birth Place is required' }]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.birth_place}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                              </Row>

                              <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#eee', margin: '0px 0px' }} />

                              <Row gutter={[12, 12]} style={{ width: "100%" }}>
                                 <Col xs={24} sm={24} md={24} lg={24}>
                                    <Form.Item
                                       name={'educational_attainment'}
                                       label="Educational Attainment Before the Training (Trainee)"
                                       // rules={[{ required: true, message: 'Educational Attainment is required' }]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.educational_attainment}
                                    >
                                       <Radio.Group
                                          disabled
                                          name='reducationalattainment'
                                          style={{ width: '100%' }}
                                       // style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                                       // onChange={(e) => onShowResultChange(e)}
                                       >
                                          <Row>
                                             <Col xs={24} sm={24} md={6} lg={6}>
                                                <Radio value="no_grade_completed">No Grade Completed</Radio>
                                             </Col>
                                             <Col xs={24} sm={24} md={6} lg={6}>
                                                <Radio value="pre_school">Pre-School</Radio>
                                             </Col>
                                             <Col xs={24} sm={24} md={6} lg={6}>
                                                <Radio value="high_school_undergraduate">High School Undergraduate</Radio>
                                             </Col>
                                             <Col xs={24} sm={24} md={6} lg={6}>
                                                <Radio value="high_school_graduate">High School Graduate</Radio>
                                             </Col>
                                          </Row>
                                          <Row>
                                             <Col xs={24} sm={24} md={6} lg={6}>
                                                <Radio value="elementary_undergraduate">Elementary Undergraduate</Radio>
                                             </Col>
                                             <Col xs={24} sm={24} md={6} lg={6}>
                                                <Radio value="post_secondary_undergraduate">Post Secondary Undergraduate</Radio>
                                             </Col>
                                             <Col xs={24} sm={24} md={6} lg={6}>
                                                <Radio value="college_undergraduate">College Undergraduate</Radio>
                                             </Col>
                                             <Col xs={24} sm={24} md={6} lg={6}>
                                                <Radio value="college_graduate">College Graduate or Higher</Radio>
                                             </Col>
                                          </Row>
                                          <Row>
                                             <Col xs={24} sm={24} md={6} lg={6}>
                                                <Radio value="elementary_graduate">Elementary Graduate</Radio>
                                             </Col>
                                             <Col xs={24} sm={24} md={6} lg={6}>
                                                <Radio value="post_secondary_graduate">Post Secondary Graduate</Radio>
                                             </Col>
                                             <Col xs={24} sm={24} md={6} lg={6}>
                                                <Radio value="junior_high_graduate">Junior High Graduate</Radio>
                                             </Col>
                                             <Col xs={24} sm={24} md={6} lg={6}>
                                                <Radio value="senior_high_graduate">Senior High Graduate</Radio>
                                             </Col>
                                          </Row>

                                          {/* <Radio value="no_grade_completed">No Grade Completed</Radio>
                              <Radio value="pre_school">Pre-School (Nursery/Kinder/Prep)</Radio>
                              <Radio value="high_school_undergraduate">High School Undergraduate</Radio>
                              <Radio value="high_school_graduate">High School Graduate</Radio>
                              <Radio value="elementary_undergraduate">Elementary Undergraduate</Radio>
                              <Radio value="post_secondary_undergraduate">Post Secondary Undergraduate</Radio>
                              <Radio value="college_undergraduate">College Undergraduate</Radio>
                              <Radio value="college_graduate">College Graduate or Higher</Radio>
                              <Radio value="elementary_graduate">Elementary Graduate</Radio>
                              <Radio value="post_secondary_graduate">Post Secondary Graduate</Radio>
                              <Radio value="junior_high_graduate">Junior High Graduate</Radio>
                              <Radio value="senior_high_graduate">Senior High Graduate</Radio> */}
                                       </Radio.Group>
                                    </Form.Item>
                                 </Col>
                              </Row>

                              <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#eee', margin: '0px 0px' }} />

                              <Row gutter={[12, 12]}>
                                 <Col xs={24} sm={24} md={7} lg={7}>
                                    <Form.Item
                                       name={'parent_guardian_name'}
                                       label="Parent/Guardian Name"
                                       rules={[{ required: true, message: 'Parent/Guardian Name is required' },]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.parent_guardian_name}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} sm={24} md={17} lg={17}>
                                    <Form.Item
                                       name={'parent_guardian_address'}
                                       label="Parent/Guardian Complete Permanent Mailing Address"
                                       rules={[{ required: true, message: 'Parent/Guardian Mailing Address is required' }]}
                                       style={{ marginBottom: '10px' }}
                                       initialValue={applicationOtherInfo.parent_guardian_address}
                                    >
                                       <Input disabled style={{ borderRadius: '7px' }} />
                                    </Form.Item>
                                 </Col>
                              </Row>

                              <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                                 <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Required Documents</Title>
                              </Divider>

                              <Row gutter={[12, 12]}>
                                 <Col xs={24} sm={24} md={24} lg={24}>
                                    {/* <ul> */}
                                    <li style={{ marginBottom: '5px' }}>
                                       Birth Certificate (PSA Accredited) <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(bc)}><EyeOutlined /></Button>
                                    </li>
                                    <li>
                                       Report Card or Diploma <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(rc)}><EyeOutlined /></Button>
                                    </li>
                                    {/* </ul> */}
                                 </Col>
                              </Row>
                              {
                                 application_status === 'pending' &&
                                 <Row gutter={[12, 12]} style={{ padding: '15px 0px 0px 0px' }}>
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'right' }}>
                                       <Space>
                                          <Popconfirm
                                             title="Are you sure you want to accept this application?"
                                             onConfirm={() => handleAcceptApplication()}
                                             okText="Yes"
                                             cancelText="No"
                                          >
                                             <Button className='button-shadow' type='primary' shape='round'>Accept</Button>
                                          </Popconfirm>
                                          <Popconfirm
                                             title="Are you sure you want to reject this application?"
                                             onConfirm={() => handleUpdateStatus('rejected')}
                                             okText="Yes"
                                             cancelText="No"
                                          >
                                             <Button className='button-shadow' type='primary' shape='round'>Reject</Button>
                                          </Popconfirm>
                                       </Space>
                                    </Col>
                                 </Row>
                              }
                           </Form>
                        </div>
                     </div>
                  }

               </div>
            </div>
         </div>

         <Modal
            key='preview-doc'
            title={"Preview "}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalVisible}
            width='75%'

            onCancel={() => { setModalVisible(false); }}
            onOk={() => { setModalVisible(false); }}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               {renderPreviewer(docToView)}
            </div>
         </Modal>
      </>
   );
}