import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Select, Input, Row, Col, Tag,
   Modal, Typography, Spin, Breadcrumb, Empty
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
// import { Levels } from '../../constants';

// import { StudentEdit } from './Components/StudentEdit';
// import { StudentAdd } from './Components/StudentAdd';

const { Option } = Select;
const { Text } = Typography;

var studentID = null;

export function PromoteStudents() {
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [schoolYearList, setSchoolYearList] = useState([]);
   const [levelList, setLevelList] = useState([]);

   // const [studentID, setStudentID] = useState(null); 
   const [level, setLevel] = useState(null);
   const [schoolYear, setSchoolYear] = useState([]);
   const [sectionList, setSectionList] = useState([]);
   const [studentList, setStudentList] = useState([]);
   const [currentSchoolYear, setCurrentSchoolYear] = useState([]);
   const [schoolYearPromotion, setSchoolYearPromotion] = useState(null);

   const [searchLoading, setSearchLoading] = useState(false);

   const [updating, setUpdating] = useState(false);
   const [editModalVisible, setEditModalVisible] = useState(false);
   const [promoteModalVisible, setPromoteModalVisible] = useState(false);

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      getSchoolYears();
   }, []);

   const getSchoolYears = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/schoolyearlist`).then((response) => {
         if (response.data) {
            const data = response.data;
            var tmp = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               tmp.push(obj);
            });

            setSchoolYearList(tmp);
            getLevels();
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getLevels = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/levellist`).then((response) => {
         if (response.data) {
            const data = response.data;
            var tmp_levels = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               tmp_levels.push(obj);
            });

            setLevelList(tmp_levels);

            getCurrentSchoolYear();
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getCurrentSchoolYear = () => {
      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/currentschoolyear`).then((response) => {
         if (response.data) {
            const data = response.data;
            setCurrentSchoolYear(data);
         }
      }, (error) => {
         setSearchLoading(false);
         console.log(error.message);
      });
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: 'Last Name',
         key: 'last_name',
         // dataIndex: 'last_name',
         sorter: (a, b) => { return a.last_name.localeCompare(b.last_name) },
         ...getColumnSearchProps('last_name'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="right" title={payload.last_name}>
               <span style={{ cursor: "default" }}>
                  {
                     payload.last_name !== null
                        ?
                        payload.last_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </span>
            </Tooltip>
         ),
         width: '25%'
      },
      {
         title: 'First Name',
         key: 'first_name',
         // dataIndex: 'first_name',
         sorter: (a, b) => { return a.first_name.localeCompare(b.first_name) },
         ...getColumnSearchProps('firstname'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="right" title={payload.first_name}>
               <span style={{ cursor: "default" }}>
                  {
                     payload.first_name !== null
                        ?
                        payload.first_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </span>
            </Tooltip>
         ),
         width: '25%'
      },
      {
         title: 'Gender',
         key: 'gender',
         ...getColumnSearchProps('gender'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.gender}>
               <Text>
                  {
                     payload.gender !== null
                        ?
                        payload.gender.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        ''
                  }
               </Text>
            </Tooltip>
         ),
         width: '10%'
      },
      {
         title: 'Date of Birth',
         key: 'date_of_birth',
         dataIndex: 'date_of_birth',
         sorter: (a, b) => { return (a.date_of_birth === null ? '' : a.date_of_birth).localeCompare(b.date_of_birth === null ? '' : b.date_of_birth) },
         width: '15%'
      },
      {
         title: 'Level',
         key: 'level',
         dataIndex: 'level',
         sorter: (a, b) => { return (a.level === null ? '' : a.level).localeCompare(b.level === null ? '' : b.level) },
         width: '15%'
      },
      {
         title: 'Section',
         key: 'section',
         dataIndex: 'section',
         sorter: (a, b) => { return (a.section === null ? '' : a.section).localeCompare(b.section === null ? '' : b.section) },
         width: '15%'
      },
      {
         title: 'Active',
         key: 'is_active',
         dataIndex: 'is_active',
         sorter: (a, b) => { return a.is_active.localeCompare(b.is_active) },
         render: is_active => (
            <Tag style={{ fontSize: 12 }} color={is_active === 'no' ? 'red' : 'green'}>
               {is_active.toUpperCase()}
            </Tag>
         ),
         width: '10%'
      },
      // {
      //    title: 'Action',
      //    key: 'action',
      //    render: payload => {
      //       return <>
      //          <Space size="small">
      //             <Tooltip placement="top" title='Edit Profile'>
      //                <AntDIcons.EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEdit(payload.id)} />
      //             </Tooltip>
      //          </Space>
      //       </>
      //    },
      //    width: '10%'
      // },
   ].filter(item => !item.hidden);

   const handleGetStudentList = () => {
      setSearchLoading(true);

      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getstudentlistforpromotion?level=${level}&schoolyear=${schoolYear}`).then((response) => {
         if (response.data) {
            const data = response.data;

            setStudentList(data);
            setSearchLoading(false);
         }
      }, (error) => {
         setSearchLoading(false);
         console.log(error.message);
      });
   }

   const handlePromoteStudents = () => {
      setUpdating(true);

      var url = `${process.env.REACT_APP_API_MANAGEMENT}/promotestudents?level=${level}&schoolyear=${schoolYearPromotion}`;
      axios.post(url).then(response => {
         console.log(response);
         if (response.data) {
            const data = response.data;

            setUpdating(false);
            // setPromoteModalVisible(false);
            Modal.success({
               title: 'Student Management',
               content: 'Promote successful.',
               centered: true,
               onOk: () => { setPromoteModalVisible(false); }
            });
         }
      }, (error) => {
         console.log(error.message);
         setUpdating(false);
         // setPromoteModalVisible(false);
         Modal.error({
            title: 'Student Management',
            content: 'Ooops! It seems that there was a problem encountered while processing your request.',
            centered: true,
         });
      });
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Management</li>
                     <li><span>/</span></li>
                     <li className="active">Student Promotion</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Management</Breadcrumb.Item>
                     <Breadcrumb.Item>Student Promotion</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
            </div>

            <Row gutter={12} >
               <Col span={24}>
                  <div className='search-columns-3'>
                     <Select
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select School Year"
                        value={schoolYear}
                        onChange={(val) => setSchoolYear(val)}
                     >
                        {schoolYearList.map(({ value, name }, idx) => {
                           var retVal = '';
                           if (value >= currentSchoolYear)
                              retVal = <Option value={value}>{name}</Option>;

                           return retVal;
                        })}
                     </Select>

                     <Select
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Level"
                        onChange={(val) => setLevel(val)}
                        value={level}
                     >
                        {levelList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>

                     <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleGetStudentList()} icon={<AntDIcons.SearchOutlined />}>Search</Button>
                  </div>
               </Col>
            </Row>

            {
               studentList.length > 0 &&
               <Row gutter={12} style={{ paddingBottom: "15px", marginTop: "20px" }}>
                  <Col className="gutter-row" span={24} style={{ textAlign: "left" }}>
                     <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => setPromoteModalVisible(true)}>
                        <Text ellipsis style={{ color: "#fff" }}><AntDIcons.ToTopOutlined />&nbsp;Promote Students</Text>
                     </Button>
                     {/* </Tooltip> */}
                  </Col>
               </Row>
            }


            <Row gutter={12}>
               <Col span={24}>
                  <div className="dashboard-item-inner3">
                     {/* {
                        searchLoading
                           ?
                           <div className='center-empty-space'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                           :
                           (studentList.length > 0 && !searchLoading)
                              ?
                              <Table
                                 rowKey={'uid'}
                                 // loading={searchLoading}
                                 size='small'
                                 columns={columns}
                                 dataSource={studentList}
                                 pagination={false}
                                 scroll={{
                                    y: 'calc(100svh - 15.5rem)',
                                    x: '1000px'
                                 }}
                                 footer={() => 'Total Records: ' + studentList.length}
                              />
                              :
                              <div className='center-empty-space'>
                                 <Empty image="../images/brainee_mascot.png" description="No records found" />
                              </div>
                     } */}
                     {
                        !searchLoading
                           ?
                           <Table
                              rowKey={'uid'}
                              // loading={searchLoading}
                              size='small'
                              columns={columns}
                              dataSource={studentList}
                              pagination={false}
                              scroll={{
                                 y: 'calc(100svh - 15.5rem)',
                                 x: '1000px'
                              }}
                              footer={() => 'Total Records: ' + studentList.length}
                           />
                           :
                           <div className='loading-please-wait'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                     }
                  </div>
               </Col>
            </Row>
         </div>

         {/* Modals */}

         <Modal
            title={"Promote Students"}
            centered
            destroyOnClose={true}
            onCancel={() => { setPromoteModalVisible(false); }}
            onOk={() => { handlePromoteStudents(); }}
            open={promoteModalVisible}
            okButtonProps={{ shape: 'round', type: 'primary' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Promote'}
         // width='30%'
         >
            <>
               <Spin spinning={updating} tip={"Promoting, please wait..."}>
                  <Text>Choose the school year you want the students to be promoted to</Text>
                  {
                     schoolYearList.length > 0 &&
                     <Select
                        showArrow
                        size='middle'
                        style={{ width: '100%', marginTop: "5px" }}
                        allowClear
                        placeholder="Select School Year"
                        // value={schoolYear}
                        onChange={(val) => setSchoolYearPromotion(val)}
                     >

                        {schoolYearList.map(({ value, name }, idx) => {
                           var retVal = '';
                           if (value > currentSchoolYear)
                              retVal = <Option value={value}>{name}</Option>;

                           return retVal;
                        })}
                     </Select>
                  }
               </Spin>
            </>
         </Modal>
      </>
   );
}