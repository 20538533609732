/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Select, Button, Modal, Typography, Input, Space, Tooltip, Table, Popconfirm, Breadcrumb } from 'antd';
import { SearchOutlined, EyeOutlined, EditOutlined, DeleteOutlined, ContactsOutlined } from '@ant-design/icons';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { useNavigate, Link } from 'react-router-dom';
import uuid from 'react-uuid';
import Highlighter from 'react-highlight-words';

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

export function SurveyAssign(props) {
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   // const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);
   const user_full_name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);

   const [sectionLoading, setSectionLoading] = useState(false);
   const [levelList, setLevelList] = useState([]);
   const [sectionList, setSectionList] = useState([]);
   const [level, setLevel] = useState(null);
   const [section, setSection] = useState(null);

   const [showAssignModal, setShowAssignModal] = useState(false);
   const [showEditAssignModal, setShowEditAssignModal] = useState(false);
   const [teacherList, setTeacherList] = useState([]);
   const [surveyAssignedList, setSurveyAssignedList] = useState([]);
   const [surveyListLoading, setSurveyListLoading] = useState(false);

   const [teacherId, setTeacherId] = useState(user_id);
   const [description, setDescription] = useState(null);
   const [link, setLink] = useState(null);

   // var survey_data = {
   //    id: null,
   //    surveyee: -1,
   //    description: null,
   //    link: null,
   //    level: -1,
   //    section: -1,
   //    assigned_by: user_id,
   // }

   const [surveyData, setSurveyData] = useState({
      id: null,
      surveyee: -1,
      description: null,
      link: null,
      level: -1,
      section: -1,
      assigned_by: user_id,
   });

   useEffect(() => {
      Utils.verifyJwt();

      getLevels();

   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const ReportCardsListColumns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'survey_link',
         key: 'survey_link',
         hidden: true,
      },
      {
         title: 'Surveyee',
         key: 'surveyee_name',
         dataIndex: 'surveyee_name',
         sorter: (a, b) => { return a.surveyee_name.localeCompare(b.surveyee_name) },
         width: '20%'
      },
      {
         title: 'Description',
         key: 'description',
         dataIndex: 'description',
         sorter: (a, b) => { return a.description.localeCompare(b.description) },
         width: '25%'
      },
      {
         title: 'Level',
         key: 'level_name',
         dataIndex: 'level_name',
         sorter: (a, b) => { return a.level_name.localeCompare(b.level_name) },
         width: '14%'
      },
      {
         title: 'Section',
         key: 'section_name',
         dataIndex: 'section_name',
         sorter: (a, b) => { return a.section_name.localeCompare(b.section_name) },
         width: '14%'
      }, {
         title: 'Assigned By',
         key: 'assigned_by_name',
         dataIndex: 'assigned_by_name',
         sorter: (a, b) => { return a.assigned_by_name.localeCompare(b.assigned_by_name) },
         width: '20%'
      },
      // },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return (
               <>
                  <Space size="small">
                     <Tooltip placement="top" title={'View Survey'}>
                        <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }}
                           onClick={() => handleViewSurvey(payload.survey_link)}
                        />
                     </Tooltip>
                     <Tooltip placement="top" title={'Edit Assignment'}>
                        <EditOutlined color='#4abec7' style={{ cursor: 'pointer' }}
                           onClick={() => handleEditAssignment(payload.id)}
                        />
                     </Tooltip>
                     <Tooltip placement="top" title={'Remove Assignment'}>
                        <Popconfirm
                           title={"Are you sure you want to remove this survey assignment?"}
                           onConfirm={() => handleRemoveAssignment(payload.id)}
                           okText="Yes"
                           cancelText="No"
                        >
                           <DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                     </Tooltip>
                  </Space>
               </>
            );
         },
         width: '7%'
      },
   ].filter(item => !item.hidden);

   const getLevels = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/levellist`).then((response) => {
         if (response.data) {
            const data = response.data;
            let levels = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               levels.push(obj);
            });

            setLevelList(levels);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleLevelChange = (val) => {
      setSection(null)
      setSectionList([]);
      setLevel(val === undefined ? null : val);
      setSectionLoading(true);

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${val}`).then((response) => {
         if (response.data) {
            const data = response.data;

            var sectionlist = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               sectionlist.push(obj);
            });

            setSectionList(sectionlist);
            setSectionLoading(false);
         }
      }, (error) => {
         setSectionLoading(false);
         console.log(error.message);
      });
   }

   const getTeachers = () => {
      var url = `${process.env.REACT_APP_API_LESSON}/listAllStaff`;
      axios.get(url).then((response) => {
         if (response.data) {
            let data = response.data;
            var users = data.filter((d) => d.id !== Number(user_id)).sort((a, b) => {
               var aVal = a["first_Name"];
               var bVal = b["first_Name"];
               switch (typeof aVal) {
                  case 'string':
                     return aVal.localeCompare(bVal);
                  case 'number':
                     return aVal - bVal;
                  default:
                     throw new Error("Unsupported value to sort by");
               }
            });
            var final = data.filter((d) => d.id === Number(user_id));
            Array.prototype.push.apply(final, users);
            setTeacherList(final)
         }
      }, (error) => {
         // setLoading(false);
         console.log(error.message);
      });
   }

   const handleSectionChange = (val) => {
      setSection(val === undefined ? null : val);
   }

   const handleSearchSurvey = () => {
      axios.get(`${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/getsurveyassigned?level=${level}&section=${section}`).then((response) => {
         if (response.data) {
            const data = response.data;
            setSurveyAssignedList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleAssignment = () => {
      if (level !== null & section !== null) {
         getTeachers();
         setShowAssignModal(true);
      }
      else {
         Modal.error({
            title: 'Survey',
            content: 'Please select Level and Section.',
            centered: true
         });
      }
   }

   const handleUserChange = (id) => {
      setTeacherId(id)
   }

   const handleSaveAssignment = () => {
      setShowAssignModal(false);

      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
      let survey_id = 'survey_assignment_' + o2o_mode + "_" + uuid();

      var survey_data = {
         id: survey_id,
         surveyee: teacherId,
         description: description,
         survey_link: link,
         level: level,
         section: section,
         assigned_by: user_id,
      }

      const url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/surveyassign`;
      axios.post(url, survey_data).then(response => {
         console.log(response);
         if (response.data) {
            // const data = response.data;

            Modal.info({
               title: 'Survey',
               content: 'Saving survey assignment successful.',
               centered: true
            });

            handleSearchSurvey();
         }

      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Survey',
            content: 'Saving survey assignment failed.',
            centered: true
         });
      });
   }

   const handleSaveAssignmentEdit = () => {
      setShowEditAssignModal(false);

      var survey_data = {
         id: surveyData.id,
         surveyee: surveyData.surveyee,
         description: description,
         survey_link: link,
         level: level,
         section: section,
         assigned_by: user_id,
      };

      const url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/surveyassign`;
      axios.post(url, survey_data).then(response => {
         console.log(response);
         if (response.data) {
            // const data = response.data;

            Modal.info({
               title: 'Survey',
               content: 'Saving survey assignment successful.',
               centered: true
            });

            handleSearchSurvey();
         }

      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Survey',
            content: 'Saving survey assignment failed.',
            centered: true
         });
      });
   }

   const handleViewSurvey = (link) => {
      window.open(link, '_blank').focus();
   }

   const handleEditAssignment = (id) => {
      const url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/getsurveyassignedinfo?survey_id=${id}`;
      axios.get(url).then(response => {
         console.log(response);
         if (response.data) {
            const data = response.data;

            setSurveyData({
               id: data.id,
               surveyee: data.surveyee,
               description: data.description,
               survey_link: data.survey_link,
               level: data.level,
               section: data.section,
               assigned_by: data.assigned_by,
            });

            setShowEditAssignModal(true);
         }

      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Survey',
            content: 'Retrieving survey assignment information failed.',
            centered: true
         });
      });
   }

   const handleRemoveAssignment = (id) => {
      const url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/surveyremove?id=${id}`;
      axios.post(url).then(response => {
         console.log(response);
         if (response.data) {
            // const data = response.data;

            Modal.info({
               title: 'Survey',
               content: 'Removing survey assignment successful.',
               centered: true
            });

            handleSearchSurvey();
         }

      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Survey',
            content: 'Removing survey assignment failed.',
            centered: true
         });
      });
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Survey Assignment</a></li>
                  </ul> */}

                  <Breadcrumb style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate('/home')}>Home</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Survey Assignment</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               <Row gutter={[10, 10]}>
                  {/* <Col
                     sm={24}
                     md={16}
                     lg={16}
                  >
                     <Row gutter={[10, 10]}>
                        <Col span={12}>
                           <Select
                              showArrow
                              size='middle'
                              style={{ width: '100%' }}
                              allowClear
                              placeholder="Select Level"
                              onChange={(val) => handleLevelChange(val)}
                              onClear={(val) => handleLevelChange(val)}
                              value={level}
                           >
                              {levelList.map(({ value, name }, idx) => {
                                 return <Option value={value}>{name}</Option>
                              })}
                           </Select>
                        </Col>
                        <Col span={12}>
                           <Select
                              loading={sectionLoading}
                              showArrow
                              size='middle'
                              style={{ width: '100%' }}
                              allowClear
                              placeholder="Select Section"
                              value={section}
                              onChange={(val) => handleSectionChange(val)}
                              onClear={(val) => handleSectionChange(val)}
                           >
                              {sectionList.map(({ value, name }, idx) => {
                                 return <Option value={value}>{name}</Option>
                              })}
                           </Select>
                        </Col>
                     </Row>
                  </Col>
                  <Col
                     sm={24}
                     md={8}
                     lg={8}
                  >
                     <Row gutter={[10, 10]}>
                        <Col span={12}>
                           <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleSearchSurvey()} icon={<SearchOutlined />}>Search</Button>
                        </Col>
                        <Col span={12}>
                           <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleAssignment()} icon={<ContactsOutlined />}>Assign</Button>
                        </Col>
                     </Row>
                  </Col> */}
                  <Col span={24}>
                     <div className='search-columns-4-2'>
                        <Select
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select Level"
                           onChange={(val) => handleLevelChange(val)}
                           onClear={(val) => handleLevelChange(val)}
                           value={level}
                        >
                           {levelList.map(({ value, name }, idx) => {
                              return <Option value={value}>{name}</Option>
                           })}
                        </Select>

                        <Select
                           loading={sectionLoading}
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select Section"
                           value={section}
                           onChange={(val) => handleSectionChange(val)}
                           onClear={(val) => handleSectionChange(val)}
                        >
                           {sectionList.map(({ value, name }, idx) => {
                              return <Option value={value}>{name}</Option>
                           })}
                        </Select>

                        <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleSearchSurvey()} icon={<SearchOutlined />}>Search</Button>
                        <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleAssignment()} icon={<ContactsOutlined />}>Assign</Button>
                     </div>
                  </Col>
               </Row>

               <Row gutter={12} style={{ overflow: "auto", paddingTop: "10px" }} >
                  <Col span={24}>
                     <div className="dashboard-item-inner3">
                        <Table
                           rowKey={'uid'}
                           loading={surveyListLoading}
                           size='small'
                           columns={ReportCardsListColumns}
                           dataSource={surveyAssignedList}
                           pagination={{ pageSize: 10 }}
                           style={{ minWidth: '650px' }}
                        />
                     </div>
                  </Col>
               </Row>
            </div>
         </div>

         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}

         <Modal
            title="Survey Assignment"
            destroyOnClose={true}
            centered
            open={showAssignModal}
            onOk={() => handleSaveAssignment()}
            onCancel={() => setShowAssignModal(false)}
            okText={"Save"}
            // cancelText={"Refresh List"}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round" }}
         // cancelButtonProps={{ shape: "round", style: { display: 'none' } }}
         // width='55%'
         >
            <div className='survey_modal_container'>
               {/* <ZoomPicker id={assessment_id} /> */}
               <Row style={{ paddingBottom: "10px" }}>
                  <Col span={6}>
                     <Text>Select Teacher</Text>
                  </Col>
                  <Col span={18}>
                     <Select
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.includes(input)}
                        defaultValue={user_full_name} style={{ width: '100%' }} onChange={(value) => handleUserChange(value)} >
                        {
                           teacherList.map((item) => {
                              var fullname = (item.first_Name + " " + item.last_Name).trimEnd()
                              return (
                                 <Option value={item.id}>{fullname}</Option>
                              )
                           })
                        }
                     </Select>
                  </Col>
               </Row>
               <Row style={{ paddingBottom: "10px" }}>
                  <Col span={6}>
                     <Text>Description</Text>
                  </Col>
                  <Col span={18}>
                     <TextArea
                        rows={3}
                        onBlur={(e) => setDescription(e.target.value)}
                     >

                     </TextArea>
                  </Col>
               </Row>
               <Row style={{ paddingBottom: "10px" }}>
                  <Col span={6}>
                     <Text>Link</Text>
                  </Col>
                  <Col span={18}>
                     <Input onBlur={(e) => setLink(e.target.value)}></Input>
                  </Col>
               </Row>
            </div>
         </Modal>

         <Modal
            title="Edit Survey Assignment"
            destroyOnClose={true}
            centered
            open={showEditAssignModal}
            onOk={() => handleSaveAssignmentEdit()}
            onCancel={() => setShowEditAssignModal(false)}
            okText={"Save"}
            okButtonProps={{ shape: "round" }}
         >
            <div className='survey_modal_container'>
               {/* <ZoomPicker id={assessment_id} /> */}
               <Row style={{ paddingBottom: "10px" }}>
                  <Col span={6}>
                     <Text>Select Teacher</Text>
                  </Col>
                  <Col span={18}>
                     <Select
                        disabled
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.includes(input)}
                        value={surveyData.surveyee}
                        style={{ width: '100%' }}
                     // onChange={(value) => handleUserChange(value)} 
                     >
                        {
                           teacherList.map((item) => {
                              var fullname = (item.first_Name + " " + item.last_Name).trimEnd()
                              return (
                                 <Option value={item.id}>{fullname}</Option>
                              )
                           })
                        }
                     </Select>
                  </Col>
               </Row>
               <Row style={{ paddingBottom: "10px" }}>
                  <Col span={6}>
                     <Text>Description</Text>
                  </Col>
                  <Col span={18}>
                     <TextArea
                        rows={3}
                        onBlur={(e) => setDescription(e.target.value)}
                        defaultValue={surveyData.description}
                     >

                     </TextArea>
                  </Col>
               </Row>
               <Row style={{ paddingBottom: "10px" }}>
                  <Col span={6}>
                     <Text>Link</Text>
                  </Col>
                  <Col span={18}>
                     <Input onBlur={(e) => setLink(e.target.value)} defaultValue={surveyData.survey_link}></Input>
                  </Col>
               </Row>
            </div>
         </Modal>
      </>
   );
}