import React, { useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux'
import LessonService from '../../../services/LessonService';
import { EditLessonPage } from '../EditLessonPage';
import { transform } from 'lodash';

const getInitialData = (data, newData) => {
    var scale = "1";
       if (newData.type === "OBJECT" || newData.type === "WEB")
         scale = "0.25"

    return {
      type: newData.type,
      id: `${newData.type}__${Date.now()}__${data.length ?? 1}`,
      position: {
        top: "50px",
        left: "50px",
        rotate : "0,0",
        scale : "1",
        transform : ""
      },
      dimension: {
        width:  newData.type === "TEXT" ? "auto" : "500px",
        height: newData.type === "TEXT" ? "auto" : "500px",
        transform : newData.type === "TEXT" ? "translate(1px, 1px)" : `translate(1px, 1px) scale(${scale}, ${scale})`
      },
      metadata :
      {
        url : newData.url ?? "",
        videoimage :  newData.videoimage ?? "",
        videoid :  newData.videoid ?? "",
        content: newData.defaultcontent ?? "",
        backgroundcolor : "lightgray",
        filetype : newData.filetype ?? "",
        filename : newData.filename ?? "",
      },
       backgroundcolor : "white",
       backgroundimage : ""
  
    }
  }

  const setData = (data, newData) => {
    return {
      type: newData.type,
      id: `${newData.type}__${Date.now()}__${data.length ?? 1}`,
      position: {
        top: newData.top ?? "50px",
        left: newData.left ?? "50px",
        rotate : newData.rotate ?? "0,0",
        scale : "1",
        transform : newData.transform ?? ""
      },
      dimension: {
        width:  newData.width ?? "",
        height: newData.height ?? "",
        transform : newData.transform ?? ""
      },
      metadata :
      {
        url : newData.url ?? "",
        videoimage :  newData.videoimage ?? "",
        videoid :  newData.videoid ?? "",
        content: newData.defaultcontent ?? "",
        backgroundcolor : "lightgray",
        filetype : newData.filetype ?? "",
        filename : newData.filename ?? "",
      },
       backgroundcolor : "white",
       backgroundimage : ""
  
    }
  }  

export const CanvasContext = React.createContext({});  

export function LessonPage(props) {
    const [zoomValue, setZoomValue] = useState(1);
    const [lessonId, setLessonId] = useState("")
    const [dataLesson, setDataLesson] = useState(null)
 
    const [canvasData, setCanvasData] = useState([])
    const [canvasListData, setCanvasListData] = useState([])
    const [canvasListDataIdx, setCanvasListDataIdx] = useState(0)
    const [activeSelection, setActiveSelection] = useState(new Set())
    const [currentElementId, setCurrentElementId] = useState(null)
    const [enableQuillToolbar, setEnableQuillToolbar] = useState(false)
    const [targetRef, setTargetRef] = useState(null)
    const [editorRef, setEditorRef] = useState(null)
    const [activeEditable, setActiveEditable] = useState({});
    const [bodyBackgroundColor, setBodyBackgroundColor] = useState("white")
    const [bodyBackgroundImage, setBodyBackgroundImage] = useState("none")
    
    const isSelectAll = useRef(false)  
    const [lessonIdx, setLessonIdx] = useState(0)
    const [topicIdx, setTopicIdx] = useState("")
 
 
    const [isSave, setIsSave] = useState(false)

    const saveSectionData = () =>
    {
      
          let datearray = dataLesson.availabilitydate;
          let dvalue = "-"
          if (datearray !== undefined) {
             let d1 = datearray[0];
             let d2 = datearray[1];
             dvalue = d1 + ' - ' + d2;
          }
      
      
          
         var dataList = dataLesson.sections[topicIdx].contents.filter(p => p.id === lessonIdx)
            dataList[0]["data"] =  [...canvasListData]
            dataList[0]["version"] =  2
      
      
            let lessonTmp = {
              LessonId: dataLesson.lessonid,
              Title: dataLesson.title,
              TeacherName: dataLesson.teachername,
              Subject: dataLesson.subject,
              Overview: dataLesson.overview,
              Term: dataLesson.term,
              AvailabilityDate: dvalue,
              EducationalLevel: dataLesson.educationallevel,
              StudentViewing: dataLesson.studentviewing,
              LessonSharing: dataLesson.lessonsharing,
              GradeLevel: dataLesson.gradelevel,
              SectionList: dataLesson.sectionlist,
              LearningType: dataLesson.learningtype,
              LearningMode: dataLesson.learningmode,
              LearningPlan: dataLesson.learningplan,
              Conference: dataLesson.conference,
              Type: "",
              SectionVersion : 2,
              Sections: [...dataLesson.sections],
              AssignedStudents: dataLesson.assignedstudents,
           }
      
            LessonService.saveStateLesson(lessonTmp).then(response => {
              console.log(response)
              if (!response.data) {
              }
           }, (error) => {
              console.log(error.message);

           });
      
    }
       
    const updateCanvasData = data => {
        const currentDataIndex =
            canvasData.findIndex(canvas => canvas.id === data.id) ?? -1
        const updatedData = { ...canvasData?.[currentDataIndex]}
        updatedData.metadata.content =  data.content
        canvasData.splice(currentDataIndex, 1, updatedData)
        setCanvasData([...(canvasData || [])])
        // Update List Canvas
        var listData = {...canvasListData[canvasListDataIdx], data : canvasData}
        canvasListData.splice(canvasListDataIdx, 1, listData)
        setCanvasListData([...canvasListData])
        }

        const updateCanvasOrientation = (data, type) => {
        setIsSave(false)
        const currentDataIndex =
        canvasData.findIndex(canvas => canvas.id === currentElementId) ?? -1
        const updatedData = { ...canvasData?.[currentDataIndex]}

        if (type === "resize" && data.type === "TEXT-MOVABLE")
        {
            updatedData.dimension.height =  data.height
            updatedData.dimension.width =  data.width
        }

        //  if (type === "rotate")
          if (data.transform)
            updatedData.dimension.transform =  data.transform

        // if (type === "drag")
        // {    
            // updatedData.position.top = data.top
            // updatedData.position.left = data.left
        // }

        var tmpCanvasData = [...canvasData]  
        tmpCanvasData.splice(currentDataIndex, 1, updatedData)

        setCanvasData([...(tmpCanvasData || [])])
        // Update List Canvas
        var listData = {...canvasListData[canvasListDataIdx], data : tmpCanvasData}
        var tmpCanvasListData = [...canvasListData]
        tmpCanvasListData.splice(canvasListDataIdx, 1, listData)
        setCanvasListData([...tmpCanvasListData])
    
   }

const updateBackgroundData = data => {
    setIsSave(true)
    const currentDataIndex =
    canvasData.findIndex(canvas => canvas.id === currentElementId) ?? -1
    const updatedData = { ...canvasData?.[currentDataIndex]}
    if (data.type === "component")
    {
        updatedData.metadata.backgroundcolor =  data.color
        canvasData.splice(currentDataIndex, 1, updatedData)
        setCanvasData([...(canvasData || [])])

        var tmpData = canvasListData
        var tmpList = tmpData[canvasListDataIdx]
        tmpList.data = [...canvasData]
        setCanvasListData ([...tmpData])
    }
    else
    {
        var tmpData = canvasListData
        var tmpList = tmpData[canvasListDataIdx]
        if (data.color)
        tmpList.backgroundcolor = data.color 
        else if (data.imagesrc) 
        tmpList.backgroundimage = data.imagesrc 

        setCanvasListData (tmpData)
        setBodyBackgroundColor(data.color )
        setBodyBackgroundImage(data.imagesrc  ?? "")
    }

    // saveSectionData()
}


    const addElement = data => {
        setIsSave(true)
        const defaultData = getInitialData(canvasData, data)
        setCanvasData([...canvasData, { ...defaultData, type: data.type ?? "TEXT" }])
        activeSelection.clear()
        activeSelection.add(defaultData.id)
        setActiveSelection(new Set(activeSelection))
        // Update List Canvas
        var listData = {...canvasListData[canvasListDataIdx], data : [...canvasData, { ...defaultData, type: data.type ?? "TEXT" }]}
        canvasListData.splice(canvasListDataIdx, 1, listData)
        setCanvasListData([...canvasListData])

    // saveSectionData()
    }

    const addAIElements = slides => {
        setIsSave(true)
        var tmpCanvasListData = [...canvasListData]
        var canvasLength = canvasListData.length
        for (var slide of slides) {
            var tnpCanvasData = []
            tmpCanvasListData = [...tmpCanvasListData, {backgroundcolor : "#bcecee", backgroundimage : "none", data : []}]
            // Add header
            var data ={
                type : "TEXT",
                defaultcontent : `<h1><strong class="ql-font-Inter">${slide.topic}</strong></h1>`,
                top : "50px",
                left : "50px",
                width : "680px",
                height : "65px",
                transform : "translate(-17.883px, -18px)",
                url :  "",
                videoimage : "",
                videoid : "",                
            }
            var defaultData = setData(tnpCanvasData, data)
            tnpCanvasData = [...tnpCanvasData, { ...defaultData, type: data.type}]

            const youtubeUrl = `${process.env.REACT_APP_YOUTUBE_URL}${slide.url}`;
            // Add Video/Image
            var data ={
                type : "VIDEO",
                defaultcontent : "",
                top : "50px",
                left : "50px",
                width : "500px",
                height : "500px",
                transform : "translate(-39px, 5.5px) scale(0.938, 0.79) ",
                url :  "https://www.youtube.com/watch?v=" + slide.url,
                videoimage : slide.videoimage,
                videoid : slide.url, 
            }
             defaultData = setData(tnpCanvasData, data)
             tnpCanvasData = [...tnpCanvasData, { ...defaultData, type: data.type}]   
            // Add Bullet points
            var data ={
                type : "TEXT",
                defaultcontent : slide.subtopics,
                top : "50px",
                left : "50px",
                width : "326px",
                height : "376px",
                transform : " translate(460px, 77px) ",
                url :  "",
                videoimage : "",
                videoid : "", 
            }
             defaultData = setData(tnpCanvasData, data)
             tnpCanvasData = [...tnpCanvasData, { ...defaultData, type: data.type}]                     

            var listData = {...tmpCanvasListData[canvasLength], data : [...tnpCanvasData]}
            tmpCanvasListData.splice(canvasLength, 1, listData)
            canvasLength += 1
        }
        setCanvasListData([...tmpCanvasListData])

    }

    const deleteElement = useCallback(() => {
        setIsSave(true)
        var tmpCanvasData =  [...canvasData.filter(data => { 
        if (data.id === currentElementId) {
            return false
        }

        return true
        })]
        setCanvasData(tmpCanvasData)
        setActiveSelection(new Set(activeSelection))
        // Update List Canvas
        var listData = {...canvasListData[canvasListDataIdx], data : tmpCanvasData}
        canvasListData.splice(canvasListDataIdx, 1, listData)
        setCanvasListData([...canvasListData])    
    // saveSectionData() 
    }, [activeSelection, canvasData])

    const selectAllElement = useCallback(() => {
        isSelectAll.current = true
        canvasData.map(data => activeSelection.add(data.id || ""))
        setActiveSelection(new Set(activeSelection))
    }, [activeSelection, canvasData])


    const deleteData = () => {
        setIsSave(true)
        var tmpCanvasData =  [...canvasData.filter(data => { 
        if (data.id === currentElementId) {
            return false
        }

            return true
        })]
        setCanvasData(tmpCanvasData)
        setActiveSelection(new Set(activeSelection))
        // Update List Canvas
        var listData = {...canvasListData[canvasListDataIdx], data : tmpCanvasData}
        canvasListData.splice(canvasListDataIdx, 1, listData)
        setCanvasListData([...canvasListData])    
        // saveSectionData() 
    }

    const addCanvas = () => {
        // Add Item to the List Canvas
        setIsSave(true)
        setCanvasListData([...canvasListData, {backgroundcolor : "white", backgroundimage : "none", data : []}])
        setCanvasListDataIdx(canvasListData.length)
        setCanvasData([])
    }

    const setSelectedIdxCanvas = (idx) => {
        // set default in List Canvas
        var canvas = canvasListData[idx]?.data ?? []
        setCanvasListDataIdx(idx)
        setBodyBackgroundImage(canvasListData[idx]?.backgroundimage ?? "")
        setBodyBackgroundColor(canvasListData[idx]?.backgroundcolor ?? "white")
        setCanvasData(canvas)
        setTargetRef(null)
    }

    const deleteCanvas = (idx) => {
        setIsSave(true)
        if (canvasListData.length > 1)
        {
            setCanvasListData([
            ...canvasListData.filter((data,i) => {
                if (idx === i) {
                return false
                }
                return true
            })
            ])
            setSelectedIdxCanvas(idx-1)
            // saveSectionData()
        } 
        else
        {
            var initData = [{backgroundcolor : "white", backgroundimage : "none", data : []}]
            setCanvasListData(initData)
            setSelectedIdxCanvas(0)     
            // var listData = {backgroundcolor : "white", backgroundimage : "none", data : canvasData}
            // setCanvasListData([...canvasData, listData])   
            setBodyBackgroundImage("none")
                           
        }
        
    }

    const updateDataListOrder = (result) =>
    {
        setIsSave(true)
        var idx = result.destination.index
        const tmpListData =  [...canvasListData];
        const [removed] = tmpListData.splice(result.draggableId, 1);
        tmpListData.splice(idx, 0, removed);

        setCanvasListData([...tmpListData])
        //  var canvas = canvasListData[idx].data
        //  setCanvasListDataIdx(idx-1)
        //  setCanvasData(canvas)
        setTargetRef(null)     
    }

    const cloneCanvas = (idx) => {
        setIsSave(true)
        if (canvasListData.length > 0)
        {
            var tmpData = [...canvasListData]
            var tmpCanvas = {backgroundcolor : tmpData[idx].backgroundcolor, 
                            backgroundimage :  tmpData[idx].backgroundimage  , 
                            data : [...tmpData[idx].data]}

            // Deep Copy Object
            var deepCopyObj = JSON.parse(JSON.stringify(tmpCanvas));

            // deepCopyObj.data.map(data => {
            //   data.id = `${data.type}__${Date.now()}__${data.length ?? 1}`
            // })
            tmpData.splice(idx+1, 0, deepCopyObj)

            setCanvasData([...deepCopyObj.data])    
            setCanvasListData([...tmpData])

            setCanvasListDataIdx(idx+1)
            setTargetRef(null)
            // saveSectionData()
        } 
    }

    const CanvasItemChangePosition = (direction = 'up') => {
        var tmpCanvasData = [...canvasData]
        // get the original index of element
        const index = tmpCanvasData.findIndex((el) => el.id === currentElementId)

        // update new Index depending on direction
        let newIndex = -1
        if (direction === 'down' && index !== 0) {
            newIndex = index - 1
        } else if (direction === 'up' && index !== tmpCanvasData.length + 1) {
            newIndex = index + 1
        } else if (direction === 'back' && index !== 0) {
            newIndex = 0
        } else if (direction === 'front' && index !== tmpCanvasData.length + 1) {
            newIndex = tmpCanvasData.length
        }

        if (newIndex >= 0) {
            var element = tmpCanvasData[index]
            // filter the array to remove the element to move
            const filteredArray= tmpCanvasData.filter((el) => el.id !== currentElementId)

            // based on the new index, insert the element at the new position
            const updatedArray = [
                // Elements before the insertion point:
                ...filteredArray.slice(0, newIndex),
                // The element to move
                element,
                // Elements after the insertion point:
                ...filteredArray.slice(newIndex)
            ];

            setCanvasData(updatedArray)
            // setActiveSelection(new Set(activeSelection))
                // Update List Canvas
                var listData = {...canvasListData[canvasListDataIdx], data : updatedArray}
                canvasListData.splice(canvasListDataIdx, 1, listData)
                setCanvasListData([...canvasListData])    

        }
    }

    const context = {
        actions: {
            setCanvasData,
            setActiveSelection,
            updateCanvasData,
            setCanvasListData,
            updateBackgroundData,
            addElement,
            updateDataListOrder,
            setEnableQuillToolbar,
            setTargetRef,
            setCurrentElementId,
            setEditorRef,
            setActiveEditable,    
            setCanvasListDataIdx,
            addCanvas,
            setSelectedIdxCanvas,
            deleteCanvas,
            cloneCanvas,
            saveSectionData,
            setIsSave,
            selectAllElement,
            setLessonIdx,
            setTopicIdx,
            setDataLesson,
            setLessonId,
            updateCanvasOrientation,
            CanvasItemChangePosition,
            deleteData,
            setBodyBackgroundColor,
            setBodyBackgroundImage,
            addAIElements
        },
        state: {
            canvasData,
            canvasListData,
            activeSelection,
            enableQuillToolbar,
            targetRef,
            currentElementId,
            editorRef,
            activeEditable,
            bodyBackgroundColor,
            canvasListDataIdx,
            lessonIdx,
            topicIdx,
            isSave,
            bodyBackgroundImage,
            lessonId,
            bodyBackgroundImage
        }
    }

    return (
        <CanvasContext.Provider value={context}> 
              <EditLessonPage/>
         </CanvasContext.Provider>
       )    
}