import React, { useState, useEffect } from 'react';
import { Input, Table, Space, Typography, Tooltip, Button, Tag, Popconfirm, Modal } from 'antd';
import * as AntDIcons from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import ZoomService from '../../services/ZoomService';

const { Text } = Typography;

export default function ZoomPicker(props) {

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [loading, setLoading] = useState(true);
   // const [user_id, setUser_Id] = useState(0);
   const [zoomPickerList, setZoomPickerList] = useState([]);

   const g_user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const g_user_full_name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);
   const g_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const mastery_id = props.id;
   const mastery_title = props.title;

   useEffect(() => {
      getZoomPickerList();
   }, []);

   const getZoomPickerList = () => {
      setLoading(true);

      let url = `${process.env.REACT_APP_API_ZOOM}/masteryzoompickerlist`;
      axios.get(url, { headers: {} }).then((response) => {
         if (response.data) {
            let data = response.data;
            setZoomPickerList(data);
         }

         setLoading(false);
      }, (error) => {
         setLoading(false);
         console.log(error.message);
      });
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const zoomPickerColumns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'zoom_account',
         key: 'zoom_account',
         hidden: true,
      },

      {
         title: 'Select Zoom Account',
         key: 'description',
         render: payload => (
            // <Tooltip placement="topLeft" title={title}>
            //    <span style={{cursor:"default"}}>{title}</span>
            // </Tooltip>
            <>
               <Popconfirm
                  title={payload.status === 'in-use' ? "Are you sure you want to end this zoom session?" : "Are you sure you want to use this account?"}
                  onConfirm={() => payload.status === 'in-use' ? handleZoomPickerRoutine(payload.zoom_account, 'end') : handleZoomPickerRoutine(payload.zoom_account, 'start')}
                  okText="Yes"
                  cancelText="No"
               >
                  <Button
                     danger={payload.status === 'in-use' && (payload.current_user_id.toString() === g_user_id || g_role === 'Admin') ? true : false}
                     className='button-shadow'
                     type='primary'
                     shape='round'
                     size='middle'
                     block
                     disabled={payload.status === 'in-use' && payload.current_user_id.toString() !== g_user_id && g_role !== 'Admin' ? true : false}
                  >
                     {payload.status === 'in-use' && payload.current_user_id.toString() !== g_user_id && g_role !== 'Admin'
                        ?
                        <Text ellipsis style={{ color: "#000" }}>{payload.description}</Text>
                        :
                        <Text ellipsis style={{ color: "#fff" }}>{payload.description}</Text>
                     }
                  </Button>
               </Popconfirm>
            </>
         ),
         width: '20%'
      },
      {
         title: 'Status',
         key: 'status',
         render: payload => (
            <Tag
               style={{ fontSize: 12, cursor: 'default' }}
               color={payload.status === 'available' ? 'green' : 'red'}
            >
               {payload.status.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
            </Tag>
         ),
         width: '8%'
      },
      {
         title: 'Current User',
         key: 'current_user_fullname',
         dataIndex: 'current_user_fullname',
         width: '15%'
      },
      {
         title: 'Title',
         key: 'mastery_title',
         dataIndex: 'mastery_title',
         width: '30%'
      },
      {
         title: 'Subject',
         key: 'mastery_subject',
         dataIndex: 'mastery_subject',
         width: '15%'
      },
      {
         title: 'Meeting ID',
         dataIndex: 'current_meeting_id',
         key: 'current_meeting_id',
         width: '12%'
      },
   ].filter(item => !item.hidden);

   const handleZoomPickerRoutine = (zoom_account, mode) => {
      if (mode === 'start') {
         let startTime = new Date().getTime();

         const options = {
            "topic": mastery_title,
            "type": 2,
            "start_time": startTime,
            "duration": 60,
            "timezone": "Asia/Manila",
            "password": "braineelms",
            "agenda": "",
            "schedule_for": zoom_account,
            // "schedule_for": "erwin.nora@gmail.com",
            "settings": {
               "join_before_host": false,
               "host_video": true,
               "participant_video": true,
               "mute_upon_entry": false,
               "enforce_login": false,
               "auto_recording": "none",
               // "alternative_hosts": "erwin_nora@yahoo.com,nate.nora@gmail.com",
               // "breakout_room": {
               //    "enable": true,
               //    "rooms": [
               //       {
               //          "name": "braineeroom1",
               //          "participants": [
               //             "erwin.m.nora@gmail.com"
               //          ]
               //       }
               //    ]
               // },
            }
         }

         let token = "not needed anymore";

         let url = `${process.env.REACT_APP_API_ZOOM}/zoomcreatemeeting?zoom_account=${zoom_account}`;
         axios.post(url, options, { headers: {} }).then((response) => {
            if (response.data) {

               let data0 = response.data;

               let zoom_picker_options = {
                  Zoom_account: zoom_account,
                  Current_user_id: g_user_id,
                  Lesson_id: mastery_id,
                  Current_meeting_id: data0.id,
                  Zoom_token: token,
                  Start_url: data0.start_url,
                  Join_url: data0.join_url,
               }


               window.open(data0.start_url, "_blank").focus();

               //-- Update the status of selected zoom account
               url = `${process.env.REACT_APP_API_ZOOM}/zoompickerupdate?mode=start`;
               axios.post(url, zoom_picker_options, { headers: {} }).then((response) => {
                  if (response.data) {
                     let data1 = response.data;

                     if (data1 === 1) {
                        setZoomPickerList([]);
                        handleRefreshList();
                     }
                     else {
                        Modal.info({
                           content: 'Someone else is already using the account you selected.',
                           centered: true,
                        });
                     }
                  }
               }, (error) => {
                  console.log(error.message);
                  Modal.error({
                     content: 'It looks like there was an error in creating zoom meeting',
                     centered: true,
                  });
               });
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               content: 'It looks like there was an error in creating zoom meeting',
               centered: true,
            });
         });
      }
      else {
         var token = ZoomService.GenerateJWTKey();

         let zoom_picker_options = {
            Zoom_account: zoom_account,
            Current_user_id: g_user_id,
            Lesson_id: mastery_id,
            Current_meeting_id: '',
            Zoom_token: token,
            Start_url: '',
            Join_url: '',
         }

         let url = `${process.env.REACT_APP_API_ZOOM}/zoompickerupdate?mode=end`;
         axios.post(url, zoom_picker_options, { headers: {} }).then((response) => {
            if (response.data) {
               let data1 = response.data;
               setZoomPickerList([]);
               handleRefreshList();
            }
         }, (error) => {
            console.log(error.message);
         });
      }
   }

   const handleRefreshList = () => {
      setLoading(true);

      let url = `${process.env.REACT_APP_API_ZOOM}/masteryzoompickerlist`;
      axios.get(url, { headers: {} }).then((response) => {
         if (response.data) {
            let data = response.data;
            setLoading(false);
            setZoomPickerList(data);
         }
      }, (error) => {
         setLoading(false);
         console.log(error.message);
      });
   }

   return (
      <>
         <div className='student_list_container'>
            <Table
               rowKey={'uid'}
               loading={loading}
               size='small'
               columns={zoomPickerColumns}
               dataSource={zoomPickerList}
               // pagination={{ pageSize: 8 }}
               pagination={false}
               scroll={{
                  y: 'calc(100vh - 260px)',
                  x: '1000px',
               }}
            // style={{ minWidth: "1000px" }}
            />
         </div>
      </>
   );
}