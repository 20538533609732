import { useContext,useState,useEffect } from 'react'
import { PhotoshopPicker } from 'react-color'
import { Flex, Slider, Box, Text } from 'theme-ui'
import emptyColorPlaceholder from '../images/base-color-picker.png'
import { CanvasContext } from '../../hooks/LessonPage'
import { useNavigate } from "react-router-dom";
import { Button } from 'antd';

function Toolbox({props}) {

  const { state, actions } = useContext(CanvasContext);
  const [id, setId] = useState("")
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState({
    displayColorPicker: false,
  })
  // const handlers = useHandlers()

  const [options, setOptions] = useState({
    backgroundColor: '##fff',
  })
  const [option, setOption] = useState({ zoomValue: 100 })
  // const { zoomRatio } = useEditorContext()
  // const handlers = useHandlers()
  const updateOptions = (key, value) => {
    setOption({ ...option, [key]: value })
  }

  useEffect(() => {
    // Utils.verifyJwt();
    const search = window.location.search;
    const query = new URLSearchParams(search);
    const Id = query.get('Id')
    setId(Id)
  }, []);

  const onZoomChange = (event) => {
    // handlers.zoomHandler.zoomToRatio(event.target.value / 100)
    props.ZoomChange(event.target.value / 100)
  }

  const zoomString = parseFloat(option.zoomValue.toFixed(2))
  const handleClick = () => {
    setDropdown({ ...dropdown, displayColorPicker: !dropdown.displayColorPicker })
  }
  const handleClose = () => {
    setDropdown({ ...dropdown, displayColorPicker: false })
  }

  const popover = {
    position: 'absolute',
    zIndex: 2,
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  const onColorChange = color => {
    // handlers.frameHandler.setBackgroundColor(color.hex)
    setOptions({ ...options, backgroundColor: color.hex })
    var type = "component"
    if (!state?.currentElementId)
       type = ""
    var data ={color: color.hex , type : type}
    actions?.updateBackgroundData(data)
    actions?.setIsSave(true)
  }

  // useEffect(() => {
  //   setToolbar(state.enableQuillToolbar);
  // }, [state.enableQuillToolbar])

  return (
    <Flex
      sx={{
        height: 54,
        alignItems: 'center',
        padding: '0 1rem',
        background: '#fff',
        justifyContent: 'space-between',
      }}
    >
       <Flex>
        <div style={{ display : "flex", position: 'relative'}}>
          <div style={{ paddingRight : "10px" }}>
          <Button type="primary" size="medium" shape='round'  onClick={() => navigate(`/subject/edit?Id=${id}`)}  >
            Done
          </Button>
          {/* <i class="fas fa-chevron-circle-left" onClick={() => navigate(`/subject/edit?Id=${id}`)} style={{fontSize : "30px", paddingRight : "10px", cursor : "pointer"}}></i> */}
          </div>
          <div style={{ cursor: 'pointer' }} onClick={handleClick}>
              <img style={{ height: '30px', display: 'flex', borderRadius: '7px' }} src={emptyColorPlaceholder} alt="color picker" />
          </div>

          {dropdown.displayColorPicker ? (
            <div style={popover}>
              <div style={cover} onClick={handleClose} />
              <PhotoshopPicker color={options.backgroundColor} onChangeComplete={onColorChange} />
            </div>
          ) : null}
        </div>
       </Flex>   
       <Flex>
        {/* <Slider value={option.zoomValue ? option.zoomValue : 1} onChange={e => onZoomChange(e)} max={300} />
        <Box
          sx={{
            padding: '0 1rem',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            background: '#fff',
            fontWeight: '600',
          }}
        >
          {zoomString}
          <Text sx={{ paddingLeft: '0.2rem' }}>%</Text>
        </Box>  */}
      </Flex> 

      {/* <div style={{paddingLeft  : "10px"}}>
      {<FontToolbar isEditEnable={isEditEnable}/>}
      </div> */}
    </Flex>
  )
}

export default Toolbox
