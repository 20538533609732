import React, { useContext } from "react"
import { CanvasContext } from "../hooks/LessonPage"

import ReactPlayer from "react-player";
import PDFLessonViewer from "./PDFLessonViewer";
import ViewMSOfficeFile from "./ViewMSOfficeFile";

// import { Worker } from '@react-pdf-viewer/core';

const FilesElement = props => {
  const { metadata, id, position, dimension, type } = props
  const { actions } = useContext(CanvasContext)
  const elementRef = React.useRef(null)
  const [showGrids, setShowGrids] = React.useState(false)

  const onClick = (e) => {
    e.stopPropagation();
    actions?.setTargetRef(elementRef)
    actions?.setCurrentElementId(id)
    var activeContent ={id : id, type : type , metadata : metadata, dimension : dimension, position : position }
    actions?.setActiveEditable(activeContent)    
  }

  const onEnter = (e,value) =>
  {
    e.stopPropagation()
    e.preventDefault()
    setShowGrids(value)
  }


  const renderElement = (type) =>
  {
    return(
          <>
            <div 
                ref={elementRef} 
                onClick={(e) => onClick(e)}       
                onMouseEnter={(e) =>  onEnter(e,true)}
                onMouseLeave={(e) =>  onEnter(e,false)}            
                // onDoubleClick={(e) => textDoubleClick(e)}                      
                 style={{ position : "absolute", 
                          left: position?.left || 0, 
                          top: position?.top || 0,
                          width: dimension?.width || 0, 
                          height: dimension?.height || 0,     
                          transform : dimension?.transform || "translate(1px, 1px)",                 
                          fontFamily: "Arial",
                          fontSize: "13px",
                          padding: 0,
                          float : "left",
                          border: `2px solid ${
                            showGrids 
                              ? "#21DEE5"
                              : "transparent"
                          }`,                            
                        }}>

                   {props.metadata.filetype === "image" && <img style={{position: "relative" , width: "100%", height : "100%"}} src={props.metadata.filename}></img>}
                   {props.metadata.filetype === "pdf" && 
                   <div  style={{ width : "100%", height : "100%", backgroundColor : "white" }}>
                      {/* <RenderPDFViewer  url={props.metadata.filename} height="100%" /> */}
                      {/* <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}> */}
                           <PDFLessonViewer fileUrl={props.metadata.filename} readonly={true}/>
                      {/* </Worker> */}
                   </div>
                   } 
                   {props.metadata.filetype === "msoffice" && 
                     <ViewMSOfficeFile filename={`${props.metadata.filename}`} readonly={true}/>
                  }            
                  {props.metadata.filetype === "video" &&       <ReactPlayer
                                 controls={true}
                                 width='100%'
                                 height={"100%"}
                                 url={props.metadata.filename}
                              />}
            </div>   
            </>
    )     
  } 

  return (
    renderElement(type)
  )
}

export default FilesElement
