import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Select, Input, Row, Col, Tag,
   Modal, Typography, Spin, Breadcrumb, Popconfirm, Empty
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
// import { StaffEdit } from './Components/StaffEdit';
// import { StaffAdd } from './Components/StaffAdd';

// const { Option } = Select;
const { Text } = Typography;

export function Subjects() {
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [subjectList, setSubjectList] = useState([]);
   const [searchLoading, setSearchLoading] = useState(false);

   const [updating, setUpdating] = useState(false);
   const [editModalVisible, setEditModalVisible] = useState(false);
   const [addModalVisible, setAddModalVisible] = useState(false);

   const [subject, setSubject] = useState(null);
   const [subjectDesc, setSubjectDesc] = useState(null);
   const [subjectCode, setSubjectCode] = useState(null);

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      GetSubjectList();
   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: 'Subject Code',
         key: 'subject_Code',
         ...getColumnSearchProps('subject_Code'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.subject_Code}>
               <Text>
                  {
                     payload.subject_Code
                  }
               </Text>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Subject',
         key: 'description',
         ...getColumnSearchProps('description'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.description}>
               <Text>
                  {
                     payload.description
                  }
               </Text>
            </Tooltip>
         ),
         width: '65%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Edit Subject'>
                     <AntDIcons.EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEdit(payload.id, payload.subject_Code, payload.description)} />
                  </Tooltip>
                  {/* <Tooltip placement="top" title='Remove Subject'>
                     <Popconfirm
                        title="Are you sure you want to remove this Subject?"
                        onConfirm={() => handleRemove(payload.id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip> */}
               </Space>
            </>
         },
         width: '15%'
      },
   ].filter(item => !item.hidden);

   const GetSubjectList = () => {
      setSearchLoading(true);

      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getsubjectlist`).then((response) => {
         if (response.data) {
            const data = response.data;

            setSubjectList(data);
            setSearchLoading(false);
         }
      }, (error) => {
         setSearchLoading(false);
         console.log(error.message);
      });
   }

   const handleAddNewSubject = () => {
      setAddModalVisible(true);
   }

   const handleEdit = (id, subjectcode, desc) => {
      // sectionID = id;
      setSubject(id);
      setSubjectDesc(desc);
      setSubjectCode(subjectcode);
      setEditModalVisible(true);
   }

   const handleSave = () => {
      if (subjectDesc !== null && subjectDesc !== '' && subjectCode !== null && subjectCode !== '') {
         setUpdating(true);

         var data = {
            description: subjectDesc,
            subject_code: subjectCode,
         }

         axios.post(`${process.env.REACT_APP_API_MANAGEMENT}/createsubject`, data).then((response) => {
            if (response.data) {
               const data = response.data;

               GetSubjectList();
               setUpdating(false);
               // setEditModalVisible(false);
               // setAddModalVisible(false);

               if (data.Code === 1) {
                  Modal.error({
                     title: 'Subject Management',
                     content: 'Ooops! It seems that there was a problem encountered while processing your request.',
                     centered: true,
                  });
               }
               else {
                  Modal.success({
                     title: 'Subject Management',
                     content: 'Create successful.',
                     centered: true,
                     onOk: () => { handleCancel() },

                  });
               }

               setUpdating(false);
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Subject Management',
               content: 'Ooops! It seems that there was a problem encountered while processing your request.',
               centered: true,
            });
            setUpdating(false);
         });
      } else {
         Modal.info({
            title: 'Subject Management',
            content: (
               <>
                  Please complete the folowing before saving:
                  <ul>
                     <li>
                        New Subject
                     </li>
                     <li>
                        Subject Code
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   const handleUpdate = () => {
      if (subjectDesc !== null && subjectDesc !== '' && subjectCode !== null && subjectCode !== '') {
         setUpdating(true);

         var data = {
            id: subject,
            description: subjectDesc,
            subject_code: subjectCode,
         }

         axios.post(`${process.env.REACT_APP_API_MANAGEMENT}/updatesubject`, data).then((response) => {
            if (response.data) {
               const data = response.data;

               GetSubjectList();
               setUpdating(false);
               // setEditModalVisible(false);
               // setAddModalVisible(false);

               if (data.Code === 1) {
                  Modal.error({
                     title: 'Subject Management',
                     content: 'Ooops! It seems that there was a problem encountered while processing your request.',
                     centered: true,
                  });
               }
               else {
                  Modal.success({
                     title: 'Subject Management',
                     content: 'Update successful.',
                     centered: true,
                     onOk: () => { handleCancel() },
                  });
               }

               setUpdating(false);
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Subject Management',
               content: 'Ooops! It seems that there was a problem encountered while processing your request.',
               centered: true,
            });

            setUpdating(false);
         });

         // GetSubjectList();
         // setUpdating(false);
         // setEditModalVisible(false);
         // setAddModalVisible(false);
      } else {
         Modal.info({
            title: 'Subject Management',
            content: (
               <>
                  Please complete the folowing before saving:
                  <ul>
                     <li>
                        Subject
                     </li>
                     <li>
                        Subject Code
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   const handleCancel = () => {
      setEditModalVisible(false);
      setAddModalVisible(false);
   }

   const handleRemove = (id) => {
      setUpdating(true);

      axios.post(`${process.env.REACT_APP_API_MANAGEMENT}/deletesubject?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;

            GetSubjectList();
            setUpdating(false);
            // setEditModalVisible(false);
            // setAddModalVisible(false);

            if (data.Code === 1) {
               Modal.error({
                  title: 'Subject Management',
                  content: 'Ooops! It seems that there was a problem encountered while processing your request.',
                  centered: true,
               });
            }
            else {
               Modal.success({
                  title: 'Subject Management',
                  content: 'Remove successful.',
                  centered: true,
                  onOk: () => { handleCancel() },
               });
            }

            setUpdating(false);
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Subject Management',
            content: 'Ooops! It seems that there was a problem encountered while processing your request.',
            centered: true,
         });

         setUpdating(false);
      });
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Management</li>
                     <li><span>/</span></li>
                     <li className="active">Subjects</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Management</Breadcrumb.Item>
                     <Breadcrumb.Item>Subject</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
            </div>

            <Row gutter={12}>
               <Col className="gutter-row" span={24} style={{ textAlign: "left" }}>
                  {/* <Tooltip placement="top" title='Create Mastery Assessment'> */}
                  <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => handleAddNewSubject()}>
                     <Text ellipsis style={{ color: "#fff" }}><AntDIcons.PlusOutlined />&nbsp;Add New Subject</Text>
                  </Button>
                  {/* </Tooltip> */}
               </Col>
            </Row>

            <Row gutter={12} style={{ marginTop: '15px' }} >
               <Col span={24}>
                  <div className="dashboard-item-inner3">
                     {
                        searchLoading
                           ?
                           <div className='center-empty-space'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                           :
                           (subjectList.length > 0 && !searchLoading)
                              ?
                              <Table
                                 rowKey={'uid'}
                                 // loading={searchLoading}
                                 size='small'
                                 columns={columns}
                                 dataSource={subjectList}
                                 pagination={false}
                                 // style={{ width: '70%' }}
                                 scroll={{
                                    y: 'calc(100svh - 12rem)',
                                    x: '1000px'
                                 }}
                                 footer={() => 'Total Records: ' + subjectList.length}
                              />
                              :
                              <div className='center-empty-space'>
                                 <Empty image="../images/brainee_mascot.png" description="No records found" />
                              </div>
                     }
                     {/* {
                        !searchLoading
                           ?
                           <Table
                              rowKey={'uid'}
                              loading={searchLoading}
                              size='small'
                              columns={columns}
                              dataSource={subjectList}
                              pagination={false}
                              // style={{ width: '70%' }}
                              scroll={{
                                 y: 'calc(100svh - 12rem)',
                                 x: '1000px'
                              }}
                              footer={() => 'Total Records: ' + subjectList.length}
                           />
                           :
                           <div className='loading-please-wait'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                     } */}
                  </div>
               </Col>
            </Row>
         </div>

         {/* Modals */}
         <Modal
            title={"Add New subject"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            onOk={() => { handleSave() }}
            open={addModalVisible}
            width='35%'
            style={{ minWidth: '300px' }}
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Save'}
         // footer={null}
         >
            <Spin spinning={updating} tip={"Updating, please wait..."}>
               <Space direction='vertical' style={{ width: "100%" }} >
                  <Text>New Subject</Text>
                  <Input
                     allowClear
                     size='large'
                     style={{ borderRadius: 7 }}
                     placeholder="Enter New Subject"
                     // onChange={(event) => { setSubjectDesc(event.target.value) }}
                     onBlur={(event) => { setSubjectDesc(event.target.value) }}
                  />
                  <Text>Subject Code</Text>
                  <Input
                     allowClear
                     size='large'
                     style={{ borderRadius: 7 }}
                     placeholder="Enter Subject Code"
                     // onChange={(event) => { setSubjectCode(event.target.value) }}
                     onBlur={(event) => { setSubjectCode(event.target.value) }}
                  />
               </Space>
            </Spin>
         </Modal>

         <Modal
            title={"Edit Subject"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            onOk={() => { handleUpdate() }}
            open={editModalVisible}
            width='35%'
            style={{ minWidth: '300px' }}
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Update'}
         // footer={null}
         >
            <Spin spinning={updating} tip={"Updating, please wait..."}>
               <Space direction='vertical' style={{ width: "100%" }} >
                  <Text>Subject</Text>
                  <Input
                     allowClear
                     size='large'
                     style={{ borderRadius: 7 }}
                     placeholder="Enter New Subject"
                     // onChange={(event) => { setSubjectDesc(event.target.value) }}
                     onBlur={(event) => { setSubjectDesc(event.target.value) }}
                     defaultValue={subjectDesc}
                  />
                  <Text>Subject Code</Text>
                  <Input
                     allowClear
                     size='large'
                     style={{ borderRadius: 7 }}
                     placeholder="Enter Subject Code"
                     // onChange={(event) => { setSubjectCode(event.target.value) }}
                     onBlur={(event) => { setSubjectCode(event.target.value) }}
                     defaultValue={subjectCode}
                  />
               </Space>
            </Spin>
         </Modal>
      </>
   );
}