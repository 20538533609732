import lessonReducer from './lesson'
import assOptionDataReducer from './assessmentOption';
import lessonrecordReducer from './lessonrecord'
import lessonsectionReducer from './lessonrecord'
import lookupReducer from './lookup'
import noticeboardReducer from './noticeboard'
import masteryAssessmentReducer from './masteryassessment';
import masterySearchKeysReducer from './masterysearchkeys';
import masteryAssignmentReducer from './masteryassignment';
import {combineReducers} from 'redux'

const allReducers = combineReducers({
   //we access this by any key we want
   lesson: lessonReducer,
   assOption: assOptionDataReducer,
   lessonrec: lessonrecordReducer,
   lessonsection: lessonsectionReducer,
   lookup: lookupReducer,
   noticeboard: noticeboardReducer,
   masteryrec: masteryAssessmentReducer,
   masterysearchkeys: masterySearchKeysReducer,
   masteryassignment: masteryAssignmentReducer,
});

export default allReducers