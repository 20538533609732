import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Form, Select, DatePicker, Modal, Row, Col, Breadcrumb } from 'antd';
import { Navigate, useNavigate, Link } from "react-router-dom";
// import { Editor } from '@tinymce/tinymce-react';
// import SideCalendar from '../../side-calendar/SideCalendar';
// import NoticeBoard from '../NoticeBoard';
import { useSelector } from 'react-redux';
// import axios from 'axios';
import uuid from 'react-uuid';
import NoticeService from '../../../services/NoticeService';

import moment from 'moment';
// import Discussions from '../../lesson/Discussions';
import Utils from '../../../misc/Utils';
import Enums from '../../../misc/Enums';
import Wysiwyg from '../../../components/wysiwyg/wysiwyg';
// import NoticeBoardList from '../NoticeBoardList';

const { RangePicker } = DatePicker;

function CreateNotice(props) {
   const navigate = useNavigate();
   const [title, setTitle] = useState("")
   const [educlevel, setEducLevel] = useState()
   const [gradelevel, setGradeLevel] = useState()
   const [section, setSection] = useState()
   const [sectionList, setSectionList] = useState()
   const [levelList, setLevelList] = useState()
   const [content, setContent] = useState("")
   const [disablesection, setDisableSection] = useState(false)
   const [disablelevel, setDisableLevel] = useState(false)
   const [done, setDone] = useState(false)
   const [effectivityDate, setEffectivityDate] = useState();

   const { TextArea } = Input;

   const editorRef = useRef(null);
   const [form] = Form.useForm();
   //  const dispatch = useDispatch();
   const lookupSelector = useSelector(state => state.lookup);

   const containerId = useRef();
   const searchValue = useRef();
   const actions = [<span>Reply to</span>];

   const dateFormat = 'MMM D, YYYY h:mm a';
   const customFormat = value => `${value.format(dateFormat)}`;

   useEffect(() => {
      let urllink = `${process.env.REACT_APP_API_LESSON_LOOKUP}`
      console.log(urllink)
   }, []
   );

   const FormatDate = () => {
      if (effectivityDate && effectivityDate.length > 0) {
         const d1 = effectivityDate[0];
         const d2 = effectivityDate[1];
         const arr = [moment(d1, dateFormat), moment(d2, dateFormat)];
         return arr;
      } else {
         console.log('no value');
         return [];
      }
   }

   const handleChangeDate = (date, dateString) => {
      const d = dateString[0];//'Jan 21, 2022, 7:07:00 am';
      const d2 = dateString[1];//'Jan 21, 2022, 7:07:00 am';
      const dvalue = d + ' - ' + d2;
      setEffectivityDate(dateString);
   }

   const handleFormValuesChange = (changedValues) => {
      const formFieldName = Object.keys(changedValues)[0];
      if (formFieldName === "educationallevel") {
         setEducLevel(changedValues[formFieldName]);
         form.setFieldsValue({ level: undefined });
         form.setFieldsValue({ sections: undefined });
         setSectionList([])
      } else if (formFieldName === "level") {
         setGradeLevel(changedValues[formFieldName]);
         form.setFieldsValue({ sections: undefined });
         setSectionList([])
      }
   };

   const handleChangeTitle = (e) => {
      setTitle(e)
   }


   const handleChangeEducLevel = (e) => {
      console.log('Educ Change')
      setEducLevel(e)
      console.log(`value = ${e}`)
      let templevels = lookupSelector.acadProgramLevels;
      let filtered = templevels.filter(item => item.programCode === `${e}`);
      setLevelList(filtered)
      if (e == 99) {
         console.log('disable')
         setDisableLevel(true)
         setDisableSection(true)
      } else {
         console.log('not disable')
         setDisableLevel(false)
         setDisableSection(false)
      }
   }

   const handleChangeLevel = (e) => {
      console.log('handleChangeLevel')
      console.log(e)
      let tempsections = lookupSelector.classSections;
      let filtered = tempsections.filter(item => item.code === `${e}`);
      setGradeLevel(e)
      setSectionList(filtered)
      if (e == 99) {
         setDisableSection(true)
      } else {
         setDisableSection(false)
      }
   }

   const handleChangeSection = (e) => {
      setSection(e);
   }

   const handleChangeEditor = (content, editor) => {
      setContent(content);
   }

   const onFinish = (values) => {
      console.log('Success:', values);
      let user = Utils.getCurrentUserInfo(Enums.UserInfo.id)
      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
      var notice_id = "notice_" + o2o_mode + "_" + uuid();
      let gradeLevel = gradelevel;
      let sections = section;
      let educLevel = educlevel;
      let effective_start_date = effectivityDate[0];
      let effective_end_date = effectivityDate[1];

      if (typeof educLevel === 'undefined') {
         educLevel = "";
         console.log('is undefined');
      }

      if (typeof gradeLevel === 'undefined') {
         gradeLevel = "";
         console.log('is undefined');
      }

      if (typeof sections === 'undefined') {
         sections = "";
         console.log('is undefined');
      }

      let notice_default = {
         id: notice_id,
         title: title,
         content: content,
         active: 0,
         issection: 0,
         sectionlist: sections,
         isgradelevel: 0,
         gradelevellist: gradeLevel,
         isacad: 0,
         acadlevellist: educLevel,
         staffid: user,
         start_date: effective_start_date,
         end_date: effective_end_date,
      }
      console.log(notice_default);

      NoticeService.saveNotice(notice_default).then(response => {
         console.log(response);

         if (response.data) {
            Modal.success({
               title: 'Create Notice Board',
               content: 'Notice Record was created.',
               centered: true
            });

            setDone(true);
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Create Notice Board',
            content: 'Notice Record creation failed.',
            centered: true
         });
         setDone(false)
      });

   };

   return (
      <>
         {done && <Navigate to={'/notice'} replace state={{}} />}
         <div className="dashboard-area">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li><a className="active" href="#" onClick={() => navigate('/notice')}>Notice Board</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Create</a></li>
                  </ul> */}
                  <Breadcrumb style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate('/home')}>Home</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <a className="active" href="#" onClick={() => navigate('/notice')}>Notice Board</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Create</Breadcrumb.Item>
                  </Breadcrumb>
                  <div className='CommonForm'>
                     <Row gutter={12}>
                        <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>

                        </Col>
                     </Row>
                     <Form
                        form={form}
                        className='DetailsForm'
                        colon={false}
                        onFinish={onFinish}
                        // onFinishFailed={onFinishFailed} 
                        onValuesChange={handleFormValuesChange}
                        autoComplete="off"
                     >
                        <Row gutter={12}>
                           <Col xs={24} xl={12}>
                              <Form.Item label="Title" name="title" style={{ display: 'inline' }}
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Title should not be blank!'
                                    },
                                 ]} >
                                 <Input
                                    size="small"
                                    placeholder="Title"
                                    value={title}
                                    style={{ color: '#000000' }}
                                    onChange={(event) => handleChangeTitle(event.target.value)}
                                 />

                              </Form.Item>
                           </Col>
                           <Col xs={24} xl={12}>
                              <Form.Item label="Educational Level" name="educationallevel" style={{ display: 'inline' }}
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Please choose an Educational Level!'
                                    },
                                 ]} initialValue={''}
                              >
                                 <Select defaultValue={''} onChange={handleChangeEducLevel}>
                                    <Select.Option value={'99'}>{'All'}</Select.Option>
                                    {lookupSelector.acadPrograms.map((level) => (
                                       <Select.Option value={level.programCode}>{level.description}</Select.Option>
                                    ))}
                                 </Select>
                              </Form.Item>
                           </Col>
                        </Row>
                        {/* <Row gutter={24}>
                              
                        </Row> */}

                        <Row gutter={12} style={{ paddingBottom: "10px" }}>
                           <Col xs={24} xl={12}>
                              <Form.Item label="Grade Level" name="level" style={{ display: 'inline' }}
                              // rules={[
                              // { 
                              // required: true,
                              // message: 'Please choose a Level!'
                              // },
                              // ]} initialValue={''}
                              >

                                 <Select
                                    // style={{  width: '40%' }} 
                                    value={gradelevel}
                                    onChange={(e) => {
                                       handleChangeLevel(e);
                                    }}
                                    disabled={disablelevel}
                                 >
                                    <Select.Option value={99}>{'All'}</Select.Option>
                                    {
                                       lookupSelector.acadProgramLevels.filter(item => item.programCode === `${educlevel}`).map((filtered) => (
                                          <Select.Option value={filtered.value}>{filtered.label}</Select.Option>
                                       ))}
                                    {/* { levelList.map((level) => (
                                    <Select.Option value={level.value}>{level.label}</Select.Option>
                                    ))}   */}
                                 </Select>

                                 {/* <Select
                                    placeholder="Please select"
                                    mode="multiple"
                                    allowClear
                                    showArrow
                                    style={{ width: '100%' }}
                                    options={levelList}
                                    onChange={(e) => {
                                       handleChangeLevel(e);
                                       }}
                                    /> */}
                                 {/* <Checkbox>All</Checkbox> */}

                              </Form.Item>
                           </Col>
                           <Col xs={24} xl={12}>
                              <Form.Item label="Sections (Multiple)" name="sections" style={{ display: 'inline' }}

                              // rules={[
                              // { 
                              // required: true,
                              // message: 'Please choose a Section!'
                              // },
                              // ]}
                              // initialValue={ '' }
                              >
                                 <Select
                                    placeholder="Please select"
                                    mode="multiple"
                                    allowClear
                                    showArrow
                                    style={{ width: '100%' }}
                                    options={sectionList}
                                    onChange={handleChangeSection}
                                    disabled={disablesection}
                                 />

                              </Form.Item>
                           </Col>
                        </Row>

                        <Row gutter={12} style={{ paddingBottom: "10px" }}>
                           <Col className="gutter-row" span={24}>
                              <Form.Item label="Effectivity Date" name="effectivedate" style={{ display: 'inline' }}>
                                 <RangePicker
                                    showTime={{ format: 'hh:mm A', use12Hours: true }}
                                    format={customFormat}
                                    defaultValue={FormatDate}
                                    onCalendarChange={handleChangeDate}
                                 />
                              </Form.Item>
                           </Col>
                        </Row>



                        {/* <Row gutter={24}>
                           
                        </Row> */}

                        <Row gutter={12}>
                           <Col className="gutter-row" span={24}>
                              {/* <div style={{position: "relative", height : "40%", padding : "10px" }}> */}
                              <label>Content</label>
                              <Wysiwyg 
                                 changeContentCallback={(e) => handleChangeEditor(e)} 
                                 content={''} 
                                 height={400}
                                 contentHeight={360}
                              />
                              {/* <Editor
                                 apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                                 onInit={(evt, editor) => editorRef.current = editor}
                                 //  onEditorChange={(content) =>  handleChangeEditor(content)}
                                 onFocusOut={(e) => handleChangeEditor(e.target.innerHTML)}
                                 initialValue=""
                                 init={{
                                    menubar: true,
                                    height: 200,

                                    plugins: [
                                       'advlist autolink lists link image charmap print preview anchor',
                                       'searchreplace visualblocks code fullscreen',
                                       'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                       'bold italic backcolor | alignleft aligncenter ' +
                                       'alignright alignjustify | bullist numlist outdent indent | ' +
                                       'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                 }}
                              /> */}
                              {/* </div>        */}
                           </Col>
                        </Row>
                        <Row gutter={12}>
                           <Col className="gutter-row" span={24}>
                              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                 <Form.Item className="gutter-row">
                                    <Button style={{ marginLeft: "auto", marginRight: 10, marginTop: 10, background: '#4abec7', color: '#fff' }}
                                       type="primary"
                                       htmlType="submit"
                                       shape='round'
                                    // onClick={ handleNextClick }
                                    >
                                       Save
                                    </Button>
                                 </Form.Item>
                              </div>
                           </Col>
                        </Row>
                     </Form>
                  </div>
               </div>
            </div>
         </div>
         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}
      </>
   );
}

export default CreateNotice;