import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Select, Input, Row, Col, Tag,
   Modal, Typography, Spin, Breadcrumb, Popconfirm, Empty
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
// import { StaffEdit } from './Components/StaffEdit';
// import { StaffAdd } from './Components/StaffAdd';

const { Option } = Select;
const { Text } = Typography;

var sectionID = null;

export function SubjectGroups() {
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [sectionList, setSectionList] = useState([]);
   const [levelList, setLevelList] = useState([]);
   const [subjectList, setSubjectList] = useState([]);
   const [subjectGroupList, setSubjectGroupList] = useState([]);

   const [searchLoading, setSearchLoading] = useState(false);
   const [updating, setUpdating] = useState(false);
   const [editModalVisible, setEditModalVisible] = useState(false);
   const [addModalVisible, setAddModalVisible] = useState(false);

   const [subjectGroup, setSubjectGroup] = useState(null);
   const [subjectGroupDesc, setSubjectGroupDesc] = useState(null);
   const [level, setLevel] = useState(null);

   const [selectedSections, setSelectedSections] = useState([]);
   const [selectedSubjects, setSelectedSubjects] = useState([]);

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      GetSubjectGroupList();
   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'level',
         key: 'level',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'assigned_sections',
         key: 'assigned_sections',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'assigned_subjects',
         key: 'assigned_subjects',
         hidden: true,
      },
      {
         title: 'Subject Group',
         key: 'subject_group',
         ...getColumnSearchProps('subject_group'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.subject_group}>
               <Text>
                  {
                     payload.subject_group
                  }
               </Text>
            </Tooltip>
         ),
         width: '15%'
      },
      {
         title: 'Level - Section',
         key: 'assigned_sections_desc',
         ...getColumnSearchProps('assigned_sections_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            // <Tooltip placement="topLeft" title={payload.level_section}>
            <>
               {
                  (payload.assigned_sections_desc !== null && payload.assigned_sections_desc !== '') &&
                  <Text>
                     <div dangerouslySetInnerHTML={{ __html: payload.assigned_sections_desc.replace(new RegExp(',', 'g'), '<br />') }} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} />
                  </Text>
               }
            </>
            // </Tooltip>
         ),
         width: '25%'
      },
      {
         title: 'Subject',
         key: 'assigned_subjects_desc',
         ...getColumnSearchProps('assigned_subjects_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            // <Tooltip placement="topLeft" title={payload.subject}>
            <>
               {
                  (payload.assigned_subjects_desc !== null && payload.assigned_subjects_desc !== '') &&
                  <Text>
                     <div dangerouslySetInnerHTML={{ __html: payload.assigned_subjects_desc.replace(new RegExp(',', 'g'), '<br />') }} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} />
                  </Text>
               }
            </>
            // </Tooltip>
         ),
         width: '45%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Edit Subject Group'>
                     <AntDIcons.EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEdit(payload.id, payload.subject_group, payload.level, payload.assigned_sections, payload.assigned_subjects)} />
                  </Tooltip>
                  {/* <Tooltip placement="top" title='Subject Group'>
                     <Popconfirm
                        title="Are you sure you want to remove this subject group?"
                        onConfirm={() => handleRemove(payload.id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip> */}
               </Space>
            </>
         },
         width: '15%'
      },
   ].filter(item => !item.hidden);

   const section_columns = [
      {
         title: '',
         dataIndex: 'value',
         key: 'value',
         hidden: true,
      },
      {
         title: 'Section',
         key: 'name',
         ...getColumnSearchProps('name'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.name}>
               <Text>
                  {
                     payload.name
                  }
               </Text>
            </Tooltip>
         ),
         width: '100%'
      },
   ].filter(item => !item.hidden);

   const subject_columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: 'Subject',
         key: 'description',
         ...getColumnSearchProps('description'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.description}>
               <Text>
                  {
                     payload.description
                  }
               </Text>
            </Tooltip>
         ),
         width: '100%'
      },
   ].filter(item => !item.hidden);

   const GetLevels = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/levellist`).then((response) => {
         if (response.data) {
            const data = response.data;
            var tmp_levels = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               tmp_levels.push(obj);
            });

            setLevelList(tmp_levels);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleLevelChange = (val) => {
      setLevel(val);
      setSelectedSections([]);
      setSelectedSubjects([]);

      GetSectionList([], val);
   }

   const GetSubjectGroupList = () => {
      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getsubjectgrouplist`).then((response) => {
         if (response.data) {
            const data = response.data;

            setSubjectGroupList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const GetSectionList = (selected, gradelevel) => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${gradelevel}`).then((response) => {
         if (response.data) {
            const data = response.data;

            for (var i = 0; i < data.length; i++) {
               data[i].key = data[i].value;
            }

            setSectionList(data);
            setSelectedSections(selected);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const GetSubjectList = (selected) => {
      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getsubjectlist`).then((response) => {
         if (response.data) {
            const data = response.data;

            for (var i = 0; i < data.length; i++) {
               data[i].key = data[i].id;
            }

            setSubjectList(data);
            setSelectedSubjects(selected);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleAdd = () => {
      setSectionList([]);
      setSubjectGroup(null);
      setLevel(null);
      GetLevels();
      GetSubjectList([])

      setAddModalVisible(true);
   }

   const handleEdit = (id, subjectgroup, level, selectedsections, selectedsubjects) => {
      GetLevels();
      GetSectionList(selectedsections !== null ? selectedsections.split(',').map(Number) : [], level);
      GetSubjectList(selectedsubjects !== null ? selectedsubjects.split(',').map(Number) : []);
      setSubjectGroup(id);
      setSubjectGroupDesc(subjectgroup);
      setLevel(level);

      setEditModalVisible(true);
   }

   const handleSave = () => {
      if (subjectGroupDesc !== null && subjectGroupDesc !== '' && level !== null && level !== undefined && selectedSections.length > 0 && selectedSubjects.length > 0) {
         setUpdating(true);

         var data = {
            subject_group: subjectGroupDesc,
            level: level,
            assigned_sections: selectedSections.length > 0 ? selectedSections.join(',') : [],
            assigned_subjects: selectedSubjects.length > 0 ? selectedSubjects.join(',') : [],
         }

         axios.post(`${process.env.REACT_APP_API_MANAGEMENT}/createsubjectgroup`, data).then((response) => {
            if (response.data) {
               const data = response.data;

               setUpdating(false);

               GetSubjectGroupList();
               setUpdating(false);
               // setEditModalVisible(false);
               // setAddModalVisible(false);

               if (data.Code === 1) {
                  Modal.error({
                     title: 'Subject Group Management',
                     content: 'Ooops! It seems that there was a problem encountered while processing your request.',
                     centered: true,
                  });
               }
               else {
                  Modal.success({
                     title: 'Subject Group Management',
                     content: 'Create successful.',
                     centered: true,
                     onOk: () => { handleCancel() },
                  });
               }
            }
         }, (error) => {
            setUpdating(false);
            console.log(error.message);
            Modal.error({
               title: 'Subject Group Management',
               content: 'Ooops! It seems that there was a problem encountered while processing your request.',
               centered: true,
            });
         });
      } else {
         Modal.info({
            title: 'Subject Group Management',
            content: (
               <>
                  Please complete the folowing before saving:
                  <ul>
                     <li>
                        Subject Group
                     </li>
                     <li>
                        Level
                     </li>
                     <li>
                        Select Sections
                     </li>
                     <li>
                        Select Subjects
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   const handleUpdate = () => {
      if (subjectGroupDesc !== null && subjectGroupDesc !== '' && level !== null && level !== undefined && selectedSections.length > 0 && selectedSubjects.length > 0) {
         setUpdating(true);

         var data = {
            id: subjectGroup,
            subject_group: subjectGroupDesc,
            level: level,
            assigned_sections: selectedSections.length > 0 ? selectedSections.join(',') : [],
            assigned_subjects: selectedSubjects.length > 0 ? selectedSubjects.join(',') : [],
         }

         axios.post(`${process.env.REACT_APP_API_MANAGEMENT}/updatesubjectgroup`, data).then((response) => {
            if (response.data) {
               const data = response.data;

               setUpdating(false);

               GetSubjectGroupList();
               setUpdating(false);
               // setEditModalVisible(false);
               // setAddModalVisible(false);

               if (data.Code === 1) {
                  Modal.error({
                     title: 'Subject Group Management',
                     content: 'Ooops! It seems that there was a problem encountered while processing your request.',
                     centered: true,
                  });
               }
               else {
                  Modal.success({
                     title: 'Subject Group Management',
                     content: 'Update successful.',
                     centered: true,
                     onOk: () => { handleCancel() },
                  });
               }
            }
         }, (error) => {
            setUpdating(false);
            console.log(error.message);
            Modal.error({
               title: 'Subject Group Management',
               content: 'Ooops! It seems that there was a problem encountered while processing your request.',
               centered: true,
            });

         });
      } else {
         Modal.info({
            title: 'Subject Group Management',
            content: (
               <>
                  Please complete the folowing before saving:
                  <ul>
                     <li>
                        Subject Group
                     </li>
                     <li>
                        Level
                     </li>
                     <li>
                        Select Sections
                     </li>
                     <li>
                        Select Subjects
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   const handleCancel = () => {
      setEditModalVisible(false);
      setAddModalVisible(false);
   }

   const handleRemove = (id) => {
      axios.post(`${process.env.REACT_APP_API_MANAGEMENT}/deletesection?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;

            GetSubjectGroupList();
            setUpdating(false);
            // setEditModalVisible(false);
            // setAddModalVisible(false);

            if (data.Code === 1) {
               Modal.error({
                  title: 'Subject Group Management',
                  content: 'Ooops! It seems that there was a problem encountered while processing your request.',
                  centered: true,
               });
            }
            else {
               Modal.success({
                  title: 'Subject Group Management',
                  content: 'Removing section successful.',
                  centered: true,
                  onOk: () => { handleCancel() },
               });
            }
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Subject Group Management',
            content: 'Ooops! It seems that there was a problem encountered while processing your request.',
            centered: true,
         });
      });
   }

   const rowSectionSelection = {
      selectedRowKeys: selectedSections,
      onChange: (selectedRowKeys, selectedRows) => {
         setSelectedSections(selectedRowKeys);
      },
   };

   const rowSubjectSelection = {
      selectedRowKeys: selectedSubjects,
      onChange: (selectedRowKeys, selectedRows) => {
         setSelectedSubjects(selectedRowKeys);
      },
   };

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Management</li>
                     <li><span>/</span></li>
                     <li className="active">Subject Groups</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Management</Breadcrumb.Item>
                     <Breadcrumb.Item>Subject Groups</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
            </div>

            <Row gutter={12}>
               <Col className="gutter-row" span={24} style={{ textAlign: "left" }}>
                  {/* <Tooltip placement="top" title='Create Mastery Assessment'> */}
                  <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => handleAdd()}>
                     <Text ellipsis style={{ color: "#fff" }}><AntDIcons.PlusOutlined />&nbsp;Add New Subject Group</Text>
                  </Button>
                  {/* </Tooltip> */}
               </Col>
            </Row>

            <Row gutter={12} style={{ marginTop: '15px' }} >
               <Col span={24}>
                  <div className="dashboard-item-inner3">
                     {
                        searchLoading
                           ?
                           <div className='center-empty-space'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                           :
                           (subjectGroupList.length > 0 && !searchLoading)
                              ?
                              <Table
                                 rowKey={'uid'}
                                 // loading={searchLoading}
                                 size='small'
                                 columns={columns}
                                 dataSource={subjectGroupList}
                                 pagination={false}
                                 // style={{ width: '70%' }}
                                 scroll={{
                                    x: '1000px',
                                    y: 'calc(100svh - 12rem)',
                                 }}
                                 footer={() => 'Total Records: ' + subjectGroupList.length}
                              />
                              :
                              <div className='center-empty-space'>
                                 <Empty image="../images/brainee_mascot.png" description="No records found" />
                              </div>
                     }

                  </div>
               </Col>
            </Row>
         </div>

         {/* Modals */}
         <Modal
            title={"Add New Subject Group"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            onOk={() => { handleSave() }}
            open={addModalVisible}
            width='60%'
            style={{ minWidth: '300px' }}
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Save'}
         >
            <Spin spinning={updating} tip={"Updating, please wait..."}>
               <Row gutter={12} style={{ paddingBottom: '12px' }}>
                  <Col sm={24} md={12} lg={12}>
                     <Space direction='vertical' style={{ width: "100%" }} >
                        <Text>Subject Group</Text>
                        <Input
                           allowClear
                           size='middle'
                           style={{ borderRadius: 7 }}
                           placeholder="Enter New Subject Group"
                           onBlur={(event) => { setSubjectGroupDesc(event.target.value) }}
                        />
                     </Space>
                  </Col>
                  <Col sm={24} md={12} lg={12}>
                     <Space direction='vertical' style={{ width: "100%" }} >
                        <Text>Level</Text>
                        <Select
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select Level"
                           onChange={(val) => handleLevelChange(val)}
                        >
                           {levelList.map(({ value, name }, idx) => {
                              return <Option value={value}>{name}</Option>
                           })}
                        </Select>
                     </Space>
                  </Col>
               </Row>

               <Row gutter={12}>
                  <Col sm={24} md={8} lg={8}>
                     <Space direction='vertical' style={{ width: "100%" }} >
                        <Text>Select Sections</Text>
                        <Table
                           rowSelection={rowSectionSelection}
                           size='small'
                           columns={section_columns}
                           dataSource={sectionList}
                           pagination={false}
                           // style={{ minHeight: '200px' }}
                           scroll={{
                              // y: '285px',
                              y: 'calc(100vh - 21rem)',
                           }}
                        />
                     </Space>
                  </Col>
                  <Col sm={24} md={16} lg={16}>
                     <Space direction='vertical' style={{ width: "100%" }} >
                        <Text>Select Subjects</Text>
                        <Table
                           rowSelection={rowSubjectSelection}
                           size='small'
                           columns={subject_columns}
                           dataSource={subjectList}
                           pagination={false}
                           // style={{ minHeight: '200px' }}
                           scroll={{
                              // y: '285px',
                              y: 'calc(100vh - 21rem)',
                           }}
                        />
                     </Space>
                  </Col>
               </Row>
            </Spin>
         </Modal>

         <Modal
            title={"Edit Subject Group"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            onOk={() => { handleUpdate() }}
            open={editModalVisible}
            width='50%'
            style={{ minWidth: '300px' }}
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Update'}
         >
            <Spin spinning={updating} tip={"Updating, please wait..."}>
               <Row gutter={12} style={{ paddingBottom: '12px' }}>
                  <Col sm={24} md={12} lg={12}>
                     <Space direction='vertical' style={{ width: "100%" }} >
                        <Text>Subject Group</Text>
                        <Input
                           allowClear
                           size='middle'
                           style={{ borderRadius: 7 }}
                           placeholder="Enter Subject Group"
                           onBlur={(event) => { setSubjectGroupDesc(event.target.value) }}
                           defaultValue={subjectGroupDesc}
                        />
                     </Space>
                  </Col>
                  <Col sm={24} md={12} lg={12}>
                     <Space direction='vertical' style={{ width: "100%" }} >
                        <Text>Level</Text>
                        <Select
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select Level"
                           onChange={(val) => handleLevelChange(val)}
                           defaultValue={level}
                        >
                           {levelList.map(({ value, name }, idx) => {
                              return <Option value={value}>{name}</Option>
                           })}
                        </Select>
                     </Space>
                  </Col>
               </Row>

               <Row gutter={12}>
                  <Col sm={24} md={8} lg={8}>
                     <Space direction='vertical' style={{ width: "100%" }} >
                        <Text>Select Sections</Text>
                        <Table
                           rowSelection={rowSectionSelection}
                           size='small'
                           columns={section_columns}
                           dataSource={sectionList}
                           pagination={false}
                           // style={{ minHeight: '200px' }}
                           scroll={{
                              // y: '285px',
                              y: 'calc(100vh - 21rem)',
                           }}
                        />
                     </Space>
                  </Col>
                  <Col sm={24} md={16} lg={16}>
                     <Space direction='vertical' style={{ width: "100%" }} >
                        <Text>Select Subjects</Text>
                        <Table
                           rowSelection={rowSubjectSelection}
                           size='small'
                           columns={subject_columns}
                           dataSource={subjectList}
                           pagination={false}
                           // style={{ minHeight: '200px' }}
                           scroll={{
                              // y: '285px',
                              y: 'calc(100vh - 21rem)',
                           }}
                        />
                     </Space>
                  </Col>
               </Row>
            </Spin>
         </Modal>
      </>
   );
}