import React, { useState } from 'react'
import { Input, Tooltip, Row, Col, Form } from 'antd';
import { CloseCircleTwoTone, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Utils from '../../../misc/Utils';

import './ShortAnswer.css';

const { TextArea } = Input;

export default function MatchingTypeItem(props) {

   const [alpha, setAlpha] = useState(props.correct !== undefined && props.correct !== '' ? props.correct : '');

   const handleChange = (command, value) => {
      const re = /^[A-Za-z]+$/;

      if ((value === "" || re.test(value)) && command === 'update-input-multi') {
         setAlpha(value);
      }

      props.parentCallback(command, value, props.id);
      // console.log(value);
   }

   const handleRemoveOption = (idx) => {
      props.parentCallback("remove", "", idx);
   }

   function check_input(event) {
      var key = event.keyCode;
      return ((key >= 65 && key <= 90) || (key >= 97 && key <= 122) || key === 8);
   };

   return (
      <Row width={'100%'} gutter={['12', '12']} style={{ padding: '3px', alignItems: 'center' }}>
         <Col span={12} >
            <Row width={'100%'} gutter={['12', '12']} style={{ alignItems: 'center' }} >
               <Col span={2}>
                  {
                     props.mode.includes('-view')
                        ?
                        props.matched
                           ?
                           <CheckOutlined style={{ fontSize: '22px', color: '#52c41a' }} />
                           :
                           <CloseOutlined style={{ fontSize: '22px', color: '#eb2f96' }} />
                        :
                        <></>

                  }
               </Col>
               <Col span={1}>{Utils.convertLetterToNumber(props.label)}.</Col>
               <Col span={4} style={{ borderBottom: '1px solid #aaa' }}>
                  <Input
                     // pattern="[a-zA-Z]+"
                     // pattern={new RegExp(/^[a-zA-Z]*$/)}
                     // onKeyDown={(e) => check_input(e)}
                     // defaultValue={props.correct}
                     bordered={false}
                     onChange={(e) => handleChange('update-input-multi', e.target.value)}
                     style={{ textAlign: 'center' }}
                     disabled={props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                     value={alpha}
                  ></Input>
               </Col>
               <Col span={16}>
                  <TextArea
                     defaultValue={props.value[0]}
                     size='middle'
                     allowClear={props.mode.includes("assign") || props.mode.includes("view") ? false : true}
                     id={props.id}
                     name={props.id}
                     autoSize={{
                        minRows: 1,
                        maxRows: 3,
                     }}
                     onBlur={(e) => handleChange('update-col-a', e.target.value)}
                     style={{ width: '100%', borderRadius: '7px', fontSize: '16px' }}
                     disabled={props.mode.includes("assign") || props.mode.includes("view") || props.mode === "answer" ? true : false}
                  >
                  </TextArea>
               </Col>
            </Row>
         </Col>
         <Col span={12} >
            <Row width={'100%'} gutter={['12', '12']} style={{ alignItems: 'center' }} >
               <Col span={1}>{props.label}</Col>
               <Col span={20}>
                  <TextArea
                     defaultValue={props.value[1]}
                     size='middle'
                     allowClear={props.mode.includes("assign") || props.mode.includes("view") ? false : true}
                     id={props.id}
                     name={props.id}
                     autoSize={{
                        minRows: 1,
                        maxRows: 3,
                     }}
                     onBlur={(e) => handleChange('update-col-b', e.target.value)}
                     style={{ width: '100%', borderRadius: '7px', fontSize: '16px' }}
                     disabled={props.mode.includes("assign") || props.mode.includes("view") || props.mode === "answer" ? true : false}
                  >
                  </TextArea>
               </Col>
               <Col span={2}>
                  {
                     props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view")
                        ?
                        <></>
                        :
                        <Tooltip placement="left" title='Remove Option'>
                           <CloseCircleTwoTone twoToneColor="red" style={{ cursor: "pointer", fontSize: "20px" }} onClick={() => handleRemoveOption(props.id)} />
                        </Tooltip>
                  }
               </Col>
            </Row>
         </Col>
      </Row>
   )
}