import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { UndoOutlined } from '@ant-design/icons';
import { Button, Descriptions, message, Popconfirm, Typography, Select, Modal, Spin, Breadcrumb } from 'antd';
// import StickyBox from "react-sticky-box";
import './Assessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
// import QuestionCard from '../../components/assessment/QuestionCard';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import { SchoolTerms } from '../../constants';
import Utils from '../../misc/Utils';
import Split from 'react-split';

// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Text, Paragraph } = Typography;
const { Option } = Select

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];

export function PreviewPDF(props) {
   const location = useLocation();
   const navigate = useNavigate();

   // const[answerSheetData, setAnswerSheetData] = useState(questionData);
   // const[questionSheetPDF, setQuestionSheetPDF] = useState("https://media.campuscloudph.com/ssapamp/uploads/lms_assessment/lms_assessment_online_163922682965702119/assessment_online_163922685061451080.pdf");

   const [origin, setOrigin] = useState(location.state.origin);
   const [id, setId] = useState(location.state.id);
   const [docLoaded, setDocLoaded] = useState(false);
   const [pdfLoaded, setPdfLoaded] = useState(false);

   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: "",
      Term: "",
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: "",
      Level: "",
      Subject: "",
      Level_desc: "",
      Subject_desc: ""
   });



   // function onDocumentLoadSuccess(numPages) {
   //    setNumPages(numPages);
   // }

   useEffect(() => {
      Utils.verifyJwt();

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];

      getAssessmentInfo(location.state.id);
   }, [location.state.id]);

   const getAssessmentInfo = (id) => {
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/info?id=${id}&at=summative`).then((response) => {
         if (response.data) {
            const data = response.data;
            setAssInfo({
               Id: data.id,
               Title: data.title,
               Term: data.term,
               File_url: data.file_url,
               Question_type: data.question_type,
               Created_by: data.created_by,
               Date_created: data.date_created,
               Question_sheet: JSON.parse(data.question_sheet),
               Level: data.level,
               Subject: data.subject,
               Level_desc: data.level_desc,
               Subject_desc: data.subject_desc
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleRestoreAssessment = (id) => {
      axios.post(`${process.env.REACT_APP_API_ASSESSMENT}/undelete?id=${id}&at=summative`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Summative Assessment',
               content: 'Your assessment has been restored.',
               centered: true,
            });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Summative Assessment',
            content: 'Assessment restore failed.',
            centered: true
         });
      });
   }

   const handleRestoreAssignedAssessment = (id) => {
      axios.post(`${process.env.REACT_APP_API_ASSESSMENT}/undeleteassigned?id=${id}&at=summative`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Summative Assessment',
               content: 'Your assigned assessment has been restored.',
               centered: true,
            });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Summative Assessment',
            content: 'Assessment restore failed.',
            centered: true
         });
      });
   }

   const handleCardRequest = () => {

   }

   const handleRestore = () => {
      if (origin.toLowerCase() === 'master list') {
         handleRestoreAssessment(id);
      } else {
         handleRestoreAssignedAssessment(id);
      }
   }

   const handleOnPDFLoad = () => {
      setDocLoaded(true);
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/assessment', { state: { activetab: "1" } })}>Summative Assessments</a></li>
                           <li><span>/</span></li>
                           <li className="active">Preview</li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <Link to="/assessment">Summative Assessment</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Preview</Breadcrumb.Item>
                        </Breadcrumb>
                     </div>
                     {
                        assInfo.File_url
                           ?
                           <div className="pdf-all-page-container">
                              <div style={{ width: "100%", height: "calc(100vh - 58px)" }}>
                                 <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                    <Viewer
                                       pageLayout={{
                                          transformSize: ({ size }) => ({
                                             height: size.height + 30,
                                             width: size.width + 30,
                                          }),
                                       }}
                                       fileUrl={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`}
                                       defaultScale={SpecialZoomLevel.PageWidth}
                                       initialPage={0}
                                    />
                                 </Worker>
                              </div>
                              {/* <RenderPDFReader url={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} height={"calc(100vh - 58px)"} /> */}
                              {/* <object data={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} width="100%" style={{ height: 'calc(100vh - 58px)' }}>
                              <p>Brainee LMS PDF - View<a href={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`}> PDF!</a></p>
                           </object> */}
                              {/* <RenderPDFViewer url={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} height={"calc(100vh - 58px)"} /> */}
                           </div>
                           :
                           <div className='temp-answer-sheet-content2'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                     }
                  </div>
               </div>

               <div>
                  <div className="notice-item">
                     <>
                        {assInfo.Question_sheet
                           ?
                           <div className='answer-sheet-content'>
                              <SimpleBarReact>
                                 <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", width: "100%" }} column={4}>
                                    <Descriptions.Item label={"Title"} span={4} style={{ textAlign: "center" }} >
                                       <Paragraph style={{ textAlign: "center", width: "100%" }}>{assInfo.Title}</Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Level"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                       <Paragraph style={{ textAlign: "center", width: "100%" }}>{assInfo.Level_desc}</Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Subject"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                       <Paragraph style={{ textAlign: "center", width: "100%" }}>{assInfo.Subject_desc}</Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Term"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                       <Select
                                          size='default'
                                          style={{ width: '100%', textAlign: "center" }}
                                          value={assInfo.Term}
                                          disabled
                                       >
                                          {SchoolTerms.map(({ value, name }, idx) => {
                                             return <Option value={value}>{name}</Option>
                                          })}
                                       </Select>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Date"} span={2} style={{ textAlign: "center", width: "50%" }}>
                                       <Paragraph style={{ textAlign: "left", width: "100%" }}>{assInfo.Date_created}</Paragraph>
                                    </Descriptions.Item>
                                 </Descriptions>
                                 <ul className="questions">
                                    {
                                       assInfo.Question_sheet.map(({ id, type, points, correct, option_labels, image_url, question }, idx) => {

                                          if (type === 'section') {
                                             qcardIdx = 1;
                                             idxCol.push(sectionIdx);
                                             sectionIdx++;
                                          } else {
                                             idxCol.push(qcardIdx);
                                             qcardIdx++;
                                          }

                                          return (
                                             <li>
                                                <BraineeQuestionCard
                                                   key={id}
                                                   index={idxCol[idx]}
                                                   id={idx}
                                                   type={type}
                                                   points={points}
                                                   correct={correct}
                                                   option_labels={option_labels}
                                                   image_url={image_url}
                                                   question={question}
                                                   question_type={'1'}
                                                   mode={'preview'}
                                                   parentCallback={handleCardRequest}
                                                   assessment_type={'summative'}
                                                />
                                             </li>
                                          );
                                       })}
                                 </ul>
                              </SimpleBarReact>
                           </div>
                           :
                           <div className='temp-answer-sheet-content2'>
                              {/* <Empty description='No items added.'/> */}
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                        }
                     </>
                  </div>
               </div>
            </Split>
         </div>

         {/* <div className="dashboard-area">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <ul>
                     <li><a href="#">Home</a></li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate('/assessment', {state: {activetab: "1"}})}>Summative Assessments</a></li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate('/assessment', {state: {activetab: "5"}})}>Recycle Bin</a></li>
                     <li><span>/</span></li>
                     <li><a className="active" href="#">View</a></li>
                  </ul>       
               </div>   
               
               <div className="pdf-all-page-container">          
                        
                  <SimpleBarReact >
                     {
                        assInfo.File_url &&
                           <AllPagesPDFViewer pdf={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} />
                     }
                  </SimpleBarReact>
               </div>     
            </div>
         </div> */}

         {/* <div className="notice-area" style={{display:"block"}}> 
            <div className="notice-item">
            <>                  
               {  assInfo.Question_sheet && 
               <div className='answer-sheet-content'>
                  <SimpleBarReact>
                     <Descriptions layout='vertical' size={"small"} bordered style={{marginBottom:"15px", width:"100%"}} column={4}>                     
                        <Descriptions.Item label={"Title"} span={4} style={{textAlign:"center"}} >
                           <Paragraph style={{textAlign:"left", width:"100%"}}>{ assInfo.Title }</Paragraph>
                        </Descriptions.Item>
                        <Descriptions.Item label={"Level"} span={2} style={{ textAlign:"center", width:"50%"}} >
                           <Paragraph style={{textAlign:"left", width:"100%"}}>{assInfo.Level_desc}</Paragraph>
                        </Descriptions.Item>
                        <Descriptions.Item label={"Subject"} span={2} style={{textAlign:"center", width:"50%"}} >
                           <Paragraph style={{textAlign:"left", width:"100%"}}>{assInfo.Subject_desc}</Paragraph>
                        </Descriptions.Item>
                        <Descriptions.Item label={"Term"} span={2} style={{textAlign:"center", width: "50%"}} >
                           <Select 
                              size='default' 
                              style={{width: '100%', textAlign:"left"}} 
                              value={ assInfo.Term }
                              disabled
                           >
                              {SchoolTerms.map(({value, name}, idx) => { 
                                 return <Option value={value}>{name}</Option>
                              })}
                           </Select>
                        </Descriptions.Item>
                        <Descriptions.Item label={"Date"} span={2} style={{textAlign:"center", width: "50%"}}>
                           <Paragraph style={{textAlign:"left", width:"100%"}}>{ assInfo.Date_created }</Paragraph>
                        </Descriptions.Item>
                     </Descriptions>
                     <StickyBox style={{zIndex: 1000}}>
                        <Popconfirm
                           title="Are you sure you want to restore this assessment?"
                           // onConfirm={() => origin.toLowerCase() === 'master list' ? handleRestoreAssessment(id) : handleRestoreAssignedAssessment(id)}
                           onConfirm={() => handleRestore()}
                           // onCancel={cancel}
                           okText="Yes"
                           cancelText="No"
                        >
                           
                           <Button className='button-shadow' type='primary' block shape='round' style={{marginBottom:"15px"}}>
                              <Text ellipsis style={{color: "#fff"}}><UndoOutlined />&nbsp;Restore</Text>
                           </Button>
                        </Popconfirm>
                     </StickyBox>

                     <ul className="questions">
                        { assInfo.Question_sheet.map(({id, type, points, correct, option_labels, image_url}, idx) => { 

                           if (type == "section") {
                              qcardIdx = 1;
                              idxCol.push(sectionIdx);
                              sectionIdx++;
                           } else {
                              idxCol.push(qcardIdx);
                              qcardIdx++;
                           }                                    

                           return (
                              <li>
                                 <QuestionCard 
                                    key={id}
                                    index={ idxCol[idx] } 
                                    id={idx} 
                                    type={type} 
                                    points={points} 
                                    correct={correct} 
                                    option_labels={option_labels} 
                                    image_url={image_url}
                                    question_type={'1'}
                                    mode={'view'}
                                    parentCallback={handleCardRequest} />
                              </li>
                           );
                        })}
                     </ul> 
                  </SimpleBarReact>
               </div>
               }
            </>         
            </div>
         </div> */}
      </>
   )
}
