import { message } from 'antd';
import uuid from 'react-uuid';

export function CardRequestHelper(method, type, data, idx, sheet_data, template = null) {
   var asd = [...sheet_data];
   let tmp_data;

   switch (method) {
      case "replace":
         asd[idx].template = template;
         break;
      case "remove":
         asd.splice(idx, 1);
         break;
      case "update":
         // if (asd[idx].type !== "short_answer" && asd[idx].type !== "section" && asd[idx].type !== "long_answer") {
         if (asd[idx].type !== "section" && asd[idx].type !== "long_answer") {
            asd[idx].correct = []; asd[idx].correct.push.apply(asd[idx].correct, data.correct);
            asd[idx].option_labels = []; asd[idx].option_labels.push.apply(asd[idx].option_labels, data.option_labels);

            if (asd[idx].type.includes("image")) {
               asd[idx].image_url = []; asd[idx].image_url.push.apply(asd[idx].image_url, data.image_url);
            }
         } else {
            if (asd[idx].type !== "long_answer") {
               asd[idx].correct = []; asd[idx].correct.push(data.correct[0]);
            }
         }

         asd[idx].points = data.points;
         asd[idx].question = data.question;
         asd[idx].question_image = data.question_image;
         asd[idx].question_audio = data.question_audio;
         asd[idx].question_video = data.question_video;
         break;
      case "add":
         // var tmpID;

         // if(asd.length > 0) {
         //    for (var i=1; i<asd.length + 1; i++) {
         //       if (!isIDExist(asd, i)) {
         //          tmpID = i;
         //          break;
         //       }
         //    }
         // }

         switch (type) {
            case "section":
               tmp_data = {
                  id: uuid(), //tmpID === undefined ? (asd.length + 1).toString() : tmpID.toString(),
                  type: "section",
                  correct: [""],
               };

               asd.push(tmp_data);
               message.info("Added new section");

               break;
            case "multiple_answer":
               tmp_data = {
                  id: uuid(), //tmpID === undefined ? (asd.length + 1).toString() : tmpID.toString(),
                  type: "multiple_answer",
                  correct: ["0"],
                  points: "1",
                  option_labels: ["A"],
                  question: "",
                  question_image: "",
                  template,
               };

               asd.push(tmp_data);
               message.info("Added multiple answer");

               break;
            case "multiple_answer_image":
               tmp_data = {
                  id: uuid(), //tmpID === undefined ? (asd.length + 1).toString() : tmpID.toString(),
                  type: "multiple_answer_image",
                  correct: ["0"],
                  points: "1",
                  option_labels: ["A"],
                  image_url: [""],
                  question: "",
                  question_image: "",
                  template,
               };

               asd.push(tmp_data);
               message.info("Added multiple answer image");

               break;
            case "multiple_choice":
               tmp_data = {
                  id: uuid(), //tmpID === undefined ? (asd.length + 1).toString() : tmpID.toString(),
                  type: "multiple_choice",
                  correct: ["0"],
                  points: "1",
                  option_labels: ["A"],
                  question: "",
                  question_image: "",
                  template,
               };

               asd.push(tmp_data);
               message.info("Added multiple choice");

               break;

            case "multiple_choice_image":
               tmp_data = {
                  id: uuid(), //tmpID === undefined ? (asd.length + 1).toString() : tmpID.toString(),
                  type: "multiple_choice_image",
                  correct: ["0"],
                  points: "1",
                  option_labels: ["A"],
                  image_url: [""],
                  question: "",
                  question_image: "",
                  template,
               };

               asd.push(tmp_data);
               message.info("Added multiple choice");

               break;

            case "short_answer":
               tmp_data = {
                  id: uuid(), //tmpID === undefined ? (asd.length + 1).toString() : tmpID.toString(),
                  type: "short_answer",
                  correct: [""],
                  points: "1",
                  question: "",
                  question_image: "",
                  template,
               };

               asd.push(tmp_data);
               message.info("Added short answer");

               break;
            case "long_answer":
               tmp_data = {
                  id: uuid(), //tmpID === undefined ? (asd.length + 1).toString() : tmpID.toString(),
                  type: "long_answer",
                  points: "1",
                  question: "",
                  question_image: "",
                  template,
               };

               asd.push(tmp_data);
               message.info("Added essay");

               break;

            case "matching_type":
               tmp_data = {
                  id: uuid(), //tmpID === undefined ? (asd.length + 1).toString() : tmpID.toString(),
                  type: "matching_type",
                  correct: [""],
                  points: "1",
                  option_labels: [["", ""]],
                  question: "",
                  question_image: "",
                  template,
               };

               asd.push(tmp_data);
               message.info("Added matching type");

               break;

            case "matching_type_image":
               tmp_data = {
                  id: uuid(), //tmpID === undefined ? (asd.length + 1).toString() : tmpID.toString(),
                  type: "matching_type_image",
                  correct: [""],
                  points: "1",
                  option_labels: [""],
                  image_url: [["", ""]],
                  question: "",
                  question_image: "",
                  template,
               };

               asd.push(tmp_data);
               message.info("Added matching type");

               break;

            default:
               break;
         }

         break;
      case "duplicate":
         switch (asd[idx].type) {
            case "multiple_answer":
               tmp_data = {
                  id: uuid(), //(asd.length + 1).toString(),
                  type: "multiple_answer",
                  correct: asd[idx].correct,
                  points: asd[idx].points,
                  option_labels: asd[idx].option_labels,
                  question: asd[idx].question,
                  question_image: asd[idx].question_image,
                  template: asd[idx].template,
               };

               asd.push(tmp_data);
               break;

            case "multiple_answer_image":
               tmp_data = {
                  id: uuid(), //(asd.length + 1).toString(),
                  type: "multiple_answer_image",
                  correct: asd[idx].correct,
                  points: asd[idx].points,
                  option_labels: asd[idx].option_labels,
                  image_url: asd[idx].image_url,
                  question: asd[idx].question,
                  question_image: asd[idx].question_image,
                  template: asd[idx].template,
               };

               asd.push(tmp_data);
               break;

            case "multiple_choice":
               tmp_data = {
                  id: uuid(), //(asd.length + 1).toString(),
                  type: "multiple_choice",
                  correct: asd[idx].correct,
                  points: asd[idx].points,
                  option_labels: asd[idx].option_labels,
                  question: asd[idx].question,
                  question_image: asd[idx].question_image,
                  template: asd[idx].template,
               };

               asd.push(tmp_data);
               break;

            case "multiple_choice_image":
               tmp_data = {
                  id: uuid(), //(asd.length + 1).toString(),
                  type: "multiple_choice_image",
                  correct: asd[idx].correct,
                  points: asd[idx].points,
                  option_labels: asd[idx].option_labels,
                  image_url: "",
                  question: asd[idx].question,
                  question_image: asd[idx].question_image,
                  template: asd[idx].template,
               };

               asd.push(tmp_data);
               break;

            case "short_answer":
               tmp_data = {
                  id: uuid(), //(asd.length + 1).toString(),
                  type: "short_answer",
                  correct: asd[idx].correct,
                  points: asd[idx].points,
                  question: asd[idx].question,
                  question_image: asd[idx].question_image,
                  template: asd[idx].template,
               };

               asd.push(tmp_data);
               break;

            case "long_answer":
               tmp_data = {
                  id: uuid(), //(asd.length + 1).toString(),
                  type: "long_answer",
                  points: asd[idx].points,
                  question: asd[idx].question,
                  question_image: asd[idx].question_image,
                  template: asd[idx].template,
               };

               asd.push(tmp_data);
               break;

            case "matching_type":
               tmp_data = {
                  id: uuid(), //tmpID === undefined ? (asd.length + 1).toString() : tmpID.toString(),
                  type: "matching_type",
                  correct: asd[idx].correct,
                  points: asd[idx].points,
                  option_labels: asd[idx].option_labels,
                  question: asd[idx].question,
                  question_image: asd[idx].question_image,
                  template: asd[idx].template,
               };

               asd.push(tmp_data);
               message.info("Added matching type");

               break;

            case "matching_type_image":
               tmp_data = {
                  id: uuid(), //tmpID === undefined ? (asd.length + 1).toString() : tmpID.toString(),
                  type: "matching_type_image",
                  correct: asd[idx].correct,
                  points: asd[idx].points,
                  image_url: ['', ''],
                  option_labels: asd[idx].option_labels,
                  question: asd[idx].question,
                  question_image: asd[idx].question_image,
                  template: asd[idx].template,
               };

               asd.push(tmp_data);
               message.info("Added matching type");

               break;
            default:
               break;
         }

         message.info('Copy successful');

         break;
      default:
         break;
   }

   return asd;
}

function isIDExist(data, id) {
   for (var i = 0; i < data.length; i++) {
      if (data[i].id === id.toString()) {
         return true;
      }
   }

   return false;
}