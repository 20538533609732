import { AssessmentOptionsModelDefault } from '../models/AssessmentModels'

// This is where assessment option is updated
const update_ass_option_data = (state = AssessmentOptionsModelDefault, payload) => {
   const tmpState = state;

   tmpState.allowed_attempts = payload.allowed_attempts;
   tmpState.assigned_to = payload.assigned_to;
   tmpState.assignment_date_end = payload.assignment_date_end;
   tmpState.assignment_date_start = payload.assignment_date_start;
   tmpState.duration = payload.duration;
   tmpState.enable_timer = payload.enable_timer;
   tmpState.passing_percentage = payload.passing_percentage;
   tmpState.show_results = payload.show_results;

   return tmpState;
}

const assOptionDataReducer = (state = AssessmentOptionsModelDefault, action) => {
   switch(action.type){
       case 'UPDATE-ASS-OPTION-DATA':
           return update_ass_option_data(state,action.payload);
       default:
           return state
   }
}
export default assOptionDataReducer