/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { Empty, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ProgressBar from "@ramonak/react-progress-bar";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import { InterpretationInd, IntroInd } from '../components';

export function MTG7ScienceReport(props) {
   const [reportData, setReportData] = useState(null);

   const [matter, setMatter] = useState(null);
   const [living, setLiving] = useState(null);
   const [force, setForce] = useState(null);
   const [earth, setEarth] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      ChartJS.register(
         CategoryScale,
         LinearScale,
         BarElement,
         Title,
         Tooltip,
         Legend
      );

      getReport(props.subject_id, props.assignment_id, props.student_id, props.template)
   }, []);

   const getReport = (subject, assignment_id, student_id, template) => {
      setDataLoading(true);
      var url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticreport?s=${subject}&ai=${assignment_id}&si=${student_id}&t=${template}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data[0];

            if (data !== undefined) {
               setMatter(data.s1 + data.s2 + data.s3 + data.s4 + data.s5 + data.s6 + data.s7 + data.s8 + data.s9 + data.s10 + data.s11);
               setLiving(data.s12 + data.s13 + data.s14 + data.s15 + data.s16 + data.s17 + data.s18 + data.s19 + data.s20 + data.s21 + data.s22);
               setForce(data.s23 + data.s24 + data.s25 + data.s26 + data.s27 + data.s28 + data.s29 + data.s30 + data.s31 + data.s32 + data.s33);
               setEarth(data.s34 + data.s35 + data.s36 + data.s37 + data.s38 + data.s39 + data.s40);

               setReportData(data);
            }

            setDataLoading(false);
         }
      }, (error) => {
         setDataLoading(false);
         console.log(error.message);
      });
      // }
   }

   const renderPartLabel = (title) => {
      return (
         <tr>
            <td style={{ width: '12.6pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
            </td>
            <td style={{ width: '382.3pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}><strong>{title}</strong></p>
            </td>
            <td style={{ width: '31.7pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
            </td>
            <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
            </td>
            <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
            </td>
         </tr>
      );
   }

   const renderCompetencyRow = (idx, label, answer, pre_percent, post_percent) => {
      return (
         <tr>
            <td style={{ width: '12.6pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{idx}</strong></p>
            </td>
            <td style={{ width: '382.3pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>{label}</p>
            </td>
            <td style={{ width: '31.7pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{answer === 1 || answer === '1' ? <CheckOutlined style={{ fontSize: '22px', color: '#52c41a' }} /> : <CloseOutlined style={{ fontSize: '22px', color: '#eb2f96' }} />}</p>
            </td>
            <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {
                     pre_percent !== 0 &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        // labelColor={pre_percent <= 40 ? "#000000" : "#FFFFFF"}
                        labelColor={"#FFFFFF"}
                        // labelAlignment={pre_percent <= 40 ? "outside" : "center"}
                        labelAlignment={"center"}
                        // customLabel={pre_percent.toFixed(1) + "%"}
                        completed={pre_percent}
                        labelSize="12px"
                        animateOnRender
                     />
                  }
               </p>
            </td>
            <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {/* {
                     pre_percent !== 0 &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        labelAlignment="center"
                        completed={pre_percent}
                        labelSize="12px"
                        animateOnRender
                     />
                  } */}
               </p>
            </td>
         </tr>
      );
   }

   const renderSummaryRow = (label, raw_score, item_count, s1, s2) => {
      return (
         <tr>
            <td style={{ width: '197.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
            </td>
            <td style={{ width: '79.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {raw_score}
               </p>
            </td>
            <td style={{ width: '61.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {(((raw_score) / item_count) * 100).toFixed(2)}%
               </p>
            </td>
            <td style={{ width: '59.9pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {((raw_score - s1) / s2).toFixed(2)}
               </p>
            </td>
            <td style={{ width: '99.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {((raw_score - s1) / s2) > 1 ? 'Competent' : (((raw_score - s1) / s2) >= -0.51 ? 'Basic' : (((raw_score - s1) / s2) > -10 ? 'Beginner' : ''))}
               </p>
            </td>
         </tr>
      );
   }

   const graph_options = {
      responsive: true,
      plugins: {
         legend: {
            position: 'top',
         },
         title: {
            display: true,
            text: 'Percent Correct for the Content Standards',
         },
      },
      scales: {
         y:
         {
            min: 0,
            max: 100,
            stepSize: 1,
         },
         x:
            {},
      }
   };
   const labels = [
      "Matter",
      "Life Science",
      "Force, Motion, and Energy",
      "Earth and Space"
   ];
   const graph_data = {
      labels: labels,
      datasets: [
         {
            label: "Percentage",
            backgroundColor: "#4F81BD",
            borderColor: "#4F81BD",
            data: [
               !isNaN((matter / 11) * 100) ? ((matter / 11) * 100).toFixed(2) : 0,
               !isNaN((living / 11) * 100) ? ((living / 11) * 100).toFixed(2) : 0,
               !isNaN((force / 11) * 100) ? ((force / 11) * 100).toFixed(2) : 0,
               !isNaN((earth / 6) * 100) ? ((earth / 7) * 100).toFixed(2) : 0
            ],
         },
      ],
   };

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               reportData !== null
                  ?
                  <div className="dashboard-item-inner3" style={{ padding: '7px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='individual-report'>

                        <IntroInd
                           subject='Science'
                           grade_level={reportData.grade_level}
                           student_name={reportData.student_name}
                           school_name={reportData.school_name}
                           section={reportData.section}
                           diagnostic_date={reportData.diagnostic_date}
                        />

                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '12pt' }}><strong>Competency Score</strong></p>
                        <div style={{ textAlign: 'center' }}>
                           <table cellSpacing={0} cellPadding={0} style={{ marginRight: 'auto', marginLeft: 'auto', border: '0.75pt solid #cccccc', borderCollapse: 'collapse', width: '100%' }}>
                              <tbody>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Learning Competency</span></strong></p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Score</span></strong></p>
                                    </td>
                                    <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Pretest Percent Correct for the Level</span></strong></p>
                                    </td>
                                    <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Posttest Percent Correct for the Level</span></strong></p>
                                    </td>
                                 </tr>

                                 {renderPartLabel("Part 1: Matter")}
                                 {renderCompetencyRow("1", "Describe the Particle Model of Matter as “All matter is made up of tiny particles with each pure substance having its own kind of particles.", reportData.s1, reportData.percent_correct_s1, reportData.percent_correct_s1)}
                                 {renderCompetencyRow("2", "Describe that particles are constantly in motion, have spaces between them, attract each other, and move faster as the temperature increases (or with the addition of heat)", reportData.s2, reportData.percent_correct_s2, reportData.percent_correct_s2)}
                                 {renderCompetencyRow("3", "Use diagrams and illustrations to describe the arrangement, spacing, and relative motion of the particles in each of the three states (phases) of matter", reportData.s3, reportData.percent_correct_s3, reportData.percent_correct_s3)}
                                 {renderCompetencyRow("4", "Explain the changes of state in terms of particle arrangement and energy changes: (a.) solid → liquid → vapor, and (b.) vapor → liquid → solid;", reportData.s4, reportData.percent_correct_s4, reportData.percent_correct_s4)}
                                 {renderCompetencyRow("5", "Follow appropriate steps of a scientific investigation which includes: a. Aim or problem, b. Materials and equipment, c. Method or procedures, d. Results including data, and e. Conclusion.", reportData.s5, reportData.percent_correct_s5, reportData.percent_correct_s5)}
                                 {renderCompetencyRow("6", "Make accurate measurements using standard units for physical quantities and organize the collected data when carrying out a scientific investigation", reportData.s6, reportData.percent_correct_s6, reportData.percent_correct_s6)}
                                 {renderCompetencyRow("7", "Identify the role of the solute and solvent in a solution", reportData.s7, reportData.percent_correct_s7, reportData.percent_correct_s7)}
                                 {renderCompetencyRow("8", "Express quantitatively the amount of solute present in a given volume of solution", reportData.s8, reportData.percent_correct_s8, reportData.percent_correct_s8)}
                                 {renderCompetencyRow("9", "Demonstrate how different factors affect the solubility of a solute in a given solvent, such as heat", reportData.s9, reportData.percent_correct_s9, reportData.percent_correct_s9)}
                                 {renderCompetencyRow("10", "Identify solutions, which can be found at home and in school and that react with litmus indicator, as acids, bases, and salts", reportData.s10, reportData.percent_correct_s10, reportData.percent_correct_s10)}
                                 {renderCompetencyRow("11", "Demonstrate proper use and handling of science equipment.", reportData.s11, reportData.percent_correct_s11, reportData.percent_correct_s11)}
                                 {renderPartLabel("Part 2: Life Science")}
                                 {renderCompetencyRow("12", "Identify the parts and functions, and demonstrate proper handling and storing of a compound microscope", reportData.s12, reportData.percent_correct_s12, reportData.percent_correct_s12)}
                                 {renderCompetencyRow("13", "Identify the parts and functions, and demonstrate proper handling and storing of a compound microscope", reportData.s13, reportData.percent_correct_s13, reportData.percent_correct_s13)}
                                 {renderCompetencyRow("14", "Use proper techniques in observing and identifying the parts of a cell with a microscope such as the cell membrane, nucleus, cytoplasm, mitochondria, chloroplasts, and ribosomes;", reportData.s14, reportData.percent_correct_s14, reportData.percent_correct_s14)}
                                 {renderCompetencyRow("15", "Recognize that some organisms consist of a single cell (unicellular) like in bacteria and some consist of many cells (multicellular) like in a human;", reportData.s15, reportData.percent_correct_s15, reportData.percent_correct_s15)}
                                 {renderCompetencyRow("16", "Differentiate plant and animal cells based on their organelles;", reportData.s16, reportData.percent_correct_s16, reportData.percent_correct_s16)}
                                 {renderCompetencyRow("17", "Recognize that cells reproduce through two types of cell division, mitosis and meiosis, and describe mitosis as cell division for growth and repair;", reportData.s17, reportData.percent_correct_s17, reportData.percent_correct_s17)}
                                 {renderCompetencyRow("18", "Explain that genetic information is passed onto offspring from both parents by the process of meiosis and fertilization", reportData.s18, reportData.percent_correct_s18, reportData.percent_correct_s18)}
                                 {renderCompetencyRow("19", "Differentiate sexual from asexual reproduction in terms of: a) number of parents involved, and b) similarities of offspring to parents", reportData.s19, reportData.percent_correct_s19, reportData.percent_correct_s19)}
                                 {renderCompetencyRow("20", "Use a labeled diagram to describe the connections between the levels of biological organization to one another from cells to the biosphere", reportData.s20, reportData.percent_correct_s20, reportData.percent_correct_s20)}
                                 {renderCompetencyRow("21", "Describe the trophic levels of an organism as levels of energy in a food pyramid;", reportData.s21, reportData.percent_correct_s21, reportData.percent_correct_s21)}
                                 {renderCompetencyRow("22", "Use examples of food pyramids to describe the transfer of energy between organisms from one trophic level to another.", reportData.s22, reportData.percent_correct_s22, reportData.percent_correct_s22)}
                                 {renderPartLabel("Part 3: Force, Motion, and Energy")}
                                 {renderCompetencyRow("23", "Identify that forces act between objects and can be measured.", reportData.s23, reportData.percent_correct_s23, reportData.percent_correct_s23)}
                                 {renderCompetencyRow("24", "Identify and describe everyday situations that demonstrate: a. balanced forces such as a box resting on an inclined plane, a man standing still, or an object moving with constant velocity; b. unbalanced forces, such as freely falling fruit or an accelerating car", reportData.s24, reportData.percent_correct_s24, reportData.percent_correct_s24)}
                                 {renderCompetencyRow("25", "Identify and describe everyday situations that demonstrate: a. balanced forces such as a box resting on an inclined plane, a man standing still, or an object moving with constant velocity; b. unbalanced forces, such as freely falling fruit or an accelerating car", reportData.s25, reportData.percent_correct_s25, reportData.percent_correct_s25)}
                                 {renderCompetencyRow("26", "Identify that when forces are not balanced, they can cause changes in the object’s speed or direction of motion;", reportData.s26, reportData.percent_correct_s26, reportData.percent_correct_s26)}
                                 {renderCompetencyRow("27", "Explain the difference between distance and displacement", reportData.s27, reportData.percent_correct_s27, reportData.percent_correct_s27)}
                                 {renderCompetencyRow("28", "Distinguish between speed and velocity using the concept of vectors", reportData.s28, reportData.percent_correct_s28, reportData.percent_correct_s28)}
                                 {renderCompetencyRow("29", "Describe uniform velocity and represent it using distance-time graphs", reportData.s29, reportData.percent_correct_s29, reportData.percent_correct_s29)}
                                 {renderCompetencyRow("30", "Explain the difference between heat and temperature", reportData.s30, reportData.percent_correct_s30, reportData.percent_correct_s30)}
                                 {renderCompetencyRow("31", "Identify advantageous and disadvantageous examples of conduction, convection, and radiation", reportData.s31, reportData.percent_correct_s31, reportData.percent_correct_s31)}
                                 {renderCompetencyRow("32", "Explain in terms of the particle model the processes underlying convection and conduction of heat energy", reportData.s32, reportData.percent_correct_s32, reportData.percent_correct_s32)}
                                 {renderCompetencyRow("33", "Explain in terms of the particle model the processes underlying convection and conduction of heat energy", reportData.s33, reportData.percent_correct_s33, reportData.percent_correct_s33)}
                                 {renderPartLabel("Part 4: Earth and Space")}
                                 {renderCompetencyRow("34", "Classify geological faults according to the angle of the fault plane and direction of slip", reportData.s34, reportData.percent_correct_s34, reportData.percent_correct_s34)}
                                 {renderCompetencyRow("35", "Describe how the effects of earthquakes on communities depend on their magnitude", reportData.s35, reportData.percent_correct_s35, reportData.percent_correct_s35)}
                                 {renderCompetencyRow("36", "Explain how earthquakes result in tsunamis that devastate shoreline communities", reportData.s36, reportData.percent_correct_s36, reportData.percent_correct_s36)}
                                 {renderCompetencyRow("37", "Describe procedures that the authorities have in place to alert communities of pending tsunamis and what procedures can be implemented should a tsunami impact a community", reportData.s37, reportData.percent_correct_s37, reportData.percent_correct_s37)}
                                 {renderCompetencyRow("38", "Explain how energy from the Sun interacts with the atmosphere.", reportData.s38, reportData.percent_correct_s38, reportData.percent_correct_s38)}
                                 {renderCompetencyRow("39", "Explain, using models, how the tilt of the Earth affects the changes in the length of daytime at different times of the year", reportData.s39, reportData.percent_correct_s39, reportData.percent_correct_s39)}
                                 {renderCompetencyRow("40", "Explain how solar energy contributes to the occurrence of land and sea breezes, monsoons, and the Intertropical Convergence Zone (ITCZ)", reportData.s40, reportData.percent_correct_s40, reportData.percent_correct_s40)}

                              </tbody>
                           </table>
                        </div>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt' }}><a name="_Hlk103365502"><strong>Summary of Results.</strong>&nbsp; The result for the content standards is expressed in terms of the raw score, percent correct, standard score, and proficiency level.&nbsp;</a></p>
                        <ul type="disc" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt', textAlign: 'justify' }}><strong><span style={{ fontFamily: 'Calibri' }}>Raw Score.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span><span style={{ fontFamily: 'Calibri' }}>The raw score is the total number of correct answers for the content standard.&nbsp;</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt', textAlign: 'justify' }}><strong><span style={{ fontFamily: 'Calibri' }}>Percent Correct.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span><span style={{ fontFamily: 'Calibri' }}>The percent correct is the raw score converted into percentage. The percent correct provides you with an easier way to determine how many correct answers you are able to obtain for each content standard. If the percent correct is close to 100%, the more correct answers you are able to attain.</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt', textAlign: 'justify' }}><strong><span style={{ fontFamily: 'Calibri' }}>Standard score.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;The scores are converted into a standard score to allow comparison of scores across content standards and across levels. A standard score near 0.0 means that the learner’s standing in the content standard is within the majority of the test takers. A standard score of more than 1.00 mean that the attainment of the competencies is high because there are several correct answers.&nbsp;</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt', textAlign: 'justify' }}><strong><span style={{ fontFamily: 'Calibri' }}>Proficiency Level.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;The proficiency level describes your present level of attainment on content standard. The levels of proficiency start from beginner to basic to competent. The beginner level means few correct answers attained in the content standard. The basic level means that the score is within the majority of the test takers. Competent means that several correct answers are attained.</span><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span></li>
                        </ul>
                        {
                           graph_data !== null &&
                           <div style={{ marginTop: "20px", paddingLeft: '120px', paddingRight: "120px" }}>
                              <Bar options={graph_options} data={graph_data} />
                           </div>
                        }


                        <div style={{ pageBreakBefore: 'always' }}></div>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '12pt' }}><strong>Summary of Results</strong></p>
                        <table cellSpacing={0} cellPadding={0} style={{ border: '0.75pt solid #cccccc', borderCollapse: 'collapse', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td style={{ width: '197.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Areas</span></strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Raw Score</span></strong></p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent Correct</span></strong></p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Standard Score</span></strong></p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Proficiency Level</span></strong></p>
                                 </td>
                              </tr>
                              {renderSummaryRow("Matter", matter, 11, 5.68, 1.79)}
                              {renderSummaryRow("Life Science", living, 11, 4.9, 1.52)}
                              {renderSummaryRow("Force, Motion, and Energy", force, 11, 4.4, 1.62)}
                              {renderSummaryRow("Earth and Space", earth, 7, 4.28, 1.25)}
                              {renderSummaryRow("Total", matter + living + force + earth, 40, 20.74, 4.35)}
                           </tbody>
                        </table>

                        <InterpretationInd backgroundColor='#4F81BD' />

                        <p style={{ marginTop: '0pt', marginBottom: '10pt' }}>&nbsp;</p>
                        <p style={{ marginTop: '0pt', marginBottom: '10pt' }}><strong>Name:&nbsp;</strong>{reportData.student_name}</p>
                     </div>
                  </div>
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }
      </>
   );
}