export const NoticeBoardModel = {
    id: 0,
    title: '',
    content: '',
    active: 0,
    issection: 0,
    sectionlist: [],
    isgradelevel: 0,
    gradelevellist: '',
    isacad: 0,
    acadlevellist: '',
    staffid: 0
} 