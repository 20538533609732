import React from "react"

import ReactPlayer from "react-player"
// import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
// Import the styles
// import '@react-pdf-viewer/core/lib/styles/index.css';
import "./FileElement.scss"
import PDFLessonViewer from "../../LessonComponents/PDFLessonViewer";
import ViewMSOfficeFile from "../../LessonComponents/ViewMSOfficeFile";

// import * as AntDIcons from '@ant-design/icons';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/toolbar/lib/styles/index.css';
// // import "../Viewer/Components/FileElement.scss"
// import Iframe from "react-iframe";


const FilesElement = props => {
  const { metadata, id, position, dimension, type } = props
  const elementRef = React.useRef(null)
  const [showGrids, setShowGrids] = React.useState(false)
  // const toolbarPluginInstance = toolbarPlugin();
  // const { Toolbar } = toolbarPluginInstance;

//   const pageLayout = {
//     transformSize: ({ size }) => ({
//         height: size.height + 30,
//         width: size.width + 30,
//     }),
// };

// const toolbarPDF = () =>
// {
//   return(
//     <div
//     style={{
//         alignItems: 'center',
//         backgroundColor: '#eeeeee',
//         border: '1px solid rgba(0, 0, 0, 0.2)',
//         borderRadius: '2px',
//         bottom: '16px',
//         display: 'flex',
//         left: '50%',
//         padding: '4px',
//         position: 'absolute',
//         transform: 'translate(-50%, 0) scale(4)',
//         zIndex: 1000,
//         // transform : "scale(4)",
//     }}
// >
//     <Toolbar>
//         {(props) => {
//             const {
//                 CurrentPageInput,
//                 Download,
//                 EnterFullScreen,
//                 GoToNextPage,
//                 GoToPreviousPage,
//                 NumberOfPages,
//                 Print,
//                 ZoomIn,
//                 ZoomOut,
//             } = props;
//             return (
//                 <>
//                     <div style={{ padding: '0px 2px'}}>
//                         <ZoomOut />
//                     </div>
//                     <div style={{ padding: '0px 2px' }}>
//                         <ZoomIn />
//                     </div>
//                     <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
//                         <GoToPreviousPage />
//                     </div>
//                     <div style={{ padding: '0px 2px', width: '3rem' }}>
//                         <CurrentPageInput />
//                     </div>
//                     {/* <div style={{ padding: '0px 2px' }}>
//                         <NumberOfPages />
//                     </div> */}
//                     <div style={{ padding: '0px 2px' }}>
//                         <GoToNextPage />
//                     </div>
//                     <div style={{ padding: '10px' }}>
//                         <AntDIcons.FullscreenOutlined style={{height : "40px", width : "40px", cursor : "pointer" }} 
//                               onClick={()=> window.open(`${props.metadata.filename}`, "_blank")}
//                         />
//                     </div>
//                     <div style={{ padding: '0px 2px' }}>
//                         <Download />
//                     </div>
//                     <div style={{ padding: '0px 2px' }}>
//                         <Print />
//                     </div>
//                 </>
//             );
//         }}
//     </Toolbar>
// </div>    
//   )
// }

    return(
            <div 
                ref={elementRef}                 
                 style={{ position : "absolute", 
                          left: position?.left || 0, 
                          top: position?.top || 0,
                          width: dimension?.width || 0, 
                          height: dimension?.height || 0,     
                          transform : dimension?.transform || "translate(1px, 1px)",                 
                          fontFamily: "Arial",
                          fontSize: "13px",
                          padding: 0,
                          float : "left",
                          border: `2px solid ${
                            showGrids 
                              ? "#21DEE5"
                              : "transparent"
                          }`,                            
                        }}
            >

                   {props.metadata.filetype === "image" && <img style={{position: "relative" , width: "100%", height : "100%"}} src={props.metadata.filename}></img>}
                   {/* {props.metadata.filetype === "pdf" &&     <RenderPDFViewer url={props.metadata.filename} height={"100%"} />}  */}
                   {props.metadata.filetype === "pdf" && 
                   <div  style={{ width : "100%", height : "100%", backgroundColor : "white" }}>
                       {/* <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}> */}
                         <PDFLessonViewer fileUrl={props.metadata.filename} />
                      {/* </Worker> */}
                   </div>
                   } 
                   {props.metadata.filetype === "msoffice" && 
                         <ViewMSOfficeFile filename={`${props.metadata.filename}`} readonly={false}/>
                    }
                    {props.metadata.filetype === "video" &&       <ReactPlayer
                            controls={true}
                            width='100%'
                            height={"100%"}
                            url={props.metadata.filename}
                        />}                          

            </div>   
    )     
}

export default FilesElement
