import React, { useEffect } from 'react';
import "./SearchServiceGoogleAPI.scss";
import GoogleService from '../../../services/GoogleService';
import { SearchOutlined,AudioOutlined } from '@ant-design/icons';

import Enums from '../../../misc/Enums';
import { Input, Skeleton } from 'antd';

export default function SearchServiceGoogleAPI(props) {
    const [searchText, setSearchText] = React.useState("");
    const [searchList, setSearchList] = React.useState([]);
    const [isLoading, setIsLoading] =  React.useState(false);

    const { Search } = Input;
    const suffix = (
      <AudioOutlined
        style={{
          fontSize: 16,
          color: '#1677ff',
        }}
      />
    );

    useEffect(() => {
      setSearchList([])
      setSearchText("")
   }, [props.type]);

    const onSearch = async (e) =>
    {    
        setIsLoading(true)
        var selectedOption = props.type === 0 ? "youtube" : props.type === 1 ? "google.search" : "google.image"
        GoogleService.searchGoogleService(selectedOption, searchText).then((response) => {
            if (response?.data) {
               var varitems = response.data.items;
               var searchResult = [];
               if (selectedOption === Enums.ResourcesType.isYoutube) {
                  varitems.map((item) => {
                     var searchItem = {
                        type: Enums.ResourcesType.isYoutube, videoId: item.id.videoId, title: item.snippet.channelTitle,
                        description: item.snippet.description, image: item.snippet.thumbnails["high"].url
                     }
                     searchResult.push(searchItem);
                  })
               }
               if (selectedOption === Enums.ResourcesType.isGoogleImage) {
                  varitems.map((item) => {
                     var searchItem = {
                        type: Enums.ResourcesType.isGoogleImage, link: item.link, title: item.title,
                        description: item.snippet, image: item.link
                     }
                     searchResult.push(searchItem);
                  })
               }
               if (selectedOption === Enums.ResourcesType.isGoogleSearch) {
                  varitems.map((item) => {
                     var searchItem = {
                        type: Enums.ResourcesType.isGoogleSearch, link: item.link, title: item.title,
                        description: item.snippet, image: item.pagemap.cse_thumbnail ? item.pagemap.cse_image[0].src : ""
                     }
                     searchResult.push(searchItem);
                  })
               }
               setSearchList(searchResult)
               setIsLoading(false)
            }
            else {
               setIsLoading(false)
               // No Data
            }
         }).catch((ex) => {
            setIsLoading(false)
            // message.error(ex.message)
         })
    }

    return(
        <>
            <div className="logo">
               <div style={{color : 'white' , fontWeight : 'bold', width : '100%', fontSize : '18px' }}>
                  {props.type === 0 ? "VIDEO SEARCH" : props.type === 1 ? "WEBSITE SEARCH" : "IMAGE SEARCH"}
               </div>
              {/* <img src={searchLogo}/> */}
            </div>
            <div className='container-search'>
                <div className='search-group'>
                   <Input size="large"  onChange={(e) => setSearchText(e.target.value)}  onPressEnter={onSearch}
                        value={searchText} placeholder="Search Keywords" prefix={<SearchOutlined />} style={{borderRadius : '5px'}} />
                </div>
            </div>
            {!isLoading ? <div className='result-container'>
               {
               searchList.map((result) =>
               {
                  return(
                     <>
                        <div className='image-container' onClick={() => props.add(result)} > 
                           <img className='image-result' src={result.image}/>
                        </div>
                        <div style={{ width : '250px', margin : 'auto', paddingTop : '10px'}}>
                           <div className='author-style'  >{result.title}</div>
                           <div className='description-style' >{result.description}</div>                          
                        </div>
                        <br/>               
                     </>
                  )
               })   
               }
            </div>:
            <div className='result-container'>
               <div className='image-container' > 
                <Skeleton.Image active className='image-result' style={{width : "250px" , height : "180px"}}/>
               </div>
               <div style={{ width : '250px', margin : 'auto', paddingTop : '10px'}}>
                  <div className=' author-style'  ><Skeleton.Input active style={{width : "250px" }}/></div>
                  <div className=' description-style' ><Skeleton.Input active  style={{width : "250px" }}/></div>    
               </div>           
            </div>
            }
        </>
    )
}