import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Space } from 'antd';
import * as AntDIcons from '@ant-design/icons';
import { Tooltip, Popconfirm, Typography } from 'antd';
// import EllipsisText from "react-ellipsis-text";
import { useSelector, useDispatch } from 'react-redux'
import { HolderOutlined } from '@ant-design/icons';
import { update_lesson_order, delete_item } from '../../actions';
import moment from 'moment';
import './LessonSection.scss'

export function LessonSection(props) {
   const { Text, Paragraph } = Typography;
   const [lessons, setLessons] = React.useState([]);
   const [viewMode, setViewMode] = React.useState('');
   var TopicIndex = props.index;

   const lessonState = useSelector(state => state.lesson)
   const dispatch = useDispatch();

   useEffect(() => {
      setLessons(props.lessons)
      setViewMode(props.mode)
   }, [props.lessons]);

   const _onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
         return;
      }
      if (result.source.index !== result.destination.index) {
         var params = { TopicIndex: TopicIndex, SourceIdx: result.source.index, DestinationIdx: result.destination.index }
         dispatch(update_lesson_order(params));
         var newLessons = [...lessonState.sections[TopicIndex].contents];
         setLessons(newLessons)
         props.saveLesson()
         props.refreshLesson();
      }
   }

   const _displayTitle = (indexNum, description, type, effectivedate) => {
      var titleColor = type !== "quiz" ? "blue" : "green"
      return (
         <>
            {viewMode === "edit" && <>
               <div style={{ display: "flex" }}>
                  {/*    <div style={{marginTop : "2px" }}><Text strong>Lesson {indexNum+1} : </Text></div> */}
                  <div style={{ color: `${titleColor}` }} className='text-display'><span style={{ paddingRight: "8px", color: "black" }}>•</span>{description === "" ? "No Description" : description}</div>
               </div>
            </>}
            {viewMode !== "edit" && <>
               <div style={{ display: "flex" }}>
                  {/*   <div><Text strong>Lesson {indexNum+1} : </Text></div> */}
                  <div style={{ color: `${titleColor}` }} className='text-display'><span style={{ paddingRight: "8px", color: "black" }}>•</span>{description === "" ? "No Description" : description}</div>
               </div>
            </>}
         </>
      )
   }

   const _displayTitleView = (indexNum, description, type, effectivedate, current) => {

      var titleColor = type !== "quiz" ? "blue" : "green"
      return (
         <>
            {viewMode === "edit" && <>
               <div style={{ display: "flex" }}>
                  {/* <div><Text strong>Lesson {indexNum+1} : </Text></div> */}
                  <div style={{ color: `${titleColor}` }} className='text-display-view'><span style={{ paddingRight: "8px", color: "black" }}>•</span>{description === "" ? "No Description" : description}</div>
               </div>
            </>}
            {viewMode !== "edit" && <>
               <div style={{ display: "flex" }}>
                  {/* <div><Text strong>Lesson {indexNum+1} : </Text></div> */}
                  <div style={{ color: `${titleColor}` }} className='text-display-view'><span style={{ paddingRight: "8px", color: "black" }}>•</span>{description === "" ? "No Description" : description}</div>
               </div>
            </>}
         </>
      )
   }

   const _displayDescription_editmode = (type, index, description, indexNum, numberMaterials, item) => {
      return <>
         {(type !== "quiz") &&
            <div onClick={() => props.lessonClick(TopicIndex, index, item.type, item.otherid)}>
               <div className='lessonDescription'><span >{_displayTitle(indexNum, description, item.type, item.effectivedate)}</span></div>
               <div style={{ paddingLeft: "20px", textAlign: 'left' }}><Text ellipsis style={{ width: "160px" }}>Total of <span style={{ fontWeight: "bold", color: "red" }}>{numberMaterials}</span>  Material(s) </Text></div>
            </div>
         }
         {type === "quiz" &&
            <div>
               <div className='lessonDescription' onClick={() => props.lessonClick(TopicIndex, index, item.type, item.otherid)}><span >{_displayTitle(indexNum, description, item.type, item.effectivedate)}</span></div>
               <div style={{ paddingLeft: "20px", textAlign: 'left' }}><Text ellipsis style={{ width: "160px" }}>{item.otherdesc}</Text></div>
            </div>
         }
      </>
      /*             } */
   }

   const _displayDescription = (type, index, description, indexNum, numberMaterials, item) => {
      return <>
         {(type !== "quiz") &&
            <div onClick={() => props.lessonClick(TopicIndex, index, item.type, item.otherid)}>
               <div className='lessonDescription' >
                  <span >{_displayTitleView(indexNum, description, item.type, item.effectivedate, true)}</span>
               </div>
               <div style={{ paddingLeft: "20px", textAlign: 'left' }}>
                  <Text ellipsis style={{ width: "160px" }}>Total of <span style={{ fontWeight: "bold", color: "red" }}>{numberMaterials}</span>  Material(s) </Text>
               </div>
            </div>
         }
         {type === "quiz" &&
            <div>
               <div className='lessonDescription' onClick={() => props.lessonClick(TopicIndex, index, item.type, item.otherid)} ><span >{_displayTitleView(indexNum, description, item.type, item.effectivedate, true)}</span></div>
               <div style={{ paddingLeft: "20px", textAlign: 'left' }}><Text ellipsis style={{ width: "160px" }}>{item.otherdesc}</Text></div>
            </div>
         }
      </>
      /*             } */
   }

   const handleDeleteRequest = (id) => {
      var params = { Type: "LESSON", TopicIndex: TopicIndex, SourceIdx: id }
      dispatch(delete_item(params));
      var newItems = [...lessonState.sections[TopicIndex].contents];
      setLessons(newItems)
      props.saveLesson()
   }



   const _displayItemMenu = (id, type, version) => {
      return <>
         <Space size="small" style={{ paddingRight: '10px' }}>
            {<Tooltip placement="bottom" title={(version === 2 || type === "quiz") ? 'Add/Edit Resources' : 'Editing is prohibited for the old version'} key={'1'}>
               <AntDIcons.EditOutlined color='#4abec7' style={{ cursor: 'pointer'}} onClick={() => (version === 2 || type === "quiz") ? props.editLesson(id, TopicIndex, type) : "" } />
            </Tooltip>}
            <Tooltip placement="bottom" title='Remove Resource' key={'2'}>
               <Popconfirm
                  title="Delete this RESOURCE ?"
                  onConfirm={() => handleDeleteRequest(id)}
                  okText="Yes"
                  cancelText="No">
                  <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
               </Popconfirm>
            </Tooltip>
         </Space>
      </>

   }

   return <>
      {viewMode === "edit" && <DragDropContext onDragEnd={_onDragEnd}>
         <Droppable droppableId="droppablelesson">
            {(provided, snapshot) => (
               <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
               >
                  {lessons.map((item, LessonIndex) => (
                     <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={LessonIndex}>
                        {(provided, snapshot) => (
                           <div className='ItemLesson'>
                              <div
                                 ref={provided.innerRef}
                                 {...provided.draggableProps}
                                 {...provided.dragHandleProps}
                                 className={'individualLesson'}
                                 style={{ ...provided.draggableProps.style }}
                              >
                                 <span
                                    className={'lesson-icon-drag'}
                                    style={{ marginTop: "-2px" }}
                                 >
                                    <HolderOutlined />
                                 </span>
                                 <div className='lesson-description' >
                                    {_displayDescription_editmode(item.type, LessonIndex + "-" + TopicIndex, item.title, LessonIndex, item.data?.length > 0 ? item.data?.length : item.links.length , item)}
                                 </div>
                                 <div className={'menu-lesson'}>{_displayItemMenu(item.id, item.type, item.data?.length >= 0 ? 2 : 0)}</div>
                                 {provided.placeholder}
                              </div>
                           </div>
                        )}
                     </Draggable>
                  ))}
                  {provided.placeholder}
               </div>
            )}
         </Droppable>
      </DragDropContext>}

      {viewMode === "view" &&
         <div>
            {lessons && lessons.map((item, LessonIndex) => (
               <div className={'individualLesson'}>
                  <div style={{ cursor: "pointer", marginLeft: "25px", width: '100%' }}>
                     {_displayDescription(item.type, LessonIndex + "-" + TopicIndex, item.title, LessonIndex, item.data?.length > 0 ? item.data?.length : item.links.length, item)}
                  </div>
               </div>
            ))}
         </div>
      }
   </>
}


