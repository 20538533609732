import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
   Input, Dropdown, Table, Typography, Space, Tooltip, Button, Select, Row, Col, Modal, Spin, Menu, Breadcrumb
} from 'antd';
import { SearchOutlined, BarChartOutlined, FormOutlined } from '@ant-design/icons';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';

const { Option } = Select;
// const { TabPane } = Tabs;
const { Text } = Typography;

export function MasteryAssessmentReport(props) {
   const navigate = useNavigate();
   // const location = useLocation();
   // const dispatch = useDispatch();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);
   // const user_full_name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);

   const [searchKeys, setSearchKeys] = useState(() => {
      var tmp = JSON.parse(localStorage.getItem("mastery-report-search-keys"));
      if (tmp !== null)
         return tmp;
      else {
         return tmp = {
            term: null,
            level: null,
            subject: null,
            userid: user_id,
         };
      }
   });

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   // const [sectionList, setSectionList] = useState([]);
   const [assignedList, setAssignedList] = useState([]);
   const [listLoading, setListLoading] = useState(false);
   // const [activeTab, setActiveTab] = useState("1");

   // const [term, setTerm] = useState(null);
   // const [level, setLevel] = useState(null);
   // const [section, setSection] = useState(null);
   // const [subject, setSubject] = useState(null);
   // const [selectedUser, setSelectedUser] = useState(user_id);

   const [lookUps, setLookUps] = useState({
      SchoolTerms: null,
      MasteryGradeLevels: null,
      MasterySubjects: null,
   });

   const [userList, setUserList] = useState([]);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'co-teacher')
         navigate('/home');

      getLookUps();

      if (user_role.toLowerCase() === "admin") {
         var url = `${process.env.REACT_APP_API_LESSON}/listAllStaff`;
         axios.get(url).then((response) => {
            if (response.data) {
               let data = response.data;
               var users = data.filter((d) => d.id !== Number(searchKeys.userid)).sort((a, b) => {
                  var aVal = a["first_Name"];
                  var bVal = b["first_Name"];
                  switch (typeof aVal) {
                     case 'string':
                        return aVal.localeCompare(bVal);
                     case 'number':
                        return aVal - bVal;
                     default:
                        throw new Error("Unsupported value to sort by");
                  }
               });
               var final = data.filter((d) => d.id === Number(searchKeys.userid));
               Array.prototype.push.apply(final, users);
               setUserList(final)
            }
         }, (error) => {
            // setLoading(false);
            console.log(error.message);
         });
      }
   }, []);

   const handleUserChange = (id) => {
      // setSelectedUser(id);
      HandleSetSearchKeys(searchKeys.term, searchKeys.level, searchKeys.subject, id);
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const assigned_ass_columns = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: 'Subject',
         key: 'subject_name',
         dataIndex: 'subject_name',
         sorter: (a, b) => { return a.subject_name.localeCompare(b.subject_name) },
         width: '18%'
      },
      {
         title: 'Level',
         key: 'level_name',
         dataIndex: 'level_name',
         sorter: (a, b) => { return a.level_name.localeCompare(b.level_name) },
         width: '10%'
      },
      {
         title: 'Code',
         dataIndex: 'competency',
         key: 'competency',
         sorter: (a, b) => { return a.competency.localeCompare(b.competency) },
         ...getColumnSearchProps('competency'),
         width: '10%'
      },
      {
         title: 'Competency Title',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: title => (
            <Tooltip placement="topLeft" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '28%'
      },
      {
         title: 'assessment_id',
         key: 'assessment_id',
         dataIndex: 'assessment_id',
         hidden: true,
      },
      // {

      //    title: 'Assigned By',
      //    key: 'assigned_by_name',
      //    ...getColumnSearchProps('assigned_by_name'),
      //    ellipsis: {
      //       showTitle: true,
      //    },
      //    render: object => (
      //       <Tooltip placement="topLeft" title={object.assigned_by_name}>
      //          <Text>{object.assigned_by_name}</Text>
      //       </Tooltip>
      //    ),
      //    width: '15%'
      // },
      {
         title: 'Assigned To',
         key: 'section_name',
         dataIndex: 'section_name',
         sorter: (a, b) => { return a.section_name.localeCompare(b.section_name) },
         width: '15%'
      },
      // {
      //    title: 'Date Assigned',
      //    key: 'date_created',
      //    dataIndex: 'date_created',
      //    sorter: (a, b) => { return a.date_created.localeCompare(b.date_created)},
      //    width:'20%'
      // },
      {
         title: 'Start Date',
         key: 'start_date',
         dataIndex: 'start_date',
         sorter: (a, b) => { return a.start_date.localeCompare(b.start_date) },
         width: '15%'
      },
      {
         title: 'End Date',
         key: 'end_date',
         dataIndex: 'end_date',
         sorter: (a, b) => { return a.end_date.localeCompare(b.end_date) },
         width: '15%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Report Type 1'><BarChartOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleShowReportType1(payload.id)} /></Tooltip>
               </Space>
            </>
         },
         width: '12%'
      },
   ].filter(item => !item.hidden);

   const getLookUps = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasteryterms`).then((response) => {
         if (response.data) {
            const data = response.data;

            var school_terms = [];
            var levels = [];
            var subjects = [];

            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               school_terms.push(obj);
            });

            axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterygradelevels`).then((response) => {
               if (response.data) {
                  const data = response.data;
                  data.map((item) => {
                     let obj = { 'value': item.value, 'name': item.name }
                     levels.push(obj);
                  });

                  axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterysubjects`).then((response) => {
                     if (response.data) {
                        const data = response.data;
                        data.map((item) => {
                           let obj = { 'value': item.value, 'name': item.name }
                           subjects.push(obj);
                        });

                        setLookUps({
                           SchoolTerms: school_terms,
                           MasteryGradeLevels: levels,
                           MasterySubjects: subjects,
                        });

                        //--If search keys are not empty
                        if (searchKeys.term !== null && searchKeys.level !== null && searchKeys.subject !== null &&
                           searchKeys.term !== undefined && searchKeys.level !== undefined && searchKeys.subject !== undefined) {

                           getAssignedtList();
                        }
                     }
                  }, (error) => {
                     console.log(error.message);
                  });
               }
            }, (error) => {
               console.log(error.message);
            });

         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getAssignedtList = () => {
      if (searchKeys.term !== null && searchKeys.level !== null && searchKeys.subject !== null &&
         searchKeys.term !== undefined && searchKeys.level !== undefined && searchKeys.subject !== undefined) {

         setListLoading(true);

         var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/listassigned?assigned_by=${searchKeys.userid}&time_tense=${0}&term=${searchKeys.term}&level=${searchKeys.level}&subject=${searchKeys.subject}`;

         // if (user_role === 'Admin')
         //    url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/listallassigned?time_tense=${3}&term=${term}&level=${level}&subject=${subject}`;

         axios.get(url).then((response) => {
            console.log(response);

            if (response.data) {
               const data = response.data;
               setListLoading(false);
               setAssignedList(data);
            }
         }, (error) => {
            console.log(error.message);
         });
      }
   }

   const handleSearchAssignedList = () => {
      if (searchKeys.term !== null && searchKeys.level !== null && searchKeys.subject !== null &&
         searchKeys.term !== undefined && searchKeys.level !== undefined && searchKeys.subject !== undefined) {

         getAssignedtList();
      }
      else {
         // message.error(`Please select all the criteria!`);
         Modal.error({
            title: 'Mastery Assessment Report',
            content: 'Please select all the criteria.',
            centered: true
         });
      }
   }

   const handleShowReportType1 = (assign_id) => {
      navigate('/masteryreport1?assign_id=' + assign_id);
   }

   const handleShowReportType = (type, term, level, subject, assigned_by) => {

      if (user_role.toLowerCase() === "admin") {
         if (assigned_by === null) {
            Modal.error({
               title: 'Mastery Assessment Report',
               content: 'Please select a teacher from the list.',
               centered: true
            });
         } else {
            if (type === 2)
               navigate(`/masteryreport2?term=${term}&level=${level}&subject=${subject}&assigned_by=${assigned_by}`);

            if (type === 3)
               navigate(`/masteryreport3?term=${term}&level=${level}&subject=${subject}&assigned_by=${assigned_by}`);

            if (type === 4)
               navigate(`/masteryreport4?term=${term}&level=${level}&subject=${subject}&assigned_by=${assigned_by}`);

            if (type === 5)
               navigate(`/masteryreport5?level=${level}&subject=${subject}&assigned_by=${assigned_by}`);
         }
      } else {
         if (type === 2)
            navigate(`/masteryreport2?term=${term}&level=${level}&subject=${subject}&assigned_by=${assigned_by}`);

         if (type === 3)
            navigate(`/masteryreport3?term=${term}&level=${level}&subject=${subject}&assigned_by=${assigned_by}`);

         if (type === 4)
            navigate(`/masteryreport4?term=${term}&level=${level}&subject=${subject}&assigned_by=${assigned_by}`);

         if (type === 5)
            navigate(`/masteryreport5?level=${level}&subject=${subject}&assigned_by=${assigned_by}`);
      }
   }

   const HandleSetSearchKeys = (term, level, subject, userid) => {
      var searchKeys = {
         term,
         level,
         subject,
         userid,
      }

      localStorage.setItem("mastery-report-search-keys", JSON.stringify(searchKeys));

      setSearchKeys({
         term,
         level,
         subject,
         userid,
      });
   }

   const handleMenuClick = (e) => {
      switch (e.key) {
         case '1':
            handleShowReportType(2, searchKeys.term, searchKeys.level, searchKeys.subject, searchKeys.userid);
            break;
         case '2':
            handleShowReportType(3, searchKeys.term, searchKeys.level, searchKeys.subject, searchKeys.userid);
            break;
         case '3':
            handleShowReportType(4, searchKeys.term, searchKeys.level, searchKeys.subject, searchKeys.userid);
            break;
         case '4':
            handleShowReportType(5, searchKeys.term, searchKeys.level, searchKeys.subject, searchKeys.userid);
            break;
      }
   };

   const menu_formative = (
      <Menu
         onClick={handleMenuClick}
         items={[
            {
               label: 'Report Type 2',
               key: '1',
               icon: <FormOutlined />,
            },
            {
               label: 'Report Type 3',
               key: '2',
               icon: <FormOutlined />,
            },
            {
               label: 'Report Type 4',
               key: '3',
               icon: <FormOutlined />,
            },
            {
               label: 'Report Type 5',
               key: '4',
               icon: <FormOutlined />,
            },
         ]}
      />
   );

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li className="active">Mastery Assessment Report</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>Mastery Assessment Report</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               {lookUps.SchoolTerms !== null
                  ?
                  <>
                     <Row style={{ paddingBottom: "20px" }}>
                        <Col className="gutter-row" span={24}>
                           {user_role.toLowerCase() === 'admin' &&
                              <>
                                 <span>View as </span>
                                 <Select
                                    showSearch
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.includes(input)}
                                    // defaultValue={user_full_name}
                                    style={{ width: 250 }}
                                    onChange={(value) => handleUserChange(value)}
                                    value={Number(searchKeys.userid)}
                                 >
                                    {
                                       userList.map((item) => {
                                          var fullname = (item.first_Name + " " + item.last_Name).trimEnd()
                                          return (
                                             <Option value={item.id}>{fullname}</Option>
                                          )
                                       })
                                    }
                                 </Select>
                              </>
                           }
                        </Col>
                     </Row>

                     <Row gutter={12} style={{ paddingBottom: "20px" }}>
                        <Col className="gutter-row" span={24}>
                           <div className='search-columns-4'>
                              <Select
                                 showArrow
                                 size='default'
                                 style={{ width: '100%', textAlign: "left" }}
                                 allowClear
                                 placeholder="Select Quarter"
                                 onChange={(val) => { HandleSetSearchKeys(val, searchKeys.level, searchKeys.subject, searchKeys.userid); }}
                                 value={searchKeys.term}
                              >
                                 <Option value={0}>All</Option>
                                 {lookUps.SchoolTerms.map(({ value, name }, idx) => {
                                    return <Option value={value}>{name}</Option>
                                 })}
                              </Select>
                              <Select
                                 showArrow
                                 size='default'
                                 style={{ width: '100%', textAlign: "left" }}
                                 allowClear
                                 placeholder="Select Level"
                                 onChange={(val) => { HandleSetSearchKeys(searchKeys.term, val, searchKeys.subject, searchKeys.userid); }}
                                 value={searchKeys.level}
                              >
                                 <Option value={0}>All</Option>
                                 {lookUps.MasteryGradeLevels.map(({ value, name }, idx) => {
                                    return <Option value={value}>{name}</Option>
                                 })}
                              </Select>
                              <Select
                                 showArrow
                                 size='default'
                                 style={{ width: '100%', textAlign: "left" }}
                                 allowClear
                                 placeholder="Select Subject"
                                 onChange={(val) => { HandleSetSearchKeys(searchKeys.term, searchKeys.level, val, searchKeys.userid); }}
                                 value={searchKeys.subject}
                              >
                                 <Option value={0}>All</Option>
                                 {lookUps.MasterySubjects.map(({ value, name }, idx) => {
                                    return <Option value={value}>{name}</Option>
                                 })}
                              </Select>
                              <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleSearchAssignedList()} icon={<SearchOutlined />}>Search</Button>
                           </div>
                        </Col>
                     </Row>

                     {
                        assignedList.length > 0 &&
                        <Row gutter={12}>
                           <Col span={24} style={{ width: '100%', textAlign: 'right' }}>
                              <Dropdown overlay={menu_formative} placement='bottomCenter' trigger={['click']} arrow>
                                 <Button className='button-shadow' size='middle' shape='round' type='primary'><BarChartOutlined /> Generate Report</Button>
                              </Dropdown>
                           </Col>
                        </Row>
                     }

                     <div className="dashboard-item-inner3">
                        <Table
                           loading={listLoading}
                           size='small'
                           columns={assigned_ass_columns}
                           dataSource={assignedList}
                           rowKey={'uid'}
                           pagination={{ position: ['topCenter'] }}
                           scroll={{
                              x: '1000px',
                              y: 'calc(100svh - 20.5rem)',
                           }}
                           footer={() => 'Total Records: ' + assignedList.length}
                        />
                     </div>

                  </>
                  :
                  <>
                     <div className='mastery_assessment_list'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  </>
               }
            </div>
         </div>

         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar/>
               <img src="../images/brainee_ma.png" width="100%" style={{ padding: "5px", maxWidth: '600px' }}></img>
               <NoticeBoard />
            </div>
         </div> */}
      </>
   );
}

