/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import {
   Row, Col, Empty, Spin
} from 'antd';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { IntroSum } from '../components';

export function Grade4SummaryReport(props) {
   const [reportData, setReportData] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   const [vocabulary, setVocabulary] = useState(null);
   const [readingComprehension, setReadingComprehension] = useState(null);
   const [grammar, setGrammar] = useState(null);
   const [englishTotal, setEnglishTotal] = useState(null);

   const [numberSense, setNumberSense] = useState(null);
   const [geometry, setGeometry] = useState(null);
   const [patternsAndAlgebra, setPatternsAndAlgebra] = useState(null);
   const [measurement, setMeasurement] = useState(null);
   const [statisticsAndProbability, setStatisticsAndProbability] = useState(null);
   const [mathTotal, setMathTotal] = useState(null);

   const [matter, setMatter] = useState(null);
   const [livingThings, setLivingThings] = useState(null);
   const [forceAndMotion, setForceAndMotion] = useState(null);
   const [earthAndSpace, setEarthAndSpace] = useState(null);
   const [scienceTotal, setScienceTotal] = useState(null);

   const [englishPCData, setEnglishPCData] = useState(null);
   const [mathPCData, setMathPCData] = useState(null);
   const [sciencePCData, setSciencePCData] = useState(null);
   const [VN, setVN] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();

      getReport(props.assignment_id, props.section_id);
   }, [props.assignment_id]);

   const getReport = (assignment_id, section_id) => {
      setDataLoading(true);

      var url = `${process.env.REACT_APP_API_CPH}/getdiagnosticreportdata?aid=${assignment_id}&sid=${section_id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            //-- English
            if (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') {
               var englishPreData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'pre');
               var englishPostData = data.filter(item => item.subject === 'english' && item.diagnostic_type === 'post');
               setVN(englishPreData.length);
            }

            //-- Math
            if (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') {
               var mathPreData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'pre');
               var mathPostData = data.filter(item => item.subject === 'math' && item.diagnostic_type === 'post');
               setVN(mathPreData.length);
            }

            //-- Science
            if (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') {
               var sciencePreData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'pre');
               var sciencePostData = data.filter(item => item.subject === 'science' && item.diagnostic_type === 'post');
               setVN(sciencePreData.length);
            }

            url = `${process.env.REACT_APP_API_CPH}/getdiagnosticsummarypercentdata?aid=${assignment_id}&sid=${section_id}`;
            axios.get(url).then((response) => {

               if (response.data) {
                  const data2 = response.data;

                  if (props.subject_id === null || props.subject_id === 'ems') {
                     setEnglishPCData(data2[0]);
                     extractDataForEnglish(englishPreData, englishPostData);

                     setMathPCData(data2[1]);
                     extractDataForMath(mathPreData, mathPostData);

                     setSciencePCData(data2[2]);
                     extractDataForScience(sciencePreData, sciencePostData);
                  } else {
                     if (props.subject_id === 'english') {
                        setEnglishPCData(data2[0]);
                        extractDataForEnglish(englishPreData, englishPostData);
                     }

                     if (props.subject_id === 'math') {
                        setMathPCData(data2[0]);
                        extractDataForMath(mathPreData, mathPostData);
                     }

                     if (props.subject_id === 'science') {
                        setSciencePCData(data2[0]);
                        extractDataForScience(sciencePreData, sciencePostData);
                     }
                  }

                  setReportData(data);
                  setDataLoading(false);
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const extractDataForEnglish = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _readingComprehension = {};
      var _grammar = {};
      var _vocabulary = {};
      var _total = {};
      var _proficiency = '';

      //-- Vocabulary (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 +
            pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 8.69) / 2.18) > 1)
            _competent++;
         else if (((_sumVal - 8.69) / 2.18) > -0.51)
            _basic++;
         else if (((_sumVal - 8.69) / 2.18) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 +
               post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 8.69) / 2.18) > 1)
                  _competent++;
               else if (((_sumVal - 8.69) / 2.18) > -0.51)
                  _basic++;
               else if (((_sumVal - 8.69) / 2.18) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 7.59 ? 'Beginner' : (_postAve >= 7.6 && _postAve <= 10.9 ? 'Basic' : (_postAve >= 10.91 && _postAve <= 15 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 7.59 ? 'Beginner' : (_preAve >= 7.6 && _preAve <= 10.9 ? 'Basic' : (_preAve >= 10.91 && _preAve <= 15 ? 'Competent' : ''));
      }

      _vocabulary = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Vocabulary (end)      

      //-- Reading Comprehension (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 +
            pre_data[i].s19 + pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 +
            pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34 +
            pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 12.92) / 3.82) > 1)
            _competent++;
         else if (((_sumVal - 12.92) / 3.82) > -0.51)
            _basic++;
         else if (((_sumVal - 12.92) / 3.82) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s16 + post_data[i].s17 + post_data[i].s18 +
               post_data[i].s19 + post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 +
               post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34 +
               post_data[i].s35 + post_data[i].s36 + post_data[i].s37 + post_data[i].s38;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 12.92) / 3.82) > 1)
                  _competent++;
               else if (((_sumVal - 12.92) / 3.82) > -0.51)
                  _basic++;
               else if (((_sumVal - 12.92) / 3.82) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 11 ? 'Beginner' : (_postAve >= 11.01 && _postAve <= 16.7 ? 'Basic' : (_postAve >= 16.71 && _postAve <= 23 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 11 ? 'Beginner' : (_preAve >= 11.01 && _preAve <= 16.7 ? 'Basic' : (_preAve >= 16.71 && _preAve <= 23 ? 'Competent' : ''));
      }

      _readingComprehension = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Reading Comprehension (end)

      //-- Grammar (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s39 + pre_data[i].s40 + pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43 + pre_data[i].s44 + pre_data[i].s45 +
            pre_data[i].s46 + pre_data[i].s47 + pre_data[i].s48 + pre_data[i].s49 + pre_data[i].s50 + pre_data[i].s51 + pre_data[i].s52 + pre_data[i].s53 +
            pre_data[i].s54 + pre_data[i].s55 + pre_data[i].s56 + pre_data[i].s57 + pre_data[i].s58 + pre_data[i].s59 + pre_data[i].s60;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 15.77) / 3.17) > 1)
            _competent++;
         else if (((_sumVal - 15.77) / 3.17) > -0.51)
            _basic++;
         else if (((_sumVal - 15.77) / 3.17) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 +
               post_data[i].s19 + post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 +
               post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 15.77) / 3.17) > 1)
                  _competent++;
               else if (((_sumVal - 15.77) / 3.17) > -0.51)
                  _basic++;
               else if (((_sumVal - 15.77) / 3.17) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 14.2 ? 'Beginner' : (_postAve >= 14.21 && _postAve <= 18.9 ? 'Basic' : (_postAve >= 18.91 && _postAve <= 22 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 14.2 ? 'Beginner' : (_preAve >= 14.21 && _preAve <= 18.9 ? 'Basic' : (_preAve >= 18.91 && _preAve <= 22 ? 'Competent' : ''));
      }

      _grammar = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Grammar (end)

      _total = {
         vn: pre_data.length,
         prem: _readingComprehension.prem + _grammar.prem + _vocabulary.prem,
         postm: _readingComprehension.postm + _grammar.postm + _vocabulary.postm,
         min: _readingComprehension.min + _grammar.min + _vocabulary.min,
         max: _readingComprehension.max + _grammar.max + _vocabulary.max,
         sd: _readingComprehension.sd + _grammar.sd + _vocabulary.sd,
         proficiency: '',
         beginner: _readingComprehension.beginner + _grammar.beginner + _vocabulary.beginner,
         basic: _readingComprehension.basic + _grammar.basic + _vocabulary.basic,
         competent: _readingComprehension.competent + _grammar.competent + _vocabulary.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 26.7 ? 'Beginner' : (_total.postm >= 26.71 && _total.postm <= 37.6 ? 'Basic' : (_total.postm >= 37.61 && _total.postm <= 60 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 26.7 ? 'Beginner' : (_total.prem >= 26.71 && _total.prem <= 37.6 ? 'Basic' : (_total.prem >= 37.61 && _total.prem <= 60 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setEnglishTotal(_total);
      setVocabulary(_vocabulary);
      setReadingComprehension(_readingComprehension);
      setGrammar(_grammar);
   }

   const extractDataForMath = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _numbersense = {};
      var _geometry = {};
      var _algebra = {};
      var _measurement = {};
      var _statistics = {};
      var _proficiency = '';
      var _total = 0;

      //-- Number Sense (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10 +
            pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 + pre_data[i].s20 +
            pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 11.94) / 4.33) > 1)
            _competent++;
         else if (((_sumVal - 11.94) / 4.33) > -0.51)
            _basic++;
         else if (((_sumVal - 11.94) / 4.33) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10 +
               post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 + post_data[i].s20 +
               post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 11.94) / 4.33) > 1)
                  _competent++;
               else if (((_sumVal - 11.94) / 4.33) > -0.51)
                  _basic++;
               else if (((_sumVal - 11.94) / 4.33) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 8.27 ? 'Beginner' : (_postAve >= 8.28 && _postAve <= 16.5 ? 'Basic' : (_postAve >= 16.51 && _postAve <= 25 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 8.27 ? 'Beginner' : (_preAve >= 8.28 && _preAve <= 16.5 ? 'Basic' : (_preAve >= 16.51 && _preAve <= 25 ? 'Competent' : ''));
      }

      _numbersense = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Number Sense (end)

      //-- Geometry (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.75) / 1.13) > 1)
            _competent++;
         else if (((_sumVal - 1.75) / 1.13) > -0.51)
            _basic++;
         else if (((_sumVal - 1.75) / 1.13) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s26 + post_data[i].s27 + post_data[i].s28 + post_data[i].s29;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.75) / 1.13) > 1)
                  _competent++;
               else if (((_sumVal - 1.75) / 1.13) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.75) / 1.13) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 0.32 ? 'Beginner' : (_postAve >= 0.33 && _postAve <= 2.11 ? 'Basic' : (_postAve >= 2.12 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 0.32 ? 'Beginner' : (_preAve >= 0.33 && _preAve <= 2.11 ? 'Basic' : (_preAve >= 2.12 && _preAve <= 4 ? 'Competent' : ''));
      }

      _geometry = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Geometry (end)

      //-- Patterns and Algebra (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.25) / 0.45) > 1)
            _competent++;
         else if (((_sumVal - 1.25) / 0.45) > -0.51)
            _basic++;
         else if (((_sumVal - 1.25) / 0.45) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s30 + post_data[i].s31 + post_data[i].s32;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.25) / 0.45) > 1)
                  _competent++;
               else if (((_sumVal - 1.25) / 0.45) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.25) / 0.45) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 1.35 ? 'Beginner' : (_postAve >= 1.36 && _postAve <= 2.84 ? 'Basic' : (_postAve >= 2.85 && _postAve <= 3 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 1.35 ? 'Beginner' : (_preAve >= 1.36 && _preAve <= 2.84 ? 'Basic' : (_preAve >= 2.85 && _preAve <= 3 ? 'Competent' : ''));
      }

      _algebra = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Patterns and Algebra (end)

      //-- Measurement (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.06) / 0.85) > 1)
            _competent++;
         else if (((_sumVal - 1.06) / 0.85) > -0.51)
            _basic++;
         else if (((_sumVal - 1.06) / 0.85) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s33 + post_data[i].s34 + post_data[i].s35 + post_data[i].s36 + post_data[i].s37 + post_data[i].s38;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.06) / 0.85) > 1)
                  _competent++;
               else if (((_sumVal - 1.06) / 0.85) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.06) / 0.85) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 0.96 ? 'Beginner' : (_postAve >= 0.97 && _postAve <= 3.62 ? 'Basic' : (_postAve >= 3.63 && _postAve <= 6 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 0.96 ? 'Beginner' : (_preAve >= 0.97 && _preAve <= 3.62 ? 'Basic' : (_preAve >= 3.63 && _preAve <= 6 ? 'Competent' : ''));
      }

      _measurement = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Measurement (end)

      //-- Statistics and Probability (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s39 + pre_data[i].s40;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 1.44) / 0.81) > 1)
            _competent++;
         else if (((_sumVal - 1.44) / 0.81) > -0.51)
            _basic++;
         else if (((_sumVal - 1.44) / 0.81) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s39 + post_data[i].s40;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 1.44) / 0.81) > 1)
                  _competent++;
               else if (((_sumVal - 1.44) / 0.81) > -0.51)
                  _basic++;
               else if (((_sumVal - 1.44) / 0.81) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 0.55 ? 'Beginner' : (_postAve >= 0.56 && _postAve <= 1.44 ? 'Basic' : (_postAve >= 1.45 && _postAve <= 2 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 0.55 ? 'Beginner' : (_preAve >= 0.56 && _preAve <= 1.44 ? 'Basic' : (_preAve >= 1.45 && _preAve <= 2 ? 'Competent' : ''));
      }

      _statistics = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Statistics and Probability (end)

      _total = {
         vn: pre_data.length,
         prem: _numbersense.prem + _geometry.prem + _algebra.prem + _measurement.prem + _statistics.prem,
         postm: _numbersense.postm + _geometry.postm + _algebra.postm + _measurement.postm + _statistics.postm,
         min: _numbersense.min + _geometry.min + _algebra.min + _measurement.min + _statistics.min,
         max: _numbersense.max + _geometry.max + _algebra.max + _measurement.max + _statistics.max,
         sd: _numbersense.sd + _geometry.sd + _algebra.sd + _measurement.sd + _statistics.sd,
         proficiency: _proficiency,
         beginner: _numbersense.beginner + _geometry.beginner + _algebra.beginner + _measurement.beginner + _statistics.beginner,
         basic: _numbersense.basic + _geometry.basic + _algebra.basic + _measurement.basic + _statistics.basic,
         competent: _numbersense.competent + _geometry.competent + _algebra.competent + _measurement.competent + _statistics.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 12.9 ? 'Beginner' : (_total.postm >= 12.91 && _total.postm <= 23.5 ? 'Basic' : (_total.postm >= 23.51 && _total.postm <= 40 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 12.9 ? 'Beginner' : (_total.prem >= 12.91 && _total.prem <= 23.5 ? 'Basic' : (_total.prem >= 23.51 && _total.prem <= 40 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setMathTotal(_total);
      setNumberSense(_numbersense);
      setGeometry(_geometry);
      setPatternsAndAlgebra(_algebra);
      setMeasurement(_measurement);
      setStatisticsAndProbability(_statistics);
   }

   const extractDataForScience = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _matter = {};
      var _livingthings = {};
      var _forceandmotion = {};
      var _earthandspace = {};
      var _proficiency = '';
      var _total = 0;

      //-- Matter (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 + pre_data[i].s10;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 4) / 2.45) > 1)
            _competent++;
         else if (((_sumVal - 4) / 2.45) > -0.51)
            _basic++;
         else if (((_sumVal - 4) / 2.45) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 + post_data[i].s10;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 4) / 2.45) > 1)
                  _competent++;
               else if (((_sumVal - 4) / 2.45) > -0.51)
                  _basic++;
               else if (((_sumVal - 4) / 2.45) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2.77 ? 'Beginner' : (_postAve >= 2.78 && _postAve <= 6.45 ? 'Basic' : (_postAve >= 6.46 && _postAve <= 10 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 2.77 ? 'Beginner' : (_preAve >= 2.78 && _preAve <= 6.45 ? 'Basic' : (_preAve >= 6.46 && _preAve <= 10 ? 'Competent' : ''));
      }

      _matter = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Matter (end)

      //-- Living Things and their Environment (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19 +
            pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24 + pre_data[i].s25 + pre_data[i].s26 +
            pre_data[i].s27 + pre_data[i].s28 + pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 +
            pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40 +
            pre_data[i].s41 + pre_data[i].s42 + pre_data[i].s43;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 16.69) / 2.69) > 1)
            _competent++;
         else if (((_sumVal - 16.69) / 2.69) > -0.51)
            _basic++;
         else if (((_sumVal - 16.69) / 2.69) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19 +
               post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24 + post_data[i].s25 + post_data[i].s26 +
               post_data[i].s27 + post_data[i].s28 + post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 +
               post_data[i].s34 + post_data[i].s35 + post_data[i].s36 + post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40 +
               post_data[i].s41 + post_data[i].s42 + post_data[i].s43;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 16.69) / 2.69) > 1)
                  _competent++;
               else if (((_sumVal - 16.69) / 2.69) > -0.51)
                  _basic++;
               else if (((_sumVal - 16.69) / 2.69) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 15.3 ? 'Beginner' : (_postAve >= 15.31 && _postAve <= 19.4 ? 'Basic' : (_postAve >= 19.41 && _postAve <= 33 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 15.3 ? 'Beginner' : (_preAve >= 15.31 && _preAve <= 19.4 ? 'Basic' : (_preAve >= 19.41 && _preAve <= 33 ? 'Competent' : ''));
      }

      _livingthings = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Living Things and their Environment (end)

      //-- Force and Motion (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s44 + pre_data[i].s45 + pre_data[i].s46 + pre_data[i].s47 + pre_data[i].s48 + pre_data[i].s49 + pre_data[i].s50 + pre_data[i].s51 +
            pre_data[i].s52 + pre_data[i].s53 + pre_data[i].s54 + pre_data[i].s55;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 7.23) / 1.64) > 1)
            _competent++;
         else if (((_sumVal - 7.23) / 1.64) > -0.51)
            _basic++;
         else if (((_sumVal - 7.23) / 1.64) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s44 + post_data[i].s45 + post_data[i].s46 + post_data[i].s47 + post_data[i].s48 + post_data[i].s49 + post_data[i].s50 + post_data[i].s51 +
               post_data[i].s52 + post_data[i].s53 + post_data[i].s54 + post_data[i].s55;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 7.23) / 1.64) > 1)
                  _competent++;
               else if (((_sumVal - 7.23) / 1.64) > -0.51)
                  _basic++;
               else if (((_sumVal - 7.23) / 1.64) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 6.4 ? 'Beginner' : (_postAve >= 6.41 && _postAve <= 8.87 ? 'Basic' : (_postAve >= 8.88 && _postAve <= 12 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 6.4 ? 'Beginner' : (_preAve >= 6.41 && _preAve <= 8.87 ? 'Basic' : (_preAve >= 8.88 && _preAve <= 12 ? 'Competent' : ''));
      }

      _forceandmotion = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Force and Motion (end)

      //-- Earth and Space (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumVal = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s56 + pre_data[i].s57 + pre_data[i].s58 + pre_data[i].s59 + pre_data[i].s60 + pre_data[i].s61 + pre_data[i].s62 + pre_data[i].s63 + pre_data[i].s64 + pre_data[i].s65;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 5) / 1.29) > 1)
            _competent++;
         else if (((_sumVal - 5) / 1.29) > -0.51)
            _basic++;
         else if (((_sumVal - 5) / 1.29) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }
      _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s56 + post_data[i].s57 + post_data[i].s58 + post_data[i].s59 + post_data[i].s60 + post_data[i].s61 + post_data[i].s62 + post_data[i].s63 + post_data[i].s64 + post_data[i].s65;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 5) / 1.29) > 1)
                  _competent++;
               else if (((_sumVal - 5) / 1.29) > -0.51)
                  _basic++;
               else if (((_sumVal - 5) / 1.29) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 4.35 ? 'Beginner' : (_postAve >= 4.36 && _postAve <= 6.29 ? 'Basic' : (_postAve >= 6.3 && _postAve <= 10 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 4.35 ? 'Beginner' : (_preAve >= 4.36 && _preAve <= 6.29 ? 'Basic' : (_preAve >= 6.3 && _preAve <= 10 ? 'Competent' : ''));
      }

      _earthandspace = {
         vn: pre_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Earth and Space (end)

      _total = {
         vn: pre_data.length,
         prem: _matter.prem + _livingthings.prem + _forceandmotion.prem + _earthandspace.prem,
         postm: _matter.postm + _livingthings.postm + _forceandmotion.postm + _earthandspace.postm,
         min: _matter.min + _livingthings.min + _forceandmotion.min + _earthandspace.min,
         max: _matter.max + _livingthings.max + _forceandmotion.max + _earthandspace.max,
         sd: _matter.sd + _livingthings.sd + _forceandmotion.sd + _earthandspace.sd,
         proficiency: _proficiency,
         beginner: _matter.beginner + _livingthings.beginner + _forceandmotion.beginner + _earthandspace.beginner,
         basic: _matter.basic + _livingthings.basic + _forceandmotion.basic + _earthandspace.basic,
         competent: _matter.competent + _livingthings.competent + _forceandmotion.competent + _earthandspace.competent,
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 33.91 ? 'Beginner' : (_total.postm >= 33.92 && _total.postm <= 42.9 ? 'Basic' : (_total.postm >= 42.91 && _total.postm <= 65 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 33.91 ? 'Beginner' : (_total.prem >= 33.92 && _total.prem <= 42.9 ? 'Basic' : (_total.prem >= 42.91 && _total.prem <= 65 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setScienceTotal(_total);
      setMatter(_matter);
      setLivingThings(_livingthings);
      setForceAndMotion(_forceandmotion);
      setEarthAndSpace(_earthandspace);
   }

   const generateMeanLevelRow = (label, data, istotal) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               {
                  istotal
                     ?
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
                     :
                     <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp; {label}</p>
               }

            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.vn}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{(data.prem).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.postm < 0 ? '' : (data.postm).toFixed(2)}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.min}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{data.max}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}><span style={{ fontFamily: 'Arial' }}>{(data.sd).toFixed(2)}</span></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.proficiency}</p>
            </td>
         </tr>
      );
   }

   const generateFrequencyRow = (label, data, index) => {
      return (
         <>
            <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>{index}. Frequency of Proficiency Levels in {label}</strong></p>
            <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
               <tbody>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Count</span></strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent</span></strong></p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Beginner</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.beginner}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'beginner')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Basic&nbsp;</strong></p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.basic}</p>
                     </td>
                     <td style={{ border: '0.75pt solid #bfbfbf', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'basic')}</p>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Competent&nbsp;</strong></p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.competent}</p>
                     </td>
                     <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'competent')}</p>
                     </td>
                  </tr>
               </tbody>
            </table>
         </>
      );
   }

   const generatePercentCorrectRow = (label, predata, postdata, index) => {
      return (
         <tr>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>{index}</strong></p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>{label}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRight: '0.75pt solid #bfbfbf', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{predata !== null ? predata.toFixed(2) + '%' : ''}</p>
            </td>
            <td style={{ borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeft: '0.75pt solid #bfbfbf', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '10pt' }}>{postdata !== null ? postdata.toFixed(2) + '%' : ''}</p>
            </td>
         </tr>
      );
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               (reportData !== null && reportData.length > 0)
                  ?
                  <div className="dashboard-item-inner3" style={{ padding: '7px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='summary-report'>
                        <div>

                           <IntroSum
                              grade_level={props.template}
                              section={props.section_desc}
                              school_name={reportData[0].school_name}
                              subject_id={props.subject_id}
                              subject={props.subject}
                              backgroundColor="#000000"
                              diagnostic_date={reportData[0].diagnostic_date}
                              diagnostic_type={reportData[0].diagnostic_type}
                           />

                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center' }}><strong>SUMMARY OF RESULTS FOR {props.template}</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>MEAN LEVEL OF SCORES IN THE SUBJECT AREAS</strong></p>
                           <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                              <tbody>
                                 <tr>
                                    <td width="28%" nowrap style={{ width: '28.7%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }} />
                                    <td width="5%" nowrap style={{ width: '5.26%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>N</span></b></p>
                                    </td>
                                    <td width="15%" nowrap style={{ width: '15.56%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Pretest Mean</span></b></p>
                                    </td>
                                    <td width="15%" style={{ width: '15.58%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Post Test Mean</span></b></p>
                                    </td>
                                    <td width="6%" nowrap style={{ width: '6.22%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Min</span></b></p>
                                    </td>
                                    <td width="6%" nowrap style={{ width: '6.62%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Max</span></b></p>
                                    </td>
                                    <td width="4%" nowrap style={{ width: '4.92%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>SD</span></b></p>
                                    </td>
                                    <td width="17%" style={{ width: '17.16%', border: 'solid black 1.0pt', borderLeft: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>Proficiency Level</span></b></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Vocabulary', vocabulary, false)}
                                       {generateMeanLevelRow('Reading Comprehension', readingComprehension, false)}
                                       {generateMeanLevelRow('Grammar', grammar, false)}
                                       {generateMeanLevelRow('ENGLISH', englishTotal, true)}
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Number Sense', numberSense, false)}
                                       {generateMeanLevelRow('Geometry', geometry, false)}
                                       {generateMeanLevelRow('Algebra', patternsAndAlgebra, false)}
                                       {generateMeanLevelRow('Measurement', measurement, false)}
                                       {generateMeanLevelRow('Statistics and Probability', statisticsAndProbability, false)}
                                       {generateMeanLevelRow('MATH', mathTotal, true)}
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       {generateMeanLevelRow('Matter', matter, false)}
                                       {generateMeanLevelRow('Living Things and their Environment', livingThings, false)}
                                       {generateMeanLevelRow('Force and Motion', forceAndMotion, false)}
                                       {generateMeanLevelRow('Earth and Space', earthAndSpace, false)}
                                       {generateMeanLevelRow('SCIENCE', scienceTotal, true)}
                                    </>
                                 }
                              </tbody>
                           </table>
                           <p style={{ marginBottom: '0in' }}><span lang="EN-US">&nbsp;</span></p>
                           <p style={{ marginBottom: '0in' }}><span lang="EN-US">&nbsp;</span></p>
                           <p style={{ marginBottom: '0in', textAlign: 'justify' }}><b><span lang="EN-US">INTERPRETATION</span></b></p>
                           <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                              <tbody>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td width="34%" nowrap style={{ width: '34.24%', borderTop: 'solid black 1.0pt', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="18%" nowrap colSpan={3} style={{ width: '18.12%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>BEGINNER</span></b></p>
                                    </td>
                                    <td width="3%" nowrap style={{ width: '3.02%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="21%" nowrap colSpan={3} style={{ width: '21.92%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>BASIC</span></b></p>
                                    </td>
                                    <td width="3%" nowrap style={{ width: '3.02%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                    <td width="19%" nowrap colSpan={3} style={{ width: '19.66%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                       <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>COMPETENT</span></b></p>
                                    </td>
                                 </tr>
                                 {
                                    (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Vocabulary</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.59</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>7.6</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>10.9</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>10.88</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>15</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Reading Comprehension</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>11</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>11</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>16.7</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>16.75</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>23</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Grammar</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>14.2</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>14.21</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>18.9</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>18.95</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>22</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>ENGLISH</span></b></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>26.7</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>26.8</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>37.6</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>37.64</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>60</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="100%" nowrap colSpan={12} style={{ width: '100.0%', border: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Number Sense</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>8.27</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>8.28</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>16.5</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>16.46</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>25</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Geometry</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0.32</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0.33</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.11</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.12</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>4</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Algebra</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>1.35</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>1.36</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.84</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.85</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Measurement</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0.96</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0.97</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.62</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>3.63</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Statistics and Probability</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0.55</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0.56</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>1.44</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>1.45</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>MATH</span></b></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>12.9</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>12.11</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>23.5</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>23.55</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>40</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="100%" nowrap colSpan={12} style={{ width: '100.0%', borderTop: 'none', borderLeft: 'solid black 1.0pt', borderBottom: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                       </tr>
                                    </>
                                 }
                                 {
                                    (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                                    <>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Matter</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.77</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>2.78</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.45</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.46</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>10</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Living Things</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>15.3</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>15.3</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>19.4</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>19.39</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>33</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Force and Motion</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.4</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.41</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>8.87</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>8.88</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>12</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ color: 'black' }}>&nbsp; Earth and Space</span></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>4.35</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>4.36</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.29</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>6.3</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', border: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>10</span></p>
                                          </td>
                                       </tr>
                                       <tr style={{ height: '14.5pt' }}>
                                          <td width="34%" nowrap style={{ width: '34.24%', border: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>SCIENCE</span></b></p>
                                          </td>
                                          <td width="4%" nowrap style={{ width: '4.46%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>0</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>33.91</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>33.92</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="8%" nowrap style={{ width: '8.28%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>42.9</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }} />
                                          <td width="9%" nowrap style={{ width: '9.78%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>42.92</span></p>
                                          </td>
                                          <td width="3%" nowrap style={{ width: '3.88%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>-</span></p>
                                          </td>
                                          <td width="6%" nowrap style={{ width: '6.0%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt', height: '14.5pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span style={{ color: 'black' }}>65</span></p>
                                          </td>
                                       </tr>
                                    </>
                                 }
                              </tbody>
                           </table>

                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp;</span></p>
                                 <p style={{ marginBottom: '0in' }}><span lang="EN-US" style={{ color: 'black' }}>&nbsp;</span></p>
                                 {generateFrequencyRow('Vocabulary', vocabulary, 1)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Reading Comprehension', readingComprehension, 2)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Grammar', grammar, 3)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('ENGLISH', englishTotal, 4)}
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Number Sense', numberSense, 5)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Geometry', geometry, 6)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Patterns and Algebra', patternsAndAlgebra, 7)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Measurement', measurement, 8)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Statistics and Probability', statisticsAndProbability, 9)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('MATH', mathTotal, 10)}
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Matter', matter, 11)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Living Things and their Environment', livingThings, 12)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Force and Motion', forceAndMotion, 13)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('Earth and Space', earthAndSpace, 14)}
                                 <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                                 {generateFrequencyRow('SCIENCE', scienceTotal, 15)}
                              </>
                           }

                           {
                              (props.subject_id === 'english' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><span lang="EN-US">&nbsp;</span></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percent Correct in English</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody><tr>
                                       <td width="70%" colSpan={2} rowSpan={2} style={{ width: '70.02%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                       </td>
                                       <td width="15%" style={{ width: '15.02%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>PRETEST</span></b></p>
                                       </td>
                                       <td width="14%" style={{ width: '14.96%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                          <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>POST TEST</span></b></p>
                                       </td>
                                    </tr>
                                       <tr>
                                          <td width="15%" style={{ width: '15.02%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                          <td width="14%" style={{ width: '14.96%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="65%" style={{ width: '65.42%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Vocabulary
                                                Development</b></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.96%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Use context clues (synonyms) to find meaning of unfamiliar words.', englishPCData.pre_s1, englishPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Use context clues (synonyms) to find meaning of unfamiliar words.', englishPCData.pre_s2, englishPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Use context clues (synonyms) to find meaning of unfamiliar words.', englishPCData.pre_s3, englishPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Use context clues (synonyms) to find meaning of unfamiliar words.', englishPCData.pre_s4, englishPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Use context clues (definition) to determine the meaning of unfamiliar words.', englishPCData.pre_s5, englishPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Identify meanings of unfamiliar words through structural analysis (e.g., compound words and their components: one-word compound [backyard], two-word compound [security guard], hyphenated compound word [sister-in-law]).', englishPCData.pre_s6, englishPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Use prefixes and root words as clues to get the meaning of words.', englishPCData.pre_s7, englishPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Use prefixes and root words as clues to get the meaning of words.', englishPCData.pre_s8, englishPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Use suffixes and root words as clues to get meaning of words.', englishPCData.pre_s9, englishPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Get the meaning of words using a dictionary.', englishPCData.pre_s10, englishPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Get the meaning of words using a dictionary.', englishPCData.pre_s11, englishPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Identify multiple meanings of words.', englishPCData.pre_s12, englishPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Identify multiple meanings of words.', englishPCData.pre_s13, englishPCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Identify different meanings of content specific words. (denotation and connotation).', englishPCData.pre_s14, englishPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Identify different meanings of content specific words. (denotation and connotation).', englishPCData.pre_s15, englishPCData.post_s15, 15)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                                          </td>
                                          <td width="65%" style={{ width: '65.42%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Reading Comprehension</b></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.96%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Note significant details in a literary text.', englishPCData.pre_s16, englishPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Note significant details in a literary text.', englishPCData.pre_s17, englishPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Analyze a story in terms of its setting.', englishPCData.pre_s18, englishPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Sequence events in a story or narrative.', englishPCData.pre_s19, englishPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Infer feelings and traits of characters in a story read.', englishPCData.pre_s20, englishPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Make inferences and draw conclusions based on a literary text.', englishPCData.pre_s21, englishPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Make inferences and draw conclusions based on a literary text.', englishPCData.pre_s22, englishPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Identify the important story elements such as setting, character, and plot.', englishPCData.pre_s23, englishPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Identify the theme of the literary text read.', englishPCData.pre_s24, englishPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Predict outcomes of events in the story.', englishPCData.pre_s25, englishPCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Give possible ending to a story read.', englishPCData.pre_s26, englishPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Distinguish reality from fantasy.', englishPCData.pre_s27, englishPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Distinguish reality from fantasy.', englishPCData.pre_s28, englishPCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Distinguish reality from fantasy.', englishPCData.pre_s29, englishPCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Distinguish reality from fantasy.', englishPCData.pre_s30, englishPCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Distinguish fact from opinion in a narrative.', englishPCData.pre_s31, englishPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Distinguish fact from opinion in a narrative.', englishPCData.pre_s32, englishPCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Distinguish fact from opinion in a narrative.', englishPCData.pre_s33, englishPCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Distinguish fact from opinion in a narrative.', englishPCData.pre_s34, englishPCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Identify various text types according to structure.', englishPCData.pre_s35, englishPCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Identify various text types according to structure.', englishPCData.pre_s36, englishPCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Identify various text types according to structure.', englishPCData.pre_s37, englishPCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Identify various text types according to structure.', englishPCData.pre_s38, englishPCData.post_s38, 38)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                                          </td>
                                          <td width="65%" style={{ width: '65.42%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Grammar</b></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.02%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="14%" style={{ width: '14.96%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Identify and use form of verbs.', englishPCData.pre_s39, englishPCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Identify and use form of verbs.', englishPCData.pre_s40, englishPCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Use the present form of verbs that agree with the subject.', englishPCData.pre_s41, englishPCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Use the present form of verbs that agree with the subject.', englishPCData.pre_s42, englishPCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Use possessive pronouns that agree in gender with antecedents.', englishPCData.pre_s43, englishPCData.post_s43, 43)}
                                       {generatePercentCorrectRow('Use correct time expressions to tell an action in the present.', englishPCData.pre_s44, englishPCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Use the past form of irregular verbs.', englishPCData.pre_s45, englishPCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Use the past form of regular verbs.', englishPCData.pre_s46, englishPCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Identify and use adjectives in sentences.', englishPCData.pre_s47, englishPCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Identify and use words that show degrees of comparison of adjectives in sentences.', englishPCData.pre_s48, englishPCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Identify and use adverbs of place in sentences.', englishPCData.pre_s49, englishPCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Use appropriate adverbs of time in sentences.', englishPCData.pre_s50, englishPCData.post_s50, 50)}
                                       {generatePercentCorrectRow('Use a particular kind of sentence for a specific purpose.', englishPCData.pre_s51, englishPCData.post_s51, 51)}
                                       {generatePercentCorrectRow('Use a particular kind of sentence for a specific purpose.', englishPCData.pre_s52, englishPCData.post_s52, 52)}
                                       {generatePercentCorrectRow('Use prepositions in sentences.', englishPCData.pre_s53, englishPCData.post_s53, 53)}
                                       {generatePercentCorrectRow('Use prepositions in sentences.', englishPCData.pre_s54, englishPCData.post_s54, 54)}
                                       {generatePercentCorrectRow('Use prepositions in sentences.', englishPCData.pre_s55, englishPCData.post_s55, 55)}
                                       {generatePercentCorrectRow('Use simple sentence: Simple subject and simple predicate.', englishPCData.pre_s56, englishPCData.post_s56, 56)}
                                       {generatePercentCorrectRow('Use simple sentence: Simple subject and simple predicate.', englishPCData.pre_s57, englishPCData.post_s57, 57)}
                                       {generatePercentCorrectRow('Use simple sentence: Simple subject and simple predicate.', englishPCData.pre_s58, englishPCData.post_s58, 58)}
                                       {generatePercentCorrectRow('Use simple sentence: Compound subject + simple predicate.', englishPCData.pre_s59, englishPCData.post_s59, 59)}
                                       {generatePercentCorrectRow('Use simple sentence: Simple subject and compound predicate.', englishPCData.pre_s60, englishPCData.post_s60, 60)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'math' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percent Correct in Math</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody>
                                       <tr>
                                          <td width="67%" colSpan={2} rowSpan={2} style={{ width: '67.66%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.44%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>PRETEST</span></b></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.9%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>POST TEST</span></b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="16%" style={{ width: '16.44%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                          <td width="15%" style={{ width: '15.9%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="63%" style={{ width: '63.06%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Number Sense</b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="15%" style={{ width: '15.9%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Visualizes numbers up to 100 000 with emphasis on numbers 10 001 – 100 000.', mathPCData.pre_s1, mathPCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Gives the place value and value of a digit in numbers up to 100 000.', mathPCData.pre_s2, mathPCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Reads and writes numbers up to hundred thousand in symbols and in words.', mathPCData.pre_s3, mathPCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Rounds numbers to the nearest thousand and ten thousand.', mathPCData.pre_s4, mathPCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Compares numbers up to 100 000 using relation symbols.', mathPCData.pre_s5, mathPCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Orders numbers up to 100 000 in increasing or decreasing order.', mathPCData.pre_s6, mathPCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Multiplies numbers up to 3-digit numbers by up to 2-digit numbers without or with regrouping.', mathPCData.pre_s7, mathPCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Estimates the products of 3- to 4-digit numbers by 2- to 3- digit numbers with reasonable results.', mathPCData.pre_s8, mathPCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Divides 3- to 4-digit numbers by 1-to 2-digit numbers without and with remainder.', mathPCData.pre_s9, mathPCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Divides 3- to 4-digit numbers by tens or hundreds or by 1 000 without and with remainder.', mathPCData.pre_s10, mathPCData.post_s10, 10)}
                                       {generatePercentCorrectRow('Performs a series of two or more operations', mathPCData.pre_s11, mathPCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Identifies factors of a given number up to 100. ', mathPCData.pre_s12, mathPCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Identifies the multiples of a given number up to 100.', mathPCData.pre_s13, mathPCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Differentiates prime from composite numbers.', mathPCData.pre_s14, mathPCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Finds the common factors and the greatest common factor (GCF) of two numbers using the following methods: listing, prime factorization, and continuous division.', mathPCData.pre_s15, mathPCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Finds the common multiples and least common multiple (LCM) of two numbers using the following methods: listing, prime factorization, and continuous division.', mathPCData.pre_s16, mathPCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Identifies proper fractions, improper fractions, and mixed numbers.', mathPCData.pre_s17, mathPCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Changes improper fraction to mixed numbers and vice versa.', mathPCData.pre_s18, mathPCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Changes improper fraction to mixed numbers and vice versa.', mathPCData.pre_s19, mathPCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Performs addition and subtraction of similar and dissimilar fractions.', mathPCData.pre_s20, mathPCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Performs addition and subtraction of similar and dissimilar fractions.', mathPCData.pre_s21, mathPCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Performs addition and subtraction of similar and dissimilar fractions.', mathPCData.pre_s22, mathPCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Gives the place value and the value of a digit of a given decimal number through hundredths.', mathPCData.pre_s23, mathPCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Rounds decimal numbers to the nearest whole number and tenth.', mathPCData.pre_s24, mathPCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Rounds decimal numbers to the nearest whole number and tenth.', mathPCData.pre_s25, mathPCData.post_s25, 25)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="63%" style={{ width: '63.06%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Geometry</b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="15%" style={{ width: '15.9%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Describes and illustrates parallel, intersecting, and perpendicular lines.', mathPCData.pre_s26, mathPCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Describes and illustrates different angles (right, acute, and obtuse) using models.', mathPCData.pre_s27, mathPCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Identifies and describes triangles according to sides and angles.', mathPCData.pre_s28, mathPCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Identifies and describes the different kinds of quadrilaterals: square, rectangle, parallelogram, trapezoid, and rhombus.', mathPCData.pre_s29, mathPCData.post_s29, 29)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="63%" style={{ width: '63.06%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Patterns and Algebra</b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="15%" style={{ width: '15.9%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Determines the missing term/s in a sequence of numbers.', mathPCData.pre_s30, mathPCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Determines the missing term/s in a sequence of numbers.', mathPCData.pre_s31, mathPCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Finds the missing number in an equation involving properties of operations.', mathPCData.pre_s32, mathPCData.post_s32, 32)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="63%" style={{ width: '63.06%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Measurement</b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="15%" style={{ width: '15.9%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Finds the elapsed time in minutes and seconds', mathPCData.pre_s33, mathPCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Solves problems involving elapsed time.', mathPCData.pre_s34, mathPCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Finds the perimeter of triangles, squares, rectangles, parallelograms, and trapezoids.', mathPCData.pre_s35, mathPCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Converts sq. cm to sq. m and vice versa.', mathPCData.pre_s36, mathPCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Finds the area of triangles, parallelograms and trapezoids using sq. cm and sq. m.', mathPCData.pre_s37, mathPCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Finds the volume of a rectangular prism using cu. cm and cu. m.', mathPCData.pre_s38, mathPCData.post_s38, 38)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="63%" style={{ width: '63.06%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Statistics and Probability</b></p>
                                          </td>
                                          <td width="16%" style={{ width: '16.44%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="15%" style={{ width: '15.9%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Records favorable outcomes in a simple experiment.', mathPCData.pre_s39, mathPCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Interprets data presented in different kinds of bar graphs (vertical/horizontal, single/double bars).', mathPCData.pre_s40, mathPCData.post_s40, 40)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           {
                              (props.subject_id === 'science' || props.subject_id === null || props.subject_id === 'ems') &&
                              <>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">&nbsp;</span></b></p>
                                 <p style={{ marginBottom: '0in' }}><b><span lang="EN-US">Percent Correct in Science</span></b></p>
                                 <table border={1} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}>
                                    <tbody>
                                       <tr>
                                          <td width="65%" colSpan={2} rowSpan={2} style={{ width: '65.28%', border: 'solid black 1.0pt', borderRight: 'none', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>LEARNING COMPETENCY</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.6%', border: 'none', borderTop: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>PRETEST</span></b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.12%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'none', borderRight: 'solid black 1.0pt', background: 'black', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><span style={{ color: 'white' }}>POST TEST</span></b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="17%" style={{ width: '17.6%', borderTop: 'solid black 1.0pt', borderLeft: 'none', borderBottom: 'solid black 1.0pt', borderRight: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.12%', border: 'solid black 1.0pt', borderLeft: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b>Percent Correct for the Level</b></p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="60%" style={{ width: '60.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Matter</b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="17%" style={{ width: '17.12%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Classify materials based on the ability to absorb water, float, sink, undergo decay.', sciencePCData.pre_s1, sciencePCData.post_s1, 1)}
                                       {generatePercentCorrectRow('Identify the effects of decaying materials on one’s health and safety.', sciencePCData.pre_s2, sciencePCData.post_s2, 2)}
                                       {generatePercentCorrectRow('Identify the effects of decaying materials on one’s health and safety.', sciencePCData.pre_s3, sciencePCData.post_s3, 3)}
                                       {generatePercentCorrectRow('Demonstrate proper disposal of waste according to the properties of its materials.', sciencePCData.pre_s4, sciencePCData.post_s4, 4)}
                                       {generatePercentCorrectRow('Demonstrate proper disposal of waste according to the properties of its materials.', sciencePCData.pre_s5, sciencePCData.post_s5, 5)}
                                       {generatePercentCorrectRow('Describe changes in solid materials when they are bent, pressed, hammered, or cut.', sciencePCData.pre_s6, sciencePCData.post_s6, 6)}
                                       {generatePercentCorrectRow('Describe changes in solid materials when they are bent, pressed, hammered, or cut.', sciencePCData.pre_s7, sciencePCData.post_s7, 7)}
                                       {generatePercentCorrectRow('Describe changes in properties of materials when exposed to certain conditions such as temperature or when mixed with other materials.', sciencePCData.pre_s8, sciencePCData.post_s8, 8)}
                                       {generatePercentCorrectRow('Describe changes in properties of materials when exposed to certain conditions such as temperature or when mixed with other materials.', sciencePCData.pre_s9, sciencePCData.post_s9, 9)}
                                       {generatePercentCorrectRow('Identify changes in materials whether useful or harmful to one’s environment.', sciencePCData.pre_s10, sciencePCData.post_s10, 10)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="60%" style={{ width: '60.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Living Things</b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="17%" style={{ width: '17.12%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Describe the main function of the major organs.', sciencePCData.pre_s11, sciencePCData.post_s11, 11)}
                                       {generatePercentCorrectRow('Describe the main function of the major organs.', sciencePCData.pre_s12, sciencePCData.post_s12, 12)}
                                       {generatePercentCorrectRow('Communicate that the major organs work together to make the body function properly.', sciencePCData.pre_s13, sciencePCData.post_s13, 13)}
                                       {generatePercentCorrectRow('Communicate that the major organs work together to make the body function properly.', sciencePCData.pre_s14, sciencePCData.post_s14, 14)}
                                       {generatePercentCorrectRow('Identify the causes and treatment of diseases of the major organs.', sciencePCData.pre_s15, sciencePCData.post_s15, 15)}
                                       {generatePercentCorrectRow('Identify the causes and treatment of diseases of the major organs.', sciencePCData.pre_s16, sciencePCData.post_s16, 16)}
                                       {generatePercentCorrectRow('Practice habits to maintain a healthy body.', sciencePCData.pre_s17, sciencePCData.post_s17, 17)}
                                       {generatePercentCorrectRow('Practice habits to maintain a healthy body.', sciencePCData.pre_s18, sciencePCData.post_s18, 18)}
                                       {generatePercentCorrectRow('Infer that body structures help animals adapt and survive in their particular habitat.', sciencePCData.pre_s19, sciencePCData.post_s19, 19)}
                                       {generatePercentCorrectRow('Infer that body structures help animals adapt and survive in their particular habitat.', sciencePCData.pre_s20, sciencePCData.post_s20, 20)}
                                       {generatePercentCorrectRow('Compare body movements of animals in their habitat.', sciencePCData.pre_s21, sciencePCData.post_s21, 21)}
                                       {generatePercentCorrectRow('Make a survey of animals found in the community and their specific habitats.', sciencePCData.pre_s22, sciencePCData.post_s22, 22)}
                                       {generatePercentCorrectRow('Make a survey of animals found in the community and their specific habitats.', sciencePCData.pre_s23, sciencePCData.post_s23, 23)}
                                       {generatePercentCorrectRow('Choose which animals to raise in a particular habitat.', sciencePCData.pre_s24, sciencePCData.post_s24, 24)}
                                       {generatePercentCorrectRow('Identify the specialized structures of terrestrial and aquatic plants.', sciencePCData.pre_s25, sciencePCData.post_s25, 25)}
                                       {generatePercentCorrectRow('Identify the specialized structures of terrestrial and aquatic plants.', sciencePCData.pre_s26, sciencePCData.post_s26, 26)}
                                       {generatePercentCorrectRow('Conduct investigation on the specialized structures of plants given varying environmental conditions: light, water, temperature, and soil type.', sciencePCData.pre_s27, sciencePCData.post_s27, 27)}
                                       {generatePercentCorrectRow('Conduct investigation on the specialized structures of plants given varying environmental conditions: light, water, temperature, and soil type.', sciencePCData.pre_s28, sciencePCData.post_s28, 28)}
                                       {generatePercentCorrectRow('Make a survey of plants found in the community and their specific habitats.', sciencePCData.pre_s29, sciencePCData.post_s29, 29)}
                                       {generatePercentCorrectRow('Make a survey of plants found in the community and their specific habitats.', sciencePCData.pre_s30, sciencePCData.post_s30, 30)}
                                       {generatePercentCorrectRow('Choose which plants to grow in a particular habitat.', sciencePCData.pre_s31, sciencePCData.post_s31, 31)}
                                       {generatePercentCorrectRow('Choose which plants to grow in a particular habitat.', sciencePCData.pre_s32, sciencePCData.post_s32, 32)}
                                       {generatePercentCorrectRow('Compare the stages in the life cycle of organisms.', sciencePCData.pre_s33, sciencePCData.post_s33, 33)}
                                       {generatePercentCorrectRow('Compare the stages in the life cycle of organisms.', sciencePCData.pre_s34, sciencePCData.post_s34, 34)}
                                       {generatePercentCorrectRow('Describe the effect of the environment on the life cycle of organisms.', sciencePCData.pre_s35, sciencePCData.post_s35, 35)}
                                       {generatePercentCorrectRow('Describe the effect of the environment on the life cycle of organisms.', sciencePCData.pre_s36, sciencePCData.post_s36, 36)}
                                       {generatePercentCorrectRow('Describe some types of beneficial interactions among living things.', sciencePCData.pre_s37, sciencePCData.post_s37, 37)}
                                       {generatePercentCorrectRow('Describe certain types of harmful interactions among living things.', sciencePCData.pre_s38, sciencePCData.post_s38, 38)}
                                       {generatePercentCorrectRow('Describe certain types of harmful interactions among living things.', sciencePCData.pre_s39, sciencePCData.post_s39, 39)}
                                       {generatePercentCorrectRow('Conduct investigations to determine environmental conditions needed by living things to survive.', sciencePCData.pre_s40, sciencePCData.post_s40, 40)}
                                       {generatePercentCorrectRow('Conduct investigations to determine environmental conditions needed by living things to survive.', sciencePCData.pre_s41, sciencePCData.post_s41, 41)}
                                       {generatePercentCorrectRow('Describe the effects of interactions among organism in their environment.', sciencePCData.pre_s42, sciencePCData.post_s42, 42)}
                                       {generatePercentCorrectRow('Describe the effects of interactions among organism in their environment.', sciencePCData.pre_s43, sciencePCData.post_s43, 43)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="60%" style={{ width: '60.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Force and Motion</b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="17%" style={{ width: '17.12%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Explain the effects of force applied to an object.', sciencePCData.pre_s44, sciencePCData.post_s44, 44)}
                                       {generatePercentCorrectRow('Explain the effects of force applied to an object.', sciencePCData.pre_s45, sciencePCData.post_s45, 45)}
                                       {generatePercentCorrectRow('Practice safety measures in physical activities and proper handling of materials.', sciencePCData.pre_s46, sciencePCData.post_s46, 46)}
                                       {generatePercentCorrectRow('Practice safety measures in physical activities and proper handling of materials.', sciencePCData.pre_s47, sciencePCData.post_s47, 47)}
                                       {generatePercentCorrectRow('Describe the force exerted by magnets.', sciencePCData.pre_s48, sciencePCData.post_s48, 48)}
                                       {generatePercentCorrectRow('Describe the force exerted by magnets.', sciencePCData.pre_s49, sciencePCData.post_s49, 49)}
                                       {generatePercentCorrectRow('Describe how light, sound and heat travel.', sciencePCData.pre_s50, sciencePCData.post_s50, 50)}
                                       {generatePercentCorrectRow('Describe how light, sound and heat travel.', sciencePCData.pre_s51, sciencePCData.post_s51, 51)}
                                       {generatePercentCorrectRow('Investigate properties and characteristics of light and sound.', sciencePCData.pre_s52, sciencePCData.post_s52, 52)}
                                       {generatePercentCorrectRow('Investigate properties and characteristics of light and sound.', sciencePCData.pre_s53, sciencePCData.post_s53, 53)}
                                       {generatePercentCorrectRow('Describe ways to protect oneself from exposure to excessive light, heat and sound.', sciencePCData.pre_s54, sciencePCData.post_s54, 54)}
                                       {generatePercentCorrectRow('Describe ways to protect oneself from exposure to excessive light, heat and sound.', sciencePCData.pre_s55, sciencePCData.post_s55, 55)}

                                       <tr>
                                          <td width="4%" style={{ width: '4.6%', border: 'none', borderLeft: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>&nbsp;</b></p>
                                          </td>
                                          <td width="60%" style={{ width: '60.68%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p style={{ marginBottom: '0in', lineHeight: 'normal' }}><b>Earth and Space</b></p>
                                          </td>
                                          <td width="17%" style={{ width: '17.6%', border: 'none', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                          <td width="17%" style={{ width: '17.12%', border: 'none', borderRight: 'solid black 1.0pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                                             <p align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}>&nbsp;</p>
                                          </td>
                                       </tr>
                                       {generatePercentCorrectRow('Compare and contrast the characteristics of different types of soil.', sciencePCData.pre_s56, sciencePCData.post_s56, 56)}
                                       {generatePercentCorrectRow('Compare and contrast the characteristics of different types of soil.', sciencePCData.pre_s57, sciencePCData.post_s57, 57)}
                                       {generatePercentCorrectRow('Explain the use of water from different sources in the context of daily activities.', sciencePCData.pre_s58, sciencePCData.post_s58, 58)}
                                       {generatePercentCorrectRow('Describe the importance of the water cycle.', sciencePCData.pre_s59, sciencePCData.post_s59, 59)}
                                       {generatePercentCorrectRow('Use weather instruments to measure the different weather components.', sciencePCData.pre_s60, sciencePCData.post_s60, 60)}
                                       {generatePercentCorrectRow('Make simple interpretations about the weather as recorded in the weather chart.', sciencePCData.pre_s61, sciencePCData.post_s61, 61)}
                                       {generatePercentCorrectRow('Identify safety precautions during different weather conditions.', sciencePCData.pre_s62, sciencePCData.post_s62, 62)}
                                       {generatePercentCorrectRow('Describe the changes in the position and length of shadows in the surroundings as the position of the Sun changes.', sciencePCData.pre_s63, sciencePCData.post_s63, 63)}
                                       {generatePercentCorrectRow('Describes the role of the Sun in the water cycle.', sciencePCData.pre_s64, sciencePCData.post_s64, 64)}
                                       {generatePercentCorrectRow('Describes the role of the Sun in the water cycle.', sciencePCData.pre_s65, sciencePCData.post_s65, 65)}
                                    </tbody>
                                 </table>
                              </>
                           }
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>&nbsp;</strong></p>
                        </div>
                     </div>
                  </div >
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }

      </>
   );
}