import React, { useState, useEffect } from 'react'
import { Button, Radio, Typography, DatePicker, Divider, Descriptions, Tree, Select, Col, Row, Empty, Spin } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import StickyBox from "react-sticky-box";
import { Levels } from '../../constants'

const { Text, Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select

export default function DiagnosticAssignTool(props) {
   // const assOptionState = useSelector(state => state.assOptionState)
   // const dispatch = useDispatch();   
   const [updating, setUpdating] = useState(false);
   const [expandedKeys, setExpandedKeys] = useState([]);
   const [checkedKeys, setCheckedKeys] = useState([]);
   const [selectedKeys, setSelectedKeys] = useState([]);
   // const [autoExpandParent, setAutoExpandParent] = useState(true);
   const [treeData, setTreeData] = useState([]);

   const [sectionLoading, setSectionLoading] = useState(false);
   const [section, setSection] = useState(null);
   const [sectionList, setSectionList] = useState([]);
   const [preDiagnosticList, setPreDiagnosticList] = useState([]);
   const [preDiagnostic, setPrediagnostic] = useState(null);

   const dateFormat = 'MMM D, YYYY h:mm a';
   const customFormat = value => `${value.format(dateFormat)}`;

   var ass_options = props.options;

   useEffect(() => {
      // Utils.verifyJwt();

      if (props.diagnostic_type === 'post')
         gePreDiagnosticList();

      if (props.options.level !== undefined && props.options.level !== null && props.options.level !== 0) {
         ass_options.level = props.options.level;
         ass_options.section = '-1';
         setExpandedKeys([]);

         axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/studentlistperleveljson?level=${props.options.level}`).then((response) => {
            if (response.data) {
               const data = response.data;
               setTreeData(data);

               var students = props.options.assigned_students_with_section.split(',');
               setCheckedKeys(students);
            }
         }, (error) => {
            console.log(error.message);
         });

         props.parentCallback(ass_options);
      }

   }, [props.options.id]);

   const gePreDiagnosticList = () => {
      var url = `${process.env.REACT_APP_API_CPH}/getdiagnostics?type=pre`;

      axios.get(url).then((response) => {
         console.log(response);

         var tempList = [];
         if (response.data) {
            const data = response.data;

            data.map((item) => {
               let obj = { 'value': item.id, 'name': item.title }
               tempList.push(obj);
            });

            setPreDiagnosticList(tempList);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const onExpand = (expandedKeysValue) => {
      //console.log('onExpand', expandedKeysValue); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.

      setExpandedKeys(expandedKeysValue);
      // setAutoExpandParent(false);
   };

   const onCheck = (checkedKeysValue) => {
      //console.log('onCheck', checkedKeysValue);
      setCheckedKeys(checkedKeysValue);

      //-- (start) with section ------------------------------
      const selectedStudsWithSection = checkedKeysValue.filter((e) => {
         return e.includes('_');
         //return e.includes('|');
      });

      ass_options.assigned_students_with_section = selectedStudsWithSection.join(',');

      //-- Remove the section part
      for (var i = 0; i < selectedStudsWithSection.length; i++) {
         selectedStudsWithSection[i] = selectedStudsWithSection[i].substring(selectedStudsWithSection[i].indexOf('_') + 1, selectedStudsWithSection[i].length);
         //selectedStudsWithSection[i] = selectedStudsWithSection[i].substring(selectedStudsWithSection[i].indexOf('|') + 1, selectedStudsWithSection[i].length);
      }

      var selected = [...new Set(selectedStudsWithSection)]; //-- Remove duplicate students
      ass_options.assigned_students = selected.join(',');
      //-- (end) with section ------------------------------

      // //-- (start) without section ------------------------------
      // const selectedStuds = checkedKeysValue.filter((e) => {
      //    return e.includes('s_');
      // });

      // ass_options.assigned_students = selectedStuds.join(',');
      // ass_options.assigned_students = ass_options.assigned_students.replace(/s_/g, '');
      // //-- (end) without section ------------------------------

      props.parentCallback(ass_options);
   };

   const onSelect = (selectedKeysValue, info) => {
      //console.log('onSelect', info);
      setSelectedKeys(selectedKeysValue);
   };

   const handleChange = (event) => {
      props.parentCallback("update", event.target.value, props.id);
   }

   const handleClose = (event) => {
      props.backCallback(ass_options);
   }

   const onDateTimeChange = (value, dateString) => {
      ass_options.start_date = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
      // ass_options.end_date = moment(dateString).add(4, 'hours').format('YYYY-MM-DD HH:mm:ss');
      props.parentCallback(ass_options);
   }

   const onDateTimeChange2 = (value, dateString) => {
      ass_options.end_date = moment(dateString).format('YYYY-MM-DD HH:mm:ss');;
      props.parentCallback(ass_options);
   }

   const handleSave = () => {
      ass_options.section = '-1';
      ass_options.start_date = moment(ass_options.start_date).format('YYYY-MM-DD HH:mm:ss');
      ass_options.end_date = moment(ass_options.end_date).format('YYYY-MM-DD HH:mm:ss');
      ass_options.pre_assignment_id = preDiagnostic;

      // setPreDiagnosticList(null);

      localStorage.setItem("_assoptions", JSON.stringify(ass_options));
      // console.log(localStorage.getItem("_assoptions"));
      props.saveCallback(ass_options);
   }

   const handleLevelChange = (val) => {
      ass_options.level = val;
      setSectionLoading(true);

      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/studentlistperleveljson?level=${val}`).then((response) => {
         if (response.data) {
            const data = response.data;
            setSectionLoading(false);

            setTreeData(data);
            setCheckedKeys([]);
            setExpandedKeys([]);
         }
      }, (error) => {
         console.log(error.message);
         setTreeData([]);
         setCheckedKeys([]);
         setExpandedKeys([]);
         sectionLoading(false);
      });

      props.parentCallback(ass_options);
   }

   const handlePreDiagnosticChange = (val) => {
      ass_options.pre_assignment_id = val;
      props.parentCallback(ass_options);
   }

   return (
      <div>
         <Spin spinning={updating} tip={"Updating, please wait..."}>
            <Divider orientation="left">
               <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Title</Title>
            </Divider>
            <>
               <Text strong style={{ fontSize: "15px" }}>{ass_options.title}</Text>
            </>
            <Divider orientation="left">
               <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Set Options</Title>
            </Divider>

            <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", padding: "0px" }} column={4}>
               {/* <Descriptions.Item label={"Schedule (Start Date & Time)"} style={{ textAlign: "center" }} span={4}>
                  <DatePicker
                     style={{ width: "100%" }}
                     showTime={{ format: 'hh:mm A', use12Hours: true }}
                     format={customFormat}
                     onChange={onDateTimeChange}
                     value={ass_options.start_date !== "" ? moment(ass_options.start_date) : ""}
                  />
               </Descriptions.Item> */}
               <Descriptions.Item label={"Schedule (Start Date & Time)"} style={{ textAlign: "center" }} span={4}>
                  <DatePicker
                     style={{ width: "100%" }}
                     showTime={{ format: 'hh:mm A', use12Hours: true }}
                     format={customFormat}
                     onChange={onDateTimeChange}
                     value={ass_options.start_date !== "" ? moment(ass_options.start_date) : ""}
                  />
               </Descriptions.Item>
               <Descriptions.Item label={"Schedule (End Date & Time)"} style={{ textAlign: "center" }} span={4}>
                  <DatePicker
                     style={{ width: "100%" }}
                     showTime={{ format: 'hh:mm A', use12Hours: true }}
                     format={customFormat}
                     onChange={onDateTimeChange2}
                     value={ass_options.end_date !== "" ? moment(ass_options.end_date) : ""}
                  />
               </Descriptions.Item>
               {
                  props.diagnostic_type === 'post' &&
                  <Descriptions.Item label={"Link Pre Diagnostic Test"} style={{ textAlign: "center" }} span={4}>
                     <Select
                        showArrow
                        size='default'
                        style={{ width: '100%', textAlign: "left", maxWidth: '330px' }}
                        allowClear
                        placeholder="Select Pre Diagnostic Test"
                        onChange={(val) => handlePreDiagnosticChange(val)}
                        value={ass_options.pre_assignment_id}
                     >
                        <Option value={''}>None</Option>
                        {preDiagnosticList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>
                  </Descriptions.Item>
               }

            </Descriptions>

            <Divider orientation="left">
               <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Assign Students</Title>
            </Divider>

            <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", padding: "0px" }} column={4}>
               <Descriptions.Item label={"Select Students"} span={4} style={{ textAlign: "center" }} >
                  <Select
                     showArrow
                     size='default'
                     style={{ width: '100%', textAlign: "left" }}
                     allowClear
                     placeholder="Select Level"
                     onChange={(val) => handleLevelChange(val)}
                     value={props.options.level}
                  >
                     {Levels.map(({ value, name }, idx) => {
                        return <Option value={value}>{name}</Option>
                     })}
                  </Select>
                  {/* { props.options.level !== undefined && props.options.level !== null && props.options.level !== 0
                     ?
                        sectionLoading
                        ?
                        <div className='center-empty-space'>
                           <Spin size='large' tip="Loading data, please wait..." />
                        </div>
                        :
                        <Tree
                           checkable
                           // showLine={true}
                           // showIcon={true}
                           onExpand={onExpand}
                           expandedKeys={expandedKeys}
                           autoExpandParent={true}
                           onCheck={onCheck}
                           checkedKeys={checkedKeys}
                           onSelect={onSelect}
                           selectedKeys={selectedKeys}
                           treeData={treeData}
                           style={{ paddingBottom: "15px", paddingTop: "15px" }}
                           defaultExpandParent={true}
                        />
                     :
                     <></>
                  } */}
                  {props.options.level !== undefined && props.options.level !== null && props.options.level !== 0 && treeData.length > 0
                     ?
                     <Tree
                        checkable
                        // showLine={true}
                        // showIcon={true}
                        onExpand={onExpand}
                        expandedKeys={expandedKeys}
                        autoExpandParent={true}
                        onCheck={onCheck}
                        checkedKeys={checkedKeys}
                        onSelect={onSelect}
                        selectedKeys={selectedKeys}
                        treeData={treeData}
                        style={{ paddingBottom: "15px", paddingTop: "15px" }}
                        defaultExpandParent={true}
                     />
                     :
                     // <Empty
                     //    image="../images/brainee_mascot.png"
                     //    description={'No records found'}
                     //    style={{ paddingTop: '5px' }}
                     // />
                     <></>
                  }
               </Descriptions.Item>
            </Descriptions>

            <StickyBox bottom={true} style={{ zIndex: 1000, paddingBottom: "3px" }}>
               <Button className='button-shadow' type='primary' block shape='round' loading={updating} onClick={props.mode === "drawer" ? handleSave : handleClose}>
                  <Text ellipsis style={{ color: "#fff" }}>{props.mode === "drawer" ? <SaveOutlined /> : <ArrowLeftOutlined />}&nbsp;{props.mode === "drawer" ? "Save" : "Back"}</Text>
               </Button>
            </StickyBox>
         </Spin>
      </div>
   )
}