import { LookupModel } from "../models/LookupModel";

const post_lookup = (state, payload) =>
{
  const tmpLookupState = payload;
  
  return tmpLookupState;
  
}







const lookupReducer = (state = LookupModel, action) => {
    switch(action.type){            
        case 'POST-TABLE':
            return post_lookup(state,action.payload)           
        default:
            return state
    }
}

export default lookupReducer;