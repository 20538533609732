export const MasteryAssignmentModel = {
   id: null,
   title: null,
   mastery_assessment_id: null,
   assigned_by: null,
   start_date: null,
   end_date: null,
   term: null,
   allowed_attempts: null,
   passing_percentage: null,
   allow_result_viewing: null,
   enable_timer: null,
   subject: null,
   subject_desc: null,
   competency: null,
   assessment_type: null,
   status: null,
   teacher_intervene: null,
};