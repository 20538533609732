import React, { useState } from 'react'
import { Input, Tooltip } from 'antd';
import { CloseCircleTwoTone } from '@ant-design/icons';

import './ShortAnswer.css';

export default function ShortAnswerItem(props) {

   const [value, setValue] = useState('');

   const handleChangeValue = (value) => {
      setValue(value);
   }

   const handleRemoveOption = (idx) => {
      props.parentCallback("remove", "", idx);
   }

   const handleUpdateInput = () => {
      props.parentCallback("update-input", value, props.id);
   }

   return (
      <div onBlur={handleUpdateInput}>
         <Input
            autoComplete='off'
            allowClear
            id={props.id}
            name={props.id}
            rows={1}
            // value={props.correct}
            defaultValue={props.correct}
            // onBlur={(event) => handleChange(event.target.value)}
            onChange={(event) => handleChangeValue(event.target.value)}
            disabled={props.mode.includes("assign") || props.mode.includes("view") ? true : false}
            // placeholder={props.mode.includes("assign") || props.mode.includes("view") ? 'Borderless' : ''}
            suffix={
               props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view")
                  ?
                  <></>
                  :
                  <Tooltip placement="right" title='Remove Option'>
                     {/* <CloseCircleTwoTone twoToneColor="#afafaf" style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => handleRemoveOption(props.id)} /> */}
                     <CloseCircleTwoTone twoToneColor="red" style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => handleRemoveOption(props.id)} />
                  </Tooltip>
            }
            style={{ borderRadius: "7px", width: "100%", marginBottom: '7px' }}
         />
      </div>
   )
}