import { useContext, useState } from 'react'
// import { CanvasContext } from '../../EditLessonPage'
import { CanvasContext } from '../../hooks/LessonPage'
import SearchServiceGoogleAPI from '../../LessonComponents/SearchServiceGoogleAPI'

// import { getPixabayImages, PixabayImage } from '@services/pixabay'
// import { useDebounce } from 'use-debounce'
// import { useHandlers } from '@/uibox'

function WebSearch() {
  const { actions } = useContext(CanvasContext);

  const setItem = (param) =>
  {
      var data = {type : "WEB", url : param.image, defaultcontent : param.link}
      actions?.addElement(data);
  }

  return (
    <>
      <SearchServiceGoogleAPI type={1} add={setItem}/>
    </>
  )
}
export default WebSearch
