import React,{ useContext, useEffect, useRef, useState } from 'react'
import { Input, Box, Grid, Flex } from 'theme-ui'
import { CanvasContext } from '../../hooks/LessonPage'

import "./ThirdParty.scss"
import { ThirdPartyItems } from './ThirdPartyItems'

function ThirdPartyPanel() {

  const { state, actions } = useContext(CanvasContext);

  const setItem = (image, link) =>
  {
    var data = {type : "WEB", url : image, defaultcontent : link}
    actions?.addElement(data);
  }

  const elementRef = React.useRef(null)

  return (
    <>   
        <Box sx={{ fontWeight: 600, fontSize: '1.54rem', padding: '0.8rem 0' }}>
          <div style={{color : "white" ,textAlign : "center"}}>
          Third Party Website
          </div>
        </Box>
        <Grid id gap={3} columns={[2, null, 3]} style={{margin : "10px"}}>
        {ThirdPartyItems.map(tabItem => (
            <Box >
              <div className="container">
                <div className="icon-container">
                   <img  style={{height : "80px", padding : "5px"}}  src={tabItem.icon} onClick={() => setItem(tabItem.icon, tabItem.url) }/>
                </div>
                <div style={{paddingTop : "5px", textAlign : "center"}}>
                  <h6>{tabItem.name}</h6>
                </div>
              </div>              
            </Box>            
        ))}
        </Grid>   
                   
          {/* </Flex>    
      </Grid> */}
    </>
  )
}
export default ThirdPartyPanel
