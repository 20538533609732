import { MasterySearchKeysModel } from "../models/MasterySearchKeysModel";

var empty_mastery_search_keys = {
   term: null,
   level: null,
   subject: null,
}

const set_mastery_search_keys = (state, payload) =>
{
  const tmpState = payload;
  
  return tmpState;
}

const masterySearchKeysReducer = (state = MasterySearchKeysModel, action) => {
   switch(action.type){     
      case 'SET-MASTERY-SEARCH-KEYS':
         return set_mastery_search_keys(state,action.payload);
      case 'RESET-MASTERY-SEARCH-KEYS':
         return empty_mastery_search_keys;
      default:
         return state
   }
}

export default masterySearchKeysReducer;