/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Select, Input, Row, Col, Modal, Segmented, DatePicker, Breadcrumb, Spin
} from 'antd';
import { SearchOutlined, SaveOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import { SchoolTerms } from '../../constants';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import moment from 'moment';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import uuid from 'react-uuid';

const { Option } = Select;
const { TextArea } = Input;

export function ClassroomAttendance() {
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [sectionLoading, setSectionLoading] = useState(false);
   const [term, setTerm] = useState(null);
   const [level, setLevel] = useState(null);
   const [section, setSection] = useState(null);
   const [sectionList, setSectionList] = useState([]);
   const [listLoading, setListLoading] = useState(false);
   const [levelList, setLevelList] = useState([]);
   const [studentList, setStudentList] = useState([]);
   const [attendanceList, setAttendanceList] = useState(null);

   // const [status, setStatus] = useState(null);
   const [remarks, setRemarks] = useState(null);
   const [processing, setProcessing] = useState(false);
   const [attendanceDate, setAttendanceDate] = useState(moment());
   const [canEdit, setCanEdit] = useState(true);

   const subdomain = localStorage.getItem("lms-subdomain");
   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const dateFormat = 'MMM D, YYYY';
   const customFormat = value => `${value.format(dateFormat)}`;

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      if (user_role !== 'Student')
         getLevels();
   }, []);

   const getLevels = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/levellist`).then((response) => {
         if (response.data) {
            const data = response.data;
            let levels = [];

            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               levels.push(obj);
            });

            setLevelList(levels);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const AttendanceListColumns = [
      {
         title: '',
         dataIndex: 'attendance_id',
         key: 'attendance_id',
         hidden: true,
      },
      {
         title: 'Last Name',
         key: 'student_lastname',
         dataIndex: 'student_lastname',
         sorter: (a, b) => { return a.student_lastname.localeCompare(b.student_lastname) },
         ...getColumnSearchProps('student_lastname'),
         ellipsis: {
            showTitle: false,
         },
         render: student_firstname => (
            <Tooltip placement="right" title={student_firstname}>
               <span style={{ cursor: "default" }}>
                  {student_firstname !== null ? student_firstname.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : ''}
               </span>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'First Name',
         key: 'student_firstname',
         dataIndex: 'student_firstname',
         sorter: (a, b) => { return a.student_firstname.localeCompare(b.student_firstname) },
         ...getColumnSearchProps('student_firstname'),
         ellipsis: {
            showTitle: false,
         },
         render: student_lastname => (
            <Tooltip placement="right" title={student_lastname}>
               <span style={{ cursor: "default" }}>
                  {student_lastname !== null ? student_lastname.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : ''}
               </span>
            </Tooltip>
         ),
         width: '18%'
      },
      // {
      //    title: 'Term',
      //    key: 'term_desc',
      //    dataIndex: 'term_desc',
      //    width: '5%'
      // },
      // {
      //    title: 'Level',
      //    key: 'level_desc',
      //    dataIndex: 'level_desc',
      //    width: '8%'
      // },
      // {
      //    title: 'Section',
      //    key: 'section_desc',
      //    dataIndex: 'section_desc',
      //    width: '10%'
      // },
      {
         title: 'Teacher',
         key: 'teacher_name',
         dataIndex: 'teacher_name',
         width: '17%'
      },
      {
         title: 'Status',
         key: 'status',
         render: object => (
            <Segmented
               disabled={object.teacher_id !== null ? (object.teacher_id === user_id ? false : true) : false}
               key={object.student_id}
               id={object.student_id}
               defaultValue={object.status === null ? 'present' : object.status}
               // block
               options={[
                  {
                     label: 'Present',
                     value: 'present',
                  },
                  {
                     label: 'Absent',
                     value: 'absent',
                  },
                  {
                     label: 'Late',
                     value: 'late',
                  },
                  {
                     label: 'Half-Day',
                     value: 'half-day',
                  },
               ]}
               onChange={(val) => handleUpdateAttendance(object.student_id, "status", val)}
            />
         ),
         width: '20%'
      },
      {
         title: 'Remarks',
         key: 'remarks',
         render: object => (
            <div onBlur={() => handleUpdateAttendance(object.student_id, "remarks", remarks)}>
               <TextArea
                  disabled={object.teacher_id !== null ? (object.teacher_id === user_id ? false : true) : false}
                  key={object.student_id}
                  allowClear={true}
                  id={object.student_id}
                  rows={1}
                  autoSize={{
                     minRows: 1,
                     maxRows: 3,
                  }}
                  onChange={(event) => setRemarks(event.target.value)}
                  defaultValue={object.remarks}
                  style={{ borderRadius: '7px' }}
               />
            </div>
         ),
         width: '35%'
      },
   ].filter(item => !item.hidden);

   const handleTermChange = (val) => {
      setStudentList([]);
      setTerm(val);
   }

   const handleLevelChange = (val) => {
      setSection(null);
      setSectionList([]);
      setStudentList([]);
      setLevel(val);
      setSectionLoading(true);

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${val}`).then((response) => {
         if (response.data) {
            const data = response.data;

            setSectionList(data);
            setSectionLoading(false);
         }
      }, (error) => {
         setSectionLoading(false);
         console.log(error.message);
      });
   }

   const handleSectionChange = (val) => {
      setStudentList([]);
      setSection(val);
   }

   const handleDateChange = (val) => {
      setStudentList([]);
      setAttendanceDate(val);
   }

   const handleSearchAttendance = (t, l, s, d) => {
      var url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/getclassroomattendance?t=${t}&l=${l}&s=${s}&d=${d}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            //Initialize data for saving
            var attendanceDataList = [];
            var isEditable = true;

            data.forEach((element, idx) => {
               var attendanceData = {
                  attendance_id: "",
                  student_id: "",
                  term: "",
                  teacher_id: "",
                  status: "",
                  remarks: "",
                  attendance_date: ""
               };

               attendanceData.attendance_id = element.attendance_id ?? "attendance_" + uuid();
               attendanceData.student_id = element.student_id;
               attendanceData.term = term;
               attendanceData.teacher_id = element.teacher_id ?? user_id;
               attendanceData.status = element.status ?? "present";
               attendanceData.remarks = element.remarks ?? ""
               attendanceData.attendance_date = element.attendance_date ?? moment(attendanceDate).format("YYYY-MM-DD");

               attendanceDataList.push(attendanceData);
               isEditable = element.teacher_id !== null ? (element.teacher_id === user_id ? true : false) : true;
            });

            setCanEdit(isEditable);
            setStudentList(data);
            setAttendanceList(attendanceDataList);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleUpdateAttendance = (id, el, value) => {
      var tmpList = [...attendanceList];

      tmpList.forEach((element, idx) => {
         if (element.student_id === id) {
            if (el === "status")
               tmpList[idx].status = value;
            if (el === "remarks")
               tmpList[idx].remarks = value;

            setAttendanceList(tmpList);
         }
      });
   }

   const handleSaveAttendance = () => {
      setProcessing(true);
      var url = `${process.env.REACT_APP_API_SCHOOL_MANAGEMENT}/upsertclassroomattendance`;
      axios.post(url, attendanceList).then(response => {
         if (response.data) {
            setProcessing(false);
            Modal.success({
               title: 'Classroom Attendance',
               content: 'Attendance successfuly saved.',
               centered: true,
            });
         }
      }, (error) => {
         console.log(error.message);
         setProcessing(false);
         Modal.error({
            title: 'Classroom Attendance',
            content: 'Attendance save failed.',
            centered: true
         });
      });
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <Spin size='large' spinning={processing} tip="Processing request...Please wait.">
               <div className="dashboard-item">
                  <div className="dashboard-item-inner">
                     <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                        <Breadcrumb.Item>
                           <Link to="/home">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Attendance</Breadcrumb.Item>
                        <Breadcrumb.Item>Classroom</Breadcrumb.Item>
                     </Breadcrumb>
                  </div>
               </div>

               {
                  user_role !== 'Student' &&
                  <Row gutter={[10, 10]} style={{ paddingBottom: "20px" }} >
                     <Col sm={24} md={4} lg={4}>
                        <Select
                           name='term'
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select Term"
                           onChange={(val) => handleTermChange(val)}
                        >
                           {SchoolTerms.map(({ value, name }, idx) => {
                              return <Option value={value}>{name}</Option>
                           })}
                        </Select>
                     </Col>
                     <Col sm={24} md={6} lg={6}>
                        <Select
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select Level"
                           onChange={(val) => handleLevelChange(val)}
                           value={level}
                        >
                           {levelList.map(({ value, name }, idx) => {
                              return <Option value={value}>{name}</Option>
                           })}
                        </Select>
                     </Col>
                     <Col sm={24} md={6} lg={6}>
                        <Select
                           loading={sectionLoading}
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select Section"
                           value={section}
                           onChange={(val) => handleSectionChange(val)}
                        >
                           {sectionList.map(({ value, name }, idx) => {
                              return <Option value={value}>{name}</Option>
                           })}
                        </Select>
                     </Col>
                     <Col sm={24} md={5} lg={5}>
                        <DatePicker
                           placeholder="Select Date"
                           format={customFormat}
                           style={{ width: "100%" }}
                           // onBlur={(event) => setAttendanceDate(event.target.value)}
                           onChange={(val) => handleDateChange(val)}
                           defaultValue={moment()}
                           disabledDate={(current) => current.isAfter(moment())}
                        />
                     </Col>
                     <Col sm={24} md={3} lg={3}>
                        <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleSearchAttendance(term, level, section, moment(attendanceDate).format("YYYY-MM-DD"))} icon={<SearchOutlined />}>Search</Button>
                     </Col>
                  </Row>
               }

               {
                  (studentList.length > 0 && canEdit) &&
                  <Row>
                     <Col><Button className='button-shadow' type='primary' shape='round' block onClick={() => handleSaveAttendance()} icon={<SaveOutlined />}>Save Attendance</Button></Col>
                  </Row>
               }


               <Row gutter={12} style={{ overflow: "auto" }} >
                  <Col span={24}>
                     <div className="dashboard-item-inner3">
                        <Table
                           rowKey={'uid'}
                           loading={listLoading}
                           size='small'
                           columns={AttendanceListColumns}
                           dataSource={studentList}
                           pagination={false}
                           scroll={{
                              x: '1000px',
                              y: 'calc(100svh - 14rem)',
                           }}
                           footer={() => 'Total Records: ' + studentList.length}
                        />
                     </div>
                  </Col>
               </Row>
            </Spin>
         </div>
         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}
      </>
   );
}
