/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Input, Tabs, Table, Typography, Space, Tooltip, Button, Popconfirm, Modal, Breadcrumb, Descriptions, Empty, Row, Col, Image } from 'antd';
import * as AntDIcons from '@ant-design/icons';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
// import NoticeBoardList from '../../components/notice-board/NoticeBoardList';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
// import platform from 'platform';
// import uuid from 'react-uuid';
// import { at } from 'lodash';
// import { format } from 'date-fns';

// import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import Split from 'react-split';
import Iframe from 'react-iframe';

import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import html2pdf from 'html2pdf.js';

const { TabPane } = Tabs;
const { Text, Paragraph } = Typography;

export function StudentDiagnosticAssessment() {
   const navigate = useNavigate();
   const location = useLocation();

   const search = window.location.search;
   const query = new URLSearchParams(search);
   const diagnosticType = query.get('type');

   const aTab = location.state !== null ? location.state.activetab : "1";

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   const [currentList, setCurrentList] = useState([]);
   const [pastList, setPastList] = useState([]);
   const [activeTab, setActiveTab] = useState("1");
   const [listLoading, setListLoading] = useState(true);
   const [showFullScreen, setShowFullScreen] = useState(false);
   // const [fileURL, setFileURL] = useState(null);
   // const [title, setTitle] = useState(null);
   // const [submitted, setSubmitted] = useState(false);
   const [message, setMessage] = useState(null);


   const [assOptions, setAssOptions] = useState({
      student: null,
      title: null,
      assignor: null,
      file_url: null,
      password: null,
      instruction_url: null,
   });

   const [showReport, setShowReport] = useState(false);
   const [subject, setSubject] = useState(null);
   const [studentID, setStudentID] = useState(null);
   const [assignId, setAssignId] = useState(null);
   const [template, setTemplate] = useState(null);
   const [downloadStatus, setDownloadStatus] = useState(false);

   const otherdata = JSON.parse(Utils.getCurrentUserInfo(Enums.UserInfo.otherdata));
   const username = otherdata.Username;
   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);
   // const screen1 = useFullScreenHandle();

   useEffect(() => {
      Utils.verifyJwt();
      // const unloadCallback = (event) => {
      // //   event.preventDefault();
      // //   event.returnValue = "";
      // // localStorage.clear();
      //   return "";
      // };

      // window.addEventListener("beforeunload", unloadCallback);
      // return () => window.removeEventListener("beforeunload", unloadCallback);

      // if (document.addEventListener) { //code for Moz
      //    document.addEventListener("keydown", keyCapt, false);
      //    document.addEventListener("keyup", keyCapt, false);
      //    document.addEventListener("keypress", keyCapt, false);
      // } else {
      //    document.attachEvent("onkeydown", keyCapt); //code for IE
      //    document.attachEvent("onkeyup", keyCapt);
      //    document.attachEvent("onkeypress", keyCapt);
      // }

      setActiveTab(() => {
         refreshList(aTab);
         return aTab
      });
   }, [diagnosticType]);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
               </Button>
               <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const current_assigned_ass_columns = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: '',
         key: 'password',
         dataIndex: 'password',
         hidden: true,
      },
      {
         title: '',
         key: 'instruction_file_url',
         dataIndex: 'instruction_file_url',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.title}>
               <b><Text>{object.title}</Text></b>
               <p style={{ marginBottom: '0px' }}>{object.start_date + ' - ' + object.end_date}</p>
            </Tooltip>
         ),
         width: '55%'
      },
      {
         title: 'Assessment Id',
         key: 'assessment_id',
         dataIndex: 'assessment_id',
         hidden: true,
      },
      {
         title: 'Subject',
         key: 'subject',
         ...getColumnSearchProps('subject'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="top" title={object.subject}>
               <Text>{object.subject !== null ? object.subject.toUpperCase() : ''}</Text>
            </Tooltip>
         ),
         width: '15%',
      },
      {
         title: 'Assigned By',
         key: 'assigned_by_name',
         ...getColumnSearchProps('assigned_by_name'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="top" title={object.assigned_by_name}>
               <Text>{object.assigned_by_name}</Text>
            </Tooltip>
         ),
         width: '20%'
      },
      // {
      //    title: 'Status',
      //    key: 'status',
      //    render: object => (
      //       <Text>{object.status === null ? (object.status === 0 ? 'Started' : 'Completed') : 'Not started'}</Text>
      //    ),
      //    width: '10%'
      // },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Answer Assessment'>
                     <Popconfirm
                        placement='left'
                        title="Do you want to answer this assessment now?"
                        onConfirm={() => handleShowAssessment(true, payload.id, payload.assessment_id, payload.file_url, payload.title, payload.assigned_by_name, payload.password, payload.instruction_file_url)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <AntDIcons.FormOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const past_assigned_ass_columns = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.title}>
               <b><Text>{object.title}</Text></b>
               <p style={{ marginBottom: '0px' }}>{object.start_date + ' - ' + object.end_date}</p>
            </Tooltip>
         ),
         width: '55%'
      },
      {
         title: 'Assessment Id',
         key: 'assessment_id',
         dataIndex: 'assessment_id',
         hidden: true,
      },
      {
         title: 'Subject',
         key: 'subject',
         ...getColumnSearchProps('subject'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="top" title={object.subject}>
               <Text>{object.subject !== null ? object.subject.toUpperCase() : ''}</Text>
            </Tooltip>
         ),
         width: '15%',
      },
      {
         title: 'Assigned By',
         key: 'assigned_by_name',
         ...getColumnSearchProps('assigned_by_name'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="top" title={object.assigned_by_name}>
               <Text>{object.assigned_by_name}</Text>
            </Tooltip>
         ),
         width: '20%',
      },
      {
         title: <div style={{ textAlign: 'center' }}>Report</div>,
         render: payload => (
            payload.report_id !== null &&
            <>
               <div style={{ textAlign: 'center' }}>
                  {
                     payload.subject !== null
                        ?
                        <Tooltip placement='left' title={'Show Report on ' + payload.subject.toUpperCase()}>
                           <AntDIcons.SolutionOutlined style={{ cursor: 'pointer' }} onClick={() => handleShowReport(user_id, payload.id, payload.subject, payload.level_desc)} />
                        </Tooltip>
                        :
                        <Space size={'small'}>
                           <Tooltip placement='left' title={'Show Report on ENGLISH'}>
                              <AntDIcons.SolutionOutlined style={{ cursor: 'pointer' }} onClick={() => handleShowReport(user_id, payload.id, 'english', payload.level_desc)} />
                           </Tooltip>
                           <Tooltip placement='left' title={'Show Report on MATH'}>
                              <AntDIcons.SolutionOutlined style={{ cursor: 'pointer' }} onClick={() => handleShowReport(user_id, payload.id, 'math', payload.level_desc)} />
                           </Tooltip>
                           <Tooltip placement='left' title={'Show Report on SCIENCE'}>
                              <AntDIcons.SolutionOutlined style={{ cursor: 'pointer' }} onClick={() => handleShowReport(user_id, payload.id, 'science', payload.level_desc)} />
                           </Tooltip>
                        </Space>
                  }
               </div>
            </>

         ),
         width: '10%'
      },
      // {
      //    title: <div style={{ textAlign: 'center' }}>English</div>,
      //    render: payload => (
      //       payload.report_id !== null &&
      //       <>
      //          <div style={{ textAlign: 'center' }}>
      //             <Tooltip placement='left' title='Show Report on English'>
      //                <AntDIcons.SolutionOutlined style={{ cursor: 'pointer' }} />
      //             </Tooltip>
      //          </div>
      //       </>

      //    ),
      //    width: '10%'
      // },
      // {
      //    title: <div style={{ textAlign: 'center' }}>Math</div>,
      //    render: payload => (
      //       payload.report_id !== null &&
      //       <>
      //          <div style={{ textAlign: 'center' }}>
      //             <Tooltip placement='left' title='Show Report on Math'>
      //                <AntDIcons.SolutionOutlined style={{ cursor: 'pointer' }} />
      //             </Tooltip>
      //          </div>
      //       </>
      //    ),
      //    width: '10%'
      // },
      // {
      //    title: <div style={{ textAlign: 'center' }}>Science</div>,
      //    render: payload => (
      //       payload.report_id !== null &&
      //       <>
      //          <div style={{ textAlign: 'center' }}>
      //             <Tooltip placement='left' title='Show Report on Science'>
      //                <AntDIcons.SolutionOutlined style={{ cursor: 'pointer' }} />
      //             </Tooltip>
      //          </div>
      //       </>
      //    ),
      //    width: '10%'
      // },
   ].filter(item => !item.hidden);

   const getCurrentList = () => {
      setListLoading(true);
      axios.get(`${process.env.REACT_APP_API_CPH}/getstudentdiagnosticassessments?student_id=${user_id}&tense=1&type=${diagnosticType}`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setCurrentList(data);
            setListLoading(false);
         }
      }, (error) => {
         setListLoading(false);
         console.log(error.message);
      });
   }

   const getPastList = () => {
      setListLoading(true);
      axios.get(`${process.env.REACT_APP_API_CPH}/getstudentdiagnosticassessments?student_id=${user_id}&tense=3&type=${diagnosticType}`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setPastList(data);
            setListLoading(false);
         }
      }, (error) => {
         setListLoading(false);
         console.log(error.message);
      });
   }

   const handleTabChange = (key) => {
      switch (key) {
         case "1":
            getCurrentList();
            setActiveTab("1");
            break;
         case "2":
            getPastList();
            setActiveTab("2");
            break;
      }
   }

   const refreshList = (tab) => {
      switch (tab) {
         case "1":
            getCurrentList();
            break;
         case "2":
            getPastList();
            break;
      }
   }

   // const handleAnswerAssessment = (id, assessment_id, q_type) => {
   //    var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";

   // }

   function GetCurrentBreadcrumbLabel(type) {
      var retVal = "";

      switch (type) {
         case "pre":
            retVal = "Pre Diagnostic Test";
            break;
         case "post":
            retVal = "Post Diagnostic Test";
            break;
         default:
            break;
      }

      return retVal;
   }

   // const reportChange = useCallback((state, handle) => {
   //    if (handle === screen1) {
   //       console.log('Screen 1 went to', state, handle);
   //    }
   //    if (handle === screen2) {
   //       console.log('Screen 2 went to', state, handle);
   //    }
   // }, [screen1, screen2]);

   const handleShowAssessment = (show, id, assessment_id, file_url, title, assigned_by, password, instruction_url) => {
      // setTitle(title);
      // setFileURL(file_url);
      setShowFullScreen(show);

      setAssOptions({
         student: name,
         title: title,
         assignor: assigned_by,
         file_url: file_url,
         password: password,
         instruction_url: instruction_url,
      })

      // if (show)
      //    screen1.enter();
      // else
      //    screen1.exit();
   }

   // const handleExit = () => {
   //    Modal.confirm({
   //       title: 'Brainee Diagnostic Assessment',
   //       icon: <AntDIcons.ExclamationCircleFilled />,
   //       content: 'Have you completed your answers?',
   //       centered: true,
   //       okButtonProps: { shape: "round" },
   //       cancelButtonProps: { shape: "round" },
   //       okText: 'Yes',
   //       cancelText: 'No',
   //       zIndex: 100000,
   //       onOk() {
   //          handleShowAssessment(false);
   //       },
   //       onCancel() {
   //          console.log('Cancel');
   //       }
   //    });

   //    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
   //       ///fire your event
   //       // handleShowAssessment(false);
   //    }

   //    // if (document.webkitIsFullScreen)
   //    //    handleShowAssessment(false);
   // }

   const handleShowFullscreen = () => {
      let element = document.getElementById('msdoc-iframe');
      element.requestFullscreen();
   }

   const handleCancelShow = () => {
      setShowFullScreen(false);
   }

   const copyText = (text) => {
      navigator.clipboard.writeText(text);
      const key = 'copytoclipboard';
      message.info({ content: 'Copied to clipboard!', key });
   };

   const handleDownloadReport = (report_type, subject) => {
      var element = null;
      var reportFileName = "";

      if (report_type === 'individual') {
         element = document.getElementById('individual-report');
         reportFileName = 'Results in ' + subject.toUpperCase() + '.pdf';
      }

      if (element !== null) {
         var opt = {
            margin: 0.5,
            filename: reportFileName,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
         };

         // New Promise-based usage:
         html2pdf().from(element).set(opt).save();
      }
   }

   const handleShowReport = (student_id, assign_id, subject, template) => {
      setSubject(subject);
      setStudentID(student_id);
      setAssignId(assign_id);
      setTemplate(template);
      setShowReport(true);
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li className="active">
                        {GetCurrentBreadcrumbLabel(diagnosticType)}
                     </li>
                  </ul>
                  {/* <Breadcrumb style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <AntDIcons.HomeOutlined />
                        <a href="#" onClick={() => navigate('/home')}>Home</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        Summative Assessment
                     </Breadcrumb.Item>
                  </Breadcrumb> */}
               </div>

               <div className="dashboard-item-inner3">
                  <Tabs defaultActiveKey={aTab} centered onChange={(key) => handleTabChange(key)}>
                     <TabPane style={{ overflow: "auto" }}
                        tab={(
                           <Tooltip title="Current assigned assessments">
                              <span>Current</span>
                           </Tooltip>
                        )}
                        key="1"
                     >
                        <Table
                           rowKey={'uid'}
                           loading={listLoading}
                           size='small'
                           columns={current_assigned_ass_columns}
                           dataSource={currentList}
                           pagination={false}
                           scroll={{
                              x: '1000px',
                              y: 'calc(100svh - 11.5rem)',
                           }}
                           footer={() => 'Records: ' + currentList.length}
                        />
                     </TabPane>
                     <TabPane style={{ overflow: "auto" }}
                        tab={(
                           <Tooltip title="Past assigned assessments">
                              <span>Past</span>
                           </Tooltip>
                        )}
                        key="2"
                     >
                        <Table
                           rowKey={'uid'}
                           loading={listLoading}
                           size='small'
                           columns={past_assigned_ass_columns}
                           dataSource={pastList}
                           pagination={false}
                           scroll={{
                              x: '1000px',
                              y: 'calc(100svh - 11.5rem)',
                           }}
                           footer={() => 'Records: ' + pastList.length}
                        />
                     </TabPane>
                  </Tabs>


               </div>
            </div>
         </div>

         {/* <div className="notice-area" style={{ display: "block" }}>
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}


         {/* <FullScreen handle={screen1} onChange={reportChange}>
            {showFullScreen
               ?
               <>
                  <div className="dashboard-area-full">
                     <Split
                        sizes={[75, 25]}
                        direction="horizontal"
                        cursor="col-resize"
                        className="split-flex"
                        style={{ height: `calc(100vh - 2rem)` }}
                     >
                        <div style={{ width: `75%` }}>
                           <Iframe
                              id="msdoc-iframe"
                              title="msdoc-iframe"
                              src={fileURL}
                              frameBorder="0"
                              height='100%'
                              width='100%'
                           />

                        </div>
                        <div style={{ width: `35%` }}>
                           <div style={{ display: 'block', textAlign: 'center' }}>
                              <Space direction='vertical' style={{ width: '100%' }}>
                                 <img src="../images/brainee_mascot.png" width="100%" style={{ padding: "5px", maxWidth: '120px' }}></img>
                                 <h5>Brainee Diagnostic Assessment</h5>
                              </Space>
                           </div>

                           <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", marginTop: '15px', width: "100%" }} column={4}>
                              <Descriptions.Item label={"Student Name"} span={4} style={{ textAlign: "center" }}>
                                 <Paragraph style={{ textAlign: "left", width: "100%" }}>{assOptions.student}</Paragraph>
                              </Descriptions.Item>
                              <Descriptions.Item label={"Assessment Title"} span={4} style={{ textAlign: "center" }}>
                                 <Paragraph style={{ textAlign: "left", width: "100%" }}>{assOptions.title}</Paragraph>
                              </Descriptions.Item>
                              <Descriptions.Item label={"Assigned By"} span={4} style={{ textAlign: "center" }}>
                                 <Paragraph style={{ textAlign: "left", width: "100%" }}>{assOptions.assignor}</Paragraph>
                              </Descriptions.Item>
                           </Descriptions>

                           <Button className='button-shadow' type='primary' block shape='round' style={{ marginBottom: "15px" }} onClick={() => handleExit()}>
                              <Text ellipsis style={{ color: "#fff" }}><AntDIcons.CheckCircleOutlined />&nbsp;Done</Text>
                           </Button>
                        </div>
                     </Split>
                  </div>
               </>
               :
               <></>
            }
         </FullScreen> */}

         <Modal
            title={"Brainee Diagnostic Assessment"}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            // okButtonProps={{ shape: "round" }}
            okButtonProps={{ shape: "round", style: { display: 'none' } }}
            centered
            destroyOnClose={true}
            open={showFullScreen}
            width='100%'
            closable={false}
            maskClosable={false}
            // onOk={() => { setShowFullScreen(false); }}
            footer={[
               <Button key="fullscreen" type="primary" shape='round' onClick={handleShowFullscreen}>
                  Full Screen
               </Button>,
               <Button key="close" type="primary" shape='round' onClick={handleCancelShow}>
                  Close
               </Button>
            ]}
         >
            <div style={{ background: '#fff' }}>
               <Row>
                  <Col xs={24} md={6} lg={6}>
                     <div className='student_list_container' style={{ paddingRight: "10px", paddingLeft: "5px" }}>
                        <div style={{ display: 'block', textAlign: 'center' }}>
                           <Space direction='vertical' style={{ width: '100%' }}>
                              <img src="../images/brainee_mascot.png" width="100%" style={{ padding: "5px", maxWidth: '120px' }}></img>
                              <h5>Brainee Diagnostic Assessment</h5>
                           </Space>
                        </div>

                        <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", marginTop: '15px', width: "100%" }} column={4}>
                           <Descriptions.Item label={"Student Name"} span={4} style={{ textAlign: "center" }}>
                              <Paragraph style={{ textAlign: "center", width: "100%" }}>{assOptions.student}</Paragraph>
                           </Descriptions.Item>
                           <Descriptions.Item label={"Assessment Title"} span={4} style={{ textAlign: "center" }}>
                              <Paragraph style={{ textAlign: "center", width: "100%" }}>{assOptions.title}</Paragraph>
                           </Descriptions.Item>
                           <Descriptions.Item label={"Assigned By"} span={4} style={{ textAlign: "center" }}>
                              <Paragraph style={{ textAlign: "center", width: "100%" }}>{assOptions.assignor}</Paragraph>
                           </Descriptions.Item>
                           <Descriptions.Item
                              label={
                                 <>
                                    My User Name
                                    <Tooltip placement="top" title='Copy my user name'>
                                       <AntDIcons.CopyOutlined onClick={() => copyText(username)} />
                                    </Tooltip>
                                 </>
                              }
                              span={4}
                              style={{ textAlign: "center" }}
                           >
                              <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{username}</b></Paragraph>
                           </Descriptions.Item>
                        </Descriptions>

                        {/* <Button className='button-shadow' type='primary' block shape='round' style={{ marginBottom: "15px" }} onClick={() => handleExit()}>
                           <Text ellipsis style={{ color: "#fff" }}><AntDIcons.CheckCircleOutlined />&nbsp;Done</Text>
                        </Button> */}
                     </div>
                  </Col>
                  <Col xs={24} md={18} lg={18}>
                     <div className='essay_list_container'>
                        <Iframe
                           id="msdoc-iframe"
                           title="msdoc-iframe"
                           src={assOptions.file_url}
                           frameBorder="0"
                           height='100%'
                           width='100%'
                           styles={{ height: "calc(100vh - 191px)" }}
                        />
                     </div>
                  </Col>
               </Row>
            </div>
         </Modal>

         <Modal
            key='show-diagnostic-report'
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> {"Results in " + (subject !== null ? subject.toUpperCase() : '')}</div>}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round", display: 'none' }}
            centered
            destroyOnClose={true}
            open={showReport}
            width='75%'
            style={{ maxWidth: '900px' }}
            // onCancel={() => { setShowReport(false); }}
            // onOk={() => { setShowReport(false); }}
            footer={[
               <Space>
                  <Button
                     shape='round' type="primary"
                     loading={downloadStatus}
                     onClick={() => handleDownloadReport('individual')}
                  >
                     Dowload
                  </Button>
                  <Button shape='round' type='primary' onClick={() => { setShowReport(false); }}>
                     Close
                  </Button>
               </Space>
            ]}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               <div className='section_to_print'>
                  {Utils.displayDiagnosticReport(template, assignId, subject, studentID)}
               </div>
            </div>
         </Modal>
      </>
   );
}