/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import {
   Row, Col, Empty, Spin
} from 'antd';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import ProgressBar from "@ramonak/react-progress-bar";
import { IntroSum } from '../components';

export function MTG4EnglishSummaryReport(props) {
   const [reportData, setReportData] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   const [literary, setLiterary] = useState(null);
   const [information, setInformation] = useState(null);
   const [vocabulary, setVocabulary] = useState(null);
   const [viewing, setViewing] = useState(null);
   const [total, setTotal] = useState(null);

   const [pcData, setPCData] = useState(null);
   // const [VN, setVN] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();

      ChartJS.register(
         CategoryScale,
         LinearScale,
         BarElement,
         Title,
         Tooltip,
         Legend
      );

      getReport(props.assignment_id, props.section_id);
   }, [props.assignment_id]);

   const getReport = (assignment_id, section_id) => {
      setDataLoading(true);

      var url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticreportdata?aid=${assignment_id}&sid=${section_id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            var preData = data.filter(item => item.diagnostic_type === 'pre');
            var postData = data.filter(item => item.diagnostic_type === 'post');
            // setVN(preData.length);

            url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticsummarypercentdata?aid=${assignment_id}&sid=${section_id}`;
            axios.get(url).then((response) => {

               if (response.data) {
                  const data2 = response.data;

                  setPCData(data2[0]);
                  extractData(preData, postData);

                  setReportData(data);
                  setDataLoading(false);
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const extractData = (pre_data, post_data) => {
      var _tmpPreTotal = 0;
      var _tmpPostTotal = 0;
      var _min = 0;
      var _max = 0;
      var _sumVal = 0;
      var _sumValArr = [];
      var _preAve = 0;
      var _postAve = -1;
      var _beginner = 0;
      var _basic = 0;
      var _competent = 0;

      var _literary = {};
      var _information = {};
      var _vocabulary = {};
      var _viewing = {};

      var _total = {};
      var _proficiency = '';

      //-- Literary (start)
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s1 + pre_data[i].s2 + pre_data[i].s3 + pre_data[i].s4 + pre_data[i].s5 + pre_data[i].s6 + pre_data[i].s7 + pre_data[i].s8 + pre_data[i].s9 +
            pre_data[i].s10 + pre_data[i].s11 + pre_data[i].s12 + pre_data[i].s13 + pre_data[i].s14 + pre_data[i].s15 + pre_data[i].s16 + pre_data[i].s17 + pre_data[i].s18 + pre_data[i].s19;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 8.69) / 2.18) > 1)
            _competent++;
         else if (((_sumVal - 8.69) / 2.18) > -0.51)
            _basic++;
         else if (((_sumVal - 8.69) / 2.18) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s1 + post_data[i].s2 + post_data[i].s3 + post_data[i].s4 + post_data[i].s5 + post_data[i].s6 + post_data[i].s7 + post_data[i].s8 + post_data[i].s9 +
               post_data[i].s10 + post_data[i].s11 + post_data[i].s12 + post_data[i].s13 + post_data[i].s14 + post_data[i].s15 + post_data[i].s16 + post_data[i].s17 + post_data[i].s18 + post_data[i].s19;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 8.69) / 2.18) > 1)
                  _competent++;
               else if (((_sumVal - 8.69) / 2.18) > -0.51)
                  _basic++;
               else if (((_sumVal - 8.69) / 2.18) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 7.59 ? 'Beginner' : (_postAve >= 7.60 && _postAve <= 15.23 ? 'Basic' : (_postAve >= 15.24 && _postAve <= 19 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 7.59 ? 'Beginner' : (_preAve >= 7.60 && _preAve <= 15.23 ? 'Basic' : (_preAve >= 15.24 && _preAve <= 19 ? 'Competent' : ''));
      }

      _literary = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Literary (end)

      //-- Information (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;
      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s20 + pre_data[i].s21 + pre_data[i].s22 + pre_data[i].s23 + pre_data[i].s24;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 2.67) / 1.06) > 1)
            _competent++;
         else if (((_sumVal - 2.67) / 1.06) > -0.51)
            _basic++;
         else if (((_sumVal - 2.67) / 1.06) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {

         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s20 + post_data[i].s21 + post_data[i].s22 + post_data[i].s23 + post_data[i].s24;
            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 2.67) / 1.06) > 1)
                  _competent++;
               else if (((_sumVal - 2.67) / 1.06) > -0.51)
                  _basic++;
               else if (((_sumVal - 2.67) / 1.06) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2.13 ? 'Beginner' : (_postAve >= 2.14 && _postAve <= 4.26 ? 'Basic' : (_postAve >= 4.27 && _postAve <= 5 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 2.13 ? 'Beginner' : (_preAve >= 2.14 && _preAve <= 4.26 ? 'Basic' : (_preAve >= 4.27 && _preAve <= 5 ? 'Competent' : ''));
      }

      _information = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Information (end)      

      //-- Vocabulary (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s25 + pre_data[i].s26 + pre_data[i].s27 + pre_data[i].s28;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 5.4) / 0.88) > 1)
            _competent++;
         else if (((_sumVal - 5.4) / 0.88) > -0.51)
            _basic++;
         else if (((_sumVal - 5.4) / 0.88) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s25 + post_data[i].s26 + post_data[i].s27 + post_data[i].s28;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 5.4) / 0.88) > 1)
                  _competent++;
               else if (((_sumVal - 5.4) / 0.88) > -0.51)
                  _basic++;
               else if (((_sumVal - 5.4) / 0.88) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 2.48 ? 'Beginner' : (_postAve >= 2.49 && _postAve <= 3.72 ? 'Basic' : (_postAve >= 3.73 && _postAve <= 4 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 2.48 ? 'Beginner' : (_preAve >= 2.49 && _preAve <= 3.72 ? 'Basic' : (_preAve >= 3.73 && _preAve <= 4 ? 'Competent' : ''));
      }

      _vocabulary = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Vocabulary (end)

      //-- Viewing (start)
      _tmpPreTotal = 0;
      _tmpPostTotal = 0;
      _min = 0;
      _max = 0;
      _sumValArr = [];
      _preAve = 0;
      _postAve = -1;
      _proficiency = '';
      _beginner = 0;
      _basic = 0;
      _competent = 0;

      for (var i = 0; i < pre_data.length; i++) {
         _sumVal = pre_data[i].s29 + pre_data[i].s30 + pre_data[i].s31 + pre_data[i].s32 + pre_data[i].s33 + pre_data[i].s34 + pre_data[i].s35 + pre_data[i].s36 + pre_data[i].s37 + pre_data[i].s38 + pre_data[i].s39 + pre_data[i].s40;

         _min = _min < _sumVal ? _min : _sumVal;
         _max = _max > _sumVal ? _max : _sumVal;

         if (((_sumVal - 5.4) / 0.88) > 1)
            _competent++;
         else if (((_sumVal - 5.4) / 0.88) > -0.51)
            _basic++;
         else if (((_sumVal - 5.4) / 0.88) > -10)
            _beginner++;

         _tmpPreTotal += _sumVal;
         _sumValArr.push(_sumVal);
      }

      if (_tmpPreTotal > 0)
         _preAve = parseFloat((_tmpPreTotal / pre_data.length).toFixed(2));

      if (post_data.length > 0) {
         if (props.diagnostic_type === 'post') {
            _sumValArr = [];
            _beginner = 0;
            _basic = 0;
            _competent = 0;
         }

         for (var i = 0; i < post_data.length; i++) {
            _sumVal = post_data[i].s29 + post_data[i].s30 + post_data[i].s31 + post_data[i].s32 + post_data[i].s33 + post_data[i].s34 + post_data[i].s35 + post_data[i].s36 + post_data[i].s37 + post_data[i].s38 + post_data[i].s39 + post_data[i].s40;

            if (props.diagnostic_type === 'post') {
               _min = _min < _sumVal ? _min : _sumVal;
               _max = _max > _sumVal ? _max : _sumVal;

               if (((_sumVal - 5.4) / 0.88) > 1)
                  _competent++;
               else if (((_sumVal - 5.4) / 0.88) > -0.51)
                  _basic++;
               else if (((_sumVal - 5.4) / 0.88) > -10)
                  _beginner++;

               _sumValArr.push(_sumVal);
            }

            _tmpPostTotal += _sumVal;
         }
         _postAve = parseFloat((_tmpPreTotal / post_data.length).toFixed(2));
         _proficiency = _postAve >= 0 && _postAve <= 4.95 ? 'Beginner' : (_postAve >= 4.96 && _postAve <= 8.04 ? 'Basic' : (_postAve >= 8.05 && _postAve <= 12 ? 'Competent' : ''));
      } else {
         _proficiency = _preAve >= 0 && _preAve <= 4.95 ? 'Beginner' : (_preAve >= 4.96 && _preAve <= 8.04 ? 'Basic' : (_preAve >= 8.05 && _preAve <= 12 ? 'Competent' : ''));
      }

      _viewing = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _preAve,
         postm: _postAve,
         min: _min,
         max: _max,
         sd: Utils.standardDeviation(_sumValArr),
         proficiency: _proficiency,
         beginner: _beginner,
         basic: _basic,
         competent: _competent,
      };
      //-- Viewing (end)

      _total = {
         vn: props.diagnostic_type === 'pre' ? pre_data.length : post_data.length,
         prem: _literary.prem + _information.prem + _vocabulary.prem + _viewing.prem,
         postm: _literary.postm + _information.postm + _vocabulary.postm + _viewing.postm,
         min: _literary.min + _information.min + _vocabulary.min + _viewing.min,
         max: _literary.max + _information.max + _vocabulary.max + _viewing.max,
         sd: _literary.sd + _information.sd + _vocabulary.sd + _viewing.sd,
         proficiency: _proficiency,
         beginner: _literary.beginner + _information.beginner + _vocabulary.beginner + _viewing.beginner,
         basic: _literary.basic + _information.basic + _vocabulary.basic + _viewing.basic,
         competent: _literary.competent + _information.competent + _vocabulary.competent + _viewing.competent
      };

      if (post_data.length > 0) {
         _proficiency = _total.postm >= 0 && _total.postm <= 24.97 ? 'Beginner' : (_total.postm >= 24.98 && _total.postm <= 31.3 ? 'Basic' : (_total.postm >= 31.31 && _total.postm <= 40 ? 'Competent' : ''));
      } else {
         _proficiency = _total.prem >= 0 && _total.prem <= 24.97 ? 'Beginner' : (_total.prem >= 24.98 && _total.prem <= 31.3 ? 'Basic' : (_total.prem >= 31.31 && _total.prem <= 40 ? 'Competent' : ''));
      }

      _total.proficiency = _proficiency;

      setTotal(_total);
      setLiterary(_literary);
      setInformation(_information);
      setVocabulary(_vocabulary);
      setViewing(_viewing);
   }

   const generateMeanLevelRow = (label, data, istotal) => {
      return (
         <tr>
            <td style={{ width: '20.56%', borderStyle: 'solid', borderWidth: '0.75pt', borderBottom: '1pt solid #cccccc', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
            </td>
            <td style={{ width: '4.78%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.vn}</p>
            </td>
            <td style={{ width: '11.4%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.prem <= 0 || data.prem === null ? '' : (data.prem).toFixed(2)}</p>
            </td>
            <td style={{ width: '11.68%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.postm <= 0 || data.postm === null ? '' : (data.postm).toFixed(2)}</p>
            </td>
            <td style={{ width: '10.8%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.min}</p>
            </td>
            <td style={{ width: '11.16%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.max}</p>
            </td>
            <td style={{ width: '14.52%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{(data.sd).toFixed(2)}</p>
            </td>
            <td style={{ width: '15.1%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.proficiency}</p>
            </td>
         </tr>
      );
   }

   const generateFrequencyRow = (label, data, index) => {
      return (
         <>
            <Row><Col span={24} style={{ textAlign: "left" }}><p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>{index}. {label !== "TOTAL" ? "Frequency of Proficiency Levels on " : ""} {label}</strong></p></Col></Row>
            <Row>
               <Col span={12}>
                  <table cellSpacing={0} cellPadding={0} style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Count</span></strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent</span></strong></p>
                        </td>
                     </tr>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Beginner</strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.beginner}</p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'beginner') === '100.00' ? '100' : (Utils.getPercentage(data, 'beginner') === '0.00' ? '0' : Utils.getPercentage(data, 'beginner'))}</p>
                        </td>
                     </tr>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Basic&nbsp;</strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.basic}</p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'basic') === '100.00' ? '100' : (Utils.getPercentage(data, 'basic') === '0.00' ? '0' : Utils.getPercentage(data, 'basic'))}</p>
                        </td>
                     </tr>
                     <tr>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong>Competent&nbsp;</strong></p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{data.competent}</p>
                        </td>
                        <td style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{Utils.getPercentage(data, 'competent') === '100.00' ? '100' : (Utils.getPercentage(data, 'competent') === '0.00' ? '0' : Utils.getPercentage(data, 'competent'))}</p>
                        </td>
                     </tr>
                  </table>
               </Col>
               <Col span={12}>
                  <div style={{ marginLeft: "20px", marginRight: "20px", height: "200px" }}>
                     <Bar
                        options={{
                           responsive: true,
                           scales: {
                              y:
                              {
                                 min: 0,
                                 max: 100,
                                 stepSize: 1,
                              },
                              x:
                                 {},
                           }
                        }}

                        data={{
                           labels: ['Beginner', 'Basic', 'Competent'],
                           datasets: [
                              {
                                 label: label,
                                 backgroundColor: "#4F81BD",
                                 borderColor: "#4F81BD",
                                 data: [Utils.getPercentage(data, 'beginner'), Utils.getPercentage(data, 'basic'), Utils.getPercentage(data, 'competent')],
                              },
                           ],
                        }}
                     />
                  </div>
               </Col>
            </Row>
         </>
      );
   }

   const generatePercentCorrectRow = (label, predata, postdata, index) => {
      return (
         <tr>
            <td style={{ width: '11.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
                  <strong>{index}</strong>
               </p>
            </td>
            <td style={{ width: '330.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
                  {label}
               </p>
            </td>
            <td style={{ width: '43.35pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {/* {predata !== null ? predata.toFixed(2) + '%' : ''} */}
                  {/* {predata !== null ? (predata === 100 ? predata : (predata === 0 ? '0' : predata.toFixed(2))) : ''} */}
                  {
                     (predata !== null && predata !== 0) &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        // labelColor={pre_percent <= 40 ? "#000000" : "#FFFFFF"}
                        labelColor={"#FFFFFF"}
                        // labelAlignment={pre_percent <= 40 ? "outside" : "center"}
                        labelAlignment={"center"}
                        customLabel={predata !== 100 ? predata.toFixed(1) + "%" : predata + "%"}
                        completed={predata}
                        labelSize="12px"
                        animateOnRender
                     />
                  }
               </p>
            </td>
            <td style={{ width: '75.35pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {/* {postdata !== null ? (postdata === 100 ? postdata : (postdata === 0 ? '0' : postdata.toFixed(2))) : ''} */}
                  {
                     (postdata !== null && postdata !== 0) &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        // labelColor={pre_percent <= 40 ? "#000000" : "#FFFFFF"}
                        labelColor={"#FFFFFF"}
                        // labelAlignment={pre_percent <= 40 ? "outside" : "center"}
                        labelAlignment={"center"}
                        customLabel={postdata !== 100 ? postdata.toFixed(1) + "%" : postdata + "%"}
                        completed={postdata}
                        labelSize="12px"
                        animateOnRender
                     />
                  }
               </p>
            </td>
         </tr>
      );
   }

   const generateContentStandards = (label, beg1, beg2, bas1, bas2, comp1, comp2) => {
      return (
         <tr style={{ height: '14.5pt' }}>
            <td style={{ width: '39.3%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: '115%', fontSize: '12pt' }}><strong>{label}</strong></p>
            </td>
            <td style={{ width: '4.54%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{beg1}</p>
            </td>
            <td style={{ width: '3.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>-</p>
            </td>
            <td style={{ width: '9.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{beg2}</p>
            </td>
            <td style={{ width: '9.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{bas1}</p>
            </td>
            <td style={{ width: '3.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>-</p>
            </td>
            <td style={{ width: '9.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{bas2}</p>
            </td>
            <td style={{ width: '8.42%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{comp1}</p>
            </td>
            <td style={{ width: '3.94%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>-</p>
            </td>
            <td style={{ width: '6.1%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ textAlign: 'center', marginBottom: '0pt' }}>{comp2}</p>
            </td>
         </tr>
      );
   }

   const renderLabel = (label) => {
      return (
         <>
            <tr>
               <td colSpan={4} style={{ width: '11.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                  <p style={{ margin: '0pt 5.65pt', textAlign: 'left', fontSize: '11pt' }}>
                     <strong>{label}</strong>
                  </p>
               </td>
            </tr>
         </>
      );
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               (reportData !== null && reportData.length > 0)
                  ?
                  <div className="dashboard-item-inner3" style={{ margin: '20px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='summary-report'>
                        <div>

                           <IntroSum
                              grade_level={props.template}
                              section={props.section_desc}
                              school_name={reportData[0].school_name}
                              subject_id={props.subject_id}
                              subject={props.subject}
                              backgroundColor="#4F81BD"
                              diagnostic_date={reportData[0].diagnostic_date}
                              diagnostic_type={reportData[0].diagnostic_type}
                           />

                           <p style={{ marginTop: '10pt', marginBottom: '0pt', lineHeight: '115%', fontSize: '12pt' }}>
                              <strong>&nbsp;</strong>
                           </p>
                           <div style={{ pageBreakBefore: 'always' }}></div>
                           <p style={{ textAlign: 'center', fontSize: '14pt' }}><strong>Summary of Results for {props.subject}</strong></p>
                           <p style={{ textAlign: 'center' }}><strong>PRE TEST</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt' }}><strong>MEAN LEVEL OF SCORES IN THE SUBJECT AREAS</strong></p>
                           <table style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr>
                                    <td style={{ textAlign: 'center', width: '25%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Area</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '4%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>N</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '10%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Pretest Mean</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '10%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Post Test Mean</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '10%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Min</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '11%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Max</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '14%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Standard Deviation</span></strong>
                                    </td>
                                    <td style={{ textAlign: 'center', width: '13%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <strong><span style={{ color: '#ffffff' }}>Proficiency Level</span></strong>
                                    </td>
                                 </tr>
                                 {generateMeanLevelRow('Comprehending Literary text', literary, false)}
                                 {generateMeanLevelRow('Comprehending Information', information, false)}
                                 {generateMeanLevelRow('Vocabulary', vocabulary, false)}
                                 {generateMeanLevelRow('Viewing and representing', viewing, false)}
                                 {generateMeanLevelRow('TOTAL', total, true)}
                              </tbody>
                           </table>
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                           <table style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr style={{ height: '14.5pt' }}>
                                    <td style={{ width: '39.3%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Content Standards</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Beginner</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Basic</span></strong></p>
                                    </td>
                                    <td colSpan={3} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center', marginBottom: '0pt' }}><strong><span style={{ color: '#ffffff' }}>Competent</span></strong></p>
                                    </td>
                                 </tr>
                                 {generateContentStandards('Comprehending Literary text', 0, 7.59, 7.6, 15.23, 15.24, 19)}
                                 {generateContentStandards('Comprehending Information', 0, 2.13, 2.14, 4.26, 4.27, 5)}
                                 {generateContentStandards('Vocabulary', 0, 2.48, 2.49, 3.72, 3.73, 4)}
                                 {generateContentStandards('Viewing and representing', 0, 4.95, 4.96, 8.04, 8.05, 12)}
                                 {generateContentStandards('TOTAL', 0, 24.97, 24.98, 31.29, 31.3, 40)}
                              </tbody>
                           </table>
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                           {generateFrequencyRow('Comprehending Literary text', literary, 1)}
                           {generateFrequencyRow('Comprehending Information', information, 2)}
                           {generateFrequencyRow('Vocabulary', vocabulary, 3)}
                           {generateFrequencyRow('Viewing and representing', viewing, 4)}
                           {generateFrequencyRow('TOTAL', total, 6)}
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                           <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '12pt' }}><strong>Percent of Correct</strong></p>
                           <table style={{ width: '100%', border: '0.75pt solid #cccccc', borderCollapse: 'collapse' }}>
                              <tbody>
                                 <tr>
                                    <td colSpan={2} style={{ borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center' }}><strong><span style={{ color: '#ffffff' }}>LEARNING COMPETENCY</span></strong></p>
                                    </td>
                                    <td style={{ width: '19.9%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center' }}><strong><span style={{ color: '#ffffff' }}>Pre Test Percent Correct for the Level</span></strong></p>
                                    </td>
                                    <td style={{ width: '20.6%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ textAlign: 'center' }}><strong><span style={{ color: '#ffffff' }}>Post Test Percent Correct for the Level</span></strong></p>
                                    </td>
                                 </tr>

                                 {renderLabel("Part 1: Comprehending Literary text")}
                                 {generatePercentCorrectRow("Noting important elements (story grammar)", pcData.pre_s1, pcData.post_s1, 1)}
                                 {generatePercentCorrectRow("Noting important elements (story grammar)", pcData.pre_s2, pcData.post_s2, 2)}
                                 {generatePercentCorrectRow("Noting important elements (story grammar)", pcData.pre_s3, pcData.post_s3, 3)}
                                 {generatePercentCorrectRow("Noting important elements (story grammar)", pcData.pre_s4, pcData.post_s4, 4)}
                                 {generatePercentCorrectRow("Noting important elements (story grammar)", pcData.pre_s5, pcData.post_s5, 5)}
                                 {generatePercentCorrectRow("Identifying author’s/speaker’s point of view from literary text", pcData.pre_s6, pcData.post_s6, 6)}
                                 {generatePercentCorrectRow("Identifying author’s/speaker’s point of view from literary text", pcData.pre_s7, pcData.post_s7, 7)}
                                 {generatePercentCorrectRow("Identifying author’s/speaker’s point of view from literary text", pcData.pre_s8, pcData.post_s8, 8)}
                                 {generatePercentCorrectRow("Making predictions: outcomes of events", pcData.pre_s9, pcData.post_s9, 9)}
                                 {generatePercentCorrectRow("Making predictions: outcomes of events", pcData.pre_s10, pcData.post_s10, 10)}
                                 {generatePercentCorrectRow("Making predictions: outcomes of events", pcData.pre_s11, pcData.post_s11, 11)}
                                 {generatePercentCorrectRow("Making predictions: outcomes of events", pcData.pre_s12, pcData.post_s12, 12)}
                                 {generatePercentCorrectRow("Drawing conclusions from literary texts.", pcData.pre_s13, pcData.post_s13, 13)}
                                 {generatePercentCorrectRow("Drawing conclusions from literary texts.", pcData.pre_s14, pcData.post_s14, 14)}
                                 {generatePercentCorrectRow("Drawing conclusions from literary texts.", pcData.pre_s15, pcData.post_s15, 15)}
                                 {generatePercentCorrectRow("Drawing conclusions from literary texts.", pcData.pre_s16, pcData.post_s16, 16)}
                                 {generatePercentCorrectRow("Identifying the main idea from the literary text.", pcData.pre_s17, pcData.post_s17, 17)}
                                 {generatePercentCorrectRow("Identifying the main idea from the literary text.", pcData.pre_s18, pcData.post_s18, 18)}
                                 {generatePercentCorrectRow("Identifying the main idea from the literary text.", pcData.pre_s19, pcData.post_s19, 19)}
                                 {renderLabel("Part 2: Comprehending Information")}
                                 {generatePercentCorrectRow("Identifying the text type through informational texts: (a.) Enumeration, (B.) time order: chronology, (C.) time order: procedural, (D.) recount.", pcData.pre_s20, pcData.post_s20, 20)}
                                 {generatePercentCorrectRow("Identifying the text type through informational texts: (a.) Enumeration, (B.) time order: chronology, (C.) time order: procedural, (D.) recount.", pcData.pre_s21, pcData.post_s21, 21)}
                                 {generatePercentCorrectRow("Identifying the text type through informational texts: (a.) Enumeration, (B.) time order: chronology, (C.) time order: procedural, (D.) recount.", pcData.pre_s22, pcData.post_s22, 22)}
                                 {generatePercentCorrectRow("Identifying the text type through informational texts: (a.) Enumeration, (B.) time order: chronology, (C.) time order: procedural, (D.) recount.", pcData.pre_s23, pcData.post_s23, 23)}
                                 {generatePercentCorrectRow("Identifying the author's purpose through informational texts.", pcData.pre_s24, pcData.post_s24, 24)}
                                 {renderLabel("Part 3: Vocabulary")}
                                 {generatePercentCorrectRow("Using context clues with literal (denotative) and implied (connotative) meanings in sentences: (a.) Synonyms, (b.) Antonyms, (c.) Definition, (d.) exemplification", pcData.pre_s25, pcData.post_s25, 25)}
                                 {generatePercentCorrectRow("Using context clues with literal (denotative) and implied (connotative) meanings in sentences: (a.) Synonyms, (b.) Antonyms, (c.) Definition, (d.) exemplification", pcData.pre_s26, pcData.post_s26, 26)}
                                 {generatePercentCorrectRow("Using context clues with literal (denotative) and implied (connotative) meanings in sentences: (a.) Synonyms, (b.) Antonyms, (c.) Definition, (d.) exemplification", pcData.pre_s27, pcData.post_s27, 27)}
                                 {generatePercentCorrectRow("Using context clues with literal (denotative) and implied (connotative) meanings in sentences: (a.) Synonyms, (b.) Antonyms, (c.) Definition, (d.) exemplification", pcData.pre_s28, pcData.post_s28, 28)}
                                 {renderLabel("Part 4: Viewing and representing")}
                                 {generatePercentCorrectRow("Identify real or make believe, fact or non-fact images.", pcData.pre_s29, pcData.post_s29, 29)}
                                 {generatePercentCorrectRow("Identify real or make believe, fact or non-fact images.", pcData.pre_s30, pcData.post_s30, 30)}
                                 {generatePercentCorrectRow("Identify multimedia elements: text and graphics", pcData.pre_s31, pcData.post_s31, 31)}
                                 {generatePercentCorrectRow("Identify multimedia elements: text and graphics", pcData.pre_s32, pcData.post_s32, 32)}
                                 {generatePercentCorrectRow("Identify multimedia elements: text and graphics", pcData.pre_s33, pcData.post_s33, 33)}
                                 {generatePercentCorrectRow("Identify multimedia elements: text and graphics", pcData.pre_s34, pcData.post_s34, 34)}
                                 {generatePercentCorrectRow("Identify multimedia elements: text and graphics", pcData.pre_s35, pcData.post_s35, 35)}
                                 {generatePercentCorrectRow("Identify multimedia elements: text and graphics", pcData.pre_s36, pcData.post_s36, 36)}
                                 {generatePercentCorrectRow("Identify multimedia elements: text and graphics", pcData.pre_s37, pcData.post_s37, 37)}
                                 {generatePercentCorrectRow("Identify multimedia elements: text and graphics", pcData.pre_s38, pcData.post_s38, 38)}
                                 {generatePercentCorrectRow("Identify multimedia elements: text and graphics", pcData.pre_s39, pcData.post_s39, 39)}
                                 {generatePercentCorrectRow("Identify multimedia elements: text and graphics", pcData.pre_s40, pcData.post_s40, 40)}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div >
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }

      </>
   );
}