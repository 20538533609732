import axios from 'axios';

const deleteNotice = (uuid) =>
{
    let api =`${process.env.REACT_APP_LMS_API}api/NoticeBoard/deleteNotice?/id={uuid}`
    const headers = { };  
    return axios.post(api, {headers: headers})   
}

const saveNotice = (notice) =>
{
    let api =`${process.env.REACT_APP_LMS_API}api/NoticeBoard/saveNotice`
    const headers = { };  
    return axios.post(api, notice,{headers: headers})    
}

export default {
    deleteNotice,
    saveNotice
  };