import axios from 'axios';

const SchoolTerms = [];
const Levels = [];
const MasteryCompetency = [];
const MasteryGradeLevels = [];
const MasterySubjects = [];
const MasteryTerms = [];
const AccountTypes = [];

// const url = `${process.env.REACT_APP_API_LOOKUP}/list`;
const getTerms = () => {
   axios.get(`${process.env.REACT_APP_API_LOOKUP}/termlist`).then((response) => {
      if (response.data) {
         const data = response.data;
         data.map((item) => {
            let obj = { 'value': item.value, 'name': item.name }
            SchoolTerms.push(obj);
         });
      }
   }, (error) => {
      console.log(error.message);
   });
}

const getLevels = () => {
   axios.get(`${process.env.REACT_APP_API_LOOKUP}/levellist`).then((response) => {
      if (response.data) {
         const data = response.data;
         data.map((item) => {
            let obj = { 'value': item.value, 'name': item.name }
            Levels.push(obj);
         });
      }
   }, (error) => {
      console.log(error.message);
   });
}

const getMasteryCompetency = () => {
   axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterycompetency`).then((response) => {
      if (response.data) {
         const data = response.data;
         data.map((item) => {
            let obj = { 'value': item.value, 'name': item.name }
            MasteryCompetency.push(obj);
         });
      }
   }, (error) => {
      console.log(error.message);
   });
}

const getMasteryGradeLevels = () => {
   axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterygradelevels`).then((response) => {
      if (response.data) {
         const data = response.data;
         data.map((item) => {
            let obj = { 'value': item.value, 'name': item.name }
            MasteryGradeLevels.push(obj);
         });
      }
   }, (error) => {
      console.log(error.message);
   });
}

const getMasterySubjects = () => {
   axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterysubjects`).then((response) => {
      if (response.data) {
         const data = response.data;
         data.map((item) => {
            let obj = { 'value': item.value, 'name': item.name }
            MasterySubjects.push(obj);
         });
      }
   }, (error) => {
      console.log(error.message);
   });
}

const getMasteryTerms = () => {
   axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasteryterms`).then((response) => {
      if (response.data) {
         const data = response.data;
         data.map((item) => {
            let obj = { 'value': item.value, 'name': item.name }
            MasteryTerms.push(obj);
         });
      }
   }, (error) => {
      console.log(error.message);
   });
}

const getAccountTypes = () => {
   let obj = [];

   obj = [
      { 'value': '-1', 'name': 'Admin' },
      { 'value': '0', 'name': 'Teacher' },
      { 'value': '-5', 'name': 'Co-Teacher' },
      { 'value': '-2', 'name': 'Registrar' },
      { 'value': '-3', 'name': 'Interviewer' },
      { 'value': '-4', 'name': 'Cashier' },
   ]
   
   AccountTypes.push(obj);
}

getTerms();
getLevels();
// getMasteryGradeLevels();
// getMasterySubjects();
// getMasteryTerms();
// getMasteryCompetency();
getAccountTypes();

export {
   SchoolTerms,
   Levels,
   AccountTypes,
   // MasteryCompetency,
   // MasteryGradeLevels,
   // MasterySubjects,
   // MasteryTerms,
}