import { useContext} from 'react'
import {  Box, Grid, Flex } from 'theme-ui'
import { CanvasContext } from '../../hooks/LessonPage';

function TextPanel() {
  const { state, actions } = useContext(CanvasContext);

  const addHeading = () => {
    var data = {type : "TEXT", url : "", defaultcontent : "<h1><strong>Add header here</strong></h1>"}
    actions?.addElement(data);    
  }

  const addSubheading = () => {
    var data = {type : "TEXT", url : "", defaultcontent : "<h3>Add a subheading</h3>"}
    actions?.addElement(data);   
  }

  const addTextBody = () => {
    var data = {type : "TEXT", url : "", defaultcontent : "Add a little bit of body text"}
    actions?.addElement(data);       
  }

  const buttonClick = () =>
  {
    var data = {type : "TEXT", url : "", defaultcontent : "Sample Text"}
    actions?.addElement(data);    
  }

  return (
    <>
      {/* <Box sx={{ padding: '2rem 2rem 1rem' }}>
        <Input style={{ background: '#fff', color: '#333' }} type="tel" placeholder="Search text" />
        <button onClick={buttonClick}>Click Me</button>
      </Box> */}
      <Box sx={{ padding: '0 2rem', color: '#fff', fontFamily: 'Lexend' }}>
        <Box sx={{ fontWeight: 600, fontSize: '0.84rem', padding: '0.8rem 0' }}>
          Click text to add to page
        </Box>
        <Grid sx={{ gridTemplateRows: '50px 50px 50px' }}>
          <Flex
            sx={{
              paddingLeft: '1rem',
              fontSize: '1.66rem',
              alignItems: 'center',
              background: 'rgba(255,255,255,0.1)',
              fontWeight: 700,
            }}
            onClick={addHeading}
          >
            Add a heading
          </Flex>
          <Flex
            sx={{
              paddingLeft: '1rem',
              fontSize: '1.12rem',
              alignItems: 'center',
              background: 'rgba(255,255,255,0.1)',
              fontWeight: 500,
            }}
            onClick={addSubheading}
          >
            Add a subheading
          </Flex>
          <Flex
            sx={{
              paddingLeft: '1rem',
              fontSize: '0.76rem',
              alignItems: 'center',
              background: 'rgba(255,255,255,0.1)',
              fontWeight: 300,
            }}
            onClick={addTextBody}
          >
            Add a litle bit of body text
          </Flex>
        </Grid>
      </Box>
    </>
  )
}
export default TextPanel
