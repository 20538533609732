/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Typography, Button, Row, Col, Modal, Image, Segmented, Breadcrumb } from 'antd';
import * as AntDIcons from '@ant-design/icons';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { useDispatch } from 'react-redux';
import { reset_mastery_data } from '../../actions';
import DocumentViewer from '../../components/document-viewer/DocumentViewer';

const { Text } = Typography;

export function MasterySubjects() {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   //const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [subjectList, setSubjectList] = useState([]);
   const [visibleContentGuide, setVisibleContentGuide] = useState(false);
   const [contentGuideURL, setContentGuideURL] = useState(null);
   const [subjectName, setSubjectName] = useState(null);

   const [questionType, setQuestionType] = useState(1);
   const [questionTypeModalVisible, setQuestionTypeModalVisible] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'co-teacher')
         navigate('/home');

      localStorage.removeItem('mastery-assessment-search-keys');
      getMasterySubjects();

   }, []);

   const getMasterySubjects = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterysubjects`).then((response) => {
         if (response.data) {
            const data = response.data;
            var subjects = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               subjects.push(obj);
            });

            setSubjectList(subjects);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   function colorize() {
      var r = ('0' + (Math.random() * 255 | 0).toString(16)).slice(-2),
         g = ('0' + (Math.random() * 255 | 0).toString(16)).slice(-2),
         b = ('0' + (Math.random() * 255 | 0).toString(16)).slice(-2);
      return '#' + r + g + b;
   }

   const handleCreateMastery = () => {
      var params = { key: ``, value: '' };
      dispatch(reset_mastery_data(params));

      navigate(`/masteryassessment/create?qt=${questionType}`);
   }

   const itemSubjectClick = (subject, subjectname) => {
      navigate(`/masteryassessmentsubject?id=${subject}&name=${subjectname}`)
   }

   const handleContentGuideClick = (subject, subjectname) => {
      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/masterycontentguide?subject=${subject}`;

      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            setContentGuideURL(data);
            setSubjectName(subjectname);
            setVisibleContentGuide(true);
         }
      }, (error) => {
         console.log(error.message);
      });

   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li className="active">Mastery Subjects</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>Mastery Subject</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               {
                  user_role.toLowerCase() === 'admin' &&
                  // user_id === '1' &&
                  <Row gutter={12} style={{ paddingBottom: "15px" }}>
                     <Col className="gutter-row" span={24} style={{ textAlign: "right" }}>
                        {/* <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => handleCreateMastery()}>
                           <Text ellipsis style={{ color: "#fff" }}><AntDIcons.PlusOutlined />&nbsp;Create Mastery</Text>
                        </Button> */}
                        <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => setQuestionTypeModalVisible(true)}>
                           <Text ellipsis style={{ color: "#fff" }}><AntDIcons.PlusOutlined />&nbsp;Create Mastery</Text>
                        </Button>
                     </Col>
                  </Row>
               }

               <div className="ag-format-container">
                  <div className="ag-courses_box">
                     {
                        // !questionTypeModalVisible &&
                        subjectList.map((subject, i) => {
                           var color = colorize();

                           return (
                              <>
                                 <div className="ag-courses_item">
                                    <a href="#" className="ag-courses-item_link">
                                       <div className="ag-courses-item_bg" style={{ backgroundColor: color }} onClick={() => itemSubjectClick(subject.value, subject.name)}></div>
                                       <div className="ag-courses-item_title" onClick={() => itemSubjectClick(subject.value, subject.name)}>
                                          {subject.name}
                                       </div>

                                       <div className="ag-courses-item_date-box" style={{ justifyContent: 'right', width: '50%' }}>
                                          {/* <span className="ag-courses-item_date"> */}
                                          <Button type='primary' shape='round' onClick={() => handleContentGuideClick(subject.value, subject.name)}>Content Guide</Button>
                                          {/* </span> */}
                                       </div>
                                    </a>
                                 </div>
                              </>
                           )
                        })
                     }
                  </div>
               </div>
            </div>
         </div>

         <Modal
            key="modal-learning-plan"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Content Guide for {subjectName}</div>}
            destroyOnClose={true}
            centered
            open={visibleContentGuide}
            onOk={() => setVisibleContentGuide(false)}
            okText={"Close"}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round", style: { display: 'none' } }}
            width='75%'
         >
            <div className='student_list_container'>
               <DocumentViewer
                  document={contentGuideURL}
                  pdfshowdownload={true}
                  pdfshowprint={true}
                  ismodal={true}
                  dvheight={"calc(100vh - 220px)"}
               />
            </div>
         </Modal>

         <Modal
            title="Select Questionaire Option"
            centered
            destroyOnClose={true}
            open={questionTypeModalVisible}
            onOk={() => handleCreateMastery()}
            onCancel={() => setQuestionTypeModalVisible(false)}
            cancelButtonProps={{ shape: "round" }}
            okButtonProps={{ shape: "round" }}
            width={"300px"}
         >
            <Segmented
               key={'uid'}
               value={questionType}
               block
               options={[
                  {
                     label: 'Upload',
                     value: 1,
                  },
                  {
                     label: 'Create',
                     value: 2,
                  },
               ]}
               onChange={(val) => setQuestionType(val)}
            />
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '20px' }}>
               <Text strong>
                  {
                     questionType === 1
                        ?
                        'Upload a questionaire in pdf format'
                        :
                        'Manually create the questionaire'
                  }
               </Text>
            </div>
         </Modal>
      </>
   )
}