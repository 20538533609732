import React, { useEffect, useState, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
   Space, Button, Row, Col, Modal, Upload, Form
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import SimpleBarReact from "simplebar-react";
import uuid from 'react-uuid';
import Utils from '../../../misc/Utils';
import Enums from '../../../misc/Enums';
// import { Editor } from '@tinymce/tinymce-react';
import Wysiwyg2 from '../../../components/wysiwyg/wysiwyg2';
import StickyBox from "react-sticky-box";

export default function CreateInstructions(props) {
   const [form] = Form.useForm();
   const editorRef = useRef(null);
   const navigate = useNavigate();

   const [descriptionCreate, setDescriptionCreate] = useState(null);
   const [uploadFileList, setUploadFileList] = useState([]);

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'co-teacher')
         navigate('/home');
   }, []);

   const upload_props = {
      onRemove: file => {
         setUploadFileList(() => {
            const index = uploadFileList.indexOf(file.originFileObj);
            const newFileList = uploadFileList.slice();
            newFileList.splice(index, 1);
            return newFileList;
         })
      },

      beforeUpload: file => {
         const isPNG = file.type === 'image/png';
         const isJPG = file.type === 'image/jpeg';
         const isBMP = file.type === 'image/bmp';
         const isDOC = file.type === 'application/msword' || file.type === 'application/vnd.ms-word';
         const isDOCX = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
         const isXLS = file.type === 'application/msexcel' || file.type === 'application/vnd.ms-excel';
         const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
         const isPPT = file.type === 'application/vnd.ms-powerpoint';
         const isPPTX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
         const isPPSX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow';
         const isPDF = file.type === 'application/pdf';
         const isMP4 = file.type === 'video/mp4';
         const isAVI = file.type === 'video/avi';

         const fileValid = isPNG || isJPG || isBMP || isDOC || isDOCX || isXLS || isXLSX || isPPT || isPPTX || isPDF || isPPSX || isMP4 || isAVI;

         if (fileValid) {
            const isLt100M = file.size / 1024 / 1024 <= 100;

            if (isLt100M) {
               var list = uploadFileList;
               list.push(file);
               setUploadFileList(list);
            } else {
               Modal.error({
                  title: 'Mastery Assessment',
                  content: 'File should be less than 100 MB',
                  centered: true
               });
            }

            // console.log(uploadFileList);
         } else {
            Modal.error({
               title: 'Mastery Assessment',
               content: 'You are not allowed to upload that file type',
               centered: true
            });
         }

         return false;
      },

      uploadFileList,
   };

   const handleDescriptionChange = (val) => {
      setDescriptionCreate(val);
   }

   const handleCancelCreate = () => {
      setUploadFileList([]);
      props.onCancelCallback();
   }

   const handleSaveRecommendation = () => {
      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
      var instruction_id = "instruction_" + o2o_mode + "_" + uuid();
      var fileList = [];

      uploadFileList.forEach(file => {
         fileList.push(file.name);
      });

      var _data = {
         instruction_id: instruction_id,
         instruction_desc: descriptionCreate,
         instruction_docs: null,
         instruction_doc_names: fileList.join(','),
      }

      props.onFinishCallback(uploadFileList, _data);
   }

   return (
      <>
         <Form
            form={form}
            layout={"vertical"}
            colon={false}
         >
            <Row gutter={12} >
               <Col span={16}>
                  <label>Description</label>
                  <Wysiwyg2
                     changeContentCallback={(e) => handleDescriptionChange(e)}
                     content={''}
                     height={300}
                     contentHeight={235}
                  />
                  {/* <Editor
                     apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                     onInit={(evt, editor) => editorRef.current = editor}
                     onFocusOut={(e) => handleDescriptionChange(e.target.innerHTML)}
                     initialValue=""
                     init={{
                        menubar: true,
                        height: 300,
                        statusbar: true,
                        plugins: [
                           'advlist autolink lists link image charmap print preview anchor',
                           'searchreplace visualblocks code',
                           'insertdatetime media table paste code help wordcount emoticons'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                           'bold italic backcolor | alignleft aligncenter ' +
                           'alignright alignjustify | bullist numlist outdent indent | ' +
                           'removeformat | emoticons | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                     }}
                  /> */}
                  {/* </Form.Item> */}
               </Col>
               <Col span={8}>
                  <Form.Item name="attached_documents" label="Attach Document(s)">
                     <SimpleBarReact style={{ maxHeight: "250px", overflow: "auto" }}>
                        <StickyBox style={{ zIndex: 1000 }}>
                           <Upload
                              style={{ display: "block" }}
                              maxCount={5}
                              multiple
                              {...upload_props}
                              listType="picture"
                              defaultFileList={[...uploadFileList]}
                              className="upload-list-inline"
                              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                              .xls,.xlsx,.png,.jpg,.pdf,.ppsx,.avi,.mp4"
                           >
                              <Button shape='round' icon={<AntDIcons.CloudUploadOutlined />} block>Select File</Button>
                           </Upload>
                        </StickyBox>
                     </SimpleBarReact>
                  </Form.Item>
               </Col>
            </Row>

            <Row gutter={12}>
               <Col span={24} style={{ textAlign: "right" }}>
                  <Space direction='horizontal'>
                     <Button shape='round' onClick={() => { handleCancelCreate(); }}>Cancel</Button>
                     <Button type='primary' shape='round' onClick={() => handleSaveRecommendation()}>Send</Button>
                  </Space>
               </Col>
            </Row>
         </Form>
      </>
   );
}