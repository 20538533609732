import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { Row, Col, Spin, Space, Empty, Typography, Table, Divider, Breadcrumb } from 'antd';

// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
// import * as AntDIcons from '@ant-design/icons';
// import SimpleBarReact from "simplebar-react";

import { Column } from '@ant-design/plots';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';

// import {
//    Chart as ChartJS,
//    CategoryScale,
//    LinearScale,
//    BarElement,
//    Title as ChartTitle,
//    Tooltip as ChartToolTip,
//    Legend,
// } from 'chart.js';

// import { Bar } from 'react-chartjs-2';

// ChartJS.register(
//    CategoryScale,
//    LinearScale,
//    BarElement,
//    ChartTitle,
//    ChartToolTip,
//    Legend
// );

var data_main = [];

var data_level1 = {
   beginner: 0,
   proficient: 0,
   competent: 0,

   beginner_list: [],
   proficient_list: [],
   competent_list: [],
}

var data_level2 = {
   beginner: 0,
   proficient: 0,
   competent: 0,

   beginner_list: [],
   proficient_list: [],
   competent_list: [],
}

var data_level3 = {
   beginner: 0,
   proficient: 0,
   competent: 0,

   beginner_list: [],
   proficient_list: [],
   competent_list: [],
}

const { Text, Title } = Typography;

export function MasteryReportType1(props) {
   const navigate = useNavigate();
   const location = useLocation();

   const search = window.location.search;
   const query = new URLSearchParams(search);
   const assign_id = query.get('assign_id');

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [data, setData] = useState({
      loading: false,
      hasData: false,
   })

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'co-teacher')
         navigate('/home');

      getMasteryReportData(assign_id);
   }, []);

   function student_columns(column_type) {
      var retVal = [
         {
            title: column_type,
            key: 'name',
            ellipsis: {
               showTitle: true,
            },
            render: object => (
               <Text>{object.student_last_name.toUpperCase() + ', ' + object.student_first_name.toUpperCase()}</Text>
            ),
            width: '76%',
         },
         {
            title: 'Score',
            key: 'score',
            render: object => (
               <Text>{object.score + ' of ' + object.total_points}</Text>
            ),
            width: '24%',
         },
      ];

      return retVal;
   }

   const getMasteryReportData = (assign_id) => {
      setData({
         loading: true,
         hasData: false,
      });

      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/getmasteryreportdata?assign_id=${assign_id}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            data_main = response.data;

            let beginner = [];
            let proficient = [];
            let competent = [];
            let total_count = 0;

            //-- Level 1
            beginner = data_main.filter(data => data.assessment_type === 'formative_1' && data.proficiency === 'Beginner');
            proficient = data_main.filter(data => data.assessment_type === 'formative_1' && data.proficiency === 'Proficient');
            competent = data_main.filter(data => data.assessment_type === 'formative_1' && data.proficiency === 'Competent');
            total_count = beginner.length + proficient.length + competent.length;

            data_level1.beginner = Math.round((beginner.length / total_count) * 100);
            data_level1.proficient = Math.round((proficient.length / total_count) * 100);
            data_level1.competent = Math.round((competent.length / total_count) * 100);
            data_level1.beginner_list = beginner.sort((a, b) => { return a.student_last_name.localeCompare(b.student_last_name) });
            data_level1.proficient_list = proficient.sort((a, b) => { return a.student_last_name.localeCompare(b.student_last_name) });
            data_level1.competent_list = competent.sort((a, b) => { return a.student_last_name.localeCompare(b.student_last_name) });

            //-- Level 2
            beginner = [];
            proficient = [];
            competent = [];
            total_count = 0;

            beginner = data_main.filter(data => data.assessment_type === 'formative_2' && data.proficiency === 'Beginner');
            proficient = data_main.filter(data => data.assessment_type === 'formative_2' && data.proficiency === 'Proficient');
            competent = data_main.filter(data => data.assessment_type === 'formative_2' && data.proficiency === 'Competent');
            total_count = beginner.length + proficient.length + competent.length;

            data_level2.beginner = Math.round((beginner.length / total_count) * 100);
            data_level2.proficient = Math.round((proficient.length / total_count) * 100);
            data_level2.competent = Math.round((competent.length / total_count) * 100);
            data_level2.beginner_list = beginner.sort((a, b) => { return a.student_last_name.localeCompare(b.student_last_name) });
            data_level2.proficient_list = proficient.sort((a, b) => { return a.student_last_name.localeCompare(b.student_last_name) });
            data_level2.competent_list = competent.sort((a, b) => { return a.student_last_name.localeCompare(b.student_last_name) });

            //-- Level 3
            beginner = [];
            proficient = [];
            competent = [];
            total_count = 0;

            beginner = data_main.filter(data => data.assessment_type === 'formative_3' && data.proficiency === 'Beginner');
            proficient = data_main.filter(data => data.assessment_type === 'formative_3' && data.proficiency === 'Proficient');
            competent = data_main.filter(data => data.assessment_type === 'formative_3' && data.proficiency === 'Competent');
            total_count = beginner.length + proficient.length + competent.length;

            data_level3.beginner = Math.round((beginner.length / total_count) * 100);
            data_level3.proficient = Math.round((proficient.length / total_count) * 100);
            data_level3.competent = Math.round((competent.length / total_count) * 100);
            data_level3.beginner_list = beginner.sort((a, b) => { return a.student_last_name.localeCompare(b.student_last_name) });
            data_level3.proficient_list = proficient.sort((a, b) => { return a.student_last_name.localeCompare(b.student_last_name) });
            data_level3.competent_list = competent.sort((a, b) => { return a.student_last_name.localeCompare(b.student_last_name) });

            setData({
               loading: false,
               hasData: data_main.length > 0 ? true : false,
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   function GenerateReport() {
      // var chart_options = {
      //    responsive: true,
      //    plugins: {
      //       legend: {
      //          position: 'top',
      //       },
      //       title: {
      //          display: false,
      //          text: 'Mastery Assessment Report',
      //       },
      //    },
      // };

      // var labels = ['Level 1', 'Level 2', 'Level 3'];
      // var data = {
      //    labels,
      //    datasets: [
      //       {
      //          label: 'Beginner',
      //          data: [data_level1.beginner, data_level2.beginner, data_level3.beginner],
      //          backgroundColor: '#5297DC',
      //       },
      //       {
      //          label: 'Proficient',
      //          data: [data_level1.proficient, data_level2.proficient, data_level3.proficient],
      //          backgroundColor: '#369DA2',
      //       },
      //       {
      //          label: 'Competent',
      //          data: [data_level1.competent, data_level2.competent, data_level3.competent],
      //          backgroundColor: '#6EA339',
      //       },
      //    ],
      // };

      var data = [
         {
            name: 'Beginner',
            level: 'Level 1',
            percent: !isNaN(data_level1.beginner) ? data_level1.beginner : 0,
         },
         {
            name: 'Proficient',
            level: 'Level 1',
            percent: !isNaN(data_level1.proficient) ? data_level1.proficient : 0,
         },
         {
            name: 'Competent',
            level: 'Level 1',
            percent: !isNaN(data_level1.competent) ? data_level1.competent : 0,
         },
         {
            name: 'Beginner',
            level: 'Level 2',
            percent: !isNaN(data_level2.beginner) ? data_level2.beginner : 0,
         },
         {
            name: 'Proficient',
            level: 'Level 2',
            percent: !isNaN(data_level2.proficient) ? data_level2.proficient : 0,
         },
         {
            name: 'Competent',
            level: 'Level 2',
            percent: !isNaN(data_level2.competent) ? data_level2.competent : 0,
         },
         {
            name: 'Beginner',
            level: 'Level 3',
            percent: !isNaN(data_level3.beginner) ? data_level3.beginner : 0,
         },
         {
            name: 'Proficient',
            level: 'Level 3',
            percent: !isNaN(data_level3.proficient) ? data_level3.proficient : 0,
         },
         {
            name: 'Competent',
            level: 'Level 3',
            percent: !isNaN(data_level3.competent) ? data_level3.competent : 0,
         },
      ]

      var config = {
         data,
         isGroup: true,
         xField: 'level',
         yField: 'percent',
         seriesField: 'name',
         dodgePadding: 2,
         autoFit: true,
         label: {
            position: 'center',
            layout: [
               {
                  type: 'interval-adjust-position',
               },
               {
                  type: 'interval-hide-overlap',
               },
               {
                  type: 'adjust-color',
               },
            ],
         },
      };

      var retVal = (
         <>
            <div style={{ width: '100%', textAlign: 'center' }}>
               <h5><b>Class Summary Report</b></h5>
            </div>
            <Row style={{ rowGap: "0px" }}>
               <Col span={24} style={{ textAlign: "left" }}>
                  <span>Teacher: <b>{data_main[0].teacher}</b></span>
               </Col>
            </Row>
            <Row style={{ rowGap: "0px" }}>
               <Col span={24} style={{ textAlign: "left" }}>
                  <span>Quarter: <b>{data_main[0].term}</b></span>
               </Col>
            </Row>
            <Row style={{ rowGap: "0px" }}>
               <Col span={24} style={{ textAlign: "left" }}>
                  <span>Subject: <b>{data_main[0].subject}</b></span>
               </Col>
            </Row>
            <Row style={{ rowGap: "0px", paddingBottom: "20px" }}>
               <Col span={24} style={{ textAlign: "left" }}>
                  <span>Grade: <b>{data_main[0].grade_level}</b></span>
               </Col>
            </Row>
            <Row style={{ paddingBottom: "30px" }}>
               <Col xs={24} md={2} lg={2} style={{ textAlign: "left", minWidth: '11ch' }}>
                  <span>Competency:</span>
               </Col>
               <Col xs={24} md={22} lg={22} style={{ textAlign: "left" }}>
                  <span><b>{data_main[0].competency.toUpperCase() + " " + data_main[0].title}</b></span>
               </Col>
            </Row>

            <div className='chart-container'>
               {/* <Bar options={chart_options} data={data} /> */}
               <Column {...config} />
            </div>

            <Divider orientation="center">
               <Title level={5} style={{ textAlign: "Left", color: "#252525" }}>List of students who took Level 1</Title>
            </Divider>

            <Row gutter={12}>
               <Col xs={24} md={8} lg={8}>
                  <Table
                     size='small'
                     columns={student_columns('Beginner')}
                     dataSource={data_level1.beginner_list}
                     pagination={false}
                     rowKey={'uid'}
                  />
               </Col>
               <Col xs={24} md={8} lg={8}>
                  <Table
                     size='small'
                     columns={student_columns('Proficient')}
                     dataSource={data_level1.proficient_list}
                     pagination={false}
                     rowKey={'uid'}
                  />
               </Col>
               <Col xs={24} md={8} lg={8}>
                  <Table
                     size='small'
                     columns={student_columns('Competent')}
                     dataSource={data_level1.competent_list}
                     pagination={false}
                     rowKey={'uid'}
                  />
               </Col>
            </Row>

            <Divider orientation="center">
               <Title level={5} style={{ textAlign: "Left", color: "#252525" }}>List of students who took Level 2</Title>
            </Divider>

            <Row gutter={12}>
               <Col xs={24} md={8} lg={8}>
                  <Table
                     size='small'
                     columns={student_columns('Beginner')}
                     dataSource={data_level2.beginner_list}
                     pagination={false}
                     rowKey={'uid'}
                  />
               </Col>
               <Col xs={24} md={8} lg={8}>
                  <Table
                     size='small'
                     columns={student_columns('Proficient')}
                     dataSource={data_level2.proficient_list}
                     pagination={false}
                     rowKey={'uid'}
                  />
               </Col>
               <Col xs={24} md={8} lg={8}>
                  <Table
                     size='small'
                     columns={student_columns('Competent')}
                     dataSource={data_level2.competent_list}
                     pagination={false}
                     rowKey={'uid'}
                  />
               </Col>
            </Row>

            <Divider orientation="center">
               <Title level={5} style={{ textAlign: "Left", color: "#252525" }}>List of students who took Level 3</Title>
            </Divider>

            <Row gutter={12}>
               <Col xs={24} md={8} lg={8}>
                  <Table
                     size='small'
                     columns={student_columns('Beginner')}
                     dataSource={data_level3.beginner_list}
                     pagination={false}
                     rowKey={'uid'}
                  />
               </Col>
               <Col xs={24} md={8} lg={8}>
                  <Table
                     size='small'
                     columns={student_columns('Proficient')}
                     dataSource={data_level3.proficient_list}
                     pagination={false}
                     rowKey={'uid'}
                  />
               </Col>
               <Col xs={24} md={8} lg={8}>
                  <Table
                     size='small'
                     columns={student_columns('Competent')}
                     dataSource={data_level3.competent_list}
                     pagination={false}
                     rowKey={'uid'}
                  />
               </Col>
            </Row>

            <Divider orientation="left">
               <Title level={5} style={{ textAlign: "center", color: "#252525" }}>Students Classified by level of Proficiency</Title>
            </Divider>

            <Space direction='vertical' style={{ width: "100%", paddingBottom: "20px" }}>
               <Row>
                  <Col span={3} style={{ textAlign: "center" }}>
                     <span><b>Level 1</b></span>
                  </Col>
                  <Col span={7} style={{ textAlign: "center" }}>
                     <span>Beginner: <b>{data_level1.beginner_list.length}</b></span>
                  </Col>
                  <Col span={7} style={{ textAlign: "center" }}>
                     <span>Proficient: <b>{data_level1.proficient_list.length}</b></span>
                  </Col>
                  <Col span={7} style={{ textAlign: "center" }}>
                     <span>Competent: <b>{data_level1.competent_list.length}</b></span>
                  </Col>
               </Row>

               <Row>
                  <Col span={3} style={{ textAlign: "center" }}>
                     <span><b>Level 2</b></span>
                  </Col>
                  <Col span={7} style={{ textAlign: "center" }}>
                     <span>Beginner: <b>{data_level2.beginner_list.length}</b></span>
                  </Col>
                  <Col span={7} style={{ textAlign: "center" }}>
                     <span>Proficient: <b>{data_level2.proficient_list.length}</b></span>
                  </Col>
                  <Col span={7} style={{ textAlign: "center" }}>
                     <span>Competent: <b>{data_level2.competent_list.length}</b></span>
                  </Col>
               </Row>

               <Row>
                  <Col span={3} style={{ textAlign: "center" }}>
                     <span><b>Level 3</b></span>
                  </Col>
                  <Col span={7} style={{ textAlign: "center" }}>
                     <span>Beginner: <b>{data_level3.beginner_list.length}</b></span>
                  </Col>
                  <Col span={7} style={{ textAlign: "center" }}>
                     <span>Proficient: <b>{data_level3.proficient_list.length}</b></span>
                  </Col>
                  <Col span={7} style={{ textAlign: "center" }}>
                     <span>Competent: <b>{data_level3.competent_list.length}</b></span>
                  </Col>
               </Row>
            </Space>
         </>
      );

      return retVal;
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate('/masteryassessmentreport')}>Mastery Assessment Report</a></li>
                     <li><span>/</span></li>
                     <li className="active">Report Type 1</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate('/masteryassessmentreport')}>Mastery Assessment Report</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Report Type 1</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
               {/* <SimpleBarReact style={{ height: "calc(100vh - 55px)" }}> */}
               {data.loading === false
                  ?
                  <>
                     {
                        data.hasData
                           ?
                           // <div className='section_to_print' style={{ width: "calc(100% - 6px)" }}>
                           <div className='section_to_print'>
                              {GenerateReport()}
                           </div>
                           :
                           <div className='temp-answer-sheet-content2'>
                              <Empty description="No data available for report" image="../images/brainee_mascot.png" />
                           </div>
                     }
                  </>
                  :
                  <>
                     <div className='mastery_assessment_list'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  </>
               }
               {/* </SimpleBarReact> */}
            </div>

         </div>

         {/* <div className="notice-area">
            <div className="notice-item">
               <img src="../images/brainee_ma.png" width="100%" style={{ padding: "5px", maxWidth: '600px' }}></img>
               <NoticeBoard />
            </div>
         </div> */}
      </>
   );
}