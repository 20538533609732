import axios from 'axios';

const searchGoogleService = (type, value) =>
{
    var api = ""
    const headers = {}
    const googleAPIKey = `${process.env.REACT_APP_GOOGLE_KEY}` 
    const cx = `${process.env.REACT_APP_ENGINE_ID}` 
    if (type === "youtube")
        api = `${process.env.REACT_APP_YOUTUBE_API}=${googleAPIKey}&q=${value}&maxResults=20&part=snippet`;
    else if (type === "google.image")
        api = `${process.env.REACT_APP_SEARCH_API}=${googleAPIKey}&cx=${cx}&q=${value}&searchType=image&maxResults=20&part=snippet`;
    else if (type === "google.search")
        api = `${process.env.REACT_APP_SEARCH_API}=${googleAPIKey}&cx=${cx}&q=${value}&maxResults=20&part=snippet`;  
    
       return axios.get(api, {transformRequest: (data, headers) => {
            delete headers.common['Authorization'];
            delete headers.common['Subdomain'];
            return data;
          }
        });

    //return axios.get(api, {headers: headers});    
}

const searchGoogleServiceAsync = async (type, value) =>
    {
        var api = ""
        const headers = {}
        const googleAPIKey = `${process.env.REACT_APP_GOOGLE_KEY}` 
        const cx = `${process.env.REACT_APP_ENGINE_ID}` 
        if (type === "youtube")
            api = `${process.env.REACT_APP_YOUTUBE_API}=${googleAPIKey}&q=${value}&maxResults=20&part=snippet`;
        else if (type === "google.image")
            api = `${process.env.REACT_APP_SEARCH_API}=${googleAPIKey}&cx=${cx}&q=${value}&searchType=image&maxResults=20&part=snippet`;
        else if (type === "google.search")
            api = `${process.env.REACT_APP_SEARCH_API}=${googleAPIKey}&cx=${cx}&q=${value}&maxResults=20&part=snippet`;  
        
           return await axios.get(api, {transformRequest: (data, headers) => {
                delete headers.common['Authorization'];
                delete headers.common['Subdomain'];
                return data;
              }
            });
     
        //return axios.get(api, {headers: headers});    
    }

export default {
    searchGoogleService,
    searchGoogleServiceAsync
  };