import React, { useState, useEffect } from 'react'
import { Button, Radio, Typography, DatePicker, Divider, Descriptions, Tree, Select, Col, Row, Empty } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import StickyBox from "react-sticky-box";
import { Levels } from '../../constants'

const { Text, Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select

export default function MasteryAssignTool(props) {
   // const assOptionState = useSelector(state => state.assOptionState)
   // const dispatch = useDispatch();   
   const [expandedKeys, setExpandedKeys] = useState([]);
   const [checkedKeys, setCheckedKeys] = useState([]);
   const [selectedKeys, setSelectedKeys] = useState([]);
   // const [autoExpandParent, setAutoExpandParent] = useState(true);
   const [treeData, setTreeData] = useState([]);

   const [sectionLoading, setSectionLoading] = useState(false);
   const [section, setSection] = useState(null);
   const [sectionList, setSectionList] = useState([]);

   const dateFormat = 'MMM D, YYYY h:mm a';
   const customFormat = value => `${value.format(dateFormat)}`;

   var ass_options = props.options;

   useEffect(() => {
      // Utils.verifyJwt();

      if (props.options.level !== undefined && props.options.level !== null && props.options.level !== 0 &&
         props.options.section !== undefined && props.options.section !== null && props.options.section !== 0) {

         ass_options.level = props.options.level;
         ass_options.section = props.options.section;
         setExpandedKeys([]);

         axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${ass_options.level}`).then((response) => {
            if (response.data) {
               const data = response.data;

               setSectionList(data);
               setSectionLoading(false);

               axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/studentlistperlevelsectionjson?level=${ass_options.level}&section=${ass_options.section}`).then((response) => {
                  if (response.data) {
                     const data = response.data;
                     setTreeData(data);

                     // var students = props.options.assigned_students.split(',');
                     // const finallist = students.map(e => 's_' + e);
                     // setCheckedKeys(finallist);

                     var students = props.options.assigned_students_with_section.split(',');
                     setCheckedKeys(students);
                  } else {
                     setTreeData([]);
                     setCheckedKeys([]);
                     // setExpandedKeys([]);
                  }
               }, (error) => {
                  console.log(error.message);
               });

            }
         }, (error) => {
            setSectionLoading(false);
            console.log(error.message);
         });

         props.parentCallback(ass_options);
      }

   }, [props.options.id]);

   const onExpand = (expandedKeysValue) => {
      //console.log('onExpand', expandedKeysValue); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.

      setExpandedKeys(expandedKeysValue);
      // setAutoExpandParent(false);
   };

   const onCheck = (checkedKeysValue) => {
      //console.log('onCheck', checkedKeysValue);
      setCheckedKeys(checkedKeysValue);

      //-- (start) with section ------------------------------
      const selectedStudsWithSection = checkedKeysValue.filter((e) => {
         return e.includes('_');
         //return e.includes('|');

      });

      ass_options.assigned_students_with_section = selectedStudsWithSection.join(',');

      //-- Remove the section part
      for (var i = 0; i < selectedStudsWithSection.length; i++) {
         selectedStudsWithSection[i] = selectedStudsWithSection[i].substring(selectedStudsWithSection[i].indexOf('_') + 1, selectedStudsWithSection[i].length);
         //selectedStudsWithSection[i] = selectedStudsWithSection[i].substring(selectedStudsWithSection[i].indexOf('|') + 1, selectedStudsWithSection[i].length);
      }

      var selected = [...new Set(selectedStudsWithSection)]; //-- Remove duplicate students
      ass_options.assigned_students = selected.join(',');
      //-- (end) with section ------------------------------

      // //-- (start) without section ------------------------------
      // const selectedStuds = checkedKeysValue.filter((e) => {
      //    return e.includes('s_');
      // });

      // ass_options.assigned_students = selectedStuds.join(',');
      // ass_options.assigned_students = ass_options.assigned_students.replace(/s_/g, '');
      // //-- (end) without section ------------------------------

      props.parentCallback(ass_options);
   };

   const onSelect = (selectedKeysValue, info) => {
      //console.log('onSelect', info);
      setSelectedKeys(selectedKeysValue);
   };

   const handleChange = (event) => {
      props.parentCallback("update", event.target.value, props.id);
   }

   const handleClose = (event) => {
      // props.parentCallback(ass_options);
      props.backCallback(ass_options);
   }

   const onDateTimeChange = (value, dateString) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      ass_options.start_date = dateString[0];
      ass_options.end_date = dateString[1];
      props.parentCallback(ass_options);
   }

   const onInterventionChange = (val) => {
      ass_options.teacher_intervene = parseInt(val.target.value);
      props.parentCallback(ass_options);
   }

   const onRandomizeQuestionsChange = (val) => {
      ass_options.randomize_questions = parseInt(val.target.value);
      props.parentCallback(ass_options);
   }

   const onCanChangeAnswersChange = (val) => {
      ass_options.can_change_answers = parseInt(val.target.value);
      props.parentCallback(ass_options);
   }

   const handleSave = () => {
      localStorage.setItem("_assoptions", JSON.stringify(ass_options));
      console.log(localStorage.getItem("_assoptions"));
      props.saveCallback(ass_options);
   }

   const handleLevelChange = (val) => {
      // setAssLevel(val);
      ass_options.level = val;

      setSection(null);
      setSectionList([]);
      setSectionLoading(true);

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${val}`).then((response) => {
         if (response.data) {
            const data = response.data;

            setSectionList(data);
            setSectionLoading(false);
         }
      }, (error) => {
         setSectionLoading(false);
         console.log(error.message);
      });

      // axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/studentlistperleveljson?level=${val}`).then((response) => {
      //    if (response.data) {
      //       const data = response.data;
      //       setTreeData(data);
      //       setCheckedKeys([]);
      //       setExpandedKeys([]);
      //    }
      // }, (error) => {
      //    console.log(error.message);
      // });

      // props.parentCallback(ass_options);
   }

   const handleSectionChange = (val) => {
      ass_options.section = val;
      setTreeData([]);
      setCheckedKeys([]);
      setExpandedKeys([]);

      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/studentlistperlevelsectionjson?level=${ass_options.level}&section=${val}`).then((response) => {
         if (response.data) {
            const data = response.data;
            setTreeData(data);
            setCheckedKeys([]);
            setExpandedKeys([]);
         }
      }, (error) => {
         console.log(error.message);
      });

      props.parentCallback(ass_options);
   }

   return (
      <div>
         <Divider orientation="left">
            <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Competency Title</Title>
         </Divider>
         <>
            <Text strong style={{ fontSize: "15px" }}>{ass_options.title}</Text>
         </>
         <Divider orientation="left">
            <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Set Options</Title>
         </Divider>

         <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", padding: "0px" }} column={4}>
            {
               ass_options.assessment_type === 'formative' &&
               <>
                  <Descriptions.Item label={"With Teacher Intervention"} style={{ textAlign: "center" }} span={4}>
                     <Radio.Group
                        name='teacher_intervene'
                        defaultValue={'0'}
                        value={ass_options.teacher_intervene.toString()}
                        buttonStyle='solid'
                        onChange={(e) => onInterventionChange(e)}
                     >
                        <Radio.Button value="1">Yes</Radio.Button>
                        <Radio.Button value="0">No</Radio.Button>
                     </Radio.Group>
                  </Descriptions.Item>
                  {
                     ass_options.mode === 2 &&
                     <Descriptions.Item label={"Randomize Questions"} style={{ textAlign: "center" }} span={4}>
                        <Radio.Group
                           name='randomize_question'
                           defaultValue={'0'}
                           value={ass_options.randomize_questions.toString()}
                           buttonStyle='solid'
                           onChange={(e) => onRandomizeQuestionsChange(e)}
                        >
                           <Radio.Button value="1">Yes</Radio.Button>
                           <Radio.Button value="0">No</Radio.Button>
                        </Radio.Group>
                     </Descriptions.Item>
                  }
                  {/* {
                     ass_options.mode === 2 &&
                     <Descriptions.Item label={"Student Can Change Answers"} style={{ textAlign: "center" }} span={4}>
                        <Radio.Group
                           name='can_change_answers'
                           defaultValue={'0'}
                           value={ass_options.can_change_answers.toString()}
                           buttonStyle='solid'
                           onChange={(e) => onCanChangeAnswersChange(e)}
                        >
                           <Radio.Button value="1">Yes</Radio.Button>
                           <Radio.Button value="0">No</Radio.Button>
                        </Radio.Group>
                     </Descriptions.Item>
                  } */}
               </>
            }

            <Descriptions.Item label={"Schedule (Start Date -> End Date)"} style={{ textAlign: "center" }} span={4}>
               <RangePicker
                  style={{ width: "100%" }}
                  showTime={{ format: 'hh:mm A', use12Hours: true }}
                  format={customFormat}
                  onChange={onDateTimeChange}
                  // defaultValue={
                  //    [props.options.start_date !== "" ? moment(props.options.start_date) : "", 
                  //    props.options.end_date !== "" ? moment(props.options.end_date) : ""]
                  // }
                  value={
                     [ass_options.start_date !== "" ? moment(ass_options.start_date) : "",
                     ass_options.end_date !== "" ? moment(ass_options.end_date) : ""]
                  }
               />
            </Descriptions.Item>
         </Descriptions>
         <Divider orientation="left">
            <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Assign Students</Title>
         </Divider>
         <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", padding: "0px" }} column={4}>
            <Descriptions.Item label={"Select Students"} span={4} style={{ textAlign: "center" }} >
               <Row gutter={[10, 10]}>
                  <Col span={12}>
                     <Select
                        showArrow
                        size='default'
                        style={{ width: '100%', textAlign: "left" }}
                        allowClear
                        placeholder="Select Level"
                        onChange={(val) => handleLevelChange(val)}
                        value={props.options.level}
                     >
                        {Levels.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>
                  </Col>
                  <Col span={12}>
                     <Select
                        showArrow
                        loading={sectionLoading}
                        size='default'
                        style={{ width: '100%', textAlign: "left" }}
                        allowClear
                        placeholder="Select Section"
                        onChange={(val) => handleSectionChange(val)}
                        value={props.options.section}
                     >
                        {sectionList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>
                  </Col>
               </Row>

               {props.options.level !== undefined && props.options.level !== null && props.options.level !== 0 &&
                  props.options.section !== undefined && props.options.section !== null && props.options.section !== 0 && treeData.length > 0
                  ?
                  <Tree
                     checkable
                     // showLine={true}
                     // showIcon={false}
                     onExpand={onExpand}
                     expandedKeys={expandedKeys}
                     autoExpandParent={true}
                     onCheck={onCheck}
                     checkedKeys={checkedKeys}
                     onSelect={onSelect}
                     selectedKeys={selectedKeys}
                     treeData={treeData}
                     style={{ paddingBottom: "15px", paddingTop: "15px" }}
                     defaultExpandParent={true}
                  />
                  :
                  <>
                     {/* <Spin size='small' tip="Loading, please wait..." /> */}
                  </>
               }
            </Descriptions.Item>
         </Descriptions>

         <StickyBox bottom={true} style={{ zIndex: 1000, paddingBottom: "3px" }}>
            <Button className='button-shadow' type='primary' block shape='round' onClick={props.mode === "drawer" ? handleSave : handleClose}>
               <Text ellipsis style={{ color: "#fff" }}>{props.mode === "drawer" ? <SaveOutlined /> : <ArrowLeftOutlined />}&nbsp;{props.mode === "drawer" ? "Save" : "Back"}</Text>
            </Button>
         </StickyBox>
      </div>
   )
}