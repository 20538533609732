/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import {
   Spin, Empty, Input, Table, Typography, Space, Tooltip, Button, Select,
   Col, Tag, notification, Modal, Breadcrumb, message, Drawer, Divider, Descriptions, Tree, DatePicker
} from 'antd';
import {
   EyeOutlined, UsergroupAddOutlined,
} from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import uuid from 'react-uuid';
import './SubjectList.scss';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
// import { capitalize } from 'lodash';
import './SubjectCard.scss'
import SimpleBarReact from "simplebar-react";
import moment from 'moment';
import LessonService from '../../services/LessonService';

// const CryptoJS = require("crypto-js");

const { Text, Title } = Typography;
export function SubjectGroupingsResources() {
   const location = useLocation();
   const navigate = useNavigate();
   const { RangePicker } = DatePicker;
   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   const dateFormat = 'YYYY-MM-DD HH:mm';//'MMM D YYYY, h:mm a';    
   const customFormat = value => `${value.format(dateFormat)}`;
   const [data, setData] = useState([])
   const [dataSubject, setDataSubject] = useState([])
   const [dataContainer, setDataContainer] = useState([])
   const [isAdmin, setIsAdmin] = useState(false)
   const [loading, setLoading] = useState(false);
   const [activeTab, setActiveTab] = useState("1");
   const [tabname, setTabName] = useState("")
   const [key, setKey] = useState(null);
   const [levelId, setLevelId] = useState(null);
   const [user_id, setUser_Id] = useState(0);


   const [viewType, setViewType] = useState(0);
   const [subjectSelected, setSubjectSelected] = useState('')
   const [assignVisible, setAssignVisible] = useState(false);
   const [expandedKeys, setExpandedKeys] = useState([]);
   const [checkedKeys, setCheckedKeys] = useState([]);
   const [selectedKeys, setSelectedKeys] = useState([]);
   const [treeData, setTreeData] = useState([]);

   const [subjectlist, setSubjectlist] = useState([])
   const [acadProgramLevels, setAcadProgramLevels] = useState([]);

   const [subject, setSubject] = useState("");

   const [term, setTerm] = useState("");
   const [title, setTitle] = useState("");
   const [gradeLevel, setGradeLevel] = useState("");
   const [studentList, setStudentList] = useState("");
   const [studentListWithSection, setStudentListWithSection] = useState("");
   const [availabilitydate, setAvailabilitydate] = useState([]);
   const [availabilityFormatteddate, setAvailabilityFormatteddate] = useState("");

   const [termHasError, setTermHasError] = useState(false);
   const [titleHasError, setTitleHasError] = useState(false);
   const [studentListHasError, setStudentListHasError] = useState(false);
   const [availabilityDateHasError, setAvailabilitydateHasError] = useState(false);
   const [subjectHasError, setSubjectHasError] = useState(false);
   const [gradeLevelHasError, setGradeLevelHasError] = useState(false);

   let columnStyle = {
      float: "left",
      width: "60%",
      padding: "5px",
      position: "relative"
   };
   const [column] = useState(columnStyle);
   var subdomain = localStorage.getItem("lms-subdomain");

   const g_user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const aTab = location.state !== null ? (location.state.activetab) : (role === "Student" ? "3" : "1");



   useEffect(() => {
      Utils.verifyJwt();

      try {

         let urllink = `${process.env.REACT_APP_API_LESSON_LOOKUP}`
         // console.log(location.state.userId)
         axios.get(urllink).then((response) => {
            var acadLevels = response.data.acadProgramLevels;
            setAcadProgramLevels(acadLevels)
         })
         var role = Utils.getCurrentUserInfo(Enums.UserInfo.role)
         setIsAdmin(role === "Admin" ? true : false)
         setUser_Id(g_user_id);
         setActiveTab(aTab);
         setLoading(false);
      } catch (error) {
         setLoading(false);
         notification.error({
            message: `Error Loading list (2)`,
            description: 'Error Loading list...',
         });
      }
      setLoading(false);
   }, []);

   useEffect(() => {

      if (subdomain === 'dbti-makati' || subdomain === 'dev' || subdomain === 'localhost') {
         if (role !== 'Student')
            getMasterList();
         else
            getStudentListResources()
      }
      else
         navigate('/home');
   }, [user_id]);


   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const assignStudentsEvent = async (levelId, subject, title, key) => {
      setGradeLevel("")
      setExpandedKeys([]);
      setTreeData([])
      setCheckedKeys([]);
      setAvailabilityFormatteddate("")
      setAvailabilitydate([])
      setTerm("")
      setSubject("")
      setAssignVisible(true);
      setKey(key)
      setTitle(title)
      setTermHasError(false)
      setTitleHasError(false)
      setStudentListHasError(false)
      setAvailabilitydateHasError(false)
      setSubjectHasError(false)
      setGradeLevelHasError(false)
      //   await GetSubjectList(levelId)
      //   await GetStudentList(levelId) 
   }

   const columns = [
      {
         title: '',
         dataIndex: 'staffid',
         key: 'staffid',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'att_status',
         key: 'att_status',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         // ellipsis: {
         //    showTitle: false,
         // },
         render: payload => {
            return <>
               <Tooltip placement="topLeft" title={payload.title}>
                  <div style={{ width: "100%", cursor: "default" }}>
                     <b><Text>{payload.title}</Text></b>
                     <p style={{ marginBottom: '0px' }}>{payload.availability}</p>
                  </div>
               </Tooltip>
            </>
         },
         width: '23%',
         responsive: ["sm", "xs"]
      },
      {
         title: 'Subject',
         dataIndex: 'subject',
         key: 'subject',
         sorter: (a, b) => { return a.subject.localeCompare(b.subject) },
         ...getColumnSearchProps('subject'),
         // ellipsis: {
         //    showTitle: false
         // },
         render: subject => (
            <Tooltip placement="topLeft" title={subject}>
               <span style={{ cursor: "default" }}>{subject}</span>
            </Tooltip>
         ),
         width: '10%',
         responsive: ["sm", "xs"]
      },
      // {
      //    title: 'Term',
      //    dataIndex: 'term',
      //    key: 'term',
      //    sorter: (a, b) => a.term - b.term,
      //    ...getColumnSearchProps('term'),
      //    width: '5%',
      //    responsive: ["sm", "xs"]
      // },
      {
         title: 'Grade/Section',
         key: 'grade',
         sorter: (a, b) => { return a.grade.localeCompare(b.grade) },
         ...getColumnSearchProps('grade'),
         render: (payload) => {
            return <>
               <div style={{ width: "100%", cursor: "default" }}>
                  <b><Text>{payload.grade}</Text></b>
                  <p style={{ marginBottom: '0px' }}>{payload.section}</p>
               </div>
            </>
         },
         width: '10%',
         responsive: ["sm", "xs"]
      },

      // {
      //    title: 'Type',
      //    key: 'type',
      //    dataIndex: 'type',
      //    sorter: (a, b) => { return a.type.localeCompare(b.type) },
      //    ...getColumnSearchProps('type'),
      //    width: '8%',
      //    responsive: ["sm", "xs"]
      // },
      {
         title: 'Teacher',
         key: 'teacher_name',
         dataIndex: 'teacher_name',
         sorter: (a, b) => { return a.teacher_name.localeCompare(b.teacher_name) },
         ...getColumnSearchProps('teacher_name'),
         width: '15%',
         responsive: ["sm", "xs"],
         hidden: role === 'Student' ? false : (tabname === 'shared' ? false : true),
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <span>
               <Space size="small">
                  <Tooltip placement="bottom" title='View'><EyeOutlined style={{ cursor: 'pointer' }} onClick={() => handleViewLesson(payload.key, 'preview', payload.level)} /></Tooltip>
                  {role !== 'Student' && <Tooltip placement="top" title='Assign'><UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => assignStudentsEvent(payload.level, payload.subject, payload.title, payload.key)} /></Tooltip>}
               </Space>
            </span>
         },
         width: '15%',
         responsive: ["sm", "xs"]
      },
   ].filter(item => !item.hidden);

   const getMasterList = () => {
      try {
         if (Number(user_id) > 0) {
            let root = `${process.env.REACT_APP_LMS_API}api/BraineeResources/listEntityLessonRecs`
            let url = root + `?userid=${user_id}&role=${role}`

            setLoading(true);
            setTabName("master");
            axios.get(url).then((response) => {
               if (response.data) {
                  const data = response.data;
                  var result = data.reduce(function (r, a) {
                     r[a.subject] = r[a.subject] || [];
                     r[a.subject].push(a);
                     return r;
                  }, Object.create(null));

                  setDataSubject(result)
                  setData(data);
                  setDataContainer(response.data);
                  setLoading(false);
               }
               else {
                  message.error("GetMasterList Error")
               }
            }, (error) => {
               console.log(error.message);
               setLoading(false)
               console.log('stop loading')
               message.error("GetMasterList Error : " + error.message)
            });
         }
      } catch (error) {
         message.error("GetMasterList Done")
      }

   }

   const getStudentListResources = () => {
      if (Number(user_id) > 0) {
         let root = `${process.env.REACT_APP_LMS_API}api/BraineeResources/getLessonStudent`
         // let userstr = user.toString();
         let url = root + `?userid=${user_id}&role=${role}&timeline=1`
         console.log(url)

         console.log('loading')
         setLoading(true)
         setTabName("current")
         axios.get(url).then((response) => {
            console.log(response);

            if (response.data) {
               const data = response.data;

               //--Check if there is class started 
               // let has_class_started = data.filter(item => item.att_status === 'start');
               // if (has_class_started.length > 0)
               // setHasClassStarted(true);
               // setHasClassStarted(false); //-- Temporary solution

               // var result = Object.groupBy(data, ({ subject }) => subject);
               var result = data.reduce(function (r, a) {
                  r[a.subject] = r[a.subject] || [];
                  r[a.subject].push(a);
                  return r;
               }, Object.create(null));
               // result.sort()
               setDataSubject(result)
               setData(data);
               setDataContainer(response.data);
               setLoading(false);
               // setTimeout(() => {
               //    setLoading(false);
               //    console.log('stop loading')
               //  }, 2000);            

            }
         }, (error) => {
            console.log(error.message);
            setLoading(false)
            console.log('stop loading')
         });
      }
   }


   const handleViewLesson = (key, learning_mode, level = null) => {
      setKey(key);
      setLevelId(level);

      if (role === 'Student') {
         logAttendance(key, 'in');
      }
      
      navigate(`/subject/view?Id=${key}&level=${level}&version=2&type=resources`);
   }


   function colorize() {
      var r = ('0' + (Math.random() * 255 | 0).toString(16)).slice(-2),
         g = ('0' + (Math.random() * 255 | 0).toString(16)).slice(-2),
         b = ('0' + (Math.random() * 255 | 0).toString(16)).slice(-2);
      return '#' + r + g + b;
   }

   const itemSubjectClick = (subject) => {
      setSubjectSelected(subject)
      setViewType(1)
      var result = dataContainer.filter(p => p.subject === subject)
      setData(result);
   }



   const displaySubjectGroups = (d) => {
      let subjects = []
      if (viewType === 0) {
         d = Object.keys(d).sort().reduce(
            (obj, key) => {
               obj[key] = d[key];
               return obj;
            },
            {}
         );
         for (const key in d) {
            var item = { subjectName: key, TeacherName: d[key][0].teacher_name }
            subjects.push(item)
         }
      }

      return (
         <>
            {
               loading
                  ?
                  <div className='center-empty-space'>
                     <Spin size='large' tip="Loading, please wait..." />
                  </div>
                  :
                  (subjects.length > 0 && viewType === 0 && !loading)
                     ?
                     <>
                        <div className="ag-format-container">
                           <div className="ag-courses_box">
                              {
                                 subjects.map((subject, i) => {
                                    //var color = "#e44002"
                                    var color = colorize()
                                    return (

                                       <div className="ag-courses_item" onClick={() => itemSubjectClick(subject.subjectName)}>
                                          <a href="#" className="ag-courses-item_link">
                                             <div className="ag-courses-item_bg" style={{ backgroundColor: color }}></div>
                                             <div className="ag-courses-item_title">
                                                {subject.subjectName}
                                             </div>

                                             <div className="ag-courses-item_date-box">
                                                Teacher :
                                                <span className="ag-courses-item_date">
                                                   {subject.TeacherName}
                                                </span>
                                             </div>
                                          </a>
                                       </div>
                                    )
                                 })

                              }

                           </div>
                        </div>
                     </>
                     :
                     (data.length > 0 && viewType === 1 && !loading ?
                        <>
                           <div>
                              <Button className='button-shadow' type="primary" shape='round' onClick={() => setViewType(0)}>
                                 {"< Go back"}
                              </Button>
                           </div>
                           <Table
                              loading={loading}
                              size='small'
                              rowKey={'uid'}
                              columns={columns}
                              dataSource={data}
                              pagination={{ position: ['topCenter'] }}
                              // style={{ minWidth: "1000px" }}
                              scroll={{
                                 // y: 'calc(100svh - 20rem)',
                                 x: '1000px',
                                 y: 'calc(100svh - 10.5rem)',
                              }}
                              footer={() => 'Total Records: ' + data.length}
                           />
                        </>
                        :
                        <div className='center-empty-space'>
                           <Empty image="../images/brainee_mascot.png" description="No records found" />
                        </div>
                     )
            }
         </>
      )
   }



   const searchLesson = (str) => {
      var filerData = dataContainer.filter(p => (p.title.toLowerCase().includes(str.toLowerCase()) || p.subject.toLowerCase().includes(str.toLowerCase())))

      var result = filerData.reduce(function (r, a) {
         r[a.subject] = r[a.subject] || [];
         r[a.subject].push(a);
         return r;
      }, Object.create(null));

      setDataSubject(result)
      setData(filerData)
   }

   const onDrawerClose = () => {
      setAssignVisible(false);
   }

   const onExpand = (expandedKeysValue) => {
      setExpandedKeys(expandedKeysValue);
   };

   const onCheck = (checkedKeysValue) => {
      //console.log('onCheck', checkedKeysValue);
      setCheckedKeys(checkedKeysValue);

      //-- (start) with section ------------------------------
      const selectedStudsWithSection = checkedKeysValue.filter((e) => {
         return e.includes('_');
         //return e.includes('|');
      });

      var assigned_students_with_section = selectedStudsWithSection.join(',');
      setStudentListWithSection(assigned_students_with_section);

      //-- Remove the section part
      for (var i = 0; i < selectedStudsWithSection.length; i++) {
         selectedStudsWithSection[i] = selectedStudsWithSection[i].substring(selectedStudsWithSection[i].indexOf('_') + 1, selectedStudsWithSection[i].length);
      }

      var selected = [...new Set(selectedStudsWithSection)]; //-- Remove duplicate students
      var assigned_students = selected.join(',');
      setStudentList(assigned_students)
   };

   const onSelect = (selectedKeysValue, info) => {
      //console.log('onSelect', info);
      setSelectedKeys(selectedKeysValue);
   };

   const FormatDate = () => {
      if (availabilitydate && availabilitydate.length) {
         const d = availabilitydate[0];//'Jan 21, 2022, 7:07:00 am';
         const d2 = availabilitydate[1];//'Jan 21, 2022, 7:07:00 am';
         const arr = [moment(d, dateFormat), moment(d2, dateFormat)];
         return arr;
      } else {
         console.log('no value');
         return [];
      }
   }

   const handleChangeDate = (value, dateString) => {
      const d = dateString[0];
      const d2 = dateString[1];
      const dvalue = d + ' ~ ' + d2;
      setAvailabilityFormatteddate(dvalue)

      var vDate = []
      vDate.push(moment(d).format('YYYY-MM-DD h:mm a'))
      vDate.push(moment(d2).format('YYYY-MM-DD h:mm a'))
      setAvailabilitydate(vDate)

   }

   const GetSubjectList = async (levelId) => {
      await axios.get(`${process.env.REACT_APP_API_LOOKUP}/subjectListbyLevel?level=${levelId}`).then((response) => {
         if (response.data) {
            const data = response.data;
            setSubjectlist(data);
            //   setFormData({ ...formData, subjectList: data })
            //   let contentTmp = data.filter(p => p.subject_Code === lessonSelector.subject)
            //   console.log(contentTmp[0].description);
            //   setFormData({ ...formData, subject: contentTmp[0].description })
         }
      }, (error) => {
         console.log(error.message);
      });

   }

   const GetStudentList = async (levelId) => {
      await axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/studentlistperleveljson?level=${levelId}`).then((response) => {
         if (response.data) {
            const data = response.data;
            setTreeData(data);

            //   var students = props.options.assigned_students_with_section.split(',');
            //   setCheckedKeys(students);
         }
      }, (error) => {
         console.log(error.message);
      });
   }


   const onFinish = () => {
      var okSubmit = true
      setTermHasError(false)
      setTitleHasError(false)
      setStudentListHasError(false)
      setAvailabilitydateHasError(false)
      setSubjectHasError(false)
      setGradeLevelHasError(false)

      if (term === "") {
         setTermHasError(true)
         okSubmit = false
      }

      if (title === "") {
         setTitleHasError(true)
         okSubmit = false
      }

      if (studentList === "") {
         setStudentListHasError(true)
         okSubmit = false
      }

      if (subject === "") {
         setSubjectHasError(true)
         okSubmit = false
      }

      if (availabilitydate.length === 0) {
         setAvailabilitydateHasError(true)
         okSubmit = false
      }

      if (gradeLevel === "") {
         setGradeLevelHasError(true)
         okSubmit = false
      }


      if (okSubmit) {
         let lesson = {
            LessonId: key,
            Title: title,
            AvailabilityDate: availabilityFormatteddate,
            StaffId: g_user_id,
            Subject: subject,
            AssignedStudents: studentList,
            Term: term,
            AssignedStudentsWithSection: studentListWithSection,
         }

         let url = `${process.env.REACT_APP_LMS_API}api/BraineeResources/saveLessonItem`

         axios.post(url, lesson).then(response => {
            if (response.data) {

               // Add Response here
               setAssignVisible(false)
               Modal.success({
                  // icon: <AntDIcons.CloseCircleOutlined />,
                  title: 'Brainee Resources',
                  content: 'Resource assignment successful.',
                  centered: true
               });
            }
         }, (error) => {
            Modal.error({
               // icon: <AntDIcons.CloseCircleOutlined />,
               title: 'Create Lesson',
               content: 'Lesson creation failed.',
               centered: true
            });
         });
      }
   };

   const onGradeLevelChange = async (levelId) => {
      setExpandedKeys([]);
      setTreeData([])
      setCheckedKeys([]);
      setStudentList("");
      setStudentListWithSection("");
      setSubject("")
      await GetSubjectList(levelId)
      await GetStudentList(levelId)
      setGradeLevel(levelId);
   }

   const logAttendance = (lessonid, stat) => {
      axios.post(`${process.env.REACT_APP_LMS_API}api/LessonAttendance/postAttendance?lessonid=${lessonid}&studentid=${user_id}&stat=${stat}`).then((response) => {
         if (response.data) {
            const data = response.data;
            // Modal.success({
            //    content: 'Lesson Sharing Status Changed Successfully',
            //    centered: true,
            //    onOk: refreshList(activeTab)
            // });
         }
      }, (error) => {
         console.log(error.message);
         // Modal.error({
         //    content: 'Error logging attendance',
         //    centered: true,
         //    onOk: refreshList(activeTab)
         // });        
      });
   }

   return (
      <>
         {/* { assOptions && renderDrawer() } */}

         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li className="active">Lesson</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee Resources</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               {isAdmin || role === 'Teacher' || role === 'Co-Teacher'
                  ?
                  // <Row gutter={12}>

                  <div className='search-columns-3'>
                     {isAdmin
                        &&
                        <>
                           <Space direction='horizontal' size={'small'}>
                              <Input addonBefore={<SearchOutlined />} placeholder="Search Lesson..." onChange={(e) => searchLesson(e.target.value)} />
                           </Space>
                        </>
                     }
                  </div>
                  // </Row>
                  :
                  <></>
               }

               <div className="dashboard-item-inner3">
                  {displaySubjectGroups(dataSubject)}
               </div>
            </div>
         </div>
         <Drawer
            title={"Assign to Students"}
            placement="right"
            size={"default"}
            closable={false}
            onClose={onDrawerClose}
            open={assignVisible}
            zIndex={1001}
            extra={
               <>
                  <Space>
                     <Button type='primary' shape='round' onClick={onDrawerClose}>Close</Button>
                  </Space>
               </>
            }
         >
            <div style={{ display: "flex", padding: "10px", justifyContent: "center", alignItems: "center" }}>
               <div>
                  <Space>
                     <Button style={{ width: "150px" }} type='primary' shape='round' onClick={onFinish}>Save</Button>
                  </Space>
               </div>
            </div>

            <SimpleBarReact style={{ height: "calc(100vh - 110px)" }}>

               <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", padding: "0px" }} column={4}>
                  <Descriptions.Item label={titleHasError ? "*Title" : "Title"} span={4} style={{ textAlign: "left", color: titleHasError ? "red" : "black" }} >
                     {/* <Input
                                 size="default"
                                 placeholder=""
                                 defaultValue={title}
                                 style={{ color: '#000000' }}
                                 value={ title } 
                                 onChange={(value) =>  setTitle(value.target.value)}
                    /> */}
                     {title}
                  </Descriptions.Item>
               </Descriptions>

               <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", padding: "0px" }} column={4}>
                  <Descriptions.Item label={availabilityDateHasError ? "*Availability Date" : "Availability Date"} span={4} style={{ textAlign: "left", color: availabilityDateHasError ? "red" : "black" }} >
                     <RangePicker
                        showTime={{ format: 'hh:mm A', use12Hours: true }}
                        format={customFormat}
                        defaultValue={FormatDate}
                        value={FormatDate}
                        // onCalendarChange={ handleChangeDate } 
                        onChange={handleChangeDate}
                     />
                  </Descriptions.Item>
               </Descriptions>

               <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", padding: "0px" }} column={4}>
                  <Descriptions.Item label={termHasError ? "*Select Term" : "Select Term"} span={2} style={{ textAlign: "left", color: termHasError ? "red" : "black" }} >
                     <Select value={term} onChange={(e) => setTerm(e)} style={{ width: "100%" }} >
                        <Select.Option value="1">1</Select.Option>
                        <Select.Option value="2">2</Select.Option>
                        <Select.Option value="3">3</Select.Option>
                        <Select.Option value="4">4</Select.Option>
                     </Select>
                  </Descriptions.Item>
                  <Descriptions.Item label={gradeLevelHasError ? "*Grade Level" : "Grade Level"} span={2} style={{ textAlign: "left", color: gradeLevelHasError ? "red" : "black" }} >
                     <Select
                        style={{ width: '100%' }}
                        value={gradeLevel}
                        onChange={(e) => {
                           onGradeLevelChange(e)
                        }}
                     >
                        {
                           acadProgramLevels.map((filtered) => (
                              <Select.Option value={filtered.value}>{filtered.label}</Select.Option>
                           ))}
                     </Select>
                  </Descriptions.Item>
               </Descriptions>

               <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", padding: "0px" }} column={4}>
                  <Descriptions.Item label={subjectHasError ? "*Select Subject" : "Select Subject"} span={4} style={{ textAlign: "left", color: subjectHasError ? "red" : "black" }} >
                     <Select value={subject} onChange={(e) => setSubject(e)} style={{ width: "100%" }}>
                        {
                           subjectlist.map((filtered) => (
                              <Select.Option value={filtered.subject_Code}>{filtered.description}</Select.Option>
                           ))}
                     </Select>
                  </Descriptions.Item>
               </Descriptions>

               {/* <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", padding: "0px" }} column={4}>
                  <Descriptions.Item label={termHasError ? "*Select Term" : "Select Term"} span={4} style={{ textAlign: "left", color: termHasError ? "red" : "black" }} >
                     <Select value={term} onChange={(e) => setTerm(e)} style={{ width: "100px" }} >
                        <Select.Option value="1">1</Select.Option>
                        <Select.Option value="2">2</Select.Option>
                        <Select.Option value="3">3</Select.Option>
                        <Select.Option value="4">4</Select.Option>
                     </Select>
                  </Descriptions.Item>
               </Descriptions> */}


               <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", padding: "0px" }} column={4}>
                  <Descriptions.Item label={studentListHasError ? "*Select Students" : "Select Students"} span={4} style={{ textAlign: "left", color: studentListHasError ? "red" : "black" }} >
                     <Tree
                        checkable
                        // showLine={true}
                        // showIcon={false}
                        onExpand={onExpand}
                        expandedKeys={expandedKeys}
                        autoExpandParent={true}
                        onCheck={onCheck}
                        checkedKeys={checkedKeys}
                        onSelect={onSelect}
                        selectedKeys={selectedKeys}
                        treeData={treeData}
                        style={{ paddingBottom: "15px", paddingTop: "15px" }}
                        defaultExpandParent={true}
                     />
                  </Descriptions.Item>
               </Descriptions>
               {/* <AssessmentAssignTool
                  mode={"drawer"}
                  options={assOptions}
                  parentCallback={handleSetOptions}
                  saveCallback={handleSaveAssignment}
               /> */}
            </SimpleBarReact>
         </Drawer>

      </>
   );
}