export const learningmodes = [
    {
        id: 1,
        value: "hyflex",
        label: "HyFlex",
        learningtype: "sync",
        images: "../images/12.png"
    },
    {
        id: 2,
        value: "classroom",
        label: "Classroom",
        learningtype: "sync",
        images: "../images/13.png"
        
    },
    {
        id: 3,
        value: "assignment",
        label: "Assignment",
        learningtype: "async",
        images: "../images/12.png"
    },
    {
        id: 4,
        value: "reviewer",
        label: "Reviewer",
        learningtype: "async",
        images: "../images/13.png"
    },
    {
        id: 5,
        value: "online",
        label: "Online",
        learningtype: "async",
        images: "../images/13.png"
    }
    
];

export const learningtypes = [
    {
        id: 1,
        value: "async",
        label: "Asynchronous"
    },
    {
        id: 2,
        value: "sync",
        label: "Synchronous"
        
    }
];

export const subjects = [
    {
      label: "Science",
      value: "science",
    },
    {
      label: "Math",
      value: "math",
    },
    {
      label: "Araling Panlipunan",
      value: "aralingpanlipunan",
    },
    {
      label: "Arts",
      value: "arts",
    },
  ];

  export const terms = [
    {
      label: "First Term",
      value: "first",
    },
    {
      label: "Second Term",
      value: "second",
    },
    {
      label: "Third Term",
      value: "third",
    },
    {
      label: "Fourth Term",
      value: "fourth",
    },
  ];    

  export const levels = [
    {
      label: "Pre-School",
      value: "preschool",
    },
    {
      label: "Grade School",
      value: "gradeschool",
    },
    {
      label: "Tertiary",
      value: "tertiary",
    },
    {
      label: "Junior High School",
      value: "juniorhighschool",
    },
    {
      label: "Senior High School",
      value: "seniorhighschool",
    },     
  ]; 

  export const gradelevels = [    
    // {
    //   educlevel: "preschool",
    //   label: "Pre-School",
    //   value: "preschool",
    // },
    // {
    //   educlevel: "gradeschool",
    //   label: "Grade 1",
    //   value: "grade1",
    // },
    // {
    //   educlevel: "gradeschool",
    //   label: "Grade 2",
    //   value: "grade2",
    // },
    // {
    //   educlevel: "gradeschool",
    //   label: "Grade 3",
    //   value: "grade3",
    // },        
    // {
    //   label: "Tertiary",
    //   value: "tertiary",
    // },
    // {
    //   educlevel: "juniorhighschool",
    //   label: "Grade 7",
    //   value: "grade7",
    // },
    // {
    //   educlevel: "juniorhighschool",
    //   label: "Grade 8",
    //   value: "grade8",
    // },
    // {
    //   educlevel: "juniorhighschool",
    //   label: "Grade 9",
    //   value: "grade9",
    // },    
    // {
    //   educlevel: "juniorhighschool",
    //   label: "Grade 10",
    //   value: "grade10",
    // }, 
    // {
    //   educlevel:"seniorhighschool",
    //   label: "Grade 11",
    //   value: "grade11",
     
    // },             
    // {
    //   educlevel:"seniorhighschool",
    //   label: "Grade 12",
    //   value: "grade12",
    // },
    {
      "programCode": "PRE",
      "label": "Nursery",
      "value": "N"
    },
    {
      "programCode": "PRE",
      "label": "Pre-Kindergarten",
      "value": "PK"
    },
    {
      "programCode": "PRE",
      "label": "Kindergarten",
      "value": "K"
    },
    {
      "programCode": "ELEM",
      "label": "Grade One",
      "value": "G1"
    },
    {
      "programCode": "ELEM",
      "label": "Grade Two",
      "value": "G2"
    },
    {
      "programCode": "ELEM",
      "label": "Grade Three",
      "value": "G3"
    },
    {
      "programCode": "ELEM",
      "label": "Grade Four",
      "value": "G4"
    },
    {
      "programCode": "ELEM",
      "label": "Grade Five",
      "value": "G5"
    },
    {
      "programCode": "ELEM",
      "label": "Grade Six",
      "value": "G6"
    },
    {
      "programCode": "JHS",
      "label": "Grade Seven",
      "value": "G7"
    },
    {
      "programCode": "JHS",
      "label": "Grade Eight",
      "value": "G8"
    },
    {
      "programCode": "JHS",
      "label": "Grade Nine",
      "value": "G9"
    },
    {
      "programCode": "JHS",
      "label": "Grade Ten",
      "value": "G10"
    },
    {
      "programCode": "SHS",
      "label": "Grade Twelve",
      "value": "G12"
    },
    {
      "programCode": "SHS",
      "label": "Grade Eleven",
      "value": "G11"
    }      
  ];

  export const sections = [
    { 
      label: 'Section 1',
      value: 'section1' 
    }, 
    { 
      label: 'Section 2' ,
      value: 'section2'
    }, 
    { 
      label: 'Section 3' ,
      value: 'section3'
    }, 
    { 
      label: 'Section 4',
      value: 'section4' 
    }
  ];

  export const contenttypes = [
    {
      label: "Text Block",
      value: "textblock",
    },
    {
      label: "Image",
      value: "image",
    },
    {
      label: "Video",
      value: "video",
    },
    {
      label: "Document",
      value: "document",
    },
  ]; 

  

  export const activitytypes = [
    {
      label: "Assessments",
      value: "assessments",
    },
    {
      label: "Formative Quiz",
      value: "formativequiz",
    },
    {
      label: "Google Forms",
      value: "googleforms",
    },
    {
      label: "External Links",
      value: "externallinks",
    },
  ];   

  export default {
    learningmodes,
    learningtypes,
    subjects,
    terms,
    levels,
    sections,
    contenttypes,
    activitytypes
}