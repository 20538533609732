import React from "react"
import ReactPlayer from "react-player"

const VideoImageElement = props => {
  const { metadata, id, position, dimension, type } = props
  const elementRef = React.useRef(null)
  const [showGrids, setShowGrids] = React.useState(false)


  const renderImage = () => {
    return (  
        <div  ref={elementRef} 
              style={{position : "relative", width : "100%", height :"100%"}}>
             <img style={{position: "relative" , width: "100%", height : "100%"}} src={props.metadata.url}></img>
       </div>   
    )
  }

  const renderVideo = () =>
  {
    return(

            <div 
                ref={elementRef}      
                 style={{ position : "absolute", width : "100%", height :"100%"}}>
                <ReactPlayer url={props.metadata.url} width="100%"  height="100%" /> 
            </div>   
    )     
  }


  const renderElement = (type) =>
  {
    return(

            <div 
                ref={elementRef}    
          
                 style={{ position : "absolute", 
                          left: position?.left || 0, 
                          top: position?.top || 0,
                          width: dimension?.width || 0, 
                          height: dimension?.height || 0,     
                          transform : dimension?.transform || "translate(1px, 1px)",   
                          // WebkitTransform : "scale(5,4)",
                          // transformOrigin : "0px 0px",                
                          fontFamily: "Arial",
                          fontSize: "13px",
                          padding: 0,
                          float : "left",
                          border: `2px solid ${
                            showGrids 
                              ? "#21DEE5"
                              : "transparent"
                          }`,                            
                        }}>
                {type === "IMAGE" && <div  ref={elementRef} 
                                            style={{position : "relative", width : "100%", height :"100%"}}>
                                          <img style={{position: "relative" , width: "100%", height : "100%"}} src={props.metadata.url}></img>
                                    </div>}
                 {type === "VIDEO" && <div 
                                        ref={elementRef}      
                                        style={{ position : "absolute", width : "100%", height :"100%"}}>
                                        <ReactPlayer controls url={props.metadata.url} width="100%"  height="100%" /> 
                                    </div>}
                {type === "WEB" && <div 
                    ref={elementRef}      
                    style={{ position : "absolute", width : "100%", height :"100%", backgroundColor : "white", border : "1px solid black", borderRadius : "5px"}}>
                   <img onClick={()=> window.open(props.metadata.content, "_blank")} style={{position: "relative" , width: "100%", height : "100%", cursor : "pointer"}} src={props.metadata.url}></img>
                </div>}                                    
            </div>   
    )     
  } 

  return (
    renderElement(type)
  )
}

export default VideoImageElement
