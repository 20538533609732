import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { Descriptions, message, Typography, Select, Breadcrumb, Spin, InputNumber, Button, Input } from 'antd';
import './Assessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
// import QuestionCard from '../../components/assessment/QuestionCard';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import Utils from '../../misc/Utils';
import Split from 'react-split';
import moment from 'moment';
// import * as AntDIcons from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
// import './SplitPanel.css';

// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

import Enums from '../../misc/Enums';

const { Text, Paragraph } = Typography;
const { TextArea } = Input;
// const { Option } = Select

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let essayIdx = -1;
let matched = false;
let pdfFile = '';
let downloadData = '';

export function TeacherViewPDF(props) {
   const location = useLocation();
   const navigate = useNavigate();
   // const [assessment_id, setId] = useState(location.state.assessment_id);
   const assignInfo = location.state.assignInfo;
   // const assignment_id = location.state.assignment_id;
   const assessmentId = location.state.assessment_id;
   const studentId = location.state.student_id;
   const studentName = location.state.student_name;
   const origin = location.state.origin;

   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: "",
      Term: "",
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: "",
      Level: "",
      Subject: "",
      Level_desc: "",
      Subject_desc: ""
   });

   const [answerInfo, setAnswerInfo] = useState({
      Id: "",
      Answers: "",
      Score: "",
      Total_points: "",
      Start_date: "",
      End_date: ""
   });

   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();

      essayIdx = -1;
      pdfFile = '';

      getAssessmentInfo(assessmentId);
   }, [location.state.assessment_id]);

   const getAssessmentInfo = (id) => {
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/info?id=${id}&at=summative`).then((response) => {
         if (response.data) {
            const data = response.data;

            // downloadData += 'Student Name: ' + studentName + '\n';
            // downloadData += 'Assessment Title: ' + data.title + '\n\n';

            setAssInfo({
               Id: data.id,
               Title: data.title,
               Term: data.term,
               File_url: data.file_url,
               Question_type: data.question_type,
               Created_by: data.created_by,
               Date_created: data.date_created,
               Question_sheet: JSON.parse(data.question_sheet),
               Level: data.level,
               Subject: data.subject,
               Level_desc: data.level_desc,
               Subject_desc: data.subject_desc
            });

            getAnswerInfo(assessmentId, assignInfo.id, studentId);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getAnswerInfo = (assessment_id, assignment_id, student_id) => {
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/answerinfo2?assessment_id=${assessment_id}&assignment_id=${assignment_id}&student_id=${student_id}&at=summative`).then((response) => {
         if (response.data) {
            const data = response.data;
            // const answers = JSON.parse(data.answer);
            // setAnswers(JSON.parse(data.answer));
            setAnswerInfo({
               Id: data.id,
               Answers: JSON.parse(data.answer),
               Score: data.score,
               Total_points: data.total_points,
               Start_date: moment(data.start_date).format("MMM DD, YYYY hh:mm a"),
               End_date: moment(data.end_date).format("MMM DD, YYYY hh:mm a"),
               Essay_score: data.essay_score,
               Essay_score_multi: data.essay_score_multi !== "" && data.essay_score_multi !== null ? JSON.parse(data.essay_score_multi) : [],
               Essay_multi_comments: data.essay_multi_comments !== "" && data.essay_multi_comments !== null ? JSON.parse(data.essay_multi_comments) : [],
            });


         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleConsiderAnswer = (question_key_id, answer_to_consider) => {
      const key = 'updatable';
      message.loading("Updating correct answers...", key);

      var data = {
         Assessment_id: assessmentId,
         Question_id: question_key_id,
         Answer_to_consider: answer_to_consider[0],
         Assignment_id: assignInfo.id,
         Student_id: studentId
      }

      axios.post(`${process.env.REACT_APP_API_ASSESSMENT}/consideranswer?at=summative`, data).then((response) => {
         console.log(response);

         //if (response.data) {
         const data = response.data;
         getAssessmentInfo(assessmentId);
         message.success({ content: "Update complete!", key, duration: 1 });
         //}
      }, (error) => {
         console.log(error.message);
      });
   }

   function sPDFFile() {
      pdfFile = assInfo.File_url;
      return true;
   }

   const handleDownloadAnswers = () => {
      //
      const element = document.createElement("a");
      // const file = new Blob([document.getElementById('myInput').value], { type: 'text/plain' });
      const file = new Blob([downloadData], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = assInfo.Title + '.txt';
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/assessment', { state: { activetab: origin } })}>Summative Assessments</a></li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate(`/assessmenttracker?assign_id=${assignInfo.id}&origin=${origin}`)}>Tracker</a></li>
                           <li><span>/</span></li>
                           <li className="active">View Answer</li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }} >
                           {
                              user_role.toLowerCase() !== 'registrar'
                                 ?
                                 <>
                                    <Breadcrumb.Item>
                                       <Link to="/home">Home</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                       <a href="#" onClick={() => navigate('/assessment', { state: { activetab: origin } })}>Summative Assessment</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                       <Link to={`/assessmenttracker?assign_id=${assignInfo.id}&origin=${origin}`}>Tracker</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>View Answer</Breadcrumb.Item>
                                 </>
                                 :
                                 <>
                                    <Breadcrumb.Item>
                                       <a href="#" onClick={() => navigate('/studentassessment', { state: { activetab: origin } })}>Examinations For Online Applicants</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                       <Link to={`/assessmenttracker?assign_id=${assignInfo.id}&origin=${origin}`}>Tracker</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>View Answer</Breadcrumb.Item>
                                 </>
                           }
                        </Breadcrumb>
                     </div>
                     {answerInfo.Answers.length > 0
                        ?
                        <div style={{ width: "100%", height: "calc(100vh - 58px)" }}>
                           <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                              <Viewer
                                 pageLayout={{
                                    transformSize: ({ size }) => ({
                                       height: size.height + 30,
                                       width: size.width + 30,
                                    }),
                                 }}
                                 fileUrl={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`}
                                 defaultScale={SpecialZoomLevel.PageWidth}
                                 initialPage={0}
                              />
                           </Worker>
                        </div>
                        // <div className="pdf-all-page-container">
                        //    <RenderPDFViewer showdownload={true} url={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} height={"calc(100vh - 58px)"} load={pdfFile !== assInfo.File_url ? sPDFFile() : false} />
                        // </div>
                        // <RenderPDFReader url={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} height={"calc(100vh - 58px)"} />
                        :
                        <div className='temp-answer-sheet-content2'>
                           <Spin size='large' tip="Loading, please wait..." />
                        </div>
                     }
                  </div>
               </div>

               <div>
                  <div className="notice-item">
                     <>
                        {answerInfo.Answers.length > 0
                           ?
                           <div className='answer-sheet-content'>
                              <SimpleBarReact>
                                 <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", width: "100%" }} column={4}>
                                    <Descriptions.Item label={"Student Name"} span={4} style={{ textAlign: "center" }} >
                                       <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{studentName}</b></Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Title"} span={4} style={{ textAlign: "center" }} >
                                       <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{assInfo.Title}</b></Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Score"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                       <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{(answerInfo.Score + answerInfo.Essay_score) + " of " + answerInfo.Total_points}</b></Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Duration"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                       <Paragraph style={{ textAlign: "center", width: "100%" }}><b>
                                          {
                                             // Math.floor((Math.abs(new Date(answerInfo.End_date) - new Date(answerInfo.Start_date)) / 1000 / 60))\
                                             new Date(moment(answerInfo.End_date).diff(answerInfo.Start_date)).toISOString().substring(11, 19)
                                          }</b>
                                       </Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Schedule"} span={4} style={{ textAlign: "center", width: "50%" }}>
                                       <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{moment(assignInfo.start_date).format("MMM. DD, YYYY hh:mm A") + " to " + moment(assignInfo.end_date).format("MMM. DD, YYYY hh:mm A")}</b></Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Download Answers"} span={4} style={{ textAlign: "center", width: "100%" }}>
                                       <Paragraph style={{ textAlign: "center", width: "100%" }}>
                                          <Button className='button-shadow' type='primary' shape='round' style={{ marginBottom: "15px" }} onClick={() => handleDownloadAnswers()}>
                                             <Text ellipsis style={{ color: "#fff" }}><DownloadOutlined />&nbsp;Download</Text>
                                          </Button>
                                       </Paragraph>
                                    </Descriptions.Item>
                                 </Descriptions>

                                 <ul className="questions">
                                    {
                                       assInfo.Question_sheet.map(({ id, type, points, correct, option_labels, image_url, question }, idx) => {

                                          if (idx === 0) {
                                             qcardIdx = 1;
                                             idxCol = [];
                                             downloadData = '';
                                          }

                                          if (type === "section") {
                                             qcardIdx = 1;
                                             idxCol.push(sectionIdx);
                                             sectionIdx++;
                                          } else {
                                             idxCol.push(qcardIdx);
                                             qcardIdx++;
                                          }

                                          //-- Check if answer and correct are matched 
                                          var matched = false;

                                          try {
                                             if (type !== "long_answer" && type !== "section") {
                                                if (type === "short_answer") {
                                                   for (var i = 0; i < correct.length; i++) {
                                                      //-- Remove all white spaces before comparing
                                                      var tmp_correct = correct[i].replace(/\s/g, '');
                                                      var tmp_answer = answerInfo.Answers[idx].answer[0].replace(/\s/g, '');

                                                      // if (correct[i].toLowerCase().trim() === answerInfo.Answers[idx].answer[0].toLowerCase().trim()) {
                                                      if (tmp_correct.toLowerCase() === tmp_answer.toLowerCase()) {
                                                         matched = true;
                                                         break;
                                                      }
                                                   }
                                                } else {
                                                   var cntMatched = 0;
                                                   for (var i = 0; i < correct.length; i++) {
                                                      if (correct[i] === answerInfo.Answers[idx].answer[i]) {
                                                         cntMatched++;
                                                      }
                                                   }

                                                   if (cntMatched === correct.length) {
                                                      matched = true;
                                                   }
                                                }

                                                //-- Generate student answers for download --
                                                // if (idx === 0) {
                                                //    downloadData = '';
                                                // }

                                                if (downloadData === '') {
                                                   downloadData += 'Student Name: ' + studentName + '\n';
                                                   downloadData += 'Assessment Title: ' + assInfo.Title + '\n\n';
                                                   downloadData += "Answers \n\n"
                                                }

                                                var tmp = '';

                                                if (type === "short_answer") {
                                                   downloadData += idxCol[idx] + '. ' + answerInfo.Answers[idx].answer[0].toLowerCase().trim() + '\n';

                                                   if (!matched) {
                                                      for (var i = 0; i < correct.length; i++) {
                                                         if (tmp !== '')
                                                            tmp += ' or ';

                                                         tmp += correct[i].toLowerCase().trim();
                                                      }

                                                      downloadData += 'Correct Answer (' + tmp + ') \n\n';
                                                   }
                                                   else {
                                                      downloadData += '\n';
                                                   }
                                                } else {
                                                   downloadData += idxCol[idx] + '. ';

                                                   for (var i = 0; i < correct.length; i++) {
                                                      if (answerInfo.Answers[idx].answer[i] !== '0') {
                                                         tmp += option_labels[i] + '\n';
                                                         break;
                                                      }
                                                   }

                                                   if (tmp === '')
                                                      tmp += '\n';

                                                   downloadData += tmp;

                                                   if (!matched) {
                                                      for (var i = 0; i < correct.length; i++) {
                                                         if (correct[i] === '1') {
                                                            downloadData += 'Correct Answer (' + option_labels[i] + ')\n';
                                                            break;
                                                         }
                                                      }
                                                   } else {
                                                      downloadData += '\n';
                                                   }
                                                }
                                                //----------------------------------------------------
                                             }

                                             if (type === "long_answer") {
                                                downloadData += idxCol[idx] + '. ' + answerInfo.Answers[idx].answer[0] + '\n';
                                                downloadData += 'Points: ' + points + '\n\n';
                                                essayIdx++;
                                             }

                                             return (
                                                <li>
                                                   <BraineeQuestionCard
                                                      key={id}
                                                      question_key_id={id}
                                                      index={idxCol[idx]}
                                                      id={idx}
                                                      type={type}
                                                      points={points}
                                                      correct={correct}
                                                      answers={answerInfo.Answers[idx].answer}
                                                      option_labels={option_labels}
                                                      image_url={image_url}
                                                      question={question}
                                                      question_type={'1'}
                                                      mode={'teacher-view'}
                                                      matched={matched}
                                                      considerAnswerCallback={handleConsiderAnswer}
                                                      assessment_type={'summative'}
                                                      essay_score={answerInfo.Essay_score_multi[essayIdx]}
                                                      essay_comment={answerInfo.Essay_multi_comments[essayIdx]}
                                                   />
                                                </li>
                                             );
                                          }
                                          catch (ex) {
                                             console.log(ex);
                                             return (<></>);
                                          }



                                       })
                                    }
                                 </ul>
                              </SimpleBarReact>
                           </div>
                           :
                           <div className='temp-answer-sheet-content2'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                        }
                     </>
                  </div>
               </div>
            </Split>
         </div>
      </>
   )
}
