
import React, { useState, useEffect, useRef } from 'react';
import './EditLesson.scss'
import {
    Card,
    Input,
    Select,
    Skeleton,
    Button,
    message
  } from 'antd';
import axios from 'axios'
import {useSelector, useDispatch} from 'react-redux'
import { add_item } from '../../actions';
import uuid from 'react-uuid'
import moment from 'moment';

export function AddLesson (props)
{
    const [topic,setTopic] = React.useState('');
    const dispatch = useDispatch();

    const addTopic = () =>
    {
/*       if (props.dateEffective !== "")
      { */
        var currentDate = moment().format("MM/DD/YYYY HH:MM")
        var newItem = {id : uuid() ,type: "lesson", otherdesc : "", otherid : "",otherid2 : "", title : topic , effectivedate : currentDate , links : [], data : []}
        var params = {Type : "LESSON" ,TopicIndex : props.topicIdx , Item : newItem}
        dispatch(add_item(params));  
        props.modalClose();    
/*       }
      else
      {
        message.error("Effective date is blank!")
      } */
    }

    return <>
      <Input.Group compact>
            <Input style={{  width: 'calc(100% - 180px)', borderRadius: "7px" }} value={topic} onChange={(e) => setTopic(e.target.value)}/>
            <Button className='button-shadow' type="primary" onClick={() => addTopic()} style={{borderRadius: "7px", marginLeft : "5px" , width : "150px"}}>Add Resource</Button>
    </Input.Group>
    </>
}

export default  AddLesson;