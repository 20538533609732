import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Collapse, Descriptions, Form, Typography, Space } from 'antd';
import {
   learningmodes,
   learningtypes,
} from './createlesson/Lookup';
import { useSelector, useDispatch } from 'react-redux';

const { Text, Paragraph } = Typography;

function LessonBoard({ formData, setFormData, topics }) {

   const [record, SetRecord] = useState(formData);
   const [lesson, setLesson] = useState([]);
   const [sectionarray, setSectionarray] = useState([])
   const [topicrecs, setTopicrecs] = useState()
   const lessonSelector = useSelector(state => state.lessonrec);
   const lookupSelector = useSelector(state => state.lookup);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [conference, setConference] = useState('');

   const dispatch = useDispatch();

   const dateFormat = 'MMM D YYYY, h:mm:ss a';

   const customFormat = value => `${value.format(dateFormat)}`;


   const data = JSON.stringify({ formData });

   const { Panel } = Collapse;
   const { Title } = Typography;

   function callback(key) {
      console.log(key);
   }

   function FormatConference() {
      if (lessonSelector.conference === 'googlemeet') {
         setConference('GMeet Live Class')
      }
      else if (lessonSelector.conference === 'brainee') {
         setConference('Brainee Live Class')
      } else {
         setConference('');
      }
      return (
         <Form.Item label={`${conference}`} colon={false} />
      )
   }

   useEffect(() => {
      let item = lessonSelector;
      setLesson(item);
      let sectionlist = item.sections;
      setSectionarray(sectionlist);
      setTopicrecs(sectionlist)
   }, []);

   useEffect(() => {
      let item = lessonSelector;
      setLesson(item);
      let sectionlist = item.sections;
      setSectionarray(sectionlist);
   });

   return (
      <div className="notice-item-inner3">
         <div className="notice-item-inner4">
            <div style={{padding : "10px" }}>
                 <Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
                       <div><Title level={3}>Lesson Summary</Title></div>
                       </Space>
             </div>            
            <Descriptions layout='vertical' size={"small"} bordered style={{ marginTop: "10px", width: "100%", minWidth: '250px' }} column={4}>
               <Descriptions.Item label="Title" span={4} style={{ textAlign: "center" }}>
                  <Paragraph style={{ textAlign: "left" }}><b>{lessonSelector.title}</b></Paragraph>
               </Descriptions.Item>

               <Descriptions.Item label="Subject" span={4} style={{ textAlign: "center" }}>
                  <Paragraph style={{ textAlign: "left" }}>
                     <b>
                        {formData.subject === "" ? lessonSelector.subject : formData.subject}
                     </b>
                  </Paragraph>
               </Descriptions.Item>

               <Descriptions.Item label="Availability Date" span={4} style={{ textAlign: "center" }}>
                  <Paragraph style={{ textAlign: "left" }}>
                     <b>
                        {
                           lookupSelector.terms.filter(term => term.value === lessonSelector.term).map(filteredterm => (
                              <>
                                 ({filteredterm.name}) {formData.display_availabilitydate === "" ? lessonSelector.display_availabilitydate : formData.display_availabilitydate}
                              </>
                           ))
                        }
                     </b>
                  </Paragraph>
               </Descriptions.Item>

               <Descriptions.Item label="Educational Level" span={4} style={{ textAlign: "center" }}>
                  <Paragraph style={{ textAlign: "left" }}>
                     <b>
                        {lookupSelector.acadProgramLevels.filter(level => level.value === lessonSelector.gradelevel).map(filteredlevel => (
                           <>
                              {filteredlevel.label}
                           </>
                        ))}
                     </b>
                  </Paragraph>
               </Descriptions.Item>

               <Descriptions.Item label="Learning Type" span={4} style={{ textAlign: "center" }}>
                  <Paragraph style={{ textAlign: "left" }}>
                     <b>
                        {learningtypes.filter(learningtype => learningtype.value === lessonSelector.learningtype).map(filteredlearningtype => (
                           <>
                              {filteredlearningtype.label}
                           </>
                        ))}
                     </b>
                  </Paragraph>
               </Descriptions.Item>

               <Descriptions.Item label="Learning Mode" span={4} style={{ textAlign: "center" }}>
                  <Paragraph style={{ textAlign: "left" }}>
                     <b>
                        {learningmodes.filter(learningmode => learningmode.value === lessonSelector.learningmode).map(filteredlearningmode => (
                           <>
                              {filteredlearningmode.label}
                              <FormatConference />
                           </>
                        ))}
                     </b>
                  </Paragraph>
               </Descriptions.Item>

            </Descriptions>
            {/* <Descriptions bordered>
            
            </Descriptions>
            <Descriptions layout='vertical' bordered>
            <Descriptions.Item label="Availability Date">
            {lookupSelector.terms.filter(term => term.value === lessonSelector.term).map(filteredterm => (
                <dd>
                    {formData.display_availabilitydate} ({filteredterm.name})
                </dd>
            ))}
             </Descriptions.Item>
            </Descriptions>
            <Descriptions  bordered>
            <Descriptions.Item label="Educational Level">
            {lookupSelector.acadPrograms.filter(level => level.programCode === lessonSelector.educationallevel).map(filteredlevel => (
                <dd>
                {filteredlevel.description}
                </dd>
            ))}
            </Descriptions.Item>
            </Descriptions>
            <Descriptions layout='vertical'  bordered>
            <Descriptions.Item label="Learning Type">
            {learningtypes.filter(learningtype => learningtype.value === lessonSelector.learningtype).map(filteredlearningtype => (
                <dd>
                {filteredlearningtype.label}
                </dd>
            ))}
            </Descriptions.Item>
            <Descriptions.Item label="Learning Mode">
            {learningmodes.filter(learningmode => learningmode.value === lessonSelector.learningmode).map(filteredlearningmode => (
                <dd>
                {filteredlearningmode.label} 
                <FormatConference/>
                </dd>
            ))}
            </Descriptions.Item>
             </Descriptions> */}

         </div>

      </div>
   );
}

export default LessonBoard;