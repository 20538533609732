import React, { useEffect, useState, useReducer, useRef } from 'react';
import { Input, Row, Col, Space, Button, Descriptions, List } from 'antd';
import * as AntDIcons from '@ant-design/icons';
import SimpleBarReact from "simplebar-react";
import axios from 'axios';
// import { Editor } from '@tinymce/tinymce-react';
import Utils from '../../../misc/Utils';
import Enums from '../../../misc/Enums';
import moment from 'moment';
import Wysiwyg from '../../../components/wysiwyg/wysiwyg';

const { TextArea } = Input;

export default function Details(props) {
   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [homeworkData, setHomeworkData] = useState(null);
   const [uploadedFiles, setUploadedFiles] = useState([]);
   const [subdomain, setSubdomain] = useState(localStorage.getItem("lms-subdomain"));

   useEffect(() => {
      Utils.verifyJwt();

      getHomeworkInfo(props.homeworkID);
   }, [props.homeworkID]);

   const getHomeworkInfo = (id) => {
      axios.get(`${process.env.REACT_APP_API_HOMEWORK}/info?id=${id}`).then((response) => {
         if (response.data) {
            var data = response.data[0];
            setHomeworkData(data);
            setUploadedFiles(data.document.split(','));
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   return (
      <>
         {homeworkData !== null &&
            <>
               <Row gutter={12} style={{ paddingLeft: "0px", paddingRight: "0px", background: "#FFF" }}>
                  <Col span={24} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                     <Descriptions
                        layout='vertical'
                        size={"small"}
                        bordered
                        style={{ marginBottom: "15px", padding: "0px" }}
                        column={{
                           xxl: 4,
                           xl: 3,
                           lg: 3,
                           md: 3,
                           sm: 2,
                           xs: 1,
                        }}
                     >
                        <Descriptions.Item label={"Title"} span={4} style={{ textAlign: "center", width: "50%" }} >
                           <b>{homeworkData.title}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label={"Homework Date"} span={2} style={{ textAlign: "center", width: "50%" }} >
                           <b>{moment(homeworkData.homework_date).format('MMM. DD, YYYY')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label={"Submission Date"} span={2} style={{ textAlign: "center", width: "50%" }} >
                           <b>{moment(homeworkData.submit_date).format('MMM. DD, YYYY')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label={"Level"} span={2} style={{ textAlign: "center", width: "50%" }} >
                           <b>{homeworkData.level}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label={"Subject"} span={2} style={{ textAlign: "center", width: "50%" }} >
                           <b>{homeworkData.subject}</b>
                        </Descriptions.Item>
                     </Descriptions>
                  </Col>
               </Row>
               <Row gutter={12} style={{ paddingBottom: "10px", paddingLeft: "0px", paddingRight: "0px", background: "#FFF" }}>
                  <Col span={24} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                     <label>Description</label>
                     <Wysiwyg 
                                 // changeContentCallback={(e) => handleDescriptionChange(e)} 
                                 content={homeworkData.description} 
                                 height={250}
                                 contentHeight={135}
                                 disabled={true}
                              />
                     {/* <Editor
                        apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                        // onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={homeworkData.description}
                        init={{
                           menubar: false,
                           height: 100,
                           statusbar: false,
                           plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code',
                              'insertdatetime media table paste code help wordcount emoticons'
                           ],
                           toolbar: false,
                           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                        disabled={true}
                     /> */}
                  </Col>
               </Row>
               <Row gutter={12} style={{ paddingBottom: "10px", paddingLeft: "0px", paddingRight: "0px", background: "#FFF" }}>
                  <Col span={24} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                     <label>Attached Documents</label>
                     <>
                        <SimpleBarReact style={{ maxHeight: "60px", overflow: "auto" }}>
                           <List
                              size="small"
                              bordered
                              dataSource={uploadedFiles}
                              renderItem={item =>
                                 <List.Item>
                                    <a target={"_blank"} href={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${process.env.REACT_APP_S3_HOMEWORKS_TEACHERS_PATH}/${item}`}>{item}</a>
                                 </List.Item>}
                           />
                        </SimpleBarReact>
                     </>
                  </Col>
               </Row>
            </>
         }
      </>
   )
}