// import './DashboardPage.css';
import React, { useEffect } from 'react'
// import { Input, Form } from 'antd';
// import * as AntDIcons from '@ant-design/icons';
// import SideCalendar from '../side-calendar/SideCalendar'; //'./side-calendar/SideCalendar';
// import NoticeBoard from '../notice-board/NoticeBoard';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
// import SimpleBarReact from "simplebar-react";
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import NoticeBoardSlide from '../notice-board/NoticeBoardSlide';
import { Typography, Breadcrumb } from 'antd';

const { Text } = Typography;

function Dashboard() {

   const navigate = useNavigate();
   const [currentUsername, setCurrentUsername] = React.useState('');
   const [isAdmin, setIsAdmin] = React.useState(false);
   const [loading, setLoading] = React.useState(false);
   const [currentCount, setCurrentCount] = React.useState(0);
   const [upcomingCount, setUpcomingCount] = React.useState(0);
   const [pastCount, setPastCount] = React.useState(0);

   const [summativeCurrentCount, setSummativeCurrentCount] = React.useState(0);
   const [summativeUpcomingCount, setSummativeUpcomingCount] = React.useState(0);
   const [summativePastCount, setSummativePastCount] = React.useState(0);

   var role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();
      let fullname = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);
      setCurrentUsername(fullname.trim() === "" ? "Parent" : fullname);
      // Utils.getCurrentUserInfo(Enums.UserInfo.role);
      getLessonCount()
   }, []);

   const onFinish = (values) => {
      console.log('Success:', values);
   };

   const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };

   const getLessonCount = () => {
      let root = ``
      let url = '';
      let user = Utils.getCurrentUserInfo(Enums.UserInfo.id)
      let role = Utils.getCurrentUserInfo(Enums.UserInfo.role)

      if (role === 'Admin') {
         url = root
         setIsAdmin(true)
      } else {
         setIsAdmin(false)
      }

      root = `${process.env.REACT_APP_LMS_API}api/Lesson/lessonCount`
      let userstr = user.toString()
      url = root + `?userid=${user}&role=${role}`
      console.log(url)


      console.log('loading')
      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            console.log(data);
            setPastCount(data.past);
            setCurrentCount(data.current);
            setUpcomingCount(data.future);

            setSummativeCurrentCount(data.summative_current);
            setSummativeUpcomingCount(data.summative_future);
            setSummativePastCount(data.summative_past);
            // setData(response.data);
            // setDataContainer(response.data);
         }
      }, (error) => {
         console.log(error.message);
         setLoading(false)
         console.log('stop loading')
      });
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">

            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>Home</Breadcrumb.Item>
                  </Breadcrumb>
                  {/*                <ul>
                  <li>&nbsp;</li>
               </ul>
 */}
                  {/* <Form
                  name="basic"
                  wrapperCol={{
                     span: 24,
                  }}
                  initialValues={{
                     remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
               >
                  <Form.Item
                     name="lessontitle"
                     rules={[
                        {
                           required: false,
                           message: 'Enter lesson title',
                        },
                     ]}
                     >
                     <Input
                        allowClear
                        size='small'
                        style={{borderRadius: 15}}
                        placeholder="Enter lesson title"
                        suffix={<AntDIcons.SearchOutlined style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }} />}
                     />
                  </Form.Item>
               </Form>                      */}
               </div>
               <div className="dashboard-item-inner2">
                  <div>
                     <h2>Welcome back, {role === 'Parent' ? currentUsername + "'s parent" : currentUsername}</h2>
                     <span>Together we evolve from offline to online learning, adapt to the new normal, and transition from traditional to non-traditional learning.</span>
                  </div>
                  <div>
                     <img src="images/10.png" alt="" style={{ height: "200px" }} />
                  </div>
               </div>

               {role === "Parent"
                  ?
                  <>
                  </>
                  :
                  <>
                     <div className="dashboard-item-inner3">
                        <h2>My Resources</h2>
                        <div className="row">
                           <div className="col-md-6 col-lg-4">
                              <div className="dashboard-item-inner4">
                                 <div className="dashboard-item-inner5">
                                    <img src="images/11.png" alt="" />
                                 </div>
                                 <div className="dashboard-item-inner6">
                                    <h3>
                                       <Text style={{ fontSize: "18px" }}>Current Lesson</Text>
                                       {/* <a href="#"><img src="images/14.png" alt="" onClick={() => navigate('/subject', { state: { activetab: "3" } })} /></a> */}
                                    </h3>
                                    {
                                       parseInt(currentCount) === 0
                                          ?
                                          <p style={{ paddingBottom: "10px" }}>
                                             <Text style={{ fontSize: '15px', color: "#5DC6CD" }}>None</Text>
                                          </p>
                                          :
                                          <p style={{ paddingBottom: "10px", cursor: 'pointer' }}>
                                             <Text style={{ fontSize: '15px', color: "#5DC6CD" }} onClick={() => navigate('/subject', { state: { activetab: "3" } })}>See my <b>{currentCount}</b> current lesson(s)</Text>
                                          </p>
                                    }

                                    {
                                       role === 'Student'
                                          ?
                                          <>
                                             <h3>
                                                <Text style={{ fontSize: "17px" }}>Current Summative Assessment</Text>
                                                {/* <a href="#"><img src="images/14.png" alt="" onClick={() => navigate('/assessment', { state: { activetab: "1" } })} /></a> */}
                                             </h3>
                                          </>
                                          :
                                          <>
                                             <h3>
                                                <Text style={{ fontSize: "17px" }}>Current Summative Assessment</Text>
                                                {/* <a href="#"><img src="images/14.png" alt="" onClick={() => navigate('/assessment', { state: { activetab: "2" } })} /></a> */}
                                             </h3>
                                          </>
                                    }
                                    {
                                       parseInt(summativeCurrentCount) === 0
                                          ?
                                          <Text style={{ fontSize: "15px", color: "#5DC6CD" }}>None</Text>
                                          :
                                          <Text style={{ fontSize: "15px", cursor: 'pointer', color: "#5DC6CD" }} onClick={() => navigate(role === 'Student' ? '/studentassessment' : '/assessment', { state: { activetab: role === 'Student' ? "1" : "2" } })}>See my <b>{summativeCurrentCount}</b> current summative assessment(s) </Text>
                                    }

                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6 col-lg-4">
                              <div className="dashboard-item-inner4">
                                 <div className="dashboard-item-inner5">
                                    <img src="images/12.png" alt="" />
                                 </div>
                                 <div className="dashboard-item-inner6">
                                    <h3>
                                       <Text style={{ fontSize: "18px" }}>Upcoming Lesson</Text>
                                       {/* <a href="#"><img src="images/14.png" alt="" onClick={() => navigate('/subject', { state: { activetab: "4" } })} /></a> */}
                                    </h3>
                                    {
                                       parseInt(upcomingCount) === 0
                                          ?
                                          <p style={{ paddingBottom: "10px" }}>
                                             <Text style={{ fontSize: '15px', color: "#5DC6CD" }}>None</Text>
                                          </p>
                                          :
                                          <p style={{ paddingBottom: "10px", cursor: 'pointer' }}>
                                             <Text style={{ fontSize: '15px', color: "#5DC6CD" }} onClick={() => navigate('/subject', { state: { activetab: "4" } })}>See my <b>{upcomingCount}</b> upcoming lessons </Text>
                                          </p>
                                    }
                                    {
                                       role === 'Student'
                                          ?
                                          <>
                                             <h3>
                                                <Text style={{ fontSize: "17px" }}>Upcoming Summative Assessment</Text>
                                                {/* <a href="#"><img src="images/14.png" alt="" onClick={() => navigate('/assessment', { state: { activetab: "1" } })} /></a> */}
                                             </h3>
                                          </>
                                          :
                                          <>
                                             <h3>
                                                <Text style={{ fontSize: "17px" }}>Upcoming Summative Assessment</Text>
                                                {/* <a href="#"><img src="images/14.png" alt="" onClick={() => navigate('/assessment', { state: { activetab: "3" } })} /></a> */}
                                             </h3>
                                          </>
                                    }
                                    {
                                       parseInt(summativeUpcomingCount) === 0
                                          ?
                                          <Text style={{ fontSize: "15px", color: "#5DC6CD" }}>None</Text>
                                          :
                                          <Text style={{ fontSize: "15px", cursor: 'pointer', color: "#5DC6CD" }} onClick={() => navigate(role === 'Student' ? '/studentassessment' : '/assessment', { state: { activetab: role === 'Student' ? "1" : "3" } })}>See my <b>{summativeUpcomingCount}</b> upcoming summative assessments</Text>
                                    }

                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6 col-lg-4">
                              <div className="dashboard-item-inner4">
                                 <div className="dashboard-item-inner5">
                                    <img src="images/13.png" alt="" />
                                 </div>
                                 <div className="dashboard-item-inner6">
                                    <h3>
                                       <Text style={{ fontSize: "18px" }}>Past Lesson</Text>
                                       {/* <a href="#"><img src="images/14.png" alt="" onClick={() => navigate('/subject', { state: { activetab: "5" } })} /></a>\ */}
                                    </h3>
                                    {
                                       parseInt(pastCount) === 0
                                          ?
                                          <p style={{ paddingBottom: "10px" }}>
                                             <Text style={{ fontSize: '15px', color: "#5DC6CD" }}>None</Text>
                                          </p>
                                          :
                                          <p style={{ paddingBottom: "10px", cursor: 'pointer' }}>
                                             <Text style={{ fontSize: '15px', color: "#5DC6CD" }} onClick={() => navigate('/subject', { state: { activetab: "5" } })}>See my <b>{pastCount}</b> past lessons</Text>
                                          </p>
                                    }

                                    {
                                       role === 'Student'
                                          ?
                                          <>
                                             <h3>
                                                <Text style={{ fontSize: "17px" }}>Past Summative Assessment</Text>
                                                {/* <a href="#"><img src="images/14.png" alt="" onClick={() => navigate('/assessment', { state: { activetab: "2" } })} /></a> */}
                                             </h3>
                                          </>
                                          :
                                          <>
                                             <h3>
                                                <Text style={{ fontSize: "17px" }}>Past Summative Assessment</Text>
                                                {/* <a href="#"><img src="images/14.png" alt="" onClick={() => navigate('/assessment', { state: { activetab: "4" } })} /></a> */}
                                             </h3>
                                          </>
                                    }
                                    {
                                       parseInt(summativePastCount) === 0
                                          ?
                                          <Text style={{ fontSize: "15px", color: "#5DC6CD" }}>None</Text>
                                          :
                                          <Text style={{ fontSize: "15px", cursor: 'pointer', color: "#5DC6CD" }} onClick={() => navigate(role === 'Student' ? '/studentassessment' : '/assessment', { state: { activetab: role === 'Student' ? "2" : "4" } })}>See my <b>{summativePastCount}</b> past summative assessments</Text>
                                    }
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </>}

            </div>
         </div>
      </>
   );
}

export default Dashboard;   